
import LoadbalancerCard from "./LoadbalancerCard.vue";
import { Loadbalancer, loadbalancersApi } from "@/api/loadbalancers";
import Vue from "vue";

enum LoadbalancersOverviewState {
    LOADING,
    ERROR,
    READY,
    CREATING,
}

export default Vue.extend({
    data() {
        return {
            LoadbalancersOverviewState,
            loadbalancersOverviewState: LoadbalancersOverviewState.LOADING,
            loadbalancers: [] as Loadbalancer[],
        };
    },

    components: {
        LoadbalancerCard,
    },

    methods: {
        async loadLoadbalancers() {
            this.loadbalancersOverviewState = LoadbalancersOverviewState.LOADING;
            try {
                this.loadbalancers = await loadbalancersApi.list();
                this.loadbalancersOverviewState = LoadbalancersOverviewState.READY;
            } catch (e) {
                this.loadbalancersOverviewState = LoadbalancersOverviewState.ERROR;
                throw e;
            }
        },

        async createLoadbalancer() {
            this.loadbalancersOverviewState = LoadbalancersOverviewState.CREATING;
            try {
                await loadbalancersApi.add();
            } finally {
                await this.loadLoadbalancers();
            }
        },
    },

    async mounted() {
        await this.loadLoadbalancers();
    },
});

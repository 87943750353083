
import { BadRequest } from "@/api/errors";
import { FtpAccount, ftpAccountApi, FtpAccountForm } from "@/api/ftpAccounts";
import { notEmpty, ValidationHelper } from "@/app/validation";
import Vue from "vue";

const PASSWORD_CHARACTERS = "ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnpqrstuvwxyz23456789";

function generateRandomPassword(length: number) {
    return Array(length)
        .fill("")
        .map(() => PASSWORD_CHARACTERS.charAt(Math.floor(Math.random() * PASSWORD_CHARACTERS.length)))
        .join("");
}

export default Vue.extend({
    props: {
        ftpAccount: Object as () => FtpAccount | null,
    },

    data() {
        const validationHelper = new ValidationHelper();
        return {
            valid: true,
            isWorking: false,
            label: this.ftpAccount?.label || "",
            labelRules: notEmpty().maxLength(63),
            username: this.ftpAccount?.username || "",
            usernameRules: notEmpty()
                .maxLength(63)
                .and(validationHelper, "username"),
            password: this.ftpAccount?.password || generateRandomPassword(16),
            passwordRules: notEmpty().maxLength(63),
            showPassword: !this.ftpAccount,
            internal: this.ftpAccount ? this.ftpAccount.internal : false,
            validationHelper,
        };
    },

    methods: {
        async submitForm() {
            if (!this.valid) {
                (this.$refs.form as any).validate();
                return;
            }

            const ftpAccountForm: FtpAccountForm = {
                label: this.label,
                username: this.username,
                password: this.password,
                internal: this.internal,
            };

            this.isWorking = true;
            try {
                if (this.ftpAccount) {
                    await ftpAccountApi.edit(this.ftpAccount.id, ftpAccountForm);
                } else {
                    await ftpAccountApi.add(ftpAccountForm);
                }
            } catch (e) {
                if (!(e instanceof BadRequest)) {
                    throw e;
                }
                this.validationHelper.update(e, this.$refs.form);
                return;
            } finally {
                this.isWorking = false;
            }
            this.$emit("success");
        },

        close() {
            this.$emit("close");
        },
    },
});

import { DutyRosterEntry, DutyRosterEntryType } from "@/api/dutyRosters";
import { User } from "@/api/users";
import { $t } from "@/app/i18n";
import { getMaxDate, getMinDate, sum } from "@/app/pages/reporting/reportingUtils";
import { RowKey, RowLabel } from "@/app/pages/reporting/rowUtils";
import { getFullName } from "@/app/userUtils";

export interface DutyRosterRow {
    readonly userId: string;
    readonly type: DutyRosterEntryType;
    readonly day: string;
    readonly begin: Date;
    readonly end: Date;
    readonly includedBreakMinutes: number;
}

export interface AnswerIncomingCallsShift<T extends DutyRosterRow> {
    readonly userId: string;
    readonly begin: Date;
    readonly end: Date;
    readonly rows: readonly T[];
    readonly duration: number;
    readonly elapsedDuration: number;
    readonly hasStarted: boolean;
    readonly hasEnded: boolean;
    readonly isBetweenSubshifts: boolean;
}

/*
 * helper
 */

function toFullSeconds(time: number | null): number | null {
    if (time === null) {
        return null;
    }

    const seconds = time / 1000;

    return seconds < 0 ? Math.floor(seconds) : Math.ceil(seconds);
}

export function toDutyRosterRow(row: DutyRosterEntry, userId: string): DutyRosterRow {
    return {
        userId,
        type: row.type,
        day: row.day,
        begin: new Date(`${row.day}T${row.beginTime}`),
        end: new Date(new Date(`${row.day}T${row.endTime}`).getTime() + 60_000),
        includedBreakMinutes: row.includedBreakMinutes,
    };
}

export function toDutyRosterRowBetween(row: DutyRosterRow, from: Date, to: Date): DutyRosterRow | null {
    const begin = getMaxDate(row.begin, from)!;
    const end = getMinDate(row.end, to)!;

    if (end.getTime() <= begin.getTime()) {
        return null;
    }

    const beginSecond = toFullSeconds(row.begin.getTime())!;
    const endSecond = toFullSeconds(row.end.getTime())!;
    const betweenBeginSecond = toFullSeconds(begin.getTime())!;
    const betweenEndSecond = toFullSeconds(end.getTime())!;

    const factor = (betweenEndSecond - betweenBeginSecond) / (endSecond - beginSecond);

    return {
        userId: row.userId,
        type: row.type,
        day: row.day,
        begin,
        end,
        includedBreakMinutes: Math.floor(row.includedBreakMinutes * factor),
    };
}

export function toAnswerIncomingCallsShift<T extends DutyRosterRow & WithDuration & WithElapsedDuration>(
    userId: string,
    rows: readonly T[]
): AnswerIncomingCallsShift<T> | null {
    const filteredRows = rows
        .filter(
            (r) =>
                r.userId === userId &&
                (r.type === DutyRosterEntryType.ANSWER_INCOMING_ORDINARY_CALLS ||
                    r.type === DutyRosterEntryType.ANSWER_INCOMING_PRIORITY_CALLS)
        )
        .sort((a, b) => a.begin.getTime() - b.begin.getTime() || a.end.getTime() - b.end.getTime());

    if (!filteredRows.length) {
        return null;
    }

    const duration = sum(filteredRows.map((r) => r.duration)) ?? 0;
    const elapsedDuration = sum(filteredRows.map((r) => r.elapsedDuration)) ?? 0;
    const hasStarted = elapsedDuration > 0;
    const hasEnded = duration === elapsedDuration;

    return {
        userId,
        begin: getMinDate(...filteredRows.map((r) => r.begin))!,
        end: getMaxDate(...filteredRows.map((r) => r.end))!,
        rows: filteredRows,
        duration,
        elapsedDuration,
        hasStarted,
        hasEnded,
        isBetweenSubshifts:
            hasStarted &&
            !hasEnded &&
            filteredRows.filter((r) => r.elapsedDuration < r.duration).every((r) => r.elapsedDuration === 0),
    };
}

/*
 * with begin second
 */

export interface WithBeginSecond {
    readonly beginSecond: number;
}

export function withBeginSecond<T extends { readonly begin: Date }>(row: T): T & WithBeginSecond {
    return {
        ...row,
        beginSecond: toFullSeconds(row.begin.getTime())!,
    };
}

/*
 * with duration
 */

export interface WithDuration {
    readonly duration: number;
}

export function withDuration<
    T extends {
        readonly begin: Date;
        readonly end: Date;
        readonly includedBreakMinutes: number;
    }
>(row: T): T & WithDuration {
    const beginSecond = toFullSeconds(row.begin.getTime())!;
    const endSecond = toFullSeconds(row.end.getTime())!;

    return {
        ...row,
        duration: endSecond - beginSecond - row.includedBreakMinutes * 60,
    };
}

/*
 * with duration
 */

export interface WithElapsedDuration {
    readonly elapsedDuration: number;
}

export function withElapsedDuration<T extends { readonly begin: Date; readonly end: Date } & WithDuration>(
    row: T,
    now: Date | null
): T & WithElapsedDuration {
    const beginSecond = toFullSeconds(row.begin.getTime())!;
    const endSecond = toFullSeconds(row.end.getTime())!;
    const nowSecond = toFullSeconds(now?.getTime() ?? null);

    if (!nowSecond || nowSecond <= beginSecond) {
        return { ...row, elapsedDuration: 0 };
    }

    const rawDuration = endSecond - beginSecond;
    const rawDurationUpToNow = Math.min(nowSecond, endSecond) - beginSecond;

    return {
        ...row,
        elapsedDuration: row.duration * (rawDurationUpToNow / rawDuration),
    };
}

/*
 * with end second
 */

export interface WithEndSecond {
    readonly endSecond: number;
}

export function withEndSecond<T extends { readonly end: Date }>(row: T): T & WithEndSecond {
    return {
        ...row,
        endSecond: toFullSeconds(row.end.getTime())!,
    };
}

/*
 * misc
 */

export interface MapDutyRosterRowKeyToLabelContext {
    users?: readonly User[];
}

export function mapDutyRosterRowKeyToRowLabel(
    key: RowKey,
    groupBy: string,
    context?: MapDutyRosterRowKeyToLabelContext
): RowLabel {
    const { users } = context ?? {};

    if (groupBy === "userId") {
        if (key === null) {
            return { label: $t("Ohne Benutzer") as string };
        } else if (typeof key === "string") {
            const user = users?.find((d) => d.id === key);

            if (!user) {
                return { label: $t("Gelöschter Benutzer") as string };
            }

            return { label: getFullName(user) };
        }
    }

    return { label: $t("Unbekannt") as string };
}


import { ExternalBdcCdr } from "@/api/externalBdcCdrs";
import ExternalBdcCdrsBottomSheet from "@/app/pages/externalbdccdr/ExternalBdcCdrsBottomSheet.vue";
import { getReportingTableByReportingTimeSeriesChart } from "@/app/pages/reporting/chartUtils";
import { ReportingChartColorPalette } from "@/app/pages/reporting/charts/reportingChart";
import { ReportingTimeSeriesChartData } from "@/app/pages/reporting/charts/reportingTimeSeriesChart";
import ReportingDashboardTimeSeriesTile from "@/app/pages/reporting/dashboard/ReportingDashboardTimeSeriesTile.vue";
import {
    WithBeginOngoingTimeSlot,
    withBeginOngoingTimeSlot,
    WithDuration,
    WithWaited,
} from "@/app/pages/reporting/externalbdc/externalBdcRowUtils";
import { addMissingRowGroups, applyKeySort, groupRowsBy, RowGroup } from "@/app/pages/reporting/pivotUtils";
import { rate } from "@/app/pages/reporting/reportingUtils";
import { ReportingTableData } from "@/app/pages/reporting/table/reportingTable";
import {
    getOngoingTimeSlotKeys,
    getOngoingTimeSlotLabel,
    OngoingTimeInterval,
} from "@/app/pages/reporting/timeInterval";
import { getNumberOfSubIntervalsInOngoingTimeInterval } from "@/app/pages/reporting/timeSeriesUtils";
import { userSession } from "@/store/userSession";
import Vue from "vue";

type ComputedExternalBdcCdrRow = PrecomputedExternalBdcCdrRow & WithBeginOngoingTimeSlot;

type PrecomputedExternalBdcCdrRow = ExternalBdcCdr & WithDuration & WithWaited;

export default Vue.extend({
    props: {
        chartHeight: {
            type: Number,
            required: false,
        },
        chartTimeInterval: {
            type: String as () => OngoingTimeInterval,
            default: OngoingTimeInterval.DATE,
        },
        chartWindowInterval: {
            type: String as () => OngoingTimeInterval,
            default: OngoingTimeInterval.WEEK,
        },
        externalBdcCdrRows: {
            type: Array as () => readonly PrecomputedExternalBdcCdrRow[],
            required: true,
        },
        loading: {
            type: Boolean,
            required: true,
        },
        minHeight: {
            type: Number,
            required: false,
        },
        small: {
            type: Boolean,
            default: false,
        },
        subtitle: {
            type: String,
            required: false,
        },
        timeSeriesFrom: {
            type: (Date as unknown) as () => Date,
            required: true,
        },
        timeSeriesTo: {
            type: (Date as unknown) as () => Date,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            bottomSheetExternalBdcCdrIds: [] as number[],
            bottomSheetVisible: false,
        };
    },

    computed: {
        chart(): ReportingTimeSeriesChartData {
            return {
                title: "",
                series: [
                    {
                        id: "acceptance-rate",
                        name: this.$t("Annahmequote") as string,
                        data: {
                            values: this.externalBdcCdrRowGroups.map((rowGroup) => ({
                                date: new Date(rowGroup.key),
                                value: rate(
                                    rowGroup.rows.filter((r) => r.startedInTimeSlot && r.accept).length,
                                    rowGroup.rows.filter((r) => r.startedInTimeSlot).length
                                ),
                                onClick: this.showBottomSheetOnClick(
                                    rowGroup.rows.filter((r) => r.startedInTimeSlot && r.accept).map((r) => r.id)
                                ),
                            })),
                            isPercentage: true,
                        },
                        colorPalette: ReportingChartColorPalette.POSITIVE,
                    },
                ],
                dateFormatter: this.dateFormatter,
            };
        },

        chartWindowSize(): number | undefined {
            return (
                getNumberOfSubIntervalsInOngoingTimeInterval(this.chartWindowInterval, this.chartTimeInterval) ??
                undefined
            );
        },

        computedExternalBdcCdrRow(): readonly ComputedExternalBdcCdrRow[] {
            return withBeginOngoingTimeSlot(this.externalBdcCdrRows, this.chartTimeInterval);
        },

        defaultKeys(): string[] {
            return getOngoingTimeSlotKeys(
                this.timeSeriesFrom,
                this.timeSeriesTo,
                this.chartTimeInterval,
                userSession.timeZone,
                true
            );
        },

        externalBdcCdrRowGroups(): RowGroup<string, ComputedExternalBdcCdrRow>[] {
            const filteredRows = this.computedExternalBdcCdrRow.filter(
                (row) =>
                    this.timeSeriesFrom.getTime() <= row.begin.getTime() &&
                    row.begin.getTime() <= this.timeSeriesTo.getTime()
            );

            const rowGroups = groupRowsBy(filteredRows, (r) => r.beginOngoingTimeSlot);
            const patchedRowGroups = addMissingRowGroups(rowGroups, this.defaultKeys);

            return applyKeySort(patchedRowGroups, this.defaultKeys);
        },

        table(): ReportingTableData | null {
            if (!this.chart) {
                return null;
            }

            return {
                ...getReportingTableByReportingTimeSeriesChart(this.chart),
                groupByHeaderText: this.$t("Zeitraum") as string,
            };
        },
    },

    methods: {
        dateFormatter(date: Date, short: boolean): string {
            return getOngoingTimeSlotLabel(date, this.chartTimeInterval, userSession.timeZone, short ? "S" : "L") || "";
        },

        hideBottomSheet() {
            this.bottomSheetVisible = false;
            this.bottomSheetExternalBdcCdrIds = [];
        },

        showBottomSheetOnClick(externalBdcCdrIds: number[]): () => void {
            return () => {
                this.bottomSheetExternalBdcCdrIds = [...new Set(externalBdcCdrIds)];
                this.bottomSheetVisible = true;
            };
        },
    },

    components: {
        ExternalBdcCdrsBottomSheet,
        ReportingDashboardTimeSeriesTile,
    },
});

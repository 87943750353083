import { BillingAccountSearchOrder, BillingAccountSearchRequest } from "@/api/billingAccountSearch";

export const EMPTY_SEARCH_REQUEST: BillingAccountSearchRequest = Object.freeze({
    billingAccountId: null,
    dealerIds: [],
    partitionIds: [],
    paymentMethods: [],
    hasReceivers: null,
    hasContracts: null,
    hasUnpaidInvoices: null,
    createdFrom: null,
    createdTo: null,
    sortBy: BillingAccountSearchOrder.CREATED_DESC,
});

import axios from "axios";

export interface StorageStats {
    readonly total: number;
    readonly totalSize: number;
    readonly unused: number;
    readonly unusedSize: number;
}

interface StorageApi {
    getStorageStats(): Promise<StorageStats>;
    removeUnusedData(): Promise<void>;
}

export const storageApi: StorageApi = {
    async getStorageStats() {
        return (await axios.get("/api/storage")).data;
    },

    removeUnusedData() {
        return axios.delete("/api/storage");
    },
};


import { ExternalBdcCdr } from "@/api/externalBdcCdrs";
import ReportingDashboardTile from "@/app/pages/reporting/dashboard/ReportingDashboardTile.vue";
import { WithBilledMinutes, WithDuration } from "@/app/pages/reporting/externalbdc/externalBdcRowUtils";
import { avg, renderDuration, sum } from "@/app/pages/reporting/reportingUtils";
import Vue from "vue";

type PrecomputedExternalBdcCdrRow = ExternalBdcCdr & WithBilledMinutes & WithDuration;

export default Vue.extend({
    props: {
        externalBdcCdrRows: {
            type: Array as () => readonly PrecomputedExternalBdcCdrRow[],
            required: true,
        },
        minHeight: {
            type: Number,
            required: false,
        },
        loading: {
            type: Boolean,
            required: true,
        },
        small: {
            type: Boolean,
            default: false,
        },
        subtitle: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            renderDuration,
        };
    },

    computed: {
        billedMinutesAvg(): number | null {
            return avg(this.externalBdcCdrRows.map((r) => r.billedMinutes)) ?? null;
        },

        billedMinutesSum(): number | null {
            return sum(this.externalBdcCdrRows.map((r) => r.billedMinutes)) ?? null;
        },
    },

    components: {
        ReportingDashboardTile,
    },
});


import {
    incomingEmailBlacklistApi,
    IncomingEmailBlacklistEntryData,
    IncomingEmailBlacklistEntryType,
} from "@/api/incomingEmailBlacklist";
import EnumField from "@/app/components/EnumField.vue";
import { notEmpty } from "@/app/validation";
import Vue from "vue";

export default Vue.extend({
    props: {
        incomingEmailBlacklistEntryData: {
            type: Object as () => IncomingEmailBlacklistEntryData,
        },
    },

    data() {
        return {
            form: this.incomingEmailBlacklistEntryData
                ? this.incomingEmailBlacklistEntryData
                : {
                      type: IncomingEmailBlacklistEntryType.SUBJECT_CONTAINS,
                      value: "",
                  },
            IncomingEmailBlacklistEntryType,
            isWorking: false,
        };
    },

    computed: {
        notEmpty() {
            return notEmpty;
        },
    },

    methods: {
        close() {
            this.$emit("close");
        },

        async submitForm() {
            if (!(this.$refs.form as any).validate()) {
                return;
            }

            this.isWorking = true;

            try {
                await incomingEmailBlacklistApi.addBlacklistEntry(this.form);
                this.$emit("success");
            } finally {
                this.isWorking = false;
            }
        },
    },

    components: {
        EnumField,
    },
});

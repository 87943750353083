
import ContractOverviewDataTableRow from "./ContractOverviewDataTableRow.vue";
import { BillingAccount, billingAccountsApi } from "@/api/billingAccounts";
import { Contract, contractsApi, ContractSearchOrder, ContractSearchRequest } from "@/api/contracts";
import { Dealer, dealersApi } from "@/api/dealers";
import { partitionsApi, PartitionSummary } from "@/api/partitions";
import DataTable from "@/app/components/DataTable.vue";
import DatePicker from "@/app/components/DatePicker.vue";
import EnumField from "@/app/components/EnumField.vue";
import { DataTableHeader } from "@/app/components/dataTable";
import { showConfirm } from "@/app/messageUtil";
import { configStore } from "@/store/config";
import { userSession } from "@/store/userSession";
import { toDateObject } from "@/util/dateTimeUtils";
import { SelectOption } from "@/util/types/SelectOptions";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            billingAccounts: [] as BillingAccount[],
            configStore,
            contracts: [] as Contract[],
            ContractSearchOrder,
            dealers: [] as Dealer[],
            deleting: false,
            loadFilter: {
                dealerIds: [] as string[],
                partitionIds: [] as string[],
                hasCommissionBillingAccountId: null as boolean | null,
                commissionPercentageFrom: null as string | null,
                commissionPercentageTo: null as string | null,
                createdFrom: null as string | null,
                createdTo: null as string | null,
                beginFrom: null as string | null,
                beginTo: null as string | null,
                endFrom: null as string | null,
                endTo: null as string | null,
                sortBy: ContractSearchOrder.BEGIN_DESC as ContractSearchOrder,
            },
            loading: true,
            partitions: [] as PartitionSummary[],
            searchCounter: 0,
            showDealerWithContracts: null as boolean | null,
        };
    },

    computed: {
        contractSearchRequest(): ContractSearchRequest {
            return {
                ...this.loadFilter,
                dealerIds: this.contractSearchRequestDealerIds,
                createdFrom: this.loadFilter.createdFrom
                    ? toDateObject(this.timeZone, this.loadFilter.createdFrom)
                    : null,
                createdTo: this.loadFilter.createdTo ? toDateObject(this.timeZone, this.loadFilter.createdTo, 1) : null,
            };
        },

        contractSearchRequestDealerIds(): string[] {
            const partitionDealerIds = this.loadFilter.partitionIds
                .map((partitionId) => this.dealers.filter((d) => d.partitionId === partitionId).map((d) => d.id))
                .reduce((prev, cur) => prev.concat(cur), []);

            return partitionDealerIds.length && this.loadFilter.dealerIds.length
                ? partitionDealerIds.filter((dealerId) => this.loadFilter.dealerIds.includes(dealerId))
                : partitionDealerIds.length
                ? partitionDealerIds
                : this.loadFilter.dealerIds;
        },

        dealerOptions(): SelectOption[] {
            return this.dealers
                .map((d) => ({ value: d.id, text: d.name }))
                .sort((a, b) => a.text.localeCompare(b.text, userSession.locale));
        },

        headers(): DataTableHeader[] {
            return [
                {
                    text: this.$t("Verträge"),
                    width: "100%",
                },
            ];
        },

        working(): boolean {
            return this.loading || this.deleting;
        },

        items(): Dealer[] {
            if (this.loading) {
                return [];
            }

            return this.dealers
                .filter(
                    (d) =>
                        !this.contractSearchRequestDealerIds.length ||
                        this.contractSearchRequestDealerIds.includes(d.id)
                )
                .filter(
                    (d) =>
                        this.showDealerWithContracts === null ||
                        this.showDealerWithContracts === this.contracts.some((c) => c.dealerId === d.id)
                );
        },

        partitionOptions(): SelectOption[] {
            return this.partitions
                .map((p) => ({ value: p.id, text: p.name }))
                .sort((a, b) => a.text.localeCompare(b.text, userSession.locale));
        },

        timeZone(): string {
            return configStore.configuration.organisationTimeZone;
        },
    },

    methods: {
        async deleteContract(id: number) {
            if (
                !(await showConfirm(
                    this.$t("Vertrag löschen") as string,
                    this.$t("Sind Sie sicher, dass Sie den Vertrag entfernen möchten?") as string
                ))
            ) {
                return;
            }

            this.deleting = true;
            try {
                await contractsApi.delete(id);
                await this.refresh();
            } finally {
                this.deleting = false;
            }
        },

        getContractsByDealer(dealerId: string): Contract[] {
            return this.contracts.filter((c) => c.dealerId === dealerId);
        },

        getPartitionById(partitionId: string): PartitionSummary | null {
            return this.partitions.find((p) => p.id === partitionId) ?? null;
        },

        async loadItems() {
            this.loading = true;

            try {
                const [billingAccounts, contractSearchResult, dealers, partitions] = await Promise.all([
                    billingAccountsApi.list(),
                    contractsApi.search(0, 10_000_000, this.contractSearchRequest, ++this.searchCounter),
                    dealersApi.list(),
                    partitionsApi.list(),
                ]);

                if (contractSearchResult.searchId === this.searchCounter) {
                    this.billingAccounts = billingAccounts;
                    this.contracts = contractSearchResult.results;
                    this.dealers = dealers;
                    this.partitions = partitions;
                    this.loading = false;
                }
            } catch (e) {
                this.loading = false;
                throw e;
            }
        },

        async refresh() {
            await this.loadItems();
        },
    },

    watch: {
        loadFilter: {
            deep: true,
            async handler() {
                try {
                    await this.refresh();
                } catch (e) {
                    this.$nextTick(() => {
                        throw e;
                    });
                }
            },
        },
    },

    async mounted() {
        await this.loadItems();
    },

    components: {
        ContractOverviewDataTableRow,
        DataTable,
        DatePicker,
        EnumField,
    },
});


import { EChartsLegendSelectChangedEvent, EChartsMouseEvent, formatAxisValueLabel } from "./eChartsUtils";
import { getColoredChartSeries } from "./reportingChart";
import { renderTooltip, ReportingChartTooltipCache } from "./reportingChartTooltip";
import {
    ReportingScatterChartAxis,
    ReportingScatterChartCategory,
    ReportingScatterChartSeries,
} from "./reportingScatterChart";
import { trimAndReturnNullIfEmpty } from "@/util/stringUtils";
import {
    DatasetComponentOption,
    DefaultLabelFormatterCallbackParams,
    EChartsOption,
    ScatterSeriesOption,
    XAXisComponentOption as XAXisOption,
    YAXisComponentOption as YAXisOption,
} from "echarts";
import Vue from "vue";

type XValueAxisOption = Extract<XAXisOption, { type?: "value" }>;
type YValueAxisOption = Extract<YAXisOption, { type?: "value" }>;

interface DataValue {
    readonly seriesIndex: number;
    readonly valueIndex: number;
    readonly name: string;
    readonly xValue: number;
    readonly yValue: number;
    readonly color: string | undefined;
}

export default Vue.extend({
    props: {
        categories: {
            type: Array as () => ReportingScatterChartCategory[],
            required: true,
        },
        height: {
            type: Number,
            required: false,
        },
        hideLegend: {
            type: Boolean,
            default: false,
        },
        series: {
            type: Array as () => ReportingScatterChartSeries[],
            required: true,
        },
        seriesAdditionalTooltipDataTooltipHeaders: {
            type: Array as () => string[],
            required: false,
        },
        seriesDataTooltipHeader: {
            type: String,
            required: false,
        },
        xAxis: {
            type: Array as () => ReportingScatterChartAxis[],
            default: () => [],
        },
        yAxis: {
            type: Array as () => ReportingScatterChartAxis[],
            default: () => [],
        },
    },

    data() {
        return {
            eChartGridRightMargin: 20,
            eChartGridTopMargin: 20,
            selected: this.series.filter((s) => s.selected !== false).map((s) => s.name),
            tooltipCache: {} as ReportingChartTooltipCache,
        };
    },

    computed: {
        eChartsDataSet(): DatasetComponentOption[] {
            return this.series.map((series, seriesIndex) => {
                const source: DataValue[] = series.data.values.map((value, valueIndex) => ({
                    seriesIndex,
                    valueIndex,
                    name: this.categories[valueIndex].name,
                    xValue: value.xValue,
                    yValue: value.yValue,
                    color: value.color,
                }));

                return {
                    id: series.id,
                    dimensions: ["seriesIndex", "valueIndex", "name", "xValue", "yValue", "color"],
                    source,
                };
            });
        },

        eChartGridBottomMargin(): number {
            return this.hideLegend ? 40 : 60;
        },

        eChartGridLeftMargin(): number {
            const hasYAxisName = this.eChartYAxisOptions
                .filter((axis) => this.series.some((s) => s.data.yAxisId === axis.id && this.isSeriesSelected(s.name)))
                .some((axis) => !!axis.name);

            return hasYAxisName ? 30 : 5;
        },

        eChartHeight(): number {
            if (this.height) {
                return this.height;
            } else {
                return 500;
            }
        },

        eChartsOption(): EChartsOption {
            return {
                animation: false,
                grid: {
                    top: `${this.eChartGridTopMargin}px`,
                    left: `${this.eChartGridLeftMargin}px`,
                    right: `${this.eChartGridRightMargin}px`,
                    bottom: `${this.eChartGridBottomMargin}px`,
                    containLabel: true,
                },
                xAxis: this.eChartXAxisOptions,
                yAxis: this.eChartYAxisOptions,
                tooltip: {
                    trigger: "item",
                    formatter: (params: any) => {
                        return this.renderTooltip(params as DefaultLabelFormatterCallbackParams) ?? "";
                    },
                },
                axisPointer: {
                    type: "none",
                },
                legend: {
                    show: !this.hideLegend,
                    type: "scroll",
                    bottom: "0px",
                    height: "30px",
                    itemWidth: 12,
                    itemHeight: 12,
                    textStyle: {
                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                        fontSize: "11px",
                    },
                    selected: this.series.reduce((selected, s) => {
                        selected[s.name] = this.isSeriesSelected(s.name);
                        return selected;
                    }, {} as { [key: string]: boolean }),
                    tooltip: {
                        show: true,
                        align: "center",
                        formatter: (params: any) => this.series.find((s) => s.name === params.name)?.tooltip ?? "",
                    },
                },
                dataset: this.eChartsDataSet,
                series: this.eChartSeries,
            };
        },

        eChartSeries(): ScatterSeriesOption[] {
            return getColoredChartSeries(this.series)
                .map((series) => ({
                    ...series,
                    xAxis: this.xAxis.find((axis) => axis.id === series.data.xAxisId)!,
                    yAxis: this.yAxis.find((axis) => axis.id === series.data.yAxisId)!,
                }))
                .filter((series) => !!series.xAxis && !!series.yAxis)
                .map((series) => {
                    const option: ScatterSeriesOption = {
                        id: series.id,
                        name: series.name,
                        type: "scatter",
                        xAxisId: series.xAxis.id,
                        yAxisId: series.yAxis.id,
                        datasetId: series.id,
                        encode: { x: "xValue", y: "yValue" },
                        color: series.color,
                        cursor: series.data.values.some((v) => v.onClick) ? "pointer" : "normal",
                        itemStyle: {
                            color: (param: any) => param.value.color ?? param.color,
                        },
                        markArea: {
                            silent: true,
                            data: [
                                ...(series.data.xAxisMarkers ?? [])
                                    .filter((marker) => marker.fillArea === "LEFT" || marker.fillArea === "RIGHT")
                                    .map((marker) => [
                                        { xAxis: marker.fillArea === "LEFT" ? undefined : marker.value },
                                        {
                                            xAxis: marker.fillArea === "LEFT" ? marker.value : undefined,
                                            itemStyle: {
                                                color: marker.color,
                                                opacity: marker.opacity ?? 0.05,
                                            },
                                        },
                                    ]),
                                ...(series.data.yAxisMarkers ?? [])
                                    .filter((marker) => marker.fillArea === "BELOW" || marker.fillArea === "ABOVE")
                                    .map((marker) => [
                                        { yAxis: marker.fillArea === "BELOW" ? undefined : marker.value },
                                        {
                                            yAxis: marker.fillArea === "BELOW" ? marker.value : undefined,
                                            itemStyle: {
                                                color: marker.color,
                                                opacity: marker.opacity ?? 0.05,
                                            },
                                        },
                                    ]),
                            ] as any,
                        },
                        markLine: {
                            silent: true,
                            symbol: "none",
                            data: [
                                ...(series.data.xAxisMarkers ?? []).map((marker) => ({
                                    label: {
                                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                                        fontSize: "11px",
                                        formatter: ({ value }: DefaultLabelFormatterCallbackParams) =>
                                            this.formatAxisValueLabel(value as number, series.xAxis) ?? "",
                                    },
                                    lineStyle: {
                                        type: "solid",
                                        color: marker.color,
                                    },
                                    xAxis: marker.value,
                                })),
                                ...(series.data.yAxisMarkers ?? []).map((marker) => ({
                                    label: {
                                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                                        fontSize: "11px",
                                        formatter: ({ value }: DefaultLabelFormatterCallbackParams) =>
                                            this.formatAxisValueLabel(value as number, series.yAxis) ?? "",
                                    },
                                    lineStyle: {
                                        type: "solid",
                                        color: marker.color,
                                    },
                                    yAxis: marker.value,
                                })),
                            ] as any,
                        },
                    };

                    return option;
                })
                .filter((series): series is ScatterSeriesOption => !!series);
        },

        eChartXAxisOptions(): XValueAxisOption[] {
            return this.xAxis
                .filter((axis, index, array) => array.findIndex((v) => v.id === axis.id) === index)
                .map((axis) => this.getValueAxisOption("X", axis) as XValueAxisOption)
                .map((a, index) => ({
                    ...a,
                    alignTicks: index === 0 || undefined,
                }));
        },

        eChartYAxisOptions(): YValueAxisOption[] {
            return this.yAxis
                .filter((axis, index, array) => array.findIndex((v) => v.id === axis.id) === index)
                .map((axis) => this.getValueAxisOption("Y", axis) as YValueAxisOption)
                .map((a, index) => ({
                    ...a,
                    alignTicks: index === 0 || undefined,
                }));
        },
    },

    methods: {
        formatAxisValueLabel(value: number, axis: ReportingScatterChartAxis): string | null {
            const label = axis.formatter
                ? trimAndReturnNullIfEmpty(axis.formatter(value, !!axis.isPercentage, "LABEL"))
                : null;

            if (label !== null) {
                return label;
            }

            return trimAndReturnNullIfEmpty(formatAxisValueLabel(value, !!axis?.isPercentage));
        },

        getChartAsPngDataUrl(): string | null {
            return (this.$refs.chart as any).getChartAsPngDataUrl();
        },

        getChartAsSvgDataUrl(): string | null {
            return (this.$refs.chart as any).getChartAsSvgDataUrl();
        },

        getValueAxisOption(axisType: "X" | "Y", axis: ReportingScatterChartAxis): XValueAxisOption | YValueAxisOption {
            const show = axis.show ?? true;

            const markerValues = this.series
                .filter((s) => this.isSeriesSelected(s.name))
                .filter(
                    (s) =>
                        (axisType === "X" && s.data.xAxisId === axis.id) ||
                        (axisType === "Y" && s.data.yAxisId === axis.id)
                )
                .map((s) => (axisType === "X" ? s.data.xAxisMarkers : s.data.yAxisMarkers))
                .map((markers) => (markers ?? []).map((m) => m.value))
                .reduce((prev, cur) => prev.concat(cur), []);

            const roundingScaleFactor = axis?.isPercentage ? 100 : 1;
            const step = (axis?.interval ?? 5) / roundingScaleFactor;

            return {
                id: axis.id,
                type: "value",
                name: show ? axis?.name : undefined,
                nameLocation: "middle",
                nameGap: 0,
                nameTextStyle: {
                    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                    fontSize: "11px",
                    padding: axisType === "X" ? [30, 0, 0, 0] : [0, 0, 30, 0],
                },
                axisLabel: {
                    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                    fontSize: "11px",
                    formatter: (value: number) => (show ? this.formatAxisValueLabel(value, axis) : null) ?? "",
                },
                interval: show ? axis?.interval : 0,
                splitLine: {
                    show,
                },
                min: (extent: { min: number }) =>
                    Math.min(
                        ...[extent.min, axis?.min ?? 0, ...markerValues]
                            .map((v) => v * roundingScaleFactor)
                            .map((v) => step * Math.ceil(v / step))
                            .map((v) => v / roundingScaleFactor)
                    ),
                max: (extent: { max: number }) =>
                    Math.max(
                        ...[extent.max, axis?.max, ...markerValues]
                            .filter((v): v is number => (v ?? null) !== null)
                            .map((v) => v * roundingScaleFactor)
                            .map((v) => step * Math.floor(v / step))
                            .map((v) => step + v / roundingScaleFactor)
                    ),
            };
        },

        isSeriesSelected(seriesName: string): boolean {
            return this.selected.includes(seriesName);
        },

        onLegendSelectChanged(e: EChartsLegendSelectChangedEvent) {
            this.selected = Object.keys(e.selected).filter((name) => e.selected[name]);
        },

        async onValueClick(e: EChartsMouseEvent<DataValue>) {
            if (e.componentType !== "series" || e.seriesType !== "scatter") {
                return;
            }

            const value = this.series[e.data.seriesIndex].data.values[e.data.valueIndex];

            if (!value.onClick) {
                return;
            }

            await value.onClick();
        },

        renderTooltip(params: DefaultLabelFormatterCallbackParams): string | null {
            const dataValue = params.value as DataValue;
            const series = this.series[dataValue.seriesIndex];
            const value = series.data.values[dataValue.valueIndex];
            const category = this.categories[dataValue.valueIndex];

            const xAxis = this.xAxis.find((axis) => axis.id === series.data.xAxisId);
            const yAxis = this.yAxis.find((axis) => axis.id === series.data.yAxisId);

            if (!xAxis || !yAxis) {
                return null;
            }

            return renderTooltip(
                {
                    additionalValueHeaders: this.seriesAdditionalTooltipDataTooltipHeaders,
                    series: [
                        {
                            name: xAxis.name ?? "",
                            marker: (params.marker as string) ?? "",
                            value: {
                                value: value.xValue,
                                isPercentage: !!xAxis.isPercentage,
                                formatter: (value: number | undefined, isPercentage: boolean) =>
                                    xAxis.formatter ? xAxis.formatter(value, isPercentage, "TOOLTIP") : null,
                            },
                            additionalTooltipValues: (series.additionalTooltipData ?? []).map((adtData) => {
                                const adtXAxis = this.xAxis.find((axis) => axis.id === adtData.xAxisId);

                                return {
                                    value: adtData.values[dataValue.valueIndex].xValue,
                                    isPercentage: !!adtXAxis?.isPercentage,
                                    formatter: (value: number | undefined, isPercentage: boolean) =>
                                        adtXAxis?.formatter ? adtXAxis.formatter(value, isPercentage, "TOOLTIP") : null,
                                };
                            }),
                            group: null,
                        },
                        {
                            name: yAxis.name ?? "",
                            marker: (params.marker as string) ?? "",
                            value: {
                                value: value.yValue,
                                isPercentage: !!yAxis.isPercentage,
                                formatter: (value: number | undefined, isPercentage: boolean) =>
                                    yAxis.formatter ? yAxis.formatter(value, isPercentage, "TOOLTIP") : null,
                            },
                            additionalTooltipValues: (series.additionalTooltipData ?? []).map((adtData) => {
                                const adtYAxis = this.yAxis.find((axis) => axis.id === adtData.yAxisId);

                                return {
                                    value: adtData.values[dataValue.valueIndex].yValue,
                                    isPercentage: !!adtYAxis?.isPercentage,
                                    formatter: (value: number | undefined, isPercentage: boolean) =>
                                        adtYAxis?.formatter ? adtYAxis.formatter(value, isPercentage, "TOOLTIP") : null,
                                };
                            }),
                            group: null,
                        },
                    ],
                    title: category.name,
                    subtitle: category.description,
                    valueHeader: this.seriesDataTooltipHeader,
                },
                this.tooltipCache,
                this.$i18n
            );
        },
    },

    watch: {
        series(__, oldSeries: ReportingScatterChartSeries[]) {
            const oldSeriesNames = oldSeries.map((s) => s.name);

            this.selected = this.series
                .filter(
                    (s) => this.selected.includes(s.name) || (s.selected !== false && !oldSeriesNames.includes(s.name))
                )
                .map((s) => s.name);
        },
    },

    components: {
        LazyEChart: () => import("./LazyEChart.vue"),
    },
});

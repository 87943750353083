
import {
    incomingEmailBlacklistApi,
    IncomingEmailBlacklistEntry,
    IncomingEmailBlacklistEntryData,
    IncomingEmailBlacklistEntryType,
} from "@/api/incomingEmailBlacklist";
import EnumField from "@/app/components/EnumField.vue";
import { showConfirm } from "@/app/messageUtil";
import CrudTablePage from "@/app/pages/CrudTablePage.vue";
import { TableHeader } from "@/app/pages/crudPage";
import { notEmpty } from "@/app/validation";
import { userSession } from "@/store/userSession";
import { SelectOption } from "@/util/types";
import Vue from "vue";

interface SortBy {
    readonly value: "TYPE" | "VALUE" | "MATCH_COUNT" | "LAST_MATCH";
    readonly direction: "ASC" | "DESC";
}

export default Vue.extend({
    data() {
        return {
            IncomingEmailBlacklistEntryType,
            defaultSortBy: { value: "LAST_MATCH", direction: "DESC" } as SortBy,
        };
    },

    computed: {
        headers(): TableHeader[] {
            return [
                {
                    id: "type",
                    text: this.$t("Typ"),
                    width: "20%",
                },
                {
                    id: "value",
                    text: this.$t("Text"),
                    width: "40%",
                },
                {
                    id: "matchCount",
                    text: this.$t("Anzahl Matches"),
                    width: "20%",
                },
                {
                    id: "lastMatch",
                    text: this.$t("Letzter Match"),
                    width: "20%",
                },
            ];
        },

        notEmpty() {
            return notEmpty;
        },

        sortOptions(): SelectOption[] {
            return [
                {
                    value: { value: "TYPE", direction: "DESC" },
                    text: this.$t("Typ (absteigend)"),
                },
                {
                    value: { value: "TYPE", direction: "ASC" },
                    text: this.$t("Typ (aufsteigend)"),
                },
                {
                    value: { value: "VALUE", direction: "DESC" },
                    text: this.$t("Text (absteigend)"),
                },
                {
                    value: { value: "VALUE", direction: "ASC" },
                    text: this.$t("Text (aufsteigend)"),
                },
                {
                    value: { value: "MATCH_COUNT", direction: "DESC" },
                    text: this.$t("Anzahl Matches (absteigend)"),
                },
                {
                    value: { value: "MATCH_COUNT", direction: "ASC" },
                    text: this.$t("Anzahl Matches (aufsteigend)"),
                },
                {
                    value: { value: "LAST_MATCH", direction: "DESC" },
                    text: this.$t("Letzter Match (absteigend)"),
                },
                {
                    value: { value: "LAST_MATCH", direction: "ASC" },
                    text: this.$t("Letzter Match (aufsteigend)"),
                },
            ];
        },
    },

    methods: {
        async addIncomingEmailBlacklistEntry(form: IncomingEmailBlacklistEntryData) {
            try {
                await incomingEmailBlacklistApi.addBlacklistEntry(form);
                return true;
            } catch (e) {
                return false;
            }
        },

        async deleteIncomingEmailBlacklistEntry(item: IncomingEmailBlacklistEntry) {
            if (
                await showConfirm(
                    this.$t("Backlist-Eintrag löschen") as string,
                    this.$t("Sind Sie sicher, dass Sie den Eintrag löschen möchten?") as string
                )
            ) {
                await incomingEmailBlacklistApi.deleteBlacklistEntry(item.id);
                return true;
            }
            return false;
        },

        emptyForm(): IncomingEmailBlacklistEntryData {
            return {
                type: IncomingEmailBlacklistEntryType.SUBJECT_CONTAINS,
                value: "",
            };
        },

        async getIncomingEmailBlacklistEntries(sortBy: SortBy | null) {
            let sortFn: (a: IncomingEmailBlacklistEntry, b: IncomingEmailBlacklistEntry) => number = () => 0;

            if (sortBy === null) {
                // keep default
            } else if (sortBy.value === "TYPE") {
                sortFn = (a, b) => {
                    const aType = this.$t(`enum.IncomingEmailBlacklistEntryType.${a.data.type}`) as string;
                    const bType = this.$t(`enum.IncomingEmailBlacklistEntryType.${b.data.type}`) as string;

                    return aType.localeCompare(bType, userSession.locale);
                };
            } else if (sortBy.value === "VALUE") {
                sortFn = (a, b) => a.data.value.localeCompare(b.data.value, userSession.locale);
            } else if (sortBy.value === "MATCH_COUNT") {
                sortFn = (a, b) => a.matchCount - b.matchCount;
            } else if (sortBy.value === "LAST_MATCH") {
                sortFn = (a, b) => {
                    if (a.lastMatch === b.lastMatch) {
                        return 0;
                    } else if (!a.lastMatch) {
                        return -1;
                    } else if (!b.lastMatch) {
                        return 1;
                    } else {
                        return a.lastMatch!.getTime() - b.lastMatch!.getTime();
                    }
                };
            }

            const directedSortFn = (a: IncomingEmailBlacklistEntry, b: IncomingEmailBlacklistEntry) =>
                sortFn(a, b) * (sortBy?.direction === "DESC" ? -1 : 1);

            return (await incomingEmailBlacklistApi.list()).sort(directedSortFn);
        },
    },

    components: {
        CrudTablePage,
        EnumField,
    },
});


import RegionField from "./RegionField.vue";
import UserShowPasswordDialog from "./UserShowPasswordDialog.vue";
import { BadRequest, Forbidden } from "@/api/errors";
import { Gender, Role, User, UserForm, usersApi } from "@/api/users";
import BirthdayPicker from "@/app/components/BirthdayPicker.vue";
import CountryPicker from "@/app/components/CountryPicker.vue";
import EnumField from "@/app/components/EnumField.vue";
import NumberField from "@/app/components/NumberField.vue";
import PhoneNumberField from "@/app/components/PhoneNumberField.vue";
import StatePicker from "@/app/components/StatePicker.vue";
import TimeZonePicker from "@/app/components/TimeZonePicker.vue";
import { readTextAsFile } from "@/app/fileUtils";
import { showError } from "@/app/messageUtil";
import { email, integer, maxLength, notEmpty, ValidationHelper } from "@/app/validation";
import { configStore } from "@/store/config";
import { userSession } from "@/store/userSession";
import { cloneObject } from "@/util/cloneUtils";
import { trimAndReturnNullIfEmpty } from "@/util/stringUtils";
import { Mutable } from "@/util/types";
import { SelectOptions } from "@/util/types/SelectOptions";
import Vue from "vue";
import VImageInput from "vuetify-image-input";

const JPEG_DATA_URI_PREFIX = "data:image/jpeg;base64,";

export default Vue.extend({
    data() {
        const validationHelper = new ValidationHelper();
        return {
            validationHelper,
            loading: true,
            password: null as string | null,
            profileImageUri: null as string | null,
            profileImageFileFormat: "jpeg",
            saving: false,
            user: null as User | null,
            userForm: {
                username: "",
                gender: null,
                namePrefix: null,
                givenName: "",
                familyName: "",
                roles: [],
                birthday: null,
                locale: configStore.configuration.defaultLocale,
                timeZone: configStore.configuration.organisationTimeZone,
                weeklyWorkingHours: null,
                employeeNumber: null,
                emailAddress: null,
                phoneNumbers: [],
                address1: null,
                address2: null,
                zip: null,
                city: null,
                state: null,
                country: null,
                citnowConversationsUserIds: [],
            } as Mutable<UserForm>,
            userId: null as string | null,

            citnowConversationsUserIdRules: notEmpty()
                .maxLength(24)
                .custom((v) => validationHelper.validate(`citnowConversationsUserIds[${v}]`, v)),
            usernameRules: notEmpty()
                .maxLength(63)
                .msg(() => this.$t("Gültiger Benutzername ist erforderlich"))
                .and(validationHelper, "username"),
            namePartRules: notEmpty().maxLength(300),

            Gender,
            email,
            integer,
            notEmpty,
            maxLength,
        };
    },

    computed: {
        defaultCountry(): string {
            return configStore.configuration.organisationCountry;
        },

        role(): SelectOptions {
            return Object.keys(Role).map((k) => ({ value: k, text: this.$t(`enum.Role.${k}`) }));
        },

        userTimeZone(): string {
            return userSession.timeZone;
        },
    },

    methods: {
        addCitnowConversationsUserId() {
            this.userForm.citnowConversationsUserIds.push("");
        },

        addPhoneNumber() {
            this.userForm.phoneNumbers.push({
                number: "",
                label: "",
            });
        },

        goToFirstError() {
            this.$nextTick(() => {
                try {
                    this.$vuetify.goTo(".error--text");
                } catch (e) {
                    // ignore
                }
            });
        },

        removeCitnowConversationsUserId(index: number) {
            this.userForm.citnowConversationsUserIds.splice(index, 1);
        },

        removePhoneNumber(index: number) {
            this.userForm.phoneNumbers.splice(index, 1);
        },

        async submitForm() {
            if (!(this.$refs.form as any).validate()) {
                this.goToFirstError();
                return;
            }

            this.saving = true;
            try {
                const profileImageFile = this.profileImageUri
                    ? await readTextAsFile(this.profileImageUri, "profile-image." + this.profileImageFileFormat)
                    : null;

                if (this.userId) {
                    await usersApi.edit(this.userId, this.userForm, profileImageFile, () => null);

                    await this.$router.push("/users");
                } else {
                    this.password = await usersApi.add(this.userForm, profileImageFile, () => null);
                }
            } catch (e) {
                if (e instanceof Forbidden) {
                    if (this.userId) {
                        showError(
                            this.$t(
                                "Sie haben nicht die Berechtigung, die Benutzereinstellungen zu verändern."
                            ) as string
                        );
                    } else {
                        showError(
                            this.$t(
                                "Sie haben nicht die Berechtigung, den Benutzer mit diesen Rollen anzulegen."
                            ) as string
                        );
                    }
                    return;
                }

                if (!(e instanceof BadRequest)) {
                    throw e;
                }

                this.validationHelper.update(e, this.$refs.form);
                this.goToFirstError();
            } finally {
                this.saving = false;
            }
        },
    },

    async mounted() {
        try {
            this.userId = trimAndReturnNullIfEmpty(this.$route.params.userid);

            if (this.userId) {
                this.user = await usersApi.getById(this.userId);
            }

            if (this.user) {
                this.userForm = cloneObject(this.user);

                if (this.user.profileImageHash) {
                    this.profileImageUri =
                        JPEG_DATA_URI_PREFIX +
                        (await usersApi.getProfileImageByHash(this.user.id, this.user.profileImageHash));
                }
            }
        } finally {
            this.loading = false;
        }
    },

    components: {
        BirthdayPicker,
        CountryPicker,
        EnumField,
        NumberField,
        PhoneNumberField,
        RegionField,
        StatePicker,
        TimeZonePicker,
        UserShowPasswordDialog,
        VImageInput,
    },
});


import { getCountryCaption } from "@/app/countryStateUtils";
import { configStore } from "@/store/config";
import { SelectOptions } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    props: {
        value: String,
    },

    computed: {
        countries(): SelectOptions {
            return [
                ...configStore.configuration.countries
                    .map((k) => ({
                        value: k.code,
                        text: getCountryCaption(k.code),
                    }))
                    .sort((a, b) => a.text.localeCompare(b.text, this.$i18n.locale)),
            ];
        },
    },
});

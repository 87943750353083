
import { getStateCaption, getStateLabel, getStates } from "@/app/countryStateUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        value: {
            type: String,
        },
        country: {
            type: String,
            required: false,
        },
    },

    data() {
        return {
            state: this.value as string | null,
        };
    },

    computed: {
        stateLabel() {
            return getStateLabel(this.country);
        },
    },

    methods: {
        handleInput(state: string | null) {
            this.state = state;
            this.$emit("input", this.state);
        },

        getStatesByCountry(countryCode: string | null) {
            const statesSelectOptions = getStates(countryCode).map((k) => ({
                value: k,
                text: k === "" ? "" : getStateCaption(countryCode!, k),
            }));

            statesSelectOptions.sort((a, b) => (a.text as string).localeCompare(b.text as string, this.$i18n.locale));

            return statesSelectOptions;
        },
    },

    watch: {
        value(value: string) {
            this.state = value;
        },
    },
});

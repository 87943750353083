
import { ExternalBdcCdr } from "@/api/externalBdcCdrs";
import ReportingDashboardTile from "@/app/pages/reporting/dashboard/ReportingDashboardTile.vue";
import { WithWaited } from "@/app/pages/reporting/externalbdc/externalBdcRowUtils";
import { avg, rate, renderDuration, renderPercentage } from "@/app/pages/reporting/reportingUtils";
import Vue from "vue";

type PrecomputedExternalBdcCdrRow = ExternalBdcCdr & WithWaited;

export default Vue.extend({
    props: {
        externalBdcCdrRows: {
            type: Array as () => readonly PrecomputedExternalBdcCdrRow[],
            required: true,
        },
        loading: {
            type: Boolean,
            required: true,
        },
        minHeight: {
            type: Number,
            required: false,
        },
        small: {
            type: Boolean,
            default: false,
        },
        subtitle: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            renderDuration,
            renderPercentage,
        };
    },

    computed: {
        acceptedCallsWaitedAvg(): number | null {
            return avg(this.externalBdcCdrRows.filter((r) => r.accept).map((r) => r.waited)) ?? null;
        },

        acceptanceRate(): number | null {
            return rate(this.externalBdcCdrRows.filter((r) => r.accept).length, this.externalBdcCdrRows.length) ?? null;
        },
    },

    components: {
        ReportingDashboardTile,
    },
});

import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export interface CallCenterStatisticsRequest {
    readonly from: Date;
    readonly to: Date;
    readonly waitingTimeThresholdSecondsForConsideringUnansweredCalls: number;
}

export interface CallCenterStatistics {
    readonly from: Date;
    readonly to: Date;
    readonly callCenterStatisticsData: CallCenterStatisticsData;
}

export interface CallCenterStatisticsData {
    readonly calls: number;
    readonly callsAccepted: number;
    readonly billedMinutes: number;
    readonly unacceptedCallsAfterThreshold: number;
    readonly issuesCreated: number;
    readonly activitiesCreated: number;
    readonly contactsCreated: number;
    readonly errorsReported: number;
    readonly scheduledSeconds: number;
    readonly workedSeconds: number;
}

interface CallCenterStatisticsApi {
    getCallCenterStatistics(request: CallCenterStatisticsRequest, secret: string): Promise<CallCenterStatistics>;
    getDailyCallCenterStatisticsByMonth(
        yearMonth: string,
        waitingTimeThresholdSecondsForConsideringUnansweredCalls: number
    ): Promise<CallCenterStatistics[]>;
    getHourlyCallCenterStatisticsByDate(
        date: string,
        waitingTimeThresholdSecondsForConsideringUnansweredCalls: number
    ): Promise<CallCenterStatistics[]>;
}

export const callCenterStatisticsApi: CallCenterStatisticsApi = {
    async getCallCenterStatistics(request, secret) {
        return cloneObject((await axios.post("/api/call-center-statistics", request, { params: { secret } })).data);
    },

    async getDailyCallCenterStatisticsByMonth(yearMonth, waitingTimeThresholdSecondsForConsideringUnansweredCalls) {
        const parts = yearMonth.split("-").map((v) => Number.parseInt(v));

        const year = parts[0];
        const month = parts[1];

        return cloneObject(
            (
                await axios.get(`/api/call-center-statistics/${year}/${month}/daily`, {
                    params: { waitingTimeThresholdSecondsForConsideringUnansweredCalls },
                })
            ).data
        );
    },

    async getHourlyCallCenterStatisticsByDate(date, waitingTimeThresholdSecondsForConsideringUnansweredCalls) {
        const parts = date.split("-").map((v) => Number.parseInt(v));

        const year = parts[0];
        const month = parts[1];
        const dayOfMonth = parts[2];

        return cloneObject(
            (
                await axios.get(`/api/call-center-statistics/${year}/${month}/${dayOfMonth}/hourly`, {
                    params: { waitingTimeThresholdSecondsForConsideringUnansweredCalls },
                })
            ).data
        );
    },
};

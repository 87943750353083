import ReportingChartTooltip from "./ReportingChartTooltip.vue";
import Vue from "vue";

export interface ReportingChartTooltipCache {
    tooltipId?: string;
    renderedTooltip?: string;
}

export interface ReportingChartTooltipComponentProps {
    readonly additionalValueHeaders?: string[];
    readonly series: ReportingChartTooltipSeries[];
    readonly title?: string;
    readonly subtitle?: string;
    readonly valueHeader?: string;
}

export interface ReportingChartTooltipSeries {
    readonly name: string;
    readonly marker: string;
    readonly value: ReportingChartTooltipSeriesValue;
    readonly additionalTooltipValues?: ReportingChartTooltipSeriesValue[];
    readonly group: string | null;
}

export interface ReportingChartTooltipSeriesValue {
    readonly value: number | undefined;
    readonly isPercentage: boolean;
    readonly formatter?: (value: number | undefined, isPercentage: boolean) => string | null;
}

export function renderTooltip(
    props: ReportingChartTooltipComponentProps,
    cache: ReportingChartTooltipCache,
    i18n: any
): string | null {
    const tooltipId = JSON.stringify(props);

    if (cache.tooltipId !== tooltipId) {
        const tooltipComponent = new Vue({
            i18n,
            render: (h) => h(ReportingChartTooltip, { props }),
        });

        cache.renderedTooltip = tooltipComponent.$mount().$el?.innerHTML;
        cache.tooltipId = tooltipId;

        tooltipComponent.$destroy();
    }

    return cache.renderedTooltip ?? null;
}

import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export enum ActorType {
    USER_OR_SYSTEM = "USER_OR_SYSTEM",
    CT_USER = "CT_USER",
}

export enum EmergencyIncidentType {
    BREAKDOWN = "BREAKDOWN",
    ACCIDENT = "ACCIDENT",
}

export enum EmergencyIncidentSummarySearchOrder {
    CREATED_DESC = "CREATED_DESC",
    CREATED_ASC = "CREATED_ASC",
    LAST_ACTIVITY_DESC = "LAST_ACTIVITY_DESC",
    LAST_ACTIVITY_ASC = "LAST_ACTIVITY_ASC",
}

export interface EmergencyIncidentSummary {
    readonly partitionId: string;
    readonly data: EmergencyIncidentSummaryData;
}

export interface EmergencyIncidentSummaryData {
    readonly id: string;
    readonly created: Date;
    readonly creatorCtUserId: string | null;
    readonly dealerId: string;
    readonly type: EmergencyIncidentType;
    readonly contactCaption: string | null;
    readonly contactVehicleCaption: string | null;
    readonly lastActivity: string | null;
    readonly activitiesCount: number;
    readonly caller: string | null;
    readonly damage: string | null;
    readonly location: string | null;
    readonly serviceProvider: string | null;
    readonly towingTarget: string | null;
}

export interface EmergencyIncidentSummarySearchRequest {
    readonly creatorActorType: ActorType[];
    readonly sortBy: EmergencyIncidentSummarySearchOrder;
}

interface EmergencyIncidentSummariesApi {
    search(searchRequest: EmergencyIncidentSummarySearchRequest): Promise<EmergencyIncidentSummary[]>;
}

export const emergencyIncidentSummariesApi: EmergencyIncidentSummariesApi = {
    async search(searchRequest) {
        return cloneObject((await axios.post("/api/emergency-incident-summaries/_search", searchRequest)).data);
    },
};


import { FeedbackType } from "@/api/actions";
import { ActionRow } from "@/api/reporting";
import ActionsBottomSheet from "@/app/pages/action/ActionsBottomSheet.vue";
import { getReportingTableByReportingBarChart } from "@/app/pages/reporting/chartUtils";
import { hasNonZeroSeries, ReportingBarChartData } from "@/app/pages/reporting/charts/reportingBarChart";
import ReportingDashboardBarTile from "@/app/pages/reporting/dashboard/ReportingDashboardBarTile.vue";
import { ReportingTableData } from "@/app/pages/reporting/table/reportingTable";
import Vue from "vue";

export default Vue.extend({
    props: {
        actionRows: {
            type: Array as () => readonly ActionRow[],
            required: true,
        },
        chartHeight: {
            type: Number,
            required: false,
        },
        loading: {
            type: Boolean,
            required: true,
        },
        small: {
            type: Boolean,
            default: false,
        },
        subtitle: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            required: false,
        },
    },

    data() {
        return {
            bottomSheetActionIds: [] as number[],
            bottomSheetVisible: false,
        };
    },

    computed: {
        chart(): ReportingBarChartData | null {
            const feedbackRows = this.actionRows.filter((r) => r.feedbackCreated);

            const topCategories = (Object.keys(FeedbackType) as FeedbackType[])
                .map((feedbackType) => ({
                    categoryId: feedbackType,
                    name: this.$t(`enum.FeedbackType.${feedbackType}`) as string,
                    rows: feedbackRows.filter((r) => r.feedbackType === feedbackType),
                }))
                .map((c) => ({
                    ...c,
                    sortValue: c.rows.length,
                }))
                .sort((a, b) => (b.sortValue ?? 0) - (a.sortValue ?? 0));

            const chart: ReportingBarChartData = {
                title: "",
                categories: topCategories.map((c) => ({ name: c.name })),
                series: [
                    {
                        id: "count",
                        name: this.$t("Anzahl") as string,
                        data: {
                            values: topCategories.map((c) => ({
                                value: c.rows.length,
                                onClick: this.showBottomSheetOnClick(c.rows.map((r) => r.id)),
                            })),
                        },
                    },
                ],
            };

            if (!hasNonZeroSeries(chart)) {
                return null;
            }

            return chart;
        },

        table(): ReportingTableData | null {
            if (!this.chart) {
                return null;
            }

            return {
                ...getReportingTableByReportingBarChart(this.chart),
                groupByHeaderText: this.$t("Art des Feedbacks") as string,
            };
        },
    },

    methods: {
        hideBottomSheet() {
            this.bottomSheetVisible = false;
            this.bottomSheetActionIds = [];
        },

        showBottomSheetOnClick(actionIds: number[]): () => void {
            return () => {
                this.bottomSheetActionIds = [...new Set(actionIds)];
                this.bottomSheetVisible = true;
            };
        },
    },

    components: {
        ActionsBottomSheet,
        ReportingDashboardBarTile,
    },
});

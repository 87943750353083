
import { incomingEmailSummariesOverviewState } from "./incomingEmailSummariesOverview";
import {
    incomingEmailsApi,
    IncomingEmailSummary,
    IncomingEmailSummaryCount,
    IncomingEmailSummaryCountType,
    IncomingEmailSummarySearchOrder,
} from "@/api/incomingEmails";
import { partitionsApi, PartitionSummary } from "@/api/partitions";
import { Permission } from "@/api/userSession";
import CopyToClipboardIcon from "@/app/components/CopyToClipboardIcon.vue";
import DataTable from "@/app/components/DataTable.vue";
import DatePicker from "@/app/components/DatePicker.vue";
import EnumField from "@/app/components/EnumField.vue";
import { DataTableHeader, DataTablePaging } from "@/app/components/dataTable";
import IconWithTooltip from "@/app/pages/IconWithTooltip.vue";
import { userSession } from "@/store/userSession";
import { formatInstant } from "@/util/dateTimeUtils";
import { PickMutable } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    data() {
        const itemsPerPage = 100;

        return {
            counts: [] as IncomingEmailSummaryCount[],
            incomingEmailsOverviewState: incomingEmailSummariesOverviewState,
            IncomingEmailSummaryCountType,
            IncomingEmailSummarySearchOrder,
            items: [] as IncomingEmailSummary[],
            itemsPerPage,
            loading: true,
            paging: {
                page: 1,
                pageSize: itemsPerPage,
                totalSize: 0,
                maxTotalSize: 1_000_000,
                maxPage: 10_000 / itemsPerPage,
            } as PickMutable<DataTablePaging, "page" | "totalSize">,
            partitions: [] as PartitionSummary[],
            searchCounter: 1,
        };
    },

    computed: {
        canManageIncomingEmails(): boolean {
            return userSession.hasPermission(Permission.MANAGE_INCOMING_EMAILS);
        },

        countHeaders(): DataTableHeader[] {
            return [
                {
                    text: this.$t(`enum.IncomingEmailSummaryCountType.${this.incomingEmailsOverviewState.countType}`),
                    width: "70%",
                },
                {
                    text: this.$t("Anzahl"),
                    width: "30%",
                },
            ];
        },

        summaryHeaders(): DataTableHeader[] {
            return [
                {
                    text: this.$t("ID"),
                    width: "20%",
                },
                {
                    text: this.$t("Absendername"),
                    width: "20%",
                },
                {
                    text: this.$t("Absenderadresse"),
                    width: "30%",
                },
                {
                    text: this.$t("Betreff"),
                    width: "30%",
                },
            ];
        },
    },

    methods: {
        getIncomingEmailLink(incomingEmailId: string, partitionId: string): string | null {
            const host = this.partitions.find((p) => p.id === partitionId)?.host;

            if (!host) {
                return null;
            }

            return `https://${host}/incoming-email/${incomingEmailId}`;
        },

        getPartitionLink(partitionId: string): string | null {
            const host = this.partitions.find((p) => p.id === partitionId)?.host;

            if (!host) {
                return null;
            }

            return `https://${host}/dealerstubs`;
        },

        getPartitionNameById(partitionId: string) {
            return this.partitions.find((p) => p.id === partitionId)?.name || this.$t("Unbekannte Partition");
        },

        getTimestamp(date: Date): string {
            return formatInstant(date, userSession.timeZone, userSession.locale, "S");
        },

        async loadItems() {
            this.paging.totalSize = 0;
            this.items = [];
            this.loading = true;
            try {
                const incomingEmailSummarySearchResults = await incomingEmailsApi.search(
                    (this.paging.page - 1) * this.itemsPerPage,
                    this.itemsPerPage,
                    this.incomingEmailsOverviewState.searchRequest,
                    ++this.searchCounter
                );

                if (incomingEmailSummarySearchResults.searchId === this.searchCounter) {
                    this.counts = incomingEmailSummarySearchResults.counts;
                    this.items = incomingEmailSummarySearchResults.results;
                    this.paging.totalSize = incomingEmailSummarySearchResults.totalSize;
                    this.loading = false;
                }
            } catch (e) {
                this.loading = false;
                this.paging.page = 1;
                throw e;
            }
        },

        async page(paging: DataTablePaging) {
            this.paging = { ...paging };

            await this.loadItems();
        },

        refresh() {
            this.incomingEmailsOverviewState.refresh();
        },

        searchGroupedValue(value: string | null) {
            if (!value) {
                return;
            }

            if (this.incomingEmailsOverviewState.countType === IncomingEmailSummaryCountType.FROM_ADDRESS) {
                this.incomingEmailsOverviewState.fromAddress = value;
            } else if (this.incomingEmailsOverviewState.countType === IncomingEmailSummaryCountType.FROM_NAME) {
                this.incomingEmailsOverviewState.fromName = value;
            }
        },
    },

    watch: {
        async "incomingEmailsOverviewState.searchRequest"() {
            this.paging.page = 1;
            try {
                await this.loadItems();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },
    },

    async mounted() {
        if (!this.incomingEmailsOverviewState.initialized) {
            this.incomingEmailsOverviewState.setTodayQuickfilter();
        }

        this.partitions = await partitionsApi.list();

        await this.loadItems();
    },

    components: {
        CopyToClipboardIcon,
        DataTable,
        DatePicker,
        EnumField,
        IconWithTooltip,
    },
});


import { BillingAccount } from "@/api/billingAccounts";
import { InvoiceWithCreditTransfer } from "@/api/creditTransfers";
import { configStore } from "@/store/config";
import Vue from "vue";

export default Vue.extend({
    props: {
        invoiceWithCreditTransfer: {
            type: Object as () => InvoiceWithCreditTransfer,
            required: true,
        },
        billingAccounts: {
            type: Array as () => BillingAccount[],
            required: true,
        },
    },

    computed: {
        organisationCurrency(): string {
            return configStore.configuration.organisationCurrency;
        },
    },

    methods: {
        getCompanyName(invoice: InvoiceWithCreditTransfer) {
            return this.billingAccounts.find((a) => a.id === invoice.invoice.billingAccountId)?.companyName;
        },
    },
});

import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export interface AgentStatistics {
    readonly userId: string;
    readonly from: Date;
    readonly to: Date;
    readonly agentStatisticsData: AgentStatisticsData;
}

export interface AgentStatisticsData {
    readonly callsAccepted: number;
    readonly callsDuration: number;
    readonly billedMinutes: number;
    readonly issuesCreated: number;
    readonly issuesCreatedDuringCall: number;
    readonly issuesCreatedAfterCall: number;
    readonly issuesCreatedNoCall: number;
    readonly secondsIssuesCreatedAfterCall: number;
    readonly secondsIssuesCreatedAfterCallMedian: number | null;
    readonly activitiesCreated: number;
    readonly contactsCreated: number;
    readonly feedbackIssuesCreated: number;
    readonly feedbackActivitiesCreated: number;
    readonly errorsReported: number;
    readonly scheduledDays: DutyRosterEntryTypeStatisticsData;
    readonly scheduledSeconds: DutyRosterEntryTypeStatisticsData;
    readonly elapsedSeconds: DutyRosterEntryTypeStatisticsData;
}

export interface DutyRosterEntryTypeStatisticsData {
    readonly answerIncomingOrdinaryCalls: number;
    readonly answerIncomingPriorityCalls: number;
    readonly training: number;
    readonly otherWork: number;
    readonly holiday: number;
    readonly overtimeCompensation: number;
    readonly otherTimeCompensation: number;
    readonly sickness: number;
    readonly sickChild: number;
    readonly otherUnpaidAbsence: number;
}

interface AgentStatisticsApi {
    getAgentStatistics(from: string, to: string): Promise<AgentStatistics[]>;
    getDailyAgentStatisticsByMonth(userId: string, yearMonth: string): Promise<AgentStatistics[]>;
    getHourlyAgentStatisticsByDate(userId: string, date: string): Promise<AgentStatistics[]>;
}

export const agentStatisticsApi: AgentStatisticsApi = {
    async getAgentStatistics(from, to) {
        return cloneObject((await axios.get("/api/agent-statistics", { params: { from, to } })).data);
    },

    async getDailyAgentStatisticsByMonth(userId, yearMonth) {
        const parts = yearMonth.split("-").map((v) => Number.parseInt(v));

        const year = parts[0];
        const month = parts[1];

        return cloneObject((await axios.get(`/api/agent-statistics/${userId}/${year}/${month}/daily`)).data);
    },

    async getHourlyAgentStatisticsByDate(userId, date) {
        const parts = date.split("-").map((v) => Number.parseInt(v));

        const year = parts[0];
        const month = parts[1];
        const dayOfMonth = parts[2];

        return cloneObject(
            (await axios.get(`/api/agent-statistics/${userId}/${year}/${month}/${dayOfMonth}/hourly`)).data
        );
    },
};

import { Gender, User } from "@/api/users";
import { userSession } from "@/store/userSession";

interface UserFullNameParts {
    readonly gender: Gender | null;
    readonly namePrefix: string | null;
    readonly givenName: string;
    readonly familyName: string;
}

export function getFullName(fullNameParts: UserFullNameParts) {
    const fullName = `${fullNameParts.givenName} ${fullNameParts.familyName}`;
    if (fullNameParts.namePrefix) {
        return `${fullNameParts.namePrefix} ${fullName}`;
    }
    return fullName;
}

export function getSortedByUserFullName<T>(items: T[], getUserId: (t: T) => string, users: User[]) {
    return [...items].sort((a, b) => {
        const userA = users.find((u) => u.id === getUserId(a));
        const userB = users.find((u) => u.id === getUserId(b));

        if (userA === userB) {
            return 0;
        } else if (!userA) {
            return 1;
        } else if (!userB) {
            return -1;
        }

        return getFullName(userA).localeCompare(getFullName(userB), userSession.locale);
    });
}

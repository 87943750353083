
import { now } from "@/store/now";
import { getDate } from "@/util/dateTimeUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        value: {
            type: String,
            required: false,
        },
        timeZone: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            birthdayPickerMenu: false,
            birthday: this.value,
            now: now(),
        };
    },

    computed: {
        currentDate(): string {
            return getDate(this.now, this.timeZone);
        },
    },

    watch: {
        birthdayPickerMenu(val) {
            val && setTimeout(() => ((this.$refs.picker as any).activePicker = "YEAR"));
        },
    },

    methods: {
        save(birthday: string) {
            (this.$refs.birthdayPickerMenu as any).save(birthday);
        },

        handleInput(v: string | null) {
            if (v === null) {
                this.birthday = "";
            }
            this.$emit("input", this.birthday);
        },
    },
});

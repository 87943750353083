import axios from "axios";

export interface BwaMonth {
    readonly month: string;
    readonly fromInvoiceId: number | null;
    readonly toInvoiceId: number | null;
}

interface BwaApi {
    generateBwaMonthInvoicesExcelLink(month: string): string;
    getAll(): Promise<BwaMonth[]>;
}

export const bwaApi: BwaApi = {
    generateBwaMonthInvoicesExcelLink(month) {
        return `/api/bwa/${encodeURIComponent(month)}/_invoice-export`;
    },

    async getAll() {
        return (await axios.get("/api/bwa")).data;
    },
};

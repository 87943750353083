
import BillingAccountFormDialog from "./BillingAccountFormDialog.vue";
import BillingAccountsDataTableRow from "./BillingAccountsDataTableRow.vue";
import { EMPTY_SEARCH_REQUEST } from "./billingAccountSearchUtils";
import {
    billingAccountSearchApi,
    BillingAccountSearchOrder,
    BillingAccountSearchRequest,
} from "@/api/billingAccountSearch";
import { BillingAccount, billingAccountsApi, PaymentMethod } from "@/api/billingAccounts";
import { Dealer, dealersApi } from "@/api/dealers";
import { partitionsApi, PartitionSummary } from "@/api/partitions";
import DataTable from "@/app/components/DataTable.vue";
import DatePicker from "@/app/components/DatePicker.vue";
import EnumField from "@/app/components/EnumField.vue";
import NumberField from "@/app/components/NumberField.vue";
import { DataTableHeader, DataTablePaging } from "@/app/components/dataTable";
import { getStateCaption } from "@/app/countryStateUtils";
import { showConfirm } from "@/app/messageUtil";
import { userSession } from "@/store/userSession";
import { cloneObject } from "@/util/cloneUtils";
import { getDate, toDateObject } from "@/util/dateTimeUtils";
import { Mutable, PickMutable, SelectOption } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            billingAccountFormDialogVisible: false,
            BillingAccountSearchOrder,
            dealers: [] as Dealer[],
            deleting: false,
            getStateCaption,
            items: [] as BillingAccount[],
            loading: true,
            paging: {
                page: 1,
                pageSize: 100,
                totalSize: 0,
                maxTotalSize: Number.MAX_SAFE_INTEGER,
                maxPage: Number.MAX_SAFE_INTEGER,
            } as PickMutable<DataTablePaging, "page" | "totalSize">,
            PaymentMethod,
            partitions: [] as PartitionSummary[],
            searchId: 1,
            searchRequest: cloneObject(EMPTY_SEARCH_REQUEST) as Mutable<BillingAccountSearchRequest>,
        };
    },

    computed: {
        createdFrom(): string | null {
            return this.searchRequest.createdFrom ? getDate(this.searchRequest.createdFrom, this.timeZone) : null;
        },

        createdTo(): string | null {
            return this.searchRequest.createdTo ? getDate(this.searchRequest.createdTo, this.timeZone, -1) : null;
        },

        dealerOptions(): SelectOption[] {
            return this.dealers
                .map((d) => ({ value: d.id, text: d.name }))
                .sort((a, b) => a.text.localeCompare(b.text, userSession.locale));
        },

        headers(): DataTableHeader[] {
            return [
                {
                    text: this.$t("Abrechnungskonto"),
                    width: "20%",
                },
                {
                    text: this.$t("Rechnungsanschrift"),
                    width: "30%",
                },
                {
                    text: this.$t("Zahlungsmethode"),
                    width: "20%",
                },
                {
                    text: this.$t("Rechnungsempfänger"),
                    width: "20%",
                },
                {
                    text: this.$t("Aktionen"),
                    align: "right",
                    width: "10%",
                },
            ];
        },

        partitionOptions(): SelectOption[] {
            return this.partitions
                .map((p) => ({ value: p.id, text: p.name }))
                .sort((a, b) => a.text.localeCompare(b.text, userSession.locale));
        },

        timeZone(): string {
            return userSession.timeZone;
        },
    },

    methods: {
        async deleteBillingAccount(id: number) {
            if (
                !(await showConfirm(
                    this.$t("Abrechnungskonto löschen") as string,
                    this.$t("Sind Sie sicher, dass Sie das Abrechnungskonto löschen möchten ?") as string
                ))
            ) {
                return;
            }

            this.deleting = true;
            try {
                await billingAccountsApi.delete(id);
                await this.refresh();
            } finally {
                this.deleting = false;
            }
        },

        async hideBillingAccountFormDialog(added: boolean) {
            this.billingAccountFormDialogVisible = false;

            if (!added) {
                return;
            }

            await this.refresh();
        },

        async loadItems() {
            this.paging.totalSize = 0;
            this.items = [];
            this.loading = true;
            try {
                const searchResults = await billingAccountSearchApi.search(
                    (this.paging.page - 1) * this.paging.pageSize,
                    this.paging.pageSize,
                    this.searchRequest,
                    ++this.searchId
                );

                if (searchResults.searchId === this.searchId) {
                    this.items = searchResults.results;
                    this.paging.totalSize = searchResults.totalSize;
                    this.loading = false;
                }
            } catch (e) {
                this.loading = false;
                this.paging.page = 1;
                throw e;
            }
        },

        async page(paging: DataTablePaging) {
            this.paging = { ...paging };

            await this.loadItems();
        },

        refresh() {
            this.searchRequest = { ...this.searchRequest };
        },

        async refreshBillingAccount(id: number) {
            const searchId = ++this.searchId;
            this.loading = true;
            try {
                const billingAccount = await billingAccountsApi.getById(id);
                const items = this.items.map((item) => (item.id !== billingAccount?.id ? item : billingAccount));

                if (searchId === this.searchId) {
                    this.items = items;
                }
            } finally {
                if (searchId === this.searchId) {
                    this.loading = false;
                }
            }
        },

        setCreatedFrom(createdFrom: string | null) {
            this.searchRequest.createdFrom = createdFrom ? toDateObject(this.timeZone, createdFrom) : null;
        },

        setCreatedTo(createdTo: string | null) {
            this.searchRequest.createdTo = createdTo ? toDateObject(this.timeZone, createdTo, 1) : null;
        },
    },

    watch: {
        searchRequest: {
            deep: true,
            async handler() {
                this.paging.page = 1;
                try {
                    await this.loadItems();
                } catch (e) {
                    this.$nextTick(() => {
                        throw e;
                    });
                }
            },
        },
    },

    async mounted() {
        this.dealers = await dealersApi.list();
        this.partitions = await partitionsApi.list();

        await this.loadItems();
    },

    components: {
        BillingAccountFormDialog,
        BillingAccountsDataTableRow,
        DataTable,
        DatePicker,
        EnumField,
        NumberField,
    },
});


import AgentDayDataTable from "./AgentDayDataTable.vue";
import { User, usersApi } from "@/api/users";
import DatePicker from "@/app/components/DatePicker.vue";
import { getFullName } from "@/app/userUtils";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { getDate } from "@/util/dateTimeUtils";
import { SelectOption } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            loading: true,
            loadFilter: {
                date: getDate(now(), userSession.timeZone),
                userId: userSession.id!,
            },
            users: [] as User[],
        };
    },

    computed: {
        userOptions(): SelectOption[] {
            return this.users
                .map((u) => ({ value: u.id, text: getFullName(u) }))
                .sort((a, b) => a.text.localeCompare(b.text, userSession.locale));
        },
    },

    methods: {
        async refresh() {
            await (this.$refs.agentDayDataTable as any).refresh();
        },
    },

    async mounted() {
        this.users = await usersApi.list();
    },

    components: {
        AgentDayDataTable,
        DatePicker,
    },
});

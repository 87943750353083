
import { FeedbackType } from "@/api/actions";
import { Dealer } from "@/api/dealers";
import { PartitionSummary } from "@/api/partitions";
import { ActionRow } from "@/api/reporting";
import { User } from "@/api/users";
import ActionsBottomSheet from "@/app/pages/action/ActionsBottomSheet.vue";
import { mapActionRowKeyToRowLabel } from "@/app/pages/reporting/action/actionRowUtils";
import { getReportingTableByReportingBarChart } from "@/app/pages/reporting/chartUtils";
import { hasNonZeroSeries, ReportingBarChartData } from "@/app/pages/reporting/charts/reportingBarChart";
import { ReportingChartColorPalette } from "@/app/pages/reporting/charts/reportingChart";
import ReportingDashboardBarTile from "@/app/pages/reporting/dashboard/ReportingDashboardBarTile.vue";
import { addMissingRowGroups, groupRowsBy } from "@/app/pages/reporting/pivotUtils";
import { TitledRowGroup } from "@/app/pages/reporting/rowUtils";
import { ReportingTableData } from "@/app/pages/reporting/table/reportingTable";
import Vue from "vue";

type ActionRowGroupBy = keyof Pick<ActionRow, "dealerId" | "partitionId" | "userId">;

export default Vue.extend({
    props: {
        actionRows: {
            type: Array as () => readonly ActionRow[],
            required: true,
        },
        chartHeight: {
            type: Number,
            required: false,
        },
        dealers: {
            type: Array as () => readonly Dealer[],
            required: true,
        },
        defaultGroupIds: {
            type: Array as () => string[],
            default: () => [],
        },
        groupBy: {
            type: String as () => "DEALER" | "PARTITION" | "USER",
            required: true,
        },
        loading: {
            type: Boolean,
            required: true,
        },
        partitions: {
            type: Array as () => readonly PartitionSummary[],
            required: true,
        },
        small: {
            type: Boolean,
            default: false,
        },
        subtitle: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            required: false,
        },
        users: {
            type: Array as () => readonly User[],
            required: true,
        },
    },

    data() {
        return {
            bottomSheetActionIds: [] as number[],
            bottomSheetTitle: null as string | null,
            bottomSheetVisible: false,
        };
    },

    computed: {
        chart(): ReportingBarChartData | null {
            const topCategories = this.rowGroups
                .map((rowGroup) => ({
                    categoryId: rowGroup.key,
                    name: rowGroup.title,
                    description: rowGroup.subtitle,
                    rows: rowGroup.rows.filter((r) => r.feedbackCreated),
                }))
                .map((c) => ({
                    ...c,
                    sortValue: c.rows.length,
                }))
                .filter((c) => c.sortValue || (!!c.categoryId && this.defaultGroupIds.includes(c.categoryId)))
                .sort((a, b) => (b.sortValue ?? 0) - (a.sortValue ?? 0));

            const chart: ReportingBarChartData = {
                title: "",
                categories: topCategories.map((c) => ({ name: c.name, description: c.description })),
                series: (Object.keys(FeedbackType) as FeedbackType[]).map((feedbackType) => ({
                    id: `feedback-type-${FeedbackType}`,
                    name: this.$t(`enum.FeedbackType.${feedbackType}`) as string,
                    data: {
                        values: topCategories.map((c) => ({
                            value: c.rows.filter((r) => r.feedbackType === feedbackType).length,
                            onClick: this.showBottomSheetOnClick(
                                c.rows.filter((r) => r.feedbackType === feedbackType).map((r) => r.id),
                                c.name
                            ),
                        })),
                    },
                    color: feedbackType === FeedbackType.INFO ? "#666666" : undefined,
                    colorPalette: ReportingChartColorPalette.NEGATIVE,
                })),
            };

            if (!hasNonZeroSeries(chart)) {
                return null;
            }

            return chart;
        },

        groupByField(): ActionRowGroupBy | null {
            if (this.groupBy === "DEALER") {
                return "dealerId";
            } else if (this.groupBy === "PARTITION") {
                return "partitionId";
            } else if (this.groupBy === "USER") {
                return "userId";
            } else {
                return null;
            }
        },

        rowGroups(): readonly TitledRowGroup<string | null, ActionRow>[] {
            const rowGroups = this.groupByField ? groupRowsBy(this.actionRows, (r) => r[this.groupByField!]) : [];

            return addMissingRowGroups(rowGroups, this.defaultGroupIds).map((rowGroup) => {
                const rowGroupLabel = mapActionRowKeyToRowLabel(rowGroup.key, this.groupByField!, {
                    dealers: this.dealers,
                    partitions: this.partitions,
                    users: this.users,
                });

                return {
                    ...rowGroup,
                    title: rowGroupLabel.label,
                    subtitle: rowGroupLabel.sublabel,
                };
            });
        },

        table(): ReportingTableData | null {
            if (!this.chart) {
                return null;
            }

            return {
                ...getReportingTableByReportingBarChart(this.chart),
                groupByHeaderText:
                    this.groupBy === "DEALER"
                        ? (this.$t("Standort") as string)
                        : this.groupBy === "PARTITION"
                        ? (this.$t("Partition") as string)
                        : this.groupBy === "USER"
                        ? (this.$t("Benutzer") as string)
                        : undefined,
            };
        },
    },

    methods: {
        hideBottomSheet() {
            this.bottomSheetVisible = false;
            this.bottomSheetTitle = null;
            this.bottomSheetActionIds = [];
        },

        showBottomSheetOnClick(actionIds: number[], title: string): () => void {
            return () => {
                this.bottomSheetActionIds = [...new Set(actionIds)];
                this.bottomSheetTitle = title;
                this.bottomSheetVisible = true;
            };
        },
    },

    components: {
        ActionsBottomSheet,
        ReportingDashboardBarTile,
    },
});

import axios from "axios";

export interface InvoiceDealerRevenue {
    readonly invoiceId: number;
    readonly partitionId: string | null;
    readonly dealerId: string | null;
    readonly invoiceRevenueData: InvoiceRevenueData;
}

export interface InvoiceRevenueData {
    readonly totalRevenue: number;
    readonly softwareRevenue: number;
    readonly fixedBdcRevenue: number;
    readonly variableBdcRevenue: number;
    readonly clickToCallRevenue: number;
    readonly callTrackingRevenue: number;
    readonly outgoingSmsRevenue: number;
    readonly whatsAppNumberRevenue: number;
    readonly setupRevenue: number;
}

interface InvoiceRevenuesApi {
    getByInvoiceIdRange(fromInvoiceId: number, toInvoiceId: number): Promise<InvoiceDealerRevenue[]>;
}

export const invoiceRevenuesApi: InvoiceRevenuesApi = {
    async getByInvoiceIdRange(fromInvoiceId, toInvoiceId) {
        return (
            await axios.get("/api/invoice-revenues", {
                params: {
                    fromInvoiceId,
                    toInvoiceId,
                },
            })
        ).data;
    },
};

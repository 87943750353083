
import { InvoiceRevenueData } from "@/api/invoicerevenues";
import { renderCurrency } from "@/app/filters";
import { getOtherRevenue } from "@/app/pages/invoicerevenue/invoiceRevenueUtils";
import { configStore } from "@/store/config";
import Vue from "vue";

export default Vue.extend({
    props: {
        benefitMonth: {
            type: String as () => string | null,
            default: null,
        },
        benefitMonthCount: {
            type: Number as () => number | null,
            default: null,
        },
        dealerCount: {
            type: Number,
            required: true,
        },
        dealerId: {
            type: String as () => string | null,
            default: null,
        },
        dealerName: {
            type: String as () => string | null,
            default: null,
        },
        isTotal: {
            type: Boolean,
            default: false,
        },
        partitionId: {
            type: String as () => string | null,
            default: null,
        },
        partitionName: {
            type: String as () => string | null,
            default: null,
        },
        revenueData: {
            type: Object as () => InvoiceRevenueData,
            required: true,
        },
        withBenefitMonth: {
            type: Boolean,
            default: false,
        },
        withDealer: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        currency(): string {
            return configStore.configuration.organisationCurrency;
        },

        otherRevenue(): number {
            return getOtherRevenue(this.revenueData);
        },
    },

    methods: {
        formatCurrency(value: number): string {
            return renderCurrency(value, this.currency);
        },
    },
});


import ShiftScheduleTemplateCalendar from "./ShiftScheduleTemplateCalendar.vue";
import ShiftScheduleTemplateDialog from "./ShiftScheduleTemplateDialog.vue";
import {
    ShiftScheduleTemplate,
    ShiftScheduleTemplateEntry,
    ShiftScheduleTemplateForm,
    shiftScheduleTemplatesApi,
} from "@/api/shiftScheduleTemplates";
import { showConfirm } from "@/app/messageUtil";
import { userSession } from "@/store/userSession";
import Vue from "vue";

function toMinutes(time: string) {
    const parts = time.split(":");
    return parseInt(parts[0]) * 60 + parseInt(parts[1]);
}

export default Vue.extend({
    data() {
        return {
            templates: [] as ShiftScheduleTemplate[],
            openTemplate: null as number | null,
            dialogVisible: false,
            template: null as ShiftScheduleTemplate | null,
            working: false,
        };
    },

    methods: {
        addTemplate() {
            this.template = null;
            this.dialogVisible = true;
        },

        editTemplate(template: ShiftScheduleTemplate) {
            this.template = template;
            this.dialogVisible = true;
        },

        async upsertTemplate(form: ShiftScheduleTemplateForm) {
            this.working = true;
            try {
                if (this.template) {
                    await shiftScheduleTemplatesApi.editTemplate(this.template.id, form);
                } else {
                    await shiftScheduleTemplatesApi.addTemplate(form);
                }
                this.dialogVisible = false;
            } finally {
                this.working = false;
            }

            this.openTemplate = null;
            await this.loadTemplates();
        },

        async deleteTemplate(id: string) {
            if (
                await showConfirm(
                    this.$t("Schichtplanvorlage löschen") as string,
                    this.$t("Sind Sie sicher, dass Sie die Vorlage löschen möchten?") as string
                )
            ) {
                this.working = true;
                try {
                    await shiftScheduleTemplatesApi.deleteTemplate(id);
                } finally {
                    this.working = false;
                }
                await this.loadTemplates();
            }
        },

        async loadTemplates() {
            this.working = true;
            try {
                this.templates = (await shiftScheduleTemplatesApi.getTemplates()).sort((a, b) =>
                    a.name.localeCompare(b.name, userSession.locale)
                );
            } finally {
                this.working = false;
            }
        },

        async saveEntries(template: ShiftScheduleTemplate, entries: ShiftScheduleTemplateEntry[]) {
            this.working = true;
            try {
                await shiftScheduleTemplatesApi.editEntries(template.id, entries);
                template.entries = entries;
            } finally {
                this.working = false;
            }
        },

        getHours(template: ShiftScheduleTemplate) {
            return (
                template.entries.reduce(
                    (s, t) => toMinutes(t.endTime) - toMinutes(t.beginTime) - t.includedBreakMinutes + 1 + s,
                    0
                ) / 60
            );
        },
    },

    async mounted() {
        await this.loadTemplates();
    },

    components: {
        ShiftScheduleTemplateCalendar,
        ShiftScheduleTemplateDialog,
    },
});

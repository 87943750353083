import { isTimestampNotification, notificationEventSource } from "@/api/notifications";
import { reactive } from "@/util/reactive";

let correction = 0;

notificationEventSource.addDataHandler((n) => {
    if (isTimestampNotification(n)) {
        correction = n.timestamp.getTime() - new Date().getTime();
        // TODO latency correction
    }
});

@reactive
class Now {
    public value = new Date();
}

const internalNow = new Now();
setInterval(() => (internalNow.value = new Date(new Date().getTime() + correction)), 1000);

export function now() {
    return internalNow.value;
}


import { loaderStore } from "@/store/loader";
import Vue from "vue";

export default Vue.extend({
    computed: {
        failed(): boolean {
            return loaderStore.failed;
        },
    },

    methods: {
        reload() {
            window.location.reload();
        },
    },
});


import ActionOverviewDataTableRow from "./ActionOverviewDataTableRow.vue";
import { Action, actionsApi, ActionSearchRequest } from "@/api/actions";
import { Dealer } from "@/api/dealers";
import { PartitionSummary } from "@/api/partitions";
import { User } from "@/api/users";
import DataTable from "@/app/components/DataTable.vue";
import { DataTableHeader, DataTablePaging } from "@/app/components/dataTable";
import { ActionLimiter } from "@/util/debounce";
import { PickMutable } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    props: {
        dealers: {
            type: Array as () => Dealer[],
            default: () => [],
        },
        loading: {
            type: Boolean,
            default: false,
        },
        partitions: {
            type: Array as () => PartitionSummary[],
            default: () => [],
        },
        searchRequest: {
            type: Object as () => ActionSearchRequest,
            required: true,
        },
        users: {
            type: Array as () => User[],
            default: () => [],
        },
    },

    data() {
        return {
            items: [] as Action[],
            loadingItems: true,
            loadLimiter: new ActionLimiter(true),
            paging: {
                page: 1,
                pageSize: 100,
                totalSize: 0,
                maxTotalSize: Number.MAX_SAFE_INTEGER,
                maxPage: Number.MAX_SAFE_INTEGER,
            } as PickMutable<DataTablePaging, "page" | "totalSize">,
            searchId: 0,
        };
    },

    computed: {
        headers(): DataTableHeader[] {
            return [
                {
                    text: this.$t("Uhrzeit"),
                    width: "8%",
                },
                {
                    text: this.$t("Referenz"),
                    width: "1%",
                },
                {
                    text: this.$t("Art der Aktion"),
                    width: "10%",
                },
                {
                    text: this.$t("Agent"),
                    width: "10%",
                },
                {
                    text: this.$t("Partition"),
                    width: "10%",
                },
                {
                    text: this.$t("Standort"),
                    width: "20%",
                },
                {
                    text: this.$t("Art des Feedbacks"),
                    width: "30%",
                },
            ];
        },
    },

    methods: {
        getDealerById(id: string | null): Dealer | null {
            return this.dealers.find((d) => d.id === id) ?? null;
        },

        getPartitionById(partitionId: string): PartitionSummary | null {
            return this.partitions.find((p) => p.id === partitionId) ?? null;
        },

        getUserById(userId: string): User | null {
            return this.users.find((u) => u.id === userId) ?? null;
        },

        async loadItems() {
            this.paging.totalSize = 0;
            this.items = [];
            this.loadingItems = true;
            const searchId = ++this.searchId;

            await this.loadLimiter.execute(async () => {
                try {
                    const searchResults = await actionsApi.search(
                        (this.paging.page - 1) * this.paging.pageSize,
                        this.paging.pageSize,
                        this.searchRequest,
                        searchId
                    );

                    if (searchId === this.searchId) {
                        this.items = searchResults.results;
                        this.paging.totalSize = searchResults.totalSize;
                    }
                } finally {
                    if (searchId === this.searchId) {
                        this.loadingItems = false;
                    }
                }
            });
        },

        async page(paging: DataTablePaging) {
            this.paging = { ...paging };

            await this.loadItems();
        },
    },

    watch: {
        searchRequest: {
            deep: true,
            immediate: true,
            async handler() {
                this.paging.page = 1;
                try {
                    await this.loadItems();
                } catch (e) {
                    this.$nextTick(() => {
                        throw e;
                    });
                }
            },
        },
    },

    components: {
        ActionOverviewDataTableRow,
        DataTable,
    },
});


import { ExternalBdcCdrRow, reportingApi } from "@/api/reporting";
import DatePicker from "@/app/components/DatePicker.vue";
import NumberField from "@/app/components/NumberField.vue";
import ReportingLineChart from "@/app/pages/reporting/charts/ReportingLineChart.vue";
import { ReportingChartColorPalette } from "@/app/pages/reporting/charts/reportingChart";
import { ReportingLineChartData } from "@/app/pages/reporting/charts/reportingLineChart";
import {
    getOngoingTimeSlot,
    getOngoingTimeSlotLabelByKey,
    OngoingTimeInterval,
} from "@/app/pages/reporting/timeInterval";
import { getTimeSeriesDefaultKeys } from "@/app/pages/reporting/timeSeriesUtils";
import { integer, notEmpty } from "@/app/validation";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { getDate, toDateObject } from "@/util/dateTimeUtils";
import { ActionLimiter } from "@/util/debounce";
import { SelectOption } from "@/util/types";
import Vue from "vue";

interface ComputedExternalBdcCdrRow {
    readonly beginSlotTime: number;
    readonly acceptedSlotTime: number | null;
    readonly endSlotTime: number;
    readonly waited: number;
    readonly userId: string | null;
}

export default Vue.extend({
    data() {
        return {
            chartCategoryAxisScrollWindowHours: 12,
            freeAgentLastCallEndWindowStart: 60,
            freeAgentLastCallEndWindowEnd: 15,
            freeAgentNoNextCallStartWindowEnd: 10,
            integer,
            loadFilter: {
                comparisonDate: getDate(now(), userSession.timeZone),
            },
            loading: true,
            loadLimiter: new ActionLimiter(true),
            notEmpty,
            nowAtLoadRows: null as Date | null,
            OngoingTimeInterval,
            ongoingTimeInterval: OngoingTimeInterval.FIFTEEN_SECONDS,
            rows: [] as readonly ExternalBdcCdrRow[],
            searchId: 0,
            waitingTimeThreshold: 4,
        };
    },

    computed: {
        chart(): ReportingLineChartData | null {
            if (!this.rows.length) {
                return null;
            }

            const windowStartTimeDelta = Math.max(120, this.freeAgentLastCallEndWindowStart);
            const windowEndTimeDelta = Math.max(30, this.freeAgentNoNextCallStartWindowEnd);

            const lastEndFrom = this.freeAgentLastCallEndWindowStart * 1000;
            const lastEndTo = this.freeAgentLastCallEndWindowEnd * 1000;
            const noNextCallStartTo = this.freeAgentNoNextCallStartWindowEnd * 1000;

            const filteredRows = this.computedRows.filter(
                (row) => row.acceptedSlotTime !== null || row.waited >= this.waitingTimeThreshold
            );

            const data = this.defaultKeys
                .map((key) => {
                    const slotT = new Date(key).getTime();

                    // extract rows that are relevant window for the current time slot
                    const overlappingFromSlotT = slotT - 1000 * (1 + windowStartTimeDelta);
                    const overlappingToSlotT = slotT + 1000 * (1 + windowEndTimeDelta);

                    const rowsInWindow = filteredRows.filter(
                        (row) =>
                            (overlappingFromSlotT <= row.beginSlotTime && row.beginSlotTime <= overlappingToSlotT) ||
                            (overlappingFromSlotT <= row.endSlotTime && row.endSlotTime <= overlappingToSlotT) ||
                            (row.acceptedSlotTime !== null && row.acceptedSlotTime <= slotT && slotT <= row.endSlotTime)
                    );

                    return {
                        key,
                        slotT,
                        rowsInWindow,
                    };
                })
                .map((s) => {
                    const slotT = s.slotT;
                    const rowsInWindow = s.rowsInWindow;

                    // extract window of rows that are relevant for the current time slot
                    const rowsOngoing = rowsInWindow.filter(
                        (row) =>
                            row.acceptedSlotTime !== null && row.acceptedSlotTime <= slotT && slotT <= row.endSlotTime
                    );

                    const rowsWaiting = rowsInWindow.filter(
                        (row) =>
                            row.beginSlotTime <= slotT &&
                            (row.acceptedSlotTime !== null ? slotT < row.acceptedSlotTime : slotT <= row.endSlotTime)
                    );

                    return {
                        key: s.key,
                        accepted: rowsInWindow.filter((row) => row.acceptedSlotTime === slotT).length,
                        begin: rowsInWindow.filter((row) => row.beginSlotTime === slotT).length,
                        cancelled: rowsInWindow.filter(
                            (row) => row.acceptedSlotTime === null && row.endSlotTime === slotT
                        ).length,
                        freeAgents:
                            this.ongoingTimeInterval === OngoingTimeInterval.ONE_SECOND
                                ? rowsInWindow
                                      .filter((r) => r.userId)
                                      .filter(
                                          (r) =>
                                              slotT - lastEndFrom <= r.endSlotTime && r.endSlotTime <= slotT - lastEndTo
                                      )
                                      .map((row) => row.userId)
                                      .filter((userId) => !rowsOngoing.some((r) => r.userId === userId))
                                      .filter(
                                          (userId) =>
                                              !rowsInWindow.some(
                                                  (r) =>
                                                      r.userId === userId &&
                                                      slotT - lastEndTo <= r.acceptedSlotTime! &&
                                                      r.acceptedSlotTime! <= slotT + noNextCallStartTo
                                              )
                                      )
                                      .filter((value, index, array) => array.indexOf(value) === index).length
                                : -1,
                        ongoing: rowsOngoing.length,
                        waiting: rowsWaiting.length,
                        waitingAndEventuallyAccepted: rowsWaiting.filter((row) => row.acceptedSlotTime !== null).length,
                        waitingAndEventuallyCancelled: rowsWaiting.filter((row) => row.acceptedSlotTime === null)
                            .length,
                    };
                });

            const chart: ReportingLineChartData = {
                title: this.$t("Anrufaufkommen") as string,
                categories: data.map(
                    (d) => getOngoingTimeSlotLabelByKey(d.key, this.ongoingTimeInterval, userSession.timeZone, "S")!
                ),
                series: [
                    {
                        id: "ongoing",
                        name: this.$t("laufend") as string,
                        data: {
                            values: data.map((d) => ({
                                value: d.ongoing,
                            })),
                        },
                        colorPalette: ReportingChartColorPalette.POSITIVE,
                    },
                    {
                        id: "waiting-and-eventually-accepted",
                        name: this.$t("wartend und schließlich angenommen") as string,
                        selected: false,
                        data: {
                            values: data.map((d) => ({
                                value: d.waitingAndEventuallyAccepted,
                            })),
                        },
                    },
                    {
                        id: "waiting-and-eventually-cancelled",
                        name: this.$t("wartend und schließlich aufgelegt") as string,
                        data: {
                            values: data.map((d) => ({
                                value: d.waitingAndEventuallyCancelled * -1,
                            })),
                        },
                        colorPalette: ReportingChartColorPalette.NEGATIVE,
                    },

                    {
                        id: "begin",
                        name: this.$t("eingetroffen") as string,
                        selected: false,
                        data: {
                            values: data.map((d) => ({
                                value: d.begin,
                            })),
                        },
                    },
                    {
                        id: "accepted",
                        name: this.$t("angenommen") as string,
                        selected: false,
                        data: {
                            values: data.map((d) => ({
                                value: d.accepted,
                            })),
                        },
                    },
                    {
                        id: "cancelled",
                        name: this.$t("aufgelegt") as string,
                        selected: false,
                        data: {
                            values: data.map((d) => ({
                                value: d.cancelled * -1,
                            })),
                        },
                    },
                ],
            };

            if (this.ongoingTimeInterval === OngoingTimeInterval.ONE_SECOND) {
                chart.series.push(
                    {
                        id: "free-agents",
                        name: this.$t("freie Agenten") as string,
                        selected: false,
                        data: {
                            values: data.map((d) => ({
                                value: d.freeAgents,
                            })),
                        },
                    },
                    {
                        id: "lines",
                        name: this.$t("Leitungen") as string,
                        selected: false,
                        data: {
                            values: data.map((d) => ({
                                value: d.ongoing + d.waiting,
                            })),
                        },
                    }
                );
            }

            return chart;
        },

        chartCategoryAxisScrollWindowSize(): number | undefined {
            const secondsPerDataPoint = this.ongoingTimeIntervalSeconds;

            if (!secondsPerDataPoint) {
                return undefined;
            }

            const hoursPerDataPoint = secondsPerDataPoint / 3600;

            return this.chartCategoryAxisScrollWindowHours / hoursPerDataPoint;
        },

        chartCategoryAxisScrollWindowHoursOptions(): SelectOption[] {
            return [
                {
                    text: this.$tc("0 Minuten | 1 Minute | {count} Minuten", 5),
                    value: 5 / 60,
                },
                {
                    text: this.$tc("0 Minuten | 1 Minute | {count} Minuten", 10),
                    value: 10 / 60,
                },
                {
                    text: this.$tc("0 Minuten | 1 Minute | {count} Minuten", 15),
                    value: 15 / 60,
                },
                {
                    text: this.$tc("0 Minuten | 1 Minute | {count} Minuten", 30),
                    value: 30 / 60,
                },
                {
                    text: this.$tc("0 Stunden | 1 Stunde | {count} Stunden", 1),
                    value: 1,
                },
                {
                    text: this.$tc("0 Stunden | 1 Stunde | {count} Stunden", 2),
                    value: 2,
                },
                {
                    text: this.$tc("0 Stunden | 1 Stunde | {count} Stunden", 3),
                    value: 3,
                },
                {
                    text: this.$tc("0 Stunden | 1 Stunde | {count} Stunden", 6),
                    value: 6,
                },
                {
                    text: this.$tc("0 Stunden | 1 Stunde | {count} Stunden", 12),
                    value: 12,
                },
                {
                    text: this.$tc("0 Stunden | 1 Stunde | {count} Stunden", 24),
                    value: 24,
                },
            ];
        },

        computedRows(): readonly ComputedExternalBdcCdrRow[] {
            return this.rows
                .map((row) => {
                    const accepted = row.duration !== null ? new Date(row.begin.getTime() + 1000 * row.waited) : null;
                    const end = new Date(row.begin.getTime() + 1000 * (row.waited + (row.duration ?? 0)));

                    return {
                        begin: row.begin,
                        beginSlotTime: this.toTimeSlot(row.begin).getTime(),
                        waited: row.waited,
                        accepted,
                        acceptedSlotTime: accepted !== null ? this.toTimeSlot(accepted).getTime() : null,
                        end,
                        endSlotTime: this.toTimeSlot(end).getTime(),
                        userId: row.userId,
                    };
                })
                .sort((a, b) => a.beginSlotTime - b.beginSlotTime || a.endSlotTime - b.endSlotTime);
        },

        defaultKeys(): string[] {
            return getTimeSeriesDefaultKeys(
                this.rows,
                (row) => row.begin,
                this.loadFilter.comparisonDate,
                this.loadFilter.comparisonDate,
                this.ongoingTimeInterval,
                this.nowAtLoadRows,
                userSession.timeZone
            );
        },

        ongoingTimeIntervalOptions(): SelectOption[] {
            return [
                {
                    text: this.$tc("0 Sekunden | 1 Sekunde | {count} Sekunden", 1),
                    value: OngoingTimeInterval.ONE_SECOND,
                },
                {
                    text: this.$tc("0 Sekunden | 1 Sekunde | {count} Sekunden", 5),
                    value: OngoingTimeInterval.FIVE_SECONDS,
                },
                {
                    text: this.$tc("0 Sekunden | 1 Sekunde | {count} Sekunden", 10),
                    value: OngoingTimeInterval.TEN_SECONDS,
                },
                {
                    text: this.$tc("0 Sekunden | 1 Sekunde | {count} Sekunden", 15),
                    value: OngoingTimeInterval.FIFTEEN_SECONDS,
                },
                {
                    text: this.$tc("0 Sekunden | 1 Sekunde | {count} Sekunden", 30),
                    value: OngoingTimeInterval.THIRTY_SECONDS,
                },
                {
                    text: this.$tc("0 Minuten | 1 Minute | {count} Minuten", 1),
                    value: OngoingTimeInterval.ONE_MINUTE,
                },
                {
                    text: this.$tc("0 Minuten | 1 Minute | {count} Minuten", 5),
                    value: OngoingTimeInterval.FIVE_MINUTES,
                },
                {
                    text: this.$tc("0 Minuten | 1 Minute | {count} Minuten", 10),
                    value: OngoingTimeInterval.TEN_MINUTES,
                },
                {
                    text: this.$tc("0 Minuten | 1 Minute | {count} Minuten", 15),
                    value: OngoingTimeInterval.FIFTEEN_MINUTES,
                },
                {
                    text: this.$tc("0 Minuten | 1 Minute | {count} Minuten", 30),
                    value: OngoingTimeInterval.THIRTY_MINUTES,
                },
                {
                    text: this.$tc("0 Minuten | 1 Minute | {count} Minuten", 60),
                    value: OngoingTimeInterval.SIXTY_MINUTES,
                },
            ];
        },

        ongoingTimeIntervalSeconds(): number | null {
            let seconds: number | null = null;

            if (this.ongoingTimeInterval === OngoingTimeInterval.ONE_SECOND) {
                seconds = 1;
            } else if (this.ongoingTimeInterval === OngoingTimeInterval.FIVE_SECONDS) {
                seconds = 5;
            } else if (this.ongoingTimeInterval === OngoingTimeInterval.TEN_SECONDS) {
                seconds = 10;
            } else if (this.ongoingTimeInterval === OngoingTimeInterval.FIFTEEN_SECONDS) {
                seconds = 15;
            } else if (this.ongoingTimeInterval === OngoingTimeInterval.THIRTY_SECONDS) {
                seconds = 30;
            } else if (this.ongoingTimeInterval === OngoingTimeInterval.ONE_MINUTE) {
                seconds = 60;
            } else if (this.ongoingTimeInterval === OngoingTimeInterval.FIVE_MINUTES) {
                seconds = 300;
            } else if (this.ongoingTimeInterval === OngoingTimeInterval.TEN_MINUTES) {
                seconds = 600;
            } else if (this.ongoingTimeInterval === OngoingTimeInterval.FIFTEEN_MINUTES) {
                seconds = 900;
            } else if (this.ongoingTimeInterval === OngoingTimeInterval.THIRTY_MINUTES) {
                seconds = 1800;
            } else if (this.ongoingTimeInterval === OngoingTimeInterval.SIXTY_MINUTES) {
                seconds = 3600;
            }

            return seconds;
        },
    },

    methods: {
        async loadRows() {
            this.nowAtLoadRows = now();
            this.rows = [];
            this.loading = true;
            const searchId = ++this.searchId;

            await this.loadLimiter.execute(async () => {
                try {
                    const beginFrom = toDateObject(userSession.timeZone, this.loadFilter.comparisonDate);
                    const beginTo = toDateObject(userSession.timeZone, this.loadFilter.comparisonDate, 1);

                    const rows = await reportingApi.externalBdcCdrRows([], [], beginFrom, beginTo);

                    if (searchId === this.searchId) {
                        this.rows = Object.freeze(rows);
                    }
                } finally {
                    if (searchId === this.searchId) {
                        this.loading = false;
                    }
                }
            });
        },

        toTimeSlot(ts: Date): Date {
            return getOngoingTimeSlot(ts, this.ongoingTimeInterval, userSession.timeZone)!;
        },
    },

    watch: {
        loadFilter: {
            deep: true,
            async handler() {
                try {
                    await this.loadRows();
                } catch (e) {
                    this.$nextTick(() => {
                        throw e;
                    });
                }
            },
        },
    },

    async mounted() {
        await this.loadRows();
    },

    components: {
        DatePicker,
        NumberField,
        ReportingLineChart,
    },
});

import { render, staticRenderFns } from "./Loader.vue?vue&type=template&id=4b7671a5&"
import script from "./Loader.vue?vue&type=script&lang=ts&"
export * from "./Loader.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

import { Dealer, dealersApi } from "@/api/dealers";
import { partitionsApi, PartitionSummary } from "@/api/partitions";
import { WhatsAppAccount, whatsAppAccountsApi } from "@/api/whatsAppAccounts";
import { userSession } from "@/store/userSession";
import { SelectOption } from "@/util/types";
import Vue from "vue";

interface Item {
    readonly number: string;
    readonly displayName?: string;
    readonly clientName?: string;
    readonly url?: string;
    readonly status?: string;
    readonly createdAt?: Date;
    readonly billingStartedAt?: Date;
    readonly cancelledAt?: Date;
    readonly terminatedAt?: Date;
    readonly dealerIds: string[];
}

enum Abnormality {
    NO_DEALER = 1,
    MULTIPLE_DEALERS,
    NO_CHANNEL,
}

function dateCompare(a?: Date, b?: Date) {
    if (!a && !b) {
        return 0;
    }
    if (!a) {
        return 1;
    }
    if (!b) {
        return -1;
    }
    return a.getTime() - b.getTime();
}

export default Vue.extend({
    data() {
        return {
            abnormality: null as Abnormality | null,
            accounts: [] as WhatsAppAccount[],
            dealers: new Map<string, Dealer>(),
            loading: true,
            partitions: new Map<string, PartitionSummary>(),
            statuses: [] as string[],
            Abnormality,
        };
    },

    computed: {
        headers() {
            return [
                {
                    text: this.$t("Nummer"),
                    sortable: true,
                    value: "number",
                    sort: (a: string, b: string) => a.localeCompare(b, userSession.locale),
                },
                {
                    text: this.$t("Display Name"),
                    sortable: true,
                    value: "displayName",
                },
                {
                    text: this.$t("Account"),
                    sortable: true,
                    value: "clientName",
                },
                {
                    text: this.$t("Status"),
                    sortable: true,
                    value: "status",
                    filter: (status?: string) => !this.statuses.length || (!!status && this.statuses.includes(status)),
                },
                {
                    text: this.$t("angelegt am"),
                    sortable: true,
                    value: "createdAt",
                    filter: (createdAt?: Date) => this.abnormality !== Abnormality.NO_CHANNEL || !createdAt,
                    sort: dateCompare,
                },
                {
                    text: this.$t("abgerechnet von 360dialog seit"),
                    sortable: true,
                    value: "billingStartedAt",
                    sort: dateCompare,
                },
                {
                    text: this.$t("Terminierung beantragt am"),
                    sortable: true,
                    value: "cancelledAt",
                    sort: dateCompare,
                },
                {
                    text: this.$t("terminiert am"),
                    sortable: true,
                    value: "terminatedAt",
                    sort: dateCompare,
                },
                {
                    text: this.$t("verknüpfte Standorte"),
                    sortable: false,
                    value: "dealerIds",
                    filter: (dealerIds: string[]) =>
                        (this.abnormality !== Abnormality.NO_DEALER &&
                            this.abnormality !== Abnormality.MULTIPLE_DEALERS) ||
                        (this.abnormality === Abnormality.NO_DEALER && !dealerIds.length) ||
                        (this.abnormality === Abnormality.MULTIPLE_DEALERS && dealerIds.length > 1),
                },
            ];
        },

        items(): Item[] {
            return this.accounts.map((a) => ({
                number: a.number,
                displayName: a.partnerChannelData?.displayName,
                clientName: a.partnerChannelData?.clientName,
                url: a.partnerChannelData?.url,
                status: a.partnerChannelData?.status,
                createdAt: a.partnerChannelData?.createdAt,
                billingStartedAt: a.partnerChannelData?.billingStartedAt ?? undefined,
                cancelledAt: a.partnerChannelData?.cancelledAt ?? undefined,
                terminatedAt: a.partnerChannelData?.terminatedAt ?? undefined,
                dealerIds: a.dealerIds,
            }));
        },

        statusOptions(): string[] {
            return [...new Set(this.items.filter((i) => !!i.status).map((i) => i.status!))].sort();
        },

        abnormalityOptions(): SelectOption[] {
            return [
                {
                    value: Abnormality.NO_DEALER,
                    text: this.$t("Kein Standort verknüpft"),
                },
                {
                    value: Abnormality.MULTIPLE_DEALERS,
                    text: this.$t("Mehr als ein Standort verknüpft"),
                },
                {
                    value: Abnormality.NO_CHANNEL,
                    text: this.$t("Nicht in 360dialog angelegt"),
                },
            ];
        },
    },

    async mounted() {
        try {
            const partitions = await partitionsApi.list();
            for (const partition of partitions) {
                this.partitions.set(partition.id, partition);
            }

            const dealers = await dealersApi.list();
            for (const dealer of dealers) {
                this.dealers.set(dealer.id, dealer);
            }

            this.accounts = await whatsAppAccountsApi.getAllAccounts();
        } finally {
            this.loading = false;
        }
    },
});


import IncomingEmailBlacklistEntryFormDialog from "./IncomingEmailBlacklistEntryFormDialog.vue";
import { IncomingEmailBlacklistEntryData, IncomingEmailBlacklistEntryType } from "@/api/incomingEmailBlacklist";
import { incomingEmailsApi, IncomingEmailSummary } from "@/api/incomingEmails";
import { partitionsApi, PartitionSummary } from "@/api/partitions";
import { Permission } from "@/api/userSession";
import CopyToClipboardIcon from "@/app/components/CopyToClipboardIcon.vue";
import DataTable from "@/app/components/DataTable.vue";
import { DataTableHeader, DataTablePaging } from "@/app/components/dataTable";
import { notEmpty } from "@/app/validation";
import { userSession } from "@/store/userSession";
import { PickMutable } from "@/util/types";
import Vue from "vue";

enum AddBlacklistEntryType {
    DOMAIN,
    FROM_ADDRESS,
    FROM_NAME,
    SUBJECT,
}

export default Vue.extend({
    data() {
        return {
            addBlacklistEntryDialogVisible: false,
            addBlacklistEntryForm: {
                type: IncomingEmailBlacklistEntryType.FROM_NAME_CONTAINS,
                value: "",
            } as IncomingEmailBlacklistEntryData,
            AddBlacklistEntryType,
            items: [] as IncomingEmailSummary[],
            loading: true,
            paging: {
                page: 1,
                pageSize: 25,
                totalSize: 0,
                maxTotalSize: Number.MAX_SAFE_INTEGER,
                maxPage: Number.MAX_SAFE_INTEGER,
            } as PickMutable<DataTablePaging, "page" | "totalSize">,
            partitions: [] as PartitionSummary[],
            handlingSpam: false,
            notEmpty,
        };
    },

    computed: {
        canManageIncomingEmailBlacklist(): boolean {
            return userSession.hasPermission(Permission.CT_MANAGE_INCOMING_EMAIL_BLACKLIST);
        },

        canManageIncomingEmails(): boolean {
            return userSession.hasPermission(Permission.MANAGE_INCOMING_EMAILS);
        },

        headers(): DataTableHeader[] {
            return [
                {
                    text: this.$t("E-Mail vom"),
                    width: "10%",
                },
                {
                    text: this.$t("gemeldet am"),
                    width: "10%",
                },
                {
                    text: this.$t("Partition"),
                    width: "10%",
                },
                {
                    text: this.$t("Absendername"),
                    width: "20%",
                },
                {
                    text: this.$t("Absenderadresse"),
                    width: "20%",
                },
                {
                    text: this.$t("Betreff"),
                    width: "30%",
                },
                {
                    text: this.$t("Aktionen"),
                    width: "10%",
                },
            ];
        },

        pagedItems(): IncomingEmailSummary[] {
            return this.items.slice(
                (this.paging.page - 1) * this.paging.pageSize,
                this.paging.page * this.paging.pageSize
            );
        },
    },

    methods: {
        async closeBlacklistEntryDialog(reload: boolean) {
            this.addBlacklistEntryDialogVisible = false;

            if (reload) {
                this.loadItems();
            }
        },

        getIncomingEmailLink(incomingEmailId: string, partitionId: string): string | null {
            const host = this.partitions.find((p) => p.id === partitionId)?.host;

            if (!host) {
                return null;
            }

            return `https://${host}/incoming-email/${incomingEmailId}`;
        },

        getPartitionLink(partitionId: string): string | null {
            const host = this.partitions.find((p) => p.id === partitionId)?.host;

            if (!host) {
                return null;
            }

            return `https://${host}/dealerstubs`;
        },

        getPartitionNameById(partitionId: string) {
            return this.partitions.find((p) => p.id === partitionId)?.name || this.$t("Unbekannte Partition");
        },

        async handleSpam(incomingEmailId: string) {
            this.handlingSpam = true;
            try {
                await incomingEmailsApi.handleSpam(incomingEmailId);
            } finally {
                this.handlingSpam = false;
            }
            await this.loadItems();
        },

        async page(paging: DataTablePaging) {
            this.paging = { ...paging };
        },

        showAddBlacklistEntryDialog(item: IncomingEmailSummary, type: AddBlacklistEntryType) {
            if (type === AddBlacklistEntryType.DOMAIN) {
                this.addBlacklistEntryForm = {
                    type: IncomingEmailBlacklistEntryType.FROM_ADDRESS_ENDS_WITH,
                    value: item.data.fromAddress.substr(item.data.fromAddress.indexOf("@")),
                };
            } else if (type === AddBlacklistEntryType.FROM_ADDRESS) {
                this.addBlacklistEntryForm = {
                    type: IncomingEmailBlacklistEntryType.FROM_ADDRESS_CONTAINS,
                    value: item.data.fromAddress,
                };
            } else if (type === AddBlacklistEntryType.FROM_NAME) {
                this.addBlacklistEntryForm = {
                    type: IncomingEmailBlacklistEntryType.FROM_NAME_CONTAINS,
                    value: item.data.fromName || "",
                };
            } else if (type === AddBlacklistEntryType.SUBJECT) {
                this.addBlacklistEntryForm = {
                    type: IncomingEmailBlacklistEntryType.SUBJECT_CONTAINS,
                    value: item.data.subject || "",
                };
            }

            this.addBlacklistEntryDialogVisible = true;
        },

        async loadItems() {
            this.loading = true;
            try {
                this.items = await incomingEmailsApi.getAllEmailSummariesWithUnhandledSpamReport();
                this.paging.totalSize = this.items.length;
            } finally {
                this.loading = false;
            }
        },
    },

    async mounted() {
        this.partitions = await partitionsApi.list();
        await this.loadItems();
    },

    components: {
        CopyToClipboardIcon,
        DataTable,
        IncomingEmailBlacklistEntryFormDialog,
    },
});

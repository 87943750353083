
import ActionCard from "./ActionCard.vue";
import ActionExternalBdcCdrRecordingsCard from "./ActionExternalBdcCdrRecordingsCard.vue";
import ActionFeedbackCard from "./ActionFeedbackCard.vue";
import { ExternalBdcCdrAndRecordings } from "./actionUtils";
import { Action, actionsApi } from "@/api/actions";
import { Dealer, dealersApi } from "@/api/dealers";
import { externalBdcCdrsApi } from "@/api/externalBdcCdrs";
import { partitionsApi, PartitionSummary } from "@/api/partitions";
import { recordingsApi } from "@/api/recordings";
import { User, usersApi } from "@/api/users";
import { canViewRecordings } from "@/app/pages/recordings/recordingUtils";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            action: null as Action | null,
            actionId: null as number | null,
            externalBdcCdrsAndRecordings: [] as ExternalBdcCdrAndRecordings[],
            dealers: [] as Dealer[],
            loading: true,
            partition: null as PartitionSummary | null,
            searchId: 0,
            user: null as User | null,
        };
    },

    computed: {
        canViewRecordings(): boolean {
            return canViewRecordings();
        },
    },

    methods: {
        getDealerById(id: string | null): Dealer | null {
            return this.dealers.find((d) => d.id === id) ?? null;
        },

        async load() {
            this.action = null;
            this.externalBdcCdrsAndRecordings = [];
            this.dealers = [];
            this.partition = null;
            this.user = null;

            if (!this.actionId) {
                return;
            }

            this.loading = true;
            const searchId = ++this.searchId;

            try {
                const action = await actionsApi.getById(this.actionId);

                if (!action) {
                    return;
                }

                const [externalBdcCrs, dealers, partition, user] = await Promise.all([
                    externalBdcCdrsApi.getByUserWithinEndRange(
                        action.userId,
                        new Date(action.created.getTime() - 300 * 1000),
                        new Date(action.created.getTime() + 300 * 1000)
                    ),
                    dealersApi.list(),
                    partitionsApi.getById(action.partitionId),
                    usersApi.getById(action.userId),
                ]);

                const externalBdcCdrsAndRecordings = [];

                for (const externalBdcCr of externalBdcCrs!) {
                    externalBdcCdrsAndRecordings.push({
                        externalBdcCr,
                        recordings: await recordingsApi.getByBeginRangeAndCaller(
                            new Date(externalBdcCr.begin.getTime() - 1000),
                            new Date(externalBdcCr.begin.getTime() + 1000),
                            externalBdcCr.caller
                        ),
                    });
                }

                if (searchId === this.searchId) {
                    this.action = action;
                    this.externalBdcCdrsAndRecordings = externalBdcCdrsAndRecordings;
                    this.dealers = dealers!;
                    this.partition = partition;
                    this.user = user;
                }
            } finally {
                if (searchId === this.searchId) {
                    this.loading = false;
                }
            }
        },
    },

    async mounted() {
        this.actionId = parseInt(this.$route.params.actionid);

        await this.load();
    },

    components: {
        ActionCard,
        ActionExternalBdcCdrRecordingsCard,
        ActionFeedbackCard,
    },
});

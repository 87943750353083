import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export interface PartnerChannelData {
    readonly displayName: string;
    readonly clientName: string;
    readonly url: string;
    readonly accountMode: string | null;
    readonly status: string;
    readonly createdAt: Date;
    readonly billingStartedAt: Date | null;
    readonly cancelledAt: Date | null;
    readonly terminatedAt: Date | null;
}

export interface WhatsAppAccount {
    readonly number: string;
    readonly dealerIds: string[];
    readonly partnerChannelData: PartnerChannelData | null;
}

interface WhatsAppAccountsApi {
    getAllAccounts(): Promise<WhatsAppAccount[]>;
}

export const whatsAppAccountsApi: WhatsAppAccountsApi = {
    async getAllAccounts() {
        return cloneObject((await axios.get(`/api/whatsapp-accounts`)).data);
    },
};


import Navigation from "./Navigation.vue";
import { showAlert } from "@/app/messageUtil";
import navigation from "@/app/navigation";
import { configStore } from "@/store/config";
import { userSession } from "@/store/userSession";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            drawer: null as boolean | null,
        };
    },

    computed: {
        fullName() {
            return userSession.fullName;
        },
        isConnected() {
            return userSession.connected;
        },
        title() {
            return this.$route.matched.map((r) => r.meta.title as string | undefined).reduceRight((p, c) => p || c);
        },
        navigation() {
            return navigation;
        },
        profileImageUrl() {
            if (userSession.profileImageHash) {
                return `/api/users/${userSession.id}/profile-image/${userSession.profileImageHash}.jpg`;
            }

            return "/img/user_placeholder.png";
        },
        outdated() {
            return configStore.outdated;
        },

        isAgent(): boolean {
            return typeof userSession.profile?.weeklyWorkingHours === "number";
        },
    },

    methods: {
        async logout() {
            await userSession.logout();
            await this.$router.push("/login");
        },

        toggleDrawer() {
            this.drawer = !this.drawer;
        },
    },

    watch: {
        async outdated() {
            await showAlert(
                this.$t("Neue Version verfügbar!") as string,
                this.$t("Es steht ein Update zur Verfügung. Die Anwendung muss neu geladen werden.") as string,
                this.$t("Installieren und neu laden") as string
            );
            window.location.reload();
        },
    },

    components: {
        Navigation,
    },
});


import AgentMonthDataTable from "./AgentMonthDataTable.vue";
import { User, usersApi } from "@/api/users";
import MonthPicker from "@/app/components/MonthPicker.vue";
import { getFullName } from "@/app/userUtils";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { getCurrentMonth } from "@/util/dateTimeUtils";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            getFullName,
            loading: true,
            loadFilter: {
                yearMonth: getCurrentMonth(now(), userSession.timeZone),
                userId: userSession.id!,
            },
            users: [] as User[],
        };
    },

    computed: {
        user(): User | null {
            if (!userSession.id) {
                return null;
            }

            return this.users.find((u) => u.id === userSession.id) ?? null;
        },
    },

    methods: {
        async refresh() {
            await (this.$refs.agentMonthDataTable as any).refresh();
        },
    },

    async mounted() {
        this.users = await usersApi.list();
    },

    components: {
        AgentMonthDataTable,
        MonthPicker,
    },
});

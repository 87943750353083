
import MysqlDatabaseCard from "./MysqlDatabaseCard.vue";
import { BadRequest } from "@/api/errors";
import { mysqlDatabasesApi, MysqlDatabaseResult } from "@/api/mysqlDatabases";
import { regEx, ValidationHelper } from "@/app/validation";
import Vue from "vue";

export default Vue.extend({
    data() {
        const validationHelper = new ValidationHelper();
        return {
            isInitialized: false,
            isAddDialogVisible: false,
            isCreating: false,
            mysqlDatabaseId: "",
            mysqlDatabaseResults: [] as MysqlDatabaseResult[],
            validationHelper,
            mysqlDatabaseIdRules: regEx(/^(?=.{1,32}$)[a-z][a-z0-9]*(-[a-z0-9]+)*$/)
                .msg(() => this.$t("Gültiger Name der Datenbank ist erforderlich"))
                .and(validationHelper, "id"),
            valid: true,
        };
    },

    components: {
        MysqlDatabaseCard,
    },

    methods: {
        toggleAddDialog() {
            this.isAddDialogVisible = !this.isAddDialogVisible;
            this.mysqlDatabaseId = "";
            (this.$refs.form as any).resetValidation();
        },

        async createDatabase() {
            if (!this.valid) {
                (this.$refs.form as any).validate();
                return;
            }

            this.isCreating = true;
            try {
                await mysqlDatabasesApi.add(this.mysqlDatabaseId);
            } catch (e) {
                this.isCreating = false;
                if (!(e instanceof BadRequest)) {
                    throw e;
                }
                this.validationHelper.update(e, this.$refs.form);
                return;
            }
            this.isAddDialogVisible = false;
            this.isCreating = false;
            this.mysqlDatabaseResults = await mysqlDatabasesApi.getAll();
        },
    },

    async mounted() {
        this.mysqlDatabaseResults = await mysqlDatabasesApi.getAll();
        this.isInitialized = true;
    },
});


import MonthlyRevenueDataTableRow from "./MonthlyRevenueDataTableRow.vue";
import { BenefitMonthRevenue, benefitRevenuesApi } from "@/api/benefitRevenues";
import { addInvoiceRevenueData } from "@/app/pages/invoicerevenue/invoiceRevenueUtils";
import { configStore } from "@/store/config";
import { now } from "@/store/now";
import Vue from "vue";

type Total = Omit<BenefitMonthRevenue, "benefitMonth">;

export default Vue.extend({
    data() {
        return {
            items: [] as BenefitMonthRevenue[],
            loading: true,
            now: now(),
        };
    },

    computed: {
        locale(): string {
            return configStore.configuration.defaultLocale;
        },

        timezone(): string {
            return configStore.configuration.organisationTimeZone;
        },

        total(): Total | null {
            if (!this.items.length) {
                return null;
            }

            return this.items
                .map((item) => item as Total)
                .reduceRight((a, b) => ({
                    billingAccountCount: a.billingAccountCount + b.billingAccountCount,
                    partitionCount: a.partitionCount + b.partitionCount,
                    dealerCount: a.dealerCount + b.dealerCount,
                    invoiceTotal: a.invoiceTotal + b.invoiceTotal,
                    unpaidInvoiceTotal: a.unpaidInvoiceTotal + b.unpaidInvoiceTotal,
                    unpaidInvoiceCount: a.unpaidInvoiceCount + b.unpaidInvoiceCount,
                    invoiceRevenueData: addInvoiceRevenueData(a.invoiceRevenueData, b.invoiceRevenueData),
                    legacyRevenueData: addInvoiceRevenueData(a.legacyRevenueData, b.legacyRevenueData),
                }));
        },
    },

    methods: {
        async loadItems() {
            this.items = [];
            this.loading = true;
            try {
                this.items = await benefitRevenuesApi.getBenefitMonthRevenues(null, null);
            } finally {
                this.loading = false;
            }
        },
    },

    async mounted() {
        await this.loadItems();
    },

    components: {
        MonthlyRevenueDataTableRow,
    },
});

import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export enum IncomingEmailBlacklistEntryType {
    FROM_NAME_STARTS_WITH = "FROM_NAME_STARTS_WITH",
    FROM_NAME_CONTAINS = "FROM_NAME_CONTAINS",
    FROM_NAME_ENDS_WITH = "FROM_NAME_ENDS_WITH",
    FROM_ADDRESS_STARTS_WITH = "FROM_ADDRESS_STARTS_WITH",
    FROM_ADDRESS_CONTAINS = "FROM_ADDRESS_CONTAINS",
    FROM_ADDRESS_ENDS_WITH = "FROM_ADDRESS_ENDS_WITH",
    SUBJECT_STARTS_WITH = "SUBJECT_STARTS_WITH",
    SUBJECT_CONTAINS = "SUBJECT_CONTAINS",
    SUBJECT_ENDS_WITH = "SUBJECT_ENDS_WITH",
}

export interface IncomingEmailBlacklistEntryData {
    readonly type: IncomingEmailBlacklistEntryType;
    readonly value: string;
}

export interface IncomingEmailBlacklistEntry {
    readonly id: string;
    readonly matchCount: number;
    readonly lastMatch: Date | null;
    readonly data: IncomingEmailBlacklistEntryData;
}

interface IncomingEmailBlacklistApi {
    list(): Promise<IncomingEmailBlacklistEntry[]>;
    addBlacklistEntry(data: IncomingEmailBlacklistEntryData): Promise<void>;
    deleteBlacklistEntry(id: string): Promise<void>;
}

export const incomingEmailBlacklistApi: IncomingEmailBlacklistApi = {
    async list() {
        return cloneObject((await axios.get("/api/incoming-email-blacklist")).data);
    },

    async addBlacklistEntry(data) {
        await axios.post("/api/incoming-email-blacklist", data);
    },

    async deleteBlacklistEntry(id) {
        await axios.delete(`/api/incoming-email-blacklist/${id}`);
    },
};

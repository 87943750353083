
import ActionOverviewDataTableRow from "./ActionOverviewDataTableRow.vue";
import { canViewActions } from "./actionUtils";
import { Action, actionsApi } from "@/api/actions";
import { Dealer, dealersApi } from "@/api/dealers";
import { partitionsApi, PartitionSummary } from "@/api/partitions";
import { User, usersApi } from "@/api/users";
import DataTable from "@/app/components/DataTable.vue";
import { DataTableHeader, DataTablePaging } from "@/app/components/dataTable";
import { PickMutable } from "@/util/types";
import Vue from "vue";

interface Item {
    readonly actionId: number;
    readonly action: Action | null;
    readonly dealer: Dealer | null;
    readonly partition: PartitionSummary | null;
    readonly user: User | null;
}

export default Vue.extend({
    props: {
        actionIds: {
            type: Array as () => number[],
            required: true,
        },
        itemsPerPage: {
            type: Number,
            default: 25,
        },
        title: {
            type: String as () => string | null,
            default: null,
        },
    },

    data() {
        return {
            items: [] as Item[],
            loading: true,
            paging: {
                page: 1,
                pageSize: this.itemsPerPage,
                totalSize: this.actionIds.length,
                maxTotalSize: 1_000_000,
                maxPage: 10_000 / this.itemsPerPage,
            } as PickMutable<DataTablePaging, "page" | "totalSize">,
            searchId: 0,
        };
    },

    computed: {
        canViewActions(): boolean {
            return canViewActions();
        },

        headers(): DataTableHeader[] {
            return [
                {
                    text: this.$t("Uhrzeit"),
                    width: "8%",
                },
                {
                    text: this.$t("Referenz"),
                    width: "1%",
                },
                {
                    text: this.$t("Art der Aktion"),
                    width: "10%",
                },
                {
                    text: this.$t("Agent"),
                    width: "10%",
                },
                {
                    text: this.$t("Partition"),
                    width: "10%",
                },
                {
                    text: this.$t("Standort"),
                    width: "20%",
                },
                {
                    text: this.$t("Art des Feedbacks"),
                    width: "30%",
                },
            ];
        },

        pageActionIds(): number[] {
            const start = (this.paging.page - 1) * this.itemsPerPage;

            return this.actionIds.slice(start, start + this.itemsPerPage);
        },
    },

    methods: {
        async loadItems() {
            this.paging.totalSize = 0;
            this.items = [];

            if (!this.canViewActions) {
                this.loading = false;
                return;
            }

            this.loading = true;
            const pageActionIds = this.pageActionIds;
            const searchId = ++this.searchId;

            try {
                const [actions, dealers, partitions, users] = await Promise.all([
                    actionsApi.getByIds(pageActionIds),
                    dealersApi.list(),
                    partitionsApi.list(),
                    usersApi.list(),
                ]);

                const items: Item[] = pageActionIds
                    .map((actionId) => ({
                        actionId,
                        action: actions.find((a) => a.id === actionId) ?? null,
                    }))
                    .map((item) => ({
                        ...item,
                        dealer: dealers.find((d) => d.id === item.action?.dealerId) ?? null,
                        partition: partitions.find((p) => p.id === item.action?.partitionId) ?? null,
                        user: users.find((u) => u.id === item.action?.userId) ?? null,
                    }));

                if (searchId === this.searchId) {
                    this.items = items;
                    this.paging.totalSize = this.actionIds.length;
                    this.loading = false;
                }
            } finally {
                if (searchId === this.searchId) {
                    this.loading = false;
                }
            }
        },

        async page(paging: DataTablePaging) {
            this.paging = { ...paging };

            await this.loadItems();
        },
    },

    watch: {
        async actionIds() {
            this.paging.page = 1;
            this.paging.totalSize = this.actionIds.length;
            try {
                await this.loadItems();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },
    },

    async mounted() {
        await this.loadItems();
    },

    components: {
        ActionOverviewDataTableRow,
        DataTable,
    },
});


import ExternalBdcReportPage from "./ExternalBdcReportPage.vue";
import { ExtendedExternalBdcCdrRow, ExternalBdcCdrReportPageResult } from "./externalBdcReportPage";
import { WithBeginOngoingTimeSlot } from "./externalBdcRowUtils";
import ReportTableCard from "@/app/pages/reporting/ReportTableCard.vue";
import ReportingTimeSeriesChartCard from "@/app/pages/reporting/ReportingTimeSeriesChartCard.vue";
import {
    getReportingTimeSeriesChartForHeaderGroup,
    TimeSeriesChartSeriesDataPointOptions,
    TimeSeriesChartSeriesOptions,
} from "@/app/pages/reporting/chartUtils";
import { ReportingTimeSeriesChartData } from "@/app/pages/reporting/charts/reportingTimeSeriesChart";
import { addMissingRowGroups, applyKeySort, groupRowsBy, RowGroup } from "@/app/pages/reporting/pivotUtils";
import { TimeRange } from "@/app/pages/reporting/reportPage";
import { renderDuration, sum } from "@/app/pages/reporting/reportingUtils";
import { RowKey } from "@/app/pages/reporting/rowUtils";
import {
    ReportingTableData,
    ReportingTableHeaderGroup,
    ReportingTableItemColumnGroup,
} from "@/app/pages/reporting/table/reportingTable";
import { getOngoingTimeSlotLabel, OngoingTimeInterval } from "@/app/pages/reporting/timeInterval";
import { getTimeSeriesDefaultKeys } from "@/app/pages/reporting/timeSeriesUtils";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { getDate } from "@/util/dateTimeUtils";
import Vue from "vue";

type PrecomputedProperties = WithBeginOngoingTimeSlot;

type ComputedExternalBdcCdrRow = ExtendedExternalBdcCdrRow & PrecomputedProperties;

type ReportingTableHeaderGroupWithColumnGroupComputer<Options> = ReportingTableHeaderGroup<Options> & {
    readonly toColumnGroup: (rowGroupRows: readonly ComputedExternalBdcCdrRow[]) => ReportingTableItemColumnGroup;
};

export default Vue.extend({
    data() {
        const ts = now();

        return {
            ongoingTimeInterval: OngoingTimeInterval.THIRTY_MINUTES as OngoingTimeInterval,
            OngoingTimeInterval,
            result: null as ExternalBdcCdrReportPageResult<PrecomputedProperties> | null,
            timeRange: {
                from: getDate(ts, userSession.timeZone),
                to: getDate(ts, userSession.timeZone),
            } as TimeRange,
        };
    },

    computed: {
        agentChart(): ReportingTimeSeriesChartData | null {
            return getReportingTimeSeriesChartForHeaderGroup(this.table, this.dateFormatter, 2);
        },

        defaultKeys(): RowKey[] {
            return getTimeSeriesDefaultKeys(
                this.result?.rows ?? [],
                (r: ComputedExternalBdcCdrRow) => r.begin,
                this.result?.filter.createdFrom ?? null,
                this.result?.filter.createdTo ?? null,
                this.ongoingTimeInterval,
                this.result?.nowAtLoadRows ?? null
            );
        },

        durationChart(): ReportingTimeSeriesChartData | null {
            return getReportingTimeSeriesChartForHeaderGroup(this.table, this.dateFormatter, 3);
        },

        incomingChart(): ReportingTimeSeriesChartData | null {
            return getReportingTimeSeriesChartForHeaderGroup(this.table, this.dateFormatter, 0);
        },

        rowGroups(): readonly RowGroup<RowKey, ComputedExternalBdcCdrRow>[] {
            const rowGroups = groupRowsBy(this.result?.rows ?? [], (r) => r.beginOngoingTimeSlot);
            const patchedRowGroups = addMissingRowGroups(rowGroups, this.defaultKeys);

            return applyKeySort(patchedRowGroups, this.defaultKeys);
        },

        table(): ReportingTableData<TimeSeriesChartSeriesOptions, TimeSeriesChartSeriesDataPointOptions> | null {
            const allRows = this.result?.rows ?? [];

            if (!allRows.length) {
                return null;
            }

            return {
                headerGroups: this.tableHeaderGroups.map((headerGroup) => ({
                    ...headerGroup,
                    toColumnGroup: undefined,
                })),
                items: this.rowGroups.map((rowGroup) => ({
                    title: this.dateFormatter(new Date(rowGroup.key as string)),
                    groups: this.tableHeaderGroups.map((headerGroup) => headerGroup.toColumnGroup(rowGroup.rows)),
                    options: {
                        date: new Date(rowGroup.key as string),
                    },
                })),
                totals: {
                    title: this.$t("Gesamt") as string,
                    groups: this.tableHeaderGroups.map((headerGroup) => headerGroup.toColumnGroup(allRows)),
                },
                groupByHeaderText: this.$t("Zeitraum") as string,
            };
        },

        tableHeaderGroups(): ReportingTableHeaderGroupWithColumnGroupComputer<TimeSeriesChartSeriesOptions>[] {
            const setUpTimeBetweenCalls = 5; // seconds
            const timeSlotSeconds =
                this.ongoingTimeInterval === OngoingTimeInterval.FIVE_MINUTES
                    ? 5 * 60
                    : this.ongoingTimeInterval === OngoingTimeInterval.TEN_MINUTES
                    ? 10 * 60
                    : this.ongoingTimeInterval === OngoingTimeInterval.FIFTEEN_MINUTES
                    ? 15 * 60
                    : this.ongoingTimeInterval === OngoingTimeInterval.THIRTY_MINUTES
                    ? 30 * 60
                    : this.ongoingTimeInterval === OngoingTimeInterval.SIXTY_MINUTES
                    ? 60 * 60
                    : undefined;

            return [
                {
                    text: this.$t("Eingehend") as string,
                    headers: [
                        { text: this.$t("Anzahl") as string },
                        { text: this.$t("Angenommen") as string },
                        { text: this.$t("Annahmequote") as string },
                    ],
                    toColumnGroup: (rowGroupRows) => {
                        const incomingRows = rowGroupRows.filter((r) => r.startedInTimeSlot);

                        const incomingCalls = incomingRows.length;
                        const acceptedCalls = incomingRows.filter((r) => r.duration !== null).length;

                        return {
                            columns: [
                                { entries: [{ value: incomingCalls }] },
                                { entries: [{ value: acceptedCalls }] },
                                {
                                    entries: [
                                        {
                                            value: incomingCalls ? acceptedCalls / incomingCalls : undefined,
                                            isPercentage: true,
                                        },
                                    ],
                                },
                            ],
                        };
                    },
                },
                {
                    text: this.$t("Laufend") as string,
                    headers: [
                        { text: this.$t("Anzahl") as string },
                        { text: this.$t("Angenommen") as string },
                        { text: this.$t("Annahmequote") as string },
                    ],
                    toColumnGroup: (rowGroupRows) => {
                        const ongoingRows = rowGroupRows.filter((r) => !r.startedInTimeSlot);

                        const ongoingCalls = ongoingRows.length;
                        const acceptedCalls = ongoingRows.filter((r) => r.duration !== null).length;

                        return {
                            columns: [
                                { entries: [{ value: ongoingCalls }] },
                                { entries: [{ value: acceptedCalls }] },
                                {
                                    entries: [
                                        {
                                            value: ongoingCalls ? acceptedCalls / ongoingCalls : undefined,
                                            isPercentage: true,
                                        },
                                    ],
                                },
                            ],
                        };
                    },
                },
                {
                    text: this.$t("Agenten") as string,
                    headers: [{ text: this.$t("Anzahl") as string }, { text: this.$t("Auslastung") as string }],
                    toColumnGroup: (rowGroupRows) => {
                        const acceptedRows = rowGroupRows.filter((r) => r.duration !== null);

                        const duration = sum(acceptedRows.map((r) => r.duration));
                        const agentsCount = new Set(acceptedRows.map((r) => r.userId)).size;

                        const setupTimeCorrection = Math.max(0, acceptedRows.length - 1) * setUpTimeBetweenCalls;
                        const theoreticalMaxDuration =
                            timeSlotSeconds !== undefined ? agentsCount * timeSlotSeconds : undefined;

                        return {
                            columns: [
                                { entries: [{ value: agentsCount }] },
                                {
                                    entries: [
                                        {
                                            value:
                                                theoreticalMaxDuration && duration !== undefined
                                                    ? (setupTimeCorrection + duration) / theoreticalMaxDuration
                                                    : undefined,
                                            isPercentage: true,
                                        },
                                    ],
                                },
                            ],
                        };
                    },
                },
                {
                    text: this.$t("Gesprächsdauer") as string,
                    headers: [
                        { text: this.$t("Agent (Durchschnitt)") as string },
                        { text: this.$t("Gesamt (Summe)") as string },
                    ],
                    toColumnGroup: (rowGroupRows) => {
                        const acceptedRows = rowGroupRows.filter((r) => r.duration !== null);

                        const duration = sum(acceptedRows.map((r) => r.duration));
                        const agentsCount = new Set(acceptedRows.map((r) => r.userId)).size;

                        return {
                            columns: [
                                {
                                    entries: [
                                        {
                                            value:
                                                duration !== undefined && agentsCount
                                                    ? duration / agentsCount
                                                    : undefined,
                                            formatter: (value) => renderDuration(value, "L"),
                                        },
                                    ],
                                },
                                {
                                    entries: [
                                        {
                                            value: duration,
                                            formatter: (value) => renderDuration(value, "L"),
                                        },
                                    ],
                                },
                            ],
                        };
                    },
                },
                {
                    text: this.$t("Wartezeit") as string,
                    headers: [{ text: this.$t("Gesamt (Summe)") as string }],
                    toColumnGroup: (rowGroupRows) => ({
                        columns: [
                            {
                                entries: [
                                    {
                                        value: sum(rowGroupRows.map((r) => r.waited)),
                                        formatter: (value) => renderDuration(value, "L"),
                                    },
                                ],
                            },
                        ],
                    }),
                },
            ];
        },

        timeZone(): string {
            return userSession.timeZone;
        },

        waitedChart(): ReportingTimeSeriesChartData | null {
            return getReportingTimeSeriesChartForHeaderGroup(this.table, this.dateFormatter, 4);
        },
    },

    methods: {
        dateFormatter(date: Date): string {
            return getOngoingTimeSlotLabel(date, this.ongoingTimeInterval, this.timeZone, "L") || "";
        },
    },

    components: {
        ExternalBdcReportPage,
        ReportingTimeSeriesChartCard,
        ReportTableCard,
    },
});


import { now } from "@/store/now";
import { formatDifference } from "@/util/dateTimeUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        from: {
            type: (Date as unknown) as () => Date | null,
            required: false,
        },
        to: {
            type: (Date as unknown) as () => Date | null,
            required: false,
        },
        humanize: Boolean,
    },

    computed: {
        difference() {
            return formatDifference(
                this.from || now(),
                this.to || now(),
                true,
                this.humanize ? this.$i18n.locale : undefined
            );
        },
    },
});

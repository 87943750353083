import { Dealer } from "@/api/dealers";
import { PartitionSummary } from "@/api/partitions";
import { User } from "@/api/users";
import { $t } from "@/app/i18n";
import { RowKey, RowLabel } from "@/app/pages/reporting/rowUtils";
import { getFullName } from "@/app/userUtils";

/*
 * helper
 */

function toFullSeconds(time: number | null): number | null {
    if (time === null) {
        return null;
    }

    const seconds = time / 1000;

    return seconds < 0 ? Math.floor(seconds) : Math.ceil(seconds);
}

/*
 * with created second
 */

export interface WithCreatedSecond {
    readonly createdSecond: number;
}

export function withCreatedSecond<T extends { created: Date }>(row: T): T & WithCreatedSecond {
    return {
        ...row,
        createdSecond: toFullSeconds(row.created.getTime())!,
    };
}

/*
 * misc
 */

export interface MapActionRowKeyToLabelContext {
    dealers?: readonly Dealer[];
    partitions?: readonly PartitionSummary[];
    users?: readonly User[];
}

export function mapActionRowKeyToRowLabel(
    key: RowKey,
    groupBy: string,
    context?: MapActionRowKeyToLabelContext
): RowLabel {
    const { dealers, partitions, users } = context ?? {};

    if (groupBy === "dealerId") {
        if (key === null) {
            return { label: $t("Ohne Standort") as string };
        } else if (typeof key === "string") {
            return { label: dealers?.find((d) => d.id === key)?.name || ($t("Unbekannter Standort") as string) };
        }
    } else if (groupBy === "partitionId") {
        if (key === null) {
            return { label: $t("Ohne Partition") as string };
        } else if (typeof key === "string") {
            return { label: partitions?.find((d) => d.id === key)?.name || ($t("Unbekannte Partition") as string) };
        }
    } else if (groupBy === "userId") {
        if (key === null) {
            return { label: $t("Ohne Benutzer") as string };
        } else if (typeof key === "string") {
            const user = users?.find((d) => d.id === key);

            if (!user) {
                return { label: $t("Gelöschter Benutzer") as string };
            }

            return { label: getFullName(user) };
        }
    }

    return { label: $t("Unbekannt") as string };
}

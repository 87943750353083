
import { notEmpty } from "@/app/validation";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            image: "",
            imagePrefix: "dealerdesk-partition-",
            notEmpty,
        };
    },

    methods: {
        close() {
            this.$emit("close");
        },

        submit() {
            if (!(this.$refs.form as any).validate()) {
                return;
            }

            this.$emit("submit", `${this.imagePrefix}${this.image.trim()}`);
        },
    },
});


import { AgentAssessment, externalBdcCdrAssessmentsApi } from "@/api/externalBdcCdrAssessments";
import { User, usersApi } from "@/api/users";
import DatePicker from "@/app/components/DatePicker.vue";
import { getFullName } from "@/app/userUtils";
import { configStore } from "@/store/config";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { getDate, toDateObject } from "@/util/dateTimeUtils";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            agentAssessments: [] as AgentAssessment[],
            loadFilter: {
                endFrom: getDate(now(), configStore.configuration.organisationTimeZone, -6) as string | null,
                endTo: null as string | null,
            },
            loading: true,
            searchId: 0,
            users: [] as User[],
        };
    },

    computed: {
        headers(): unknown[] {
            return [
                {
                    text: this.$t("Agent"),
                    width: "20%",
                },
                {
                    text: this.$t("Durchschnitt"),
                    width: "20%",
                },
                {
                    text: this.$t("Beurteilungen"),
                    width: "60%",
                },
            ];
        },
    },

    methods: {
        getUserFullNameById(userId: string | null) {
            const user = this.users.find((u) => u.id === userId);

            return user ? getFullName(user) : "-";
        },

        async loadItems() {
            this.loading = true;
            this.agentAssessments = [];
            const searchId = ++this.searchId;

            try {
                const endFrom = this.loadFilter.endFrom
                    ? toDateObject(userSession.timeZone, this.loadFilter.endFrom)
                    : null;

                const endTo = this.loadFilter.endTo
                    ? toDateObject(userSession.timeZone, this.loadFilter.endTo, 1)
                    : null;

                const agentAssessments = await externalBdcCdrAssessmentsApi.getAgentAssessmentsByEndRange(
                    endFrom,
                    endTo
                );

                if (searchId === this.searchId) {
                    this.agentAssessments = agentAssessments;
                }
            } finally {
                if (searchId === this.searchId) {
                    this.loading = false;
                }
            }
        },

        async refresh() {
            await this.loadItems();
        },
    },

    watch: {
        loadFilter: {
            deep: true,
            async handler() {
                try {
                    await this.loadItems();
                } catch (e) {
                    this.$nextTick(() => {
                        throw e;
                    });
                }
            },
        },
    },

    async mounted() {
        this.users = await usersApi.list();

        await this.loadItems();
    },

    components: {
        DatePicker,
    },
});

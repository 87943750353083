import { configStore } from "./config";
import { Unauthorized } from "@/api/errors";
import { notificationEventSource } from "@/api/notifications";
import { LoginForm, PasswordForm, Permission, UserProfile, UserProfileForm, userSessionApi } from "@/api/userSession";
import { getFullName } from "@/app/userUtils";
import { reactive } from "@/util/reactive";

@reactive
export class UserSession {
    connected = false;
    detectReconnect = false;
    profile: UserProfile | null = null;

    get id() {
        return this.profile?.id;
    }

    get username() {
        return this.profile?.username;
    }

    get fullName() {
        if (!this.profile) {
            return undefined;
        }

        return getFullName(this.profile);
    }

    get permissions() {
        return this.profile?.permissions;
    }

    get timeZone() {
        if (this.profile) {
            return this.profile.timeZone;
        }
        return configStore.configuration.organisationTimeZone;
    }

    get locale() {
        if (this.profile) {
            return this.profile.locale;
        }
        return configStore.configuration.defaultLocale;
    }

    get birthday() {
        return this.profile?.birthday;
    }

    get darkmode(): boolean {
        return !!this.profile?.darkmode;
    }

    get profileImageHash() {
        return this.profile?.profileImageHash;
    }

    get csrfToken() {
        return this.profile?.csrfToken;
    }

    hasPermission(permission: Permission) {
        return (this.permissions || []).some((p) => p === permission);
    }

    async login(loginForm: LoginForm, passwordForm?: PasswordForm) {
        try {
            this.profile = await userSessionApi.login(loginForm, passwordForm);
            await this.refreshStores();
            notificationEventSource.connect();
        } catch (e) {
            if (!(e instanceof Unauthorized)) {
                throw e;
            }
        }
        return !!this.profile;
    }

    async logout() {
        try {
            await userSessionApi.logout();
        } catch (e) {
            // ignore
        }
        this.profile = null;
        this.detectReconnect = false;
        notificationEventSource.disconnect();
    }

    async initialize() {
        try {
            this.profile = await userSessionApi.currentUser();
            await this.refreshStores();
            notificationEventSource.connect();
        } catch (e) {
            if (!(e instanceof Unauthorized)) {
                throw e;
            }
        }
    }

    async refreshStores() {
        // nothing to do yet
    }

    async editUserProfile(userProfileForm: UserProfileForm) {
        this.profile = await userSessionApi.edit(userProfileForm);
    }

    async changePassword(passwordForm: PasswordForm) {
        this.profile = await userSessionApi.changePassword(passwordForm);
    }
}

export const userSession = new UserSession();

notificationEventSource.addConnectionHandler(async (connected) => {
    userSession.connected = connected;
    if (connected) {
        if (userSession.detectReconnect) {
            userSession.profile = await userSessionApi.currentUser();
            await userSession.refreshStores();
        } else {
            userSession.detectReconnect = true;
        }
    }
});


import DealerStatisticsTableRow from "./DealerStatisticsTableRow.vue";
import {
    DealerStatisticSearchOrder,
    sortStatisticResults,
    StatisticResult,
    sumStatisticResults,
    toStatistic,
} from "./dealerStatisticsOverview";
import { CompensationSubject } from "@/api/contracts";
import { DealerStatistic, dealerStatisticsApi } from "@/api/dealerStatistics";
import { partitionsApi, PartitionSummary } from "@/api/partitions";
import DateRangePicker from "@/app/components/DateRangePicker.vue";
import EnumField from "@/app/components/EnumField.vue";
import { DateRange } from "@/app/components/dateRangePicker";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { addDuration, endOf, getDate, startOf, toDateObject, UnitOfTime } from "@/util/dateTimeUtils";
import { ActionLimiter } from "@/util/debounce";
import { SelectOption } from "@/util/types";
import moment from "moment-timezone";
import Vue from "vue";
import { TranslateResult } from "vue-i18n";

interface Item {
    readonly result: StatisticResult;
    readonly compare: StatisticResult;
}

interface QuickFilter {
    readonly label: TranslateResult;
    readonly from: string;
    readonly to: string;
    readonly compareFrom: string;
    readonly compareTo: string;
}

interface TableHeader {
    readonly text: TranslateResult;
    readonly tooltip: TranslateResult;
    readonly width: string;
}

export default Vue.extend({
    data() {
        const ts = now();

        return {
            compareDealerStatistics: [] as DealerStatistic[],
            CompensationSubject,
            dealerStatistics: [] as DealerStatistic[],
            DealerStatisticSearchOrder,
            groupByPartition: false as boolean,
            loadFilter: {
                compare: false,
                compareRange: null as DateRange | null,
                dateRange: {
                    from: getDate(ts, userSession.timeZone),
                    to: getDate(ts, userSession.timeZone),
                } as DateRange,
            },
            loadLimiter: new ActionLimiter(true),
            loading: true,
            localFilter: {
                hasActiveContracts: null as boolean | null,
                hasActivities: null as boolean | null,
                uncoveredCompensationSubjects: [] as CompensationSubject[],
            },
            now: ts,
            partitions: [] as PartitionSummary[],
            searchId: 0,
            sortBy: DealerStatisticSearchOrder.EXTERNAL_BDC_MINUTES_DESC as DealerStatisticSearchOrder,
        };
    },

    computed: {
        compareFrom(): string {
            return (
                this.loadFilter.compareRange?.from ??
                getDate(
                    toDateObject(userSession.timeZone, this.loadFilter.dateRange.from),
                    userSession.timeZone,
                    -1 * this.timeWindowDays
                )
            );
        },

        compareStatisticResults(): StatisticResult[] {
            return this.compareDealerStatistics.map((statistic) => ({
                dealerId: statistic.dealerId,
                name: statistic.name,
                partitionId: statistic.partitionId,
                partition: this.partitions.find((p) => p.id === statistic.partitionId) ?? null,
                statistic: toStatistic(statistic, 1, this.compareTimeWindowDays),
            }));
        },

        compareTimeWindowDays(): number {
            return 1 + Math.ceil(moment.duration(moment(this.compareTo).diff(this.compareFrom)).asDays());
        },

        compareTo(): string {
            return (
                this.loadFilter.compareRange?.to ??
                getDate(toDateObject(userSession.timeZone, this.loadFilter.dateRange.from), userSession.timeZone, -1)
            );
        },

        groupByOptions(): SelectOption[] {
            return [
                {
                    value: false,
                    text: this.$t("Keine Gruppierung"),
                },
                {
                    value: true,
                    text: this.$t("Partition"),
                },
            ];
        },

        headers(): TableHeader[] {
            return [
                {
                    text: "",
                    tooltip: "",
                    width: "10%",
                },
                // no text translation for the following headers
                {
                    text: "SW",
                    tooltip: this.$t("Software-Tage (gebucht/gesamt)"),
                    width: "3%",
                },
                {
                    text: "CTC",
                    tooltip: this.$t("Click-to-Call-Minuten (abgedeckt/generiert)"),
                    width: "5%",
                },
                {
                    text: "CTM",
                    tooltip: this.$t("Call-Tracking-Minuten (abgedeckt/generiert)"),
                    width: "5%",
                },
                {
                    text: "SMS",
                    tooltip: this.$t("SMS (abgedeckt/gesendet)"),
                    width: "5%",
                },
                {
                    text: "BDC",
                    tooltip: this.$t("Externe-BDC-Minuten (abgedeckt/generiert)"),
                    width: "5%",
                },
                {
                    text: "WA",
                    tooltip: this.$t("WhatsApp-Nachrichten (empfangen/gesendet)"),
                    width: "5%",
                },
                {
                    text: "WAN",
                    tooltip: this.$t("WhatsApp-Rufnummern"),
                    width: "3%",
                },
                {
                    text: "VC",
                    tooltip: this.$t("Videochats"),
                    width: "3%",
                },
                {
                    text: "U-O/T",
                    tooltip: this.$t("Benutzer (online/gesamt)"),
                    width: "5%",
                },
                {
                    text: "IV",
                    tooltip: this.$t("Bestandsfahrzeuge"),
                    width: "3%",
                },
                {
                    text: "IVI",
                    tooltip: this.$t("Aktive Suchaufträge"),
                    width: "3%",
                },
                {
                    text: "CO",
                    tooltip: this.$t("Kontakte"),
                    width: "5%",
                },
                {
                    text: "C-O/A",
                    tooltip: this.$t("Fälle (offen/zugeteilt)"),
                    width: "5%",
                },
                {
                    text: "O-O/A",
                    tooltip: this.$t("Verkaufschancen (offen/zugeteilt)"),
                    width: "5%",
                },
                {
                    text: "E-O",
                    tooltip: this.$t("Notdienstvorgänge (offen)"),
                    width: "1%",
                },
                {
                    text: "C-P/CT/S/H/U",
                    tooltip: this.$t("Erstellte Fälle (Benutzer/Externer Agent/System/glücklich/unglücklich)"),
                    width: "8%",
                },
                {
                    text: "O-P/CT/S/H/U",
                    tooltip: this.$t(
                        "Erstellte Verkaufschancen (Benutzer/Externer Agent/System/glücklich/unglücklich)"
                    ),
                    width: "8%",
                },
                {
                    text: "E-P/CT/S",
                    tooltip: this.$t("Erstellte Notdienstvorgänge (Benutzer/Externer Agent/System)"),
                    width: "5%",
                },
            ];
        },

        items(): Item[] {
            const uncoveredSubjects = this.localFilter.uncoveredCompensationSubjects;

            const filteredStatisticResults = this.statisticResults
                .filter(
                    (result) =>
                        this.localFilter.hasActivities === null ||
                        result.statistic.clickToCallMinutes +
                            result.statistic.callTrackingMinutes +
                            result.statistic.outgoingSms +
                            result.statistic.externalBdcMinutes +
                            result.statistic.incomingWhatsAppMessages +
                            result.statistic.outgoingWhatsAppMessages +
                            result.statistic.videochats >
                            0 ===
                            this.localFilter.hasActivities
                )
                .filter(
                    (result) =>
                        this.localFilter.hasActiveContracts === null ||
                        result.statistic.activeContracts > 0 === this.localFilter.hasActiveContracts
                )
                .filter(
                    (result) =>
                        !uncoveredSubjects.length ||
                        (uncoveredSubjects.includes(CompensationSubject.SOFTWARE_LICENSE) &&
                            result.statistic.softwareLicenseDaysCovered < result.statistic.timeWindowDays) ||
                        (uncoveredSubjects.includes(CompensationSubject.EXTERNAL_BDC_MINUTE) &&
                            result.statistic.externalBdcMinutesCovered < result.statistic.externalBdcMinutes) ||
                        (uncoveredSubjects.includes(CompensationSubject.CLICK_TO_CALL_MINUTE) &&
                            result.statistic.clickToCallMinutesCovered < result.statistic.clickToCallMinutes) ||
                        (uncoveredSubjects.includes(CompensationSubject.CALL_TRACKING_MINUTE) &&
                            result.statistic.callTrackingMinutesCovered < result.statistic.callTrackingMinutes) ||
                        (uncoveredSubjects.includes(CompensationSubject.OUTGOING_SMS) &&
                            result.statistic.outgoingSmsCovered < result.statistic.outgoingSms)
                );

            const partitionIds = [...new Set(filteredStatisticResults.map((result) => result.partitionId))];

            const groups: StatisticResult[][] = !this.groupByPartition
                ? filteredStatisticResults.map((result) => [result])
                : partitionIds.map((partitionId) =>
                      filteredStatisticResults.filter((item) => item.partitionId === partitionId)
                  );

            return groups.map((group) => ({
                result: sumStatisticResults(group),
                compare: sumStatisticResults(
                    this.compareStatisticResults.filter((cr) =>
                        group.some((r) => cr.partitionId === r.partitionId && cr.dealerId === r.dealerId)
                    )
                ),
            }));
        },

        quickFilters(): QuickFilter[] {
            const timeZone = userSession.timeZone;
            const locale = userSession.locale;

            const relativeStartOf = (offset: number, unit: UnitOfTime) =>
                getDate(startOf(addDuration(this.now, timeZone, offset, unit), timeZone, locale, unit), timeZone);

            const relativeEndOf = (offset: number, unit: UnitOfTime) =>
                getDate(endOf(addDuration(this.now, timeZone, offset, unit), timeZone, locale, unit), timeZone);

            return [
                {
                    label: this.$t("Heute"),
                    from: relativeStartOf(0, UnitOfTime.DAY),
                    to: relativeEndOf(0, UnitOfTime.DAY),
                    compareFrom: relativeStartOf(-1, UnitOfTime.DAY),
                    compareTo: relativeEndOf(-1, UnitOfTime.DAY),
                },
                {
                    label: this.$t("Aktuelle Woche"),
                    from: relativeStartOf(0, UnitOfTime.WEEK),
                    to: relativeEndOf(0, UnitOfTime.WEEK),
                    compareFrom: relativeStartOf(-1, UnitOfTime.WEEK),
                    compareTo: relativeEndOf(-1, UnitOfTime.WEEK),
                },
                {
                    label: this.$t("Letzte Woche"),
                    from: relativeStartOf(-1, UnitOfTime.WEEK),
                    to: relativeEndOf(-1, UnitOfTime.WEEK),
                    compareFrom: relativeStartOf(-2, UnitOfTime.WEEK),
                    compareTo: relativeEndOf(-2, UnitOfTime.WEEK),
                },
                {
                    label: this.$t("Aktueller Monat"),
                    from: relativeStartOf(0, UnitOfTime.MONTH),
                    to: relativeEndOf(0, UnitOfTime.MONTH),
                    compareFrom: relativeStartOf(-1, UnitOfTime.MONTH),
                    compareTo: relativeEndOf(-1, UnitOfTime.MONTH),
                },
                {
                    label: this.$t("Letzter Monat"),
                    from: relativeStartOf(-1, UnitOfTime.MONTH),
                    to: relativeEndOf(-1, UnitOfTime.MONTH),
                    compareFrom: relativeStartOf(-2, UnitOfTime.MONTH),
                    compareTo: relativeEndOf(-2, UnitOfTime.MONTH),
                },
                {
                    label: this.$t("Vorletzter Monat"),
                    from: relativeStartOf(-2, UnitOfTime.MONTH),
                    to: relativeEndOf(-2, UnitOfTime.MONTH),
                    compareFrom: relativeStartOf(-3, UnitOfTime.MONTH),
                    compareTo: relativeEndOf(-3, UnitOfTime.MONTH),
                },
            ];
        },

        sortedItems(): Item[] {
            return sortStatisticResults(this.items, (item) => item.result, this.sortBy);
        },

        statisticResults(): StatisticResult[] {
            return this.dealerStatistics.map((statistic) => ({
                dealerId: statistic.dealerId,
                name: statistic.name,
                partitionId: statistic.partitionId,
                partition: this.partitions.find((p) => p.id === statistic.partitionId) ?? null,
                statistic: toStatistic(statistic, 1, this.timeWindowDays),
            }));
        },

        timeWindowDays(): number {
            return (
                1 +
                Math.ceil(
                    moment.duration(moment(this.loadFilter.dateRange.to).diff(this.loadFilter.dateRange.from)).asDays()
                )
            );
        },

        timeZone(): string {
            return userSession.timeZone;
        },

        total(): StatisticResult {
            return sumStatisticResults(this.items.map((item) => item.result));
        },

        uncoveredCompensationSubjectOptions(): SelectOption[] {
            return [
                {
                    text: this.$t("Software-Tage"),
                    value: CompensationSubject.SOFTWARE_LICENSE,
                },
                {
                    text: this.$t("Click-to-Call-Minuten"),
                    value: CompensationSubject.CLICK_TO_CALL_MINUTE,
                },
                {
                    text: this.$t("Call-Tracking-Minuten"),
                    value: CompensationSubject.CALL_TRACKING_MINUTE,
                },
                {
                    text: this.$t("Ausgehende SMS"),
                    value: CompensationSubject.OUTGOING_SMS,
                },
                {
                    text: this.$t("Externe-BDC-Minuten"),
                    value: CompensationSubject.EXTERNAL_BDC_MINUTE,
                },
            ];
        },
    },

    methods: {
        isQuickFilterSelected(quickFilter: QuickFilter) {
            return (
                this.loadFilter.dateRange.from === quickFilter.from && this.loadFilter.dateRange.to === quickFilter.to
            );
        },

        async loadItems() {
            this.compareDealerStatistics = [];
            this.dealerStatistics = [];
            this.loading = true;
            const searchId = ++this.searchId;

            await this.loadLimiter.execute(async () => {
                try {
                    const [dealerStatistics, compareDealerStatistics] = await Promise.all([
                        dealerStatisticsApi.getDealerStatistics(
                            this.loadFilter.dateRange.from,
                            this.loadFilter.dateRange.to
                        ),
                        this.loadFilter.compare
                            ? dealerStatisticsApi.getDealerStatistics(this.compareFrom, this.compareTo)
                            : Promise.resolve([]),
                    ]);

                    if (searchId === this.searchId) {
                        this.dealerStatistics = dealerStatistics;
                        this.compareDealerStatistics = compareDealerStatistics;
                    }
                } finally {
                    if (searchId === this.searchId) {
                        this.loading = false;
                    }
                }
            });
        },

        async refresh() {
            await this.loadItems();
        },

        setTimeRangeByQuickFilter(quickFilter: QuickFilter) {
            this.loadFilter = {
                ...this.loadFilter,
                dateRange: {
                    from: quickFilter.from,
                    to: quickFilter.to,
                },
                compareRange: {
                    from: quickFilter.compareFrom,
                    to: quickFilter.compareTo,
                },
            };
        },
    },

    watch: {
        loadFilter: {
            deep: true,
            async handler() {
                try {
                    await this.loadItems();
                } catch (e) {
                    this.$nextTick(() => {
                        throw e;
                    });
                }
            },
        },
    },

    async mounted() {
        this.partitions = await partitionsApi.list();

        await this.loadItems();
    },

    components: {
        DateRangePicker,
        DealerStatisticsTableRow,
        EnumField,
    },
});

import { $t } from "./i18n";
import { showAlert, showError } from "./messageUtil";
import { BadRequest, errorsApi, Forbidden, ServerError, Unauthorized } from "@/api/errors";
import axios, { AxiosError } from "axios";
import StackTrace from "stacktrace-js";
import Vue from "vue";

function asI18nValues(args: any) {
    return typeof args === "object" ? args : [args];
}

async function reportError(error: Error) {
    let stack: string | null;
    try {
        const frames = await StackTrace.fromError(error);
        const lines = [`${error}`];
        for (const frame of frames) {
            lines.push(
                `    at ${frame.getFunctionName() ||
                    "<anonymous>"} (${frame.getFileName()}:${frame.getLineNumber()}:${frame.getColumnNumber()})`
            );
        }
        stack = lines.join("\n");
    } catch (e) {
        stack = null;
    }
    await errorsApi.reportError({
        name: error.name,
        message: error.message,
        stack,
        userAgent: navigator.userAgent,
        url: (error as AxiosError).config?.url || null,
        method: (error as AxiosError).config?.method || null,
    });
}

Vue.config.errorHandler = async (error: any) => {
    if (error.from && error.to) {
        // ignore vue-router errors
        return;
    }
    if (error instanceof Unauthorized) {
        await showAlert($t("Sitzung abgelaufen") as string, $t("Bitte loggen Sie sich erneut ein.") as string);
        window.location.reload();
    } else {
        let msg: string;
        if (error instanceof BadRequest) {
            msg = $t(error.details[0].messageKey, asI18nValues(error.details[0].rejectedValue)) as string;
        } else if (error instanceof ServerError) {
            msg = `${$t("Server-Fehler")} ${error.uuid} (${error.path})`;
        } else {
            if (error.error) {
                error = error.error;
            }
            if (!(error instanceof Error)) {
                error = new Error(error);
            }
            msg = `${error}`;
            // eslint-disable-next-line no-console
            console.warn(error);
            reportError(error);
        }
        showError(msg);
    }
};

axios.interceptors.response.use(undefined, async (error: AxiosError) => {
    const response = error.response;
    if (response) {
        if (response.status === 400 && Array.isArray(response.data) && response.data.length) {
            throw new BadRequest(response.data);
        }
        if (response.status === 500 && response.data.uuid) {
            throw new ServerError(response.data.uuid, response.data.path);
        }
        if (response.status === 401) {
            throw new Unauthorized();
        }
        if (response.status === 403) {
            throw new Forbidden(error);
        }
    }
    throw error;
});


import { Dealer, dealersApi } from "@/api/dealers";
import {
    ActorType,
    emergencyIncidentSummariesApi,
    EmergencyIncidentSummary,
    EmergencyIncidentSummarySearchOrder,
    EmergencyIncidentType,
} from "@/api/emergencyIncidentSummaries";
import { partitionsApi, PartitionSummary } from "@/api/partitions";
import { Permission } from "@/api/userSession";
import { User, usersApi } from "@/api/users";
import DataTable from "@/app/components/DataTable.vue";
import EnumField from "@/app/components/EnumField.vue";
import TimeSpan from "@/app/components/TimeSpan.vue";
import { DataTableHeader } from "@/app/components/dataTable";
import IconWithTooltip from "@/app/pages/IconWithTooltip.vue";
import { getFullName } from "@/app/userUtils";
import { userSession } from "@/store/userSession";
import { formatInstant } from "@/util/dateTimeUtils";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            EmergencyIncidentSummarySearchOrder,
            EmergencyIncidentType,
            dealers: [] as Dealer[],
            items: [] as EmergencyIncidentSummary[],
            loading: true,
            partitions: [] as PartitionSummary[],
            sortBy: EmergencyIncidentSummarySearchOrder.CREATED_ASC,
            users: [] as User[],
        };
    },

    computed: {
        headers(): DataTableHeader[] {
            return [
                {
                    text: this.$t("Notdienstvorgang"),
                    width: "30%",
                },
                {
                    text: this.$t("Typ und Details"),
                    width: "35%",
                },
                {
                    text: this.$t("Kontakt, Fahrzeug und Aktivitäten") as string,
                    width: "35%",
                },
            ];
        },
    },

    methods: {
        canManageEmergencyIncidents(): boolean {
            return userSession.hasPermission(Permission.MANAGE_EMERGENCY_INCIDENTS);
        },

        getDealerLink(dealerId: string, partitionId: string): string | null {
            const host = this.partitions.find((p) => p.id === partitionId)?.host;

            if (!host) {
                return null;
            }

            return `https://${host}/location-details?dealerId=${dealerId}`;
        },

        getDealerNameById(dealerId: string) {
            return this.dealers.find((d) => d.id === dealerId)?.name || this.$t("Unbekannter Standort");
        },

        getEmergencyIncidentLink(emergencyIncidentId: string, dealerId: string, partitionId: string): string | null {
            const host = this.partitions.find((p) => p.id === partitionId)?.host;

            if (!host) {
                return null;
            }

            return `https://${host}/emergency-incident/${emergencyIncidentId}?dealerId=${dealerId}`;
        },

        getPartitionLink(partitionId: string): string | null {
            const host = this.partitions.find((p) => p.id === partitionId)?.host;

            if (!host) {
                return null;
            }

            return `https://${host}/dealerstubs`;
        },

        getPartitionNameById(partitionId: string) {
            return this.partitions.find((p) => p.id === partitionId)?.name || this.$t("Unbekannte Partition");
        },

        getTimestamp(date: Date): string {
            return formatInstant(date, userSession.timeZone, userSession.locale, "S");
        },

        getUserFullNameById(userId: string | null) {
            const user = this.users.find((u) => u.id === userId);

            if (!user) {
                return this.$t("Unbekannter Benutzer");
            }

            return getFullName(user);
        },

        async loadItems() {
            this.loading = true;

            try {
                this.items = [];
                this.items = await emergencyIncidentSummariesApi.search({
                    creatorActorType: [ActorType.CT_USER],
                    sortBy: this.sortBy,
                });
            } catch (e) {
                throw e;
            } finally {
                this.loading = false;
            }
        },
    },

    async mounted() {
        this.dealers = await dealersApi.list();
        this.partitions = await partitionsApi.list();
        this.users = await usersApi.list();

        await this.loadItems();
    },

    components: {
        DataTable,
        EnumField,
        IconWithTooltip,
        TimeSpan,
    },
});

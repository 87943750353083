
import { availableTimeZones } from "@/util/dateTimeUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        value: String,
    },

    computed: {
        timeZones() {
            return availableTimeZones();
        },
        timeZoneRules() {
            return [
                (v: string) =>
                    availableTimeZones().some((t) => t === v) || this.$t("Gültige Zeitzone ist erforderlich"),
            ];
        },
    },
});


import BwaMonthDealerRevenueDataTableRow from "./BwaMonthDealerRevenueDataTableRow.vue";
import { BwaMonthDealerRevenue, bwaRevenuesApi } from "@/api/bwarevenues";
import { Dealer, dealersApi } from "@/api/dealers";
import { InvoiceRevenueData } from "@/api/invoicerevenues";
import { partitionsApi, PartitionSummary } from "@/api/partitions";
import CsvDownloadIcon from "@/app/components/CsvDownloadIcon.vue";
import MonthPicker from "@/app/components/MonthPicker.vue";
import {
    addInvoiceRevenueData,
    getOtherRevenue,
    ZERO_INVOICE_REVENUE_DATA,
} from "@/app/pages/invoicerevenue/invoiceRevenueUtils";
import { getFileBasename } from "@/app/pages/reporting/downloadUtils";
import { configStore } from "@/store/config";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { cloneObject } from "@/util/cloneUtils";
import { addDuration, getCurrentMonth, UnitOfTime } from "@/util/dateTimeUtils";
import { SelectOption } from "@/util/types";
import Vue from "vue";

interface BwaMonthDealerRevenueWithNames extends BwaMonthDealerRevenue {
    readonly dealerName: string | null;
    readonly partitionId: string | null;
    readonly partitionName: string | null;
}

interface Total {
    readonly dealerCount: number;
    readonly partitionCount: number;
    readonly unpaidRevenueTotal: number;
    readonly invoiceRevenueData: InvoiceRevenueData;
}

export default Vue.extend({
    data() {
        const ts = now();
        const timeZone = configStore.configuration.organisationTimeZone;

        return {
            dealerRevenuesWithNames: [] as BwaMonthDealerRevenueWithNames[],
            dealers: [] as Dealer[],
            loadFilter: {
                from: getCurrentMonth(addDuration(ts, timeZone, -1, UnitOfTime.MONTH), timeZone),
                to: getCurrentMonth(addDuration(ts, timeZone, -1, UnitOfTime.MONTH), timeZone),
            },
            loading: true,
            localFilter: {
                dealerIds: [] as string[],
                partitionIds: [] as string[],
            },
            now: ts,
            partitions: [] as PartitionSummary[],
            searchId: 1,
        };
    },

    computed: {
        csvRevenueData(): string[][] {
            return [
                [
                    this.$t("Partition") as string,
                    this.$t("Standort") as string,
                    this.$t("Monat") as string,
                    this.$t("Gesamtumsatz") as string,
                    this.$t("Software") as string,
                    this.$t("BDC (wiederkehrend)") as string,
                    this.$t("BDC (variabel)") as string,
                    this.$t("Click-to-Call") as string,
                    this.$t("Call-Tracking") as string,
                    this.$t("SMS") as string,
                    this.$t("WhatsApp") as string,
                    this.$t("Einrichtung") as string,
                    this.$t("Sonstiges") as string,
                ],
                ...this.items.map((i) => [
                    i.partitionId === null
                        ? (this.$t("Unbestimmte Partition") as string)
                        : i.partitionName ?? (this.$t("Unbekannte Partition") as string),
                    i.dealerId === null
                        ? (this.$t("Unbestimmter Standort") as string)
                        : i.dealerName ?? (this.$t("Unbekannter Standort") as string),
                    i.bwaMonth.month.toString(),
                    this.formatPriceForCsv(i.invoiceRevenueData.totalRevenue),
                    this.formatPriceForCsv(i.invoiceRevenueData.softwareRevenue),
                    this.formatPriceForCsv(i.invoiceRevenueData.fixedBdcRevenue),
                    this.formatPriceForCsv(i.invoiceRevenueData.variableBdcRevenue),
                    this.formatPriceForCsv(i.invoiceRevenueData.clickToCallRevenue),
                    this.formatPriceForCsv(i.invoiceRevenueData.callTrackingRevenue),
                    this.formatPriceForCsv(i.invoiceRevenueData.outgoingSmsRevenue),
                    this.formatPriceForCsv(i.invoiceRevenueData.whatsAppNumberRevenue),
                    this.formatPriceForCsv(i.invoiceRevenueData.setupRevenue),
                    this.formatPriceForCsv(getOtherRevenue(i.invoiceRevenueData)),
                ]),
            ];
        },

        csvRevenueFilename(): string {
            const prefix = getFileBasename(
                this.$t("BWA") as string,
                this.loadFilter.from,
                this.loadFilter.from !== this.loadFilter.to ? this.loadFilter.to : null,
                this.$t("Händlerumsatz") as string
            );

            return `${prefix}.csv`;
        },

        currency(): string {
            return configStore.configuration.organisationCurrency;
        },

        dealerOptions(): SelectOption[] {
            return this.dealers
                .map((d) => ({ value: d.id, text: d.name }))
                .sort((a, b) => a.text.localeCompare(b.text, userSession.locale));
        },

        items(): BwaMonthDealerRevenueWithNames[] {
            return this.dealerRevenuesWithNames
                .filter(
                    (r) =>
                        !this.localFilter.dealerIds.length ||
                        (!!r.dealerId && this.localFilter.dealerIds.includes(r.dealerId))
                )
                .filter(
                    (r) =>
                        !this.localFilter.partitionIds.length ||
                        (!!r.partitionId && this.localFilter.partitionIds.includes(r.partitionId))
                );
        },

        partitionOptions(): SelectOption[] {
            return this.partitions
                .map((p) => ({ value: p.id, text: p.name }))
                .sort((a, b) => a.text.localeCompare(b.text, userSession.locale));
        },

        total(): Total | null {
            if (!this.items.length) {
                return null;
            }

            return {
                dealerCount: new Set(this.items.map((i) => i.dealerId).filter((dealerId) => dealerId)).size,
                partitionCount: new Set(this.items.map((i) => i.partitionId).filter((partitionId) => partitionId)).size,
                unpaidRevenueTotal: this.items.map((r) => r.unpaidRevenueTotal).reduce((a, b) => a + b, 0),
                invoiceRevenueData: this.items.reduce(
                    (revenueData, item) => addInvoiceRevenueData(revenueData, item.invoiceRevenueData),
                    cloneObject(ZERO_INVOICE_REVENUE_DATA)
                ),
            };
        },
    },

    methods: {
        formatPriceForCsv(value: number): string {
            return this.$n(value, {
                minimumFractionDigits: "2",
                maximumFractionDigits: "4",
                useGrouping: false as any, // interface requires string, but the implementation a number
            });
        },

        async loadItems() {
            this.dealerRevenuesWithNames = [];
            this.loading = true;
            const searchId = ++this.searchId;

            try {
                const dealerRevenuesWithNames: BwaMonthDealerRevenueWithNames[] = (
                    await bwaRevenuesApi.getDealerRevenuesByBwaMonthRange(this.loadFilter.from, this.loadFilter.to)
                )
                    .map((r) => ({
                        ...r,
                        dealerName: this.dealers.find((d) => d.id === r.dealerId)?.name ?? null,
                        partitionName: this.partitions.find((p) => p.id === r.partitionId)?.name ?? null,
                    }))
                    .sort(
                        (a, b) =>
                            (a.partitionName ?? "").localeCompare(b.partitionName ?? "", userSession.locale) ||
                            (a.dealerName ?? "").localeCompare(b.dealerName ?? "", userSession.locale) ||
                            -1 * a.bwaMonth.month.localeCompare(b.bwaMonth.month, userSession.locale)
                    );

                if (searchId === this.searchId) {
                    this.dealerRevenuesWithNames = dealerRevenuesWithNames;
                }
            } finally {
                if (searchId === this.searchId) {
                    this.loading = false;
                }
            }
        },

        async refresh() {
            await this.loadItems();
        },
    },

    watch: {
        loadFilter: {
            deep: true,
            async handler() {
                try {
                    await this.loadItems();
                } catch (e) {
                    this.$nextTick(() => {
                        throw e;
                    });
                }
            },
        },
    },

    async mounted() {
        this.dealers = await dealersApi.list();
        this.partitions = await partitionsApi.list();

        await this.loadItems();
    },

    components: {
        CsvDownloadIcon,
        BwaMonthDealerRevenueDataTableRow,
        MonthPicker,
    },
});

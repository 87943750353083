
import { BillingAccount } from "@/api/billingAccounts";
import { CompensationSubject, CompensationSubjectCost, Contract, ContractForm, contractsApi } from "@/api/contracts";
import DatePicker from "@/app/components/DatePicker.vue";
import NumberField from "@/app/components/NumberField.vue";
import { showConfirm } from "@/app/messageUtil";
import { currency, formatAmount, maxLength, notEmpty } from "@/app/validation";
import { configStore } from "@/store/config";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { addDuration, endOf, toDateObject, UnitOfTime } from "@/util/dateTimeUtils";
import { trimAndReturnNullIfEmpty } from "@/util/stringUtils";
import { SelectOption } from "@/util/types/SelectOptions";
import Vue from "vue";

export default Vue.extend({
    props: {
        contract: {
            type: Object as () => Contract | null,
            default: null,
        },
        billingAccounts: {
            type: Array as () => BillingAccount[],
            required: true,
        },
        dealerId: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            CompensationSubject,
            configStore,
            valid: true,
            isWorking: false,
            contractId: this.contract?.id || null,
            begin: this.contract?.begin || "",
            end: this.contract?.end || "",
            durationMonths: this.contract?.durationMonths?.toString() || "0",
            noticePeriodMonths: this.contract?.noticePeriodMonths?.toString() || "0",
            notes: this.contract?.notes || "",
            notesRules: maxLength(8000),
            commissionBillingAccountId: this.contract?.commissionBillingAccountId || null,
            commissionPercentage: this.contract?.commissionPercentage || "",
            commissionPercentageRules: notEmpty().decimal({ min: 0, max: 100, totalDigits: 5, precision: 2 }),
            currencyRules: notEmpty().decimal(currency(configStore.configuration.organisationCurrency)),
            unitsIncludedRules: notEmpty().integer(),
            compensationSubjectCosts: {} as Partial<Record<CompensationSubject, CompensationSubjectCost>>,
            notEmptyRules: notEmpty(),
        };
    },

    computed: {
        billingAccountOptions(): SelectOption[] {
            return this.billingAccounts.map((billingAccount) => ({
                value: billingAccount.id,
                text: billingAccount.companyName,
            }));
        },

        compensationSubjects(): CompensationSubject[] {
            return Object.keys(CompensationSubject) as CompensationSubject[];
        },

        durations(): SelectOption[] {
            return [...Array(120).keys()]
                .map((v) => v + 1)
                .map((month) => ({
                    value: month.toString(),
                    text: this.$tc("Keine | 1 Monat | {count} Monate", month),
                }));
        },

        timeZone(): string {
            return configStore.configuration.organisationTimeZone;
        },
    },

    methods: {
        async submitForm() {
            if (!this.valid) {
                (this.$refs.form as any).validate();
                return;
            }

            if (
                this.isInvoiceGoingToBeCreatedForPreviousBillingMonth() &&
                !(await showConfirm(
                    this.$t("Automatische Rechnungserstellung") as string,
                    this.$t(
                        "Für zurückdatierte Verträge wird automatisch eine Rechnung für die vorherige Abrechnungsperiode erstellt. Sind Sie sicher, dass Sie fortfahren möchten?"
                    ) as string
                ))
            ) {
                return;
            }

            this.isWorking = true;

            const contractForm: ContractForm = {
                begin: this.begin!,
                end: this.end,
                durationMonths: parseInt(this.durationMonths, 10),
                noticePeriodMonths: parseInt(this.noticePeriodMonths, 10),
                notes: this.notes,
                commissionBillingAccountId: this.commissionBillingAccountId,
                commissionPercentage: this.commissionPercentage,
                compensationItems: this.compensationSubjectCosts,
            };

            try {
                if (this.contractId) {
                    await contractsApi.edit(this.contractId, contractForm);
                } else {
                    await contractsApi.add(this.dealerId, contractForm);
                }

                this.$emit("success");
            } finally {
                this.isWorking = false;
            }
        },

        close() {
            this.$emit("close");
        },

        isInvoiceGoingToBeCreatedForPreviousBillingMonth(): boolean {
            const billingTimeZone = configStore.configuration.organisationTimeZone;

            const oldContractBegin = this.contract ? toDateObject(billingTimeZone, this.contract.begin) : null;
            const newContractBegin = !!trimAndReturnNullIfEmpty(this.begin)
                ? toDateObject(billingTimeZone, this.begin!)
                : null;

            const previousBillingMonthEnd = endOf(
                addDuration(now(), billingTimeZone, -1, UnitOfTime.MONTH),
                billingTimeZone,
                userSession.locale,
                UnitOfTime.MONTH
            );

            const oldContractCoversPreviousBillingMonth =
                !!oldContractBegin && oldContractBegin.getTime() < previousBillingMonthEnd.getTime();

            const newContractCoversPreviousBillingMonth =
                !!newContractBegin && newContractBegin.getTime() < previousBillingMonthEnd.getTime();

            return !oldContractCoversPreviousBillingMonth && newContractCoversPreviousBillingMonth;
        },
    },

    async beforeMount() {
        const compensationSubjectCosts: Partial<Record<CompensationSubject, CompensationSubjectCost>> = {};
        for (const key of Object.keys(CompensationSubject)) {
            const compensationSubject = key as CompensationSubject;
            const compensationItems = this.contract?.compensationItems || {};
            const compensationSubjectCost = compensationItems[compensationSubject] || {
                monthlyFee: "0",
                unitsIncluded: 0,
                pricePerAdditionalUnit: "0",
            };
            compensationSubjectCosts[compensationSubject] = {
                monthlyFee: formatAmount(
                    compensationSubjectCost.monthlyFee,
                    configStore.configuration.organisationCurrency
                ),
                unitsIncluded: compensationSubjectCost.unitsIncluded,
                pricePerAdditionalUnit: formatAmount(
                    compensationSubjectCost.pricePerAdditionalUnit,
                    configStore.configuration.organisationCurrency
                ),
            };
        }
        this.compensationSubjectCosts = compensationSubjectCosts;
    },

    components: {
        DatePicker,
        NumberField,
    },
});

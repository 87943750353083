import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export interface Country {
    readonly code: string;
    readonly states: string[];
}

export interface Configuration {
    readonly shortCommitId: string;
    readonly buildTime: Date;
    readonly primaryDomain: string;
    readonly ftpDomain: string;
    readonly appName: string;
    readonly defaultLocale: string;
    readonly organisationCompany: string;
    readonly organisationCity: string;
    readonly organisationCountry: string;
    readonly organisationTimeZone: string;
    readonly organisationCurrency: string;
    readonly waitingTimeThresholdSecondsForConsideringUnansweredCalls: number;
    readonly countries: Country[];
    readonly messages: { [locale: string]: { [key: string]: string } };
}

interface ConfigurationApi {
    config(): Promise<Configuration>;
}

export const configurationApi: ConfigurationApi = {
    async config() {
        return cloneObject((await axios.get("/api/frontend-config")).data);
    },
};


import { Dealer } from "@/api/dealers";
import { DisconnectStatus, ExternalBdcCdr } from "@/api/externalBdcCdrs";
import { PartitionSummary } from "@/api/partitions";
import { User } from "@/api/users";
import ExternalBdcCdrsBottomSheet from "@/app/pages/externalbdccdr/ExternalBdcCdrsBottomSheet.vue";
import { getReportingTableByReportingBarChart } from "@/app/pages/reporting/chartUtils";
import {
    hasNonZeroSeries,
    isNonZeroSeries,
    ReportingBarChartData,
} from "@/app/pages/reporting/charts/reportingBarChart";
import ReportingDashboardBarTile from "@/app/pages/reporting/dashboard/ReportingDashboardBarTile.vue";
import {
    mapExternalBdcCdrRowKeyToRowLabel,
    WithDuration,
    WithPartitionId,
} from "@/app/pages/reporting/externalbdc/externalBdcRowUtils";
import { groupRowsBy } from "@/app/pages/reporting/pivotUtils";
import { TitledRowGroup } from "@/app/pages/reporting/rowUtils";
import { ReportingTableData } from "@/app/pages/reporting/table/reportingTable";
import { groupByAsMap } from "@/util/arrayUtils";
import Vue from "vue";

type ComputedExternalBdcCdrRow = PrecomputedExternalBdcCdrRow;
type ComputedExternalBdcCdrRowGroupBy = keyof Pick<ComputedExternalBdcCdrRow, "dealerId" | "partitionId" | "userId">;

type PrecomputedExternalBdcCdrRow = ExternalBdcCdr & WithDuration & WithPartitionId;

export default Vue.extend({
    props: {
        chartHeight: {
            type: Number,
            required: false,
        },
        dealers: {
            type: Array as () => readonly Dealer[],
            required: true,
        },
        durationThreshold: {
            type: Number,
            required: true,
        },
        externalBdcCdrRows: {
            type: Array as () => readonly PrecomputedExternalBdcCdrRow[],
            required: true,
        },
        groupBy: {
            type: String as () => "DEALER" | "PARTITION" | "USER",
            required: true,
        },
        loading: {
            type: Boolean,
            required: true,
        },
        partitions: {
            type: Array as () => readonly PartitionSummary[],
            required: true,
        },
        subtitle: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            required: false,
        },
        users: {
            type: Array as () => readonly User[],
            required: true,
        },
    },

    data() {
        return {
            bottomSheetExternalBdcCdrIds: [] as number[],
            bottomSheetTitle: null as string | null,
            bottomSheetVisible: false,
        };
    },

    computed: {
        chart(): ReportingBarChartData | null {
            const topCategories = this.rowGroups
                .filter((rowGroup) => !!rowGroup.key || this.groupBy !== "USER")
                .map((rowGroup) => ({
                    categoryId: rowGroup.key,
                    name: rowGroup.title,
                    description: rowGroup.subtitle,
                    rows: rowGroup.rows,
                    rowsByDisconnectStatus: groupByAsMap(rowGroup.rows, (r) => r.disconnectStatus),
                }))
                .map((rowGroup) => ({
                    ...rowGroup,
                    sortValue:
                        rowGroup.rowsByDisconnectStatus.get(DisconnectStatus.ORIGINATOR_DISCONNECTED)?.length ?? 0,
                }))
                .sort((a, b) => b.sortValue - a.sortValue);

            const chart: ReportingBarChartData = {
                title: "",
                categories: topCategories.map((c) => ({ name: c.name, description: c.description })),
                series: [
                    DisconnectStatus.ORIGINATOR_DISCONNECTED,
                    DisconnectStatus.CALL_DISCONNECTED,
                    ...(Object.keys(DisconnectStatus) as DisconnectStatus[]),
                ]
                    .filter((disconnectStatus, index, array) => array.indexOf(disconnectStatus) === index)
                    .map((disconnectStatus) => ({
                        id: disconnectStatus,
                        name: this.$t(`enum.DisconnectStatus.${disconnectStatus}`) as string,
                        data: {
                            values: topCategories.map((c) => ({
                                value: (c.rowsByDisconnectStatus.get(disconnectStatus) ?? []).length,
                                onClick: this.showBottomSheetOnClick(
                                    (c.rowsByDisconnectStatus.get(disconnectStatus) ?? []).map((r) => r.id),
                                    c.name
                                ),
                            })),
                        },
                    }))
                    .map((series) => ({
                        ...series,
                        selected: isNonZeroSeries(series),
                    })),
            };

            if (!hasNonZeroSeries(chart)) {
                return null;
            }

            return chart;
        },

        groupByField(): ComputedExternalBdcCdrRowGroupBy | null {
            if (this.groupBy === "DEALER") {
                return "dealerId";
            } else if (this.groupBy === "PARTITION") {
                return "partitionId";
            } else if (this.groupBy === "USER") {
                return "userId";
            } else {
                return null;
            }
        },

        rowGroups(): readonly TitledRowGroup<string | null, ComputedExternalBdcCdrRow>[] {
            return groupRowsBy(this.externalBdcCdrRows, (r) => r[this.groupByField!]).map((rowGroup) => {
                const rowGroupLabel = mapExternalBdcCdrRowKeyToRowLabel(rowGroup.key, this.groupByField!, {
                    dealers: this.dealers,
                    partitions: this.partitions,
                    users: this.users,
                });

                return {
                    ...rowGroup,
                    title: rowGroupLabel.label,
                    subtitle: rowGroupLabel.sublabel,
                };
            });
        },

        table(): ReportingTableData | null {
            if (!this.chart) {
                return null;
            }

            return {
                ...getReportingTableByReportingBarChart(this.chart),
                groupByHeaderText:
                    this.groupBy === "DEALER"
                        ? (this.$t("Standort") as string)
                        : this.groupBy === "PARTITION"
                        ? (this.$t("Partition") as string)
                        : this.groupBy === "USER"
                        ? (this.$t("Benutzer") as string)
                        : undefined,
            };
        },
    },

    methods: {
        hideBottomSheet() {
            this.bottomSheetVisible = false;
            this.bottomSheetTitle = null;
            this.bottomSheetExternalBdcCdrIds = [];
        },

        showBottomSheetOnClick(externalBdcCdrIds: number[], title: string): () => void {
            return () => {
                this.bottomSheetExternalBdcCdrIds = [...new Set(externalBdcCdrIds)];
                this.bottomSheetTitle = title;
                this.bottomSheetVisible = true;
            };
        },
    },

    components: {
        ExternalBdcCdrsBottomSheet,
        ReportingDashboardBarTile,
    },
});


import { ShiftScheduleTemplate, ShiftScheduleTemplateForm } from "@/api/shiftScheduleTemplates";
import { usersApi } from "@/api/users";
import { notEmpty } from "@/app/validation";
import { userSession } from "@/store/userSession";
import { SelectOptions } from "@/util/types/SelectOptions";
import Vue from "vue";

export default Vue.extend({
    props: {
        template: {
            type: Object as () => ShiftScheduleTemplate | null,
            required: false,
        },
        saving: Boolean,
    },

    data() {
        return {
            form: {
                name: this.template?.name || "",
                userIds: this.template?.userIds || [],
            } as ShiftScheduleTemplateForm,
            agents: [] as SelectOptions,
            loading: false,
            notEmpty,
        };
    },

    methods: {
        submitForm() {
            if (!(this.$refs.form as any).validate()) {
                return;
            }
            this.$emit("submit", this.form);
        },
    },

    async mounted() {
        this.loading = true;
        try {
            this.agents = (await usersApi.list())
                .filter((u) => u.weeklyWorkingHours !== null)
                .map((u) => ({
                    value: u.id,
                    text: `${u.givenName} ${u.familyName} (${u.username})`,
                }))
                .sort((a, b) => a.text.localeCompare(b.text, userSession.locale));
        } finally {
            this.loading = false;
        }
    },
});


import { StatisticResult } from "./dealerStatisticsOverview";
import Vue from "vue";

export default Vue.extend({
    props: {
        isColumnTotal: {
            type: Boolean,
            default: false,
        },
        isCompareRow: {
            type: Boolean,
            default: false,
        },
        isComparing: {
            type: Boolean,
            default: false,
        },
        isGrouped: {
            type: Boolean,
            default: false,
        },
        result: {
            type: Object as () => StatisticResult,
            required: true,
        },
    },

    computed: {
        tdClass(): string {
            return this.isCompareRow ? "py-0 pl-4 pr-2" : "text-no-wrap py-1 pl-4 pr-2";
        },
    },

    methods: {
        getHighlightEqualClass(value1: number | null, value2: number | null): string | null {
            if (this.isCompareRow) {
                return null;
            } else if (value1 === value2) {
                return "success--text";
            } else {
                return "error--text";
            }
        },

        renderPercentage(value: number | null): string | null {
            if (value === null) {
                return null;
            }

            return this.$n(value, { style: "percent", minimumFractionDigits: "2" });
        },
    },
});

import axios from "axios";

export enum DutyRosterEntryType {
    ANSWER_INCOMING_ORDINARY_CALLS = "ANSWER_INCOMING_ORDINARY_CALLS",
    ANSWER_INCOMING_PRIORITY_CALLS = "ANSWER_INCOMING_PRIORITY_CALLS",
    TRAINING = "TRAINING",
    OTHER_WORK = "OTHER_WORK",
    HOLIDAY = "HOLIDAY",
    OVERTIME_COMPENSATION = "OVERTIME_COMPENSATION",
    OTHER_TIME_COMPENSATION = "OTHER_TIME_COMPENSATION",
    SICKNESS = "SICKNESS",
    SICK_CHILD = "SICK_CHILD",
    OTHER_UNPAID_ABSENCE = "OTHER_UNPAID_ABSENCE",
}

export interface DutyRosterEntry {
    readonly day: string;
    readonly beginTime: string;
    readonly endTime: string;
    readonly type: DutyRosterEntryType;
    readonly includedBreakMinutes: number;
}

export interface DutyRosterEntryWithUserId {
    readonly userId: string;
    readonly entry: DutyRosterEntry;
}

interface DutyRostersApi {
    getEntries(start: string, end: string): Promise<DutyRosterEntryWithUserId[]>;
    getEntriesByUserAndWeek(userId: string, firstDayOfWeek: string): Promise<DutyRosterEntry[]>;
    editEntriesByUserAndWeek(userId: string, firstDayOfWeek: string, entries: DutyRosterEntry[]): Promise<void>;
    getScheduledTime(start: string, weeks: number): Promise<{ [userId: string]: number[] }>;
    getScheduledTimeByUser(userId: string, start: string, weeks: number): Promise<number[]>;
    getEntriesForCurrentUser(start: string, end: string): Promise<DutyRosterEntry[]>;
}

export const dutyRostersApi: DutyRostersApi = {
    async getEntries(start, end) {
        return (await axios.get("/api/duty-rosters", { params: { start, end } })).data;
    },

    async getEntriesByUserAndWeek(userId, firstDayOfWeek) {
        return (await axios.get(`/api/duty-rosters/${userId}`, { params: { firstDayOfWeek } })).data;
    },

    async editEntriesByUserAndWeek(userId, firstDayOfWeek, entries) {
        await axios.post(`/api/duty-rosters/${userId}`, entries, { params: { firstDayOfWeek } });
    },

    async getScheduledTime(start, weeks) {
        return (await axios.get("/api/duty-rosters/_scheduled-time", { params: { start, weeks } })).data;
    },

    async getScheduledTimeByUser(userId, start, weeks) {
        return (await axios.get(`/api/duty-rosters/${userId}/_scheduled-time`, { params: { start, weeks } })).data;
    },

    async getEntriesForCurrentUser(start, end) {
        return (await axios.get("/api/duty-rosters/_current-user", { params: { start, end } })).data;
    },
};

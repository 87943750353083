import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export enum CompensationSubject {
    SOFTWARE_LICENSE = "SOFTWARE_LICENSE",
    EXTERNAL_BDC_CALL = "EXTERNAL_BDC_CALL",
    EXTERNAL_BDC_MINUTE = "EXTERNAL_BDC_MINUTE",
    CLICK_TO_CALL_MINUTE = "CLICK_TO_CALL_MINUTE",
    CALL_TRACKING_MINUTE = "CALL_TRACKING_MINUTE",
    OUTGOING_SMS = "OUTGOING_SMS",
    WHATSAPP_NUMBER = "WHATSAPP_NUMBER",
}

export enum ContractSearchOrder {
    CREATED_DESC = "CREATED_DESC",
    CREATED_ASC = "CREATED_ASC",
    BEGIN_DESC = "BEGIN_DESC",
    BEGIN_ASC = "BEGIN_ASC",
    END_DESC = "END_DESC",
    END_ASC = "END_ASC",
}

export interface CompensationSubjectCost {
    readonly monthlyFee: string;
    readonly unitsIncluded: number;
    readonly pricePerAdditionalUnit: string;
}

export interface Contract {
    readonly id: number;
    readonly created: Date;
    readonly updated: Date;
    readonly dealerId: string;
    readonly begin: string;
    readonly end: string | null;
    readonly durationMonths: number;
    readonly noticePeriodMonths: number;
    readonly notes: string | null;
    readonly commissionBillingAccountId: number | null;
    readonly commissionPercentage: string | null;
    readonly compensationItems: Partial<Record<CompensationSubject, CompensationSubjectCost>>;
}

export interface ContractForm {
    readonly begin: string;
    readonly end: string | null;
    readonly durationMonths: number;
    readonly noticePeriodMonths: number;
    readonly notes: string | null;
    readonly commissionBillingAccountId: number | null;
    readonly commissionPercentage: string | null;
    readonly compensationItems: Partial<Record<CompensationSubject, CompensationSubjectCost>>;
}

export interface ContractSearchRequest {
    readonly dealerIds: string[];
    readonly hasCommissionBillingAccountId: boolean | null;
    readonly commissionPercentageFrom: string | null;
    readonly commissionPercentageTo: string | null;
    readonly createdFrom: Date | null;
    readonly createdTo: Date | null;
    readonly beginFrom: string | null;
    readonly beginTo: string | null;
    readonly endFrom: string | null;
    readonly endTo: string | null;
    readonly sortBy: ContractSearchOrder;
}

export interface ContractSearchResults {
    readonly searchId: number;
    readonly totalSize: number;
    readonly results: Contract[];
}

interface ContractsApi {
    add(dealerId: string, contractForm: ContractForm): Promise<void>;
    edit(id: number, contractForm: ContractForm): Promise<void>;
    delete(id: number): Promise<void>;
    list(): Promise<Contract[]>;
    search(
        start: number,
        size: number,
        searchRequest: ContractSearchRequest,
        searchId: number
    ): Promise<ContractSearchResults>;
}

export const contractsApi: ContractsApi = {
    async add(dealerId: string, contractForm: ContractForm) {
        await axios.post("/api/contracts", contractForm, {
            params: {
                dealerId,
            },
        });
    },

    async edit(id: number, contractForm: ContractForm) {
        await axios.put(`/api/contracts/${id}`, contractForm);
    },

    async delete(id: number) {
        await axios.delete(`/api/contracts/${id}`);
    },
    async list() {
        return cloneObject((await axios.get("/api/contracts")).data);
    },

    async search(start, size, searchRequest, searchId) {
        return cloneObject(
            (await axios.post("/api/contracts/_search", searchRequest, { params: { start, size, searchId } })).data
        );
    },
};

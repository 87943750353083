
import DutyRosterMonthlyView from "./DutyRosterMonthlyView.vue";
import { DutyRosterEntry, dutyRostersApi } from "@/api/dutyRosters";
import moment from "moment-timezone";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            entries: [] as DutyRosterEntry[],
            start: "",
        };
    },

    computed: {
        startSecondMonth(): string {
            return moment(this.start)
                .add(1, "month")
                .format()
                .substring(0, 10);
        },
    },

    async created() {
        const start = moment().startOf("month");
        const end = start.clone().add(2, "month");
        this.start = start.format().substring(0, 10);
        this.entries = await dutyRostersApi.getEntriesForCurrentUser(this.start, end.format().substring(0, 10));
    },

    components: {
        DutyRosterMonthlyView,
    },
});

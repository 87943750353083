export function debounce(f: (this: any, ...args: any[]) => any, timeout: number) {
    const t = Math.random()
        .toString(36)
        .substring(2);
    return function(this: any, ...args: any[]) {
        if (this[t]) {
            clearTimeout(this[t]);
        }
        this[t] = setTimeout(() => f.apply(this, args), timeout);
    };
}

type Action = () => Promise<void>;

export class ActionLimiter {
    private isRunning_ = false;
    private pendingAction?: Action;
    private pendingResolves: (() => void)[] = [];

    constructor(private block = false) {}

    get isRunning() {
        return this.isRunning_;
    }

    async execute(action: Action) {
        if (this.isRunning_) {
            this.pendingAction = action;

            if (this.block) {
                return new Promise((resolve) => {
                    this.pendingResolves.push(resolve);
                });
            }

            return;
        }

        this.isRunning_ = true;
        try {
            let lastError: any;

            try {
                await action();
            } catch (e) {
                lastError = e;
            }

            while (this.pendingAction) {
                const a = this.pendingAction;
                this.pendingAction = undefined;

                const pendingResolves = this.pendingResolves;
                this.pendingResolves = [];

                try {
                    await a();
                } catch (e) {
                    lastError = e;
                }

                for (const pendingResolve of pendingResolves) {
                    pendingResolve();
                }
            }

            if (lastError) {
                throw lastError;
            }
        } finally {
            this.isRunning_ = false;
        }
    }
}

import { ReportingChartColorPalette } from "./reportingChart";

export interface ReportingDonutChartData {
    readonly title: string;
    readonly subtitle?: string;
    readonly series: ReportingDonutChartSeries[];
    readonly seriesDataTooltipHeader?: string;
    readonly seriesAdditionalTooltipDataTooltipHeaders?: string[];
}

export interface ReportingDonutChartSeries {
    readonly id: string;
    readonly name: string;
    readonly tooltip?: string;
    readonly data: ReportingDonutChartSeriesData;
    readonly color?: string;
    readonly colorPalette?: ReportingChartColorPalette;
    readonly additionalTooltipData?: ReportingDonutChartSeriesData[];
}

export interface ReportingDonutChartSeriesData {
    readonly value: ReportingDonutChartSeriesValue;
    readonly formatter?: ReportingDonutChartSeriesValueFormatter;
}

export interface ReportingDonutChartSeriesValue {
    readonly value: number;
    readonly onClick?: () => Promise<void> | void;
}

export type ReportingDonutChartSeriesValueFormatter = (
    value: number | undefined,
    context: "LABEL" | "TOOLTIP"
) => string | null;

export function isNonZeroSeries(series: ReportingDonutChartSeries): boolean {
    return (series.data.value.value ?? 0) !== 0;
}

export function hasNonZeroSeries(chart: ReportingDonutChartData): boolean {
    return chart.series.some(isNonZeroSeries);
}

import axios from "axios";

export interface ShiftScheduleTemplateEntry {
    readonly weekday: number;
    readonly beginTime: string;
    readonly endTime: string;
    readonly includedBreakMinutes: number;
}

export interface ShiftScheduleTemplate {
    readonly id: string;
    readonly name: string;
    entries: ShiftScheduleTemplateEntry[];
    readonly userIds: string[];
}

export interface ShiftScheduleTemplateForm {
    readonly name: string;
    readonly userIds: string[];
}

interface ShiftScheduleTemplatesApi {
    getTemplates(): Promise<ShiftScheduleTemplate[]>;
    addTemplate(form: ShiftScheduleTemplateForm): Promise<void>;
    editTemplate(id: string, form: ShiftScheduleTemplateForm): Promise<void>;
    editEntries(id: string, entries: ShiftScheduleTemplateEntry[]): Promise<void>;
    deleteTemplate(id: string): Promise<void>;
}

export const shiftScheduleTemplatesApi: ShiftScheduleTemplatesApi = {
    async getTemplates() {
        return (await axios.get("/api/shift-schedule-templates")).data;
    },

    async addTemplate(form) {
        await axios.post("/api/shift-schedule-templates", form);
    },

    async editTemplate(id, form) {
        await axios.put(`/api/shift-schedule-templates/${id}`, form);
    },

    async editEntries(id, entries) {
        await axios.post(`/api/shift-schedule-templates/${id}`, entries);
    },

    async deleteTemplate(id) {
        await axios.delete(`/api/shift-schedule-templates/${id}`);
    },
};

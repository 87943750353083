export function trimAndReturnNullIfEmpty(val: string | null | undefined, maxLength?: number) {
    if (!val) {
        return null;
    }

    const trimmedVal = val.trim();
    if (!trimmedVal.length) {
        return null;
    }

    if (!maxLength && maxLength !== 0) {
        return trimmedVal;
    }

    const maxLengthString = trimmedVal.length > maxLength ? trimmedVal.substring(0, maxLength) : trimmedVal;

    if (!maxLengthString.length) {
        return null;
    }

    return maxLengthString;
}

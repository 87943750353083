
import { email, validate } from "@/app/validation";
import Vue from "vue";

export default Vue.extend({
    props: {
        value: {
            type: Array as () => string[],
            required: true,
        },
    },

    data() {
        return {
            delimiters: [",", ";", " "],
        };
    },

    methods: {
        focusout(e: Event) {
            if (!(this.$refs.field as any | undefined)?.onEnterDown) {
                return;
            }

            (this.$refs.field as any).onEnterDown(e);
        },

        input(value: string[]) {
            const addresses = value.filter((address) => !!address);

            const validAddresses = [];
            const invalidAddresses = [];

            for (const address of addresses) {
                if (validate(email(), address)) {
                    validAddresses.push(address);
                } else {
                    invalidAddresses.push(address);
                }
            }

            this.$emit("input", [...new Set(validAddresses)], [...new Set(invalidAddresses)]);
        },

        paste(event: ClipboardEvent) {
            event.preventDefault();

            const data = event.clipboardData;

            if (!data) {
                return;
            }

            const pastedAddresses = [...this.delimiters, "<", ">", "\r", "\n", "\t"]
                .reduce(
                    (recs, delimiter) =>
                        recs.map((r) => r.split(delimiter)).reduce((prev, cur) => prev.concat(cur), []),
                    [data.getData("text/plain")]
                )
                .filter((a) => !!a)
                .filter((a) => validate(email(), a));

            this.input([...this.value, ...pastedAddresses]);
        },
    },
});

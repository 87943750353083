import axios from "axios";

export interface FtpAccount {
    readonly id: string;
    readonly label: string;
    readonly username: string;
    readonly password: string;
    readonly internal: boolean;
}

export interface FtpAccountForm {
    label: string;
    username: string;
    password: string;
    internal: boolean;
}

interface FtpAccountApi {
    list(): Promise<FtpAccount[]>;
    add(ftpAccountForm: FtpAccountForm): Promise<void>;
    edit(id: string, ftpAccountForm: FtpAccountForm): Promise<void>;
    delete(id: string): Promise<void>;
}

export const ftpAccountApi: FtpAccountApi = {
    async list() {
        return (await axios.get("/api/ftp-accounts")).data;
    },

    async add(ftpAccountForm) {
        await axios.post("/api/ftp-accounts", ftpAccountForm);
    },

    async edit(id, ftpAccountForm) {
        await axios.put(`/api/ftp-accounts/${id}`, ftpAccountForm);
    },

    async delete(id) {
        await axios.delete(`/api/ftp-accounts/${id}`);
    },
};

import { ExtendedAgentStatisticsData } from "./agentStatisticsUtils";

export enum AgentActionStatisticsDataTableSearchOrder {
    ELAPSED_SECONDS_ANSWER_INCOMING_ORDINARY_CALLS_DESC = "ELAPSED_SECONDS_ANSWER_INCOMING_ORDINARY_CALLS_DESC",
    ELAPSED_SECONDS_ANSWER_INCOMING_ORDINARY_CALLS_ASC = "ELAPSED_SECONDS_ANSWER_INCOMING_ORDINARY_CALLS_ASC",
    ELAPSED_SECONDS_ANSWER_INCOMING_PRIORITY_CALLS_DESC = "ELAPSED_SECONDS_ANSWER_INCOMING_PRIORITY_CALLS_DESC",
    ELAPSED_SECONDS_ANSWER_INCOMING_PRIORITY_CALLS_ASC = "ELAPSED_SECONDS_ANSWER_INCOMING_PRIORITY_CALLS_ASC",
    ELAPSED_SECONDS_TRAINING_DESC = "ELAPSED_SECONDS_TRAINING_DESC",
    ELAPSED_SECONDS_TRAINING_ASC = "ELAPSED_SECONDS_TRAINING_ASC",
    ELAPSED_SECONDS_OTHER_WORK_DESC = "ELAPSED_SECONDS_OTHER_WORK_DESC",
    ELAPSED_SECONDS_OTHER_WORK_ASC = "ELAPSED_SECONDS_OTHER_WORK_ASC",
    ELAPSED_SECONDS_TOTAL_DESC = "ELAPSED_SECONDS_TOTAL_DESC",
    ELAPSED_SECONDS_TOTAL_ASC = "ELAPSED_SECONDS_TOTAL_ASC",
    CALLS_ACCEPTED_DESC = "CALLS_ACCEPTED_DESC",
    CALLS_ACCEPTED_ASC = "CALLS_ACCEPTED_ASC",
    CALL_PERFORMANCE_DESC = "CALL_PERFORMANCE_DESC",
    CALL_PERFORMANCE_ASC = "CALL_PERFORMANCE_ASC",
    BILLED_MINUTES_DESC = "BILLED_MINUTES_DESC",
    BILLED_MINUTES_ASC = "BILLED_MINUTES_ASC",
    BILLED_MINUTES_PERFORMANCE_DESC = "BILLED_MINUTES_PERFORMANCE_DESC",
    BILLED_MINUTES_PERFORMANCE_ASC = "BILLED_MINUTES_PERFORMANCE_ASC",
    ERRORS_REPORTED_DESC = "ERRORS_REPORTED_DESC",
    ERRORS_REPORTED_ASC = "ERRORS_REPORTED_ASC",
    ERROR_RATIO_DESC = "ERROR_RATIO_DESC",
    ERROR_RATIO_ASC = "ERROR_RATIO_ASC",
    ISSUES_CREATED_TO_CALLS_ACCEPTED_RATIO_DESC = "ISSUES_CREATED_TO_CALLS_ACCEPTED_RATIO_DESC",
    ISSUES_CREATED_TO_CALLS_ACCEPTED_RATIO_ASC = "ISSUES_CREATED_TO_CALLS_ACCEPTED_RATIO_ASC",
    NOTES_CREATED_TO_CALLS_ACCEPTED_RATIO_DESC = "NOTES_CREATED_TO_CALLS_ACCEPTED_RATIO_DESC",
    NOTES_CREATED_TO_CALLS_ACCEPTED_RATIO_ASC = "NOTES_CREATED_TO_CALLS_ACCEPTED_RATIO_ASC",
    CONTACTS_CREATED_TO_ISSUES_CREATED_RATIO_DESC = "CONTACTS_CREATED_TO_ISSUES_CREATED_RATIO_DESC",
    CONTACTS_CREATED_TO_ISSUES_CREATED_RATIO_ASC = "CONTACTS_CREATED_TO_ISSUES_CREATED_RATIO_ASC",
    SECONDS_ISSUES_CREATED_AFTER_CALL_DESC = "SECONDS_ISSUES_CREATED_AFTER_CALL_DESC",
    SECONDS_ISSUES_CREATED_AFTER_CALL_ASC = "SECONDS_ISSUES_CREATED_AFTER_CALL_ASC",
    SECONDS_ISSUES_CREATED_AFTER_CALL_MEDIAN_DESC = "SECONDS_ISSUES_CREATED_AFTER_CALL_MEDIAN_DESC",
    SECONDS_ISSUES_CREATED_AFTER_CALL_MEDIAN_ASC = "SECONDS_ISSUES_CREATED_AFTER_CALL_MEDIAN_ASC",
    SCHEDULED_DAYS_WITH_ANSWER_INCOMING_ORDINARY_CALLS_DESC = "SCHEDULED_DAYS_WITH_ANSWER_INCOMING_ORDINARY_CALLS_DESC",
    SCHEDULED_DAYS_WITH_ANSWER_INCOMING_ORDINARY_CALLS_ASC = "SCHEDULED_DAYS_WITH_ANSWER_INCOMING_ORDINARY_CALLS_ASC",
    SCHEDULED_DAYS_WITH_ANSWER_INCOMING_PRIORITY_CALLS_DESC = "SCHEDULED_DAYS_WITH_ANSWER_INCOMING_PRIORITY_CALLS_DESC",
    SCHEDULED_DAYS_WITH_ANSWER_INCOMING_PRIORITY_CALLS_ASC = "SCHEDULED_DAYS_WITH_ANSWER_INCOMING_PRIORITY_CALLS_ASC",
    SCHEDULED_DAYS_WITH_TRAINING_DESC = "SCHEDULED_DAYS_WITH_TRAINING_DESC",
    SCHEDULED_DAYS_WITH_TRAINING_ASC = "SCHEDULED_DAYS_WITH_TRAINING_ASC",
    SCHEDULED_DAYS_WITH_OTHER_WORK_DESC = "SCHEDULED_DAYS_WITH_OTHER_WORK_DESC",
    SCHEDULED_DAYS_WITH_OTHER_WORK_ASC = "SCHEDULED_DAYS_WITH_OTHER_WORK_ASC",
    SCHEDULED_DAYS_WITH_HOLIDAY_DESC = "SCHEDULED_DAYS_WITH_HOLIDAY_DESC",
    SCHEDULED_DAYS_WITH_HOLIDAY_ASC = "SCHEDULED_DAYS_WITH_HOLIDAY_ASC",
    SCHEDULED_DAYS_WITH_OVERTIME_COMPENSATION_DESC = "SCHEDULED_DAYS_WITH_OVERTIME_COMPENSATION_DESC",
    SCHEDULED_DAYS_WITH_OVERTIME_COMPENSATION_ASC = "SCHEDULED_DAYS_WITH_OVERTIME_COMPENSATION_ASC",
    SCHEDULED_DAYS_WITH_OTHER_TIME_COMPENSATION_DESC = "SCHEDULED_DAYS_WITH_OTHER_TIME_COMPENSATION_DESC",
    SCHEDULED_DAYS_WITH_OTHER_TIME_COMPENSATION_ASC = "SCHEDULED_DAYS_WITH_OTHER_TIME_COMPENSATION_ASC",
    SCHEDULED_DAYS_WITH_SICKNESS_DESC = "SCHEDULED_DAYS_WITH_SICKNESS_DESC",
    SCHEDULED_DAYS_WITH_SICKNESS_ASC = "SCHEDULED_DAYS_WITH_SICKNESS_ASC",
    SCHEDULED_DAYS_WITH_SICK_CHILD_DESC = "SCHEDULED_DAYS_WITH_SICK_CHILD_DESC",
    SCHEDULED_DAYS_WITH_SICK_CHILD_ASC = "SCHEDULED_DAYS_WITH_SICK_CHILD_ASC",
    SCHEDULED_DAYS_WITH_OTHER_UNPAID_ABSENCE_DESC = "SCHEDULED_DAYS_WITH_OTHER_UNPAID_ABSENCE_DESC",
    SCHEDULED_DAYS_WITH_OTHER_UNPAID_ABSENCE_ASC = "SCHEDULED_DAYS_WITH_OTHER_UNPAID_ABSENCE_ASC",
}

export function getAgentStatisticsSortField(
    extendedAgentStatisticsData: ExtendedAgentStatisticsData,
    searchOrder: AgentActionStatisticsDataTableSearchOrder
): number | null {
    if (searchOrder === AgentActionStatisticsDataTableSearchOrder.ELAPSED_SECONDS_ANSWER_INCOMING_ORDINARY_CALLS_DESC) {
        return extendedAgentStatisticsData.elapsedSeconds.answerIncomingOrdinaryCalls;
    } else if (
        searchOrder === AgentActionStatisticsDataTableSearchOrder.ELAPSED_SECONDS_ANSWER_INCOMING_ORDINARY_CALLS_ASC
    ) {
        return extendedAgentStatisticsData.elapsedSeconds.answerIncomingOrdinaryCalls;
    } else if (
        searchOrder === AgentActionStatisticsDataTableSearchOrder.ELAPSED_SECONDS_ANSWER_INCOMING_PRIORITY_CALLS_DESC
    ) {
        return extendedAgentStatisticsData.elapsedSeconds.answerIncomingPriorityCalls;
    } else if (
        searchOrder === AgentActionStatisticsDataTableSearchOrder.ELAPSED_SECONDS_ANSWER_INCOMING_PRIORITY_CALLS_ASC
    ) {
        return extendedAgentStatisticsData.elapsedSeconds.answerIncomingPriorityCalls;
    } else if (searchOrder === AgentActionStatisticsDataTableSearchOrder.ELAPSED_SECONDS_TRAINING_DESC) {
        return extendedAgentStatisticsData.elapsedSeconds.training;
    } else if (searchOrder === AgentActionStatisticsDataTableSearchOrder.ELAPSED_SECONDS_TRAINING_ASC) {
        return extendedAgentStatisticsData.elapsedSeconds.training;
    } else if (searchOrder === AgentActionStatisticsDataTableSearchOrder.ELAPSED_SECONDS_OTHER_WORK_DESC) {
        return extendedAgentStatisticsData.elapsedSeconds.otherWork;
    } else if (searchOrder === AgentActionStatisticsDataTableSearchOrder.ELAPSED_SECONDS_OTHER_WORK_ASC) {
        return extendedAgentStatisticsData.elapsedSeconds.otherWork;
    } else if (searchOrder === AgentActionStatisticsDataTableSearchOrder.ELAPSED_SECONDS_TOTAL_DESC) {
        return (
            extendedAgentStatisticsData.elapsedSeconds.answerIncomingOrdinaryCalls +
            extendedAgentStatisticsData.elapsedSeconds.answerIncomingPriorityCalls +
            extendedAgentStatisticsData.elapsedSeconds.training +
            extendedAgentStatisticsData.elapsedSeconds.otherWork
        );
    } else if (searchOrder === AgentActionStatisticsDataTableSearchOrder.ELAPSED_SECONDS_TOTAL_ASC) {
        return (
            extendedAgentStatisticsData.elapsedSeconds.answerIncomingOrdinaryCalls +
            extendedAgentStatisticsData.elapsedSeconds.answerIncomingPriorityCalls +
            extendedAgentStatisticsData.elapsedSeconds.training +
            extendedAgentStatisticsData.elapsedSeconds.otherWork
        );
    } else if (searchOrder === AgentActionStatisticsDataTableSearchOrder.CALLS_ACCEPTED_DESC) {
        return extendedAgentStatisticsData.callsAccepted;
    } else if (searchOrder === AgentActionStatisticsDataTableSearchOrder.CALLS_ACCEPTED_ASC) {
        return extendedAgentStatisticsData.callsAccepted;
    } else if (searchOrder === AgentActionStatisticsDataTableSearchOrder.CALL_PERFORMANCE_DESC) {
        return extendedAgentStatisticsData.callPerformance;
    } else if (searchOrder === AgentActionStatisticsDataTableSearchOrder.CALL_PERFORMANCE_ASC) {
        return extendedAgentStatisticsData.callPerformance;
    } else if (searchOrder === AgentActionStatisticsDataTableSearchOrder.BILLED_MINUTES_DESC) {
        return extendedAgentStatisticsData.billedMinutes;
    } else if (searchOrder === AgentActionStatisticsDataTableSearchOrder.BILLED_MINUTES_ASC) {
        return extendedAgentStatisticsData.billedMinutes;
    } else if (searchOrder === AgentActionStatisticsDataTableSearchOrder.BILLED_MINUTES_PERFORMANCE_DESC) {
        return extendedAgentStatisticsData.billedMinutesPerformance;
    } else if (searchOrder === AgentActionStatisticsDataTableSearchOrder.BILLED_MINUTES_PERFORMANCE_ASC) {
        return extendedAgentStatisticsData.billedMinutesPerformance;
    } else if (searchOrder === AgentActionStatisticsDataTableSearchOrder.ERRORS_REPORTED_DESC) {
        return extendedAgentStatisticsData.errorsReported;
    } else if (searchOrder === AgentActionStatisticsDataTableSearchOrder.ERRORS_REPORTED_ASC) {
        return extendedAgentStatisticsData.errorsReported;
    } else if (searchOrder === AgentActionStatisticsDataTableSearchOrder.ERROR_RATIO_DESC) {
        return extendedAgentStatisticsData.errorRatio;
    } else if (searchOrder === AgentActionStatisticsDataTableSearchOrder.ERROR_RATIO_ASC) {
        return extendedAgentStatisticsData.errorRatio;
    } else if (searchOrder === AgentActionStatisticsDataTableSearchOrder.ISSUES_CREATED_TO_CALLS_ACCEPTED_RATIO_DESC) {
        return extendedAgentStatisticsData.issuesCreatedToCallsAcceptedRatio;
    } else if (searchOrder === AgentActionStatisticsDataTableSearchOrder.ISSUES_CREATED_TO_CALLS_ACCEPTED_RATIO_ASC) {
        return extendedAgentStatisticsData.issuesCreatedToCallsAcceptedRatio;
    } else if (searchOrder === AgentActionStatisticsDataTableSearchOrder.NOTES_CREATED_TO_CALLS_ACCEPTED_RATIO_DESC) {
        return extendedAgentStatisticsData.notesCreatedToCallsAcceptedRatio;
    } else if (searchOrder === AgentActionStatisticsDataTableSearchOrder.NOTES_CREATED_TO_CALLS_ACCEPTED_RATIO_ASC) {
        return extendedAgentStatisticsData.notesCreatedToCallsAcceptedRatio;
    } else if (
        searchOrder === AgentActionStatisticsDataTableSearchOrder.CONTACTS_CREATED_TO_ISSUES_CREATED_RATIO_DESC
    ) {
        return extendedAgentStatisticsData.contactsCreatedToIssuesCreatedRatio;
    } else if (searchOrder === AgentActionStatisticsDataTableSearchOrder.CONTACTS_CREATED_TO_ISSUES_CREATED_RATIO_ASC) {
        return extendedAgentStatisticsData.contactsCreatedToIssuesCreatedRatio;
    } else if (searchOrder === AgentActionStatisticsDataTableSearchOrder.SECONDS_ISSUES_CREATED_AFTER_CALL_DESC) {
        return extendedAgentStatisticsData.secondsIssuesCreatedAfterCall;
    } else if (searchOrder === AgentActionStatisticsDataTableSearchOrder.SECONDS_ISSUES_CREATED_AFTER_CALL_ASC) {
        return extendedAgentStatisticsData.secondsIssuesCreatedAfterCall;
    } else if (
        searchOrder === AgentActionStatisticsDataTableSearchOrder.SECONDS_ISSUES_CREATED_AFTER_CALL_MEDIAN_DESC
    ) {
        return extendedAgentStatisticsData.secondsIssuesCreatedAfterCallMedian;
    } else if (searchOrder === AgentActionStatisticsDataTableSearchOrder.SECONDS_ISSUES_CREATED_AFTER_CALL_MEDIAN_ASC) {
        return extendedAgentStatisticsData.secondsIssuesCreatedAfterCallMedian;
    } else if (
        searchOrder ===
        AgentActionStatisticsDataTableSearchOrder.SCHEDULED_DAYS_WITH_ANSWER_INCOMING_ORDINARY_CALLS_DESC
    ) {
        return extendedAgentStatisticsData.scheduledDays.answerIncomingOrdinaryCalls;
    } else if (
        searchOrder === AgentActionStatisticsDataTableSearchOrder.SCHEDULED_DAYS_WITH_ANSWER_INCOMING_ORDINARY_CALLS_ASC
    ) {
        return extendedAgentStatisticsData.scheduledDays.answerIncomingOrdinaryCalls;
    } else if (
        searchOrder ===
        AgentActionStatisticsDataTableSearchOrder.SCHEDULED_DAYS_WITH_ANSWER_INCOMING_PRIORITY_CALLS_DESC
    ) {
        return extendedAgentStatisticsData.scheduledDays.answerIncomingPriorityCalls;
    } else if (
        searchOrder === AgentActionStatisticsDataTableSearchOrder.SCHEDULED_DAYS_WITH_ANSWER_INCOMING_PRIORITY_CALLS_ASC
    ) {
        return extendedAgentStatisticsData.scheduledDays.answerIncomingPriorityCalls;
    } else if (searchOrder === AgentActionStatisticsDataTableSearchOrder.SCHEDULED_DAYS_WITH_TRAINING_DESC) {
        return extendedAgentStatisticsData.scheduledDays.training;
    } else if (searchOrder === AgentActionStatisticsDataTableSearchOrder.SCHEDULED_DAYS_WITH_TRAINING_ASC) {
        return extendedAgentStatisticsData.scheduledDays.training;
    } else if (searchOrder === AgentActionStatisticsDataTableSearchOrder.SCHEDULED_DAYS_WITH_OTHER_WORK_DESC) {
        return extendedAgentStatisticsData.scheduledDays.otherWork;
    } else if (searchOrder === AgentActionStatisticsDataTableSearchOrder.SCHEDULED_DAYS_WITH_OTHER_WORK_ASC) {
        return extendedAgentStatisticsData.scheduledDays.otherWork;
    } else if (searchOrder === AgentActionStatisticsDataTableSearchOrder.SCHEDULED_DAYS_WITH_HOLIDAY_DESC) {
        return extendedAgentStatisticsData.scheduledDays.holiday;
    } else if (searchOrder === AgentActionStatisticsDataTableSearchOrder.SCHEDULED_DAYS_WITH_HOLIDAY_ASC) {
        return extendedAgentStatisticsData.scheduledDays.holiday;
    } else if (
        searchOrder === AgentActionStatisticsDataTableSearchOrder.SCHEDULED_DAYS_WITH_OVERTIME_COMPENSATION_DESC
    ) {
        return extendedAgentStatisticsData.scheduledDays.overtimeCompensation;
    } else if (
        searchOrder === AgentActionStatisticsDataTableSearchOrder.SCHEDULED_DAYS_WITH_OVERTIME_COMPENSATION_ASC
    ) {
        return extendedAgentStatisticsData.scheduledDays.overtimeCompensation;
    } else if (
        searchOrder === AgentActionStatisticsDataTableSearchOrder.SCHEDULED_DAYS_WITH_OTHER_TIME_COMPENSATION_DESC
    ) {
        return extendedAgentStatisticsData.scheduledDays.otherTimeCompensation;
    } else if (
        searchOrder === AgentActionStatisticsDataTableSearchOrder.SCHEDULED_DAYS_WITH_OTHER_TIME_COMPENSATION_ASC
    ) {
        return extendedAgentStatisticsData.scheduledDays.otherTimeCompensation;
    } else if (searchOrder === AgentActionStatisticsDataTableSearchOrder.SCHEDULED_DAYS_WITH_SICKNESS_DESC) {
        return extendedAgentStatisticsData.scheduledDays.sickness;
    } else if (searchOrder === AgentActionStatisticsDataTableSearchOrder.SCHEDULED_DAYS_WITH_SICKNESS_ASC) {
        return extendedAgentStatisticsData.scheduledDays.sickness;
    } else if (searchOrder === AgentActionStatisticsDataTableSearchOrder.SCHEDULED_DAYS_WITH_SICK_CHILD_DESC) {
        return extendedAgentStatisticsData.scheduledDays.sickChild;
    } else if (searchOrder === AgentActionStatisticsDataTableSearchOrder.SCHEDULED_DAYS_WITH_SICK_CHILD_ASC) {
        return extendedAgentStatisticsData.scheduledDays.sickChild;
    } else if (
        searchOrder === AgentActionStatisticsDataTableSearchOrder.SCHEDULED_DAYS_WITH_OTHER_UNPAID_ABSENCE_DESC
    ) {
        return extendedAgentStatisticsData.scheduledDays.otherUnpaidAbsence;
    } else if (searchOrder === AgentActionStatisticsDataTableSearchOrder.SCHEDULED_DAYS_WITH_OTHER_UNPAID_ABSENCE_ASC) {
        return extendedAgentStatisticsData.scheduledDays.otherUnpaidAbsence;
    } else {
        return extendedAgentStatisticsData.callsAccepted;
    }
}

export function isAgentStatisticsAscendingSort(searchOrder: AgentActionStatisticsDataTableSearchOrder): boolean {
    return searchOrder.endsWith("_ASC");
}

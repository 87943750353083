
import DutyRosterPanelBottomSheet from "./DutyRosterPanelBottomSheet.vue";
import { getDutyRosterEventColor } from "./dutyRosterUtils";
import { BdcTeam, bdcTeamsApi } from "@/api/bdcTeams";
import { DutyRosterEntryType, DutyRosterEntryWithUserId, dutyRostersApi } from "@/api/dutyRosters";
import { CallsPerUserAndTime, externalBdcCdrsApi } from "@/api/externalBdcCdrs";
import { UserOnlinePeriod, userTrackingApi } from "@/api/userTracking";
import { usersApi } from "@/api/users";
import EnumField from "@/app/components/EnumField.vue";
import { configStore } from "@/store/config";
import { userSession } from "@/store/userSession";
import { formatLocalTime, getDate, getFirstDayOfWeek } from "@/util/dateTimeUtils";
import { SelectOption } from "@/util/types";
import { CalendarOptions, DatesSetArg, EventContentArg, EventInput } from "@fullcalendar/core";
import FullCalendar from "@fullcalendar/vue";
// eslint-disable-next-line import/order
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import moment from "moment-timezone";
import Vue from "vue";

interface Agent {
    readonly id: string;
    readonly title?: string;
}

function toDate(ts: Date) {
    return moment(ts)
        .format()
        .substring(0, 10);
}

function toTime(ts: Date) {
    return moment(ts)
        .format()
        .substring(11, 16);
}

const SUMMARY_ID = "X";

export default Vue.extend({
    data() {
        return {
            DutyRosterEntryType,
            agents: [] as Agent[],
            bdcTeams: [] as readonly BdcTeam[],
            callsPerUserAndTimeList: [] as CallsPerUserAndTime[],
            dutyRosterEntries: [] as DutyRosterEntryWithUserId[],
            dutyRosterPanelBottomSheetUserId: null as string | null,
            dutyRosterPanelBottomSheetScheduleUpdated: false,
            end: null as Date | null,
            localFilter: {
                agentHasAtLeastOneOfDutyRosterEntryTypes: [] as DutyRosterEntryType[],
                bdcTeamIds: [] as string[],
                userIds: [] as string[],
                dutyRosterEntriesAvailable: null as boolean | null,
                dutyRosterEntryTypes: [] as DutyRosterEntryType[],
            },
            loading: true,
            start: null as Date | null,
            printDate: "",
            userOnlinePeriods: [] as UserOnlinePeriod[],
            viewType: "resourceTimelineDay",
        };
    },

    computed: {
        agentOptions(): SelectOption[] {
            return this.agents.map((agent) => ({
                text: agent.title ?? "",
                value: agent.id,
            }));
        },

        calendarOptions(): CalendarOptions {
            return {
                schedulerLicenseKey: "0297760784-fcs-1658939163",
                plugins: [resourceTimelinePlugin],
                height: "100%",
                resourceAreaWidth: 280,
                initialView: this.viewType,
                customButtons: {
                    refresh: {
                        text: this.loading ? (this.$t("Lade Daten") as string) : (this.$t("Aktualisieren") as string),
                        click: async () => {
                            try {
                                await this.loadItems();
                            } catch (e) {
                                Vue.nextTick(() => {
                                    throw e;
                                });
                            }
                        },
                    },
                    printButton: {
                        text: this.$t("Drucken") as string,
                        click: () => window.open(`/duty-roster-print?d=${this.printDate}`),
                    },
                },
                headerToolbar: {
                    left: ["prev,next", "refresh", this.printDate && !this.loading ? "printButton" : ""]
                        .filter((s) => !!s)
                        .join(" "),
                    center: "title",
                    right: "today resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth",
                },
                resourceAreaHeaderContent: this.$t("Agenten"),
                resourceAreaHeaderDidMount: ({ el }) => this.fixBackgroundColor(el),
                slotLabelDidMount: ({ el }) => this.fixBackgroundColor(el),
                resourceOrder: "index",
                buttonText: {
                    today: this.$t("heute") as string,
                    day: this.$t("Tag") as string,
                    week: this.$t("Woche") as string,
                    month: this.$t("Monat") as string,
                },
                firstDay: getFirstDayOfWeek(configStore.configuration.defaultLocale),
                locale: userSession.locale,
                resources: [{ id: SUMMARY_ID }, ...this.filteredAgents],
                events: this.events,
                datesSet: async (arg: DatesSetArg) => {
                    if (!this.filteredAgents.length) {
                        return;
                    }

                    if (this.start?.getTime() === arg.start.getTime() && this.end?.getTime() === arg.end.getTime()) {
                        return;
                    }

                    this.start = arg.start;
                    this.end = arg.end;
                    this.viewType = arg.view.type;

                    this.printDate = this.viewType === "resourceTimelineDay" ? toDate(this.start) : "";

                    try {
                        await this.loadItems();
                    } catch (e) {
                        Vue.nextTick(() => {
                            throw e;
                        });
                    }
                },
                eventContent: (arg) => this.renderEvent(arg),
                resourceLabelContent: ({ resource }) => this.renderResource(resource),
                views: {
                    month: {
                        slotMinWidth: 140,
                    },
                },
            };
        },

        bdcTeamOptions(): SelectOption[] {
            return this.bdcTeams.map((bdcTeam) => ({
                text: `${bdcTeam.name} (${this.$tc(
                    "0 Agenten | 1 Agent | {count} Agenten",
                    bdcTeam.memberUserIds.length
                )})`,
                value: bdcTeam.id,
            }));
        },

        endDate(): string | null {
            if (!this.end) {
                return null;
            }

            return getDate(this.end, this.timeZone);
        },

        events(): EventInput[] {
            const agentIds = this.filteredAgentIds;
            const dutyRosterEntries = this.filteredDutyRosterEntriesForTypes.filter((e) => agentIds.has(e.userId));

            return [
                ...(this.viewType === "resourceTimelineMonth"
                    ? this.toDailySummary(dutyRosterEntries)
                    : this.toHourlySummary(dutyRosterEntries)),

                ...this.userOnlinePeriods
                    .filter((e) => agentIds.has(e.userId))
                    .map((e) => ({
                        resourceId: e.userId,
                        start: e.begin!,
                        end: e.end || new Date(),
                        display: "background",
                        color: "rgb(0,255,0)",
                    })),

                ...this.callsPerUserAndTimeList
                    .filter((c) => agentIds.has(c.userId))
                    .map((c) => ({
                        resourceId: c.userId,
                        start: c.timestamp,
                        end: new Date(c.timestamp.getTime() + 1),
                        display: "background",
                        extendedProps: {
                            numberOfCalls: c.numberOfCalls,
                        },
                    })),

                ...dutyRosterEntries.map((e) => ({
                    resourceId: e.userId,
                    start: Date.parse(`${e.entry.day}T${e.entry.beginTime}`),
                    end: Date.parse(`${e.entry.day}T${e.entry.endTime}`) + 60 * 1000,
                    extendedProps: {
                        type: e.entry.type,
                        break: e.entry.includedBreakMinutes,
                    },
                    color: getDutyRosterEventColor(e.entry.type, this.$vuetify.theme.dark),
                })),
            ];
        },

        filteredAgents(): Agent[] {
            const agentIdsByHasAtLeastOneOfDutyRosterEntryTypes = new Set(
                this.dutyRosterEntries
                    .filter(
                        (e) =>
                            !this.localFilter.agentHasAtLeastOneOfDutyRosterEntryTypes.length ||
                            this.localFilter.agentHasAtLeastOneOfDutyRosterEntryTypes.includes(e.entry.type)
                    )
                    .map((e) => e.userId)
            );
            const agentIdsByFilteredDutyRosterEntriesForTypes = new Set(
                this.filteredDutyRosterEntriesForTypes.map((e) => e.userId)
            );

            return this.agents
                .filter(
                    (agent) =>
                        !this.localFilter.bdcTeamIds.length || this.memberUserIdsOfSelectedBdcTeams.includes(agent.id)
                )
                .filter((agent) => !this.localFilter.userIds.length || this.localFilter.userIds.includes(agent.id))
                .filter(
                    (agent) =>
                        !this.localFilter.agentHasAtLeastOneOfDutyRosterEntryTypes.length ||
                        agentIdsByHasAtLeastOneOfDutyRosterEntryTypes.has(agent.id)
                )
                .filter(
                    (agent) =>
                        this.localFilter.dutyRosterEntriesAvailable === null ||
                        this.localFilter.dutyRosterEntriesAvailable ===
                            agentIdsByFilteredDutyRosterEntriesForTypes.has(agent.id)
                );
        },

        filteredAgentIds(): Set<string> {
            return new Set(this.filteredAgents.map((a) => a.id));
        },

        filteredDutyRosterEntriesForTypes(): DutyRosterEntryWithUserId[] {
            return this.dutyRosterEntries.filter(
                (e) =>
                    !this.localFilter.dutyRosterEntryTypes.length ||
                    this.localFilter.dutyRosterEntryTypes.includes(e.entry.type)
            );
        },

        memberUserIdsOfSelectedBdcTeams(): string[] {
            return this.bdcTeams
                .filter((bdcTeam) => this.localFilter.bdcTeamIds.includes(bdcTeam.id))
                .map((bdcTeam) => bdcTeam.memberUserIds)
                .reduce((prev, cur) => prev.concat(cur), []);
        },

        startDate(): string | null {
            if (!this.start) {
                return null;
            }

            return getDate(this.start, this.timeZone);
        },

        timeZone(): string {
            return configStore.configuration.organisationTimeZone;
        },

        trueFalseOptions(): SelectOption[] {
            return [
                { value: true, text: this.$t("Ja") },
                { value: false, text: this.$t("Nein") },
            ];
        },
    },

    methods: {
        async loadItems() {
            const agentIds = new Set(this.agents.map((a) => a.id));

            if (!agentIds.size || !this.start || !this.end) {
                return;
            }

            this.loading = true;

            try {
                const isTimelineMonthView = this.viewType === "resourceTimelineMonth";

                const [dutyRosterEntries, userOnlinePeriods, callsPerUserAndTimeList] = await Promise.all([
                    dutyRostersApi.getEntries(toDate(this.start), toDate(this.end)),
                    !isTimelineMonthView
                        ? userTrackingApi.getUserOnlinePeriods(this.start, this.end)
                        : Promise.resolve([]),
                    !isTimelineMonthView
                        ? externalBdcCdrsApi.getCallsPerUserAndTime(this.start, this.end, 30)
                        : Promise.resolve([]),
                ]);

                this.dutyRosterEntries = dutyRosterEntries as DutyRosterEntryWithUserId[];
                this.userOnlinePeriods = userOnlinePeriods as UserOnlinePeriod[];
                this.callsPerUserAndTimeList = callsPerUserAndTimeList as CallsPerUserAndTime[];
            } finally {
                this.loading = false;
            }
        },

        toDailySummary(entries: DutyRosterEntryWithUserId[]): EventInput[] {
            const resourcesByDay = entries
                .filter(
                    (e) =>
                        e.entry.type === DutyRosterEntryType.ANSWER_INCOMING_ORDINARY_CALLS ||
                        e.entry.type === DutyRosterEntryType.ANSWER_INCOMING_PRIORITY_CALLS
                )
                .reduce((map, e) => {
                    const day = e.entry.day;

                    if (map.has(day)) {
                        map.get(day)!.add(e.userId);
                    } else {
                        map.set(day, new Set([e.userId]));
                    }

                    return map;
                }, new Map<string, Set<string>>());

            return [...resourcesByDay.entries()]
                .map((e) => ({ day: e[0], users: e[1] }))
                .map((e) => ({
                    resourceId: SUMMARY_ID,
                    start: e.day,
                    extendedProps: {
                        numberOfResources: e.users.size,
                    },
                    color: `rgba(255,0,0,${this.numberOfAgentsToOpacity(e.users.size)})`,
                }));
        },

        toHourlySummary(entries: DutyRosterEntryWithUserId[]): EventInput[] {
            const resourcesByHour = entries
                .filter(
                    (e) =>
                        e.entry.type === DutyRosterEntryType.ANSWER_INCOMING_ORDINARY_CALLS ||
                        e.entry.type === DutyRosterEntryType.ANSWER_INCOMING_PRIORITY_CALLS
                )
                .reduce((map, e) => {
                    let hour = Date.parse(`${e.entry.day}T${e.entry.beginTime.substring(0, 3)}00`);
                    const end = Date.parse(`${e.entry.day}T${e.entry.endTime.substring(0, 3)}00`);

                    while (hour <= end) {
                        if (map.has(hour)) {
                            map.get(hour)!.add(e.userId);
                        } else {
                            map.set(hour, new Set([e.userId]));
                        }
                        hour += 60 * 60 * 1000;
                    }

                    return map;
                }, new Map<number, Set<string>>());

            return [...resourcesByHour.entries()]
                .map((e) => ({ hour: e[0], users: e[1] }))
                .map((e) => ({
                    resourceId: SUMMARY_ID,
                    start: e.hour,
                    end: e.hour + 60 * 60 * 1000,
                    extendedProps: {
                        numberOfResources: e.users.size,
                    },
                    color: `rgba(255,0,0,${this.numberOfAgentsToOpacity(e.users.size)})`,
                }));
        },

        numberOfAgentsToOpacity(n: number) {
            if (n < 1) {
                return 0;
            }

            const totalAgents = this.filteredAgents.length - 1;

            if (totalAgents < 1) {
                return 1;
            }

            return 0.3 + (0.7 * n) / totalAgents;
        },

        renderEvent({ event, view }: EventContentArg) {
            // summary
            if (event.extendedProps.numberOfResources) {
                const text = document.createElement("b");
                if (view.type === "resourceTimelineMonth") {
                    text.innerText = this.$tc(
                        "keine Agenten | 1 Agent | {count} Agenten",
                        event.extendedProps.numberOfResources
                    );
                } else {
                    text.innerText = `${event.extendedProps.numberOfResources}`;
                }
                return { domNodes: [text] };
            }

            // number of calls
            if (!!event.extendedProps.numberOfCalls) {
                const text = document.createElement("div");
                text.innerText = `${event.extendedProps.numberOfCalls}`;
                text.style.fontSize = "10px";
                text.style.marginTop = "20px";
                return { domNodes: [text] };
            }

            // online time
            if (event.extendedProps.break === undefined) {
                return;
            }

            // duty roster entry
            const entryLabel = document.createElement("div");
            entryLabel.style.fontSize = "11px";
            entryLabel.style.overflow = "hidden";
            entryLabel.style.textOverflow = "ellipsis";
            entryLabel.style.whiteSpace = "nowrap";

            const entryLabelText = [
                event.extendedProps.type !== DutyRosterEntryType.ANSWER_INCOMING_ORDINARY_CALLS &&
                event.extendedProps.type !== DutyRosterEntryType.ANSWER_INCOMING_PRIORITY_CALLS
                    ? (this.$t(`enum.DutyRosterEntryType.${event.extendedProps.type}`) as string)
                    : null,
                event.extendedProps.break
                    ? (this.$tc("ohne Pause | 1 min Pause | {count} min Pause", event.extendedProps.break) as string)
                    : null,
            ]
                .filter((s) => s)
                .join(", ");

            if (!!entryLabelText) {
                entryLabel.innerText = entryLabelText;
            } else {
                entryLabel.innerHTML = "&nbsp;";
            }

            if (view.type === "resourceTimelineMonth") {
                const range = document.createElement("b");
                range.innerText = `${formatLocalTime(
                    toTime(event.start!),
                    userSession.locale,
                    "S"
                )} - ${formatLocalTime(toTime(event.end!), userSession.locale, "S")}`;
                return { domNodes: [range, document.createElement("br"), entryLabel] };
            }
            return { domNodes: [entryLabel] };
        },

        renderResource(resource: Agent) {
            if (resource.id === SUMMARY_ID) {
                const text = document.createElement("i");
                text.innerText = this.$t("Zusammenfassung") as string;
                return { domNodes: [text] };
            }

            const a = document.createElement("a");

            a.href = "";
            a.innerText = resource.title || "";
            a.onclick = (e: Event) => {
                e.preventDefault();

                this.dutyRosterPanelBottomSheetUserId = resource.id;
                this.dutyRosterPanelBottomSheetScheduleUpdated = false;
            };

            return { domNodes: [a] };
        },

        fixBackgroundColor(el: HTMLElement) {
            if (this.$vuetify.theme.dark) {
                el.style.background = "#121212";
            }
        },

        async hideDutyRosterPanelBottomSheet() {
            const reloadItems = this.dutyRosterPanelBottomSheetScheduleUpdated;

            this.dutyRosterPanelBottomSheetUserId = null;
            this.dutyRosterPanelBottomSheetScheduleUpdated = false;

            if (reloadItems) {
                await this.loadItems();
            }
        },
    },

    async mounted() {
        this.bdcTeams = await bdcTeamsApi.getAll();
        this.agents = (await usersApi.list())
            .filter((u) => u.weeklyWorkingHours !== null)
            .map((u) => ({
                id: u.id,
                title: `${u.givenName} ${u.familyName} (${u.username})`,
            }))
            .sort((a, b) => a.title.localeCompare(b.title, userSession.locale));
    },

    components: {
        DutyRosterPanelBottomSheet,
        EnumField,
        FullCalendar,
    },
});

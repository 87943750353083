import { getSeriesValuesMatrixMaxValue, ReportingChartColorPalette } from "./reportingChart";

export interface ReportingTimeSeriesChartData {
    readonly title: string;
    readonly subtitle?: string;
    readonly series: ReportingTimeSeriesChartSeries[];
    readonly dateFormatter: ReportingTimeSeriesDateFormatter;
    readonly seriesDataTooltipHeader?: string;
    readonly seriesAdditionalTooltipDataTooltipHeaders?: string[];
}

export interface ReportingTimeSeriesChartSeries {
    readonly id: string;
    readonly name: string;
    readonly tooltip?: string;
    readonly selected?: boolean;
    readonly data: ReportingTimeSeriesChartSeriesData;
    readonly color?: string;
    readonly colorPalette?: ReportingChartColorPalette;
    readonly additionalTooltipData?: ReportingTimeSeriesChartSeriesData[];
}

export interface ReportingTimeSeriesChartSeriesData {
    readonly values: ReportingTimeSeriesChartSeriesDataPoint[];
    readonly isPercentage?: boolean;
    readonly formatter?: ReportingTimeSeriesChartSeriesValueFormatter;
}

export interface ReportingTimeSeriesChartSeriesDataPoint {
    readonly date: Date;
    readonly value: number | undefined;
    readonly onClick?: () => Promise<void> | void;
}

export type ReportingTimeSeriesChartSeriesValueFormatter = (
    value: number | undefined,
    isPercentage: boolean,
    context: "LABEL" | "TOOLTIP"
) => string | null;

export type ReportingTimeSeriesDateFormatter = (date: Date, short: boolean) => string;

export function isNonZeroSeries(series: ReportingTimeSeriesChartSeries): boolean {
    return series.data.values.some((v) => (v.value ?? 0) !== 0);
}

export function hasNonZeroSeries(chart: ReportingTimeSeriesChartData): boolean {
    return chart.series.some(isNonZeroSeries);
}

export function getDataPoints(series: ReportingTimeSeriesChartSeries[]): Date[] {
    return series
        .map((series) => series.data.values.map((v) => v.date))
        .reduce((prev, cur) => prev.concat(cur), [] as Date[])
        .sort((a, b) => a.getTime() - b.getTime())
        .filter((date, index, array) => index === 0 || array[index - 1].getTime() !== date.getTime());
}

export function getSeriesMaxValue(series: ReportingTimeSeriesChartSeries[], stacked: boolean): number | undefined {
    if (!series.length) {
        return undefined;
    }

    const indexes = Array.from(series[0].data.values.keys());

    return getSeriesValuesMatrixMaxValue(
        indexes.map((index) => series.map((s) => s.data.values[index].value)),
        stacked
    );
}

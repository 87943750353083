
import { Dealer, dealersApi } from "@/api/dealers";
import {
    CustomInvoiceForm,
    CustomInvoiceLineItemForm,
    InvoiceLineItemCompensationSubject,
    InvoiceLineItemCompensationType,
    invoicesApi,
} from "@/api/invoices";
import { partitionsApi, PartitionSummary } from "@/api/partitions";
import DateRangePicker from "@/app/components/DateRangePicker.vue";
import NumberField from "@/app/components/NumberField.vue";
import { DateRange } from "@/app/components/dateRangePicker";
import { showConfirm } from "@/app/messageUtil";
import { currency, DecimalArgs, isIntegerOrEmpty, notEmpty } from "@/app/validation";
import { configStore } from "@/store/config";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { cloneObject } from "@/util/cloneUtils";
import { formatLocalDate, getDate } from "@/util/dateTimeUtils";
import { trimAndReturnNullIfEmpty } from "@/util/stringUtils";
import { DeepMutable, Mutable, SelectOptions } from "@/util/types";
import Vue from "vue";

const EMPTY_CUSTOM_LINE_ITEM_FORM: CustomInvoiceLineItemForm = Object.freeze({
    dealerId: null,
    compensationSubject: null,
    compensationType: null,
    description: "",
    pricePerUnit: 0,
    quantity: 0,
});

interface Service {
    readonly compensationSubject: InvoiceLineItemCompensationSubject;
    readonly compensationType: InvoiceLineItemCompensationType;
}

export default Vue.extend({
    props: {
        billingAccountId: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            customInvoiceForm: {
                billingAccountId: this.billingAccountId,
                periodBegin: null as string | null,
                periodEnd: null as string | null,
                customInvoiceLineItemForms: [cloneObject(EMPTY_CUSTOM_LINE_ITEM_FORM)],
            } as DeepMutable<CustomInvoiceForm>,
            dealers: [] as Dealer[],
            InvoiceLineItemCompensationType,
            isIntegerOrEmpty,
            isWorking: false,
            notEmpty,
            now: now(),
            partitions: [] as PartitionSummary[],
            sendEmail: false,
            valid: true,
        };
    },

    computed: {
        currency(): DecimalArgs {
            return currency(this.organisationCurrency);
        },

        dealerOptions(): SelectOptions {
            return [
                { header: this.$t("Standorte des Abrechnungskontos") },
                ...this.dealers
                    .filter((d) => d.billingAccountId === this.billingAccountId)
                    .map((d) => {
                        const partition = this.partitions.find((p) => p.id === d.partitionId);
                        return { value: d.id, text: partition ? `${d.name} (${partition.name})` : d.name };
                    })
                    .sort((a, b) => a.text.localeCompare(b.text, userSession.locale)),
                { header: this.$t("Weitere Standorte") },
                ...this.dealers
                    .filter((d) => d.billingAccountId !== this.billingAccountId)
                    .map((d) => {
                        const partition = this.partitions.find((p) => p.id === d.partitionId);
                        return { value: d.id, text: partition ? `${d.name} (${partition.name})` : d.name };
                    })
                    .sort((a, b) => a.text.localeCompare(b.text, userSession.locale)),
            ];
        },

        netAmount(): number {
            return this.customInvoiceForm.customInvoiceLineItemForms
                .map((f) => this.getCustomInvoiceLineItemFormPrice(f))
                .reduce((a, b) => a + b, 0);
        },

        organisationCurrency(): string {
            return configStore.configuration.organisationCurrency;
        },

        period(): DateRange | null {
            return this.customInvoiceForm.periodBegin
                ? { from: this.customInvoiceForm.periodBegin, to: this.customInvoiceForm.periodEnd }
                : null;
        },

        periodEndMaxDate(): string {
            return getDate(this.now, this.timeZone);
        },

        selectedDealersOfOtherBillingAccount(): Dealer[] {
            return this.customInvoiceForm.customInvoiceLineItemForms
                .map((f) =>
                    this.dealers.find((d) => d.id === f.dealerId && d.billingAccountId !== this.billingAccountId)
                )
                .filter((dealer): dealer is Dealer => !!dealer);
        },

        serviceOptions(): SelectOptions {
            const mainServices: Service[] = [
                {
                    compensationSubject: InvoiceLineItemCompensationSubject.SETUP,
                    compensationType: InvoiceLineItemCompensationType.ONE_TIME_FEE,
                },
                {
                    compensationSubject: InvoiceLineItemCompensationSubject.SOFTWARE_LICENSE,
                    compensationType: InvoiceLineItemCompensationType.MONTHLY_FEE,
                },
                {
                    compensationSubject: InvoiceLineItemCompensationSubject.EXTERNAL_BDC_MINUTE,
                    compensationType: InvoiceLineItemCompensationType.UNIT,
                },
                {
                    compensationSubject: InvoiceLineItemCompensationSubject.CLICK_TO_CALL_MINUTE,
                    compensationType: InvoiceLineItemCompensationType.UNIT,
                },
                {
                    compensationSubject: InvoiceLineItemCompensationSubject.CALL_TRACKING_MINUTE,
                    compensationType: InvoiceLineItemCompensationType.UNIT,
                },
                {
                    compensationSubject: InvoiceLineItemCompensationSubject.OUTGOING_SMS,
                    compensationType: InvoiceLineItemCompensationType.UNIT,
                },
                {
                    compensationSubject: InvoiceLineItemCompensationSubject.WHATSAPP_NUMBER,
                    compensationType: InvoiceLineItemCompensationType.UNIT,
                },
            ];

            return [
                { header: this.$t("Bevorzugte Leistungsarten") },
                ...mainServices.map((t) => {
                    const compensationTypeText = this.$t(`enum.InvoiceLineItemCompensationType.${t.compensationType}`);
                    const compensationSubjectText = this.$t(
                        `enum.InvoiceLineItemCompensationSubject.${t.compensationSubject}`
                    );

                    return {
                        text: `${compensationSubjectText}: ${compensationTypeText}`,
                        value: t,
                    };
                }),
                { header: this.$t("Weitere Leistungsarten") },
                ...[...Object.keys(InvoiceLineItemCompensationSubject)]
                    .map((compensationSubject) =>
                        [...Object.keys(InvoiceLineItemCompensationType)].map((compensationType) => ({
                            compensationSubject: compensationSubject as InvoiceLineItemCompensationSubject,
                            compensationType: compensationType as InvoiceLineItemCompensationType,
                        }))
                    )
                    .reduce((prev, cur) => prev.concat(cur), [])
                    .filter(
                        (t) =>
                            t.compensationSubject !== InvoiceLineItemCompensationSubject.SOFTWARE_LICENSE ||
                            t.compensationType === InvoiceLineItemCompensationType.MONTHLY_FEE
                    )
                    .filter(
                        (t) =>
                            t.compensationSubject !== InvoiceLineItemCompensationSubject.WHATSAPP_NUMBER ||
                            t.compensationType === InvoiceLineItemCompensationType.UNIT
                    )
                    .filter((t) => t.compensationSubject !== InvoiceLineItemCompensationSubject.SETUP)
                    .filter((t) => t.compensationType !== InvoiceLineItemCompensationType.ONE_TIME_FEE)
                    .filter(
                        (t) =>
                            !mainServices.find(
                                (mt) =>
                                    t.compensationSubject === mt.compensationSubject &&
                                    t.compensationType === mt.compensationType
                            )
                    )
                    .map((t) => {
                        const compensationTypeText = this.$t(
                            `enum.InvoiceLineItemCompensationType.${t.compensationType}`
                        );
                        const compensationSubjectText = this.$t(
                            `enum.InvoiceLineItemCompensationSubject.${t.compensationSubject}`
                        );

                        return {
                            text: `${compensationSubjectText}: ${compensationTypeText}`,
                            value: t,
                        };
                    }),
            ];
        },

        timeZone(): string {
            return configStore.configuration.organisationTimeZone;
        },
    },

    methods: {
        addLineItem() {
            this.customInvoiceForm.customInvoiceLineItemForms.push(cloneObject(EMPTY_CUSTOM_LINE_ITEM_FORM));
        },

        close() {
            this.$emit("close");
        },

        generateDescription(lineItemForm: CustomInvoiceLineItemForm): string | null {
            if (!lineItemForm.compensationSubject || !lineItemForm.dealerId || !this.customInvoiceForm.periodBegin) {
                return null;
            }

            const description = trimAndReturnNullIfEmpty(
                [
                    this.$t(`enum.InvoiceLineItemCompensationSubject.${lineItemForm.compensationSubject}`),
                    this.$t(`enum.InvoiceLineItemCompensationType.${lineItemForm.compensationType}`),
                    this.dealers.find((d) => d.id === lineItemForm.dealerId)?.name ?? null,
                ]
                    .filter((v): v is string => !!v)
                    .join(", ")
            );

            if (!description) {
                return description;
            }

            const formattedPeriodBegin = formatLocalDate(this.customInvoiceForm.periodBegin!, userSession.locale, "S");
            const formattedPeriodEnd = formatLocalDate(this.customInvoiceForm.periodEnd!, userSession.locale, "S");

            return `${description}, ${formattedPeriodBegin} - ${formattedPeriodEnd}`;
        },

        getCustomInvoiceLineItemFormPrice(form: CustomInvoiceLineItemForm): number {
            // can be of type string due to number field
            let quantity =
                typeof (form.quantity as string | number) === "number"
                    ? form.quantity
                    : Number.parseFloat(form.quantity as any);
            let pricePerUnit =
                typeof (form.pricePerUnit as string | number) === "number"
                    ? form.pricePerUnit
                    : Number.parseFloat(form.pricePerUnit as any);

            quantity = !isNaN(quantity) ? quantity : 0;
            pricePerUnit = !isNaN(pricePerUnit) ? pricePerUnit : 0;

            const precisionFactor = Math.pow(10, this.currency.precision);

            return Math.round(quantity * pricePerUnit * precisionFactor) / precisionFactor;
        },

        getService(lineItem: CustomInvoiceLineItemForm): Service | null {
            if (!lineItem.compensationSubject || !lineItem.compensationType) {
                return null;
            }

            return {
                compensationSubject: lineItem.compensationSubject,
                compensationType: lineItem.compensationType,
            };
        },

        isDealerOfOtherBillingAccount(dealerId: string | null): boolean {
            if (!dealerId) {
                return false;
            }

            return !!this.dealers.find((d) => d.id === dealerId && d.billingAccountId !== this.billingAccountId);
        },

        async removeLineItem(index: number) {
            if (
                !(await showConfirm(
                    this.$t("Rechnungsposition löschen") as string,
                    this.$t('Sind Sie sicher, dass Sie die Rechnungsposition "{0}" löschen möchten?', [
                        this.customInvoiceForm.customInvoiceLineItemForms[index].description || index + 1,
                    ]) as string
                ))
            ) {
                return;
            }

            this.customInvoiceForm.customInvoiceLineItemForms.splice(index, 1);
        },

        setDealerId(lineItem: Mutable<CustomInvoiceLineItemForm>, dealerId: string | null) {
            lineItem.dealerId = dealerId;
            lineItem.description = "";
        },

        setPeriod(dateRange: DateRange) {
            this.customInvoiceForm.periodBegin = dateRange.from;
            this.customInvoiceForm.periodEnd = dateRange.to;

            this.customInvoiceForm.customInvoiceLineItemForms = this.customInvoiceForm.customInvoiceLineItemForms.map(
                (form) => ({
                    ...form,
                    description: "",
                })
            );
        },

        setService(lineItem: Mutable<CustomInvoiceLineItemForm>, service: Service) {
            if (!service) {
                lineItem.compensationSubject = null;
                lineItem.compensationType = null;
            } else {
                lineItem.compensationSubject = service.compensationSubject;
                lineItem.compensationType = service.compensationType;
            }

            lineItem.description = "";
        },

        async submitForm() {
            if (!this.valid) {
                (this.$refs.form as any).validate();
                return;
            }

            if (
                !!this.selectedDealersOfOtherBillingAccount.length &&
                !(await showConfirm(
                    this.$t("Falsches Abrechnungskonto") as string,
                    this.$t(
                        'Der Händler "{0}" gehört zu einem anderen Abrechnungskonto. Sind Sie sicher, dass Sie fortfahren möchten?',
                        [this.selectedDealersOfOtherBillingAccount[0].name]
                    ) as string
                ))
            ) {
                return;
            }

            if (
                !!this.customInvoiceForm.customInvoiceLineItemForms.find((f) => !f.compensationSubject) &&
                !(await showConfirm(
                    this.$t("Es ist keine Leistungsart ausgewählt.") as string,
                    this.$t(
                        "Die Rechnung wird voraussichtlich vom Buchhalter beanstandet und muss ggf. händisch nachgebucht werden. Sind Sie sicher, dass Sie fortfahren möchten?"
                    ) as string
                ))
            ) {
                return;
            }

            this.isWorking = true;

            try {
                await invoicesApi.createCustomInvoice(this.customInvoiceForm, this.sendEmail);

                this.$emit("success");
            } finally {
                this.isWorking = false;
            }
        },
    },

    watch: {
        customInvoiceForm: {
            deep: true,
            async handler() {
                await this.$nextTick();

                (this.$refs.form as any).validate();
            },
        },
    },

    async mounted() {
        this.dealers = await dealersApi.list();
        this.partitions = await partitionsApi.list();
    },

    components: {
        DateRangePicker,
        NumberField,
    },
});

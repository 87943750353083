
import { ActionType, FeedbackType } from "@/api/actions";
import { ExternalBdcCdr } from "@/api/externalBdcCdrs";
import { ActionRow } from "@/api/reporting";
import { User } from "@/api/users";
import ActionsBottomSheet from "@/app/pages/action/ActionsBottomSheet.vue";
import { mapActionRowKeyToRowLabel } from "@/app/pages/reporting/action/actionRowUtils";
import { getReportingTableByReportingScatterChart } from "@/app/pages/reporting/chartUtils";
import { hasNonZeroSeries, ReportingScatterChartData } from "@/app/pages/reporting/charts/reportingScatterChart";
import ReportingDashboardScatterTile from "@/app/pages/reporting/dashboard/ReportingDashboardScatterTile.vue";
import { mapExternalBdcCdrRowKeyToRowLabel } from "@/app/pages/reporting/externalbdc/externalBdcRowUtils";
import { groupRowsBy } from "@/app/pages/reporting/pivotUtils";
import { TitledRowGroup } from "@/app/pages/reporting/rowUtils";
import { ReportingTableData } from "@/app/pages/reporting/table/reportingTable";
import Vue from "vue";

export default Vue.extend({
    props: {
        actionRows: {
            type: Array as () => readonly ActionRow[],
            required: true,
        },
        chartHeight: {
            type: Number,
            required: false,
        },
        externalBdcCdrRows: {
            type: Array as () => readonly ExternalBdcCdr[],
            required: true,
        },
        loading: {
            type: Boolean,
            required: true,
        },
        subtitle: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            required: false,
        },
        users: {
            type: Array as () => readonly User[],
            required: true,
        },
    },

    data() {
        return {
            bottomSheetActionIds: [] as number[],
            bottomSheetTitle: null as string | null,
            bottomSheetVisible: false,
        };
    },

    computed: {
        chart(): ReportingScatterChartData | null {
            const topCategories = this.actionRowGroups
                .map((rowGroup) => ({
                    categoryId: rowGroup.key,
                    name: rowGroup.title,
                    description: rowGroup.subtitle,
                    rows: rowGroup.rows,
                    issuesCreated: rowGroup.rows.filter(
                        (r) =>
                            r.type === ActionType.ADD_ACTIVITY_CREATING_CASE ||
                            r.type === ActionType.ADD_ACTIVITY_CREATING_EMERGENCY_INCIDENT ||
                            r.type === ActionType.ADD_ACTIVITY_CREATING_OPPORTUNITY
                    ).length,
                    notesCreated: rowGroup.rows.filter(
                        (r) =>
                            r.type === ActionType.ADD_ACTIVITY_EXISTING_CASE ||
                            r.type === ActionType.ADD_ACTIVITY_EXISTING_EMERGENCY_INCIDENT ||
                            r.type === ActionType.ADD_ACTIVITY_EXISTING_OPPORTUNITY
                    ).length,
                    rowsErrorsReported: rowGroup.rows.filter(
                        (r) => !!r.feedbackCreated && r.feedbackType !== FeedbackType.INFO
                    ),
                    acceptedCallCount:
                        this.externalBdcCdrRowGroups.find((g) => g.key === rowGroup.key)?.rows.length ?? 0,
                }))
                .map((c) => ({
                    ...c,
                    errorRate:
                        c.issuesCreated + c.notesCreated
                            ? c.rowsErrorsReported.length / (c.issuesCreated + c.notesCreated)
                            : null,
                }))
                .sort((a, b) => (b.errorRate ?? 0) - (a.errorRate ?? 0))
                .filter((c) => c.errorRate);

            const chart: ReportingScatterChartData = {
                title: "",
                xAxis: [{ id: "call-count", name: this.$t("Anrufe") as string }],
                yAxis: [{ id: "error-rate", name: this.$t("Fehlerquote") as string, isPercentage: true }],
                categories: topCategories.map((c) => ({ name: c.name, description: c.description })),
                series: [
                    {
                        id: "rate",
                        name: this.$t("Quote") as string,
                        data: {
                            values: topCategories.map((c) => ({
                                xValue: c.acceptedCallCount,
                                yValue: c.errorRate ?? 0,
                                onClick: this.showBottomSheetOnClick(
                                    c.rowsErrorsReported.map((r) => r.id),
                                    c.name
                                ),
                            })),
                            xAxisId: "call-count",
                            yAxisId: "error-rate",
                            yAxisMarkers: [{ value: 0.01, fillArea: "ABOVE", opacity: 0.02, color: "red" }],
                        },
                    },
                ],
            };

            if (!hasNonZeroSeries(chart)) {
                return null;
            }

            return chart;
        },

        actionRowGroups(): readonly TitledRowGroup<string, ActionRow>[] {
            return groupRowsBy(
                this.actionRows.filter((r) => r.userId),
                (r) => r.userId
            ).map((rowGroup) => {
                const rowGroupLabel = mapActionRowKeyToRowLabel(rowGroup.key, "userId", {
                    users: this.users,
                });

                return {
                    ...rowGroup,
                    title: rowGroupLabel.label,
                    subtitle: rowGroupLabel.sublabel,
                };
            });
        },

        externalBdcCdrRowGroups(): readonly TitledRowGroup<string | null, ExternalBdcCdr>[] {
            return groupRowsBy(
                this.externalBdcCdrRows.filter((r) => r.userId),
                (r) => r.userId
            ).map((rowGroup) => {
                const rowGroupLabel = mapExternalBdcCdrRowKeyToRowLabel(rowGroup.key, "userId", {
                    users: this.users,
                });

                return {
                    ...rowGroup,
                    title: rowGroupLabel.label,
                    subtitle: rowGroupLabel.sublabel,
                };
            });
        },

        table(): ReportingTableData | null {
            if (!this.chart) {
                return null;
            }

            return {
                ...getReportingTableByReportingScatterChart(this.chart),
                groupByHeaderText: this.$t("Benutzer") as string,
            };
        },
    },

    methods: {
        hideBottomSheet() {
            this.bottomSheetVisible = false;
            this.bottomSheetTitle = null;
            this.bottomSheetActionIds = [];
        },

        showBottomSheetOnClick(actionIds: number[], title: string): () => void {
            return () => {
                this.bottomSheetActionIds = [...new Set(actionIds)];
                this.bottomSheetTitle = title;
                this.bottomSheetVisible = true;
            };
        },
    },

    components: {
        ActionsBottomSheet,
        ReportingDashboardScatterTile,
    },
});


import AgentDayDataTable from "./AgentDayDataTable.vue";
import DatePicker from "@/app/components/DatePicker.vue";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { getDate } from "@/util/dateTimeUtils";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            loading: true,
            loadFilter: {
                date: getDate(now(), userSession.timeZone),
            },
        };
    },

    computed: {
        userId(): string {
            return userSession.id!;
        },
    },

    methods: {
        async refresh() {
            await (this.$refs.agentDayDataTable as any).refresh();
        },
    },

    components: {
        AgentDayDataTable,
        DatePicker,
    },
});

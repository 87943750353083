/* eslint-disable prettier/prettier */
export default {
    "validation": {
        "no-partition-deployment": "Es ist noch kein Image für eine Partition verfügbar",
        "duplicate-database-id": "Name ist bereits vergeben",
        "duplicate-partition-id": "Schlüssel ist bereits vergeben",
        "duplicate-partition-prefix": "Präfix ist bereits vergeben",
        "duplicate-username": "Benutzername ist bereits vergeben",
        "duplicate-receiver": "Der Empfänger existiert bereits",
        "billing-account-in-use": "Das Abrechnungskonto befindet sich noch in Verwendung",
        "contract-in-use": "Der Vertrag befindet sich noch in Verwendung",
        "password-wrong": "Das eingegebene Passwort ist nicht korrekt",
        "invoice-lock-not-available": "Eine Rechnungserstellung ist aktuell nicht möglich, bitte versuchen es später noch einmal",
        "too-many-login-attempts": "Sie hatten zu viele erfolglose Login-Versuche. Bitte warten Sie 10 Minuten und versuchen es dann erneut.",
        "sql-syntax-error": "SQL Syntax Fehler: {0}",
        "new-password-equals-old-password": "Das neue Kennwort muss sich vom alten Kennwort unterscheiden.",
        "duplicate-citnow-conversations-user-id": "Die CitNOW-Conversations-Benutzer-ID ist bereits vergeben."
    },
    "locale": {
        "de": "Deutsch (Deutschland)",
        "en": "English (US)",
    },
    "enum": {
        "Role": {
            "ACCOUNTANT": "Buchhalter",
            "AGENT": "Agent",
            "BDC_ADMIN": "BDC-Admin",
            "BDC_MANAGER": "BDC-Manager",
            "CONTRACT_MANAGER": "Vertragsverwalter",
            "CT_ADMIN": "Admin",
            "CT_SUPER_ADMIN": "Super-Admin",
            "FEATURE_A_TESTER": "Feature-A-Tester",
            "FEATURE_B_TESTER": "Feature-B-Tester",
            "INFRASTRUCTURE_MANAGER": "Infrastrukturverwalter",
            "PARTITION_MANAGER": "Partitionsverwalter",
            "QUALITY_MANAGER": "Qualitätsmanager"
        },
        "PaymentMethod": {
            "BANK_TRANSFER": "Überweisung",
            "SEPA_B2B_DIRECT_DEBIT": "SEPA-Firmenlastschrift",
        },
        "StateType": {
            "DE": "Bundesland",
        },
        "Gender": {
            "MALE": "männlich",
            "FEMALE": "weiblich",
            "DIVERSE": "divers"
        },
        "IncomingEmailBlacklistEntryType": {
            "FROM_ADDRESS_STARTS_WITH": "Absenderadresse beginnt mit",
            "FROM_ADDRESS_CONTAINS": "Absenderadresse enthält",
            "FROM_ADDRESS_ENDS_WITH": "Absenderadresse endet mit",
            "FROM_NAME_STARTS_WITH": "Absendername beginnt mit",
            "FROM_NAME_CONTAINS": "Absendername enthält",
            "FROM_NAME_ENDS_WITH": "Absendername endet mit",
            "SUBJECT_STARTS_WITH": "Betreff beginnt mit",
            "SUBJECT_CONTAINS": "Betreff enthält",
            "SUBJECT_ENDS_WITH": "Betreff endet mit"
        },
        "EmergencyIncidentSummarySearchOrder": {
            "CREATED_DESC": "Erstellungsdatum (absteigend)",
            "CREATED_ASC": "Erstellungsdatum (aufsteigend)",
            "LAST_ACTIVITY_DESC": "Letzte Aktivität (absteigend)",
            "LAST_ACTIVITY_ASC": "Letzte Aktivität (aufsteigend)",
        },
        "EmergencyIncidentType": {
            "BREAKDOWN": "Panne",
            "ACCIDENT": "Unfall",
        },
        "IncomingEmailSummarySearchOrder": {
            "CREATED_DESC": "Erstellungsdatum (absteigend)",
            "CREATED_ASC": "Erstellungsdatum (aufsteigend)",
            "FROM_NAME_DESC": "Absendername (absteigend)",
            "FROM_NAME_ASC": "Absendername (aufsteigend)",
            "FROM_ADDRESS_DESC": "Absenderadresse (absteigend)",
            "FROM_ADDRESS_ASC": "Absenderadresse (aufsteigend)",
            "SUBJECT_DESC": "Betreff (absteigend)",
            "SUBJECT_ASC": "Betreff (aufsteigend)"
        },
        "IncomingEmailSummaryCountType": {
            "FROM_NAME": "Absendername",
            "FROM_ADDRESS": "Absenderadresse"
        },
        "DealerStatisticSearchOrder": {
            "PARTITION_ASC": "Partition (aufsteigend)",
            "PARTITION_DESC": "Partition (absteigend)",
            "DEALER_ASC": "Standort (aufsteigend)",
            "DEALER_DESC": "Standort (absteigend)",
            "SOFTWARE_LICENSE_DAYS_COVERED_ASC": "Anzahl der Tage mit gebuchter Software (aufsteigend)",
            "SOFTWARE_LICENSE_DAYS_COVERED_DESC": "Anzahl der Tage mit gebuchter Software (absteigend)",
            "CLICK_TO_CALL_MINUTES_ASC": "Anzahl der generierten Click-to-Call-Minuten (aufsteigend)",
            "CLICK_TO_CALL_MINUTES_DESC": "Anzahl der generierten Click-to-Call-Minuten (absteigend)",
            "CLICK_TO_CALL_MINUTES_COVERED_ASC": "Anzahl der abgedeckten Click-to-Call-Minuten (aufsteigend)",
            "CLICK_TO_CALL_MINUTES_COVERED_DESC": "Anzahl der abgedeckten Click-to-Call-Minuten (absteigend)",
            "CALL_TRACKING_MINUTES_ASC": "Anzahl der generierten Call-Tracking-Minuten (aufsteigend)",
            "CALL_TRACKING_MINUTES_DESC": "Anzahl der generierten Call-Tracking-Minuten (absteigend)",
            "CALL_TRACKING_MINUTES_COVERED_ASC": "Anzahl der abgedeckten Call-Tracking-Minuten (aufsteigend)",
            "CALL_TRACKING_MINUTES_COVERED_DESC": "Anzahl der abgedeckten Call-Tracking-Minuten (absteigend)",
            "OUTGOING_SMS_ASC": "Anzahl der gesendeten SMS (aufsteigend)",
            "OUTGOING_SMS_DESC": "Anzahl der gesendeten SMS (absteigend)",
            "OUTGOING_SMS_COVERED_ASC": "Anzahl der abgedeckten SMS (aufsteigend)",
            "OUTGOING_SMS_COVERED_DESC": "Anzahl der abgedeckten SMS (absteigend)",
            "EXTERNAL_BDC_MINUTES_ASC": "Anzahl der generierten Externe-BDC-Minuten (aufsteigend)",
            "EXTERNAL_BDC_MINUTES_DESC": "Anzahl der generierten Externe-BDC-Minuten (absteigend)",
            "EXTERNAL_BDC_MINUTES_COVERED_ASC": "Anzahl der abgedeckten Externe-BDC-Minuten (aufsteigend)",
            "EXTERNAL_BDC_MINUTES_COVERED_DESC": "Anzahl der abgedeckten Externe-BDC-Minuten (absteigend)",
            "INCOMING_WHATSAPP_MESSAGES_ASC": "Anzahl der empfangenen WhatsApp-Nachrichten (aufsteigend)",
            "INCOMING_WHATSAPP_MESSAGES_DESC": "Anzahl der empfangenen WhatsApp-Nachrichten (absteigend)",
            "OUTGOING_WHATSAPP_MESSAGES_ASC": "Anzahl der gesendeten WhatsApp-Nachrichten (aufsteigend)",
            "OUTGOING_WHATSAPP_MESSAGES_DESC": "Anzahl der gesendeten WhatsApp-Nachrichten (absteigend)",
            "WHATSAPP_NUMBERS_ASC": "Anzahl der WhatsApp-Rufnummern (aufsteigend)",
            "WHATSAPP_NUMBERS_DESC": "Anzahl der WhatsApp-Rufnummern (absteigend)",
            "VIDEOCHATS_ASC": "Anzahl der Videochats (aufsteigend)",
            "VIDEOCHATS_DESC": "Anzahl der Videochats (absteigend)",
            "TOTAL_USERS_ASC": "Anzahl der angelegten Benutzer (aufsteigend)",
            "TOTAL_USERS_DESC": "Anzahl der angelegten Benutzer (absteigend)",
            "ONLINE_USERS_ASC": "Anzahl der Online-Benutzer (aufsteigend)",
            "ONLINE_USERS_DESC": "Anzahl der Online-Benutzer (absteigend)",
            "UNDELETED_INVENTORY_VEHICLES_ASC": "Anzahl der aktiven Bestandsfahrzeuge (aufsteigend)",
            "UNDELETED_INVENTORY_VEHICLES_DESC": "Anzahl der aktiven Bestandsfahrzeuge (absteigend)",
            "ACTIVE_INVENTORY_VEHICLE_INQUIRIES_ASC": "Anzahl der aktiven Suchaufträge (aufsteigend)",
            "ACTIVE_INVENTORY_VEHICLE_INQUIRIES_DESC": "Anzahl der aktiven Suchaufträge (absteigend)",
            "UNDELETED_CONTACTS_ASC": "Anzahl der aktiven Kontakte (aufsteigend)",
            "UNDELETED_CONTACTS_DESC": "Anzahl der aktiven Kontakte (absteigend)",
            "CASES_OPEN_ASC": "Anzahl der offenen Fälle (aufsteigend)",
            "CASES_OPEN_DESC": "Anzahl der offenen Fälle (absteigend)",
            "CASES_ASSIGNED_ASC": "Anzahl der zugeteilten Fälle (aufsteigend)",
            "CASES_ASSIGNED_DESC": "Anzahl der zugeteilten Fälle (absteigend)",
            "OPPORTUNITIES_OPEN_ASC": "Anzahl der offenen Verkaufschancen (aufsteigend)",
            "OPPORTUNITIES_OPEN_DESC": "Anzahl der offenen Verkaufschancen (absteigend)",
            "OPPORTUNITIES_ASSIGNED_ASC": "Anzahl der zugeteilten Verkaufschancen (aufsteigend)",
            "OPPORTUNITIES_ASSIGNED_DESC": "Anzahl der zugeteilten Verkaufschancen (absteigend)",
            "EMERGENCY_INCIDENTS_OPEN_ASC": "Anzahl der offenen Notdienstvorgänge (aufsteigend)",
            "EMERGENCY_INCIDENTS_OPEN_DESC": "Anzahl der offenen Notdienstvorgänge (absteigend)",
            "CASES_CREATED_BY_PARTITION_USER_ASC": "Anzahl der von Benutzern erstellten Fälle (aufsteigend)",
            "CASES_CREATED_BY_PARTITION_USER_DESC": "Anzahl der von Benutzern erstellten Fälle (absteigend)",
            "CASES_CREATED_BY_CT_USER_ASC": "Anzahl der von externen Agenten erstellten Fälle (aufsteigend)",
            "CASES_CREATED_BY_CT_USER_DESC": "Anzahl der von externen Agenten erstellten Fälle (absteigend)",
            "CASES_CREATED_BY_SYSTEM_ASC": "Anzahl der vom System erstellten Fälle (aufsteigend)",
            "CASES_CREATED_BY_SYSTEM_DESC": "Anzahl der vom System erstellten Fälle (absteigend)",
            "CASES_CREATED_SENTIMENT_HAPPY_PER_CREATED_CASES_ASC": "Verhältnis der erstellten Fälle mit glücklicher Stimmung zu allen erstellten Fällen (aufsteigend)",
            "CASES_CREATED_SENTIMENT_HAPPY_PER_CREATED_CASES_DESC": "Verhältnis der erstellten Fälle mit glücklicher Stimmung zu allen erstellten Fällen (absteigend)",
            "CASES_CREATED_SENTIMENT_UNHAPPY_PER_CREATED_CASES_ASC": "Verhältnis der erstellten Fälle mit unglücklicher Stimmung zu allen erstellten Fällen (aufsteigend)",
            "CASES_CREATED_SENTIMENT_UNHAPPY_PER_CREATED_CASES_DESC": "Verhältnis der erstellten Fälle mit unglücklicher Stimmung zu allen erstellten Fällen (absteigend)",
            "OPPORTUNITIES_CREATED_BY_PARTITION_USER_ASC": "Anzahl der von Benutzern erstellten Verkaufschancen (aufsteigend)",
            "OPPORTUNITIES_CREATED_BY_PARTITION_USER_DESC": "Anzahl der von Benutzern erstellten Verkaufschancen (absteigend)",
            "OPPORTUNITIES_CREATED_BY_CT_USER_ASC": "Anzahl der von externen Agenten erstellten Verkaufschancen (aufsteigend)",
            "OPPORTUNITIES_CREATED_BY_CT_USER_DESC": "Anzahl der von externen Agenten erstellten Verkaufschancen (absteigend)",
            "OPPORTUNITIES_CREATED_BY_SYSTEM_ASC": "Anzahl der vom System erstellten Verkaufschancen (aufsteigend)",
            "OPPORTUNITIES_CREATED_BY_SYSTEM_DESC": "Anzahl der vom System erstellten Verkaufschancen (absteigend)",
            "OPPORTUNITIES_CREATED_SENTIMENT_HAPPY_PER_CREATED_OPPORTUNITIES_ASC": "Verhältnis der erstellten Verkaufschancen mit glücklicher Stimmung zu allen erstellten Verkaufschancen (aufsteigend)",
            "OPPORTUNITIES_CREATED_SENTIMENT_HAPPY_PER_CREATED_OPPORTUNITIES_DESC": "Verhältnis der erstellten Verkaufschancen mit glücklicher Stimmung zu allen erstellten Verkaufschancen (absteigend)",
            "OPPORTUNITIES_CREATED_SENTIMENT_UNHAPPY_PER_CREATED_OPPORTUNITIES_ASC": "Verhältnis der erstellten Verkaufschancen mit unglücklicher Stimmung zu allen erstellten Verkaufschancen (aufsteigend)",
            "OPPORTUNITIES_CREATED_SENTIMENT_UNHAPPY_PER_CREATED_OPPORTUNITIES_DESC": "Verhältnis der erstellten Verkaufschancen mit unglücklicher Stimmung zu allen erstellten Verkaufschancen (absteigend)",
            "EMERGENCY_INCIDENTS_CREATED_BY_PARTITION_USER_ASC": "Anzahl der von Benutzern erstellten Notdienstvorgänge (aufsteigend)",
            "EMERGENCY_INCIDENTS_CREATED_BY_PARTITION_USER_DESC": "Anzahl der von Benutzern erstellten Notdienstvorgänge (absteigend)",
            "EMERGENCY_INCIDENTS_CREATED_BY_CT_USER_ASC": "Anzahl der von externen Agenten erstellten Notdienstvorgänge (aufsteigend)",
            "EMERGENCY_INCIDENTS_CREATED_BY_CT_USER_DESC": "Anzahl der von externen Agenten erstellten Notdienstvorgänge (absteigend)",
            "EMERGENCY_INCIDENTS_CREATED_BY_SYSTEM_ASC": "Anzahl der vom System erstellten Notdienstvorgänge (aufsteigend)",
            "EMERGENCY_INCIDENTS_CREATED_BY_SYSTEM_DESC": "Anzahl der vom System erstellten Notdienstvorgänge (absteigend)"
        },
        "ActionType": {
            "ADD_ACTIVITY_CREATING_CASE": "Fall erstellt",
            "ADD_ACTIVITY_CREATING_OPPORTUNITY": "Verkaufschance erstellt",
            "ADD_ACTIVITY_CREATING_EMERGENCY_INCIDENT": "Notdienstvorgang erstellt",
            "ADD_ACTIVITY_EXISTING_CASE": "Notiz erstellt (Fall)",
            "ADD_ACTIVITY_EXISTING_OPPORTUNITY": "Notiz erstellt (Verkaufschance)",
            "ADD_ACTIVITY_EXISTING_EMERGENCY_INCIDENT": "Notiz erstellt (Notdienstvorgang)",
            "ADD_CONTACT": "Kontakt erstellt",
        },
        "ExternalBdcCdrSearchOrder": {
            "BEGIN_ASC": "Beginn (aufsteigend)",
            "BEGIN_DESC": "Beginn (absteigend)",
            "ACCEPT_ASC": "Annahme (aufsteigend)",
            "ACCEPT_DESC": "Annahme (absteigend)",
            "END_ASC": "Ende (aufsteigend)",
            "END_DESC": "Ende (absteigend)",
        },
        "ContractSearchOrder": {
            "CREATED_DESC": "Erstellungsdatum (absteigend)",
            "CREATED_ASC": "Erstellungsdatum (aufsteigend)",
            "BEGIN_DESC": "Vertragsbeginn (absteigend)",
            "BEGIN_ASC": "Vertragsbeginn (aufsteigend)",
            "END_DESC": "Vertragsbeginn (absteigend)",
            "END_ASC": "Vertragsbeginn (aufsteigend)"
        },
        "BillingAccountSearchOrder": {
            "CREATED_DESC": "Erstellungsdatum (absteigend)",
            "CREATED_ASC": "Erstellungsdatum (aufsteigend)",
        },
        "DutyRosterEntryType": {
            "ANSWER_INCOMING_ORDINARY_CALLS": "Anrufannahme (normale Anrufe)",
            "ANSWER_INCOMING_PRIORITY_CALLS": "Anrufannahme (priorisierte Anrufe)",
            "TRAINING": "Schulung",
            "OTHER_WORK": "Sonstige Arbeitszeit",
            "HOLIDAY": "Urlaub",
            "OVERTIME_COMPENSATION": "Überstundenausgleich",
            "OTHER_TIME_COMPENSATION": "Sonstiger Zeitausgleich",
            "SICKNESS": "Krankheit",
            "SICK_CHILD": "krankes Kind",
            "OTHER_UNPAID_ABSENCE": "Sonstige Abwesenheit (o. B.)",
        },
        "DisconnectStatus": {
            "UNKNOWN": "unbekannt",
            "BUSY": "besetzt",
            "REJECT": "abgelehnt",
            "NO_ANSWER": "keine Antwort",
            "UNKNOWN_NUMBER": "unbekannte Number",
            "UNREACHABLE": "nicht erreichbar",
            "NETWORK_CONGESTION": "Netzwerk überlastet",
            "CALL_DISCONNECTED": "Agent aufgelegt",
            "NO_CHANNEL_AVAILABLE": "kein Kanal verfügbar",
            "ORIGINATOR_DISCONNECTED": "Anrufer aufgelegt",
            "OTHER": "sonstiges"
        },
        "AgentActionStatisticsDataTableSearchOrder": {
            "ELAPSED_SECONDS_ANSWER_INCOMING_ORDINARY_CALLS_DESC": "Arbeitszeit in der eingehende normale Anrufe angenommen wurden (absteigend)",
            "ELAPSED_SECONDS_ANSWER_INCOMING_ORDINARY_CALLS_ASC": "Arbeitszeit in der eingehende normale Anrufe angenommen wurden (aufsteigend)",
            "ELAPSED_SECONDS_ANSWER_INCOMING_PRIORITY_CALLS_DESC": "Arbeitszeit in der eingehende priorisierte Anrufe angenommen wurden (absteigend)",
            "ELAPSED_SECONDS_ANSWER_INCOMING_PRIORITY_CALLS_ASC": "Arbeitszeit in der eingehende priorisierte Anrufe angenommen wurden (aufsteigend)",
            "ELAPSED_SECONDS_TRAINING_DESC": "Arbeitszeit verbracht mit Schulung (absteigend)",
            "ELAPSED_SECONDS_TRAINING_ASC": "Arbeitszeit verbracht mit Schulung (aufsteigend)",
            "ELAPSED_SECONDS_OTHER_WORK_DESC": "Arbeitszeit verbracht mit sonstiger Arbeit (absteigend)",
            "ELAPSED_SECONDS_OTHER_WORK_ASC": "Arbeitszeit verbracht mit sonstiger Arbeit (aufsteigend)",
            "ELAPSED_SECONDS_TOTAL_DESC": "Gesamtarbeitszeit (absteigend)",
            "ELAPSED_SECONDS_TOTAL_ASC": "Gesamtarbeitszeit (aufsteigend)",
            "CALLS_ACCEPTED_DESC": "Anrufe (absteigend)",
            "CALLS_ACCEPTED_ASC": "Anrufe (aufsteigend)",
            "CALL_PERFORMANCE_DESC": "Leistung (Anrufe) (absteigend)",
            "CALL_PERFORMANCE_ASC": "Leistung (Anrufe) (aufsteigend)",
            "BILLED_MINUTES_DESC": "Minuten (absteigend)",
            "BILLED_MINUTES_ASC": "Minuten (aufsteigend)",
            "BILLED_MINUTES_PERFORMANCE_DESC": "Leistung (Minuten) (absteigend)",
            "BILLED_MINUTES_PERFORMANCE_ASC": "Leistung (Minuten) (aufsteigend)",
            "ERRORS_REPORTED_DESC": "Fehler (absteigend)",
            "ERRORS_REPORTED_ASC": "Fehler (aufsteigend)",
            "ERROR_RATIO_DESC": "Fehlerquote (absteigend)",
            "ERROR_RATIO_ASC": "Fehlerquote (aufsteigend)",
            "ISSUES_CREATED_TO_CALLS_ACCEPTED_RATIO_DESC": "Vorgang/Anruf (absteigend)",
            "ISSUES_CREATED_TO_CALLS_ACCEPTED_RATIO_ASC": "Vorgang/Anruf (aufsteigend)",
            "NOTES_CREATED_TO_CALLS_ACCEPTED_RATIO_DESC": "Notiz/Anruf (absteigend)",
            "NOTES_CREATED_TO_CALLS_ACCEPTED_RATIO_ASC": "Notiz/Anruf (aufsteigend)",
            "CONTACTS_CREATED_TO_ISSUES_CREATED_RATIO_DESC": "Kontakt/Vorgang (absteigend)",
            "CONTACTS_CREATED_TO_ISSUES_CREATED_RATIO_ASC": "Kontakt/Vorgang (aufsteigend)",
            "SECONDS_ISSUES_CREATED_AFTER_CALL_DESC": "Datenpflege (absteigend)",
            "SECONDS_ISSUES_CREATED_AFTER_CALL_ASC": "Datenpflege (aufsteigend)",
            "SECONDS_ISSUES_CREATED_AFTER_CALL_MEDIAN_DESC": "Datenpflege (Median) (absteigend)",
            "SECONDS_ISSUES_CREATED_AFTER_CALL_MEDIAN_ASC": "Datenpflege (Median) (aufsteigend)",
            "SCHEDULED_DAYS_WITH_ANSWER_INCOMING_ORDINARY_CALLS_DESC": "Tage an denen eingehende normale Anrufe angenommen wurden (descending)",
            "SCHEDULED_DAYS_WITH_ANSWER_INCOMING_ORDINARY_CALLS_ASC": "Tage an denen eingehende normale Anrufe angenommen wurden (aufsteigend)",
            "SCHEDULED_DAYS_WITH_ANSWER_INCOMING_PRIORITY_CALLS_DESC": "Tage an denen eingehende priorisierte Anrufe angenommen wurden (descending)",
            "SCHEDULED_DAYS_WITH_ANSWER_INCOMING_PRIORITY_CALLS_ASC": "Tage an denen eingehende priorisierte Anrufe angenommen wurden (aufsteigend)",
            "SCHEDULED_DAYS_WITH_TRAINING_DESC": "Tage mit Schulung (descending)",
            "SCHEDULED_DAYS_WITH_TRAINING_ASC": "Tage mit Schulung (aufsteigend)",
            "SCHEDULED_DAYS_WITH_OTHER_WORK_DESC": "Tage mit sonstiger Arbeit (descending)",
            "SCHEDULED_DAYS_WITH_OTHER_WORK_ASC": "Tage mit sonstiger Arbeit (aufsteigend)",
            "SCHEDULED_DAYS_WITH_HOLIDAY_DESC": "Tage mit Urlaub (absteigend)",
            "SCHEDULED_DAYS_WITH_HOLIDAY_ASC": "Tage mit Urlaub (aufsteigend)",
            "SCHEDULED_DAYS_WITH_OVERTIME_COMPENSATION_DESC": "Tage mit Überstundenausgleich (absteigend)",
            "SCHEDULED_DAYS_WITH_OVERTIME_COMPENSATION_ASC": "Tage mit Überstundenausgleich (aufsteigend)",
            "SCHEDULED_DAYS_WITH_OTHER_TIME_COMPENSATION_DESC": "Tage mit sonstigem Zeitausgleich (absteigend)",
            "SCHEDULED_DAYS_WITH_OTHER_TIME_COMPENSATION_ASC": "Tage mit sonstigem Zeitausgleich (aufsteigend)",
            "SCHEDULED_DAYS_WITH_SICKNESS_DESC": "Tage mit Krankheit (absteigend)",
            "SCHEDULED_DAYS_WITH_SICKNESS_ASC": "Tage mit Krankheit (aufsteigend)",
            "SCHEDULED_DAYS_WITH_SICK_CHILD_DESC": "Tage mit krankem Kind (absteigend)",
            "SCHEDULED_DAYS_WITH_SICK_CHILD_ASC": "Tage mit krankem Kind (aufsteigend)",
            "SCHEDULED_DAYS_WITH_OTHER_UNPAID_ABSENCE_DESC": "Tage mit sonstiger unbezahlter Abwesenheit (absteigend)",
            "SCHEDULED_DAYS_WITH_OTHER_UNPAID_ABSENCE_ASC": "Tage mit sonstiger unbezahlter Abwesenheit (aufsteigend)"
        },
        "ActionSearchOrder": {
            "CREATED_DESC": "Erstellungsdatum (absteigend)",
            "CREATED_ASC": "Erstellungsdatum (aufsteigend)",
            "FEEDBACK_TYPE_DESC": "Art des Feedbacks (absteigend)",
            "FEEDBACK_TYPE_ASC": "Art des Feedbacks (aufsteigend)",
        }
    },
    "marketingEmailPlaceholders": {
        "SALUTATION": "Anrede mit Begrüßungsformel",
        "UNSUBSCRIBE_LINK": "Link zum Abmelden",
    },
    "marketingEmailPlaceholdersDescription": {
        "SALUTATION": "Formale Anrede des Benutzers, z.B. Sehr geehrter Herr Schmidt",
        "UNSUBSCRIBE_LINK": "Der Empfänger wird von Rundschreiben abgemeldet, falls er diesen Link klickt",
    },
    "email": {
        "invoice": {
            "reminder": {
                "subject": "Zahlungserinnerung",
                "content": `<p>Sehr geehrte Damen und Herren,</p>
<p>im hektischen Alltag geht manches auch mal unter. Deshalb möchten wir Sie heute an unsere noch ausstehende Rechnung bezüglich des Leistungszeitraums vom %INVOICE_PERIOD_BEGIN% bis zum %INVOICE_PERIOD_END% erinnern.</p>
<p>Die Rechnung befindet sich im Anhang.</p>
<p>Bitte überweisen Sie den fälligen Betrag auf unser Bankkonto.</p>
<p>Sollten Sie den Betrag zwischenzeitlich beglichen haben, betrachten Sie diese Zahlungserinnerung bitte als gegenstandslos.</p>
<p>%ORGANISATION_COMPANY%</p>
<p>%ORGANISATION_ADDRESS1%<br />
%ORGANISATION_ADDRESS2%<br />
%ORGANISATION_ZIP% %ORGANISATION_CITY%</p>
<p>Telefon: %ORGANISATION_BILLING_PHONE%<br />
E-Mail: %ORGANISATION_BILLING_EMAIL%<br />
Web: %ORGANISATION_WEBSITE%<br />
UST-IdNr.: %ORGANISATION_VAT_NUMBER%</p>
<p>Kreditinstitut: %ORGANISATION_BANK_NAME%<br />
IBAN: %ORGANISATION_BANK_ACCOUNT_NUMBER%<br />
BIC: %ORGANISATION_BANK_CODE%<br />
Gläubiger-ID: %ORGANISATION_SEPA_CREDITOR_IDENTIFIER%</p>`,
            },
        },
    },
};


import InvoicesDataTable from "./InvoicesDataTable.vue";
import InvoicesWithCreditTransferDialog from "./InvoicesWithCreditTransferDialog.vue";
import { BillingAccount, billingAccountsApi } from "@/api/billingAccounts";
import { CreditTransfer, creditTransfersApi, InvoiceWithCreditTransfer } from "@/api/creditTransfers";
import { Invoice, invoicesApi } from "@/api/invoices";
import { Permission } from "@/api/userSession";
import CsvDownloadIcon from "@/app/components/CsvDownloadIcon.vue";
import MonthPicker from "@/app/components/MonthPicker.vue";
import { downloadOrOpenFile, fileDialog } from "@/app/fileUtils";
import { renderCurrency } from "@/app/filters";
import { configStore } from "@/store/config";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { formatLocalDate, getCurrentMonth } from "@/util/dateTimeUtils";
import Vue from "vue";

const UTF8_BOM = "\ufeff";

export default Vue.extend({
    data() {
        return {
            activeTab: parseInt((this.$route.query.activeTab as string) || "0"),
            billingAccounts: [] as BillingAccount[],
            loadingOpenInvoices: true,
            openInvoices: [] as Invoice[],
            loadingMonthInvoices: true,
            monthInvoices: [] as Invoice[],
            month: (this.$route.query.month ||
                getCurrentMonth(now(), configStore.configuration.organisationTimeZone)) as string,
            searchOpenInvoices: null as string | null,
            invoicesWithCreditTransfer: null as InvoiceWithCreditTransfer[] | null,
            unmatchedCreditTransfers: [] as CreditTransfer[],
            timeZone: configStore.configuration.organisationTimeZone,
            isWorking: false,
        };
    },

    methods: {
        canUpdatePaid(): boolean {
            return userSession.hasPermission(Permission.CT_VIEW_INVOICES);
        },

        getInvoicesCsvData(invoices: Invoice[]): string[][] {
            return [
                [
                    "invoice-id",
                    "invoice-date",
                    "invoice-period-start",
                    "invoice-period-end",
                    "billing-account",
                    "billing-account-id",
                    "invoice-net-amount",
                    "invoice-gross-amount",
                    "recipient",
                ],
                ...invoices.map((invoice) => {
                    const billingAccount = this.billingAccounts.find((b) => b.id === invoice.billingAccountId);

                    return [
                        invoice.id.toString(),
                        formatLocalDate(invoice.created, userSession.locale, "S"),
                        formatLocalDate(invoice.periodBegin, userSession.locale, "S"),
                        formatLocalDate(invoice.periodEnd, userSession.locale, "S"),
                        billingAccount?.companyName ?? "",
                        invoice.billingAccountId.toString(),
                        renderCurrency(invoice.netAmount, configStore.configuration.organisationCurrency),
                        renderCurrency(invoice.grossAmount, configStore.configuration.organisationCurrency),
                        (billingAccount?.receivers ?? []).join(", "),
                    ];
                }),
            ];
        },

        async loadBillingAccounts() {
            this.billingAccounts = await billingAccountsApi.list();
        },

        async loadOpenInvoices() {
            this.loadingOpenInvoices = true;
            try {
                await this.loadBillingAccounts();
                this.openInvoices = await invoicesApi.getAll(null, null, false);
            } finally {
                this.loadingOpenInvoices = false;
            }
        },

        async loadMonthInvoices() {
            this.loadingMonthInvoices = true;
            try {
                await this.loadBillingAccounts();
                this.monthInvoices = await invoicesApi.getByCreatedMonth(this.month);
            } finally {
                this.loadingMonthInvoices = false;
            }
        },

        async refresh() {
            await this.loadOpenInvoices();
            await this.loadMonthInvoices();
        },

        uploadCreditTransfers() {
            fileDialog(
                false,
                async (files) => {
                    this.isWorking = true;
                    try {
                        const result = await creditTransfersApi.getInvoicesForCreditTransfers(files[0], () => {
                            // do nothing
                        });
                        this.invoicesWithCreditTransfer = result.invoicesWithCreditTransfer;
                        this.unmatchedCreditTransfers = result.unmatchedCreditTransfers;
                    } finally {
                        this.isWorking = false;
                    }
                },
                [".csv"]
            );
        },

        async markAsPaidAndDownloadCreditTransfers(invoiceIds: number[]) {
            const filteredCreditTransfers = [
                ...this.invoicesWithCreditTransfer!.filter((i) => !i.invoice.paid)
                    .filter((i) => !invoiceIds.includes(i.invoice.id))
                    .map((i) => i.creditTransfer),
                ...this.unmatchedCreditTransfers,
            ].sort((a, b) => a.bookingDay.localeCompare(b.bookingDay));

            this.invoicesWithCreditTransfer = null;

            if (invoiceIds.length) {
                this.isWorking = true;
                try {
                    await invoicesApi.markAsPaid(invoiceIds);
                } finally {
                    this.isWorking = false;
                }
                await this.refresh();
            }

            if (filteredCreditTransfers.length) {
                this.downloadCreditTransferCsv(filteredCreditTransfers);
            }
        },

        downloadCreditTransferCsv(creditTransfers: CreditTransfer[]) {
            const csv = creditTransfers
                .map((ct) => [ct.bookingDay, ct.paymentParty, ct.purpose, ct.amount.toFixed(2)])
                .map((values) => values.map((v) => `"${v.replace(/"/g, '""')}"`))
                .map((values) => values.join(";"))
                .join("\r\n");

            const file = new File([UTF8_BOM + csv], `credit_transfers_${now().getTime()}.csv`, {
                type: "text/csv;charset=UTF-8;header=absent",
                encoding: "UTF-8",
            } as any);
            downloadOrOpenFile(file, true);
        },
    },

    watch: {
        async month(newValue) {
            await this.$router.push({ query: { ...this.$route.query, month: newValue } });
            await this.loadMonthInvoices();
        },
        async activeTab(newValue) {
            await this.$router.push({ query: { ...this.$route.query, activeTab: newValue } });
        },
    },

    async mounted() {
        await this.refresh();
    },

    components: {
        CsvDownloadIcon,
        InvoicesDataTable,
        InvoicesWithCreditTransferDialog,
        MonthPicker,
    },
});

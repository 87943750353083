
import { ActionFeedback } from "@/api/actions";
import Vue from "vue";

export default Vue.extend({
    props: {
        actionFeedback: {
            type: Object as () => ActionFeedback,
            required: true,
        },
    },
});

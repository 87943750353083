
import { BillingAccountForm, billingAccountsApi, PaymentMethod } from "@/api/billingAccounts";
import CountryPicker from "@/app/components/CountryPicker.vue";
import DatePicker from "@/app/components/DatePicker.vue";
import EnumField from "@/app/components/EnumField.vue";
import StatePicker from "@/app/components/StatePicker.vue";
import { showConfirm } from "@/app/messageUtil";
import { maxLength, notEmpty } from "@/app/validation";
import { configStore } from "@/store/config";
import { debounce } from "@/util/debounce";
import { DeepMutable } from "@/util/types";
import Vue from "vue";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
    props: {
        billingAccountId: {
            type: Number as () => number | null,
            default: null,
        },
    },

    data() {
        return {
            PaymentMethod,
            isWorking: false,
            billingAccountForm: {
                companyName: "",
                address1: null,
                address2: null,
                zip: "",
                city: "",
                state: null,
                country: configStore.configuration.organisationCountry,
                vatNumber: null,
                companyRegistrationNumber: null,
                receivers: [],
                paymentMethod: PaymentMethod.BANK_TRANSFER,
                sepaB2bDirectDebitPaymentInformation: {
                    iban: "",
                    bic: "",
                    mandateReference: "",
                    signatureDate: "",
                },
            } as DeepMutable<BillingAccountForm>,
            loading: !!this.billingAccountId,

            companyNameRules: notEmpty().maxLength(300),
            address1Rules: maxLength(300),
            address2Rules: maxLength(300),
            zipRules: notEmpty().maxLength(63),
            cityRules: notEmpty().maxLength(300),
            vatNumberRules: maxLength(63),
            companyRegistrationNumberRules: maxLength(63),

            ibanRules: notEmpty().maxLength(63),
            ibanErrors: [] as TranslateResult[],
            bicRules: notEmpty().maxLength(63),
            mandateReferenceRules: notEmpty().maxLength(63),
            notEmptyRules: notEmpty(),
            receiverRules: notEmpty().email(),
        };
    },

    watch: {
        iban: debounce(async function(iban: string) {
            this.ibanErrors =
                !iban || (await billingAccountsApi.checkIban(iban)) ? [] : [this.$t("Gültige IBAN ist erforderlich")];
        }, 500),
    },

    methods: {
        addReceiver() {
            this.billingAccountForm.receivers.push("");
        },

        async removeReceiver(index: number) {
            if (
                !!this.billingAccountForm.receivers[index] &&
                !(await showConfirm(
                    this.$t("Rechnungsempfänger löschen") as string,
                    this.$t("Sind Sie sicher, dass Sie den Rechnungsempfänger löschen möchten?") as string
                ))
            ) {
                return;
            }

            this.billingAccountForm.receivers.splice(index, 1);
        },

        async submitForm() {
            if (!(this.$refs.form as any).validate()) {
                return;
            }

            this.isWorking = true;

            const billingAccountForm: BillingAccountForm = {
                ...this.billingAccountForm,
                sepaB2bDirectDebitPaymentInformation:
                    this.billingAccountForm.paymentMethod === PaymentMethod.SEPA_B2B_DIRECT_DEBIT
                        ? this.billingAccountForm.sepaB2bDirectDebitPaymentInformation
                        : null,
            };

            try {
                if (this.billingAccountId) {
                    await billingAccountsApi.edit(this.billingAccountId, billingAccountForm);
                } else {
                    await billingAccountsApi.add(billingAccountForm);
                }
                this.$emit("success");
            } finally {
                this.isWorking = false;
            }
        },

        close() {
            this.$emit("close");
        },
    },

    async mounted() {
        if (this.billingAccountId) {
            this.loading = true;
            try {
                const billingAccount = await billingAccountsApi.getById(this.billingAccountId);

                if (!billingAccount) {
                    this.close();
                    return;
                }

                this.billingAccountForm = {
                    ...billingAccount,
                    sepaB2bDirectDebitPaymentInformation: {
                        iban: billingAccount.sepaB2bDirectDebitPaymentInformation?.iban ?? "",
                        bic: billingAccount.sepaB2bDirectDebitPaymentInformation?.bic ?? "",
                        mandateReference: billingAccount.sepaB2bDirectDebitPaymentInformation?.mandateReference ?? "",
                        signatureDate: billingAccount.sepaB2bDirectDebitPaymentInformation?.signatureDate ?? "",
                    },
                };
            } catch (e) {
                this.close();
            } finally {
                this.loading = false;
            }
        }
    },

    components: {
        CountryPicker,
        DatePicker,
        EnumField,
        StatePicker,
    },
});


import { BwaMonth } from "@/api/bwa";
import { InvoiceRevenueData } from "@/api/invoicerevenues";
import { renderCurrency } from "@/app/filters";
import InvoiceRevenueBottomSheet from "@/app/pages/invoicerevenue/InvoiceRevenueBottomSheet.vue";
import { getOtherRevenue } from "@/app/pages/invoicerevenue/invoiceRevenueUtils";
import { configStore } from "@/store/config";
import Vue from "vue";

export default Vue.extend({
    props: {
        billingAccountCount: {
            type: Number,
            required: true,
        },
        bwaMonth: {
            type: Object as () => BwaMonth | null,
            default: null,
        },
        dealerCount: {
            type: Number,
            required: true,
        },
        invoiceRevenueData: {
            type: Object as () => InvoiceRevenueData,
            required: true,
        },
        invoiceTotal: {
            type: Number,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        partitionCount: {
            type: Number,
            required: true,
        },
        unpaidInvoiceCount: {
            type: Number,
            required: true,
        },
        unpaidInvoiceTotal: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            invoiceRevenueBottomSheetVisible: false,
        };
    },

    computed: {
        currency(): string {
            return configStore.configuration.organisationCurrency;
        },

        otherAndSetupRevenue(): number {
            return this.otherRevenue + this.invoiceRevenueData.setupRevenue;
        },

        otherRevenue(): number {
            return getOtherRevenue(this.invoiceRevenueData);
        },

        recurringSoftwareRevenue(): number {
            return this.invoiceRevenueData.softwareRevenue + this.invoiceRevenueData.whatsAppNumberRevenue;
        },

        softwareSalesRatio(): number | null {
            return this.getRatio(
                this.recurringSoftwareRevenue + this.variableSoftwareRevenue,
                this.invoiceRevenueData.totalRevenue
            );
        },

        variableSoftwareRevenue(): number {
            return (
                this.invoiceRevenueData.callTrackingRevenue +
                this.invoiceRevenueData.clickToCallRevenue +
                this.invoiceRevenueData.outgoingSmsRevenue
            );
        },
    },

    methods: {
        formatCurrency(value: number): string {
            return renderCurrency(value, this.currency);
        },

        getRatio(value: number, total: number): number | null {
            if (!total) {
                return null;
            }

            return value / total;
        },
    },

    components: {
        InvoiceRevenueBottomSheet,
    },
});


import { CompensationSubject, Contract } from "@/api/contracts";

import { configStore } from "@/store/config";
import { SelectOptions } from "@/util/types/SelectOptions";
import Vue from "vue";

export default Vue.extend({
    props: {
        contract: {
            type: Object as () => Contract,
            required: true,
        },
    },

    data() {
        return {
            configStore,
        };
    },

    computed: {
        compensationSubjects(): SelectOptions {
            return Object.keys(CompensationSubject).map((k) => ({
                value: k,
                text: this.$t(`enum.CompensationSubject.${k}`),
            }));
        },
    },
});

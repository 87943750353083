
import { ExternalBdcCdrAndRecordings } from "./actionUtils";
import { Dealer } from "@/api/dealers";
import { recordingsApi } from "@/api/recordings";
import { userSession } from "@/store/userSession";
import { formatDifference, formatTime } from "@/util/dateTimeUtils";
import { parseAndFormatNumber } from "@/util/phoneNumberUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        dealers: {
            type: Array as () => Dealer[],
            required: true,
        },
        externalBdcCdrsAndRecordings: {
            type: Array as () => ExternalBdcCdrAndRecordings[],
            required: true,
        },
    },

    data() {
        return {
            parseAndFormatNumber,
        };
    },

    methods: {
        formatDifference(lengthInSeconds: number) {
            return formatDifference(new Date(0), new Date(lengthInSeconds * 1000), true);
        },

        formatTime(date: Date) {
            return formatTime(date, userSession.timeZone, userSession.locale);
        },

        generateRecordingLink(id: number): string {
            return recordingsApi.generateRecordingLink(id);
        },

        getDealerById(id: string | null): Dealer | null {
            return this.dealers.find((d) => d.id === id) ?? null;
        },
    },
});

import { Invoice } from "./invoices";
import axios from "axios";

export interface CreditTransfer {
    readonly bookingDay: string;
    readonly paymentParty: string;
    readonly purpose: string;
    readonly amount: number;
}

export interface InvoiceWithCreditTransfer {
    readonly invoice: Invoice;
    readonly creditTransfer: CreditTransfer;
}

export interface InvoicesWithCreditTransferResult {
    readonly invoicesWithCreditTransfer: InvoiceWithCreditTransfer[];
    readonly unmatchedCreditTransfers: CreditTransfer[];
}

interface CreditTransfersApi {
    getInvoicesForCreditTransfers(
        file: File,
        progressCallback: (progressEvent: ProgressEvent) => void
    ): Promise<InvoicesWithCreditTransferResult>;
}

export const creditTransfersApi: CreditTransfersApi = {
    async getInvoicesForCreditTransfers(file, onUploadProgress) {
        const formData = new FormData();
        formData.append("file", file);
        return (
            await axios.post("/api/credit-transfers/_invoices-for-credit-transfers", formData, { onUploadProgress })
        ).data;
    },
};

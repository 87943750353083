
import { showInfo } from "@/app/messageUtil";
import Vue from "vue";

export default Vue.extend({
    props: {
        content: {
            type: String,
            required: true,
        },
        hidden: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        async copy() {
            if (!navigator.clipboard) {
                this.fallbackCopyTextToClipboard(this.content);
            } else {
                await navigator.clipboard.writeText(this.content);
            }

            showInfo(
                this.hidden
                    ? (this.$t("Der Wert wurde erfolgreich in die Zwischenablage kopiert.") as string)
                    : (this.$t("{0} wurde erfolgreich in die Zwischenablage kopiert.", [this.content]) as string)
            );
        },

        fallbackCopyTextToClipboard(text: string) {
            const textArea = document.createElement("textarea");
            textArea.value = text;
            textArea.style.top = "0";
            textArea.style.left = "0";
            textArea.style.position = "fixed";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            try {
                document.execCommand("copy");
            } finally {
                document.body.removeChild(textArea);
            }
        },
    },
});

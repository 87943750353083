import { getSeriesValuesMatrixMaxValue, ReportingChartColorPalette } from "./reportingChart";

export interface ReportingBarChartCategory {
    readonly name: string;
    readonly description?: string;
}

export interface ReportingBarChartData {
    readonly title: string;
    readonly subtitle?: string;
    readonly categories: ReportingBarChartCategory[];
    readonly series: ReportingBarChartSeries[];
    readonly seriesDataTooltipHeader?: string;
    readonly seriesAdditionalTooltipDataTooltipHeaders?: string[];
}

export interface ReportingBarChartSeries {
    readonly id: string;
    readonly name: string;
    readonly tooltip?: string;
    readonly selected?: boolean;
    readonly data: ReportingBarChartSeriesData;
    readonly color?: string;
    readonly colorPalette?: ReportingChartColorPalette;
    readonly group?: string;
    readonly additionalTooltipData?: ReportingBarChartSeriesData[];
}

export interface ReportingBarChartSeriesData {
    readonly values: ReportingBarChartSeriesValue[];
    readonly isPercentage?: boolean;
    readonly formatter?: ReportingBarChartSeriesValueFormatter;
}

export interface ReportingBarChartSeriesValue {
    readonly value: number | undefined;
    readonly onClick?: () => Promise<void> | void;
}

export type ReportingBarChartSeriesValueFormatter = (
    value: number | undefined,
    isPercentage: boolean,
    context: "LABEL" | "TOOLTIP"
) => string | null;

export function isNonZeroSeries(series: ReportingBarChartSeries): boolean {
    return series.data.values.some((v) => (v.value ?? 0) !== 0);
}

export function hasNonZeroSeries(chart: ReportingBarChartData): boolean {
    return chart.series.some(isNonZeroSeries);
}

export function getSeriesMaxValue(series: ReportingBarChartSeries[], stacked: boolean): number | undefined {
    if (!series.length) {
        return undefined;
    }

    const indexes = Array.from(series[0].data.values.keys());
    const groups = series.map((s) => s.group).filter((group, index, array) => array.indexOf(group) === index);

    const maxValues = groups
        .map((group) =>
            getSeriesValuesMatrixMaxValue(
                indexes.map((index) => series.filter((s) => s.group === group).map((s) => s.data.values[index].value)),
                stacked
            )
        )
        .filter((max) => max !== undefined) as number[];

    if (!maxValues.length) {
        return undefined;
    }

    return Math.max(...maxValues);
}

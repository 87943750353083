import {
    hasNonZeroSeries as hasBarChartNonZeroSeries,
    ReportingBarChartData,
    ReportingBarChartSeries,
    ReportingBarChartSeriesData,
    ReportingBarChartSeriesValueFormatter,
} from "./charts/reportingBarChart";
import { ReportingDonutChartSeries } from "./charts/reportingDonutChart";
import { ReportingLineChartData, ReportingLineChartSeries } from "./charts/reportingLineChart";
import {
    hasNonZeroSeries as hasPunchCardChartNonZeroSeries,
    ReportingPunchCardChartData,
    ReportingPunchCardChartSeries,
    ReportingPunchCardChartSeriesData,
    ReportingPunchCardChartSeriesValueFormatter,
} from "./charts/reportingPunchCardChart";
import { ReportingScatterChartData } from "./charts/reportingScatterChart";
import {
    getDataPoints,
    hasNonZeroSeries as hasTimeSeriesChartNonZeroSeries,
    ReportingTimeSeriesChartData,
    ReportingTimeSeriesChartSeries,
    ReportingTimeSeriesChartSeriesData,
    ReportingTimeSeriesChartSeriesValueFormatter,
    ReportingTimeSeriesDateFormatter,
} from "./charts/reportingTimeSeriesChart";
import { renderDuration } from "./reportingUtils";
import {
    extractHeaderGroup,
    ReportingTableData,
    ReportingTableHeaderGroup,
    ReportingTableItem,
    ReportingTableItemColumnEntries,
    ReportingTableValue,
} from "./table/reportingTable";
import { DateTimeOutputFormat } from "@/util/dateTimeUtils";

export function durationFormatter(
    seconds: number | undefined | null,
    context: "LABEL" | "TOOLTIP",
    format?: DateTimeOutputFormat
): string | null {
    if (typeof seconds !== "number") {
        return null;
    }

    const isTooltip = context === "TOOLTIP";
    const duration = renderDuration(seconds, format ?? (isTooltip ? "L" : "S"), !isTooltip);

    if (!duration || isTooltip) {
        return duration;
    }

    return duration.replace(/ /g, "\n");
}

function getReportingTableEntries(
    s:
        | ReportingBarChartSeries
        | ReportingLineChartSeries
        | ReportingPunchCardChartSeries
        | ReportingTimeSeriesChartSeries,
    valueIndex: number,
    valueClass?: string,
    additionalValuesClasses?: string[]
): ReportingTableItemColumnEntries {
    const classes = [valueClass, ...(additionalValuesClasses ?? [])];
    const data: {
        readonly values: {
            readonly value: number | undefined;
            readonly onClick?: () => Promise<void> | void;
        }[];
        readonly isPercentage?: boolean;
        readonly formatter?: (
            value: number | undefined,
            isPercentage: boolean,
            context: "LABEL" | "TOOLTIP"
        ) => string | null;
    }[] = [s.data, ...(s.additionalTooltipData ?? [])];

    return data.map(
        (d, classIndex: number) =>
            ({
                value: d.values[valueIndex].value,
                isPercentage: d.isPercentage,
                class: classes[classIndex],
                onClick: d.values[valueIndex].onClick,
                formatter: d.formatter
                    ? (value, isPercentage) => d.formatter!(value, isPercentage, "TOOLTIP")
                    : undefined,
            } as ReportingTableValue)
    ) as ReportingTableItemColumnEntries;
}

/*
 * bar chart
 */

function getBarChartGroups(series: ReportingBarChartSeries[]): (string | undefined)[] {
    return series.map((series) => series.group).filter((group, index, array) => array.indexOf(group) === index);
}

export function getReportingTableByReportingBarChart(
    chart: ReportingBarChartData,
    valueClass?: string,
    additionalValuesClasses?: string[]
): ReportingTableData {
    const groups = getBarChartGroups(chart.series);

    return {
        headerGroups: groups.map((group) => ({
            text: group ?? null,
            headers: chart.series
                .filter((series) => series.group === group)
                .map((series) => ({ text: series.name, tooltip: series.tooltip })),
        })),
        items: chart.categories.map((category, index) => ({
            title: category.name,
            subtitle: category.description,
            groups: groups.map((group) => ({
                columns: chart.series
                    .filter((s) => s.group === group)
                    .map((s) => ({
                        entries: getReportingTableEntries(s, index, valueClass, additionalValuesClasses),
                    })),
            })),
        })),
    };
}

export interface BarChartSeriesOptions {
    readonly color?: ReportingBarChartSeries["color"];
    readonly colorPalette?: ReportingBarChartSeries["colorPalette"];
    readonly selected?: ReportingBarChartSeries["selected"];
    readonly group?: ReportingBarChartSeries["group"];
    readonly formatter?: ReportingBarChartSeriesData["formatter"];
}

export function getReportingBarChart(table: ReportingTableData<BarChartSeriesOptions | never>): ReportingBarChartData {
    return {
        title: "",
        categories: table.items.map((item) => ({
            name: item.title,
            description: item.subtitle,
        })),
        series: table.headerGroups
            .map((headerGroup, headerGroupIndex) =>
                headerGroup.headers.map((header, columnIndex) => {
                    const tableValues = table.items
                        .map((item) => item.groups[headerGroupIndex].columns[columnIndex].entries)
                        .map((entries) => entries[0]);

                    const tableValueFormatter = tableValues.find((v) => v.formatter)?.formatter;

                    const formatter: ReportingBarChartSeriesValueFormatter | undefined = tableValueFormatter
                        ? (value, isPercentage) => tableValueFormatter!(value, isPercentage)
                        : undefined;

                    return {
                        id: `series-${headerGroupIndex}-${columnIndex}`,
                        name: header.text,
                        tooltip: header.tooltip,
                        data: {
                            values: tableValues.map((tableValue) => ({
                                value: tableValue.value,
                                onClick: tableValue.onClick,
                            })),
                            isPercentage: tableValues.some((tableValue) => tableValue.isPercentage),
                            formatter: header.options?.formatter ?? formatter,
                        },
                        color: header.options?.color,
                        colorPalette: header.options?.colorPalette,
                        selected: header.options?.selected,
                        group: header.options?.group,
                    } as ReportingBarChartSeries;
                })
            )
            .reduce((prev, cur) => prev.concat(cur), []),
    };
}

export function getReportingBarChartForHeaderGroup(
    table: ReportingTableData<BarChartSeriesOptions> | null,
    headerGroupIndex: number
): ReportingBarChartData | null {
    if (!table) {
        return null;
    }

    const headerGroupTable = extractHeaderGroup<BarChartSeriesOptions>(table, headerGroupIndex);

    const chart: ReportingBarChartData = getReportingBarChart(headerGroupTable);

    if (!hasBarChartNonZeroSeries(chart)) {
        return null;
    }

    return { ...chart, title: headerGroupTable.headerGroups[0].text ?? chart.title };
}

/*
 * donut chart
 */

export function getReportingTableItemsByReportingDonutChartSeries(
    series: ReportingDonutChartSeries[],
    valueClass?: string,
    additionalValuesClasses?: string[]
): ReportingTableItem[] {
    return series.map((s) => ({
        title: s.name,
        groups: [
            {
                columns: [
                    {
                        entries: [
                            {
                                value: s.data.value.value,
                                onClick: s.data.value.onClick,
                                formatter: s.data.formatter
                                    ? (value) => s.data.formatter!(value, "TOOLTIP")
                                    : undefined,
                            },
                            ...((s.additionalTooltipData ?? []).map((adtData, adtDataIndex) => ({
                                value: adtData.value.value,
                                class:
                                    adtDataIndex < (additionalValuesClasses || []).length
                                        ? additionalValuesClasses![adtDataIndex]
                                        : undefined,
                                onClick: adtData.value.onClick,
                                formatter: adtData.formatter
                                    ? (value) => adtData.formatter!(value, "TOOLTIP")
                                    : undefined,
                            })) as ReportingTableValue[]),
                        ],
                    },
                ],
            },
        ],
    }));
}

/*
 * line chart
 */

export function getReportingTableByReportingLineChart(
    chart: ReportingLineChartData,
    valueClass?: string,
    additionalValuesClasses?: string[]
): ReportingTableData {
    return {
        headerGroups: [
            {
                text: null,
                headers: chart.series.map((s) => ({ text: s.name, tooltip: s.tooltip })),
            },
        ],
        items: chart.categories.map((category, index) => ({
            title: category,
            groups: [
                {
                    columns: chart.series.map((s) => ({
                        entries: getReportingTableEntries(s, index, valueClass, additionalValuesClasses),
                    })),
                },
            ],
        })),
    };
}

/*
 * punch card chart
 */

export function getReportingTableByReportingPunchCardChart(
    chart: ReportingPunchCardChartData,
    valueClass?: string,
    additionalValuesClasses?: string[]
): ReportingTableData {
    return {
        headerGroups: [
            {
                text: null,
                headers: chart.series.map((s) => ({ text: s.name, tooltip: s.tooltip })),
            },
        ],
        items: chart.categories.map((category, index) => ({
            title: category.name,
            subtitle: category.description,
            groups: [
                {
                    columns: chart.series.map((s) => ({
                        entries: getReportingTableEntries(s, index, valueClass, additionalValuesClasses),
                    })),
                },
            ],
        })),
    };
}

export interface PunchCardChartSeriesOptions {
    readonly formatter?: ReportingPunchCardChartSeriesData["formatter"];
}

export function getReportingPunchCardChart(
    table: ReportingTableData<PunchCardChartSeriesOptions | never>
): ReportingPunchCardChartData {
    return {
        title: "",
        categories: table.items.map((item) => ({
            name: item.title,
            description: item.subtitle,
        })),
        series: table.headerGroups
            .map((headerGroup, headerGroupIndex) =>
                headerGroup.headers.map((header, columnIndex) => {
                    const tableValues = table.items
                        .map((item) => item.groups[headerGroupIndex].columns[columnIndex].entries)
                        .map((entries) => entries[0]);

                    const tableValueFormatter = tableValues.find((v) => v.formatter)?.formatter;

                    const formatter: ReportingPunchCardChartSeriesValueFormatter | undefined = tableValueFormatter
                        ? (value) => tableValueFormatter!(value, false)
                        : undefined;

                    return {
                        id: `series-${headerGroupIndex}-${columnIndex}`,
                        name: header.text,
                        data: {
                            values: tableValues.map((tableValue) => ({
                                value: tableValue.value,
                                onClick: tableValue.onClick,
                            })),
                            isPercentage: tableValues.some((tableValue) => tableValue.isPercentage),
                            formatter: header.options?.formatter ?? formatter,
                        },
                    } as ReportingPunchCardChartSeries;
                })
            )
            .reduce((prev, cur) => prev.concat(cur), []),
    };
}

export function getPunchCardChartForHeaderGroup(
    table: ReportingTableData<PunchCardChartSeriesOptions> | null,
    headerGroupIndex: number
): ReportingPunchCardChartData | null {
    if (!table) {
        return null;
    }

    const headerGroupTable = extractHeaderGroup<PunchCardChartSeriesOptions>(table, headerGroupIndex);

    const chart: ReportingPunchCardChartData = getReportingPunchCardChart(headerGroupTable);

    if (!hasPunchCardChartNonZeroSeries(chart)) {
        return null;
    }

    return { ...chart, title: headerGroupTable.headerGroups[0].text ?? chart.title };
}

/*
 * scatter chart
 */

export function getReportingTableByReportingScatterChart(
    chart: ReportingScatterChartData,
    valueClass?: string,
    additionalValuesClasses?: string[]
): ReportingTableData {
    const classes = [valueClass, ...(additionalValuesClasses ?? [])];

    return {
        headerGroups: chart.series
            .map((series) => {
                const xAxis = chart.xAxis.find((axis) => axis.id === series.data.xAxisId);
                const yAxis = chart.yAxis.find((axis) => axis.id === series.data.yAxisId);

                if (!xAxis || !yAxis) {
                    return null;
                }

                return {
                    text: series.name,
                    tooltip: series.tooltip,
                    headers: [{ text: xAxis.name ?? "X" }, { text: yAxis.name ?? "Y" }],
                } as ReportingTableHeaderGroup;
            })
            .filter((hg): hg is ReportingTableHeaderGroup => !!hg),
        items: chart.categories.map((category, categoryIndex) => ({
            title: category.name,
            subtitle: category.description,
            groups: chart.series.map((series) => {
                const data = [series.data, ...(series.additionalTooltipData ?? [])].map((d) => ({
                    ...d,
                    xAxis: chart.xAxis.find((axis) => axis.id === d.xAxisId),
                    yAxis: chart.yAxis.find((axis) => axis.id === d.yAxisId),
                }));

                return {
                    columns: [
                        {
                            entries: data.map((d, classIndex: number) => ({
                                value: d.values[categoryIndex].xValue,
                                isPercentage: !!d.xAxis?.isPercentage,
                                class: classes[classIndex],
                                onClick: d.values[categoryIndex].onClick,
                                formatter: d.xAxis?.formatter
                                    ? (value, isPercentage) => d.xAxis?.formatter!(value, isPercentage, "TOOLTIP")
                                    : undefined,
                            })) as ReportingTableItemColumnEntries,
                        },
                        {
                            entries: data.map((d, classIndex: number) => ({
                                value: d.values[categoryIndex].yValue,
                                isPercentage: !!d.yAxis?.isPercentage,
                                class: classes[classIndex],
                                onClick: d.values[categoryIndex].onClick,
                                formatter: d.yAxis?.formatter
                                    ? (value, isPercentage) => d.yAxis?.formatter!(value, isPercentage, "TOOLTIP")
                                    : undefined,
                            })) as ReportingTableItemColumnEntries,
                        },
                    ],
                };
            }),
        })),
    };
}

/*
 * time series chart
 */

export function getReportingTableByReportingTimeSeriesChart(
    chart: ReportingTimeSeriesChartData,
    valueClass?: string,
    additionalValuesClasses?: string[]
): ReportingTableData {
    const dataPoints = getDataPoints(chart.series);

    return {
        headerGroups: [
            {
                text: null,
                headers: chart.series.map((s) => ({ text: s.name, tooltip: s.tooltip })),
            },
        ],
        items: dataPoints.map((date) => ({
            title: chart.dateFormatter(date, false),
            groups: [
                {
                    columns: chart.series.map((s) => ({
                        entries: getReportingTableEntries(
                            s,
                            s.data.values.findIndex((v) => v.date.getTime() === date.getTime()),
                            valueClass,
                            additionalValuesClasses
                        ),
                    })),
                },
            ],
        })),
    };
}

export interface TimeSeriesChartSeriesOptions {
    readonly color?: ReportingTimeSeriesChartSeries["color"];
    readonly colorPalette?: ReportingTimeSeriesChartSeries["colorPalette"];
    readonly selected?: ReportingTimeSeriesChartSeries["selected"];
    readonly formatter?: ReportingTimeSeriesChartSeriesData["formatter"];
}

export interface TimeSeriesChartSeriesDataPointOptions {
    readonly date: Date;
}

export function getReportingTimeSeriesChart(
    table: ReportingTableData<TimeSeriesChartSeriesOptions | never, TimeSeriesChartSeriesDataPointOptions>,
    dateFormatter: ReportingTimeSeriesDateFormatter
): ReportingTimeSeriesChartData {
    return {
        title: "",
        series: table.headerGroups
            .map((headerGroup, headerGroupIndex) =>
                headerGroup.headers.map((header, columnIndex) => {
                    const tableValues = table.items
                        .filter((item) => item.options)
                        .map((item) => ({
                            ...item.groups[headerGroupIndex].columns[columnIndex].entries[0],
                            date: item.options!.date,
                        }));

                    const tableValueFormatter = tableValues.find((v) => v.formatter)?.formatter;

                    const formatter: ReportingTimeSeriesChartSeriesValueFormatter | undefined = tableValueFormatter
                        ? (value, isPercentage) => tableValueFormatter!(value, isPercentage)
                        : undefined;

                    return {
                        id: `series-${headerGroupIndex}-${columnIndex}`,
                        name: header.text,
                        data: {
                            values: tableValues.map((tableValue) => ({
                                date: tableValue.date,
                                value: tableValue.value,
                                onClick: tableValue.onClick,
                            })),
                            isPercentage: tableValues.some((tableValue) => tableValue.isPercentage),
                            formatter: header.options?.formatter ?? formatter,
                        },
                        color: header.options?.color,
                        colorPalette: header.options?.colorPalette,
                        selected: header.options?.selected,
                    } as ReportingTimeSeriesChartSeries;
                })
            )
            .reduce((prev, cur) => prev.concat(cur), []),
        dateFormatter,
    };
}

export function getReportingTimeSeriesChartForHeaderGroup(
    table: ReportingTableData<TimeSeriesChartSeriesOptions, TimeSeriesChartSeriesDataPointOptions> | null,
    dateFormatter: ReportingTimeSeriesDateFormatter,
    headerGroupIndex: number
): ReportingTimeSeriesChartData | null {
    if (!table) {
        return null;
    }

    const headerGroupTable = extractHeaderGroup<TimeSeriesChartSeriesOptions, TimeSeriesChartSeriesDataPointOptions>(
        table,
        headerGroupIndex
    );

    const chart: ReportingTimeSeriesChartData = getReportingTimeSeriesChart(headerGroupTable, dateFormatter);

    if (!hasTimeSeriesChartNonZeroSeries(chart)) {
        return null;
    }

    return { ...chart, title: headerGroupTable.headerGroups[0].text ?? chart.title };
}


import { ShiftScheduleTemplate, shiftScheduleTemplatesApi } from "@/api/shiftScheduleTemplates";
import { userSession } from "@/store/userSession";
import Vue from "vue";

export default Vue.extend({
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        icon: {
            type: String,
            required: true,
        },
        tooltip: {
            type: String,
            required: true,
        },
        userId: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            allTemplates: [] as ShiftScheduleTemplate[],
            showAllTemplates: false,
        };
    },

    computed: {
        templates(): ShiftScheduleTemplate[] {
            return this.allTemplates.filter((t) => this.showAllTemplates || t.userIds.includes(this.userId));
        },
    },

    methods: {
        apply(template: ShiftScheduleTemplate) {
            this.$emit("apply", template.entries);
        },
    },

    async mounted() {
        this.allTemplates = (await shiftScheduleTemplatesApi.getTemplates()).sort((a, b) =>
            a.name.localeCompare(b.name, userSession.locale)
        );
    },
});

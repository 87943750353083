var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-simple-table',{staticClass:"ma-0",attrs:{"dense":""}},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"}),_c('th',{staticClass:"caption py-0"},[_vm._v(" "+_vm._s(_vm.$t("monatl. Grundgebühr"))+" ")]),_c('th',{staticClass:"caption py-0"},[_vm._v(" "+_vm._s(_vm.$t("Einheiten inkl."))+" ")]),_c('th',{staticClass:"caption py-0"},[_vm._v(" "+_vm._s(_vm.$t("Preis pro Einheit"))+" ")])])]),_c('tbody',_vm._l((_vm.compensationSubjects),function(compensationSubject){return _c('tr',{key:compensationSubject.value},[_c('td',{staticClass:"caption py-0 text-no-wrap",staticStyle:{"height":"auto"}},[_vm._v(" "+_vm._s(compensationSubject.text)+" ")]),_c('td',{staticClass:"caption py-0",staticStyle:{"height":"auto"}},[(
                        _vm.contract.compensationItems[compensationSubject.value] &&
                            _vm.contract.compensationItems[compensationSubject.value].monthlyFee !== 0
                    )?_c('span',[_c('strong',[_vm._v(_vm._s(_vm._f("currency")(_vm.contract.compensationItems[compensationSubject.value].monthlyFee,_vm.configStore.configuration.organisationCurrency)))])]):_vm._e()]),_c('td',{staticClass:"caption py-0",staticStyle:{"height":"auto"}},[(
                        _vm.contract.compensationItems[compensationSubject.value] &&
                            _vm.contract.compensationItems[compensationSubject.value].unitsIncluded !== 0
                    )?_c('span',[_c('strong',[_vm._v(_vm._s(_vm.$n(_vm.contract.compensationItems[compensationSubject.value].unitsIncluded)))])]):_vm._e()]),_c('td',{staticClass:"caption py-0",staticStyle:{"height":"auto"}},[(
                        _vm.contract.compensationItems[compensationSubject.value] &&
                            _vm.contract.compensationItems[compensationSubject.value].pricePerAdditionalUnit !== 0
                    )?_c('span',[_c('strong',[_vm._v(_vm._s(_vm._f("currency")(_vm.contract.compensationItems[compensationSubject.value].pricePerAdditionalUnit,_vm.configStore.configuration.organisationCurrency)))])]):_vm._e()])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }
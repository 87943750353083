
import { partitionSettingsApi, PartitionSettingsForm } from "@/api/partitionSettings";
import { notEmpty } from "@/app/validation";
import Vue from "vue";

export default Vue.extend({
    props: {
        partitionId: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            isWorking: false,
            notEmpty,
            partitionSettingsForm: null as PartitionSettingsForm | null,
        };
    },

    methods: {
        close() {
            this.$emit("close");
        },

        async loadSettings() {
            this.partitionSettingsForm = null;
            this.isWorking = true;
            try {
                this.partitionSettingsForm = await partitionSettingsApi.getSettings(this.partitionId);
            } finally {
                this.isWorking = false;
            }
        },

        async saveSettings() {
            if (!this.partitionSettingsForm || !(this.$refs.form as any).validate()) {
                return;
            }

            this.isWorking = true;
            try {
                await partitionSettingsApi.editSettings(this.partitionId, this.partitionSettingsForm);
                this.$emit("close");
            } finally {
                this.isWorking = false;
            }
        },
    },

    async mounted() {
        await this.loadSettings();
    },
});

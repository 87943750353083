
import { User, usersApi } from "@/api/users";
import NumberField from "@/app/components/NumberField.vue";
import { notEmpty } from "@/app/validation";
import Vue from "vue";

export default Vue.extend({
    props: {
        user: Object as () => User,
    },

    data() {
        return {
            weeklyWorkingHours: this.user.weeklyWorkingHours ?? 0,
            weeklyWorkingHoursRules: notEmpty().integer(0),
            isWorking: false,
        };
    },

    methods: {
        async submitForm() {
            if (!(this.$refs.form as any).validate()) {
                return;
            }

            this.isWorking = true;
            try {
                await usersApi.editWeeklyWorkingHours(this.user.id, this.weeklyWorkingHours);
            } finally {
                this.isWorking = false;
            }
            this.$emit("success");
        },

        close() {
            this.$emit("close");
        },
    },

    components: {
        NumberField,
    },
});

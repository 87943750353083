
import { ExternalBdcCdr } from "@/api/externalBdcCdrs";
import { Recording, recordingsApi } from "@/api/recordings";
import Vue from "vue";

export default Vue.extend({
    props: {
        cdr: {
            type: Object as () => ExternalBdcCdr,
        },
    },

    data() {
        return {
            loading: true,
            recordings: [] as Recording[],
        };
    },

    methods: {
        close() {
            this.$emit("close");
        },

        generateRecordingLink(id: number): string {
            return recordingsApi.generateRecordingLink(id);
        },
    },

    async mounted() {
        this.recordings = await recordingsApi.getByBeginRangeAndCaller(
            new Date(this.cdr.begin.getTime() - 1000),
            new Date(this.cdr.begin.getTime() + 1000),
            this.cdr.caller
        );
        this.loading = false;
    },
});

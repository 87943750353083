
import { Dealer, dealersApi } from "@/api/dealers";
import { partitionsApi, PartitionSummary } from "@/api/partitions";
import { ExternalBdcCdrRow, reportingApi } from "@/api/reporting";
import DatePicker from "@/app/components/DatePicker.vue";
import NumberField from "@/app/components/NumberField.vue";
import ReportingLineChart from "@/app/pages/reporting/charts/ReportingLineChart.vue";
import { ReportingLineChartData } from "@/app/pages/reporting/charts/reportingLineChart";
import { renderDuration } from "@/app/pages/reporting/reportingUtils";
import ReportingTable from "@/app/pages/reporting/table/ReportingTable.vue";
import { ReportingTableData } from "@/app/pages/reporting/table/reportingTable";
import {
    getOngoingTimeSlotKey,
    getOngoingTimeSlotLabelByKey,
    OngoingTimeInterval,
} from "@/app/pages/reporting/timeInterval";
import { getTimeSeriesDefaultKeys } from "@/app/pages/reporting/timeSeriesUtils";
import { notEmpty } from "@/app/validation";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { getDate, toDateObject } from "@/util/dateTimeUtils";
import { ActionLimiter } from "@/util/debounce";
import { SelectOption } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            notEmpty,
            dealers: [] as Dealer[],
            expectedCallsPerHourPerAgent: 25,
            expectedCallAcceptanceRate: 0.9,
            expectedWaitingTimeThreshold: 4,
            loadFilter: {
                dealerIds: [] as string[],
                partitionIds: [] as string[],
                comparisonDate: getDate(now(), userSession.timeZone, -7),
            },
            loading: true,
            loadLimiter: new ActionLimiter(true),
            nowAtLoadRows: null as Date | null,
            ongoingTimeInterval: OngoingTimeInterval.SIXTY_MINUTES,
            partitions: [] as PartitionSummary[],
            rows: [] as readonly ExternalBdcCdrRow[],
            searchId: 0,
            waitingTimeThresholds: [0, 1, 4, 5, 6, 10],
        };
    },

    computed: {
        chart(): ReportingLineChartData | null {
            if (!this.table) {
                return null;
            }

            const headerGroup = this.table.headerGroups[2];
            const waitingTimeGroups = this.table.items.map((item) => ({
                text: item.title,
                columns: item.groups[2].columns,
            }));
            const agents = this.table.items.map((item) => item.groups[0].columns[0].entries[0]);

            const expectedWaitingTimeThresholdIndex = this.waitingTimeThresholds.findIndex(
                (t) => t === this.expectedWaitingTimeThreshold
            );

            return {
                title: this.$t("Benötigte Agentenanzahl") as string,
                categories: waitingTimeGroups.map((g) => g.text),
                series: [
                    ...headerGroup.headers.map((headerGroup, headerGroupIndex) => ({
                        id: `required-agents-${headerGroup.text}`,
                        name: headerGroup.text,
                        selected: expectedWaitingTimeThresholdIndex === headerGroupIndex,
                        data: {
                            values: waitingTimeGroups.map((g) => ({
                                value: g.columns[headerGroupIndex].entries[2].value,
                            })),
                        },
                    })),
                    {
                        id: "actual-agents",
                        name: this.$t("Tatsächlich eingesetzte Agenten") as string,
                        data: {
                            values: agents.map((a) => ({
                                value: a.value,
                            })),
                        },
                    },
                ],
            };
        },

        dealerOptions(): SelectOption[] {
            return this.dealers
                .map((d) => ({ value: d.id, text: d.name }))
                .sort((a, b) => a.text.localeCompare(b.text, userSession.locale));
        },

        defaultKeys(): string[] {
            return getTimeSeriesDefaultKeys(
                this.rows,
                (row) => row.begin,
                null,
                null,
                this.ongoingTimeInterval,
                this.nowAtLoadRows,
                userSession.timeZone
            );
        },

        table(): ReportingTableData | null {
            if (!this.rows.length) {
                return null;
            }

            const table: ReportingTableData = {
                headerGroups: [
                    {
                        text: this.$t("Agenten") as string,
                        headers: [
                            {
                                text: this.$t("Anzahl") as string,
                            },
                            {
                                text: this.$t("ø Gesprächszeit") as string,
                            },
                            {
                                text: this.$t("ø Abgerechnete Minuten") as string,
                            },
                        ],
                    },
                    {
                        text: this.$t("Anrufe") as string,
                        headers: [
                            {
                                text: this.$t("ø Angenommen") as string,
                            },
                            {
                                text: this.$t("ø Gesprächsdauer") as string,
                            },
                        ],
                    },
                    {
                        text: this.$t("Annahmequote für Anrufe mit einer Wartezeit von wenigstens") as string,
                        headers: this.waitingTimeThresholds.map((waitingTimeThreshold) => ({
                            text: this.$n(waitingTimeThreshold, { style: "unit", unit: "second" }),
                        })),
                    },
                ],
                items: this.defaultKeys.map((key) => {
                    const rowsInTimeSlot = this.rows.filter(
                        (row) =>
                            getOngoingTimeSlotKey(row.begin, this.ongoingTimeInterval, userSession.timeZone) === key
                    );

                    const rowsAcceptedInTimeSlot = rowsInTimeSlot.filter((row) => row.duration !== null);

                    const agentIdsInTimeSlot = rowsAcceptedInTimeSlot
                        .map((row) => row.userId)
                        .sort()
                        .filter((value, index, array) => array.indexOf(value) === index);

                    const agentCountInTimeSlot = agentIdsInTimeSlot.length;

                    const durationSum = rowsAcceptedInTimeSlot.reduce((dur, row) => dur + row.duration!, 0);
                    const billedMinutesSum = rowsAcceptedInTimeSlot.reduce((dur, row) => dur + row.billedMinutes!, 0);

                    const callsAcceptedPerAgent = agentCountInTimeSlot
                        ? rowsAcceptedInTimeSlot.length / agentCountInTimeSlot
                        : undefined;

                    const callDurationAvg = rowsAcceptedInTimeSlot.length
                        ? durationSum / rowsAcceptedInTimeSlot.length
                        : undefined;

                    return {
                        title: getOngoingTimeSlotLabelByKey(key, this.ongoingTimeInterval, userSession.timeZone, "S")!,
                        groups: [
                            {
                                columns: [
                                    {
                                        entries: [{ value: agentCountInTimeSlot }],
                                    },
                                    {
                                        entries: [
                                            {
                                                value: agentCountInTimeSlot
                                                    ? durationSum / agentCountInTimeSlot
                                                    : undefined,
                                                formatter: (value) => renderDuration(value, "S", true),
                                            },
                                        ],
                                    },
                                    {
                                        entries: [
                                            {
                                                value: agentCountInTimeSlot
                                                    ? (60 * billedMinutesSum) / agentCountInTimeSlot
                                                    : undefined,
                                                formatter: (value) => renderDuration(value, "S", true),
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                columns: [
                                    {
                                        entries: [
                                            {
                                                value: callsAcceptedPerAgent,
                                            },
                                        ],
                                    },
                                    {
                                        entries: [
                                            {
                                                value: callDurationAvg,
                                                formatter: (value) => renderDuration(value, "S", true),
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                columns: this.waitingTimeThresholds.map((waitingTimeThreshold) => {
                                    const rowsInWaitingTimeThreshold = rowsInTimeSlot.filter(
                                        (row) => row.waited >= waitingTimeThreshold || row.duration !== null
                                    );

                                    const callsCount = rowsInWaitingTimeThreshold.length;
                                    const callsAccepted = rowsInWaitingTimeThreshold.filter(
                                        (row) => row.duration !== null
                                    ).length;

                                    const acceptanceRate = callsCount ? callsAccepted / callsCount : undefined;

                                    const entryClass =
                                        acceptanceRate === undefined
                                            ? undefined
                                            : acceptanceRate >= this.expectedCallAcceptanceRate
                                            ? "success--text"
                                            : acceptanceRate >= Math.max(0, this.expectedCallAcceptanceRate - 0.05)
                                            ? "warning--text"
                                            : "error--text";

                                    const hourFraction = this.timeIntervalMinutes! / 60;

                                    const requiredAgents = Math.ceil(
                                        (callsCount * this.expectedCallAcceptanceRate) /
                                            (this.expectedCallsPerHourPerAgent * hourFraction)
                                    );

                                    return {
                                        entries: [
                                            {
                                                value: acceptanceRate !== undefined ? acceptanceRate : undefined,
                                                isPercentage: true,
                                                class: entryClass,
                                            },
                                            {
                                                value: callsCount,
                                                formatter: (value) => {
                                                    if (value === undefined) {
                                                        return null;
                                                    }

                                                    const formatted = this.$tc(
                                                        "0 Anrufe | 1 Anruf | {count} Anrufe",
                                                        value,
                                                        { count: this.$n(value) }
                                                    ) as string;

                                                    return `${this.$n(callsAccepted)}/${formatted}`;
                                                },
                                            },
                                            {
                                                value: requiredAgents,
                                                formatter: (value) => {
                                                    if (value === undefined) {
                                                        return null;
                                                    }

                                                    return this.$tc(
                                                        "0 Agenten | 1 Agent | {count} Agenten",
                                                        value
                                                    ) as string;
                                                },
                                            },
                                        ],
                                    };
                                }),
                            },
                        ],
                    };
                }),
            };

            if (!table.items.length) {
                return null;
            }

            return table;
        },

        timeIntervalMinutes(): number | null {
            if (this.ongoingTimeInterval === OngoingTimeInterval.FIFTEEN_MINUTES) {
                return 15;
            } else if (this.ongoingTimeInterval === OngoingTimeInterval.THIRTY_MINUTES) {
                return 30;
            } else if (this.ongoingTimeInterval === OngoingTimeInterval.SIXTY_MINUTES) {
                return 60;
            } else {
                return null;
            }
        },

        timeIntervalOptions(): SelectOption[] {
            return [
                {
                    text: this.$tc("0 Minuten | 1 Minute | {count} Minuten", 15),
                    value: OngoingTimeInterval.FIFTEEN_MINUTES,
                },
                {
                    text: this.$tc("0 Minuten | 1 Minute | {count} Minuten", 30),
                    value: OngoingTimeInterval.THIRTY_MINUTES,
                },
                {
                    text: this.$tc("0 Minuten | 1 Minute | {count} Minuten", 60),
                    value: OngoingTimeInterval.SIXTY_MINUTES,
                },
            ];
        },

        partitionOptions(): SelectOption[] {
            return this.partitions
                .map((p) => ({ value: p.id, text: p.name }))
                .sort((a, b) => a.text.localeCompare(b.text, userSession.locale));
        },
    },

    methods: {
        async loadRows() {
            this.nowAtLoadRows = now();
            this.rows = [];
            this.loading = true;
            const searchId = ++this.searchId;

            await this.loadLimiter.execute(async () => {
                try {
                    const beginFrom = toDateObject(userSession.timeZone, this.loadFilter.comparisonDate);
                    const beginTo = toDateObject(userSession.timeZone, this.loadFilter.comparisonDate, 1);

                    const rows = await reportingApi.externalBdcCdrRows(
                        this.loadFilter.dealerIds,
                        this.loadFilter.partitionIds,
                        beginFrom,
                        beginTo
                    );

                    if (searchId === this.searchId) {
                        this.rows = Object.freeze(rows);
                    }
                } finally {
                    if (searchId === this.searchId) {
                        this.loading = false;
                    }
                }
            });
        },
    },

    watch: {
        loadFilter: {
            deep: true,
            async handler() {
                try {
                    await this.loadRows();
                } catch (e) {
                    this.$nextTick(() => {
                        throw e;
                    });
                }
            },
        },
    },

    async mounted() {
        this.dealers = await dealersApi.list();
        this.partitions = await partitionsApi.list();

        await this.loadRows();
    },

    components: {
        DatePicker,
        NumberField,
        ReportingLineChart,
        ReportingTable,
    },
});

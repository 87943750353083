import { ActionType, FeedbackType } from "./actions";
import { DisconnectStatus } from "./externalBdcCdrs";
import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export interface ActionRow {
    readonly id: number;
    readonly created: Date;
    readonly partitionId: string;
    readonly dealerId: string | null;
    readonly userId: string;
    readonly type: ActionType;
    readonly feedbackCreated: Date | null;
    readonly feedbackType: FeedbackType | null;
}

export interface ExternalBdcCdrRow {
    readonly callee: string;
    readonly begin: Date;
    readonly waited: number;
    readonly duration: number | null;
    readonly billedMinutes: number | null;
    readonly userId: string | null;
    readonly dealerId: string | null;
    readonly disconnectStatus: DisconnectStatus;
}

interface ReportingApi {
    actionRows(
        dealerIds: string[],
        partitionIds: string[],
        createdFrom: Date | null,
        createdTo: Date | null
    ): Promise<ActionRow[]>;
    externalBdcCdrRows(
        dealerIds: string[],
        partitionIds: string[],
        createdFrom: Date | null,
        createdTo: Date | null
    ): Promise<ExternalBdcCdrRow[]>;
}

function getParams(dealerIds: string[], partitionIds: string[], createdFrom: Date | null, createdTo: Date | null) {
    const params = new URLSearchParams(
        [dealerIds.map((i) => `dealerId=${i}`).join("&"), partitionIds.map((i) => `partitionId=${i}`).join("&")]
            .filter((p) => !!p)
            .join("&")
    );

    if (createdFrom) {
        params.append("createdFrom", createdFrom.toJSON());
    }

    if (createdTo) {
        params.append("createdTo", createdTo.toJSON());
    }

    return params;
}

export const reportingApi: ReportingApi = {
    async actionRows(dealerIds, partitionIds, createdFrom, createdTo) {
        const params = getParams(dealerIds, partitionIds, createdFrom, createdTo);

        return cloneObject((await axios.get("/api/reporting/actions", { params })).data);
    },

    async externalBdcCdrRows(dealerIds, partitionIds, createdFrom, createdTo) {
        const params = getParams(dealerIds, partitionIds, createdFrom, createdTo);

        return cloneObject((await axios.get("/api/reporting/external-bdc-cdrs", { params })).data);
    },
};


import BdcTeamFormDialog from "./BdcTeamFormDialog.vue";
import BdcTeamOverviewDataTable from "./BdcTeamOverviewDataTable.vue";
import { BdcTeam, bdcTeamsApi } from "@/api/bdcTeams";
import { User, usersApi } from "@/api/users";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            bdcTeamFormDialogVisible: false,
            bdcTeams: [] as BdcTeam[],
            loading: true,
            users: [] as User[],
        };
    },

    methods: {
        async loadItems() {
            this.loading = true;
            this.bdcTeams = [];
            this.users = [];
            try {
                this.users = await usersApi.list();
                this.bdcTeams = await bdcTeamsApi.getAll();
            } finally {
                this.loading = false;
            }
        },
    },

    async mounted() {
        await this.loadItems();
    },

    components: {
        BdcTeamFormDialog,
        BdcTeamOverviewDataTable,
    },
});

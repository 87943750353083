
import { ExternalBdcCdr } from "@/api/externalBdcCdrs";
import { User } from "@/api/users";
import ExternalBdcCdrsBottomSheet from "@/app/pages/externalbdccdr/ExternalBdcCdrsBottomSheet.vue";
import { getReportingTableByReportingScatterChart } from "@/app/pages/reporting/chartUtils";
import {
    ReportingScatterChartAxisMarker,
    ReportingScatterChartData,
} from "@/app/pages/reporting/charts/reportingScatterChart";
import ReportingDashboardScatterTile from "@/app/pages/reporting/dashboard/ReportingDashboardScatterTile.vue";
import {
    AnswerIncomingCallsShift,
    DutyRosterRow,
    toAnswerIncomingCallsShift,
    toDutyRosterRowBetween,
    WithDuration as WithDutyRosterDuration,
    withDuration as withDutyRosterDuration,
    WithElapsedDuration as WthDutyRosterElapsedDuration,
    withElapsedDuration as wthDutyRosterElapsedDuration,
} from "@/app/pages/reporting/dutyroster/dutyRosterRowUtils";
import {
    mapExternalBdcCdrRowKeyToRowLabel,
    WithBilledMinutes as WithExternalBdcCdrBilledMinutes,
} from "@/app/pages/reporting/externalbdc/externalBdcRowUtils";
import { addMissingRowGroups, groupRowsBy } from "@/app/pages/reporting/pivotUtils";
import { getMaxDate, getMinDate, renderHumanizedDuration, sum } from "@/app/pages/reporting/reportingUtils";
import { TitledRowGroup } from "@/app/pages/reporting/rowUtils";
import { ReportingTableData } from "@/app/pages/reporting/table/reportingTable";
import Vue from "vue";

type ComputedDutyRosterRow = PrecomputedDutyRosterRow;
type ComputedExternalBdcCdrRow = PrecomputedExternalBdcCdrRow;

type PrecomputedDutyRosterRow = DutyRosterRow & WithDutyRosterDuration & WthDutyRosterElapsedDuration;
type PrecomputedExternalBdcCdrRow = ExternalBdcCdr & WithExternalBdcCdrBilledMinutes;

export default Vue.extend({
    props: {
        answerIncomingCallsShift: {
            type: Array as () => readonly AnswerIncomingCallsShift<PrecomputedDutyRosterRow>[],
            required: true,
        },
        chartHeight: {
            type: Number,
            required: false,
        },
        externalBdcCdrRows: {
            type: Array as () => readonly PrecomputedExternalBdcCdrRow[],
            required: true,
        },
        from: {
            type: (Date as unknown) as () => Date,
            required: true,
        },
        loading: {
            type: Boolean,
            required: true,
        },
        now: {
            type: (Date as unknown) as () => Date,
            required: true,
        },
        subtitle: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            required: false,
        },
        to: {
            type: (Date as unknown) as () => Date,
            required: true,
        },
        users: {
            type: Array as () => readonly User[],
            required: true,
        },
    },

    data() {
        return {
            bottomSheetExternalBdcCdrIds: [] as number[],
            bottomSheetTitle: null as string | null,
            bottomSheetVisible: false,
        };
    },

    computed: {
        chart(): ReportingScatterChartData {
            const topCategories = this.externalBdcCdrRowGroups
                .map((rowGroup) => ({
                    ...rowGroup,
                    shift: this.answerIncomingCallsShift.find((s) => s.userId === rowGroup.key) ?? null,
                    subShift: this.subShifts.find((s) => s.userId === rowGroup.key) ?? null,
                }))
                .filter((rowGroup) => !!rowGroup.shift && !!rowGroup.subShift)
                .map((rowGroup) => ({
                    ...rowGroup,
                    rowsInSubShift: rowGroup.rows.filter((r) =>
                        rowGroup.subShift!.rows.some(
                            (s) => s.begin.getTime() <= r.begin.getTime() && r.begin.getTime() < s.end.getTime()
                        )
                    ),
                }))
                .map((rowGroup) => {
                    const subShiftElapsedHours = rowGroup.subShift!.elapsedDuration / 3600;
                    const billedMinutesInSubShift = sum(rowGroup.rowsInSubShift.map((row) => row.billedMinutes)) ?? 0;

                    return {
                        ...rowGroup,
                        categoryId: rowGroup.key,
                        name: rowGroup.title,
                        description:
                            rowGroup.shift!.duration === rowGroup.subShift!.duration
                                ? (this.$t(`Schicht: {0} von {1}`, [
                                      renderHumanizedDuration(rowGroup.shift!.elapsedDuration, "S"),
                                      renderHumanizedDuration(rowGroup.shift!.duration, "S"),
                                  ]) as string)
                                : `${this.$t(`Teilschicht: {0}`, [
                                      renderHumanizedDuration(rowGroup.subShift!.duration, "S"),
                                  ])} (${this.$t(`Schicht: {0} von {1}`, [
                                      renderHumanizedDuration(rowGroup.shift!.elapsedDuration, "S"),
                                      renderHumanizedDuration(rowGroup.shift!.duration, "S"),
                                  ])})`,
                        performanceCallsCount: subShiftElapsedHours
                            ? rowGroup.rowsInSubShift.length / subShiftElapsedHours
                            : 0,
                        performanceMinutesCount: subShiftElapsedHours
                            ? billedMinutesInSubShift / subShiftElapsedHours
                            : 0,
                        billedMinutesInSubShift,
                        sortValue: rowGroup.rows.length,
                    };
                })
                .sort(
                    (a, b) =>
                        b.performanceMinutesCount - a.performanceMinutesCount ||
                        b.performanceCallsCount - a.performanceCallsCount
                )
                .filter((c) => c.shift!.hasStarted || !!c.rowsInSubShift.length);

            return {
                title: "",
                xAxis: [{ id: "call-count", name: this.$t("Anrufe") as string }],
                yAxis: [{ id: "billed-minutes", name: this.$t("Minuten") as string }],
                categories: topCategories.map((c) => ({ name: c.name, description: c.description })),
                series: [
                    {
                        id: "performance",
                        name: this.$t("Leistung") as string,
                        data: {
                            values: topCategories.map((c) => ({
                                xValue: c.performanceCallsCount,
                                yValue: c.performanceMinutesCount,
                                color: c.shift?.hasEnded ? "gray" : c.shift?.isBetweenSubshifts ? "orange" : undefined,
                                onClick: this.showBottomSheetOnClick(
                                    c.rowsInSubShift.map((r) => r.id),
                                    c.name
                                ),
                            })),
                            xAxisId: "call-count",
                            yAxisId: "billed-minutes",
                            xAxisMarkers: ([
                                { value: 25 },
                                { value: 40, fillArea: "RIGHT", opacity: 0.02, color: "red" },
                            ] as ReportingScatterChartAxisMarker[]).filter((m) =>
                                topCategories.some((c) => !m.fillArea || m.value <= c.performanceCallsCount)
                            ),
                            yAxisMarkers: ([
                                { value: 55 },
                                { value: 80, fillArea: "ABOVE", opacity: 0.02, color: "red" },
                            ] as ReportingScatterChartAxisMarker[]).filter((m) =>
                                topCategories.some((c) => !m.fillArea || m.value <= c.performanceMinutesCount)
                            ),
                        },
                        additionalTooltipData: [
                            {
                                values: topCategories.map((c) => ({
                                    xValue: c.rowsInSubShift.length,
                                    yValue: c.billedMinutesInSubShift,
                                })),
                                xAxisId: "call-count",
                                yAxisId: "billed-minutes",
                            },
                        ],
                    },
                ],
                seriesAdditionalTooltipDataTooltipHeaders: [this.$t("Volumen") as string],
                seriesDataTooltipHeader: this.$t("Leistung") as string,
            };
        },

        externalBdcCdrRowGroups(): readonly TitledRowGroup<string | null, ComputedExternalBdcCdrRow>[] {
            const filteredExternalBdcCdrRows = this.externalBdcCdrRows
                .filter((r) => r.userId)
                .filter((r) => this.from.getTime() <= r.begin.getTime() && r.begin.getTime() < this.to.getTime());

            const rowGroups = groupRowsBy(filteredExternalBdcCdrRows, (r) => r.userId);

            const userIdsWithShift = this.subShifts
                .filter((shift) =>
                    shift.rows.some(
                        (r) => 0 < getMinDate(r.end, this.to)!.getTime() - getMaxDate(r.begin, this.from)!.getTime()
                    )
                )
                .map((shift) => shift.userId);

            return addMissingRowGroups(rowGroups, userIdsWithShift).map((rowGroup) => {
                const rowGroupLabel = mapExternalBdcCdrRowKeyToRowLabel(rowGroup.key, "userId", {
                    users: this.users,
                });

                return {
                    ...rowGroup,
                    title: rowGroupLabel.label,
                    subtitle: rowGroupLabel.sublabel,
                };
            });
        },

        subShifts(): readonly AnswerIncomingCallsShift<ComputedDutyRosterRow>[] {
            return this.answerIncomingCallsShift
                .map((shift) =>
                    toAnswerIncomingCallsShift(
                        shift.userId,
                        shift.rows
                            .map((r) => toDutyRosterRowBetween(r, this.from, this.to))
                            .filter((r): r is DutyRosterRow => !!r)
                            .map(withDutyRosterDuration)
                            .map((r) => wthDutyRosterElapsedDuration(r, this.now))
                    )
                )
                .filter((shift): shift is AnswerIncomingCallsShift<ComputedDutyRosterRow> => !!shift);
        },

        table(): ReportingTableData | null {
            if (!this.chart) {
                return null;
            }

            return {
                ...getReportingTableByReportingScatterChart(this.chart),
                groupByHeaderText: this.$t("Benutzer") as string,
            };
        },
    },

    methods: {
        hideBottomSheet() {
            this.bottomSheetVisible = false;
            this.bottomSheetTitle = null;
            this.bottomSheetExternalBdcCdrIds = [];
        },

        showBottomSheetOnClick(externalBdcCdrIds: number[], title: string): () => void {
            return () => {
                this.bottomSheetExternalBdcCdrIds = [...new Set(externalBdcCdrIds)];
                this.bottomSheetTitle = title;
                this.bottomSheetVisible = true;
            };
        },
    },

    components: {
        ExternalBdcCdrsBottomSheet,
        ReportingDashboardScatterTile,
    },
});

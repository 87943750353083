
import { Dealer } from "@/api/dealers";
import { DisconnectStatus, ExternalBdcCdr } from "@/api/externalBdcCdrs";
import { User } from "@/api/users";
import RecordingsDialog from "@/app/pages/recordings/RecordingsDialog.vue";
import { canViewRecordings } from "@/app/pages/recordings/recordingUtils";
import { getFullName } from "@/app/userUtils";
import { configStore } from "@/store/config";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { formatDifference, formatLocalDate, formatTime, getDate } from "@/util/dateTimeUtils";
import { parseAndFormatNumber } from "@/util/phoneNumberUtils";
import Vue from "vue";

const RECORDING_RETENTION_PERIOD_MSEC = 90 * 24 * 60 * 60 * 1000;

export default Vue.extend({
    props: {
        dealer: {
            type: Object as () => Dealer | null,
            default: null,
        },
        externalBdcCdr: {
            type: Object as () => ExternalBdcCdr,
            required: true,
        },
        user: {
            type: Object as () => User | null,
            default: null,
        },
    },

    data() {
        return {
            DisconnectStatus,
            getFullName,
            parseAndFormatNumber,
            recordingsDialogVisible: false,
        };
    },

    computed: {
        canShowRecordings() {
            return (
                !!this.externalBdcCdr.accept &&
                this.externalBdcCdr.begin.getTime() >= now().getTime() - RECORDING_RETENTION_PERIOD_MSEC
            );
        },

        canViewRecordings(): boolean {
            return canViewRecordings();
        },

        durationInSeconds(): number | null {
            if (!this.externalBdcCdr.accept) {
                return null;
            }

            return Math.floor(this.externalBdcCdr.end.getTime() - this.externalBdcCdr.accept.getTime()) / 1000;
        },

        rawWaitTimeInSeconds(): number {
            return Math.floor(
                ((this.externalBdcCdr.accept ? this.externalBdcCdr.accept : this.externalBdcCdr.end).getTime() -
                    this.externalBdcCdr.begin.getTime()) /
                    1000
            );
        },

        timeZone(): string {
            return configStore.configuration.organisationTimeZone;
        },

        waitTimeInSeconds(): number {
            return Math.max(0, this.rawWaitTimeInSeconds - this.externalBdcCdr.distributionBeginOffset);
        },
    },

    methods: {
        formatDateTime(date: Date): string {
            const formattedDate = formatLocalDate(getDate(date, userSession.timeZone), userSession.locale, "S");
            const formattedTime = this.formatTime(date);

            return `${formattedDate} ${formattedTime}`;
        },

        formatDifference(lengthInSeconds: number): string {
            return formatDifference(new Date(0), new Date(lengthInSeconds * 1000), true);
        },

        formatTime(date: Date): string {
            return formatTime(date, userSession.timeZone, userSession.locale);
        },
    },

    components: {
        RecordingsDialog,
    },
});

import { ActionSearchOrder, ActionSearchRequest } from "@/api/actions";

export const EMPTY_SEARCH_REQUEST: ActionSearchRequest = Object.freeze({
    types: [],
    bdcTeamIds: [],
    userIds: [],
    partitionIds: [],
    dealerIds: [],
    feedbackTypes: [],
    hasFeedback: null,
    createdFrom: new Date(),
    createdTo: new Date(),
    sortBy: ActionSearchOrder.CREATED_DESC,
});

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-dialog',{attrs:{"persistent":_vm.isWorking,"max-width":"600","value":true},on:{"input":_vm.close}},[_c('v-form',{ref:"form",attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.saveSettings.apply(null, arguments)}}},[_c('v-card',{attrs:{"disabled":_vm.isWorking}},[_c('v-toolbar',{attrs:{"color":_vm.$vuetify.theme.dark ? undefined : 'primary',"dark":"","flat":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t("Partitionseinstellungen bearbeiten"))+" ")])],1),(!_vm.partitionSettingsForm)?_c('v-card-text',[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}})],1):_vm._e(),(_vm.partitionSettingsForm)?_c('v-card-text',{staticClass:"pb-0"},[_c('v-row',{staticClass:"my-0"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"autocomplete":"no","dense":"","items":[
                                { value: true, text: _vm.$t('Ja') },
                                { value: false, text: _vm.$t('Nein') },
                            ],"label":_vm.$t('CitNOW-Conversations-API aktiv'),"outlined":"","rules":_vm.notEmpty},model:{value:(_vm.partitionSettingsForm.citnowConversationsApiActive),callback:function ($$v) {_vm.$set(_vm.partitionSettingsForm, "citnowConversationsApiActive", $$v)},expression:"partitionSettingsForm.citnowConversationsApiActive"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"autocomplete":"no","dense":"","items":[
                                { value: true, text: _vm.$t('Ja') },
                                { value: false, text: _vm.$t('Nein') },
                            ],"label":_vm.$t('Agenten dürfen auf die Partition zugreifen'),"outlined":"","rules":_vm.notEmpty},model:{value:(_vm.partitionSettingsForm.accessibleForExternalBdcAgents),callback:function ($$v) {_vm.$set(_vm.partitionSettingsForm, "accessibleForExternalBdcAgents", $$v)},expression:"partitionSettingsForm.accessibleForExternalBdcAgents"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"autocomplete":"no","dense":"","items":[
                                { value: true, text: _vm.$t('Ja') },
                                { value: false, text: _vm.$t('Nein') },
                            ],"label":_vm.$t('Erweitertes Externes-BDC-Reporting'),"outlined":"","rules":_vm.notEmpty},model:{value:(_vm.partitionSettingsForm.extendedExternalBdcReporting),callback:function ($$v) {_vm.$set(_vm.partitionSettingsForm, "extendedExternalBdcReporting", $$v)},expression:"partitionSettingsForm.extendedExternalBdcReporting"}})],1)],1)],1):_vm._e(),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$t("Abbrechen")))]),_c('v-btn',{attrs:{"text":"","type":"submit","loading":_vm.isWorking}},[_vm._v(_vm._s(_vm.$t("Speichern")))])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
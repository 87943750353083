
import { formatTooltipLabel } from "./eChartsUtils";
import { ReportingChartTooltipSeries, ReportingChartTooltipSeriesValue } from "./reportingChartTooltip";
import { trimAndReturnNullIfEmpty } from "@/util/stringUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        additionalValueHeaders: {
            type: Array as () => string[],
            default: () => [],
        },
        series: {
            type: Array as () => ReportingChartTooltipSeries[],
            required: true,
        },
        subtitle: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            required: false,
        },
        valueHeader: {
            type: String,
            required: false,
        },
    },

    computed: {
        groupedSeries(): ReportingChartTooltipSeries[][] {
            return this.series
                .map((series) => series.group)
                .filter((group, index, array) => array.indexOf(group) === index)
                .map((group) => this.series.filter((series) => series.group === group))
                .filter((seriesGroup) => seriesGroup.length);
        },
    },

    methods: {
        renderValue(value: ReportingChartTooltipSeriesValue): string | null {
            let rendered: string | null = null;

            if (value.formatter) {
                rendered = trimAndReturnNullIfEmpty(value.formatter(value.value, value.isPercentage));
            }

            return rendered ?? trimAndReturnNullIfEmpty(formatTooltipLabel(value.value ?? null, value.isPercentage));
        },
    },
});

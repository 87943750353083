import axios from "axios";

export interface Loadbalancer {
    readonly id: number;
    readonly created: Date;
    readonly gcloudId: string;
    readonly gcloudStaticExternalIpId: string;
    readonly host: string;
    readonly usageCount: number;
}

interface LoadbalancerFinalizationForm {
    readonly gcloudId: string;
    readonly gcloudStaticExternalIpId: string;
    readonly host: string;
}

interface LoadbalancersApi {
    add(): Promise<void>;
    list(): Promise<Loadbalancer[]>;
    finalize(id: string, loadbalancerFinalizationForm: LoadbalancerFinalizationForm): Promise<void>;
}

export const loadbalancersApi: LoadbalancersApi = {
    async add() {
        await axios.post("/api/loadbalancers");
    },

    async list() {
        const result: Loadbalancer[] = (await axios.get("/api/loadbalancers")).data;

        return result.map((v) => ({
            ...v,
            created: new Date(v.created),
        }));
    },

    async finalize(id: string, loadbalancerFinalizationForm: LoadbalancerFinalizationForm) {
        await axios.post(`/api/loadbalancers/${id}/_finalize`, loadbalancerFinalizationForm);
    },
};

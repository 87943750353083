import { AgentStatisticsData, DutyRosterEntryTypeStatisticsData } from "@/api/agentStatistics";

function getErrorRatio(issuesCreated: number, activitiesCreated: number, errorsReported: number) {
    if (issuesCreated + activitiesCreated <= 0) {
        return 0;
    }

    return errorsReported / (issuesCreated + activitiesCreated);
}

function getRatio(dividend: number, divisor: number) {
    if (divisor <= 0) {
        return 0;
    }

    return dividend / divisor;
}

export interface ExtendedAgentStatisticsData extends AgentStatisticsData {
    readonly billedMinutesPerformance: number;
    readonly callPerformance: number;
    readonly contactsCreatedToIssuesCreatedRatio: number;
    readonly errorRatio: number;
    readonly issuesCreatedToCallsAcceptedRatio: number;
    readonly issuesCreatedAfterCallToIssuesCreatedWithCallRatio: number;
    readonly issuesCreatedDuringCallToIssuesCreatedWithCallRatio: number;
    readonly notesCreatedToCallsAcceptedRatio: number;
}

export function toExtendedAgentStatisticsData(d: AgentStatisticsData): ExtendedAgentStatisticsData {
    return {
        ...d,
        billedMinutesPerformance:
            100 *
            getRatio(
                d.billedMinutes,
                (d.elapsedSeconds.answerIncomingOrdinaryCalls + d.elapsedSeconds.answerIncomingPriorityCalls) / 36
            ),
        callPerformance:
            100 *
            getRatio(
                d.callsAccepted,
                (d.elapsedSeconds.answerIncomingOrdinaryCalls + d.elapsedSeconds.answerIncomingPriorityCalls) / 36
            ),
        contactsCreatedToIssuesCreatedRatio: getRatio(d.contactsCreated, d.issuesCreated),
        errorRatio: getErrorRatio(d.issuesCreated, d.activitiesCreated, d.errorsReported),
        issuesCreatedToCallsAcceptedRatio: getRatio(d.issuesCreated, d.callsAccepted),
        issuesCreatedAfterCallToIssuesCreatedWithCallRatio: getRatio(
            d.issuesCreatedAfterCall,
            d.issuesCreatedDuringCall + d.issuesCreatedAfterCall
        ),
        issuesCreatedDuringCallToIssuesCreatedWithCallRatio: getRatio(
            d.issuesCreatedDuringCall,
            d.issuesCreatedDuringCall + d.issuesCreatedAfterCall
        ),
        notesCreatedToCallsAcceptedRatio: getRatio(d.activitiesCreated, d.callsAccepted),
    };
}

export function addDutyRosterEntryTypeStatisticsData(
    a: DutyRosterEntryTypeStatisticsData,
    b: DutyRosterEntryTypeStatisticsData
): DutyRosterEntryTypeStatisticsData {
    return {
        answerIncomingOrdinaryCalls: a.answerIncomingOrdinaryCalls + b.answerIncomingOrdinaryCalls,
        answerIncomingPriorityCalls: a.answerIncomingPriorityCalls + b.answerIncomingPriorityCalls,
        training: a.training + b.training,
        otherWork: a.otherWork + b.otherWork,
        holiday: a.holiday + b.holiday,
        overtimeCompensation: a.overtimeCompensation + b.overtimeCompensation,
        otherTimeCompensation: a.otherTimeCompensation + b.otherTimeCompensation,
        sickness: a.sickness + b.sickness,
        sickChild: a.sickChild + b.sickChild,
        otherUnpaidAbsence: a.otherUnpaidAbsence + b.otherUnpaidAbsence,
    };
}

export function subtractDutyRosterEntryTypeStatisticsData(
    a: DutyRosterEntryTypeStatisticsData,
    b: DutyRosterEntryTypeStatisticsData
): DutyRosterEntryTypeStatisticsData {
    return {
        answerIncomingOrdinaryCalls: a.answerIncomingOrdinaryCalls - b.answerIncomingOrdinaryCalls,
        answerIncomingPriorityCalls: a.answerIncomingPriorityCalls - b.answerIncomingPriorityCalls,
        training: a.training - b.training,
        otherWork: a.otherWork - b.otherWork,
        holiday: a.holiday - b.holiday,
        overtimeCompensation: a.overtimeCompensation - b.overtimeCompensation,
        otherTimeCompensation: a.otherTimeCompensation - b.otherTimeCompensation,
        sickness: a.sickness - b.sickness,
        sickChild: a.sickChild - b.sickChild,
        otherUnpaidAbsence: a.otherUnpaidAbsence - b.otherUnpaidAbsence,
    };
}

export function addAgentStatisticsData(a: AgentStatisticsData, b: AgentStatisticsData): AgentStatisticsData {
    return {
        callsAccepted: a.callsAccepted + b.callsAccepted,
        callsDuration: a.callsDuration + b.callsDuration,
        billedMinutes: a.billedMinutes + b.billedMinutes,
        issuesCreated: a.issuesCreated + b.issuesCreated,
        issuesCreatedDuringCall: a.issuesCreatedDuringCall + b.issuesCreatedDuringCall,
        issuesCreatedAfterCall: a.issuesCreatedAfterCall + b.issuesCreatedAfterCall,
        issuesCreatedNoCall: a.issuesCreatedNoCall + b.issuesCreatedNoCall,
        secondsIssuesCreatedAfterCall: a.secondsIssuesCreatedAfterCall + b.secondsIssuesCreatedAfterCall,
        secondsIssuesCreatedAfterCallMedian: null,
        activitiesCreated: a.activitiesCreated + b.activitiesCreated,
        contactsCreated: a.contactsCreated + b.contactsCreated,
        feedbackIssuesCreated: a.feedbackIssuesCreated + b.feedbackIssuesCreated,
        feedbackActivitiesCreated: a.feedbackActivitiesCreated + b.feedbackActivitiesCreated,
        errorsReported: a.errorsReported + b.errorsReported,
        scheduledDays: addDutyRosterEntryTypeStatisticsData(a.scheduledDays, b.scheduledDays),
        scheduledSeconds: addDutyRosterEntryTypeStatisticsData(a.scheduledSeconds, b.scheduledSeconds),
        elapsedSeconds: addDutyRosterEntryTypeStatisticsData(a.elapsedSeconds, b.elapsedSeconds),
    };
}

export function addExtendedAgentStatisticsData(
    a: ExtendedAgentStatisticsData,
    b: ExtendedAgentStatisticsData
): ExtendedAgentStatisticsData {
    return toExtendedAgentStatisticsData(addAgentStatisticsData(a, b));
}

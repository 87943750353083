import axios from "axios";

interface PartitionHouseKeepInventoryApi {
    getNonDeletedInventoryVehiclesWithoutSourceCount(host: string): Promise<number>;
    markInventoryVehiclesWithoutSourceAsDeleted(host: string): Promise<void>;
    removeDuplicatesOfAllInventoryVehicles(host: string): Promise<void>;
    removeDuplicatesOfInventoryVehiclesWithoutSource(host: string): Promise<void>;
}

export const partitionHouseKeepInventoryApi: PartitionHouseKeepInventoryApi = {
    async getNonDeletedInventoryVehiclesWithoutSourceCount(host: string) {
        return (
            await axios.get("/api/inventory-import/non-deleted-inventory-vehicles-without-source-count", {
                baseURL: `https://${host}`,
                withCredentials: true,
            })
        ).data;
    },

    async markInventoryVehiclesWithoutSourceAsDeleted(host: string) {
        await axios.post("/api/inventory-import/_mark-inventory-vehicles-without-source-as-deleted", undefined, {
            baseURL: `https://${host}`,
            withCredentials: true,
        });
    },

    async removeDuplicatesOfAllInventoryVehicles(host: string) {
        await axios.post("/api/inventory-vehicle-duplicates/_remove-duplicates-of-all-inventory-vehicles", undefined, {
            baseURL: `https://${host}`,
            withCredentials: true,
        });
    },

    async removeDuplicatesOfInventoryVehiclesWithoutSource(host: string) {
        await axios.post(
            "/api/inventory-vehicle-duplicates/_remove-duplicates-of-inventory-vehicles-without-source",
            undefined,
            {
                baseURL: `https://${host}`,
                withCredentials: true,
            }
        );
    },
};


import { marketingEmailsApi } from "@/api/marketingEmails";
import { showConfirm, showInfo } from "@/app/messageUtil";
import { notEmpty } from "@/app/validation";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            subjectRules: notEmpty().maxLength(2000),
            contentRules: notEmpty().maxLength(16_777_215),
            subject: "",
            content: "",
            isWorking: false,
            placeholders: ["SALUTATION", "UNSUBSCRIBE_LINK"],
        };
    },

    methods: {
        appendToContent(placeholder: string) {
            this.content += placeholder;
        },

        async submit() {
            if (!(this.$refs.form as any).validate()) {
                return;
            }

            if (
                !(await showConfirm(
                    this.$t("Rundschreiben abschicken") as string,
                    this.$t("Sind Sie sicher, dass Sie das Rundschreiben abschicken möchten?") as string
                ))
            ) {
                return;
            }

            this.isWorking = true;
            try {
                await marketingEmailsApi.sendEmail({
                    subject: this.subject,
                    htmlBody: this.content,
                });

                showInfo("Rundschreiben erfolgreich versandt.");
                await this.$router.push("/");
            } finally {
                this.isWorking = false;
            }
        },
    },

    components: {
        WysiwygEditor: () => import("@/app/components/WysiwygEditor.vue"),
    },
});

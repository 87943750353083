
import { InvoiceRevenueData } from "@/api/invoicerevenues";
import { renderCurrency } from "@/app/filters";
import { addInvoiceRevenueData, getOtherRevenue } from "@/app/pages/invoicerevenue/invoiceRevenueUtils";
import { configStore } from "@/store/config";
import Vue from "vue";

export default Vue.extend({
    props: {
        billingAccountCount: {
            type: Number,
            required: true,
        },
        dealerCount: {
            type: Number,
            required: true,
        },
        invoiceRevenueData: {
            type: Object as () => InvoiceRevenueData,
            required: true,
        },
        invoiceTotal: {
            type: Number,
            required: true,
        },
        legacyRevenueData: {
            type: Object as () => InvoiceRevenueData,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        partitionCount: {
            type: Number,
            required: true,
        },
        unpaidInvoiceCount: {
            type: Number,
            required: true,
        },
        unpaidInvoiceTotal: {
            type: Number,
            required: true,
        },
    },

    computed: {
        currency(): string {
            return configStore.configuration.organisationCurrency;
        },

        otherRevenue(): number {
            return getOtherRevenue(this.revenueData);
        },

        revenueData(): InvoiceRevenueData {
            return addInvoiceRevenueData(this.invoiceRevenueData, this.legacyRevenueData);
        },
    },

    methods: {
        formatCurrency(value: number): string {
            return renderCurrency(value, this.currency);
        },
    },
});

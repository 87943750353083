import { ExternalBdcCdr } from "./externalBdcCdrs";
import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export interface AgentAssessment {
    readonly userId: string;
    readonly assessments: number;
    readonly assessmentAvg: number;
}

export interface ExternalBdcCdrAssessment {
    readonly externalBdcCdrId: number;
    readonly assessed: Date;
    readonly assessorUserId: string | null;
    readonly assessment: number | null;
}

interface ExternalBdcCdrAssessmentsApi {
    editAssessment(externalBdcCdrId: number, assessment: number | null): Promise<void>;
    getAgentAssessmentsByEndRange(endFrom: Date | null, endTo: Date | null): Promise<AgentAssessment[]>;
    getRandomUnassessed(
        endFrom: Date,
        endTo: Date,
        userId: string | null,
        dealerId: string | null
    ): Promise<ExternalBdcCdr | null>;
}

export const externalBdcCdrAssessmentsApi: ExternalBdcCdrAssessmentsApi = {
    async editAssessment(externalBdcCdrId, assessment) {
        await axios.put(`/api/external-bdc-cdr-assessments/${externalBdcCdrId}`, null, { params: { assessment } });
    },

    async getAgentAssessmentsByEndRange(endFrom, endTo) {
        return (await axios.get("/api/external-bdc-cdr-assessments", { params: { endFrom, endTo } })).data;
    },

    async getRandomUnassessed(endFrom, endTo, userId, dealerId) {
        return cloneObject(
            (
                await axios.get("/api/external-bdc-cdr-assessments/_random-unassessed", {
                    params: { endFrom, endTo, userId, dealerId },
                })
            ).data
        );
    },
};

import { DutyRosterEntryType } from "@/api/dutyRosters";

export interface DutyRosterCalendarEvent {
    readonly start: number;
    readonly end: number;
    readonly break: number;
    readonly type: DutyRosterEntryType;
    readonly timed: true;
}
export function isUnpaidAbsenceType(type: DutyRosterEntryType) {
    return type === DutyRosterEntryType.SICK_CHILD || type === DutyRosterEntryType.OTHER_UNPAID_ABSENCE;
}

export function isWorkingTimeType(type: DutyRosterEntryType) {
    return (
        type === DutyRosterEntryType.ANSWER_INCOMING_ORDINARY_CALLS ||
        type === DutyRosterEntryType.ANSWER_INCOMING_PRIORITY_CALLS ||
        type === DutyRosterEntryType.TRAINING ||
        type === DutyRosterEntryType.OTHER_WORK
    );
}

export function getDutyRosterEventColor(type: DutyRosterEntryType, isDarkMode: boolean): string {
    if (type === DutyRosterEntryType.ANSWER_INCOMING_ORDINARY_CALLS) {
        return "#214a8b";
    } else if (type === DutyRosterEntryType.ANSWER_INCOMING_PRIORITY_CALLS) {
        return "#c20000";
    } else if (isWorkingTimeType(type)) {
        return isDarkMode ? "#5E35B1" : "#9575CD";
    } else if (
        type === DutyRosterEntryType.HOLIDAY ||
        type === DutyRosterEntryType.OVERTIME_COMPENSATION ||
        type === DutyRosterEntryType.OTHER_TIME_COMPENSATION
    ) {
        return isDarkMode ? "#1B5E20" : "#43A047";
    } else if (type === DutyRosterEntryType.OTHER_UNPAID_ABSENCE) {
        return isDarkMode ? "#ad6600" : "#fb8c00";
    } else {
        return isDarkMode ? "#424242" : "grey";
    }
}


import {
    ExtendedExternalBdcCdrRow,
    ExternalBdcCdrReportPageFilter,
    ExternalBdcCdrReportPageResult,
} from "./externalBdcReportPage";
import { withBeginOngoingTimeSlot, withPartitionId } from "./externalBdcRowUtils";
import { BdcTeam, bdcTeamsApi } from "@/api/bdcTeams";
import { Dealer, dealersApi } from "@/api/dealers";
import { partitionsApi, PartitionSummary } from "@/api/partitions";
import { ExternalBdcCdrRow, reportingApi } from "@/api/reporting";
import { User, usersApi } from "@/api/users";
import DateRangePicker from "@/app/components/DateRangePicker.vue";
import ReportPage from "@/app/pages/reporting/ReportPage.vue";
import { TimeRange } from "@/app/pages/reporting/reportPage";
import { OngoingTimeInterval, RecurringTimeInterval } from "@/app/pages/reporting/timeInterval";
import { getFullName } from "@/app/userUtils";
import { configStore } from "@/store/config";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { getDate, toDateObject } from "@/util/dateTimeUtils";
import { ActionLimiter } from "@/util/debounce";
import { SelectOption, SelectOptions } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    props: {
        evaluationType: {
            type: Number,
            required: false,
        },
        evaluationTypeOptions: {
            type: Array as () => SelectOption[],
            required: false,
            default: () => [],
        },
        includeBelowAcceptanceThreshold: {
            type: Boolean,
            default: false,
        },
        groupBy: {
            type: String,
            required: false,
        },
        groupByOptions: {
            type: Array as () => SelectOption[],
            required: false,
            default: () => [],
        },
        loading: {
            type: Boolean,
            default: false,
        },
        noData: {
            type: Boolean,
            default: false,
        },
        sortBy: {
            type: Object,
            required: false,
        },
        sortByOptions: {
            type: Array as () => SelectOptions,
            required: false,
            default: () => [],
        },
        timeInterval: {
            type: String as () => OngoingTimeInterval | RecurringTimeInterval,
            required: false,
        },
        timeIntervals: {
            type: Array as () => OngoingTimeInterval[] | RecurringTimeInterval[],
            required: false,
            default: () => [],
        },
        timeRange: {
            type: Object as () => TimeRange,
            required: false,
        },
        timeZone: {
            type: String,
            required: true,
        },
        withBeginOngoingTimeSlot: {
            type: String as () => OngoingTimeInterval | null,
            default: null,
        },
    },

    data() {
        const ts = now();

        return {
            bdcTeams: [] as readonly BdcTeam[],
            dealers: [] as readonly Dealer[],
            loadFilter: {
                dealerIds: [] as string[],
                partitionIds: [] as string[],
                userIds: [] as string[],
                createdRange: {
                    from: this.timeRange ? this.timeRange.from : getDate(ts, this.timeZone, -6),
                    to: this.timeRange ? this.timeRange.to : getDate(ts, this.timeZone),
                },
            },
            loadingRows: false,
            loadLimiter: new ActionLimiter(true),
            localFilter: {
                bdcTeamIds: [] as string[],
                userIds: [] as (string | null)[],
            },
            nowAtLoadRows: null as Date | null,
            partitions: [] as readonly PartitionSummary[],
            rows: [] as readonly ExternalBdcCdrRow[],
            searchId: 0,
            users: [] as readonly User[],
        };
    },

    computed: {
        bdcTeamOptions(): SelectOption[] {
            return this.bdcTeams.map((bdcTeam) => ({
                text: bdcTeam.name,
                value: bdcTeam.id,
            }));
        },

        computedRows(): readonly ExtendedExternalBdcCdrRow[] {
            return [
                this.rows.filter(
                    (r) =>
                        this.includeBelowAcceptanceThreshold ||
                        r.duration !== null ||
                        this.waitingTimeThresholdSecondsForConsideringUnansweredCalls <= r.waited
                ),
            ]
                .map((rows) =>
                    this.withBeginOngoingTimeSlot ? withBeginOngoingTimeSlot(rows, this.withBeginOngoingTimeSlot) : rows
                )
                .map((rows) => withPartitionId(rows, this.dealers))
                .pop()!;
        },

        currentDate(): string | null {
            return this.nowAtLoadRows ? getDate(this.nowAtLoadRows, this.timeZone) : null;
        },

        dealerOptions(): SelectOption[] {
            return this.dealers
                .map((d) => ({ value: d.id, text: d.name }))
                .sort((a, b) => a.text.localeCompare(b.text, userSession.locale));
        },

        filter(): ExternalBdcCdrReportPageFilter {
            return {
                ...{ ...this.loadFilter, createdRange: undefined },
                createdFrom: this.loadFilter.createdRange.from,
                createdTo: this.loadFilter.createdRange.to,
                ...this.localFilter,
            };
        },

        filteredRows(): readonly ExtendedExternalBdcCdrRow[] {
            return this.computedRows
                .filter(
                    (r) =>
                        !this.localFilter.bdcTeamIds.length ||
                        (!!r.userId && this.memberUserIdsOfSelectedBdcTeams.includes(r.userId))
                )
                .filter((r) => !this.localFilter.userIds.length || this.localFilter.userIds.includes(r.userId));
        },

        memberUserIdsOfSelectedBdcTeams(): string[] {
            return this.bdcTeams
                .filter((bdcTeam) => this.localFilter.bdcTeamIds.includes(bdcTeam.id))
                .map((bdcTeam) => bdcTeam.memberUserIds)
                .reduce((prev, cur) => prev.concat(cur), []);
        },

        partitionOptions(): SelectOption[] {
            return this.partitions
                .map((p) => ({ value: p.id, text: p.name }))
                .sort((a, b) => a.text.localeCompare(b.text, userSession.locale));
        },

        userOptions(): SelectOption[] {
            return [
                {
                    value: null,
                    text: this.$t("Unbekannter Benutzer"),
                },
                ...this.users
                    .map((u) => ({ value: u.id, text: getFullName(u) }))
                    .sort((a, b) => a.text.localeCompare(b.text, userSession.locale)),
            ];
        },

        waitingTimeThresholdSecondsForConsideringUnansweredCalls(): number {
            return configStore.configuration.waitingTimeThresholdSecondsForConsideringUnansweredCalls;
        },
    },

    methods: {
        async loadRows() {
            this.nowAtLoadRows = now();
            this.rows = [];
            const searchId = ++this.searchId;
            this.loadingRows = true;

            await this.loadLimiter.execute(async () => {
                try {
                    const createdFrom = toDateObject(this.timeZone, this.loadFilter.createdRange.from);
                    const createdTo = toDateObject(this.timeZone, this.loadFilter.createdRange.to, 1);

                    const rows = await reportingApi.externalBdcCdrRows(
                        this.loadFilter.dealerIds,
                        this.loadFilter.partitionIds,
                        createdFrom,
                        createdTo
                    );

                    if (searchId === this.searchId) {
                        this.rows = Object.freeze(rows);
                    }
                } finally {
                    if (searchId === this.searchId) {
                        this.loadingRows = false;
                    }
                }
            });
        },

        updateTimeRange(timeRange: TimeRange): void {
            this.loadFilter.createdRange = { from: timeRange.from, to: timeRange.to };
        },
    },

    watch: {
        filteredRows() {
            this.$emit("loaded", {
                filter: { ...this.filter },
                rows: Object.freeze(this.filteredRows),
                nowAtLoadRows: this.nowAtLoadRows,
                mapKeyToLabelContext: {
                    dealers: this.dealers,
                    partitions: this.partitions,
                    users: this.users,
                },
            } as ExternalBdcCdrReportPageResult);
        },

        loadFilter: {
            deep: true,
            async handler() {
                try {
                    await this.loadRows();
                } catch (e) {
                    this.$nextTick(() => {
                        throw e;
                    });
                }
            },
        },
    },

    async mounted() {
        this.bdcTeams = Object.freeze(await bdcTeamsApi.getAll());
        this.dealers = Object.freeze(await dealersApi.list());
        this.partitions = Object.freeze(await partitionsApi.list());
        this.users = Object.freeze(await usersApi.list());

        await this.loadRows();
    },

    components: {
        DateRangePicker,
        ReportPage,
    },
});

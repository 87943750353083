
import { partitionIndexApi } from "./partitionIndexApi";
import { partitionsApi } from "@/api/partitions";
import { showConfirm } from "@/app/messageUtil";
import { userSession } from "@/store/userSession";
import Vue from "vue";
import { TranslateResult } from "vue-i18n";

enum IndexType {
    CASE_SEARCH = "CASE_SEARCH",
    CONTACT_SEARCH = "CONTACT_SEARCH",
    EMERGENCY_INCIDENT_SEARCH = "EMERGENCY_INCIDENT_SEARCH",
    FINANCE_CONTRACT_SEARCH = "FINANCE_CONTRACT_SEARCH",
    INVENTORY_SEARCH = "INVENTORY_SEARCH",
    OPPORTUNITY_SEARCH = "OPPORTUNITY_SEARCH",
}

interface Index {
    readonly type: IndexType;
    readonly label: TranslateResult;
    readonly rebuild: (host: string) => Promise<void>;
    readonly size: (host: string) => Promise<IndexSize>;
}

interface IndexSize {
    readonly expectedSize: number;
    readonly actualSize: number | null;
}

interface IndexState {
    readonly type: IndexType;
    expectedSize: number | null;
    actualSize: number | null;
    sizeDelta: number | null;
    errorWhileRebuilding: boolean;
    querying: boolean;
    rebuilding: boolean;
    enqueued: { [operation: string]: boolean };
}

enum Operation {
    QUERY_SIZE = "QUERY_SIZE",
    REBUILD = "REBUILD",
}

interface Job {
    readonly partitionState: PartitionState;
    readonly indexType: IndexType;
    readonly operation: Operation;
    working: boolean;
    completed: boolean;
}

interface PartitionState {
    readonly host: string;
    readonly indexes: { [indexType: string]: IndexState };
}

export default Vue.extend({
    data() {
        return {
            aborting: false,
            loading: false,
            maxParallelOtherRequests: 5,
            maxParallelQuerySizeRequests: 20,
            newJobQueue: [] as Job[],
            Operation,
            partitionStates: [] as PartitionState[],
            processingQueue: [] as Job[],
            processingTimeout: null as number | null,
        };
    },

    computed: {
        indexes(): Index[] {
            return [
                {
                    type: IndexType.CASE_SEARCH,
                    label: this.$t("Fälle"),
                    rebuild: partitionIndexApi.rebuildCaseSearchIndex,
                    size: partitionIndexApi.caseSearchIndexSize,
                },
                {
                    type: IndexType.EMERGENCY_INCIDENT_SEARCH,
                    label: this.$t("Notdienstvorgänge"),
                    rebuild: partitionIndexApi.rebuildEmergencyIncidentSearchIndex,
                    size: partitionIndexApi.emergencyIncidentSearchIndexSize,
                },
                {
                    type: IndexType.FINANCE_CONTRACT_SEARCH,
                    label: this.$t("Bankdaten"),
                    rebuild: partitionIndexApi.rebuildFinanceContractSearchIndex,
                    size: partitionIndexApi.financeContractSearchIndexSize,
                },
                {
                    type: IndexType.OPPORTUNITY_SEARCH,
                    label: this.$t("Verkaufschancen"),
                    rebuild: partitionIndexApi.rebuildOpportunitySearchIndex,
                    size: partitionIndexApi.opportunitySearchIndexSize,
                },
                {
                    type: IndexType.CONTACT_SEARCH,
                    label: this.$t("Kontakte"),
                    rebuild: partitionIndexApi.rebuildContactSearchIndex,
                    size: partitionIndexApi.contactSearchIndexSize,
                },
                {
                    type: IndexType.INVENTORY_SEARCH,
                    label: this.$t("Fahrzeuge"),
                    rebuild: partitionIndexApi.rebuildInventorySearchIndex,
                    size: partitionIndexApi.inventorySearchIndexSize,
                },
            ];
        },
    },

    methods: {
        addJobToQueue(indexType: IndexType, partitionState: PartitionState, operation: Operation) {
            if (partitionState.indexes[indexType].enqueued[operation]) {
                return;
            }

            partitionState.indexes[indexType].enqueued[operation] = true;

            this.newJobQueue.push({
                partitionState,
                indexType,
                operation,
                working: false,
                completed: false,
            });
        },

        addQueryIndexSizesToQueue(indexType: IndexType) {
            for (const partitionState of this.partitionStates) {
                this.addJobToQueue(indexType, partitionState, Operation.QUERY_SIZE);
            }
        },

        async addRebuildIndexJobByPartitionToQueue(indexType: IndexType, partitionState: PartitionState) {
            if (
                !(await showConfirm(
                    this.$t("Index neu bauen") as string,
                    this.$t("Sind Sie sicher, dass Sie den Index neu bauen möchten?") as string
                ))
            ) {
                return;
            }

            this.addJobToQueue(indexType, partitionState, Operation.REBUILD);
        },

        async addRebuildIndexJobToQueue(indexType: IndexType, onlyWithError: boolean) {
            if (
                !(await showConfirm(
                    this.$t(
                        onlyWithError
                            ? "Index in fehlgeschlagenen Partitionen neu bauen"
                            : "Index in allen Partitionen neu bauen"
                    ) as string,
                    this.$t("Sind Sie sicher, dass Sie den Index neu bauen möchten?") as string
                ))
            ) {
                return;
            }

            for (const partitionState of this.partitionStates) {
                const indexState = partitionState.indexes[indexType];

                if (indexState.rebuilding || (onlyWithError && !indexState.errorWhileRebuilding)) {
                    continue;
                }

                this.addJobToQueue(indexType, partitionState, Operation.REBUILD);
            }
        },

        continueToProcess() {
            if (this.processingTimeout !== null) {
                clearTimeout(this.processingTimeout);
            }

            // add new jobs to processing queue
            const newJobQueue = this.newJobQueue;
            this.newJobQueue = [];
            this.processingQueue.push(...newJobQueue);

            // remove completed jobs from processing queue
            const uncompleted: Job[] = [];

            for (const job of this.processingQueue) {
                if (job.completed || (this.aborting && !job.working)) {
                    job.partitionState.indexes[job.indexType].enqueued[job.operation] = false;
                } else {
                    uncompleted.push(job);
                }
            }

            this.processingQueue = uncompleted;

            // determine next jobs
            const otherRequestJobs = this.processingQueue.filter((j) => j.operation !== Operation.QUERY_SIZE);
            const querySizeRequestJobs = this.processingQueue.filter((j) => j.operation === Operation.QUERY_SIZE);

            const otherRequestRunningCount = otherRequestJobs.filter((j) => j.working).length;
            const querySizeRequestRunningCount = querySizeRequestJobs.filter((j) => j.working).length;

            const nextJobs = [
                ...otherRequestJobs
                    .filter((j) => !j.working)
                    .slice(0, Math.max(0, this.maxParallelOtherRequests - otherRequestRunningCount)),
                ...querySizeRequestJobs
                    .filter((j) => !j.working)
                    .slice(0, Math.max(0, this.maxParallelQuerySizeRequests - querySizeRequestRunningCount)),
            ];

            // start processing of next job (non-blocking)
            for (const job of nextJobs) {
                this.processJob(job);
            }

            // continue
            this.aborting = false;
            this.processingTimeout = setTimeout(this.continueToProcess, 50);
        },

        getIndexStates(partitionState: PartitionState, indexes: Index[]): IndexState[] {
            return indexes.map((index) => partitionState.indexes[index.type]);
        },

        hasRebuildErrorsByIndexType(indexType: IndexType) {
            return this.partitionStates.some((p) => p.indexes[indexType]?.errorWhileRebuilding);
        },

        async loadPartitionIndexesStates() {
            this.partitionStates = [];
            this.newJobQueue = [];
            this.processingQueue = [];

            this.loading = true;
            try {
                const partitionStates = (await partitionsApi.list())
                    .filter((p) => p.active)
                    .map((p) => ({
                        host: p.host,
                        indexes: this.indexes.reduce((map, index) => {
                            map[index.type] = {
                                type: index.type,
                                expectedSize: null,
                                actualSize: null,
                                sizeDelta: null,
                                errorWhileRebuilding: false,
                                querying: false,
                                rebuilding: false,
                                enqueued: Object.keys(Operation).reduce((opMap, operation) => {
                                    opMap[operation] = false;

                                    return opMap;
                                }, {} as IndexState["enqueued"]),
                            };

                            return map;
                        }, {} as PartitionState["indexes"]),
                    }));

                this.partitionStates = partitionStates.sort((a, b) => a.host.localeCompare(b.host, userSession.locale));
            } finally {
                this.loading = false;
            }
        },

        async queryIndexSizeByPartition(indexType: IndexType, partitionState: PartitionState) {
            const index = this.indexes.find((index) => index.type === indexType);
            const indexState = partitionState.indexes[indexType];

            if (!index || indexState.querying) {
                return;
            }

            indexState.expectedSize = null;
            indexState.actualSize = null;
            indexState.sizeDelta = null;
            indexState.querying = true;
            try {
                const size = await index.size(partitionState.host);

                indexState.expectedSize = size.expectedSize;
                indexState.actualSize = size.actualSize;
                indexState.sizeDelta = size.actualSize !== null ? size.actualSize - size.expectedSize : null;
            } finally {
                indexState.querying = false;
            }
        },

        async processJob(job: Job) {
            job.working = true;
            job.completed = false;

            try {
                if (job.operation === Operation.QUERY_SIZE) {
                    await this.queryIndexSizeByPartition(job.indexType, job.partitionState);
                } else if (job.operation === Operation.REBUILD) {
                    await this.rebuildIndexByPartition(job.indexType, job.partitionState);
                }
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            } finally {
                job.working = false;
                job.completed = true;
            }
        },

        async rebuildIndexByPartition(indexType: IndexType, partitionState: PartitionState) {
            const index = this.indexes.find((index) => index.type === indexType);
            const indexState = partitionState.indexes[indexType];

            if (!index || indexState.rebuilding) {
                return;
            }

            indexState.expectedSize = null;
            indexState.actualSize = null;
            indexState.sizeDelta = null;
            indexState.errorWhileRebuilding = false;
            indexState.rebuilding = true;
            try {
                await index.rebuild(partitionState.host);

                this.addJobToQueue(indexType, partitionState, Operation.QUERY_SIZE);
            } catch (e) {
                indexState.errorWhileRebuilding = false;
                throw e;
            } finally {
                indexState.rebuilding = false;
            }
        },
    },

    async mounted() {
        await this.loadPartitionIndexesStates();
        await this.continueToProcess();
    },

    destroyed() {
        if (this.processingTimeout) {
            clearTimeout(this.processingTimeout);
        }
    },
});

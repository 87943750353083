
import ReportingTableRow from "./ReportingTableRow.vue";
import { getCsvData, ReportingTableHeaderGroup, ReportingTableItem } from "./reportingTable";
import CsvDownloadIcon from "@/app/components/CsvDownloadIcon.vue";
import Vue from "vue";

export default Vue.extend({
    props: {
        csvDownload: {
            type: Boolean,
            default: false,
        },
        csvFilename: {
            type: String,
            required: false,
        },
        groupByHeaderText: {
            type: String,
            default: "",
        },
        headerGroups: {
            type: Array as () => ReportingTableHeaderGroup[],
            required: true,
        },
        items: {
            type: Array as () => ReportingTableItem[],
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        small: {
            type: Boolean,
            default: false,
        },
        totals: {
            type: Object as () => ReportingTableItem,
            required: false,
        },
    },

    computed: {
        csvData(): string[][] {
            if (!this.csvDownload) {
                return [];
            }

            return getCsvData(this.headerGroups, this.items, this.totals, this.groupByHeaderText);
        },

        hasHeaderGroupWithText(): boolean {
            return this.headerGroups.some((headerGroup) => headerGroup.text);
        },

        headersCount(): number {
            return this.headerGroups.reduce((count, headerGroup) => count + headerGroup.headers.length, 0);
        },

        totalsProp(): ReportingTableItem | undefined {
            if (!this.totals) {
                return;
            }

            return {
                ...this.totals,
                title: this.totals.title || (this.$t("Gesamt") as string),
            };
        },
    },

    components: {
        CsvDownloadIcon,
        ReportingTableRow,
    },
});


import RegionField from "./RegionField.vue";
import { UserProfileForm } from "@/api/userSession";
import { Gender, usersApi } from "@/api/users";
import BirthdayPicker from "@/app/components/BirthdayPicker.vue";
import CountryPicker from "@/app/components/CountryPicker.vue";
import EnumField from "@/app/components/EnumField.vue";
import PhoneNumberField from "@/app/components/PhoneNumberField.vue";
import StatePicker from "@/app/components/StatePicker.vue";
import TimeZonePicker from "@/app/components/TimeZonePicker.vue";
import { email, maxLength, notEmpty } from "@/app/validation";
import { configStore } from "@/store/config";
import { userSession } from "@/store/userSession";
import { cloneObject } from "@/util/cloneUtils";
import Vue from "vue";
import VImageInput from "vuetify-image-input";

const JPEG_DATA_URI_PREFIX = "data:image/jpeg;base64,";

export default Vue.extend({
    data() {
        return {
            isWorking: true,
            valid: true,
            gender: userSession.profile?.gender || null,
            namePrefix: userSession.profile?.namePrefix || null,
            givenName: userSession.profile?.givenName || "",
            familyName: userSession.profile?.familyName || "",
            birthday: userSession.birthday || "",
            locale: userSession.locale || "",
            timeZone: userSession.timeZone || "",
            darkmode: userSession.darkmode,
            emailAddress: userSession.profile?.emailAddress || null,
            phoneNumbers: userSession.profile ? cloneObject(userSession.profile.phoneNumbers) : [],
            address1: userSession.profile?.address1 || null,
            address2: userSession.profile?.address2 || null,
            zip: userSession.profile?.zip || null,
            city: userSession.profile?.city || null,
            state: userSession.profile?.state || null,
            country: userSession.profile?.country || null,
            profileImage: null as string | null,
            namePartRules: notEmpty().maxLength(300),
            Gender,
            maxLength,
            notEmpty,
            email,
        };
    },

    computed: {
        defaultCountry(): string {
            return configStore.configuration.organisationCountry;
        },

        profileImageUri: {
            get() {
                return JPEG_DATA_URI_PREFIX + (this as any).profileImage;
            },
            set(v: string | null) {
                this.profileImage = v ? (this.profileImage = v.substr(JPEG_DATA_URI_PREFIX.length)) : null;
            },
        },

        userTimeZone(): string {
            return userSession.timeZone;
        },
    },

    methods: {
        addPhoneNumber() {
            this.phoneNumbers.push({
                number: "",
                label: "",
            });
        },

        removePhoneNumber(index: number) {
            this.phoneNumbers.splice(index, 1);
        },

        async saveUser() {
            if (!this.valid) {
                (this.$refs.form as any).validate();
                return;
            }

            this.isWorking = true;

            const userProfileForm: UserProfileForm = {
                timeZone: this.timeZone,
                locale: this.locale,
                gender: this.gender,
                namePrefix: this.namePrefix,
                givenName: this.givenName,
                familyName: this.familyName,
                birthday: this.birthday || null,
                darkmode: this.darkmode,
                profileImage: this.profileImage,
                emailAddress: this.emailAddress,
                phoneNumbers: this.phoneNumbers,
                address1: this.address1,
                address2: this.address2,
                zip: this.zip,
                city: this.city,
                state: this.state,
                country: this.country,
            };

            try {
                await userSession.editUserProfile(userProfileForm);
            } finally {
                this.isWorking = false;
            }
        },
    },

    async mounted() {
        this.profileImage = userSession.profileImageHash
            ? await usersApi.getProfileImageByHash(userSession.id!, userSession.profileImageHash)
            : null;

        this.isWorking = false;
    },

    components: {
        BirthdayPicker,
        CountryPicker,
        EnumField,
        PhoneNumberField,
        RegionField,
        StatePicker,
        TimeZonePicker,
        VImageInput,
    },
});

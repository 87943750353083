import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export interface Recording {
    readonly id: number;
    readonly begin: Date;
    readonly caller: string | null;
    readonly dataHash: string;
    readonly contentType: string;
    readonly contentLength: number;
    readonly lengthSeconds: number;
}

interface RecordingsApi {
    getByBeginRangeAndCaller(beginFrom: Date, beginTo: Date, caller: string | null): Promise<Recording[]>;
    generateRecordingLink(id: number): string;
}

export const recordingsApi: RecordingsApi = {
    async getByBeginRangeAndCaller(beginFrom, beginTo, caller) {
        return cloneObject(
            (
                await axios.get(`/api/recordings`, {
                    params: { beginFrom, beginTo, caller },
                })
            ).data
        );
    },

    generateRecordingLink(id) {
        return `/api/recordings/${id}`;
    },
};

import axios from "axios";

export interface MarketingEmail {
    readonly subject: string;
    readonly htmlBody: string;
}

interface MarketingEmailsApi {
    sendEmail(email: MarketingEmail): Promise<void>;
}

export const marketingEmailsApi: MarketingEmailsApi = {
    async sendEmail(email) {
        await axios.post("/api/marketing-emails", email);
    },
};


import {
    guessCountryCode,
    isRealNumber,
    parseAndFormatNumber,
    PhoneNumberCountryCode,
    PhoneNumberFormat,
} from "@/util/phoneNumberUtils";
import Vue from "vue";
import { TranslateResult } from "vue-i18n";

function formatNumber(v: string, format: PhoneNumberFormat, cc: PhoneNumberCountryCode) {
    if (!v) {
        return "";
    }
    v = v.trim();
    return parseAndFormatNumber(v, format, cc) || v;
}

export default Vue.extend({
    props: {
        value: String,
        normalizationFormat: {
            type: String as () => PhoneNumberFormat,
            default: "INTERNATIONAL" as PhoneNumberFormat,
        },
        defaultCountryCode: {
            type: String as () => PhoneNumberCountryCode,
            required: true,
        },
        rules: Array as () => Function[],
        numbers: Array as () => string[],
        hint: {
            type: String,
            default: "",
        },
        persistentHint: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            formattedValue: "",
            internalValue: "",
        };
    },

    computed: {
        items(): string[] {
            if (!this.numbers || !this.numbers.length) {
                return [];
            }

            return this.numbers.map((n) => this.toInputString(n));
        },

        rulez(): Function[] {
            const rs = this.rules || [];
            return rs.map((r) => (v: string) => r(this.fromInputString(v)));
        },

        cc(): PhoneNumberCountryCode {
            return guessCountryCode(this.internalValue, this.defaultCountryCode);
        },

        numberHint(): TranslateResult | null {
            return !this.internalValue || isRealNumber(this.internalValue)
                ? null
                : this.$t("Die Nummer ist möglicherweise nicht gültig.");
        },
    },

    methods: {
        normaliseFilterValue(value: string | null): string {
            return (value || "").replace(/[^\d]/g, "").replace(/^0*/, "");
        },

        filter(item: string, v: string | null) {
            return this.normaliseFilterValue(item).indexOf(this.normaliseFilterValue(v)) >= 0;
        },

        fromInputString(v: string) {
            return formatNumber(v, "E.164", this.cc);
        },

        toInputString(v: string) {
            return formatNumber(v, this.normalizationFormat, this.cc);
        },

        input(v: string) {
            (this.$refs.comboboxField as any).validate(true, v);
            this.internalValue = this.fromInputString(v);
            this.$emit("input", this.internalValue);
        },

        change(v: string) {
            this.internalValue = this.fromInputString(v);
            const field = this.$refs.comboboxField as any;
            this.formattedValue = field.lazyValue = field.lazySearch = this.toInputString(v);
            this.$emit("change", this.internalValue);
        },
    },

    watch: {
        value() {
            const internalValue = this.fromInputString(this.value);
            if (this.internalValue !== internalValue) {
                this.internalValue = internalValue;
                this.formattedValue = this.toInputString(this.value);
            }
        },
    },

    created() {
        this.internalValue = this.fromInputString(this.value);
        this.formattedValue = this.toInputString(this.value);
    },
});


import { getDutyRosterEventColor, isWorkingTimeType } from "./dutyRosterUtils";
import { dutyRostersApi } from "@/api/dutyRosters";
import { userTrackingApi } from "@/api/userTracking";
import { usersApi } from "@/api/users";
import { userSession } from "@/store/userSession";
import { CalendarOptions, DatesSetArg, EventContentArg, EventInput } from "@fullcalendar/core";
import FullCalendar from "@fullcalendar/vue";
// eslint-disable-next-line import/order
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import moment from "moment-timezone";
import Vue from "vue";

interface Agent {
    readonly id: string;
    readonly title?: string;
}

function toDate(ts: Date) {
    return moment(ts)
        .format()
        .substring(0, 10);
}

export default Vue.extend({
    data() {
        return {
            agents: [] as Agent[],
            events: [] as EventInput[],
            lastStart: "",
            lastEnd: "",
        };
    },

    computed: {
        calendarOptions(): CalendarOptions {
            return {
                schedulerLicenseKey: "0297760784-fcs-1658939163",
                plugins: [resourceTimelinePlugin],
                height: "auto",
                resourceAreaWidth: 280,
                initialView: "resourceTimelineDay",
                headerToolbar: {
                    left: "",
                    center: "title",
                    right: "",
                },
                resourceAreaHeaderContent: this.$t("Agenten"),
                resourceOrder: "index",
                locale: userSession.locale,
                initialDate: (this.$route.query.d as string) || "",
                resources: this.agents,
                events: this.events,
                datesSet: (arg) => this.loadEvents(arg),
                eventContent: (arg) => this.renderEvent(arg),
            };
        },
    },

    methods: {
        async loadEvents(arg: DatesSetArg) {
            if (!this.agents.length) {
                return;
            }

            const start = toDate(arg.start);
            const end = toDate(arg.end);
            if (this.lastStart === start && this.lastEnd === end) {
                return;
            }
            this.lastStart = start;
            this.lastEnd = end;

            const agentIds = new Set(this.agents.map((a) => a.id));
            const entries = (await dutyRostersApi.getEntries(start, end)).filter((e) => agentIds.has(e.userId));
            const onlinePeriods = (await userTrackingApi.getUserOnlinePeriods(arg.start, arg.end)).filter((e) =>
                agentIds.has(e.userId)
            );

            this.events = [
                ...onlinePeriods.map((e) => ({
                    resourceId: e.userId,
                    start: e.begin!,
                    end: e.end || new Date(),
                    display: "background",
                    color: "rgb(0,255,0)",
                })),

                ...entries.map((e) => ({
                    resourceId: e.userId,
                    start: Date.parse(`${e.entry.day}T${e.entry.beginTime}`),
                    end: Date.parse(`${e.entry.day}T${e.entry.endTime}`) + 60 * 1000,
                    extendedProps: {
                        type: e.entry.type,
                        break: e.entry.includedBreakMinutes,
                    },
                    color: getDutyRosterEventColor(e.entry.type, this.$vuetify.theme.dark),
                })),
            ];

            await this.$nextTick();
            window.print();
            window.close();
        },

        renderEvent({ event }: EventContentArg) {
            // online time
            if (event.extendedProps.break === undefined) {
                return;
            }

            // duty roster entry
            const entryLabel = document.createTextNode(
                isWorkingTimeType(event.extendedProps.type)
                    ? this.$tc("ohne Pause | 1 min Pause | {count} min Pause", event.extendedProps.break)
                    : (this.$t(`enum.DutyRosterEntryType.${event.extendedProps.type}`) as string)
            );
            return { domNodes: [entryLabel] };
        },
    },

    created() {
        this.$vuetify.theme.dark = false;
    },

    async mounted() {
        this.agents = (await usersApi.list())
            .filter((u) => u.weeklyWorkingHours !== null)
            .map((u) => ({
                id: u.id,
                title: `${u.givenName} ${u.familyName} (${u.username})`,
            }))
            .sort((a, b) => a.title.localeCompare(b.title, userSession.locale));
    },

    components: {
        FullCalendar,
    },
});

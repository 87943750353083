
import { getReportingTableByReportingBarChart } from "./chartUtils";
import ReportingBarChart from "./charts/ReportingBarChart.vue";
import { ReportingBarChartData } from "./charts/reportingBarChart";
import { ReportingChartComponent } from "./charts/reportingChart";
import { downloadChartAsPng, downloadChartAsSvg, getFileBasename } from "./downloadUtils";
import ReportingTable from "./table/ReportingTable.vue";
import { getCsvData, ReportingTableData } from "./table/reportingTable";
import CsvDownloadIcon from "@/app/components/CsvDownloadIcon.vue";
import Vue from "vue";

export default Vue.extend({
    props: {
        chart: {
            type: Object as () => ReportingBarChartData,
            required: true,
        },
        chartHeight: {
            type: Number,
            required: false,
        },
        chartHideLegend: {
            type: Boolean,
            default: false,
        },
        chartHideAbsoluteValueAxis: {
            type: Boolean,
            default: false,
        },
        chartHidePercentageValueAxis: {
            type: Boolean,
            default: false,
        },
        chartHideValueAxis: {
            type: Boolean,
            default: false,
        },
        chartOverlap: {
            type: Boolean,
            default: false,
        },
        chartPercentageMax: {
            type: Number,
            required: false,
        },
        chartStacked: {
            type: Boolean,
            default: false,
        },
        filenamePrefix: {
            type: String,
            required: false,
        },
        groupByHeaderText: {
            type: String as () => string | null,
            required: false,
            default: null,
        },
        table: {
            type: Object as () => ReportingTableData | null,
            required: false,
            default: null,
        },
    },

    data() {
        return {
            tableVisible: false,
        };
    },

    computed: {
        chartTable(): ReportingTableData | null {
            if (this.table) {
                return this.table;
            }

            const table = getReportingTableByReportingBarChart(this.chart);

            if (!table.headerGroups.length && !table.items.length) {
                return null;
            }

            return { ...table, groupByHeaderText: this.groupByHeaderText ?? undefined };
        },

        csvData(): string[][] {
            if (!this.chartTable) {
                return [];
            }

            return getCsvData(
                this.chartTable.headerGroups,
                this.chartTable.items,
                this.chartTable.totals,
                this.chartTable.groupByHeaderText
            );
        },

        csvFilename(): string | undefined {
            return this.fileBasename ? `${this.fileBasename}.csv` : undefined;
        },

        fileBasename(): string | null {
            return getFileBasename(this.filenamePrefix, this.chart.title, this.chart.subtitle);
        },
    },

    methods: {
        downloadAsPng() {
            downloadChartAsPng((this.$refs.chart as unknown) as ReportingChartComponent, this.fileBasename ?? null);
        },

        downloadAsSvg() {
            downloadChartAsSvg((this.$refs.chart as unknown) as ReportingChartComponent, this.fileBasename ?? null);
        },
    },

    components: {
        CsvDownloadIcon,
        ReportingBarChart,
        ReportingTable,
    },
});


import { getOtherRevenue } from "./invoiceRevenueUtils";
import { BillingAccount, billingAccountsApi } from "@/api/billingAccounts";
import { Dealer, dealersApi } from "@/api/dealers";
import { InvoiceDealerRevenue, InvoiceRevenueData, invoiceRevenuesApi } from "@/api/invoicerevenues";
import { Invoice, invoicesApi } from "@/api/invoices";
import { partitionsApi, PartitionSummary } from "@/api/partitions";
import CsvDownloadIcon from "@/app/components/CsvDownloadIcon.vue";
import { renderCurrency } from "@/app/filters";
import { getFileBasename } from "@/app/pages/reporting/downloadUtils";
import { configStore } from "@/store/config";
import { userSession } from "@/store/userSession";
import { formatLocalDate } from "@/util/dateTimeUtils";
import Vue from "vue";

interface Item {
    readonly invoice: Invoice;
    readonly invoiceDealerRevenues: InvoiceDealerRevenue[];
    readonly billingAccount: BillingAccount | null;
}

export default Vue.extend({
    props: {
        fromInvoiceId: {
            type: Number,
            required: true,
        },
        title: {
            type: String as () => string | null,
            default: null,
        },
        toInvoiceId: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            billingAccounts: [] as BillingAccount[],
            dealers: [] as Dealer[],
            getOtherRevenue,
            items: [] as Item[],
            loading: true,
            partitions: [] as PartitionSummary[],
            searchId: 0,
        };
    },

    computed: {
        currency(): string {
            return configStore.configuration.organisationCurrency;
        },

        invoicesCsvData(): string[][] {
            const getRevenueSumFn = (
                invoiceDealerRevenue: InvoiceDealerRevenue[],
                getRevenueFn: (d: InvoiceRevenueData) => number
            ): number => invoiceDealerRevenue.reduce((sum, r) => sum + getRevenueFn(r.invoiceRevenueData), 0);

            return [
                [
                    this.$t("Erstellt am") as string,
                    this.$t("Rechnungsnummer") as string,
                    this.$t("Bezahlt") as string,
                    this.$t("Storniert") as string,
                    this.$t("Abrechnungskonto") as string,
                    this.$t("Nettobetrag") as string,
                    this.$t("Software") as string,
                    this.$t("BDC (wiederkehrend)") as string,
                    this.$t("BDC (variabel)") as string,
                    this.$t("Click-to-Call") as string,
                    this.$t("Call-Tracking") as string,
                    this.$t("SMS") as string,
                    this.$t("WhatsApp") as string,
                    this.$t("Einrichtung") as string,
                    this.$t("Sonstiges") as string,
                ],
                ...this.items.map((i) => [
                    this.formatLocalDate(i.invoice.created),
                    i.invoice.id.toString(),
                    (i.invoice.paid ? this.$t("ja") : this.$t("nein")) as string,
                    (i.invoice.reverseInvoiceId !== null ? this.$t("ja") : this.$t("nein")) as string,
                    i.billingAccount?.companyName || (this.$t("Unbekanntes Abrechnungskonto") as string),
                    this.formatPriceForCsv(i.invoice.netAmount),
                    this.formatPriceForCsv(getRevenueSumFn(i.invoiceDealerRevenues, (d) => d.softwareRevenue)),
                    this.formatPriceForCsv(getRevenueSumFn(i.invoiceDealerRevenues, (d) => d.fixedBdcRevenue)),
                    this.formatPriceForCsv(getRevenueSumFn(i.invoiceDealerRevenues, (d) => d.variableBdcRevenue)),
                    this.formatPriceForCsv(getRevenueSumFn(i.invoiceDealerRevenues, (d) => d.clickToCallRevenue)),
                    this.formatPriceForCsv(getRevenueSumFn(i.invoiceDealerRevenues, (d) => d.callTrackingRevenue)),
                    this.formatPriceForCsv(getRevenueSumFn(i.invoiceDealerRevenues, (d) => d.outgoingSmsRevenue)),
                    this.formatPriceForCsv(getRevenueSumFn(i.invoiceDealerRevenues, (d) => d.whatsAppNumberRevenue)),
                    this.formatPriceForCsv(getRevenueSumFn(i.invoiceDealerRevenues, (d) => d.setupRevenue)),
                    this.formatPriceForCsv(getRevenueSumFn(i.invoiceDealerRevenues, (d) => getOtherRevenue(d))),
                ]),
            ];
        },

        invoicesCsvFilename(): string | null {
            if (!this.items.length) {
                return null;
            }

            const prefix = getFileBasename(
                this.title,
                this.$t("Rechnungen {0} bis {1}", [this.fromInvoiceId, this.toInvoiceId]) as string
            );

            return `${prefix}.csv`;
        },

        revenueCsvData(): string[][] {
            return [
                [
                    this.$t("Erstellt am") as string,
                    this.$t("Rechnungsnummer") as string,
                    this.$t("Bezahlt") as string,
                    this.$t("Storniert") as string,
                    this.$t("Abrechnungskonto") as string,
                    this.$t("Partition") as string,
                    this.$t("Standort") as string,
                    this.$t("Gesamtumsatz") as string,
                    this.$t("Software") as string,
                    this.$t("BDC (wiederkehrend)") as string,
                    this.$t("BDC (variabel)") as string,
                    this.$t("Click-to-Call") as string,
                    this.$t("Call-Tracking") as string,
                    this.$t("SMS") as string,
                    this.$t("WhatsApp") as string,
                    this.$t("Einrichtung") as string,
                    this.$t("Sonstiges") as string,
                ],
                ...this.items
                    .map((i) =>
                        i.invoiceDealerRevenues.map((r) => [
                            this.formatLocalDate(i.invoice.created),
                            i.invoice.id.toString(),
                            (i.invoice.paid ? this.$t("ja") : this.$t("nein")) as string,
                            (i.invoice.reverseInvoiceId !== null ? this.$t("ja") : this.$t("nein")) as string,
                            i.billingAccount?.companyName || (this.$t("Unbekanntes Abrechnungskonto") as string),
                            r.partitionId
                                ? this.getPartitionById(r.partitionId)?.name ??
                                  (this.$t("Unbekannte Partition") as string)
                                : "",
                            r.dealerId
                                ? this.getDealerById(r.dealerId)?.name ?? (this.$t("Unbekannter Standort") as string)
                                : "",
                            this.formatPriceForCsv(r.invoiceRevenueData.totalRevenue),
                            this.formatPriceForCsv(r.invoiceRevenueData.softwareRevenue),
                            this.formatPriceForCsv(r.invoiceRevenueData.fixedBdcRevenue),
                            this.formatPriceForCsv(r.invoiceRevenueData.variableBdcRevenue),
                            this.formatPriceForCsv(r.invoiceRevenueData.clickToCallRevenue),
                            this.formatPriceForCsv(r.invoiceRevenueData.callTrackingRevenue),
                            this.formatPriceForCsv(r.invoiceRevenueData.outgoingSmsRevenue),
                            this.formatPriceForCsv(r.invoiceRevenueData.whatsAppNumberRevenue),
                            this.formatPriceForCsv(r.invoiceRevenueData.setupRevenue),
                            this.formatPriceForCsv(getOtherRevenue(r.invoiceRevenueData)),
                        ])
                    )
                    .reduce((prev, cur) => prev.concat(cur), []),
            ];
        },

        revenuesCsvFilename(): string | null {
            if (!this.items.length) {
                return null;
            }

            const prefix = getFileBasename(
                this.$t("Umsätze") as string,
                this.title,
                this.$t("Rechnungen {0} bis {1}", [this.fromInvoiceId, this.toInvoiceId]) as string
            );

            return `${prefix}.csv`;
        },
    },

    methods: {
        formatCurrency(value: number): string {
            return renderCurrency(value, this.currency);
        },

        formatLocalDate(date: string): string {
            return formatLocalDate(date, userSession.locale, "S");
        },

        formatPriceForCsv(value: number): string {
            return this.$n(value, {
                minimumFractionDigits: "2",
                maximumFractionDigits: "4",
                useGrouping: false as any, // interface requires string, but the implementation a number
            });
        },

        getBillingAccountById(billingAccountId: number): BillingAccount | null {
            return this.billingAccounts.find((b) => b.id === billingAccountId) ?? null;
        },

        getDealerById(dealerId: string): Dealer | null {
            return this.dealers.find((d) => d.id === dealerId) ?? null;
        },

        getInvoiceDownloadLink(invoiceId: number): string {
            return invoicesApi.generateDownloadLink(invoiceId, false);
        },

        getPartitionById(partitionId: string): PartitionSummary | null {
            return this.partitions.find((p) => p.id === partitionId) ?? null;
        },

        getInvoiceNetAmountToInvoiceRevenueDelta(item: Item): number {
            const revenueTotal = item.invoiceDealerRevenues
                .map((r) => r.invoiceRevenueData.totalRevenue)
                .reduce((a, b) => a + b, 0);

            return Math.round(10_000 * (item.invoice.netAmount - revenueTotal)) / 10_000;
        },

        async loadItems() {
            this.loading = true;
            this.items = [];
            const searchId = ++this.searchId;

            try {
                const [invoiceDealerRevenues, invoices] = await Promise.all([
                    invoiceRevenuesApi.getByInvoiceIdRange(this.fromInvoiceId, this.toInvoiceId),
                    invoicesApi.getAll(this.fromInvoiceId, this.toInvoiceId, null),
                ]);

                if (searchId === this.searchId) {
                    this.items = invoices
                        .map((invoice) => ({
                            invoice,
                            invoiceDealerRevenues: invoiceDealerRevenues.filter((r) => r.invoiceId === invoice.id),
                            billingAccount: this.getBillingAccountById(invoice.billingAccountId),
                        }))
                        .sort((a, b) => b.invoice.id - a.invoice.id);
                }
            } finally {
                if (searchId === this.searchId) {
                    this.loading = false;
                }
            }
        },
    },

    watch: {
        async fromInvoiceId() {
            try {
                await this.loadItems();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },

        async toInvoiceId() {
            try {
                await this.loadItems();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },
    },

    async mounted() {
        this.billingAccounts = await billingAccountsApi.list();
        this.dealers = await dealersApi.list();
        this.partitions = await partitionsApi.list();

        await this.loadItems();
    },

    components: {
        CsvDownloadIcon,
    },
});

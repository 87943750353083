import { InvoiceRevenueData } from "./invoicerevenues";
import axios from "axios";

export interface BenefitMonthRevenue {
    readonly benefitMonth: string;
    readonly billingAccountCount: number;
    readonly partitionCount: number;
    readonly dealerCount: number;
    readonly invoiceTotal: number;
    readonly unpaidInvoiceTotal: number;
    readonly unpaidInvoiceCount: number;
    readonly invoiceRevenueData: InvoiceRevenueData;
    readonly legacyRevenueData: InvoiceRevenueData;
}

export interface DealerBenefitMonthRevenue {
    readonly dealerId: string | null;
    readonly benefitMonth: string;
    readonly partitionId: string | null;
    readonly invoiceRevenueData: InvoiceRevenueData;
    readonly legacyRevenueData: InvoiceRevenueData;
}

interface BenefitRevenuesApi {
    getBenefitMonthRevenues(from: string | null, to: string | null): Promise<BenefitMonthRevenue[]>;
    getDealerBenefitMonthRevenues(from: string | null, to: string | null): Promise<DealerBenefitMonthRevenue[]>;
}

export const benefitRevenuesApi: BenefitRevenuesApi = {
    async getBenefitMonthRevenues(from, to) {
        return (
            await axios.get("/api/benefit-revenues/monthly", {
                params: { from, to },
            })
        ).data;
    },

    async getDealerBenefitMonthRevenues(from, to) {
        return (
            await axios.get("/api/benefit-revenues/dealers", {
                params: { from, to },
            })
        ).data;
    },
};


import { InvoiceRevenueData } from "@/api/invoicerevenues";
import { renderCurrency } from "@/app/filters";
import { getOtherRevenue } from "@/app/pages/invoicerevenue/invoiceRevenueUtils";
import { configStore } from "@/store/config";
import Vue from "vue";

export default Vue.extend({
    props: {
        billingAccountCount: {
            type: Number as () => number | null,
            default: null,
        },
        bwaMonth: {
            type: String as () => string | null,
            default: null,
        },
        dealerCount: {
            type: Number as () => number | null,
            default: null,
        },
        dealerId: {
            type: String as () => string | null,
            default: null,
        },
        dealerName: {
            type: String as () => string | null,
            default: null,
        },
        invoiceRevenueData: {
            type: Object as () => InvoiceRevenueData,
            required: true,
        },
        isTotal: {
            type: Boolean,
            default: false,
        },
        partitionCount: {
            type: Number as () => number | null,
            default: null,
        },
        partitionId: {
            type: String as () => string | null,
            default: null,
        },
        partitionName: {
            type: String as () => string | null,
            default: null,
        },
        unpaidInvoiceCount: {
            type: Number as () => number | null,
            default: null,
        },
        unpaidRevenueTotal: {
            type: Number,
            required: true,
        },
    },

    computed: {
        currency(): string {
            return configStore.configuration.organisationCurrency;
        },

        otherAndSetupRevenue(): number {
            return this.otherRevenue + this.invoiceRevenueData.setupRevenue;
        },

        otherRevenue(): number {
            return getOtherRevenue(this.invoiceRevenueData);
        },

        recurringSoftwareRevenue(): number {
            return this.invoiceRevenueData.softwareRevenue + this.invoiceRevenueData.whatsAppNumberRevenue;
        },

        softwareSalesRatio(): number | null {
            return this.getRatio(
                this.recurringSoftwareRevenue + this.variableSoftwareRevenue,
                this.invoiceRevenueData.totalRevenue
            );
        },

        variableSoftwareRevenue(): number {
            return (
                this.invoiceRevenueData.callTrackingRevenue +
                this.invoiceRevenueData.clickToCallRevenue +
                this.invoiceRevenueData.outgoingSmsRevenue
            );
        },
    },

    methods: {
        formatCurrency(value: number): string {
            return renderCurrency(value, this.currency);
        },

        getRatio(value: number, total: number): number | null {
            if (!total) {
                return null;
            }

            return value / total;
        },
    },
});


import { ElasticCluster, ElasticClusterForm, elasticClustersApi } from "@/api/elasticClusters";
import CopyToClipboardIcon from "@/app/components/CopyToClipboardIcon.vue";
import CrudTablePage from "@/app/pages/CrudTablePage.vue";
import { TableHeader } from "@/app/pages/crudPage";
import { MAX_PARTITIONS_PER_ELASTIC_CLUSTER } from "@/app/resourceLimits";
import { notEmpty } from "@/app/validation";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            notEmpty,
            MAX_PARTITIONS_PER_ELASTIC_CLUSTER,
        };
    },

    computed: {
        headers(): TableHeader[] {
            return [
                {
                    id: "id",
                    text: this.$t("ID"),
                    width: "15%",
                },
                {
                    id: "url",
                    text: this.$t("URL"),
                    width: "55%",
                },
                {
                    id: "username",
                    text: this.$t("Benutzername"),
                    width: "15%",
                },
                {
                    id: "password",
                    text: this.$t("Passwort"),
                    width: "15%",
                },
            ];
        },
    },

    methods: {
        async getElasticClusters(): Promise<ElasticCluster[]> {
            return await elasticClustersApi.list();
        },

        async addElasticCluster(form: ElasticClusterForm) {
            await elasticClustersApi.add(form);
        },

        emptyForm(): ElasticClusterForm {
            return {
                url: "",
                username: "elastic",
                password: "",
            };
        },

        usageCountColor(elasticCluster: ElasticCluster) {
            if (elasticCluster.usageCount >= MAX_PARTITIONS_PER_ELASTIC_CLUSTER) {
                return "error";
            }
            return "success";
        },
    },

    components: {
        CopyToClipboardIcon,
        CrudTablePage,
    },
});


import { TwilioApiKey } from "@/api/partitions";
import CopyToClipboardIcon from "@/app/components/CopyToClipboardIcon.vue";
import Vue from "vue";

export default Vue.extend({
    props: {
        accountSid: {
            type: String,
            required: true,
        },
        twilioApiKey: {
            type: Object as () => TwilioApiKey,
            required: true,
        },
    },
    components: {
        CopyToClipboardIcon,
    },
});

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.headerGroups.length)?_c('v-simple-table',{staticClass:"text-center",attrs:{"dense":""}},[_c('thead',[_c('tr',[_c('th',{staticClass:"border-bottom",attrs:{"rowspan":_vm.hasHeaderGroupWithText ? 2 : 1}},[_c(_vm.small ? 'small' : 'span',{tag:"component"},[_vm._v(" "+_vm._s(_vm.groupByHeaderText)+" ")]),(_vm.csvDownload)?_c('csv-download-icon',{attrs:{"data":_vm.csvData,"filename":_vm.csvFilename,"has-header":"","small":""}}):_vm._e()],1),_vm._l((_vm.headerGroups),function(headerGroup){return [(headerGroup.text)?_c('th',{staticClass:"border-bottom border-left pt-2 text-center",staticStyle:{"vertical-align":"top"},attrs:{"colspan":headerGroup.headers.length,"rowspan":headerGroup.headers.length === 1 && headerGroup.text === headerGroup.headers[0].text ? 2 : 1}},[_c(_vm.small ? 'small' : 'span',{tag:"component"},[_vm._v(" "+_vm._s(headerGroup.text)+" "),(headerGroup.tooltip)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({style:(_vm.small ? 'margin-top: -2px' : ''),attrs:{"x-small":""}},on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(headerGroup.tooltip))])]):_vm._e()],1)],1):_vm._l((headerGroup.headers),function(header,index){return _c('th',{class:{
                        'border-left': !index,
                        'border-bottom': true,
                        'pa-1': true,
                        'text-center': true,
                    },attrs:{"rowspan":_vm.hasHeaderGroupWithText ? 2 : 1}},[_c(_vm.small ? 'small' : 'span',{tag:"component"},[_vm._v(" "+_vm._s(header.text)+" "),(header.tooltip)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({style:(_vm.small ? 'margin-top: -2px' : ''),attrs:{"x-small":""}},on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(header.tooltip))])]):_vm._e()],1)],1)})]})],2),(_vm.hasHeaderGroupWithText)?_c('tr',[_vm._l((_vm.headerGroups),function(headerGroup){return [(
                        headerGroup.text &&
                            !(headerGroup.headers.length === 1 && headerGroup.text === headerGroup.headers[0].text)
                    )?_vm._l((headerGroup.headers),function(header,index){return _c('th',{class:{ 'border-left': !index, 'pa-1': true, 'pt-2': true, 'text-center': true },staticStyle:{"vertical-align":"top"}},[_c(_vm.small ? 'small' : 'span',{tag:"component"},[_vm._v(" "+_vm._s(header.text)+" "),(header.tooltip)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({attrs:{"x-small":""}},on),[_vm._v("mdi-information-outline")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(header.tooltip))])]):_vm._e()],1)],1)}):_vm._e()]})],2):_vm._e()]),(_vm.loading)?_c('tbody',[_c('tr',{staticClass:"v-data-table__empty-wrapper"},[_c('td',{staticClass:"pa-1",attrs:{"colspan":_vm.headersCount + 1}},[_c(_vm.small ? 'small' : 'span',{tag:"component"},[_vm._v(" "+_vm._s(_vm.$t("Lade Daten"))+" ")])],1)])]):_vm._e(),(!_vm.loading)?_c('tbody',[(!_vm.items.length)?_c('tr',{staticClass:"v-data-table__empty-wrapper"},[_c('td',{staticClass:"pa-1",attrs:{"colspan":_vm.headersCount + 1}},[_c(_vm.small ? 'small' : 'span',{tag:"component"},[_vm._v(" "+_vm._s(_vm.$t("Keine Daten vorhanden"))+" ")])],1)]):_vm._e(),_vm._l((_vm.items),function(item,index){return _c('reporting-table-row',{key:index,attrs:{"header-groups":_vm.headerGroups,"item":item,"small":_vm.small}})}),(_vm.items.length && _vm.totalsProp)?_c('reporting-table-row',{key:"reporting-table-totals",attrs:{"header-groups":_vm.headerGroups,"is-total":"","item":_vm.totalsProp,"small":_vm.small}}):_vm._e()],2):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }

import Vue from "vue";

export default Vue.extend({
    props: {
        contentClass: {
            type: String,
            required: false,
        },
        loading: {
            type: Boolean,
            required: true,
        },
        minHeight: {
            type: Number,
            required: false,
        },
        noData: {
            type: Boolean,
            default: false,
        },
        small: {
            type: Boolean,
            default: false,
        },
        subtitle: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            required: false,
        },
        titleClass: {
            type: String,
            required: false,
        },
    },
});

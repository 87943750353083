import axios from "axios";

export interface PartitionsQuery {
    readonly partitionIds: string[];
    readonly sql: string;
}

export type SelectStatementResult = { [column: string]: SelectStatementResultValue }[];

export type SelectStatementResultValue = string | number | null;

interface SqlConsoleApi {
    executePartitionsSelect(partitionsQuery: PartitionsQuery): Promise<SelectStatementResult>;
}

export const sqlConsoleApi: SqlConsoleApi = {
    async executePartitionsSelect(partitionsQuery) {
        return (await axios.post("/api/sql-console/_execute-partitions-select", partitionsQuery)).data;
    },
};

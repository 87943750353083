
import ActionBottomSheet from "./ActionBottomSheet.vue";
import { getPartitionResourceUrl } from "./actionUtils";
import { Action } from "@/api/actions";
import { Dealer } from "@/api/dealers";
import { PartitionSummary } from "@/api/partitions";
import { User } from "@/api/users";
import { getFullName } from "@/app/userUtils";
import { configStore } from "@/store/config";
import Vue from "vue";

export default Vue.extend({
    props: {
        action: {
            type: Object as () => Action,
            required: true,
        },
        dealer: {
            type: Object as () => Dealer | null,
            default: null,
        },
        partition: {
            type: Object as () => PartitionSummary | null,
            default: null,
        },
        user: {
            type: Object as () => User | null,
            default: null,
        },
    },

    data() {
        return {
            getFullName,
            actionBottomSheetVisible: false,
            commentVisible: false,
        };
    },

    computed: {
        timeZone(): string {
            return configStore.configuration.organisationTimeZone;
        },
    },

    methods: {
        getFullyQualifiedResourceUrl(action: Action): string | null {
            if (!action.resourceUrl || !this.partition) {
                return null;
            }

            return getPartitionResourceUrl(action, this.partition);
        },
    },

    components: {
        ActionBottomSheet,
    },
});

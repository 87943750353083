import de from "./de";
import en from "./en";
import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const i18n = new VueI18n({
    messages: {
        de,
        en,
    },
    fallbackLocale: "de",
    formatFallbackMessages: true,
    silentTranslationWarn: true,
});

export default i18n;
export const $t = i18n.t.bind(i18n);

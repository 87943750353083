
import UserShowPasswordDialog from "./UserShowPasswordDialog.vue";
import { Forbidden } from "@/api/errors";
import { Role, User, usersApi } from "@/api/users";
import { showConfirm, showError } from "@/app/messageUtil";
import { getFullName } from "@/app/userUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        user: {
            type: Object as () => User,
            required: true,
        },
    },

    data() {
        return {
            getFullName,
            newPassword: null as string | null,
        };
    },

    methods: {
        async deleteUser() {
            if (
                !(await showConfirm(
                    this.$t("Benutzer löschen") as string,
                    this.$t("Sind Sie sicher, dass Sie den Benutzer {0} löschen möchten?", [
                        getFullName(this.user),
                    ]) as string
                ))
            ) {
                return;
            }

            try {
                await usersApi.delete(this.user.id);

                this.$emit("changed");
            } catch (e) {
                if (!(e instanceof Forbidden)) {
                    throw e;
                }

                showError(this.$t("Sie haben nicht die Berechtigung, den Benutzer zu löschen.") as string);
            }
        },

        async forcePasswordChange() {
            if (
                !(await showConfirm(
                    this.$t("Benutzer bis Passwortänderung sperren") as string,
                    this.$t("Sind Sie sicher, dass Sie Benutzer {0} sperren möchten, bis das Passwort geändert wird?", [
                        this.user.username,
                    ]) as string
                ))
            ) {
                return;
            }

            try {
                await usersApi.editForcePasswordChange(this.user.id);

                this.$emit("changed");
            } catch (e) {
                if (!(e instanceof Forbidden)) {
                    throw e;
                }
                showError(
                    this.$t("Sie haben nicht die Berechtigung, das Passwort des Benutzers zurückzusetzen.") as string
                );
                return;
            }
        },

        getRoleColor(role: Role): string | null {
            if (role === Role.ACCOUNTANT) {
                return "accent";
            } else if (role === Role.BDC_ADMIN) {
                return "warning";
            } else if (role === Role.CONTRACT_MANAGER) {
                return "accent";
            } else if (role === Role.CT_ADMIN) {
                return "warning";
            } else if (role === Role.CT_SUPER_ADMIN) {
                return "error";
            } else if (role === Role.INFRASTRUCTURE_MANAGER) {
                return "error";
            } else if (role === Role.PARTITION_MANAGER) {
                return "warning";
            } else {
                return null;
            }
        },

        async resetPassword() {
            if (
                !(await showConfirm(
                    this.$t("Neues Passwort generieren") as string,
                    this.$t("Sind Sie sicher, dass Sie das Passwort für den Benutzer {0} zurücksetzen möchten ?", [
                        this.user.username,
                    ]) as string
                ))
            ) {
                return;
            }

            try {
                this.newPassword = await usersApi.resetPassword(this.user.id);
            } catch (e) {
                if (!(e instanceof Forbidden)) {
                    throw e;
                }

                showError(
                    this.$t("Sie haben nicht die Berechtigung, das Passwort des Benutzers zurückzusetzen.") as string
                );
            }
        },
    },

    components: {
        UserShowPasswordDialog,
    },
});


import { renderReportingTableValue, ReportingTableHeaderGroup, ReportingTableItem } from "./reportingTable";
import Vue from "vue";

export default Vue.extend({
    props: {
        headerGroups: {
            type: Array as () => ReportingTableHeaderGroup[],
            required: true,
        },
        isTotal: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object as () => ReportingTableItem,
            required: true,
        },
        small: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            renderReportingTableValue,
        };
    },
});


import AgentDayDataTable from "./AgentDayDataTable.vue";
import DatePicker from "@/app/components/DatePicker.vue";
import { userSession } from "@/store/userSession";
import { addDuration, getDate, toDateObject, UnitOfTime } from "@/util/dateTimeUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        date: {
            type: String,
            required: true,
        },
        includeCallCenterStatistics: {
            type: Boolean,
            default: false,
        },
        userFullName: {
            type: String as () => string | null,
            default: null,
        },
        userId: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            includeCallCenterStatisticsProp: this.includeCallCenterStatistics,
            dateProp: this.date,
        };
    },

    computed: {
        timeZone(): string {
            return userSession.timeZone;
        },
    },

    methods: {
        addDays(offset: number) {
            this.dateProp = [this.dateProp]
                .map((date) => toDateObject(this.timeZone, date))
                .map((date) => addDuration(date, this.timeZone, offset, UnitOfTime.DAY))
                .map((date) => getDate(date, this.timeZone))
                .pop()!;
        },
    },

    watch: {
        date() {
            this.dateProp = this.date;
        },
    },

    components: {
        AgentDayDataTable,
        DatePicker,
    },
});

export interface MimeType {
    readonly type: string;
    readonly subtype: string;
    readonly parameters: string | null;
    readonly raw: string;
}

export function getDataUrlMimeType(dataUrl: string): MimeType | null {
    const match: RegExpMatchArray | null = dataUrl.match(/^data:((\w+)\/([^;,]+)(?:;([^,]+))?),/);

    if (!match) {
        return null;
    }

    const raw = match[1].trim();
    const type = match[2].trim().toLowerCase();
    const subtype = match[3].trim().toLowerCase();
    const parameters = match[4].trim() || null;

    return { type, subtype, parameters, raw };
}


import BdcTeamOverviewDataTableRow from "./BdcTeamOverviewDataTableRow.vue";
import { BdcTeam } from "@/api/bdcTeams";
import { User } from "@/api/users";
import DataTable from "@/app/components/DataTable.vue";
import { DataTableHeader } from "@/app/components/dataTable";
import Vue from "vue";

export default Vue.extend({
    props: {
        bdcTeams: {
            type: Array as () => BdcTeam[],
            default: () => [],
        },
        loading: {
            type: Boolean,
            default: false,
        },
        users: {
            type: Array as () => User[],
            required: true,
        },
    },

    computed: {
        headers(): DataTableHeader[] {
            return [
                {
                    text: this.$t("Team"),
                    width: "45%",
                },
                {
                    text: this.$t("Mitglieder"),
                    width: "45%",
                },
                {
                    text: this.$t("Aktionen"),
                    align: "right",
                    width: "10%",
                },
            ];
        },
    },

    components: {
        BdcTeamOverviewDataTableRow,
        DataTable,
    },
});


import DutyRosterCalendar from "./DutyRosterCalendar.vue";
import DutyRosterTemplateMenu from "./DutyRosterTemplateMenu.vue";
import { DutyRosterEntry, DutyRosterEntryType, dutyRostersApi } from "@/api/dutyRosters";
import { ShiftScheduleTemplateEntry } from "@/api/shiftScheduleTemplates";
import { configStore } from "@/store/config";
import { getFirstDayOfWeek } from "@/util/dateTimeUtils";
import moment from "moment-timezone";
import Vue from "vue";

export default Vue.extend({
    props: {
        editMode: {
            type: Boolean,
            default: false,
        },
        open: {
            type: Boolean,
            required: true,
        },
        usedCapacity: {
            type: Number,
            required: true,
        },
        userId: {
            type: String,
            required: true,
        },
        weeklyWorkingHours: {
            type: Number,
            required: true,
        },
        weekNumber: {
            type: Number,
            required: true,
        },
        weekStart: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            editModeProp: this.editMode,
            entries: [] as DutyRosterEntry[],
            working: false,
        };
    },

    computed: {
        weekEnd() {
            return moment(this.weekStart).add(6, "d");
        },
    },

    watch: {
        async open() {
            if (this.open) {
                try {
                    await this.loadEntries();
                } catch (e) {
                    this.$nextTick(() => {
                        throw e;
                    });
                }
            } else {
                this.editModeProp = this.editMode;
            }
        },
    },

    methods: {
        activeEditMode(e: Event) {
            this.editModeProp = true;

            if (this.open) {
                e.stopPropagation();
            }
        },

        async loadEntries() {
            this.working = true;
            try {
                this.entries = await dutyRostersApi.getEntriesByUserAndWeek(this.userId, this.weekStart);
            } finally {
                this.working = false;
            }
        },

        async saveEntries(entries: DutyRosterEntry[]) {
            this.working = true;
            try {
                await dutyRostersApi.editEntriesByUserAndWeek(this.userId, this.weekStart, entries);
                this.entries = entries;
                this.$emit("update", this.userId);
            } finally {
                this.working = false;
            }
        },

        async pasteTemplate(templateEntries: ShiftScheduleTemplateEntry[]) {
            await this.saveEntries([...this.entries, ...this.templateToRoster(templateEntries)]);
        },

        async replaceByTemplate(templateEntries: ShiftScheduleTemplateEntry[]) {
            await this.saveEntries(this.templateToRoster(templateEntries));
        },

        templateToRoster(templateEntries: ShiftScheduleTemplateEntry[]): DutyRosterEntry[] {
            return templateEntries.map((e) => ({
                day: this.toDate(e.weekday),
                beginTime: e.beginTime,
                endTime: e.endTime,
                type: DutyRosterEntryType.ANSWER_INCOMING_ORDINARY_CALLS,
                includedBreakMinutes: e.includedBreakMinutes,
            }));
        },

        toDate(weekday: number) {
            weekday = (weekday - getFirstDayOfWeek(configStore.configuration.defaultLocale) + 7) % 7;
            return moment(this.weekStart)
                .add(weekday, "d")
                .format()
                .substring(0, 10);
        },
    },

    async mounted() {
        if (this.open) {
            await this.loadEntries();
        }
    },

    components: {
        DutyRosterCalendar,
        DutyRosterTemplateMenu,
    },
});

import axios from "axios";

export interface PartitionSettings {
    readonly citnowConversationsApiActive: boolean;
    readonly accessibleForExternalBdcAgents: boolean;
    readonly extendedExternalBdcReporting: boolean;
}

export interface PartitionSettingsForm {
    readonly citnowConversationsApiActive: boolean;
    readonly accessibleForExternalBdcAgents: boolean;
    readonly extendedExternalBdcReporting: boolean;
}

interface PartitionSettingsApi {
    getSettings(id: string): Promise<PartitionSettings>;
    editSettings(id: string, partitionSettingsForm: PartitionSettingsForm): Promise<void>;
}

export const partitionSettingsApi: PartitionSettingsApi = {
    async getSettings(id) {
        return (await axios.get(`/api/partition-settings/${id}`)).data;
    },

    async editSettings(id, partitionSettingsForm) {
        await axios.put(`/api/partition-settings/${id}`, partitionSettingsForm);
    },
};

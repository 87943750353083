import i18n, { $t } from "@/app/i18n";
import { numberCompare, renderPercentage } from "@/app/pages/reporting/reportingUtils";
import { trimAndReturnNullIfEmpty } from "@/util/stringUtils";
import { SelectHeader, SelectOption } from "@/util/types";

export interface ReportingTableData<HeaderOptions = unknown, ItemOptions = unknown> {
    readonly headerGroups: ReportingTableHeaderGroup<HeaderOptions>[];
    readonly items: ReportingTableItem<ItemOptions>[];
    readonly totals?: ReportingTableItem<ItemOptions>;
    readonly groupByHeaderText?: string;
}

export interface ReportingTableHeader<HeaderOptions = unknown> {
    readonly text: string;
    readonly tooltip?: string;
    readonly options?: HeaderOptions;
}

export interface ReportingTableHeaderGroup<HeaderOptions = unknown> {
    readonly text: string | null;
    readonly tooltip?: string;
    readonly headers: ReportingTableHeader<HeaderOptions>[];
}

export interface ReportingTableItem<ItemOptions = unknown> {
    readonly title: string;
    readonly subtitle?: string;
    readonly tooltip?: string;
    readonly groups: ReportingTableItemColumnGroup[];
    readonly onHeaderClick?: () => Promise<void> | void;
    readonly options?: ItemOptions;
}

export interface ReportingTableItemColumnGroup {
    readonly columns: ReportingTableItemColumn[];
}

export interface ReportingTableItemColumn {
    readonly entries: ReportingTableItemColumnEntries;
}

export type ReportingTableItemColumnEntries = [ReportingTableValue, ...ReportingTableValue[]];

export type ReportingTableValue = {
    readonly value: number | undefined;
    readonly isPercentage?: boolean;
    readonly class?: string;
    readonly onClick?: () => Promise<void> | void;
    readonly formatter?: ReportingTableValueFormatter;
};

export type ReportingTableValueFormatter = (value: number | undefined, isPercentage: boolean) => string | null;

export type ReportingTableSortByOption = SelectOption & { value: ReportingTableSortByOptionValue };

export type ReportingTableSortByOptions = (ReportingTableSortByOption | SelectHeader)[];

export interface ReportingTableSortByOptionValue {
    readonly groupIndex: number;
    readonly columnIndex: number;
    readonly direction: "ASC" | "DESC";
}

export function renderReportingTableValue(value: ReportingTableValue, collapseNewLines?: boolean): string | null {
    const customLabel = value.formatter
        ? trimAndReturnNullIfEmpty(value.formatter(value.value, !!value.isPercentage))
        : null;

    const label = customLabel
        ? customLabel
        : value.value === undefined
        ? null
        : value.isPercentage
        ? renderPercentage(value.value)
        : trimAndReturnNullIfEmpty(i18n.n(value.value, { maximumFractionDigits: "1" }));

    if (!collapseNewLines) {
        return label;
    }

    return trimAndReturnNullIfEmpty((label ?? "").replace(/\n/g, " "));
}

function getCsvDataLine(item: ReportingTableItem, entryIndex: number): string[] {
    return [
        item.subtitle ? `${item.title} [${item.subtitle}]` : item.title,
        ...item.groups
            .map((group) =>
                group.columns.map(
                    (column) =>
                        renderReportingTableValue(
                            entryIndex < column.entries.length ? column.entries[entryIndex] : { value: undefined },
                            true
                        ) ?? ""
                )
            )
            .reduce((prev, cur) => prev.concat(cur), []),
    ];
}

function getCsvDataByEntry(
    headerGroups: ReportingTableHeaderGroup[],
    items: ReportingTableItem[],
    entryIndex: number,
    totals?: ReportingTableItem,
    header?: string
): string[][] {
    const csvHeader: string[] = [
        header ?? "",
        ...headerGroups
            .map((headerGroup) =>
                headerGroup.headers.map((header) =>
                    !headerGroup.text || headerGroup.text === header.text
                        ? header.text
                        : `${headerGroup.text} > ${header.text}`
                )
            )
            .reduce((prev, cur) => prev.concat(cur), []),
    ];

    const csvData: string[][] = [csvHeader, ...items.map((item) => getCsvDataLine(item, entryIndex))];

    if (totals) {
        csvData.push(getCsvDataLine(totals, entryIndex));
    }

    return csvData;
}

export function getCsvData(
    headerGroups: ReportingTableHeaderGroup[],
    items: ReportingTableItem[],
    totals?: ReportingTableItem,
    header?: string
): string[][] {
    const maxEntriesCount = Math.max(
        0,
        ...items
            .map((item) =>
                item.groups
                    .map((group) => group.columns)
                    .reduce((prev, cur) => prev.concat(cur), [])
                    .map((column) => column.entries.length)
            )
            .reduce((prev, cur) => prev.concat(cur), [])
    );

    return [...Array(maxEntriesCount).keys()]
        .map((__, entryIndex) =>
            getCsvDataByEntry(headerGroups, items, entryIndex, totals, entryIndex === 0 ? header : undefined)
        )
        .reduce(
            (lines, csv) => (lines.length ? lines.map((line, index) => line.concat(csv[index].slice(1))) : csv),
            []
        );
}

export function getSortByOptions(headerGroups: ReportingTableHeaderGroup[]): ReportingTableSortByOptions {
    const ascendingText = $t("aufsteigend");
    const descendingText = $t("absteigend");

    return headerGroups
        .map((headerGroup, groupIndex) => [
            { header: headerGroup.text ?? (headerGroup.headers.length ? headerGroup.headers[0].text : "") },
            ...headerGroup.headers
                .map(
                    (header, columnIndex) =>
                        [
                            {
                                text: `${header.text} (${ascendingText})`,
                                value: {
                                    groupIndex,
                                    columnIndex,
                                    direction: "ASC",
                                },
                            },
                            {
                                text: `${header.text} (${descendingText})`,
                                value: {
                                    groupIndex,
                                    columnIndex,
                                    direction: "DESC",
                                },
                            },
                        ] as ReportingTableSortByOption[]
                )
                .reduce((prev, cur) => prev.concat(cur), []),
        ])
        .filter((items) => items.length > 1)
        .reduce((prev, cur) => prev.concat(cur), []);
}

export function sort<ItemOptions = unknown>(
    items: ReportingTableItem<ItemOptions>[],
    sortBy: ReportingTableSortByOptionValue
): ReportingTableItem<ItemOptions>[] {
    return [...items].sort((a, b) => {
        const aValue = a.groups[sortBy.groupIndex].columns[sortBy.columnIndex].entries[0].value;
        const bValue = b.groups[sortBy.groupIndex].columns[sortBy.columnIndex].entries[0].value;

        return numberCompare(aValue, bValue, sortBy.direction);
    });
}

export function transpose(table: ReportingTableData): ReportingTableData {
    return {
        headerGroups: [
            ...table.items.map((item) => ({
                text: null,
                headers: [{ text: item.title }],
            })),
            ...(table.totals
                ? [
                      {
                          text: null,
                          headers: [{ text: table.totals.title }],
                      },
                  ]
                : []),
        ],
        items: table.headerGroups
            .map((headerGroup, headerGroupIndex) =>
                headerGroup.headers.map((header, headerIndex) => ({
                    title: header.text,
                    groups: [
                        ...table.items.map((item) => ({
                            columns: [item.groups[headerGroupIndex].columns[headerIndex]],
                        })),
                        ...(table.totals
                            ? [{ columns: [table.totals.groups[headerGroupIndex].columns[headerIndex]] }]
                            : []),
                    ],
                }))
            )
            .reduce((prev, cur) => prev.concat(cur), []),
    };
}

export function extractHeaderGroup<HeaderOptions, ItemOptions = unknown>(
    table: ReportingTableData<HeaderOptions, ItemOptions>,
    headerGroupIndex: number
): ReportingTableData<HeaderOptions, ItemOptions> {
    return {
        headerGroups: [table.headerGroups[headerGroupIndex]],
        items: table.items.map((item) => ({
            ...item,
            groups: [item.groups[headerGroupIndex]],
        })),
        totals: table.totals
            ? {
                  ...table.totals,
                  groups: [table.totals.groups[headerGroupIndex]],
              }
            : undefined,
        groupByHeaderText: table.groupByHeaderText,
    };
}

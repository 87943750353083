
import InvoiceWithCreditTransferListItemContent from "./InvoiceWithCreditTransferListItemContent.vue";
import { BillingAccount } from "@/api/billingAccounts";
import { InvoiceWithCreditTransfer } from "@/api/creditTransfers";
import Vue from "vue";

export default Vue.extend({
    props: {
        invoicesWithCreditTransfer: {
            type: Array as () => InvoiceWithCreditTransfer[],
            required: true,
        },
        billingAccounts: {
            type: Array as () => BillingAccount[],
            required: true,
        },
    },

    data() {
        return {
            selectedInvoiceIds: this.invoicesWithCreditTransfer.filter((i) => !i.invoice.paid).map((i) => i.invoice.id),
        };
    },

    computed: {
        openInvoicesWithCreditTransfer(): InvoiceWithCreditTransfer[] {
            return this.invoicesWithCreditTransfer
                .filter((i) => !i.invoice.paid)
                .sort((a, b) => a.invoice.id - b.invoice.id);
        },

        paidInvoicesWithCreditTransfer(): InvoiceWithCreditTransfer[] {
            return this.invoicesWithCreditTransfer
                .filter((i) => i.invoice.paid)
                .sort((a, b) => a.invoice.id - b.invoice.id);
        },
    },

    methods: {
        async submitForm() {
            this.$emit("submit", this.selectedInvoiceIds);
        },

        close() {
            this.$emit("close");
        },
    },

    components: {
        InvoiceWithCreditTransferListItemContent,
    },
});

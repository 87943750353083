
import { Action, Callbacks, TableHeader } from "./crudPage";
import DataTable from "@/app/components/DataTable.vue";
import { SelectOption } from "@/util/types";
import Sortable from "sortablejs";
import Vue from "vue";
import { DataTableHeader } from "vuetify";

export default Vue.extend({
    props: {
        noItemsText: String,
        addDialogTitle: String,
        editDialogTitle: String,
        pageTitle: String,
        autoRefresh: {
            type: Boolean,
            default: true,
        },
        persistentDialog: {
            type: Boolean,
            default: false,
        },
        callbacks: Object as () => Callbacks<unknown, unknown, unknown, unknown>,
        headers: {
            type: Array as () => TableHeader[],
            required: true,
        },
        sortOptions: Array as () => SelectOption[],
        defaultSortBy: [Object, Number, String],
    },

    data() {
        return {
            items: [] as unknown[],
            form: null as unknown,
            editedItem: null as unknown,
            dialogVisible: false,
            loading: true,
            saving: false,
            deleting: false,
            handlingAction: false,
            sortableInitialized: false,
            sortBy: this.defaultSortBy ? JSON.stringify(this.defaultSortBy) : null,
        };
    },

    computed: {
        actions(): Action<any>[] {
            return this.callbacks.actions || [];
        },

        canUpdateOrder(): boolean {
            return !this.selectableSortOptions.length && !!this.callbacks.updateOrder;
        },

        hasActions(): boolean {
            return !!this.callbacks.edit || !!this.callbacks.delete || !!this.actions.length;
        },

        selectableSortOptions(): SelectOption[] {
            return (this.sortOptions || []).map((o: SelectOption) => ({ ...o, value: JSON.stringify(o.value) }));
        },

        tableHeaders(): DataTableHeader[] {
            const tableHeaders = [];

            if (this.canUpdateOrder) {
                tableHeaders.push({
                    text: "",
                    width: "1%",
                });
            }

            tableHeaders.push(...this.headers);

            if (this.hasActions) {
                tableHeaders.push({
                    text: this.$t("Aktionen"),
                    align: "right",
                    width: "1%",
                });
            }

            return tableHeaders as DataTableHeader[];
        },
    },

    methods: {
        async handleAction<T>(action: Action<T>, item: T) {
            this.handlingAction = true;
            try {
                const result = await action.callback(item);
                if (this.autoRefresh && (result === undefined || result === true)) {
                    await this.loadItems();
                }
            } finally {
                this.handlingAction = false;
            }
        },

        async loadItems() {
            this.loading = true;
            try {
                this.items = await this.callbacks.load(this.sortBy !== null ? JSON.parse(this.sortBy) : null);
            } finally {
                this.loading = false;
            }
        },

        showCreateDialog() {
            const form = this.callbacks.emptyForm();
            if (form) {
                this.editedItem = null;
                this.form = form;
                this.dialogVisible = true;
            }
        },

        showEditDialog(item: unknown) {
            this.editedItem = item;
            this.form = this.callbacks.toForm!(item);
            this.dialogVisible = true;
        },

        async deleteItem(item: unknown) {
            this.deleting = true;
            try {
                const result = await this.callbacks.delete!(item);
                if (this.autoRefresh && (result === undefined || result === true)) {
                    await this.loadItems();
                }
            } finally {
                this.deleting = false;
            }
        },

        async submitForm() {
            if (!(this.$refs.form as any).validate()) {
                return;
            }

            this.saving = true;
            try {
                let result: boolean | void;
                if (this.editedItem) {
                    result = await this.callbacks.edit!(this.editedItem, this.form, this.$refs.form);
                } else {
                    result = await this.callbacks.add!(this.form, this.$refs.form);
                }
                if (result === false) {
                    return;
                }
            } finally {
                this.saving = false;
            }

            this.dialogVisible = false;
            if (this.autoRefresh) {
                await this.loadItems();
            }
        },
    },

    watch: {
        items() {
            const items = (this.$refs.items as HTMLElement).querySelector("tbody") as HTMLElement;

            if (!items) {
                return;
            }

            if (this.canUpdateOrder && !this.sortableInitialized && this.items.length && items) {
                Sortable.create(items, {
                    animation: 150,
                    handle: ".drag-handle",
                    ghostClass: "accent",
                    onEnd: async ({ oldIndex, newIndex }) => {
                        if (oldIndex !== undefined && newIndex !== undefined && oldIndex !== newIndex) {
                            const item = this.items.splice(oldIndex, 1)[0];
                            this.items.splice(newIndex, 0, item);
                            await this.callbacks.updateOrder!(this.items);
                        }
                    },
                });
                this.sortableInitialized = true;
            }
        },
    },

    async mounted() {
        await this.loadItems();
    },

    components: {
        DataTable,
    },
});

import { BillingAccount, PaymentMethod } from "./billingAccounts";
import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export enum BillingAccountSearchOrder {
    CREATED_DESC = "CREATED_DESC",
    CREATED_ASC = "CREATED_ASC",
}

export interface BillingAccountSearchRequest {
    readonly billingAccountId: number | null;
    readonly dealerIds: string[];
    readonly partitionIds: string[];
    readonly paymentMethods: PaymentMethod[];
    readonly hasReceivers: boolean | null;
    readonly hasContracts: boolean | null;
    readonly hasUnpaidInvoices: boolean | null;
    readonly createdFrom: Date | null;
    readonly createdTo: Date | null;
    readonly sortBy: BillingAccountSearchOrder;
}

export interface BillingAccountSearchResults {
    readonly totalSize: number;
    readonly results: BillingAccount[];
    readonly searchId: number;
}

interface BillingAccountSearchApi {
    search(
        start: number,
        size: number,
        searchRequest: BillingAccountSearchRequest,
        searchId: number
    ): Promise<BillingAccountSearchResults>;
}

export const billingAccountSearchApi: BillingAccountSearchApi = {
    async search(start, size, searchRequest, searchId) {
        return cloneObject(
            (
                await axios.post("/api/billing-account-search", searchRequest, {
                    params: {
                        start,
                        size,
                        searchId,
                    },
                })
            ).data
        );
    },
};

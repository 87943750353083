
import { BillingAccount, PaymentMethod } from "@/api/billingAccounts";
import { BwaMonth } from "@/api/bwa";
import { Invoice, invoicesApi } from "@/api/invoices";
import { configStore } from "@/store/config";
import Vue from "vue";

export default Vue.extend({
    props: {
        billingAccount: {
            type: Object as () => BillingAccount,
            required: true,
        },
        bwaMonth: {
            type: Object as () => BwaMonth | null,
            default: null,
        },
        canTogglePaid: {
            type: Boolean,
            default: false,
        },
        canReverseInvoices: {
            type: Boolean,
            default: false,
        },
        canRemindInvoices: {
            type: Boolean,
            default: false,
        },
        compact: {
            type: Boolean,
            required: false,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        hideActions: {
            type: Boolean,
            required: false,
            default: false,
        },
        invoice: {
            type: Object as () => Invoice,
            required: true,
        },
    },

    computed: {
        invoiceDownloadLink(): string {
            return invoicesApi.generateDownloadLink(this.invoice.id, false);
        },

        invoiceReminderDownloadLink(): string {
            return invoicesApi.generateDownloadLink(this.invoice.id, true);
        },

        organisationCurrency(): string {
            return configStore.configuration.organisationCurrency;
        },

        remindableInvoice(): boolean {
            return (
                !!this.billingAccount.receivers.length &&
                this.billingAccount.paymentMethod === PaymentMethod.BANK_TRANSFER &&
                !this.invoice.paid &&
                !this.invoice.reverseInvoiceId &&
                this.invoice.grossAmount > 0
            );
        },

        reverseInvoiceDownloadLink(): string | null {
            if (this.invoice.reverseInvoiceId === null) {
                return null;
            }

            return invoicesApi.generateDownloadLink(this.invoice.reverseInvoiceId, false);
        },

        verticalPadding(): number {
            return this.compact ? 1 : 3;
        },
    },
});

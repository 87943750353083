
import ActionOverviewDataTable from "./ActionOverviewDataTable.vue";
import { EMPTY_SEARCH_REQUEST } from "./actionSearchUtils";
import { ActionSearchOrder, ActionSearchRequest, ActionType, FeedbackType } from "@/api/actions";
import { BdcTeam, bdcTeamsApi } from "@/api/bdcTeams";
import { Dealer, dealersApi } from "@/api/dealers";
import { partitionsApi, PartitionSummary } from "@/api/partitions";
import { User, usersApi } from "@/api/users";
import DateRangePicker from "@/app/components/DateRangePicker.vue";
import EnumField from "@/app/components/EnumField.vue";
import { DateRange } from "@/app/components/dateRangePicker";
import { getFullName } from "@/app/userUtils";
import { notEmpty } from "@/app/validation";
import { configStore } from "@/store/config";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { cloneObject } from "@/util/cloneUtils";
import { addDuration, endOf, getDate, startOf, toDateObject, UnitOfTime } from "@/util/dateTimeUtils";
import { Mutable, SelectOption } from "@/util/types";
import Vue from "vue";
import { TranslateResult } from "vue-i18n";

interface QuickFilter {
    readonly label: TranslateResult;
    readonly from: string;
    readonly to: string;
}

export default Vue.extend({
    data() {
        const ts = now();

        const timeZone = configStore.configuration.organisationTimeZone;

        return {
            ActionSearchOrder,
            ActionType,
            FeedbackType,
            notEmpty,
            bdcTeams: [] as readonly BdcTeam[],
            dealers: [] as Dealer[],
            loading: true,
            now: ts,
            partitions: [] as PartitionSummary[],
            searchRequest: {
                ...cloneObject(EMPTY_SEARCH_REQUEST),
                hasFeedback: true,
                createdFrom: toDateObject(timeZone, getDate(ts, timeZone)),
                createdTo: toDateObject(timeZone, getDate(ts, timeZone, 1)),
            } as Mutable<ActionSearchRequest>,
            timeZone,
            users: [] as User[],
        };
    },

    computed: {
        bdcTeamOptions(): SelectOption[] {
            return this.bdcTeams.map((bdcTeam) => ({
                text: bdcTeam.name,
                value: bdcTeam.id,
            }));
        },

        createdRange(): DateRange {
            return {
                from: getDate(this.searchRequest.createdFrom, this.timeZone),
                to: getDate(this.searchRequest.createdTo, this.timeZone, -1),
            };
        },

        dealerOptions(): SelectOption[] {
            return this.dealers
                .map((d) => ({ value: d.id, text: d.name }))
                .sort((a, b) => a.text.localeCompare(b.text, userSession.locale));
        },

        partitionOptions(): SelectOption[] {
            return this.partitions
                .map((p) => ({ value: p.id, text: p.name }))
                .sort((a, b) => a.text.localeCompare(b.text, userSession.locale));
        },

        quickFilters(): QuickFilter[] {
            const timeZone = this.timeZone;
            const locale = userSession.locale;

            const relativeStartOf = (offset: number, unit: UnitOfTime) =>
                getDate(startOf(addDuration(this.now, timeZone, offset, unit), timeZone, locale, unit), timeZone);

            const relativeEndOf = (offset: number, unit: UnitOfTime) =>
                getDate(endOf(addDuration(this.now, timeZone, offset, unit), timeZone, locale, unit), timeZone);

            return [
                {
                    label: this.$t("Heute"),
                    from: relativeStartOf(0, UnitOfTime.DAY),
                    to: relativeEndOf(0, UnitOfTime.DAY),
                },
                {
                    label: this.$t("Aktuelle Woche"),
                    from: relativeStartOf(0, UnitOfTime.WEEK),
                    to: relativeEndOf(0, UnitOfTime.WEEK),
                },
                {
                    label: this.$t("Letzte Woche"),
                    from: relativeStartOf(-1, UnitOfTime.WEEK),
                    to: relativeEndOf(-1, UnitOfTime.WEEK),
                },
                {
                    label: this.$t("Aktueller Monat"),
                    from: relativeStartOf(0, UnitOfTime.MONTH),
                    to: relativeEndOf(0, UnitOfTime.MONTH),
                },
                {
                    label: this.$t("Letzter Monat"),
                    from: relativeStartOf(-1, UnitOfTime.MONTH),
                    to: relativeEndOf(-1, UnitOfTime.MONTH),
                },
                {
                    label: this.$t("Vorletzter Monat"),
                    from: relativeStartOf(-2, UnitOfTime.MONTH),
                    to: relativeEndOf(-2, UnitOfTime.MONTH),
                },
            ];
        },

        userOptions(): SelectOption[] {
            return this.users
                .map((u) => ({ value: u.id, text: getFullName(u) }))
                .sort((a, b) => a.text.localeCompare(b.text, userSession.locale));
        },
    },

    methods: {
        isQuickFilterSelected(quickFilter: QuickFilter) {
            return (
                this.createdRange.from === quickFilter.from &&
                this.createdRange.to === quickFilter.to &&
                !!this.searchRequest.hasFeedback
            );
        },

        async refresh() {
            this.searchRequest = { ...this.searchRequest };
        },

        setCreatedRange(dateRange: DateRange) {
            this.searchRequest = {
                ...this.searchRequest,
                createdFrom: toDateObject(this.timeZone, dateRange.from),
                createdTo: toDateObject(this.timeZone, dateRange.to, 1),
            };
        },

        setCreatedRangeByQuickFilter(quickFilter: QuickFilter) {
            this.searchRequest = {
                ...this.searchRequest,
                hasFeedback: true,
                createdFrom: toDateObject(this.timeZone, quickFilter.from),
                createdTo: toDateObject(this.timeZone, quickFilter.to, 1),
            };
        },
    },

    async mounted() {
        try {
            this.bdcTeams = await bdcTeamsApi.getAll();
            this.partitions = await partitionsApi.list();
            this.dealers = await dealersApi.list();
            this.users = await usersApi.list();
        } finally {
            this.loading = false;
        }
    },

    components: {
        ActionOverviewDataTable,
        DateRangePicker,
        EnumField,
    },
});


import ExternalBdcCdrDataTableRow from "./ExternalBdcCdrDataTableRow.vue";
import { BdcTeam, bdcTeamsApi } from "@/api/bdcTeams";
import { Dealer, dealersApi } from "@/api/dealers";
import {
    ExternalBdcCdr,
    externalBdcCdrsApi,
    ExternalBdcCdrSearchOrder,
    ExternalBdcCdrSearchRequest,
} from "@/api/externalBdcCdrs";
import { partitionsApi, PartitionSummary } from "@/api/partitions";
import { User, usersApi } from "@/api/users";
import CsvDownloadIcon from "@/app/components/CsvDownloadIcon.vue";
import DataTable from "@/app/components/DataTable.vue";
import DateRangePicker from "@/app/components/DateRangePicker.vue";
import EnumField from "@/app/components/EnumField.vue";
import { DataTableHeader, DataTablePaging } from "@/app/components/dataTable";
import { DateRange } from "@/app/components/dateRangePicker";
import { getFullName } from "@/app/userUtils";
import { configStore } from "@/store/config";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { formatLocalDate, formatTime, getDate, toDateObject } from "@/util/dateTimeUtils";
import { parseAndFormatNumber } from "@/util/phoneNumberUtils";
import { PickMutable, SelectOption } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    data() {
        const itemsPerPage = 500;
        const ts = now();

        return {
            bdcTeams: [] as readonly BdcTeam[],
            dealers: [] as Dealer[],
            ExternalBdcCdrSearchOrder,
            items: [] as ExternalBdcCdr[],
            itemsPerPage,
            loadFilter: {
                bdcTeamIds: [] as string[],
                userIds: [] as string[],
                dealerIds: [] as string[],
                partitionIds: [] as string[],
                accepted: null as boolean | null,
                beginRange: {
                    from: getDate(ts, configStore.configuration.organisationTimeZone),
                    to: getDate(ts, configStore.configuration.organisationTimeZone),
                } as DateRange,
                sortBy: ExternalBdcCdrSearchOrder.BEGIN_DESC as ExternalBdcCdrSearchOrder,
            },
            loading: true,
            parseAndFormatNumber,
            paging: {
                page: 1,
                pageSize: itemsPerPage,
                totalSize: 0,
                maxTotalSize: 1_000_000,
                maxPage: 1_000_000 / itemsPerPage,
            } as PickMutable<DataTablePaging, "page" | "totalSize">,
            partitions: [] as PartitionSummary[],
            searchCounter: 1,
            users: [] as User[],
        };
    },

    computed: {
        areAllItemsLoaded(): boolean {
            return this.items.length === this.paging.totalSize;
        },

        bdcTeamOptions(): SelectOption[] {
            return this.bdcTeams.map((bdcTeam) => ({
                text: bdcTeam.name,
                value: bdcTeam.id,
            }));
        },

        dealerOptions(): SelectOption[] {
            return this.dealers
                .map((d) => ({ value: d.id, text: d.name }))
                .sort((a, b) => a.text.localeCompare(b.text, userSession.locale));
        },

        headers(): DataTableHeader[] {
            return [
                {
                    text: this.$t("Beginn"),
                    width: "5%",
                },
                {
                    text: this.$t("Annahme"),
                    width: "5%",
                },
                {
                    text: this.$t("Ende"),
                    width: "5%",
                },
                {
                    text: this.$t("Ansage"),
                    width: "5%",
                },
                {
                    text: this.$t("Wartezeit"),
                    width: "5%",
                },
                {
                    text: this.$t("Gesprächsdauer"),
                    width: "5%",
                },
                {
                    text: this.$t("Grund Gesprächsende"),
                    width: "5%",
                },
                {
                    text: this.$t("Anruferkennung"),
                    width: "10%",
                },
                {
                    text: this.$t("Zielrufnummer"),
                    width: "10%",
                },
                {
                    text: this.$t("Standort"),
                    width: "20%",
                },
                {
                    text: this.$t("Agent"),
                    width: "16%",
                },
                {
                    text: this.$t("Aufzeichnung"),
                    width: "14%",
                },
            ];
        },

        isSearchRequestForSinglePartition(): boolean {
            return (
                this.searchRequest.dealerIds
                    .map((dealerId) => this.dealers.find((d) => d.id === dealerId)!.partitionId)
                    .filter((partitionId, index, array) => array.indexOf(partitionId) === index).length === 1
            );
        },

        itemsOnPage(): ExternalBdcCdr[] {
            if (this.items.length <= this.itemsPerPage) {
                return this.items;
            } else {
                return this.items.slice((this.paging.page - 1) * this.itemsPerPage, this.itemsPerPage);
            }
        },

        partitionOptions(): SelectOption[] {
            return this.partitions
                .map((p) => ({ value: p.id, text: p.name }))
                .sort((a, b) => a.text.localeCompare(b.text, userSession.locale));
        },

        publicCsvData(): string[][] {
            if (!this.isSearchRequestForSinglePartition || !this.areAllItemsLoaded) {
                return [];
            }

            return [
                [
                    this.$t("Beginn") as string,
                    this.$t("Abgerechnete Minuten") as string,
                    this.$t("Anruferkennung") as string,
                    this.$t("Zielrufnummer") as string,
                    this.$t("Standort") as string,
                ],
                ...this.items
                    .filter((cdr) => cdr.accept && cdr.dealerId && this.searchRequest.dealerIds.includes(cdr.dealerId))
                    .map((cdr) => {
                        const duration =
                            Math.trunc(cdr.end.getTime() / 1000) - Math.trunc(cdr.accept!.getTime() / 1000);
                        const billedMinutes = Math.max(1, Math.ceil(duration / 60));

                        return [
                            this.formatDateTime(cdr.begin),
                            billedMinutes.toString(),
                            (cdr.caller ? parseAndFormatNumber(cdr.caller, "INTERNATIONAL") : "") ?? "",
                            (cdr.callee ? parseAndFormatNumber(cdr.callee, "INTERNATIONAL") : "") ?? "",
                            this.getDealerById(cdr.dealerId)?.name ?? "-",
                        ];
                    }),
            ];
        },

        publicCsvFilename(): string | null {
            if (!this.isSearchRequestForSinglePartition || !this.areAllItemsLoaded) {
                return null;
            }

            return [
                this.loadFilter.beginRange.from,
                this.loadFilter.beginRange.to,
                this.$t("Einzelverbindungsnachweis") as string,
                ...this.searchRequest.dealerIds.map((dealerId) => this.getDealerById(dealerId)?.name ?? "-"),
            ].join(" - ");
        },

        searchRequest(): ExternalBdcCdrSearchRequest {
            const partitionDealerIds = this.loadFilter.partitionIds
                .map((partitionId) => this.dealers.filter((d) => d.partitionId === partitionId).map((d) => d.id))
                .reduce((prev, cur) => prev.concat(cur), []);

            const dealerIds =
                partitionDealerIds.length && this.loadFilter.dealerIds.length
                    ? partitionDealerIds.filter((dealerId) => this.loadFilter.dealerIds.includes(dealerId))
                    : partitionDealerIds.length
                    ? partitionDealerIds
                    : this.loadFilter.dealerIds;

            return {
                bdcTeamIds: this.loadFilter.bdcTeamIds,
                userIds: this.loadFilter.userIds,
                dealerIds,
                accepted: this.loadFilter.accepted ?? null,
                beginFrom: toDateObject(
                    configStore.configuration.organisationTimeZone,
                    this.loadFilter.beginRange.from
                ),
                beginTo: toDateObject(configStore.configuration.organisationTimeZone, this.loadFilter.beginRange.to, 1),
                sortBy: this.loadFilter.sortBy,
            };
        },

        timeZone(): string {
            return configStore.configuration.organisationTimeZone;
        },

        userOptions(): SelectOption[] {
            return this.users
                .map((u) => ({ value: u.id, text: getFullName(u) }))
                .sort((a, b) => a.text.localeCompare(b.text, userSession.locale));
        },
    },

    methods: {
        formatDateTime(date: Date): string {
            const formattedDate = formatLocalDate(getDate(date, userSession.timeZone), userSession.locale, "S");
            const formattedTime = this.formatTime(date);

            return `${formattedDate} ${formattedTime}`;
        },

        formatTime(date: Date): string {
            return formatTime(date, userSession.timeZone, userSession.locale);
        },

        getDealerById(id: string | null): Dealer | null {
            return this.dealers.find((d) => d.id === id) ?? null;
        },

        getUserById(userId: string | null): User | null {
            return this.users.find((u) => u.id === userId) ?? null;
        },

        getUserFullNameById(userId: string | null): string {
            const user = this.getUserById(userId);

            if (!user) {
                return "-";
            }

            return getFullName(user);
        },

        async loadItems(loadAllItems?: boolean) {
            this.paging.totalSize = 0;
            this.items = [];
            this.loading = true;

            try {
                const start = loadAllItems ? 0 : (this.paging.page - 1) * this.itemsPerPage;
                const size = loadAllItems ? this.paging.maxTotalSize : this.itemsPerPage;

                const searchResults = await externalBdcCdrsApi.search(
                    start,
                    size,
                    this.searchRequest,
                    ++this.searchCounter
                );

                if (searchResults.searchId === this.searchCounter) {
                    this.items = searchResults.results;
                    this.paging.totalSize = searchResults.totalSize;
                    this.loading = false;
                }
            } catch (e) {
                this.loading = false;
                this.paging.page = 1;
                throw e;
            }
        },

        async page(paging: DataTablePaging) {
            this.paging = { ...paging };

            await this.loadItems();
        },

        async refresh(loadAllItems?: boolean) {
            await this.loadItems(loadAllItems);
        },
    },

    watch: {
        searchRequest: {
            deep: true,
            async handler() {
                this.paging.page = 1;
                try {
                    await this.loadItems();
                } catch (e) {
                    this.$nextTick(() => {
                        throw e;
                    });
                }
            },
        },
    },

    async mounted() {
        this.bdcTeams = await bdcTeamsApi.getAll();
        this.dealers = await dealersApi.list();
        this.partitions = await partitionsApi.list();
        this.users = await usersApi.list();

        await this.loadItems();
    },

    components: {
        CsvDownloadIcon,
        DataTable,
        DateRangePicker,
        EnumField,
        ExternalBdcCdrDataTableRow,
    },
});

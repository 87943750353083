import { EMPTY_SEARCH_REQUEST } from "./incomingEmailSummarySearchUtils";
import {
    IncomingEmailSummaryCountType,
    IncomingEmailSummarySearchOrder,
    IncomingEmailSummarySearchRequest,
} from "@/api/incomingEmails";
import { configStore } from "@/store/config";
import { now } from "@/store/now";
import { cloneObject } from "@/util/cloneUtils";
import { getDate, toDateObject } from "@/util/dateTimeUtils";
import { reactive } from "@/util/reactive";

export interface IncomingEmailSummariesOverviewState {
    readonly searchRequest: IncomingEmailSummarySearchRequest;
    readonly initialized: boolean;
    blacklisted: boolean | null;
    contentDetected: boolean | null;
    user: boolean | null;
    fromName: string | null;
    fromAddress: string | null;
    createdFrom: string | null;
    createdTo: string | null;
    countType: IncomingEmailSummaryCountType;
    sortBy: IncomingEmailSummarySearchOrder;
    readonly todayQuickfilter: IncomingEmailSummarySearchRequest;
    readonly isTodayQuickfilter: boolean;
    readonly undetectedLastSevenDaysQuickfilter: IncomingEmailSummarySearchRequest;
    readonly isUndetectedLastSevenDaysQuickfilter: boolean;
    readonly allQuickfilter: IncomingEmailSummarySearchRequest;
    readonly isAllQuickfilter: boolean;

    tz(): string;
    refresh(): void;
    setTodayQuickfilter(): void;
    setUndetectedLastSevenDaysQuickfilter(): void;
    setAllQuickfilter(): void;
}

@reactive
class IncomingEmailSummariesOverviewStateImpl implements IncomingEmailSummariesOverviewState {
    private searchRequest_: IncomingEmailSummarySearchRequest = EMPTY_SEARCH_REQUEST;
    private initialized_ = false;

    get searchRequest() {
        return this.searchRequest_;
    }

    get initialized() {
        return this.initialized_;
    }

    set blacklisted(blacklisted: boolean | null) {
        this.searchRequest_ = { ...this.searchRequest, blacklisted };
    }

    get blacklisted() {
        return this.searchRequest.blacklisted;
    }

    set contentDetected(contentDetected: boolean | null) {
        this.searchRequest_ = { ...this.searchRequest, contentDetected };
    }

    get contentDetected() {
        return this.searchRequest.contentDetected;
    }

    set user(user: boolean | null) {
        this.searchRequest_ = { ...this.searchRequest, user };
    }

    get user() {
        return this.searchRequest.user;
    }

    set fromName(fromName: string | null) {
        this.searchRequest_ = { ...this.searchRequest, fromName };
    }

    get fromName() {
        return this.searchRequest.fromName;
    }

    set fromAddress(fromAddress: string | null) {
        this.searchRequest_ = { ...this.searchRequest, fromAddress };
    }

    get fromAddress() {
        return this.searchRequest.fromAddress;
    }

    set createdFrom(createdFrom: string | null) {
        this.searchRequest_ = {
            ...this.searchRequest,
            createdFrom: createdFrom ? toDateObject(this.tz(), createdFrom) : null,
        };
    }

    get createdFrom() {
        return this.searchRequest.createdFrom ? getDate(this.searchRequest.createdFrom, this.tz()) : null;
    }

    set createdTo(createdTo: string | null) {
        this.searchRequest_ = {
            ...this.searchRequest,
            createdTo: createdTo ? toDateObject(this.tz(), createdTo, 1) : null,
        };
    }

    get createdTo() {
        return this.searchRequest.createdTo ? getDate(this.searchRequest.createdTo, this.tz(), -1) : null;
    }

    set countType(countType: IncomingEmailSummaryCountType) {
        this.searchRequest_ = { ...this.searchRequest, countType };
    }

    get countType() {
        return this.searchRequest.countType;
    }

    set sortBy(sortBy: IncomingEmailSummarySearchOrder) {
        this.searchRequest_ = { ...this.searchRequest, sortBy };
    }

    get sortBy() {
        return this.searchRequest.sortBy;
    }

    tz() {
        return configStore.configuration.organisationTimeZone;
    }

    refresh() {
        this.searchRequest_ = cloneObject(this.searchRequest);
    }

    get todayQuickfilter() {
        return {
            ...EMPTY_SEARCH_REQUEST,
            createdFrom: toDateObject(this.tz(), getDate(now(), this.tz())),
            createdTo: toDateObject(this.tz(), getDate(now(), this.tz(), 1)),
        };
    }

    setTodayQuickfilter() {
        this.searchRequest_ = this.todayQuickfilter;
        this.initialized_ = true;
    }

    get isTodayQuickfilter() {
        return JSON.stringify(this.todayQuickfilter) === JSON.stringify(this.searchRequest);
    }

    get undetectedLastSevenDaysQuickfilter() {
        return {
            ...EMPTY_SEARCH_REQUEST,
            contentDetected: false,
            createdFrom: toDateObject(this.tz(), getDate(now(), this.tz(), -6)),
            createdTo: toDateObject(this.tz(), getDate(now(), this.tz(), 1)),
        };
    }

    setUndetectedLastSevenDaysQuickfilter() {
        this.searchRequest_ = this.undetectedLastSevenDaysQuickfilter;
    }

    get isUndetectedLastSevenDaysQuickfilter() {
        return JSON.stringify(this.undetectedLastSevenDaysQuickfilter) === JSON.stringify(this.searchRequest);
    }

    get allQuickfilter() {
        return EMPTY_SEARCH_REQUEST;
    }

    setAllQuickfilter() {
        this.searchRequest_ = this.allQuickfilter;
    }

    get isAllQuickfilter() {
        return JSON.stringify(this.allQuickfilter) === JSON.stringify(this.searchRequest);
    }
}

export const incomingEmailSummariesOverviewState: IncomingEmailSummariesOverviewState = new IncomingEmailSummariesOverviewStateImpl();

import { DealerStatistic } from "@/api/dealerStatistics";
import { PartitionSummary } from "@/api/partitions";
import { userSession } from "@/store/userSession";

export enum DealerStatisticSearchOrder {
    PARTITION_ASC = "PARTITION_ASC",
    PARTITION_DESC = "PARTITION_DESC",
    DEALER_ASC = "DEALER_ASC",
    DEALER_DESC = "DEALER_DESC",
    SOFTWARE_LICENSE_DAYS_COVERED_ASC = "SOFTWARE_LICENSE_DAYS_COVERED_ASC",
    SOFTWARE_LICENSE_DAYS_COVERED_DESC = "SOFTWARE_LICENSE_DAYS_COVERED_DESC",
    CLICK_TO_CALL_MINUTES_ASC = "CLICK_TO_CALL_MINUTES_ASC",
    CLICK_TO_CALL_MINUTES_DESC = "CLICK_TO_CALL_MINUTES_DESC",
    CLICK_TO_CALL_MINUTES_COVERED_ASC = "CLICK_TO_CALL_MINUTES_COVERED_ASC",
    CLICK_TO_CALL_MINUTES_COVERED_DESC = "CLICK_TO_CALL_MINUTES_COVERED_DESC",
    CALL_TRACKING_MINUTES_ASC = "CALL_TRACKING_MINUTES_ASC",
    CALL_TRACKING_MINUTES_DESC = "CALL_TRACKING_MINUTES_DESC",
    CALL_TRACKING_MINUTES_COVERED_ASC = "CALL_TRACKING_MINUTES_COVERED_ASC",
    CALL_TRACKING_MINUTES_COVERED_DESC = "CALL_TRACKING_MINUTES_COVERED_DESC",
    OUTGOING_SMS_ASC = "OUTGOING_SMS_ASC",
    OUTGOING_SMS_DESC = "OUTGOING_SMS_DESC",
    OUTGOING_SMS_COVERED_ASC = "OUTGOING_SMS_COVERED_ASC",
    OUTGOING_SMS_COVERED_DESC = "OUTGOING_SMS_COVERED_DESC",
    EXTERNAL_BDC_MINUTES_ASC = "EXTERNAL_BDC_MINUTES_ASC",
    EXTERNAL_BDC_MINUTES_DESC = "EXTERNAL_BDC_MINUTES_DESC",
    EXTERNAL_BDC_MINUTES_COVERED_ASC = "EXTERNAL_BDC_MINUTES_COVERED_ASC",
    EXTERNAL_BDC_MINUTES_COVERED_DESC = "EXTERNAL_BDC_MINUTES_COVERED_DESC",
    INCOMING_WHATSAPP_MESSAGES_ASC = "INCOMING_WHATSAPP_MESSAGES_ASC",
    INCOMING_WHATSAPP_MESSAGES_DESC = "INCOMING_WHATSAPP_MESSAGES_DESC",
    OUTGOING_WHATSAPP_MESSAGES_ASC = "OUTGOING_WHATSAPP_MESSAGES_ASC",
    OUTGOING_WHATSAPP_MESSAGES_DESC = "OUTGOING_WHATSAPP_MESSAGES_DESC",
    WHATSAPP_NUMBERS_ASC = "WHATSAPP_NUMBERS_ASC",
    WHATSAPP_NUMBERS_DESC = "WHATSAPP_NUMBERS_DESC",
    VIDEOCHATS_ASC = "VIDEOCHATS_ASC",
    VIDEOCHATS_DESC = "VIDEOCHATS_DESC",
    ONLINE_USERS_ASC = "ONLINE_USERS_ASC",
    ONLINE_USERS_DESC = "ONLINE_USERS_DESC",
    TOTAL_USERS_ASC = "TOTAL_USERS_ASC",
    TOTAL_USERS_DESC = "TOTAL_USERS_DESC",
    UNDELETED_INVENTORY_VEHICLES_ASC = "UNDELETED_INVENTORY_VEHICLES_ASC",
    UNDELETED_INVENTORY_VEHICLES_DESC = "UNDELETED_INVENTORY_VEHICLES_DESC",
    ACTIVE_INVENTORY_VEHICLE_INQUIRIES_ASC = "ACTIVE_INVENTORY_VEHICLE_INQUIRIES_ASC",
    ACTIVE_INVENTORY_VEHICLE_INQUIRIES_DESC = "ACTIVE_INVENTORY_VEHICLE_INQUIRIES_DESC",
    UNDELETED_CONTACTS_ASC = "UNDELETED_CONTACTS_ASC",
    UNDELETED_CONTACTS_DESC = "UNDELETED_CONTACTS_DESC",
    CASES_OPEN_ASC = "CASES_OPEN_ASC",
    CASES_OPEN_DESC = "CASES_OPEN_DESC",
    CASES_ASSIGNED_ASC = "CASES_ASSIGNED_ASC",
    CASES_ASSIGNED_DESC = "CASES_ASSIGNED_DESC",
    OPPORTUNITIES_OPEN_ASC = "OPPORTUNITIES_OPEN_ASC",
    OPPORTUNITIES_OPEN_DESC = "OPPORTUNITIES_OPEN_DESC",
    OPPORTUNITIES_ASSIGNED_ASC = "OPPORTUNITIES_ASSIGNED_ASC",
    OPPORTUNITIES_ASSIGNED_DESC = "OPPORTUNITIES_ASSIGNED_DESC",
    EMERGENCY_INCIDENTS_OPEN_ASC = "EMERGENCY_INCIDENTS_OPEN_ASC",
    EMERGENCY_INCIDENTS_OPEN_DESC = "EMERGENCY_INCIDENTS_OPEN_DESC",
    CASES_CREATED_BY_PARTITION_USER_ASC = "CASES_CREATED_BY_PARTITION_USER_ASC",
    CASES_CREATED_BY_PARTITION_USER_DESC = "CASES_CREATED_BY_PARTITION_USER_DESC",
    CASES_CREATED_BY_CT_USER_ASC = "CASES_CREATED_BY_CT_USER_ASC",
    CASES_CREATED_BY_CT_USER_DESC = "CASES_CREATED_BY_CT_USER_DESC",
    CASES_CREATED_BY_SYSTEM_ASC = "CASES_CREATED_BY_SYSTEM_ASC",
    CASES_CREATED_BY_SYSTEM_DESC = "CASES_CREATED_BY_SYSTEM_DESC",
    CASES_CREATED_SENTIMENT_HAPPY_PER_CREATED_CASES_ASC = "CASES_CREATED_SENTIMENT_HAPPY_PER_CREATED_CASES_ASC",
    CASES_CREATED_SENTIMENT_HAPPY_PER_CREATED_CASES_DESC = "CASES_CREATED_SENTIMENT_HAPPY_PER_CREATED_CASES_DESC",
    CASES_CREATED_SENTIMENT_UNHAPPY_PER_CREATED_CASES_ASC = "CASES_CREATED_SENTIMENT_UNHAPPY_PER_CREATED_CASES_ASC",
    CASES_CREATED_SENTIMENT_UNHAPPY_PER_CREATED_CASES_DESC = "CASES_CREATED_SENTIMENT_UNHAPPY_PER_CREATED_CASES_DESC",
    OPPORTUNITIES_CREATED_BY_PARTITION_USER_ASC = "OPPORTUNITIES_CREATED_BY_PARTITION_USER_ASC",
    OPPORTUNITIES_CREATED_BY_PARTITION_USER_DESC = "OPPORTUNITIES_CREATED_BY_PARTITION_USER_DESC",
    OPPORTUNITIES_CREATED_BY_CT_USER_ASC = "OPPORTUNITIES_CREATED_BY_CT_USER_ASC",
    OPPORTUNITIES_CREATED_BY_CT_USER_DESC = "OPPORTUNITIES_CREATED_BY_CT_USER_DESC",
    OPPORTUNITIES_CREATED_BY_SYSTEM_ASC = "OPPORTUNITIES_CREATED_BY_SYSTEM_ASC",
    OPPORTUNITIES_CREATED_BY_SYSTEM_DESC = "OPPORTUNITIES_CREATED_BY_SYSTEM_DESC",
    OPPORTUNITIES_CREATED_SENTIMENT_HAPPY_PER_CREATED_OPPORTUNITIES_ASC = "OPPORTUNITIES_CREATED_SENTIMENT_HAPPY_PER_CREATED_OPPORTUNITIES_ASC",
    OPPORTUNITIES_CREATED_SENTIMENT_HAPPY_PER_CREATED_OPPORTUNITIES_DESC = "OPPORTUNITIES_CREATED_SENTIMENT_HAPPY_PER_CREATED_OPPORTUNITIES_DESC",
    OPPORTUNITIES_CREATED_SENTIMENT_UNHAPPY_PER_CREATED_OPPORTUNITIES_ASC = "OPPORTUNITIES_CREATED_SENTIMENT_UNHAPPY_PER_CREATED_OPPORTUNITIES_ASC",
    OPPORTUNITIES_CREATED_SENTIMENT_UNHAPPY_PER_CREATED_OPPORTUNITIES_DESC = "OPPORTUNITIES_CREATED_SENTIMENT_UNHAPPY_PER_CREATED_OPPORTUNITIES_DESC",
    EMERGENCY_INCIDENTS_CREATED_BY_PARTITION_USER_ASC = "EMERGENCY_INCIDENTS_CREATED_BY_PARTITION_USER_ASC",
    EMERGENCY_INCIDENTS_CREATED_BY_PARTITION_USER_DESC = "EMERGENCY_INCIDENTS_CREATED_BY_PARTITION_USER_DESC",
    EMERGENCY_INCIDENTS_CREATED_BY_CT_USER_ASC = "EMERGENCY_INCIDENTS_CREATED_BY_CT_USER_ASC",
    EMERGENCY_INCIDENTS_CREATED_BY_CT_USER_DESC = "EMERGENCY_INCIDENTS_CREATED_BY_CT_USER_DESC",
    EMERGENCY_INCIDENTS_CREATED_BY_SYSTEM_ASC = "EMERGENCY_INCIDENTS_CREATED_BY_SYSTEM_ASC",
    EMERGENCY_INCIDENTS_CREATED_BY_SYSTEM_DESC = "EMERGENCY_INCIDENTS_CREATED_BY_SYSTEM_DESC",
}

type DealerStatisticData = Omit<DealerStatistic, "partitionId" | "dealerId" | "name">;

export interface Statistic extends DealerStatisticData {
    readonly dealerCount: number;
    readonly timeWindowDays: number;
    readonly casesCreated: number;
    readonly opportunitiesCreated: number;
    readonly emergencyIncidentsCreated: number;
    readonly happyPerCreatedCase: number | null;
    readonly unhappyPerCreatedCase: number | null;
    readonly happyPerCreatedOpportunity: number | null;
    readonly unhappyPerCreatedOpportunity: number | null;
    readonly undeletedInventoryVehicles: number;
    readonly undeletedContacts: number;
}

export interface StatisticResult {
    readonly dealerId: string | null;
    readonly name: string | null;
    readonly partitionId: string | null;
    readonly partition: PartitionSummary | null;
    readonly statistic: Statistic;
}

const EMPTY_DEALER_STATISTIC_DATA: DealerStatisticData = {
    activeContracts: 0,
    softwareLicenseDaysCovered: 0,
    onlineUsers: 0,
    totalUsers: 0,
    deletedInventoryVehicles: 0,
    totalInventoryVehicles: 0,
    deletedContacts: 0,
    totalContacts: 0,
    casesOpen: 0,
    casesAssigned: 0,
    opportunitiesOpen: 0,
    opportunitiesAssigned: 0,
    emergencyIncidentsOpen: 0,
    clickToCallMinutes: 0,
    clickToCallMinutesCovered: 0,
    callTrackingMinutes: 0,
    callTrackingMinutesCovered: 0,
    outgoingSms: 0,
    outgoingSmsCovered: 0,
    externalBdcMinutes: 0,
    externalBdcMinutesCovered: 0,
    incomingWhatsAppMessages: 0,
    outgoingWhatsAppMessages: 0,
    videochats: 0,
    whatsAppNumbers: 0,
    activeInventoryVehicleInquiries: 0,
    caseCreationStatistic: {
        createdBySystem: 0,
        createdByCtUser: 0,
        createdByPartitionUser: 0,
    },
    opportunityCreationStatistic: {
        createdBySystem: 0,
        createdByCtUser: 0,
        createdByPartitionUser: 0,
    },
    emergencyIncidentCreationStatistic: {
        createdBySystem: 0,
        createdByCtUser: 0,
        createdByPartitionUser: 0,
    },
    caseSentimentStatistic: {
        happy: 0,
        neutral: 0,
        unhappy: 0,
        unknown: 0,
    },
    opportunitySentimentStatistic: {
        happy: 0,
        neutral: 0,
        unhappy: 0,
        unknown: 0,
    },
};

export function numberCompare(
    a: number | undefined | null,
    b: number | undefined | null,
    direction: "ASC" | "DESC"
): number {
    if ((a ?? null) === (b ?? null)) {
        return 0;
    } else if ((a ?? null) === null) {
        return 1;
    } else if ((b ?? null) === null) {
        return -1;
    }

    return direction === "ASC" ? a! - b! : b! - a!;
}

export function sortStatisticResults<T>(
    items: readonly T[],
    getStatisticResult: (item: T) => StatisticResult,
    sortBy: DealerStatisticSearchOrder
): T[] {
    const direction = sortBy.endsWith("_DESC") ? "DESC" : "ASC";
    const directionModifier = direction === "DESC" ? -1 : 1;

    let sortFn: (a: StatisticResult, b: StatisticResult) => number = () => 0;

    if (sortBy === DealerStatisticSearchOrder.PARTITION_ASC || sortBy === DealerStatisticSearchOrder.PARTITION_DESC) {
        sortFn = (a, b) =>
            (a.partition?.name ?? "").localeCompare(b.partition?.name ?? "", userSession.locale) * directionModifier;
    } else if (sortBy === DealerStatisticSearchOrder.DEALER_ASC || sortBy === DealerStatisticSearchOrder.DEALER_DESC) {
        sortFn = (a, b) => (a.name ?? "").localeCompare(b.name ?? "", userSession.locale) * directionModifier;
    } else if (
        sortBy === DealerStatisticSearchOrder.SOFTWARE_LICENSE_DAYS_COVERED_ASC ||
        sortBy === DealerStatisticSearchOrder.SOFTWARE_LICENSE_DAYS_COVERED_DESC
    ) {
        sortFn = (a, b) =>
            numberCompare(a.statistic.softwareLicenseDaysCovered, b.statistic.softwareLicenseDaysCovered, direction);
    } else if (
        sortBy === DealerStatisticSearchOrder.CLICK_TO_CALL_MINUTES_ASC ||
        sortBy === DealerStatisticSearchOrder.CLICK_TO_CALL_MINUTES_DESC
    ) {
        sortFn = (a, b) => numberCompare(a.statistic.clickToCallMinutes, b.statistic.clickToCallMinutes, direction);
    } else if (
        sortBy === DealerStatisticSearchOrder.CLICK_TO_CALL_MINUTES_COVERED_ASC ||
        sortBy === DealerStatisticSearchOrder.CLICK_TO_CALL_MINUTES_COVERED_DESC
    ) {
        sortFn = (a, b) =>
            numberCompare(a.statistic.clickToCallMinutesCovered, b.statistic.clickToCallMinutesCovered, direction);
    } else if (
        sortBy === DealerStatisticSearchOrder.CALL_TRACKING_MINUTES_ASC ||
        sortBy === DealerStatisticSearchOrder.CALL_TRACKING_MINUTES_DESC
    ) {
        sortFn = (a, b) => numberCompare(a.statistic.callTrackingMinutes, b.statistic.callTrackingMinutes, direction);
    } else if (
        sortBy === DealerStatisticSearchOrder.CALL_TRACKING_MINUTES_COVERED_ASC ||
        sortBy === DealerStatisticSearchOrder.CALL_TRACKING_MINUTES_COVERED_DESC
    ) {
        sortFn = (a, b) =>
            numberCompare(a.statistic.callTrackingMinutesCovered, b.statistic.callTrackingMinutesCovered, direction);
    } else if (
        sortBy === DealerStatisticSearchOrder.OUTGOING_SMS_ASC ||
        sortBy === DealerStatisticSearchOrder.OUTGOING_SMS_DESC
    ) {
        sortFn = (a, b) => numberCompare(a.statistic.outgoingSms, b.statistic.outgoingSms, direction);
    } else if (
        sortBy === DealerStatisticSearchOrder.OUTGOING_SMS_COVERED_ASC ||
        sortBy === DealerStatisticSearchOrder.OUTGOING_SMS_COVERED_DESC
    ) {
        sortFn = (a, b) => numberCompare(a.statistic.outgoingSmsCovered, b.statistic.outgoingSmsCovered, direction);
    } else if (
        sortBy === DealerStatisticSearchOrder.EXTERNAL_BDC_MINUTES_ASC ||
        sortBy === DealerStatisticSearchOrder.EXTERNAL_BDC_MINUTES_DESC
    ) {
        sortFn = (a, b) => numberCompare(a.statistic.externalBdcMinutes, b.statistic.externalBdcMinutes, direction);
    } else if (
        sortBy === DealerStatisticSearchOrder.EXTERNAL_BDC_MINUTES_COVERED_ASC ||
        sortBy === DealerStatisticSearchOrder.EXTERNAL_BDC_MINUTES_COVERED_DESC
    ) {
        sortFn = (a, b) =>
            numberCompare(a.statistic.externalBdcMinutesCovered, b.statistic.externalBdcMinutesCovered, direction);
    } else if (
        sortBy === DealerStatisticSearchOrder.INCOMING_WHATSAPP_MESSAGES_ASC ||
        sortBy === DealerStatisticSearchOrder.INCOMING_WHATSAPP_MESSAGES_DESC
    ) {
        sortFn = (a, b) =>
            numberCompare(a.statistic.incomingWhatsAppMessages, b.statistic.incomingWhatsAppMessages, direction);
    } else if (
        sortBy === DealerStatisticSearchOrder.OUTGOING_WHATSAPP_MESSAGES_ASC ||
        sortBy === DealerStatisticSearchOrder.OUTGOING_WHATSAPP_MESSAGES_DESC
    ) {
        sortFn = (a, b) =>
            numberCompare(a.statistic.outgoingWhatsAppMessages, b.statistic.outgoingWhatsAppMessages, direction);
    } else if (
        sortBy === DealerStatisticSearchOrder.WHATSAPP_NUMBERS_ASC ||
        sortBy === DealerStatisticSearchOrder.WHATSAPP_NUMBERS_DESC
    ) {
        sortFn = (a, b) => numberCompare(a.statistic.whatsAppNumbers, b.statistic.whatsAppNumbers, direction);
    } else if (
        sortBy === DealerStatisticSearchOrder.VIDEOCHATS_ASC ||
        sortBy === DealerStatisticSearchOrder.VIDEOCHATS_DESC
    ) {
        sortFn = (a, b) => numberCompare(a.statistic.videochats, b.statistic.videochats, direction);
    } else if (
        sortBy === DealerStatisticSearchOrder.TOTAL_USERS_ASC ||
        sortBy === DealerStatisticSearchOrder.TOTAL_USERS_DESC
    ) {
        sortFn = (a, b) => numberCompare(a.statistic.totalUsers, b.statistic.totalUsers, direction);
    } else if (
        sortBy === DealerStatisticSearchOrder.ONLINE_USERS_ASC ||
        sortBy === DealerStatisticSearchOrder.ONLINE_USERS_DESC
    ) {
        sortFn = (a, b) => numberCompare(a.statistic.onlineUsers, b.statistic.onlineUsers, direction);
    } else if (
        sortBy === DealerStatisticSearchOrder.UNDELETED_INVENTORY_VEHICLES_ASC ||
        sortBy === DealerStatisticSearchOrder.UNDELETED_INVENTORY_VEHICLES_DESC
    ) {
        sortFn = (a, b) =>
            numberCompare(a.statistic.undeletedInventoryVehicles, b.statistic.undeletedInventoryVehicles, direction);
    } else if (
        sortBy === DealerStatisticSearchOrder.ACTIVE_INVENTORY_VEHICLE_INQUIRIES_ASC ||
        sortBy === DealerStatisticSearchOrder.ACTIVE_INVENTORY_VEHICLE_INQUIRIES_DESC
    ) {
        sortFn = (a, b) =>
            numberCompare(
                a.statistic.activeInventoryVehicleInquiries,
                b.statistic.activeInventoryVehicleInquiries,
                direction
            );
    } else if (
        sortBy === DealerStatisticSearchOrder.UNDELETED_CONTACTS_ASC ||
        sortBy === DealerStatisticSearchOrder.UNDELETED_CONTACTS_DESC
    ) {
        sortFn = (a, b) => numberCompare(a.statistic.undeletedContacts, b.statistic.undeletedContacts, direction);
    } else if (
        sortBy === DealerStatisticSearchOrder.CASES_OPEN_ASC ||
        sortBy === DealerStatisticSearchOrder.CASES_OPEN_DESC
    ) {
        sortFn = (a, b) => numberCompare(a.statistic.casesOpen, b.statistic.casesOpen, direction);
    } else if (
        sortBy === DealerStatisticSearchOrder.CASES_ASSIGNED_ASC ||
        sortBy === DealerStatisticSearchOrder.CASES_ASSIGNED_DESC
    ) {
        sortFn = (a, b) => numberCompare(a.statistic.casesAssigned, b.statistic.casesAssigned, direction);
    } else if (
        sortBy === DealerStatisticSearchOrder.OPPORTUNITIES_OPEN_ASC ||
        sortBy === DealerStatisticSearchOrder.OPPORTUNITIES_OPEN_DESC
    ) {
        sortFn = (a, b) => numberCompare(a.statistic.opportunitiesOpen, b.statistic.opportunitiesOpen, direction);
    } else if (
        sortBy === DealerStatisticSearchOrder.OPPORTUNITIES_ASSIGNED_ASC ||
        sortBy === DealerStatisticSearchOrder.OPPORTUNITIES_ASSIGNED_DESC
    ) {
        sortFn = (a, b) =>
            numberCompare(a.statistic.opportunitiesAssigned, b.statistic.opportunitiesAssigned, direction);
    } else if (
        sortBy === DealerStatisticSearchOrder.EMERGENCY_INCIDENTS_OPEN_ASC ||
        sortBy === DealerStatisticSearchOrder.EMERGENCY_INCIDENTS_OPEN_DESC
    ) {
        sortFn = (a, b) =>
            numberCompare(a.statistic.emergencyIncidentsOpen, b.statistic.emergencyIncidentsOpen, direction);
    } else if (
        sortBy === DealerStatisticSearchOrder.CASES_CREATED_BY_SYSTEM_ASC ||
        sortBy === DealerStatisticSearchOrder.CASES_CREATED_BY_SYSTEM_DESC
    ) {
        sortFn = (a, b) =>
            numberCompare(
                a.statistic.caseCreationStatistic.createdBySystem,
                b.statistic.caseCreationStatistic.createdBySystem,
                direction
            );
    } else if (
        sortBy === DealerStatisticSearchOrder.CASES_CREATED_BY_CT_USER_ASC ||
        sortBy === DealerStatisticSearchOrder.CASES_CREATED_BY_CT_USER_DESC
    ) {
        sortFn = (a, b) =>
            numberCompare(
                a.statistic.caseCreationStatistic.createdByCtUser,
                b.statistic.caseCreationStatistic.createdByCtUser,
                direction
            );
    } else if (
        sortBy === DealerStatisticSearchOrder.CASES_CREATED_BY_PARTITION_USER_ASC ||
        sortBy === DealerStatisticSearchOrder.CASES_CREATED_BY_PARTITION_USER_DESC
    ) {
        sortFn = (a, b) =>
            numberCompare(
                a.statistic.caseCreationStatistic.createdByPartitionUser,
                b.statistic.caseCreationStatistic.createdByPartitionUser,
                direction
            );
    } else if (
        sortBy === DealerStatisticSearchOrder.CASES_CREATED_SENTIMENT_HAPPY_PER_CREATED_CASES_ASC ||
        sortBy === DealerStatisticSearchOrder.CASES_CREATED_SENTIMENT_HAPPY_PER_CREATED_CASES_DESC
    ) {
        sortFn = (a, b) => numberCompare(a.statistic.happyPerCreatedCase, b.statistic.happyPerCreatedCase, direction);
    } else if (
        sortBy === DealerStatisticSearchOrder.CASES_CREATED_SENTIMENT_UNHAPPY_PER_CREATED_CASES_ASC ||
        sortBy === DealerStatisticSearchOrder.CASES_CREATED_SENTIMENT_UNHAPPY_PER_CREATED_CASES_DESC
    ) {
        sortFn = (a, b) =>
            numberCompare(a.statistic.unhappyPerCreatedCase, b.statistic.unhappyPerCreatedCase, direction);
    } else if (
        sortBy === DealerStatisticSearchOrder.OPPORTUNITIES_CREATED_BY_SYSTEM_ASC ||
        sortBy === DealerStatisticSearchOrder.OPPORTUNITIES_CREATED_BY_SYSTEM_DESC
    ) {
        sortFn = (a, b) =>
            numberCompare(
                a.statistic.opportunityCreationStatistic.createdBySystem,
                b.statistic.opportunityCreationStatistic.createdBySystem,
                direction
            );
    } else if (
        sortBy === DealerStatisticSearchOrder.OPPORTUNITIES_CREATED_BY_CT_USER_ASC ||
        sortBy === DealerStatisticSearchOrder.OPPORTUNITIES_CREATED_BY_CT_USER_DESC
    ) {
        sortFn = (a, b) =>
            numberCompare(
                a.statistic.opportunityCreationStatistic.createdByCtUser,
                b.statistic.opportunityCreationStatistic.createdByCtUser,
                direction
            );
    } else if (
        sortBy === DealerStatisticSearchOrder.OPPORTUNITIES_CREATED_BY_PARTITION_USER_ASC ||
        sortBy === DealerStatisticSearchOrder.OPPORTUNITIES_CREATED_BY_PARTITION_USER_DESC
    ) {
        sortFn = (a, b) =>
            numberCompare(
                a.statistic.opportunityCreationStatistic.createdByPartitionUser,
                b.statistic.opportunityCreationStatistic.createdByPartitionUser,
                direction
            );
    } else if (
        sortBy === DealerStatisticSearchOrder.OPPORTUNITIES_CREATED_SENTIMENT_HAPPY_PER_CREATED_OPPORTUNITIES_ASC ||
        sortBy === DealerStatisticSearchOrder.OPPORTUNITIES_CREATED_SENTIMENT_HAPPY_PER_CREATED_OPPORTUNITIES_DESC
    ) {
        sortFn = (a, b) =>
            numberCompare(a.statistic.happyPerCreatedOpportunity, b.statistic.happyPerCreatedOpportunity, direction);
    } else if (
        sortBy === DealerStatisticSearchOrder.OPPORTUNITIES_CREATED_SENTIMENT_UNHAPPY_PER_CREATED_OPPORTUNITIES_ASC ||
        sortBy === DealerStatisticSearchOrder.OPPORTUNITIES_CREATED_SENTIMENT_UNHAPPY_PER_CREATED_OPPORTUNITIES_DESC
    ) {
        sortFn = (a, b) =>
            numberCompare(
                a.statistic.unhappyPerCreatedOpportunity,
                b.statistic.unhappyPerCreatedOpportunity,
                direction
            );
    } else if (
        sortBy === DealerStatisticSearchOrder.EMERGENCY_INCIDENTS_CREATED_BY_SYSTEM_ASC ||
        sortBy === DealerStatisticSearchOrder.EMERGENCY_INCIDENTS_CREATED_BY_SYSTEM_DESC
    ) {
        sortFn = (a, b) =>
            numberCompare(
                a.statistic.emergencyIncidentCreationStatistic.createdBySystem,
                b.statistic.emergencyIncidentCreationStatistic.createdBySystem,
                direction
            );
    } else if (
        sortBy === DealerStatisticSearchOrder.EMERGENCY_INCIDENTS_CREATED_BY_CT_USER_ASC ||
        sortBy === DealerStatisticSearchOrder.EMERGENCY_INCIDENTS_CREATED_BY_CT_USER_DESC
    ) {
        sortFn = (a, b) =>
            numberCompare(
                a.statistic.emergencyIncidentCreationStatistic.createdByCtUser,
                b.statistic.emergencyIncidentCreationStatistic.createdByCtUser,
                direction
            );
    } else if (
        sortBy === DealerStatisticSearchOrder.EMERGENCY_INCIDENTS_CREATED_BY_PARTITION_USER_ASC ||
        sortBy === DealerStatisticSearchOrder.EMERGENCY_INCIDENTS_CREATED_BY_PARTITION_USER_DESC
    ) {
        sortFn = (a, b) =>
            numberCompare(
                a.statistic.emergencyIncidentCreationStatistic.createdByPartitionUser,
                b.statistic.emergencyIncidentCreationStatistic.createdByPartitionUser,
                direction
            );
    }

    return [...items].sort((a, b) => sortFn(getStatisticResult(a), getStatisticResult(b)));
}

export function toStatistic(
    statistic: DealerStatistic | DealerStatisticData,
    dealerCount: number,
    timeWindowDays: number
): Statistic {
    const casesCreated =
        statistic.caseCreationStatistic.createdBySystem +
        statistic.caseCreationStatistic.createdByCtUser +
        statistic.caseCreationStatistic.createdByPartitionUser;

    const opportunitiesCreated =
        statistic.opportunityCreationStatistic.createdBySystem +
        statistic.opportunityCreationStatistic.createdByCtUser +
        statistic.opportunityCreationStatistic.createdByPartitionUser;

    const emergencyIncidentsCreated =
        statistic.emergencyIncidentCreationStatistic.createdBySystem +
        statistic.emergencyIncidentCreationStatistic.createdByCtUser +
        statistic.emergencyIncidentCreationStatistic.createdByPartitionUser;

    return {
        ...{ ...statistic, dealerId: undefined, name: undefined, partitionId: undefined },
        dealerCount,
        timeWindowDays,
        casesCreated,
        opportunitiesCreated,
        emergencyIncidentsCreated,
        happyPerCreatedCase: casesCreated ? statistic.caseSentimentStatistic.happy / casesCreated : null,
        unhappyPerCreatedCase: casesCreated ? statistic.caseSentimentStatistic.unhappy / casesCreated : null,
        happyPerCreatedOpportunity: opportunitiesCreated
            ? statistic.opportunitySentimentStatistic.happy / opportunitiesCreated
            : null,
        unhappyPerCreatedOpportunity: opportunitiesCreated
            ? statistic.opportunitySentimentStatistic.unhappy / opportunitiesCreated
            : null,
        undeletedInventoryVehicles: statistic.totalInventoryVehicles - statistic.deletedInventoryVehicles,
        undeletedContacts: statistic.totalContacts - statistic.deletedContacts,
    };
}

function sumDealerStatistics(statistics: readonly DealerStatisticData[]): DealerStatisticData {
    return statistics.reduce(
        (a, b) => ({
            activeContracts: a.activeContracts + b.activeContracts,
            softwareLicenseDaysCovered: a.softwareLicenseDaysCovered + b.softwareLicenseDaysCovered,
            onlineUsers: a.onlineUsers + b.onlineUsers,
            totalUsers: a.totalUsers + b.totalUsers,
            deletedInventoryVehicles: a.deletedInventoryVehicles + b.deletedInventoryVehicles,
            totalInventoryVehicles: a.totalInventoryVehicles + b.totalInventoryVehicles,
            deletedContacts: a.deletedContacts + b.deletedContacts,
            totalContacts: a.totalContacts + b.totalContacts,
            casesOpen: a.casesOpen + b.casesOpen,
            casesAssigned: a.casesAssigned + b.casesAssigned,
            opportunitiesOpen: a.opportunitiesOpen + b.opportunitiesOpen,
            opportunitiesAssigned: a.opportunitiesAssigned + b.opportunitiesAssigned,
            emergencyIncidentsOpen: a.emergencyIncidentsOpen + b.emergencyIncidentsOpen,
            clickToCallMinutes: a.clickToCallMinutes + b.clickToCallMinutes,
            clickToCallMinutesCovered: a.clickToCallMinutesCovered + b.clickToCallMinutesCovered,
            callTrackingMinutes: a.callTrackingMinutes + b.callTrackingMinutes,
            callTrackingMinutesCovered: a.callTrackingMinutesCovered + b.callTrackingMinutesCovered,
            outgoingSms: a.outgoingSms + b.outgoingSms,
            outgoingSmsCovered: a.outgoingSmsCovered + b.outgoingSmsCovered,
            externalBdcMinutes: a.externalBdcMinutes + b.externalBdcMinutes,
            externalBdcMinutesCovered: a.externalBdcMinutesCovered + b.externalBdcMinutesCovered,
            incomingWhatsAppMessages: a.incomingWhatsAppMessages + b.incomingWhatsAppMessages,
            outgoingWhatsAppMessages: a.outgoingWhatsAppMessages + b.outgoingWhatsAppMessages,
            videochats: a.videochats + b.videochats,
            whatsAppNumbers: a.whatsAppNumbers + b.whatsAppNumbers,
            activeInventoryVehicleInquiries: a.activeInventoryVehicleInquiries + b.activeInventoryVehicleInquiries,
            caseCreationStatistic: {
                createdBySystem: a.caseCreationStatistic.createdBySystem + b.caseCreationStatistic.createdBySystem,
                createdByCtUser: a.caseCreationStatistic.createdByCtUser + b.caseCreationStatistic.createdByCtUser,
                createdByPartitionUser:
                    a.caseCreationStatistic.createdByPartitionUser + b.caseCreationStatistic.createdByPartitionUser,
            },
            opportunityCreationStatistic: {
                createdBySystem:
                    a.opportunityCreationStatistic.createdBySystem + b.opportunityCreationStatistic.createdBySystem,
                createdByCtUser:
                    a.opportunityCreationStatistic.createdByCtUser + b.opportunityCreationStatistic.createdByCtUser,
                createdByPartitionUser:
                    a.opportunityCreationStatistic.createdByPartitionUser +
                    b.opportunityCreationStatistic.createdByPartitionUser,
            },
            emergencyIncidentCreationStatistic: {
                createdBySystem:
                    a.emergencyIncidentCreationStatistic.createdBySystem +
                    b.emergencyIncidentCreationStatistic.createdBySystem,
                createdByCtUser:
                    a.emergencyIncidentCreationStatistic.createdByCtUser +
                    b.emergencyIncidentCreationStatistic.createdByCtUser,
                createdByPartitionUser:
                    a.emergencyIncidentCreationStatistic.createdByPartitionUser +
                    b.emergencyIncidentCreationStatistic.createdByPartitionUser,
            },
            caseSentimentStatistic: {
                happy: a.caseSentimentStatistic.happy + b.caseSentimentStatistic.happy,
                neutral: a.caseSentimentStatistic.neutral + b.caseSentimentStatistic.neutral,
                unhappy: a.caseSentimentStatistic.unhappy + b.caseSentimentStatistic.unhappy,
                unknown: a.caseSentimentStatistic.unknown + b.caseSentimentStatistic.unknown,
            },
            opportunitySentimentStatistic: {
                happy: a.opportunitySentimentStatistic.happy + b.opportunitySentimentStatistic.happy,
                neutral: a.opportunitySentimentStatistic.neutral + b.opportunitySentimentStatistic.neutral,
                unhappy: a.opportunitySentimentStatistic.unhappy + b.opportunitySentimentStatistic.unhappy,
                unknown: a.opportunitySentimentStatistic.unknown + b.opportunitySentimentStatistic.unknown,
            },
        }),
        EMPTY_DEALER_STATISTIC_DATA
    );
}

export function sumStatisticResults(results: readonly StatisticResult[]): StatisticResult {
    if (results.length === 1) {
        return results[0];
    }

    const normalized = [...results]
        .sort((a, b) => (a.partitionId ?? "").localeCompare(b.partitionId ?? ""))
        .map((result, index, array) =>
            // keep partition values exactly once
            !index || array[index - 1].partitionId !== result.partitionId
                ? result.statistic
                : {
                      ...result.statistic,
                      deletedContacts: 0,
                      totalContacts: 0,
                      undeletedContacts: 0,
                  }
        );

    const isOnePartition = new Set(results.map((result) => result.partitionId)).size === 1;

    return {
        dealerId: null,
        name: null,
        partitionId: isOnePartition ? results.find((r) => r.partitionId)?.partitionId ?? null : null,
        partition: isOnePartition ? results.find((r) => r.partition)?.partition ?? null : null,
        statistic: toStatistic(
            sumDealerStatistics(normalized),
            results.reduce((sum, cur) => sum + cur.statistic.dealerCount, 0),
            results.reduce((sum, cur) => sum + cur.statistic.timeWindowDays, 0)
        ),
    };
}

import { userSession } from "@/store/userSession";
import axios from "axios";

axios.interceptors.request.use((config) => {
    const method = config.method;
    if (method === "get" || method === "GET" || method === "head" || method === "HEAD") {
        return config;
    }
    const csrfToken = userSession.csrfToken;
    if (csrfToken) {
        config.headers["X-Csrf-Token"] = csrfToken;
    }
    return config;
});

export type DataHandler<T> = (data: T) => void;
export type ConnectionHandler = (connected: boolean) => void;
export type DataMapper<T> = (data: any) => T;

export class ReconnectingEventSource<T> {
    private dataHandlers: Set<DataHandler<T>> = new Set();
    private connectionHandlers: Set<ConnectionHandler> = new Set();
    private eventSource?: EventSource;
    private pendingReconnect?: number;
    private pendingHeartbeatMissed?: number;

    constructor(private url: string, private dataMapper: DataMapper<T> = (data) => data) {}

    connect() {
        if (!this.eventSource) {
            this.eventSource = new EventSource(this.url, { withCredentials: true });
            this.eventSource.onopen = () => {
                this.registerHeartbeatMissedHandler();
                this.notifyConnectionHandlers(true);
            };
            this.eventSource.onmessage = (e) => {
                if (e.data) {
                    this.notifyDataHandlers(JSON.parse(e.data));
                } else {
                    this.deregisterHeartbeatMissedHandler();
                    this.registerHeartbeatMissedHandler();
                }
            };
            this.eventSource.onerror = () => {
                this.disconnect();
                this.pendingReconnect = setTimeout(() => this.connect(), 2000);
            };
        }
    }

    disconnect() {
        this.deregisterHeartbeatMissedHandler();
        if (this.pendingReconnect !== undefined) {
            clearTimeout(this.pendingReconnect);
            this.pendingReconnect = undefined;
        }
        if (this.eventSource) {
            this.eventSource.close();
            this.eventSource = undefined;
            this.notifyConnectionHandlers(false);
        }
    }

    addDataHandler(dataHandler: DataHandler<T>) {
        this.dataHandlers.add(dataHandler);
        return dataHandler;
    }

    removeDataHandler(dataHandler: DataHandler<T>) {
        return this.dataHandlers.delete(dataHandler);
    }

    addConnectionHandler(connectionHandler: ConnectionHandler) {
        this.connectionHandlers.add(connectionHandler);
        return connectionHandler;
    }

    removeConnectionHandler(connectionHandler: ConnectionHandler) {
        return this.connectionHandlers.delete(connectionHandler);
    }

    private registerHeartbeatMissedHandler() {
        this.pendingHeartbeatMissed = setTimeout(() => {
            // heartbeat not received in time, reconnect to event source
            this.disconnect();
            this.connect();
        }, 15000);
    }

    private deregisterHeartbeatMissedHandler() {
        if (this.pendingHeartbeatMissed !== undefined) {
            clearTimeout(this.pendingHeartbeatMissed);
            this.pendingHeartbeatMissed = undefined;
        }
    }

    private notifyDataHandlers(data: any) {
        data = this.dataMapper(data);
        for (const dataHandler of this.dataHandlers) {
            try {
                dataHandler(data);
            } catch (e) {
                // ignore
            }
        }
    }

    private notifyConnectionHandlers(connected: boolean) {
        for (const connectionHandler of this.connectionHandlers) {
            try {
                connectionHandler(connected);
            } catch (e) {
                // ignore
            }
        }
    }
}

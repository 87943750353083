
import { InvoiceReminderEmailForm, InvoiceReminderEmailPreview, invoicesApi } from "@/api/invoices";
import EmailAddressesField from "@/app/components/EmailAddressesField.vue";
import { writeHtmlToIframe } from "@/app/emailUtils";
import { showInfo } from "@/app/messageUtil";
import { notEmpty } from "@/app/validation";
import { userSession } from "@/store/userSession";
import { Mutable } from "@/util/types";
import Vue from "vue";

type InvoiceReminderEmailPreviewWithInvoiceId = InvoiceReminderEmailPreview & {
    readonly invoiceId: number;
};

export default Vue.extend({
    props: {
        invoiceIds: {
            type: Array as () => number[],
            required: true,
        },
    },

    data() {
        return {
            invoiceReminderEmailForm: {
                bccReceivers: [],
                subject: this.$t(`email.invoice.reminder.subject`),
                htmlBody: this.$t(`email.invoice.reminder.content`),
                locale: userSession.locale,
            } as Mutable<InvoiceReminderEmailForm>,
            invoiceReminderEmailPreview: null as InvoiceReminderEmailPreviewWithInvoiceId | null,
            loadingIframe: false,
            notEmpty,
            working: false,
        };
    },

    methods: {
        hidePreview() {
            (this.$refs.iframe as HTMLIFrameElement).src = "/empty.html";
            this.invoiceReminderEmailPreview = null;
        },

        async preview() {
            if (!(this.$refs.form as any).validate()) {
                return;
            }

            this.working = true;
            try {
                const invoiceId = this.invoiceIds[0];

                const invoiceReminderEmailPreview = await invoicesApi.previewReminderEmail(
                    invoiceId,
                    this.invoiceReminderEmailForm
                );

                if (!invoiceReminderEmailPreview) {
                    return;
                }

                this.invoiceReminderEmailPreview = { invoiceId, ...invoiceReminderEmailPreview };

                // wait such that the card is visible and the iframe gets the correct height
                await this.$nextTick();

                writeHtmlToIframe(this.$refs.iframe as HTMLIFrameElement, this.invoiceReminderEmailPreview!.htmlBody);
            } finally {
                this.working = false;
            }
        },

        setBccReceivers(validAddresses: string[], invalidAddresses: string[]) {
            if (invalidAddresses.length) {
                showInfo(this.$t("{0} ist keine gültige E-Mail Adresse.", [invalidAddresses[0]]) as string);
                this.invoiceReminderEmailForm.bccReceivers = [...this.invoiceReminderEmailForm.bccReceivers];
                return;
            }

            this.invoiceReminderEmailForm.bccReceivers = validAddresses;
        },

        async submit() {
            if (!(this.$refs.form as any).validate()) {
                return;
            }

            this.$emit("send", this.invoiceIds, this.invoiceReminderEmailForm);
        },
    },

    components: {
        EmailAddressesField,
        WysiwygEditor: () => import("@/app/components/WysiwygEditor.vue"),
    },
});

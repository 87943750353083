
import ContractCompensationTable from "./ContractCompensationTable.vue";
import ContractFormDialog from "./ContractFormDialog.vue";
import { BillingAccount } from "@/api/billingAccounts";
import { Contract } from "@/api/contracts";
import { Dealer } from "@/api/dealers";
import { Permission } from "@/api/userSession";
import { configStore } from "@/store/config";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { toDateObject } from "@/util/dateTimeUtils";
import Vue from "vue";

enum ContractStatus {
    UPCOMING,
    ACTIVE,
    EXPIRED,
}

export default Vue.extend({
    props: {
        billingAccounts: {
            type: Array as () => BillingAccount[],
            required: true,
        },
        contract: {
            type: Object as () => Contract,
            required: true,
        },
        dealer: {
            type: Object as () => Dealer,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            contractFormDialogVisible: false,
            ContractStatus,
            now: now(),
        };
    },

    computed: {
        canManageContracts(): boolean {
            return userSession.hasPermission(Permission.CT_MANAGE_CONTRACTS);
        },

        commissionBillingAccount(): BillingAccount | null {
            if (!this.contract.commissionBillingAccountId) {
                return null;
            }

            return this.billingAccounts.find((b) => b.id === this.contract.commissionBillingAccountId) ?? null;
        },

        contractStatus(): ContractStatus {
            const contractBegin = toDateObject(this.timeZone, this.contract.begin);
            const contractEnd = this.contract.end ? toDateObject(this.timeZone, this.contract.end, 1) : null;

            return contractEnd && contractEnd.getTime() < this.now.getTime()
                ? ContractStatus.EXPIRED
                : this.now.getTime() < contractBegin.getTime()
                ? ContractStatus.UPCOMING
                : ContractStatus.ACTIVE;
        },

        contractStatusColor(): string | null {
            if (this.contractStatus === ContractStatus.UPCOMING) {
                return "warning";
            } else if (this.contractStatus === ContractStatus.ACTIVE) {
                return "success";
            } else if (this.contractStatus === ContractStatus.EXPIRED) {
                return "error";
            } else {
                return null;
            }
        },

        contractStatusText(): string {
            if (this.contractStatus === ContractStatus.UPCOMING) {
                return this.$t("bevorstehend") as string;
            } else if (this.contractStatus === ContractStatus.ACTIVE) {
                return this.$t("aktiv") as string;
            } else if (this.contractStatus === ContractStatus.EXPIRED) {
                return this.$t("abgelaufen") as string;
            } else {
                return this.$t("Unbekannt") as string;
            }
        },

        timeZone(): string {
            return configStore.configuration.organisationTimeZone;
        },
    },

    methods: {
        savedContract() {
            this.contractFormDialogVisible = false;
            this.$emit("saved");
        },
    },

    components: {
        ContractCompensationTable,
        ContractFormDialog,
    },
});


import AgentMonthDataTable from "./AgentMonthDataTable.vue";
import MonthPicker from "@/app/components/MonthPicker.vue";
import { userSession } from "@/store/userSession";
import { addDuration, getCurrentMonth, toDateObject, UnitOfTime } from "@/util/dateTimeUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        includeCallCenterStatistics: {
            type: Boolean,
            default: false,
        },
        isAgent: {
            type: Boolean,
            required: false,
        },
        userFullName: {
            type: String as () => string | null,
            default: null,
        },
        userId: {
            type: String,
            required: true,
        },
        yearMonth: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            includeCallCenterStatisticsProp: this.includeCallCenterStatistics,
            yearMonthProp: this.yearMonth,
        };
    },

    computed: {
        timeZone(): string {
            return userSession.timeZone;
        },
    },

    methods: {
        addMonths(offset: number) {
            this.yearMonthProp = [this.yearMonthProp]
                .map((yearMonth) => toDateObject(this.timeZone, `${yearMonth}-01`))
                .map((firstOfMonth) => addDuration(firstOfMonth, this.timeZone, offset, UnitOfTime.MONTH))
                .map((date) => getCurrentMonth(date, this.timeZone))
                .pop()!;
        },
    },

    watch: {
        yearMonth() {
            this.yearMonthProp = this.yearMonth;
        },
    },

    components: {
        AgentMonthDataTable,
        MonthPicker,
    },
});

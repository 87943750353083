
import ActionAgentContactAndIssueCreatedScatterTile from "./action/ActionAgentContactAndIssueCreatedScatterTile.vue";
import ActionAgentErrorRateScatterTile from "./action/ActionAgentErrorRateScatterTile.vue";
import ActionAgentNoteCreatedScatterTile from "./action/ActionAgentNoteCreatedScatterTile.vue";
import ActionByFeedbackTypeTile from "./action/ActionByFeedbackTypeTile.vue";
import ActionFeedbackCountTile from "./action/ActionFeedbackCountTile.vue";
import ActionFeedbackTypeByGroupTile from "./action/ActionFeedbackTypeByGroupTile.vue";
import DutyRosterInShiftCountTimeSeriesTile from "./dutyroster/DutyRosterInShiftCountTimeSeriesTile.vue";
import ExternalBdcAcceptanceRateTile from "./externalbdc/ExternalBdcAcceptanceRateTile.vue";
import ExternalBdcAcceptanceRateTimeSeriesTile from "./externalbdc/ExternalBdcAcceptanceRateTimeSeriesTile.vue";
import ExternalBdcAgentCallCountScatterTile from "./externalbdc/ExternalBdcAgentCallCountScatterTile.vue";
import ExternalBdcAgentCallDurationScatterTile from "./externalbdc/ExternalBdcAgentCallDurationScatterTile.vue";
import ExternalBdcAgentCallOverlapScatterTile from "./externalbdc/ExternalBdcAgentCallOverlapScatterTile.vue";
import ExternalBdcAgentPerformanceScatterTile from "./externalbdc/ExternalBdcAgentPerformanceScatterTile.vue";
import ExternalBdcAgentShortCallDurationCountBarTile from "./externalbdc/ExternalBdcAgentShortCallDurationCountBarTile.vue";
import ExternalBdcBilledMinutesTile from "./externalbdc/ExternalBdcBilledMinutesTile.vue";
import ExternalBdcCallDisconnectReasonByGroupBarTile from "./externalbdc/ExternalBdcCallDisconnectReasonByGroupBarTile.vue";
import ExternalBdcCallStatusTile from "./externalbdc/ExternalBdcCallStatusTile.vue";
import ExternalBdcDurationTile from "./externalbdc/ExternalBdcDurationTile.vue";
import ExternalBdcOngoingCallTimeSeriesTile from "./externalbdc/ExternalBdcOngoingCallTimeSeriesTile.vue";
import ExternalBdcWaitedTile from "./externalbdc/ExternalBdcWaitedTile.vue";
import { BdcTeam, bdcTeamsApi } from "@/api/bdcTeams";
import { Dealer, dealersApi } from "@/api/dealers";
import { DutyRosterEntryType, DutyRosterEntryWithUserId, dutyRostersApi } from "@/api/dutyRosters";
import { ExternalBdcCdr, externalBdcCdrsApi, ExternalBdcCdrSearchOrder } from "@/api/externalBdcCdrs";
import { partitionsApi, PartitionSummary } from "@/api/partitions";
import { ActionRow, reportingApi } from "@/api/reporting";
import { UserOnlinePeriod, userTrackingApi } from "@/api/userTracking";
import { User, usersApi } from "@/api/users";
import DateRangePicker from "@/app/components/DateRangePicker.vue";
import NumberField from "@/app/components/NumberField.vue";
import { DateRange } from "@/app/components/dateRangePicker";
import {
    withCreatedSecond as withActionCreatedSecond,
    WithCreatedSecond as WithActionCreatedSecond,
} from "@/app/pages/reporting/action/actionRowUtils";
import {
    AnswerIncomingCallsShift,
    DutyRosterRow,
    toAnswerIncomingCallsShift,
    toDutyRosterRow,
    withBeginSecond as withDutyRosterBeginSecond,
    WithBeginSecond as WithDutyRosterBeginSecond,
    WithDuration as WithDutyRosterDuration,
    withDuration as withDutyRosterDuration,
    WithElapsedDuration as WithDutyRosterElapsedDuration,
    withElapsedDuration as withDutyRosterElapsedDuration,
    withEndSecond as withDutyRosterEndSecond,
    WithEndSecond as WithDutyRosterEndSecond,
} from "@/app/pages/reporting/dutyroster/dutyRosterRowUtils";
import {
    withAcceptSecond as withExternalBdcCdrAcceptSecond,
    WithAcceptSecond as WithExternalBdcCdrAcceptSecond,
    withBeginSecond as withExternalBdcCdrBeginSecond,
    WithBeginSecond as WithExternalBdcCdrBeginSecond,
    withBilledMinutes as withExternalBdcCdrBilledMinutes,
    WithBilledMinutes as WithExternalBdcCdrBilledMinutes,
    withDuration as withExternalBdcCdrDuration,
    WithDuration as WithExternalBdcCdrDuration,
    withEndSecond as withExternalBdcCdrEndSecond,
    WithEndSecond as WithExternalBdcCdrEndSecond,
    withPartitionId as withExternalBdcCdrPartitionId,
    WithPartitionId as WithExternalBdcCdrPartitionId,
    withWaited as withExternalBdcCdrWaited,
    WithWaited as WithExternalBdcCdrWaited,
} from "@/app/pages/reporting/externalbdc/externalBdcRowUtils";
import {
    getEndOfRelativeTimeSlot,
    getStartOfRelativeTimeSlot,
    OngoingTimeInterval,
    RelativeTimeInterval,
} from "@/app/pages/reporting/timeInterval";
import { getFullName } from "@/app/userUtils";
import { notEmpty } from "@/app/validation";
import { configStore } from "@/store/config";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { groupByAsMap } from "@/util/arrayUtils";
import { addDuration, endOf, getDate, startOf, toDateObject, UnitOfTime } from "@/util/dateTimeUtils";
import { ActionLimiter } from "@/util/debounce";
import { SelectOption } from "@/util/types";
import Vue from "vue";
import { TranslateResult } from "vue-i18n";

type ComputedActionRow = ActionRow & WithActionCreatedSecond;

type ComputedDutyRosterRow = DutyRosterRow &
    WithDutyRosterBeginSecond &
    WithDutyRosterDuration &
    WithDutyRosterElapsedDuration &
    WithDutyRosterEndSecond;

type ComputedExternalBdcCdrRow = ExternalBdcCdr &
    WithExternalBdcCdrAcceptSecond &
    WithExternalBdcCdrBeginSecond &
    WithExternalBdcCdrBilledMinutes &
    WithExternalBdcCdrDuration &
    WithExternalBdcCdrEndSecond &
    WithExternalBdcCdrPartitionId &
    WithExternalBdcCdrWaited;

interface QuickFilter {
    readonly label: TranslateResult;
    readonly from: string;
    readonly to: string;
}

export default Vue.extend({
    data() {
        const ts = now();
        const timeZone = userSession.timeZone;

        return {
            DutyRosterEntryType,
            OngoingTimeInterval,
            notEmpty,
            actionRows: [] as readonly ActionRow[],
            bdcTeams: [] as readonly BdcTeam[],
            callShortDurationThresholdSeconds: 5,
            dealers: [] as Dealer[],
            dutyRosterEntries: [] as readonly DutyRosterEntryWithUserId[],
            externalBdcCdrImportDelayMinutes: 3,
            externalBdcCdrs: [] as readonly ExternalBdcCdr[],
            loadFilter: {
                dateRange: {
                    from: getDate(ts, timeZone),
                    to: getDate(ts, timeZone),
                } as DateRange,
            },
            loading: true,
            loadLimiter: new ActionLimiter(true),
            localFilter: {
                bdcTeamIds: [] as string[],
                dealerIds: [] as string[],
                partitionIds: [] as string[],
                userIds: [] as (string | null)[],
            },
            nowAtLoadRows: ts as Date,
            partitions: [] as PartitionSummary[],
            performanceMinutes: 60,
            searchId: 0,
            timeZone,
            users: [] as User[],
            userOnlinePeriods: [] as readonly UserOnlinePeriod[],
            waitingTimeThresholdSecondsForConsideringUnansweredCalls:
                configStore.configuration.waitingTimeThresholdSecondsForConsideringUnansweredCalls,
        };
    },

    computed: {
        agentIds(): string[] {
            return [
                ...new Set([
                    ...this.dutyRosterEntries.map((row) => row.userId!),
                    ...this.externalBdcCdrs.filter((row) => row.userId).map((row) => row.userId!),
                ]),
            ];
        },

        arePerformanceTilesVisible(): boolean {
            const to = toDateObject(this.timeZone, this.loadFilter.dateRange.to, 1);

            return this.performanceFromDate.getTime() < to.getTime();
        },

        answerIncomingCallsShifts(): readonly AnswerIncomingCallsShift<ComputedDutyRosterRow>[] {
            return [...groupByAsMap(this.computedDutyRosterRows, (r) => r.userId).entries()]
                .map((e) => toAnswerIncomingCallsShift(e[0], e[1]))
                .filter((s): s is AnswerIncomingCallsShift<ComputedDutyRosterRow> => !!s)
                .map((s) => Object.freeze(s));
        },

        bdcTeamOptions(): SelectOption[] {
            return this.bdcTeams.map((bdcTeam) => ({
                text: bdcTeam.name,
                value: bdcTeam.id,
            }));
        },

        computedActionRows(): readonly ComputedActionRow[] {
            return this.actionRows.map(withActionCreatedSecond).map((r) => Object.freeze(r));
        },

        computedDutyRosterRows(): readonly ComputedDutyRosterRow[] {
            return this.dutyRosterEntries
                .map((e) => toDutyRosterRow(e.entry, e.userId))
                .map(withDutyRosterBeginSecond)
                .map(withDutyRosterEndSecond)
                .map(withDutyRosterDuration)
                .map((r) => withDutyRosterElapsedDuration(r, this.nowAtLoadRows))
                .map((r) => Object.freeze(r));
        },

        computedExternalBdcCdrRows(): readonly ComputedExternalBdcCdrRow[] {
            return [this.externalBdcCdrs]
                .map((rows) => withExternalBdcCdrPartitionId(rows, this.dealers))
                .pop()!
                .map(withExternalBdcCdrAcceptSecond)
                .map(withExternalBdcCdrBeginSecond)
                .map(withExternalBdcCdrEndSecond)
                .map(withExternalBdcCdrDuration)
                .map(withExternalBdcCdrWaited)
                .map(withExternalBdcCdrBilledMinutes)
                .filter((row) => row.end.getTime() - row.begin.getTime() < 60 * 60 * 1000)
                .map((r) => Object.freeze(r));
        },

        dateRangeFromDate(): Date {
            return toDateObject(this.timeZone, this.loadFilter.dateRange.from);
        },

        dateRangeToDate(): Date {
            return toDateObject(this.timeZone, this.loadFilter.dateRange.from, 1);
        },

        dealerOptions(): SelectOption[] {
            return this.dealers
                .map((d) => ({ value: d.id, text: d.name }))
                .sort((a, b) => a.text.localeCompare(b.text, userSession.locale));
        },

        filteredComputedActionRows(): readonly ComputedActionRow[] {
            return this.computedActionRows
                .filter(
                    (r) =>
                        !this.localFilter.bdcTeamIds.length || this.memberUserIdsOfSelectedBdcTeams.includes(r.userId)
                )
                .filter(
                    (r) =>
                        !this.localFilter.dealerIds.length ||
                        (!!r.dealerId && this.localFilter.dealerIds.includes(r.dealerId))
                )
                .filter(
                    (r) =>
                        !this.localFilter.partitionIds.length ||
                        (!!r.partitionId && this.localFilter.partitionIds.includes(r.partitionId))
                )
                .filter((r) => !this.localFilter.userIds.length || this.localFilter.userIds.includes(r.userId));
        },

        filteredAnswerIncomingCallsShifts(): readonly AnswerIncomingCallsShift<ComputedDutyRosterRow>[] {
            return this.answerIncomingCallsShifts
                .filter(
                    (r) =>
                        !this.localFilter.bdcTeamIds.length || this.memberUserIdsOfSelectedBdcTeams.includes(r.userId)
                )
                .filter((s) => !this.localFilter.userIds.length || this.localFilter.userIds.includes(s.userId));
        },

        filteredComputedExternalBdcCdrRows(): readonly ComputedExternalBdcCdrRow[] {
            return this.computedExternalBdcCdrRows
                .filter((r) => !!r.accept || this.waitingTimeThresholdSecondsForConsideringUnansweredCalls <= r.waited)
                .filter(
                    (r) =>
                        !this.localFilter.bdcTeamIds.length ||
                        (!!r.userId && this.memberUserIdsOfSelectedBdcTeams.includes(r.userId))
                )
                .filter(
                    (r) =>
                        !this.localFilter.dealerIds.length ||
                        (!!r.dealerId && this.localFilter.dealerIds.includes(r.dealerId))
                )
                .filter(
                    (r) =>
                        !this.localFilter.partitionIds.length ||
                        (!!r.partitionId && this.localFilter.partitionIds.includes(r.partitionId))
                )
                .filter(
                    (r) => !r.accept || !this.localFilter.userIds.length || this.localFilter.userIds.includes(r.userId)
                );
        },

        maxDateRangeTo(): string {
            return getDate(this.maxDateRangeToDate, this.timeZone);
        },

        maxDateRangeToDate(): Date {
            return endOf(this.nowAtLoadRows, this.timeZone, userSession.locale, UnitOfTime.DAY);
        },

        memberUserIdsOfSelectedBdcTeams(): string[] {
            return this.bdcTeams
                .filter((bdcTeam) => this.localFilter.bdcTeamIds.includes(bdcTeam.id))
                .map((bdcTeam) => bdcTeam.memberUserIds)
                .reduce((prev, cur) => prev.concat(cur), []);
        },

        partitionOptions(): SelectOption[] {
            return this.partitions
                .map((p) => ({ value: p.id, text: p.name }))
                .sort((a, b) => a.text.localeCompare(b.text, userSession.locale));
        },

        performanceFromDate(): Date {
            return addDuration(this.performanceToDate, this.timeZone, -1 * this.performanceMinutes, UnitOfTime.MINUTE);
        },

        performanceFromDateTimeSeries(): Date {
            return startOf(this.nowAtLoadRows, this.timeZone, userSession.locale, UnitOfTime.DAY);
        },

        performanceToDate(): Date {
            return addDuration(
                this.nowAtLoadRows,
                this.timeZone,
                -1 * this.externalBdcCdrImportDelayMinutes,
                UnitOfTime.MINUTE
            );
        },

        quickFilters(): QuickFilter[] {
            const ts = this.nowAtLoadRows;

            return [
                { label: this.$t("Heute"), interval: RelativeTimeInterval.TODAY },
                { label: this.$t("Gestern"), interval: RelativeTimeInterval.YESTERDAY },
                { label: this.$t("Aktuelle Woche"), interval: RelativeTimeInterval.CURRENT_WEEK },
                { label: this.$t("Letzte Woche"), interval: RelativeTimeInterval.PREVIOUS_WEEK },
                { label: this.$t("Aktueller Monat"), interval: RelativeTimeInterval.CURRENT_MONTH },
                { label: this.$t("Letzter Monat"), interval: RelativeTimeInterval.PREVIOUS_MONTH },
            ].map((i) => {
                const endOfTimeSlot = getEndOfRelativeTimeSlot(ts, i.interval)!;

                return {
                    label: i.label,
                    from: getDate(getStartOfRelativeTimeSlot(ts, i.interval)!, this.timeZone),
                    to: getDate(
                        this.maxDateRangeToDate.getTime() < endOfTimeSlot.getTime()
                            ? this.maxDateRangeToDate
                            : endOfTimeSlot,
                        this.timeZone
                    ),
                };
            });
        },

        userOptions(): SelectOption[] {
            return [
                {
                    value: null,
                    text: this.$t("Unbekannter Benutzer"),
                },
                ...this.users
                    .map((u) => ({ value: u.id, text: getFullName(u) }))
                    .sort((a, b) => a.text.localeCompare(b.text, userSession.locale)),
            ];
        },
    },

    methods: {
        isDateRangeSelected(quickFilter: QuickFilter) {
            return (
                this.loadFilter.dateRange.from === quickFilter.from && this.loadFilter.dateRange.to === quickFilter.to
            );
        },

        async loadRows() {
            this.nowAtLoadRows = now();
            this.actionRows = [];
            this.dutyRosterEntries = [];
            this.externalBdcCdrs = [];
            this.loading = true;
            const searchId = ++this.searchId;

            await this.loadLimiter.execute(async () => {
                try {
                    const from = toDateObject(this.timeZone, this.loadFilter.dateRange.from);
                    const to = toDateObject(this.timeZone, this.loadFilter.dateRange.to, 1);

                    const [actionRows, dutyRosterEntries, externalBdcCdrsResult, userOnlinePeriods] = await Promise.all(
                        [
                            reportingApi.actionRows([], [], from, to),
                            dutyRostersApi.getEntries(getDate(from, this.timeZone), getDate(to, this.timeZone)),
                            externalBdcCdrsApi.search(
                                0,
                                1_000_000,
                                {
                                    bdcTeamIds: [],
                                    userIds: [],
                                    dealerIds: [],
                                    accepted: null,
                                    beginFrom: from,
                                    beginTo: to,
                                    sortBy: ExternalBdcCdrSearchOrder.BEGIN_ASC,
                                },
                                searchId
                            ),
                            userTrackingApi.getUserOnlinePeriods(from, to),
                        ]
                    );

                    if (searchId === this.searchId) {
                        this.actionRows = Object.freeze(actionRows);
                        this.dutyRosterEntries = Object.freeze(dutyRosterEntries);
                        this.externalBdcCdrs = Object.freeze(externalBdcCdrsResult.results);
                        this.userOnlinePeriods = Object.freeze(userOnlinePeriods);
                    }
                } finally {
                    if (searchId === this.searchId) {
                        this.loading = false;
                    }
                }
            });
        },

        async refresh() {
            await this.loadRows();
        },

        selectDateRange(quickFilter: QuickFilter): void {
            this.loadFilter = {
                ...this.loadFilter,
                dateRange: { from: quickFilter.from, to: quickFilter.to },
            };
        },
    },

    watch: {
        loadFilter: {
            deep: true,
            async handler() {
                try {
                    await this.loadRows();
                } catch (e) {
                    this.$nextTick(() => {
                        throw e;
                    });
                }
            },
        },
    },

    async mounted() {
        this.bdcTeams = await bdcTeamsApi.getAll();
        this.dealers = await dealersApi.list();
        this.partitions = await partitionsApi.list();
        this.users = await usersApi.list();

        await this.loadRows();
    },

    components: {
        ActionAgentContactAndIssueCreatedScatterTile,
        ActionAgentErrorRateScatterTile,
        ActionAgentNoteCreatedScatterTile,
        ActionByFeedbackTypeTile,
        ActionFeedbackCountTile,
        ActionFeedbackTypeByGroupTile,
        DateRangePicker,
        DutyRosterInShiftCountTimeSeriesTile,
        ExternalBdcAcceptanceRateTile,
        ExternalBdcAcceptanceRateTimeSeriesTile,
        ExternalBdcAgentCallCountScatterTile,
        ExternalBdcAgentCallDurationScatterTile,
        ExternalBdcAgentCallOverlapScatterTile,
        ExternalBdcAgentPerformanceScatterTile,
        ExternalBdcAgentShortCallDurationCountBarTile,
        ExternalBdcBilledMinutesTile,
        ExternalBdcCallDisconnectReasonByGroupBarTile,
        ExternalBdcCallStatusTile,
        ExternalBdcDurationTile,
        ExternalBdcOngoingCallTimeSeriesTile,
        ExternalBdcWaitedTile,
        NumberField,
    },
});

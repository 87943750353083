
import PartitionImageDialog from "./PartitionImageDialog.vue";
import PartitionSettingsDialog from "./PartitionSettingsDialog.vue";
import ShowTwilioApiKeyDialog from "./ShowTwilioApiKeyDialog.vue";
import { PartitionMachineType, partitionsApi, PartitionSummary, TwilioApiKey } from "@/api/partitions";
import { Permission } from "@/api/userSession";
import { showConfirm } from "@/app/messageUtil";
import { notEmpty } from "@/app/validation";
import { configStore } from "@/store/config";
import { userSession } from "@/store/userSession";
import Vue from "vue";

enum PartitionPanelState {
    READY,
    LOADING,
    RESTARTING,
    CREATING_RESOURCES,
    DEACTIVATING,
    DEPLOYING,
    RESETTING_RESTARTING,
}

enum PartitionCreationLifecycleState {
    BEFORE_CREATE_MYSQL_USER,
    BEFORE_CREATE_TWILIO_SUB_ACCOUNT,
    BEFORE_VERIFY_DEFAULT_CLICK_TO_CALL_CALLER_ID,
    BEFORE_CREATE_POSTMARK_DOMAIN,
    BEFORE_CREATE_DNS_RECORDS,
    BEFORE_CREATE_POSTMARK_SERVER,
    BEFORE_CREATE_BUCKET,
    BEFORE_CREATE_SERVICE_ACCOUNT,
    BEFORE_CREATE_INSTANCEGROUP,
    BEFORE_CREATE_BACKEND,
    BEFORE_CREATE_PATHMATCHER,
    BEFORE_CREATE_INSTANCE,
    CREATED,
}

enum PartitionEditorState {
    HIDDEN,
    LOADING,
    VISIBLE,
    EDITING,
}

export default Vue.extend({
    data() {
        return {
            PartitionCreationLifecycleState,
            partition: this.partitionProp,
            PartitionPanelState,
            partitionPanelState: PartitionPanelState.READY as PartitionPanelState,
            PartitionEditorState,
            partitionEditorState: PartitionEditorState.HIDDEN as PartitionEditorState,
            partitionName: "",
            partitionNameRules: notEmpty()
                .maxLength(255)
                .msg(() => this.$t("Gültiger Name ist erforderlich")),
            machineType: null as PartitionMachineType | null,
            numberOfInstances: 1,
            marketingEmailsEnabled: true,
            valid: true,
            configStore,
            partitionSettingsDialogVisible: false,
            partitionImageDialogVisible: false,
            citnowConversationsTwilioApiKey: null as TwilioApiKey | null,
        };
    },

    computed: {
        canCreateCitnowConversationsTwilioSubAccount(): boolean {
            return userSession.hasPermission(Permission.CT_CREATE_CITNOW_CONVERSATIONS_TWILIO_SUB_ACCOUNT);
        },

        canCreatePartition(): boolean {
            return userSession.hasPermission(Permission.CT_CREATE_PARTITIONS);
        },

        canDeactivatePartition(): boolean {
            return userSession.hasPermission(Permission.CT_DEACTIVATE_PARTITIONS);
        },

        canDeployCustomImage(): boolean {
            return userSession.hasPermission(Permission.CT_DEPLOY_CUSTOM_IMAGE);
        },

        canStartPartition(): boolean {
            return userSession.hasPermission(Permission.CT_START_PARTITIONS);
        },

        canViewCitnowConversationsTwilioSubAccountApiKey(): boolean {
            return userSession.hasPermission(Permission.CT_VIEW_CITNOW_CONVERSATIONS_TWILIO_SUB_ACCOUNT_API_KEY);
        },

        currentCreationLifecycleState(): PartitionCreationLifecycleState {
            if (!this.partition.mysqlUserWithDatabaseCreated) {
                return PartitionCreationLifecycleState.BEFORE_CREATE_MYSQL_USER;
            } else if (!this.partition.dealerdeskTwilioAccountSid) {
                return PartitionCreationLifecycleState.BEFORE_CREATE_TWILIO_SUB_ACCOUNT;
            } else if (!this.partition.defaultClickToCallCallerId && !this.partition.postmarkDomainId) {
                return PartitionCreationLifecycleState.BEFORE_VERIFY_DEFAULT_CLICK_TO_CALL_CALLER_ID;
            } else if (!this.partition.postmarkDomainId) {
                return PartitionCreationLifecycleState.BEFORE_CREATE_POSTMARK_DOMAIN;
            } else if (!this.partition.dnsRecordsCreated) {
                return PartitionCreationLifecycleState.BEFORE_CREATE_DNS_RECORDS;
            } else if (
                !this.partition.postmarkServerId ||
                !this.partition.dkimVerified ||
                !this.partition.returnPathVerified
            ) {
                return PartitionCreationLifecycleState.BEFORE_CREATE_POSTMARK_SERVER;
            } else if (!this.partition.bucketName) {
                return PartitionCreationLifecycleState.BEFORE_CREATE_BUCKET;
            } else if (!this.partition.gcloudServiceAccountCreated) {
                return PartitionCreationLifecycleState.BEFORE_CREATE_SERVICE_ACCOUNT;
            } else if (!this.partition.gcloudInstancegroupName) {
                return PartitionCreationLifecycleState.BEFORE_CREATE_INSTANCEGROUP;
            } else if (!this.partition.gcloudBackendName) {
                return PartitionCreationLifecycleState.BEFORE_CREATE_BACKEND;
            } else if (!this.partition.gcloudPathMatcherName) {
                return PartitionCreationLifecycleState.BEFORE_CREATE_PATHMATCHER;
            } else if (!this.partition.gcloudInstanceNamePrefix) {
                return PartitionCreationLifecycleState.BEFORE_CREATE_INSTANCE;
            } else {
                return PartitionCreationLifecycleState.CREATED;
            }
        },

        partitionMachineTypes() {
            return Object.keys(PartitionMachineType);
        },
    },

    props: {
        partitionProp: {
            type: Object as () => PartitionSummary,
            required: true,
        },
    },

    methods: {
        async createCitnowConversationsTwilioSubAccountAndApiKey() {
            if (
                !(await showConfirm(
                    this.$t("Twilio-Account für CitNOW-Conversations erstellen") as string,
                    this.$t(
                        "Sind Sie sicher, dass Sie einen Twilio-Account für CitNOW-Conversations erstellen möchten?"
                    ) as string
                ))
            ) {
                return;
            }

            this.partitionPanelState = PartitionPanelState.CREATING_RESOURCES;
            try {
                await partitionsApi.createCitnowConversationsTwilioSubAccount(this.partition.id);
                await partitionsApi.createCitnowConversationsTwilioSubAccountApiKey(this.partition.id);
            } finally {
                await this.loadPartition();
            }
        },

        async createCitnowConversationsTwilioSubAccountApiKey() {
            this.partitionPanelState = PartitionPanelState.CREATING_RESOURCES;
            try {
                await partitionsApi.createCitnowConversationsTwilioSubAccountApiKey(this.partition.id);
            } finally {
                await this.loadPartition();
            }
        },

        async loadPartition() {
            this.partitionPanelState = PartitionPanelState.LOADING;
            try {
                this.partition = await partitionsApi.getById(this.partition.id);
            } finally {
                this.partitionPanelState = PartitionPanelState.READY;
            }
        },

        async recreatePartition() {
            if (
                !(await showConfirm(
                    this.$t("Partition neustarten") as string,
                    this.$t('Sind Sie sicher, dass Sie die Partition "{0}" neustarten möchten?', [
                        this.partition.id,
                    ]) as string
                ))
            ) {
                return;
            }

            this.partitionPanelState = PartitionPanelState.RESTARTING;
            try {
                await partitionsApi.recreateInstancesById(this.partition.id, false);
                this.partition = { ...this.partition, active: true };
            } finally {
                this.partitionPanelState = PartitionPanelState.READY;
            }
        },

        async deactivatePartition() {
            if (
                !(await showConfirm(
                    this.$t("Partition deaktivieren") as string,
                    this.$t('Sind Sie sicher, dass Sie die Partition "{0}" deaktivieren möchten?', [
                        this.partition.id,
                    ]) as string
                ))
            ) {
                return;
            }

            this.partitionPanelState = PartitionPanelState.DEACTIVATING;
            try {
                await partitionsApi.deactivateInstancesById(this.partition.id);
                this.partition = { ...this.partition, active: false };
            } finally {
                this.partitionPanelState = PartitionPanelState.READY;
            }
        },

        async deployCustomImage(image: string) {
            this.partitionImageDialogVisible = false;

            if (
                !(await showConfirm(
                    this.$t("Andere Version installieren") as string,
                    this.$t('Sind Sie sicher, dass Sie das Image "{0}" auf der Partition "{1}" installieren möchten?', [
                        image,
                        this.partition.id,
                    ]) as string
                ))
            ) {
                return;
            }

            this.partitionPanelState = PartitionPanelState.DEPLOYING;
            try {
                await partitionsApi.deployCustomImageById(this.partition.id, image);
                this.partition = { ...this.partition, active: true, customImage: image };
            } finally {
                this.partitionPanelState = PartitionPanelState.READY;
            }
        },

        async resetAndRecreatePartition() {
            if (
                !(await showConfirm(
                    this.$t("Partition auf Standard-Version zurücksetzen und neustarten") as string,
                    this.$t('Sind Sie sicher, dass Sie die Partition "{0}" neustarten möchten?', [
                        this.partition.id,
                    ]) as string
                ))
            ) {
                return;
            }

            this.partitionPanelState = PartitionPanelState.RESETTING_RESTARTING;
            try {
                await partitionsApi.resetAndRecreateInstancesById(this.partition.id, false);
                this.partition = { ...this.partition, customImage: null };
            } finally {
                this.partitionPanelState = PartitionPanelState.READY;
            }
        },

        async createMysqlUserWithDatabase() {
            this.partitionPanelState = PartitionPanelState.CREATING_RESOURCES;

            try {
                await partitionsApi.createMysqlUserWithDatabase(this.partition.id);
            } finally {
                this.loadPartition();
            }
        },

        async createDealerdeskTwilioSubAccount() {
            this.partitionPanelState = PartitionPanelState.CREATING_RESOURCES;

            try {
                await partitionsApi.createDealerdeskTwilioSubAccount(this.partition.id);
            } finally {
                await this.loadPartition();
            }
        },

        async verifyDefaultClickToCallCallerId() {
            this.partitionPanelState = PartitionPanelState.CREATING_RESOURCES;

            try {
                await partitionsApi.verifyDefaultClickToCallCallerId(this.partition.id);
            } finally {
                await this.loadPartition();
            }
        },

        async createPostmarkDomain() {
            this.partitionPanelState = PartitionPanelState.CREATING_RESOURCES;

            try {
                await partitionsApi.createPostmarkDomain(this.partition.id);
            } finally {
                await this.loadPartition();
            }
        },

        async createDnsRecords() {
            this.partitionPanelState = PartitionPanelState.CREATING_RESOURCES;

            try {
                await partitionsApi.createDnsRecords(this.partition.id);
            } finally {
                await this.loadPartition();
            }
        },

        async createPostmarkServer() {
            this.partitionPanelState = PartitionPanelState.CREATING_RESOURCES;

            try {
                await partitionsApi.createPostmarkServer(this.partition.id);
            } finally {
                await this.loadPartition();
            }
        },

        async createBucket() {
            this.partitionPanelState = PartitionPanelState.CREATING_RESOURCES;

            try {
                await partitionsApi.createBucket(this.partition.id);
            } finally {
                await this.loadPartition();
            }
        },

        async createGcloudCredentials() {
            this.partitionPanelState = PartitionPanelState.CREATING_RESOURCES;

            try {
                await partitionsApi.createGcloudCredentials(this.partition.id);
            } finally {
                await this.loadPartition();
            }
        },

        async createInstancegroup() {
            this.partitionPanelState = PartitionPanelState.CREATING_RESOURCES;

            try {
                await partitionsApi.createInstancegroup(this.partition.id);
            } finally {
                await this.loadPartition();
            }
        },

        async createBackend() {
            this.partitionPanelState = PartitionPanelState.CREATING_RESOURCES;

            try {
                await partitionsApi.createBackend(this.partition.id);
            } finally {
                await this.loadPartition();
            }
        },

        async createPathMatcher() {
            this.partitionPanelState = PartitionPanelState.CREATING_RESOURCES;

            try {
                await partitionsApi.createPathMatcher(this.partition.id);
            } finally {
                await this.loadPartition();
            }
        },

        async createInstances() {
            this.partitionPanelState = PartitionPanelState.CREATING_RESOURCES;

            try {
                await partitionsApi.createInstancesById(this.partition.id);
            } finally {
                await this.loadPartition();
            }
        },

        toggleEditDialog() {
            if (this.partitionEditorState === PartitionEditorState.VISIBLE) {
                this.partitionEditorState = PartitionEditorState.HIDDEN;
            } else {
                this.partitionEditorState = PartitionEditorState.LOADING;

                this.partitionName = this.partition.name;
                this.machineType = this.partition.machineType;
                this.numberOfInstances = this.partition.numberOfInstances;
                this.marketingEmailsEnabled = this.partition.marketingEmailsEnabled;

                this.partitionEditorState = PartitionEditorState.VISIBLE;
            }
        },

        async editPartition() {
            if (!this.valid) {
                (this.$refs.form as any).validate();
                return;
            }

            this.partitionEditorState = PartitionEditorState.EDITING;

            try {
                await partitionsApi.edit(this.partition.id, {
                    name: this.partitionName,
                    machineType: this.machineType!,
                    numberOfInstances: this.numberOfInstances,
                    marketingEmailsEnabled: this.marketingEmailsEnabled,
                });
            } finally {
                await this.loadPartition();
            }

            this.partitionEditorState = PartitionEditorState.HIDDEN;
        },

        async showCitnowConversationsTwilioSubAccountApiKey() {
            this.citnowConversationsTwilioApiKey = null;
            this.partitionPanelState = PartitionPanelState.LOADING;

            try {
                this.citnowConversationsTwilioApiKey = await partitionsApi.getCitnowConversationsTwilioSubAccountApiKey(
                    this.partition.id
                );
            } finally {
                this.partitionPanelState = PartitionPanelState.READY;
            }
        },
    },

    components: {
        PartitionImageDialog,
        PartitionSettingsDialog,
        ShowTwilioApiKeyDialog,
    },
});


import { citNowDataExchangeApi } from "@/api/citNowDataExchange";
import DatePicker from "@/app/components/DatePicker.vue";
import { notEmpty } from "@/app/validation";
import { configStore } from "@/store/config";
import { now } from "@/store/now";
import { getDate } from "@/util/dateTimeUtils";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            createdDate: null as string | null,
            isWorking: false,
            notEmpty,
            now: now(),
        };
    },

    computed: {
        maxCreatedDate(): string {
            return getDate(this.now, this.timeZone, -1);
        },

        timeZone(): string {
            return configStore.configuration.organisationTimeZone;
        },
    },

    methods: {
        close() {
            this.$emit("close");
        },

        async submitForm() {
            if (!(this.$refs.form as any).validate()) {
                return;
            }

            this.isWorking = true;

            try {
                await citNowDataExchangeApi.uploadBillingItemsByCreatedDate(this.createdDate!);
                this.$emit("success");
            } finally {
                this.isWorking = false;
            }
        },
    },

    components: {
        DatePicker,
    },
});

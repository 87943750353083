
import { SelectOptions } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    props: {
        value: [String as () => string | null, Array as () => string[]],
        multiple: {
            type: Boolean,
            default: false,
        },
        values: Object,
        text: Function,
    },

    computed: {
        items(): SelectOptions {
            return Object.keys(this.values).map((k) => ({ value: k, text: this.text(k) }));
        },
    },
});

import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export interface UserOnlinePeriod {
    readonly userId: string;
    readonly begin: Date;
    readonly end: Date | null;
}

interface UserTrackingApi {
    getUserOnlinePeriods(start: Date, end: Date): Promise<UserOnlinePeriod[]>;
    getCurrentOnlinePeriodsForScheduledAgents(secret: string): Promise<UserOnlinePeriod[]>;
}

export const userTrackingApi: UserTrackingApi = {
    async getUserOnlinePeriods(start, end) {
        return cloneObject((await axios.get("/api/user-tracking/online-periods", { params: { start, end } })).data);
    },

    async getCurrentOnlinePeriodsForScheduledAgents(secret: string) {
        return cloneObject((await axios.get("/api/user-tracking/current-online-periods", { params: { secret } })).data);
    },
};

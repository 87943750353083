import { foldAscii } from "@/util/foldAsciiUtils";
import { parseAndFormatNumber } from "@/util/phoneNumberUtils";

function normalize(str: string | null | undefined) {
    return new Set(
        foldAscii((str || "").trim())
            .toLowerCase()
            .split(/\s+/)
    );
}

export function searchFilter<T>(search: string | null, ...values: (string | null | undefined)[]) {
    if (!search) {
        return true;
    }

    const vs = new Set<string>();
    for (const ts of values.map(normalize)) {
        for (const t of ts) {
            vs.add(t);
        }
    }

    outer: for (const s of normalize(search)) {
        for (const v of vs) {
            if (v.includes(s)) {
                continue outer;
            }
        }
        return false;
    }

    return true;
}

export function searchFilterPhoneNumbers<T>(search: string | null, ...phoneNumbers: string[]) {
    if (!search) {
        return true;
    }

    if (!search.replace(/[^\d+]/g, "")) {
        return false;
    }

    const normalized = search.replace(/[^\d]/g, "");

    for (const phoneNumber of phoneNumbers) {
        if (!phoneNumber) {
            continue;
        }

        if (phoneNumber.substr(1).includes(normalized)) {
            return true;
        }

        const formatted = parseAndFormatNumber(phoneNumber, "NATIONAL");

        if (formatted && formatted.replace(/[^\d]/g, "").includes(normalized)) {
            return true;
        }
    }

    return false;
}

import { ReportingChartComponent } from "./charts/reportingChart";
import { downloadDataUrl } from "@/app/fileUtils";
import { now } from "@/store/now";
import { foldAscii } from "@/util/foldAsciiUtils";

export function getFileBasename(...parts: (string | undefined | null)[]): string | null {
    return (
        parts
            .map((part) => (part ?? "").trim())
            .filter((part) => !!part)
            .map((part) => foldAscii(part))
            .map((part) => part.replace(/[^\w .()_-]/gi, "_"))
            .filter((part, index, array) => (index ? array[index - 1] !== part : true))
            .join(" - ") || null
    );
}

export function downloadChartAsPng(chartComponent: ReportingChartComponent, fileBasename: string | null) {
    if (!chartComponent) {
        return;
    }

    const dataUrl = chartComponent.getChartAsPngDataUrl();

    if (!dataUrl) {
        return;
    }

    const basename = fileBasename || now().getTime();

    downloadDataUrl(dataUrl, `${basename}.png`);
}

export function downloadChartAsSvg(chartComponent: ReportingChartComponent, fileBasename: string | null) {
    if (!chartComponent) {
        return;
    }

    const dataUrl = chartComponent.getChartAsSvgDataUrl();

    if (!dataUrl) {
        return;
    }

    const basename = fileBasename || now().getTime();

    downloadDataUrl(dataUrl, `${basename}.svg`);
}

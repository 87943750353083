import i18n from "@/app/i18n";

export interface EChartsDatazoomEvent {
    readonly type: string;
    readonly animation: any;
    readonly dataZoomId: string;
    readonly start: number;
    readonly end: number;
    readonly from: string;
}

export interface EChartsLegendSelectChangedEvent {
    readonly type: string;
    readonly name: string;
    readonly selected: { [key: string]: boolean };
}

export interface EChartsMouseEvent<D> {
    readonly componentType: string;
    readonly componentSubType?: string;
    readonly seriesType: string;
    readonly seriesIndex: number;
    readonly seriesName: string;
    readonly name: string;
    readonly dataIndex: number;
    readonly data: D;
    readonly dataType: string;
    readonly value: number | number[];
    readonly color: string;
}

export function formatAxisCategoryLabel(value: string, maxLength: number, wrap: boolean): string {
    const trimmed = value.trim().replace(/\s+/g, " ");
    let parts = wrap ? trimmed.split(" ") : [trimmed];

    if (maxLength) {
        // join parts if it does not exceed maxLength
        parts = parts.reduce((parts, cur) => {
            if (!parts.length) {
                parts.push(cur);
                return parts;
            }

            const lastIndex = parts.length - 1;
            const extended = `${parts[lastIndex]} ${cur}`;

            if (extended.length <= maxLength) {
                parts[lastIndex] = extended;
            } else {
                parts.push(cur);
            }

            return parts;
        }, [] as string[]);
    }

    return parts
        .map((part) => {
            if (!maxLength || part.length <= maxLength + 1) {
                return part;
            } else {
                return `${part.substring(0, maxLength)}...`;
            }
        })
        .join("\n");
}

export function formatAxisValueLabel(value: number, isPercentage: boolean): string {
    return isPercentage
        ? i18n.n(value, { style: "percent", maximumFractionDigits: "0" })
        : i18n.n(value, { maximumFractionDigits: "0" });
}

export function formatDataLabel(value: number | null, isPercentage: boolean): string {
    if (!value) {
        return "";
    }

    return isPercentage
        ? i18n.n(value, { style: "percent", maximumFractionDigits: "0" })
        : i18n.n(value, { maximumFractionDigits: "1" });
}

export function formatTooltipLabel(value: number | null, isPercentage: boolean): string {
    if (value === undefined || value === null) {
        return "";
    }

    return isPercentage
        ? i18n.n(value, { style: "percent", minimumFractionDigits: "2", maximumFractionDigits: "2" })
        : i18n.n(value, { maximumFractionDigits: "1" });
}

export function calculateAxisMaxValue(
    extent: { min: number; max: number },
    max: number | undefined,
    isPercentage: boolean
): number | null {
    if (!max) {
        return null;
    }

    const step = 5;
    const scaleFactor = isPercentage ? 100 : 1;
    const scaled = extent.max * scaleFactor;

    const chartMax = (step * Math.ceil(scaled / step)) / scaleFactor;

    return Math.min(chartMax, max);
}

export function getDatazoomStartIndex(e: EChartsDatazoomEvent, categoriesCount: number): number {
    if (e.type !== "datazoom" || !(0 <= e.start && e.start <= 100) || categoriesCount === 0) {
        return 0;
    }

    const maxStartIndex = categoriesCount - 1;

    return Math.min(maxStartIndex, Math.floor(categoriesCount * (e.start / 100)));
}

export interface SliderWindow {
    readonly startIndex: number;
    readonly endIndex: number;
}

export function getSliderWindow(dataZoomStartIndex: number, categoriesCount: number, windowSize: number): SliderWindow {
    const lastStartIndexFullWindow = Math.max(0, categoriesCount - windowSize);

    const startIndex = Math.min(dataZoomStartIndex, lastStartIndexFullWindow);
    const endIndex = Math.max(0, Math.min(startIndex + (windowSize - 1), categoriesCount - 1));

    return { startIndex, endIndex };
}

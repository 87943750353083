/* eslint-disable prettier/prettier */
export default {
    "0 Agenten | 1 Agent | {count} Agenten": "0 agents | 1 agent | {count} agents",
    "0 Aktivitäten | 1 Aktivität | {count} Aktivitäten": "0 activities | 1 activity | {count} activities",
    "0 Anrufe | 1 Anruf | {count} Anrufe": "0 calls | 1 call | {count} calls",
    "0 Minuten | 1 Minute | {count} Minuten": "0 minutes | 1 minute | {count} minutes",
    "0 Sekunden | 1 Sekunde | {count} Sekunden": "0 seconds | 1 second | {count} seconds",
    "0 Stunden | 1 Stunde | {count} Stunden": "0 hours | 1 hour | {count} hours",
    "1 - 2 Stunden": "1 - 2 hours",
    "1 - 2 Wochen": "1 - 2 weeks",
    "15 - 30 Minuten": "15 - 30 minutes",
    "2 - 4 Stunden": "2 - 4 hours",
    "2 - 4 Wochen": "2 - 4 weeks",
    "24 - 72 Stunden": "24 - 72 hours",
    "3 - 7 Tage": "3 - 7 days",
    "30 - 60 Minuten": "30 - 60 minutes",
    "4 - 24 Stunden": "4 - 24 hours",
    "5 - 15 Minuten": "5 - 15 minutes",
    "< 5 Minuten": "< 5 minutes",
    ">= 4 Wochen": ">= 4 weeks",
    "Abbrechen": "Cancel",
    "Abgerechnet (Summe)": "Billed (total)",
    "Abgerechnete Minuten": "Billed minutes",
    "Abrechnungseinträge hochladen": "Upload billing items",
    "Abrechnungskonten": "Billing accounts",
    "Abrechnungskonto": "Billing account",
    "Abrechnungskonto bearbeiten": "Edit billing account",
    "Abrechnungskonto hinzufügen": "Add billing account",
    "Abrechnungskonto löschen": "Delete billing account",
    "Abrechnungsquote": "Billing ratio",
    "Absenderadresse": "Sender address",
    "Absendername": "Sender name",
    "Absolut": "Absolute",
    "Abwesend": "Absent",
    "Account": "Account",
    "Account-SID": "Account SID",
    "Administration": "Administration",
    "Adresszuordnung": "URL mapping",
    "Adresszuordnung erstellen": "Create URL map",
    "Adresszusatz": "Address",
    "Agent": "Agent",
    "Agent (Durchschnitt)": "Agent (average)",
    "Agenten": "Agents",
    "Agenten dürfen auf die Partition zugreifen": "Agents are allowed to access the partition",
    "Agenten-Einstellungen": "Agent settings",
    "Agenten-Monatsübersicht": "Agent month overview",
    "Agenten-Tagesübersicht": "Agent day overview",
    "Agentenbeurteilungen": "Agent assessments",
    "Agentenstatistik": "Agent statistic",
    "Aktion": "Action",
    "Aktionen": "Actions",
    "Aktive Suchaufträge": "Active search inquiries",
    "Aktivitäten": "Activities",
    "Aktivitäten mit Feedback": "Activities with feedback",
    "Aktivitäten vorhanden": "Activities available",
    "Aktualisieren": "Refresh",
    "Aktuelle Woche": "Current week",
    "Aktueller Monat": "Current month",
    "Aktuelles Jahr": "Current year",
    "Aktuelles Quartal": "Current quarter",
    "Alle Anrufe": "All calls",
    "Alle Daten im ausgewählten Zeitbereich laden": "Load all data in the selected time range",
    "Alle E-Mails": "All emails",
    "Alle Rechnungen": "All invoices",
    "Alle Vorlagen anzeigen...": "Show all templates...",
    "Alle neu starten": "Reboot all",
    "Als CSV herunterladen": "Download as CSV",
    "Als PNG herunterladen": "Download as PNG",
    "Als SVG herunterladen": "Download as SVG",
    "Als bearbeitet markieren": "Mark as processed",
    "Altes Passwort": "Old password",
    "Andere Version installieren": "Deploy custom version",
    "Angenommen": "Answered",
    "Angenommen (Median)": "Answered (median)",
    "Angenommen oder Wartezeit >= {0} Sekunden ": "Answered or waiting time >= {0} seconds",
    "Annahme": "Acceptance",
    "Annahmequote": "Acceptance quota",
    "Annahmequote für Anrufe mit einer Wartezeit von wenigstens": "Acceptance quota for calls with a waiting time of at least",
    "Anonym": "Anonymous",
    "Anrufaufkommen": "Call volume",
    "Anrufe": "Calls",
    "Anrufe (Leistung)": "Calls (performance)",
    "Anrufer": "Caller",
    "Anruferkennung": "Caller ID",
    "Ansage": "Announcement",
    "Anteil": "Share",
    "Anteil der kurzen Anrufe": "Share of short calls",
    "Anzahl": "Count",
    "Anzahl Aktivitäten": "Activities count",
    "Anzahl Instanzen": "Number of instances",
    "Anzahl Matches": " Matches count",
    "Anzahl Matches (absteigend)": "Matches count (descending)",
    "Anzahl Matches (aufsteigend)": "Matches count (ascending)",
    "Anzahl Offene Rechnungen": "Open invoices count",
    "Anzahl Rechnungsempfänger": "Invoice receivers count",
    "Anzahl Verträge": "Contracts count",
    "Anzahl der manuell zu bearbeitenden E-Mails": "Count of emails to be processed manually",
    "Anzeige": "Display",
    "Api-Key-SID": "Api key SID",
    "Api-Key-Secret": "Api key secret",
    "Art der Aktion": "Action type",
    "Art des Feedbacks": "Feedback type",
    "Auf Standard-Version zurücksetzen": "Reset to standard version",
    "Auffälligkeiten": "Abnormalities",
    "Aufgabe ausführen": "Execute task",
    "Aufgabe in allen Partitionen ausführen": "Execute task in all partitions",
    "Aufgabe in fehlgeschlagenen Partitionen ausführen": "Execute task in failed partitions",
    "Aufgabengröße in allen Partitionen abfragen": "Query task size in all partitions",
    "Aufzeichnung": "Recording",
    "Aufzeichnungen": "Recordings",
    "Aufzeichnungen anzeigen": "Show recordings",
    "Ausführen": "Execute",
    "Ausgehende SMS": "Outgoing text messages",
    "Ausgewählter Kontakt": "Selected contact",
    "Ausgleich": "Compensation",
    "Auslastung": "Load",
    "Ausreichend": "Sufficient",
    "Auswertung": "Evaluation",
    "Automatische Rechnungserstellung": "Automatic invoice creation",
    "BCC-Empfänger": "BCC receivers",
    "BDC": "BDC",
    "BDC (variabel)": "BDC (variable)",
    "BDC (wiederkehrend)": "BDC (recurring)",
    "BDC-Team bearbeiten": "Edit BDC team",
    "BDC-Team hinzufügen": "Add BDC team",
    "BDC-Team löschen": "Delete BDC team",
    "BDC-Teams": "BDC teams",
    "BIC": "BIC",
    "BWA": "BWA",
    "BWA-Monat": "BWA month",
    "Backend": "Backend",
    "Backend erstellen": "Create Backend",
    "Backlist-Eintrag löschen": "Delete blacklist entry",
    "Bankdaten": "Bank data",
    "Bearbeiten": "Edit",
    "Beginn": "Begin",
    "Beginn bis": "Begin until",
    "Beginn von": "Begin from",
    "Beginn zwischen": "Begin between",
    "Belegte Leitungen und freie Agenten können nur im sekündlichen Zeitintervall verglichen werden": "Used lines and free agents can only be compared in the time interval of one second",
    "Benutzer": "User",
    "Benutzer (online/gesamt)": "User (online/total)",
    "Benutzer bis Passwortänderung sperren": "Lock user until password change",
    "Benutzer erfolgreich erstellt": "User created successfully",
    "Benutzer löschen": "Delete user",
    "Benutzer-E-Mail": "User email",
    "Benutzer-ID": "User ID",
    "Benutzername": "Username",
    "Benutzername ist erforderlich": "Username is required",
    "Benutzerverwaltung": "User Management",
    "Benötigte Agentenanzahl": "Required number of agents",
    "Bereits bezahlte Rechnungen": "Already paid invoices",
    "Berichte": "Reports",
    "Beschreibung": "Description",
    "Bestandsfahrzeuge": "Inventory vehicles",
    "Betreff": "Subject",
    "Beurteilung": "Assessment",
    "Beurteilungen": "Assessments",
    "Bevorzugte Leistungsarten": "Preferred benefit types",
    "Bezahlt": "Paid",
    "Bezeichnung": "Label",
    "Bitte geben Sie einen gültigen Wert ein.": "Please enter a valid value.",
    "Bitte loggen Sie sich erneut ein.": "Please log in again.",
    "Blacklist": "Blacklist",
    "Blacklist-Eintrag für Absenderadresse hinzufügen": "Add blacklist entry for from address",
    "Blacklist-Eintrag für Absendername hinzufügen": "Add blacklist entry for from name",
    "Blacklist-Eintrag für Betreff hinzufügen": "Add blacklist entry for subject",
    "Blacklist-Eintrag für Domain hinzufügen": "Add blacklist entry for domain",
    "Blacklist-Eintrag hinzufügen": "Add blacklist entry",
    "Blacklisted": "Blacklisted",
    "Bruttobetrag": "Gross amount",
    "Bucket": "Bucket",
    "Bucket erstellen": "Create Bucket",
    "Call-Center": "Call-Center",
    "Call-Center Dashboard": "Call-Center Dashboard",
    "Call-Center-Statistik anzeigen": "Show Call-Center statistics",
    "Call-Tracking": "Call-tracking",
    "Call-Tracking-Minuten": "Call-tracking minutes",
    "Call-Tracking-Minuten (abgedeckt/generiert)": "Call-tracking minutes (covered/generated)",
    "CitNOW-Conversations-API aktiv": "CitNOW conversations API active",
    "CitNOW-Conversations-Benutzer": "CitNOW conversations user",
    "CitNOW-Conversations-Benutzer-ID hinzufügen": "Add CitNOW conversations user",
    "CitNOW-Datenaustausch": "CitNOW data exchange",
    "CitNOW-Salesforce": "CitNOW Salesforce",
    "Click-to-Call": "Click-to-call",
    "Click-to-Call-Minuten": "Click-to-call minutes",
    "Click-to-Call-Minuten (abgedeckt/generiert)": "Click-to-call minutes (covered/generated)",
    "Code": "Code",
    "Control Tower": "Control Tower",
    "DD.MM": "MM/DD",
    "DNS": "DNS",
    "DNS-Einträge erstellen": "Create DNS Entries",
    "Das Passwort muss mindestens 8 Zeichen lang sein sowie kein Leerzeichen, einen Kleinbuchstaben, einen Großbuchstaben und eine Ziffer enthalten.": "The password has a minimum length of 8 characters and must not contain a space but at least one small letter, one capital letter and one digit.",
    "Dashboard": "Dashboard",
    "Datenbank": "Database",
    "Datenbank finalisieren": "Finalize Database",
    "Datenbank-Benutzer erstellen": "Create Database User",
    "Datenbanken": "Databases",
    "Datenbankpasswörter erneuern": "Renew database passwords",
    "Datenpflege": "Data maintenance",
    "Datum": "Date",
    "Datum der Unterschrift": "Date of signature",
    "Dauer": "Duration",
    "Deaktivieren": "Deactivate",
    "Der Agent wurde nicht gefunden.": "The agent was not found.",
    "Der Benutzer wurde nicht gefunden.": "The user was not found.",
    "Der Händler \"{0}\" gehört zu einem anderen Abrechnungskonto. Sind Sie sicher, dass Sie fortfahren möchten?": "The dealer \"{0}\" belongs to a different billing account. Are you sure you want to continue?",
    "Der Händler gehört zu einem anderen Abrechnungskonto.": "The dealer belongs to a different billing account.",
    "Der Monat wurde noch nicht geschlossen.": "The month has not been closed yet.",
    "Der Wert wurde erfolgreich in die Zwischenablage kopiert.": "The value was copied to clipboard successfully.",
    "Der angeklickte Link führt auf eine externe Seite. Möchten Sie fortfahren?": "The link you click leads to an external page. Do you want to continue?",
    "Der ausgewählte Zeitraum ist zu lang.": "The selected time span is too long.",
    "Design": "Theme",
    "Die Aktivität wurde nicht gefunden.": "The activity was not found.",
    "Die Datenbank ist noch nicht bereit. Bitte versuchen Sie es später nochmal.": "The Database ist no ready yet. Please try again later.",
    "Die Einzelverbindung wurde nicht gefunden.": "The call detail record was not found.",
    "Die Nummer ist möglicherweise nicht gültig.": "The number might not be valid.",
    "Die Rechnung wird voraussichtlich vom Buchhalter beanstandet und muss ggf. händisch nachgebucht werden. Sind Sie sicher, dass Sie fortfahren möchten?": "The invoice is likely to be disputed by the accountant and may need to be manually rebooked. Are you sure you want to continue?",
    "Die aufgerufene Seite wurde nicht gefunden.": "The requested page was not found",
    "Die eingegebenen Passwörter stimmen nicht überein.": "The entered passwords are different",
    "Dienstkonto": "Service Account",
    "Dienstkonto erstellen": "Create Service Account",
    "Dienstplan": "Duty roster",
    "Dienstplanung": "Duty scheduling",
    "Dienstplanübersicht": "Duty roster overview",
    "Dienstpläne": "Duty rosters",
    "Dieses Feld ist erforderlich.": "This field is required.",
    "Display Name": "Display Name",
    "Drucken": "Print",
    "Dunkel": "Dark",
    "Durch Schichtplanvorlage ersetzen": "Replace with shift schedule template",
    "Durchschnitt": "Average",
    "E-Mail an Rechnungsempfänger schicken": "Send email to invoice receivers",
    "E-Mail vom": "Email dated",
    "E-Mail-Adresse": "Email address",
    "E-Mail-Adresse des Melders": "Email address of reporter",
    "E-Mail-Adresse oder Passwort sind nicht korrekt.": "Email address or password is incorrect.",
    "E-Mail-Versand": "Email delivery",
    "E-Mails heute": "Emails today",
    "Eingehend": "Incoming",
    "Eingehende Anrufe": "Incomming calls",
    "Eingehende Anrufentwicklung": "Incoming call performance",
    "Eingehende E-Mails": "Incoming emails",
    "Eingeplant": "Planned",
    "Einheiten": "Units",
    "Einheiten inkl.": "Units included",
    "Einrichtung": "Setup",
    "Einstellungen": "Settings",
    "Eintragstyp": "Entry type",
    "Einträge je Seite": "Items per page",
    "Einträge vorhanden": "Entry available",
    "Einzelgespräch in Sekunden": "Individual call in seconds",
    "Einzelverbindung": "Call detail record",
    "Einzelverbindungen": "Call detail records",
    "Einzelverbindungsnachweis": "Call detail record",
    "Einzelverbindungsübersicht": "Call detail record overview",
    "Elastic-Cluster": "Elastic cluster",
    "Elastic-Cluster hinzufügen": "Add Elastic cluster",
    "Elastic-Clusters": "Elastic clusters",
    "Email-Domain": "Emain domain",
    "Email-Server": "Email server",
    "Ende": "End",
    "Ende bis": "End until",
    "Ende von": "End from",
    "Entferne Dubletten von Fahrzeugen ohne Importquelle": "Remove duplicates of vehicles without import source",
    "Entferne Dubletten von allen Fahrzeugen": "Remove duplicates of all vehicles",
    "Entferne Fahrzeuge ohne Importquelle aus dem Fahrzeugbestand": "Remove vehicles without import source from inventory",
    "Ergebnis": "Result",
    "Erneut versuchen": "Try again",
    "Erstellen": "Create",
    "Erstellt am": "Created",
    "Erstellt bis": "Created until",
    "Erstellt von": "Created from",
    "Erstellt zwischen": "Created between",
    "Erstellte Fälle (Benutzer/Externer Agent/System/glücklich/unglücklich)": "Created cases (user/external agent/system/happy/unhappy)",
    "Erstellte Notdienstvorgänge (Benutzer/Externer Agent/System)": "Created emergency incidents (user/external agent/system)",
    "Erstellte Verkaufschancen (Benutzer/Externer Agent/System/glücklich/unglücklich)": "Created opportunities (user/external agent/system/happy/unhappy)",
    "Erstellungszeitpunkt": "Creation date and time",
    "Erweitertes Externes-BDC-Reporting": "Extended external BDC reporting",
    "Es ist ein Fehler aufgetreten.": "An error occurred.",
    "Es ist keine Leistungsart ausgewählt.": "No benefit type is selected.",
    "Es ist keine unbeurteilte Einzelverbindung verfügbar.": "There is no unassessed call detail record available.",
    "Es sind keine Rechnungspositionen vorhanden.": "There are no invoice line items.",
    "Es sind keine Schichtplanvorlagen vorhanden.": "There are no shift schedule templates available.",
    "Es steht ein Update zur Verfügung. Die Anwendung muss neu geladen werden.": "An update is available. The application has to be reloaded.",
    "Es wurde kein Elastic-Cluster gefunden. Bitte fügen Sie zunächst einen Elastic-Cluster hinzu und fahren Sie dann fort.": "No Elastic cluster found. Please add an Elastic cluster and try again.",
    "Es wurde kein Loadbalancer gefunden. Bitte fügen Sie zunächst einen Loadbalancer hinzu und fahren Sie dann fort.": "No Loadbalancer found. Please add a Loadbalancer and try again.",
    "Es wurde keine Datenbank gefunden. Bitte fügen Sie zunächst eine MySQL Datenbank hinzu und fahren Sie dann fort.": "No Database found. Please add a Database and try again.",
    "Es wurden keine Aufzeichnungen gefunden.": "No recordings found.",
    "Es wurden keine Einträge gefunden.": "No entries were found.",
    "Es wurden keine Einzelverbindungen gefunden.": "No call detail records found.",
    "Es wurden keine Elastic-Clusters gefunden.": "No Elastic clusters found.",
    "Es wurden keine Rechnungsempfänger gefunden.": "No invoice receivers found.",
    "Es wurden keine Umsätze gefunden.": "No revenues found.",
    "Es wurden keine Zielrufnummern gefunden.": "No target numbers found.",
    "Es wurden keine offenen Rechnungen mit passenden Überweisungen gefunden.": "No open invoices with matching credit transfers found.",
    "Es wurden {0} von {1} Rechnungen ignoriert, da sie bereits bezahlt oder storniert wurden bzw. keine Rechnungsempfänger hinterlegt sind.": "Ignored {0} out of {1} invoices, as they have already been already paid or canceled or there are no invoice receivers.",
    "Excel-Datei mit den Rechnungen {from} bis {to} für die BWA des Monats {month}.": "Excel file with the invoices {from} to {to} for the BWA of the month {month}.",
    "Externe-BDC-Minuten": "External BDC minutes",
    "Externe-BDC-Minuten (abgedeckt/generiert)": "External BDC minutes (covered/generated)",
    "Externer Link": "External link",
    "FTP-Zugang": "FTP account",
    "FTP-Zugang bearbeiten": "Edit FTP account",
    "FTP-Zugang hinzufügen": "Add FTP account",
    "FTP-Zugang löschen": "Delete FTP account",
    "FTP-Zugänge": "FTP accounts",
    "Fahrzeug": "Vehicle",
    "Fahrzeugbestand": "Inventory",
    "Fahrzeugbestand aufräumen": "Clean up inventory",
    "Fahrzeuge": "Vehicles",
    "Fahrzeugverwaltung": "Inventory management",
    "Falsches Abrechnungskonto": "Incorrect billing account",
    "Feedback": "Feedback",
    "Feedback an Team-Lead schicken": "Send feedback to team lead",
    "Feedback vorhanden": "Feedback available",
    "Feedbacks": "Feedbacks",
    "Fehler": "Errors",
    "Fehler automatisch überspringen": "Automatically skip errors",
    "Fehler beim Laden": "Loading Error",
    "Fehler beim Laden der Aktion.": "Error loading action",
    "Fehlerquote": "Error rate",
    "Filter": "Filter",
    "Finalisieren": "Finalize",
    "Firma": "Company",
    "Fortschritt": "Progress",
    "Fortsetzen": "Continue",
    "Fälle": "Cases",
    "Fälle (offen/zugeteilt)": "Cases (open/assigned)",
    "Für zurückdatierte Verträge wird automatisch eine Rechnung für die vorherige Abrechnungsperiode erstellt. Sind Sie sicher, dass Sie fortfahren möchten?": "For backdated contracts, an invoice is automatically created for the previous billing period. Are you sure you want to continue?",
    "Geburtsdatum": "Date of birth",
    "Geendet bis": "End from",
    "Geendet von": "End to",
    "Gefilterte Überweisungen herunterladen": "Download filtered credit transfers",
    "Gelöschter Benutzer": "Deleted user",
    "Gesamt": "Total",
    "Gesamt (Median)": "Total (median)",
    "Gesamt (Summe)": "Total (sum)",
    "Gesamtbrutto": "Total gross",
    "Gesamtdauer": "Total duration",
    "Gesamter Zeitraum": "Total period",
    "Gesamtnetto": "Total net",
    "Gesamtpreis": "Total price",
    "Gesamtspeicher": "Total storage",
    "Gesamtumsatz": "Total revenue",
    "Geschlecht": "Gender",
    "Gesprächsdauer": "Call duration",
    "Gesprächsdauer in Minuten": "Call duration in minutes",
    "Gesprächsdauer unter {0} Sekunden": "Call duration under {0} seconds",
    "Gestern": "Yesterday",
    "Grund Gesprächsende": "End of call reason",
    "Grund des Gesprächendes": "End of call reason",
    "Gruppierung": "Grouping",
    "Gut": "Good",
    "Gültige E-Mail-Adresse ist erforderlich": "Valid email address is required",
    "Gültige IBAN ist erforderlich": "Valid IBAN is required",
    "Gültige ID ist erforderlich": "Valid ID is required",
    "Gültige Zeitzone ist erforderlich": "Valid time zone is required",
    "Gültiger Benutzername ist erforderlich": "Valid Username is required",
    "Gültiger Name der Datenbank ist erforderlich": "Valid Database name is required",
    "Gültiger Name ist erforderlich": "Valid name is required",
    "Gültiger Schlüssel ist erforderlich": "Valid key is required",
    "Gültiges Präfix ist erforderlich": "Valid prefix is required",
    "Gültiges Währungskürzel ist erforderlich": "Valid currency code is required",
    "Hat Eintrag mit Eintragstyp": "Has entry with entry type",
    "Hauptdaten": "Main data",
    "Hell": "Light",
    "Heute": "Today",
    "Hinzufügen": "Add",
    "Hochladen": "Upload",
    "Host": "Host",
    "Händler": "Dealer",
    "Händlergruppe": "Dealer Group",
    "Händlerstatistik": "Dealer Statistic",
    "Händlerumsatz": "Dealer revenue",
    "Händlerumsatz (BWA)": "Dealer revenue (BWA)",
    "Händlerumsatz (Leistung)": "Dealer revenue (benefit)",
    "Händlerverzeichnis": "Dealer Directory",
    "IBAN": "IBAN",
    "ID": "ID",
    "Im Gespräch": "In call",
    "Image": "Image",
    "In die Zwischenablage kopieren": "Copy to clipboard",
    "Index in allen Partitionen neu bauen": "Rebuild index in all partitions",
    "Index in fehlgeschlagenen Partitionen neu bauen": "Rebuild index in failed partitions",
    "Index neu bauen": "Rebuild index",
    "Indexgröße in allen Partitionen abfragen": "Query index size in all partitions",
    "Inhalt erkannt": "Content detected",
    "Installieren und neu laden": "Install and reload",
    "Instanzen": "Instances",
    "Instanzen erstellen und starten": "Create instances and run",
    "Instanzgruppe": "Instancegroup",
    "Instanzgruppe erstellen": "Create Instance Group",
    "Interne Anmerkungen": "Internal remarks",
    "Ja": "Yes",
    "Jahr": "Year",
    "KPIs": "KPIs",
    "Kalenderwoche {0}": "Calendar week {0}",
    "Kein Inhalt erkannt": "No content detected",
    "Kein Rundschreiben erlauben": "Do not allow broadcast message",
    "Kein Standort verknüpft": "No location linked",
    "Keine Aktivitäten vorhanden": "No activities available",
    "Keine Daten vorhanden": "No data available",
    "Keine Datensätze gefunden.": "No record found.",
    "Keine Gruppierung": "No grouping",
    "Keine Matches | 1 Match | {count} Matches": "No matches | 1 match | {count} matches",
    "Keine Rechnung | 1 Rechnung | {count} Rechnungen": "No invoices | 1 invoice | {count} invoices",
    "Keine Rechnungsempfänger vorhanden": "No invoice receivers available",
    "Keine Verträge vorhanden": "No contracts available",
    "Keine offenen Rechnungen vorhanden": "No open invoices available",
    "Keine zugeordneten Vorlagen": "No assigned templates",
    "Keine | 1 Monat | {count} Monate": "None | 1 month | {count} months",
    "Kommentar": "Comment",
    "Kontakt, Fahrzeug und Aktivitäten": "Contact, vehicle and activities",
    "Kontakt/Vorgang": "Contact/Issue",
    "Kontakte": "Contacts",
    "Kontoumsätze hochladen": "Upload credit transfers",
    "Kurze Anrufe": "Short calls",
    "Kündigungsfrist": "Notice period",
    "Label": "Label",
    "Lade Daten": "Loading data",
    "Lade Elemente": "Loading elements",
    "Land": "Country",
    "Lange Anrufe": "Long calls",
    "Laufend": "Ongoing",
    "Leistung": "Performance",
    "Leistungsart": "Benefit type",
    "Leistungsmonat": "Benefit month",
    "Leistungsmonate": "Benefit months",
    "Leistungszeitraum": "Benefit period",
    "Leistungszeitraum started in {0}": "Benefit period starts in {0}",
    "Leitungen": "Lines",
    "Letzte 7 Tage mit unerkanntem Inhalt": "Last 7 days with undetected content",
    "Letzte Woche": "Last week",
    "Letzten 10 Wochen": "Last 10 weeks",
    "Letzten 14 Tage": "Last 14 days",
    "Letzten 7 Tage": "Last 7 days",
    "Letzten {0} Minuten": "Last {0} minutes",
    "Letzter Anruf beendet Zeitfenster Ende": "Last call ended time window end",
    "Letzter Anruf beendet Zeitfenster Start": "Last call ended time window start",
    "Letzter Match": "Last match",
    "Letzter Match (absteigend)": "Last match (descending)",
    "Letzter Match (aufsteigend)": "Last match (ascending)",
    "Letzter Monat": "Last month",
    "Letztes Quartal": "Last quarter",
    "Loadbalancer": "Loadbalancer",
    "Login": "Login",
    "Logout": "Logout",
    "Löschen": "Delete",
    "MMM YYYY": "MMM YYYY",
    "Mandatsreferenz": "Mandate reference",
    "Maschinentyp": "Machine Type",
    "Median": "Median",
    "Median {0}": "Median {0}",
    "Mehr als ein Standort verknüpft": "More than one location linked",
    "Meine Monatsübersicht": "My month overview",
    "Meine Tagesübersicht": "My day overview",
    "Melder": "Reporter",
    "Mindestwartezeit nicht angenommener Anrufe": "Minimum waiting time for unanswered calls",
    "Minuten": "Minutes",
    "Mitglieder": "Members",
    "Monat": "Month",
    "Monat bis": "Month until",
    "Monat des Jahres": "Month of the year",
    "Monat von": "Month from",
    "Monate": "Months",
    "Monatsumsatz (BWA)": "Monthly revenue (BWA)",
    "Monatsumsatz (Leistung)": "Monthly revenue (benefit)",
    "MySQL Datenbank": "MySQL database",
    "Mögliche Einzelverbindungen": "Possible call detail records",
    "Nach Benutzer": "By user",
    "Nach Händler": "By dealer",
    "Nach Monat": "By month",
    "Nach Partition": "By partition",
    "Nachname": "Family name",
    "Nachricht": "Message",
    "Name": "Name",
    "Name der Datenbank": "Database Name",
    "Name der Google Cloud statischen IP-Adresse": "Name of the Google Cloud Static IP address",
    "Name der Partition": "Partition Name",
    "Name des Google Cloud Loadbalancers": "Name of the Google Cloud Loadbalancer",
    "Nein": "No",
    "Nettobetrag": "Net amount",
    "Neu starten": "Reboot",
    "Neue MySQL Datenbank": "New MySQL Database",
    "Neue Partition": "New Partition",
    "Neue Version verfügbar!": "New version available!",
    "Neues Passwort": "New password",
    "Neues Passwort generieren": "Generate new password",
    "Nicht abgedeckte Abrechnungseinheiten": "Not covered billing units",
    "Nicht angenommen": "Unanswered",
    "Nicht geschlossen": "Not closed",
    "Nicht in 360dialog angelegt": "Not created in 360dialog",
    "Nicht-Standard-Version": "Non-standard version",
    "Notdienstvorgang": "Emergency incident",
    "Notdienstvorgang {0}": "Emergency incident {0}",
    "Notdienstvorgänge": "Emergency incidents",
    "Notdienstvorgänge (offen)": "Emergency incidents (open)",
    "Notiz/Anruf": "Note/Call",
    "Notizen": "Notes",
    "Nummer": "Number",
    "Nur Händler mit Vertrag anzeigen": "Show only dealers with a contract",
    "Nur Händler ohne Vertrag anzeigen": "Show only dealers without a contract",
    "Nächster Anruf Start Zeitfenster": "Next call start time window",
    "OK": "OK",
    "Offene Rechnungen": "Open invoices",
    "Offene Rechnungen vorhanden": "Open invoices available",
    "Ohne Benutzer": "Without user",
    "Ohne Partition": "Without partition",
    "Ohne Standort": "Without location",
    "Online": "Online",
    "Online und untätig seit mind. {0}": "Online and idle since at least {0}",
    "Open-Source-Lizenzen": "Open source licenses",
    "Ort": "City",
    "Partition": "Partition",
    "Partition auf Standard-Version zurücksetzen und neustarten": "Reset partition to standard version and reboot",
    "Partition deaktivieren": "Deactivate partition",
    "Partition neustarten": "Reboot partition",
    "Partitionen": "Partitions",
    "Partitionen neustarten": "Reboot partitions",
    "Partitionseinstellungen bearbeiten": "Edit partition settings",
    "Partitionsverwaltung": "Partition Management",
    "Passwort": "Password",
    "Passwort bestätigen": "Confirm password",
    "Passwort erfolgreich geändert": "Password has been changed successfully",
    "Passwort erneuern": "Renew password",
    "Passwort ist erforderlich": "Password is required",
    "Passwort zurückgesetzt": "Password was reset",
    "Passwort ändern": "Change password",
    "Passwortänderung ausstehend": "Password change pending",
    "Pausieren": "Pause",
    "Personalnummer": "Employee number",
    "Platzhalter": "Placeholder",
    "Postleitzahl": "ZIP Code",
    "Postmark Domain erstellen": "Create Postmark Domain",
    "Postmark Server erstellen": "Create Postmark Server",
    "Preis pro Einheit": "Price per unit",
    "Priorität": "Priority",
    "Profil bearbeiten": "Edit profile",
    "Profilbild": "Profile picture",
    "Provision": "Commission",
    "Provisionsempfänger": "Commission receiver",
    "Provisionsempfänger vorhanden": "Commission receiver available",
    "Provisionssatz": "Commission percentage",
    "Prozentual": "Percentage",
    "Präfix der Partition": "Partition prefix",
    "Qo [Quartal] YYYY": "Qo [quarter] YYYY",
    "Quartal": "Quarter",
    "Quartal des Jahres": "Quarter of the year",
    "Quote": "Rate",
    "Rechnung": "Invoice",
    "Rechnung als bezahlt markieren": "Mark invoice as paid",
    "Rechnung als unbezahlt markieren": "Mark invoice as unpaid",
    "Rechnung stornieren": "Cancel invoice",
    "Rechnung öffnen": "Open invoice",
    "Rechnungen": "Invoices",
    "Rechnungen als CSV herunterladen": "Download invoices as CSV",
    "Rechnungen mit passenden Überweisungen": "Invoices with matching credit transfers",
    "Rechnungen {0} bis {1}": "Invoices {0} to {1}",
    "Rechnungsanschrift": "Invoice address",
    "Rechnungsdetails": "Invoice details",
    "Rechnungsempfänger": "Invoice recipients",
    "Rechnungsempfänger löschen": "Delete invoice receiver",
    "Rechnungsempfänger vorhanden": "Invoice receiver available",
    "Rechnungserstellung": "Invoice creation",
    "Rechnungsnummer": "Invoice number",
    "Rechnungsposition hinzufügen": "Add invoice line item",
    "Rechnungsposition löschen": "Delete invoice line item",
    "Rechnungspositionen": "Invoice line items",
    "Referenz": "Reference",
    "Register-Nr.": "Company registration number",
    "Regulärer Account": "Regular account",
    "Ressource": "Resource",
    "Rollen": "Roles",
    "Rufnummer": "Phone number",
    "Rufnummer des Melders": "Phone number of reporter",
    "Rufnummer hinzufügen": "Add phone number",
    "Rufnummern": "Phone numbers",
    "Rundschreiben": "Broadcast message",
    "Rundschreiben abschicken": "Send broadcast message",
    "Rundschreiben erlauben": "Allow broadcast message",
    "SMS": "Text messages",
    "SMS (abgedeckt/gesendet)": "Text messages (covered/sent)",
    "SQL": "SQL",
    "SQL-Konsole": "SQL console",
    "Schaden": "Damage",
    "Schicht: {0} von {1}": "Shift: {0} of {1}",
    "Schichtabschnitt": "Shift section",
    "Schichtplanvorlage einfügen": "Insert shift schedule template",
    "Schichtplanvorlage hinzufügen": "Add shift schedule template",
    "Schichtplanvorlage löschen": "Delete shift schedule template",
    "Schichtplanvorlage ändern": "Edit shift schedule template",
    "Schichtplanvorlagen": "Shift schedule template",
    "Schließen": "Close",
    "Schlüssel der Partition": "Partition Key",
    "Schnellauswahl": "Quick selection",
    "Schulung": "Training",
    "Scroll-Fenstergröße": "Scroll window size",
    "Sehr gut": "Very good",
    "Sekunden bis zur Anrufverteilung": "Seconds until call distribution",
    "Sende Zahlungserinnerung": "Send invoice reminder",
    "Senden": "Send",
    "Sequenzabweichung": "Sequence offset",
    "Server-Fehler": "Server Error",
    "Sie haben nicht die Berechtigung auf Aktivitäten zuzugreifen.": "You do not have the permission to access activities.",
    "Sie haben nicht die Berechtigung auf Aufzeichnungen zuzugreifen.": "You do not have the permission to access recordings.",
    "Sie haben nicht die Berechtigung auf Einzelverbindungen zuzugreifen.": "You do not have the permission to access call detail records.",
    "Sie haben nicht die Berechtigung, das Passwort des Benutzers zurückzusetzen.": "You do not have the permission to reset the user password.",
    "Sie haben nicht die Berechtigung, den Benutzer mit diesen Rollen anzulegen.": "You do not have the permission to create the user with these roles.",
    "Sie haben nicht die Berechtigung, den Benutzer zu löschen.": "You do not have the permission to delete the user.",
    "Sie haben nicht die Berechtigung, die Benutzereinstellungen zu verändern.": "You do not have the permission to change the user settings.",
    "Sie müssen ihr Passwort ändern.": "You need to change your password.",
    "Sind Sie sicher, dass Sie Benutzer {0} sperren möchten, bis das Passwort geändert wird?": "Are you sure you want to lock user {0} until the password is changed?",
    "Sind Sie sicher, dass Sie alle Partitionen neustarten möchten?": "Are you sure you want to reboot all partitions?",
    "Sind Sie sicher, dass Sie das Abrechnungskonto löschen möchten ?": "Are you sure you want to delete the billing account?",
    "Sind Sie sicher, dass Sie das BDC-Team {0} löschen möchten?": "Are you sure you want to delete the BDC team {0}?",
    "Sind Sie sicher, dass Sie das Image \"{0}\" auf der Partition \"{1}\" installieren möchten?": "Are you sure you want to deploy image \"{0}\" on partition \"{1}\"?",
    "Sind Sie sicher, dass Sie das Passwort erneuern möchten?": "Are you sure you want to renew the password?",
    "Sind Sie sicher, dass Sie das Passwort für den Benutzer {0} zurücksetzen möchten ?": "Are you sure you want to reset the password for the user {0}?",
    "Sind Sie sicher, dass Sie das Rundschreiben abschicken möchten?": "Are you sure you want to send the broadcast message?",
    "Sind Sie sicher, dass Sie den Benutzer {0} löschen möchten?": "Are you sure you want to delete user {0}?",
    "Sind Sie sicher, dass Sie den Eintrag löschen möchten?": "Are you sure you want to delete the entry?",
    "Sind Sie sicher, dass Sie den FTP-Zugang löschen möchten?": "Are you sure you want to delete the FTP account?",
    "Sind Sie sicher, dass Sie den Index neu bauen möchten?": "Are you sure you want to rebuild the index?",
    "Sind Sie sicher, dass Sie den Rechnungsempfänger löschen möchten?": "Are you sure you want to delete the invoice receiver?",
    "Sind Sie sicher, dass Sie den Vertrag entfernen möchten?": "Are you sure you want to delete this contract?",
    "Sind Sie sicher, dass Sie die Aufgabe ausführen möchten?": "Are your sure you want to execute this task?",
    "Sind Sie sicher, dass Sie die Partition \"{0}\" deaktivieren möchten?": "Are you sure you want to deactivate partition \"{0}\"?",
    "Sind Sie sicher, dass Sie die Partition \"{0}\" neustarten möchten?": "Are you sure you want to reboot partition \"{0}\"?",
    "Sind Sie sicher, dass Sie die Rechnung mit der Nummer {0} stornieren möchten ?": "Are you sure you want to cancel the invoice {0}?",
    "Sind Sie sicher, dass Sie die Rechnungsposition \"{0}\" löschen möchten?": "Are you sure you want to delete the invoice line item \"{0}\"?",
    "Sind Sie sicher, dass Sie die Vorlage löschen möchten?": "Are you sure you want to delete the template?",
    "Sind Sie sicher, dass Sie einen Twilio-Account für CitNOW-Conversations erstellen möchten?": "Are you sure you want to create a twilio account for CitNOW conversations?",
    "Sitzung abgelaufen": "Session timed out",
    "Software": "Software",
    "Software-Tage": "Software days",
    "Software-Tage (gebucht/gesamt)": "Software days (booked/total)",
    "Softwareanteil": "Software ratio",
    "Soll eine E-Mail an die Rechnungsempfänger geschickt werden?": "Should an email be sent to the invoice receivers?",
    "Sonstige": "Other",
    "Sonstiges": "Other",
    "Sortierung": "Sort",
    "Spam-Meldungen": "Spam reports",
    "Speicher": "Storage",
    "Speicher aufräumen": "Clean up storage",
    "Speicherinformation": "Storage information",
    "Speichern": "Save",
    "Speichern und gefilterte Überweisungen herunterladen": "Save and download filtered credit transfers",
    "Sprache/Region": "Language/Region",
    "Staat": "State",
    "Stammdaten": "Master data",
    "Standard-Click-to-Call-Anruferkennung verifizieren": "Verify default click-to-call caller ID",
    "Standort": "Location",
    "Standorte": "Locations",
    "Standorte des Abrechnungskontos": "Locations of the billing account",
    "Statische IP-Adresse": "Static IP address",
    "Statistiken": "Statistics",
    "Status": "Status",
    "Storniert": "Cancelled",
    "Stornorechnung öffnen": "Open cancel invoice",
    "Straße und Hausnummer": "Street",
    "Stunde": "Hour",
    "Stunden": "Hours",
    "Suche": "Search",
    "Suchen": "Search",
    "Suchindexe": "Search indexes",
    "Summe": "Sum",
    "Summe (netto)": "Sum (net)",
    "System-E-Mail": "System email",
    "Tabellenansicht ausblenden": "Hide table view",
    "Tabellenansicht einblenden": "Show table view",
    "Tag": "Day",
    "Tag des Jahres": "Day of the year",
    "Tag des Monats": "Day of the month",
    "Tage mit...": "Days with...",
    "Tatsächlich eingesetzte Agenten": "Actual deployed agents",
    "Team": "Team",
    "Team-Lead": "Team lead",
    "Teilschicht: {0}": "Subshift: {0}",
    "Terminierung beantragt am": "Termination requested at",
    "Text": "Text",
    "Text (absteigend)": "Text (descending)",
    "Text (aufsteigend)": "Text (ascending)",
    "Titel": "Title",
    "Twilio": "Twilio",
    "Twilio Legacy-Account": "Twilio legacy account",
    "Twilio-Account für CitNOW-Conversations eingerichtet": "Twilio account set up for CitNOW Conversations",
    "Twilio-Account für CitNOW-Conversations erstellen": "Create twilio account for CitNOW conversations",
    "Twilio-Api-Key": "Twilio api key",
    "Twilio-Api-Key für CitNOW-Conversations anzeigen": "Show twilio api key for CitNOW conversations",
    "Twilio-Api-Key für CitNOW-Conversations erstellen": "Create twilio api key for CitNOW conversations",
    "Twilio-Unterkonto erstellen": "Create Twilio sub account",
    "Typ": "Type",
    "Typ (absteigend)": "Type (descending)",
    "Typ (aufsteigend)": "Type (ascending)",
    "Typ des Notdienstvorgangs": "Emergency incident type",
    "Typ und Details": "Type and details",
    "URL": "URL",
    "USt-IdNr.": "VAT-ID",
    "Uhrzeit": "Time",
    "Umsatz": "Revenue",
    "Umsatzvorschau": "Revenue forecast",
    "Umsätze": "Revenues",
    "Umsätze als CSV herunterladen": "Download revenues as CSV",
    "Unbekannt": "Unknown",
    "Unbekannte Partition": "Unknown partition",
    "Unbekannter Benutzer": "Unknown user",
    "Unbekannter Standort": "Unknown location",
    "Unbekanntes Abrechnungskonto": "Unknown billing account",
    "Unbenutzer Speicher": "Unused storage",
    "Unbestimmte Partition": "Undetermined partition",
    "Unbestimmter Standort": "Undetermined location",
    "Unbeurteilbar": "Unassessable",
    "Ungenügend": "Insufficient",
    "Urlaub": "Holiday",
    "Variabel": "Variable",
    "Verarbeite {0} von {1}": "Process {0} of {1}",
    "Vergleich": "Compare",
    "Vergleichstag": "Comparison day",
    "Verkaufschancen": "Opportunities",
    "Verkaufschancen (offen/zugeteilt)": "Opportunities (open/assigned)",
    "Verlauf": "History",
    "Verpasst": "Missed",
    "Verpasst (Median)": "Missed (median)",
    "Vertrag bearbeiten": "Edit contract",
    "Vertrag hinzufügen": "Add contract",
    "Vertrag löschen": "Delete contract",
    "Vertragsbeginn": "Contract begin",
    "Vertragsende": "Contract end",
    "Vertragslaufzeit": "Duration",
    "Verträge": "Contracts",
    "Verträge vorhanden": "Contracts available",
    "Verträge, Abrechnungs-/Zahlungsdaten und Umsätze hochladen": "Upload contracts, billing/invoicing data, and revenues",
    "Videochats": "Videochats",
    "Volumen": "Volume",
    "Vor 7 Tagen": "7 days ago",
    "Vorg./Anruf": "Issue/Call",
    "Vorgang/Anruf": "Issue/Call",
    "Vorgänge": "Issues",
    "Vorgänge nach Auflegen": "Issues after hang up",
    "Vorgänge ohne Anruf": "Issues without call",
    "Vorherige Woche": "Previous week",
    "Vorheriger Monat": "Previous month",
    "Vorheriges Jahr": "Previous year",
    "Vorheriges Quartal": "Previous quarter",
    "Vorletzter Monat": "Penultimate month",
    "Vorname": "Given name",
    "Vorschau": "Preview",
    "Vorschau schließen": "Close preview",
    "Vortag": "Previous day",
    "Wartezeit": "Waiting time",
    "Wartezeit in Sekunden": "Wait time in seconds",
    "Weitere Leistungsarten": "More benefit types",
    "Weitere Standorte": "More locations",
    "WhatsApp": "WhatsApp",
    "WhatsApp-Nachrichten (empfangen/gesendet)": "WhatsApp messages (received/sent)",
    "WhatsApp-Rufnummern": "WhatsApp numbers",
    "WhatsApp-Zugänge": "WhatsApp accounts",
    "Wiederkehrend": "Recurring",
    "Wo [Woche] GGGG": "Wo [week] GGGG",
    "Woche": "Week",
    "Woche des Jahres": "Week of the year",
    "Wochentag": "Day of the week",
    "Währung": "Currency",
    "Wöchentliche Arbeitszeit": "Weekly working hours",
    "XML-Datei für den automatisierten SEPA-Lastschrift-Einzug": "XML-file for automated SEPA direct debit",
    "Zahlungserinnerung": "Invoice reminder",
    "Zahlungserinnerung senden": "Send invoice reminder",
    "Zahlungserinnerung öffnen": "Open invoice reminder",
    "Zahlungsmethode": "Payment method",
    "Zeitintervall": "Time interval",
    "Zeitraum": "Period",
    "Zeitraum für den der Notfallvorgang offen ist": "Time span for which the emergency incident is open",
    "Zeitzone": "Time Zone",
    "Zielrufnummer": "Target number",
    "Zielrufnummer bearbeiten": "Edit target number",
    "Zielrufnummern": "Target phone numbers",
    "Zugeordnete Agenten": "Assigned agents",
    "Zusammenfassung": "Summary",
    "abgelaufen": "expired",
    "abgerechnet von 360dialog seit": "billed by 360dialog since",
    "absteigend": "descending",
    "abwesend": "absent",
    "aktiv": "active",
    "angelegt am": "created at",
    "angenommen": "answered",
    "aufgelegt": "hang up",
    "aufsteigend": "ascending",
    "ausstehend": "pending",
    "bevorstehend": "upcoming",
    "bezahlt": "paid",
    "bis": "to",
    "eingetroffen": "arrived",
    "erstellt am {0}": "created {0}",
    "freie Agenten": "free agents",
    "gekündigt": "cancelled",
    "gemeldet am": "reported on",
    "heute": "today",
    "in Pause": "in break",
    "inaktiv": "inactive",
    "inkl. Pause": "incl. break",
    "ja": "yes",
    "keine Agenten | 1 Agent | {count} Agenten": "no agents | 1 agent | {count} agents",
    "keine Dateien | 1 Datei | {n} Dateien": "no files | 1 file | {n} files",
    "laufend": "ongoing",
    "letzte Aktivität am {0}": "last activity {0}",
    "letzten 0 Minuten | letzte Minute | letzten {count} Minuten": "last 0 minutes | last minute | last {count} minutes",
    "monatl. Grundgebühr": "Monthly fee",
    "nein": "no",
    "offen": "open",
    "offline": "offline",
    "ohne Pause | 1 min Pause | {count} min Pause": "w/o break | 1 min break | {count} min break",
    "online": "online",
    "storniert": "cancelled",
    "terminiert am": "terminated at",
    "unfinalisiert": "unfinalized",
    "ungekündigt": "not cancelled",
    "untätig": "idle",
    "untätig von {0} bis {1}": "idle from {0} to {1}",
    "verknüpfte Standorte": "Linked locations",
    "verpasst": "missed",
    "von": "from",
    "wartend und schließlich angenommen": "waiting and eventually answered",
    "wartend und schließlich aufgelegt": "waiting and eventually hang up",
    "{0} Aktivitäten insgesamt": "{0} activities in total",
    "{0} Stunden": "{0} hours",
    "{0} ist keine gültige E-Mail Adresse.": "{0} is no valid email address.",
    "{0} von {1} Stunden": "{0} of {1} hours",
    "{0} von {1} verarbeitet": "Processed {0} of {1}",
    "{0} wurde erfolgreich in die Zwischenablage kopiert.": "{0} was copied to clipboard successfully.",
    "{0}-{1} von {2}": "{0}-{1} of {2}",
    "{0}-{1} von {2}+": "{0}-{1} of {2}+",
    "{from} bis {to}": "{from} to {to}",
    "Überschneidung": "Overlap",
    "Überspringen": "Skip",
    "ø Abgerechnete Minuten": "ø billed minutes",
    "ø Angenommen": "ø answered",
    "ø Gesprächsdauer": "ø call duration",
    "ø Gesprächszeit": "ø call time",
    "ø Wartezeit (angenommen): {0}": "ø waiting time (answered): {0}",
    "ø {0}": "ø {0}",
    "validation": {
        "no-partition-deployment": "No partition image available yet",
        "duplicate-database-id": "Name is already in use",
        "duplicate-partition-id": "Key is already in use",
        "duplicate-partition-prefix": "Prefix is already in use",
        "duplicate-username": "Username is already in use",
        "duplicate-receiver": "Receiver already exists",
        "billing-account-in-use": "Billing account is still in use",
        "contract-in-use": "Contract is still in use",
        "password-wrong": "The entered password is invalid",
        "invoice-lock-not-available": "Invoice creation is currently not possible, please try again later",
        "too-many-login-attempts": "You had too many unsuccessful login attempts. Please wait for 10 minutes and then try again.",
        "sql-syntax-error": "SQL syntax error: {0}",
        "new-password-equals-old-password": "The new password must be different from the old password.",
        "duplicate-citnow-conversations-user-id": "The CitNOW Conversations user ID is already in use"
    },
    "locale": {
        "de": "Deutsch (Deutschland)",
        "en": "English (US)"
    },
    "enum": {
        "Role": {
            "ACCOUNTANT": "Accountant",
            "AGENT": "Agent",
            "BDC_ADMIN": "BDC Admin",
            "BDC_MANAGER": "BDC Manager",
            "CONTRACT_MANAGER": "Contract manager",
            "CT_ADMIN": "Admin",
            "CT_SUPER_ADMIN": "Super Admin",
            "FEATURE_A_TESTER": "Feature A tester",
            "FEATURE_B_TESTER": "Feature B tester",
            "INFRASTRUCTURE_MANAGER": "Infrastructure manager",
            "PARTITION_MANAGER": "Partition manager",
            "QUALITY_MANAGER": "Quality Manager"
        },
        "PaymentMethod": {
            "BANK_TRANSFER": "Bank transfer",
            "SEPA_B2B_DIRECT_DEBIT": "SEPA B2B direct debit"
        },
        "StateType": {
            "DE": "Federal state"
        },
        "Gender": {
            "MALE": "male",
            "FEMALE": "female",
            "DIVERSE": "diverse"
        },
        "IncomingEmailBlacklistEntryType": {
            "FROM_ADDRESS_STARTS_WITH": "Sender address starts with",
            "FROM_ADDRESS_CONTAINS": "Sender address contains",
            "FROM_ADDRESS_ENDS_WITH": "Sender address ends with",
            "FROM_NAME_STARTS_WITH": "Sender name starts with",
            "FROM_NAME_CONTAINS": "Sender name contains",
            "FROM_NAME_ENDS_WITH": "Sender name ends with",
            "SUBJECT_STARTS_WITH": "Subject starts with",
            "SUBJECT_CONTAINS": "Subject contains",
            "SUBJECT_ENDS_WITH": "Subject ends with"
        },
        "EmergencyIncidentSummarySearchOrder": {
            "CREATED_DESC": "Created (descending)",
            "CREATED_ASC": "Created (ascending)",
            "LAST_ACTIVITY_DESC": "Last activity (descending)",
            "LAST_ACTIVITY_ASC": "Last activity (ascending)"
        },
        "EmergencyIncidentType": {
            "BREAKDOWN": "Breakdown",
            "ACCIDENT": "Accident"
        },
        "IncomingEmailSummarySearchOrder": {
            "CREATED_DESC": "Created (descending)",
            "CREATED_ASC": "Created (ascending)",
            "FROM_NAME_DESC": "Sender name (descending)",
            "FROM_NAME_ASC": "Sender name (ascending)",
            "FROM_ADDRESS_DESC": "Sender address (descending)",
            "FROM_ADDRESS_ASC": "Sender address (ascending)",
            "SUBJECT_DESC": "Subject (descending)",
            "SUBJECT_ASC": "Subject (ascending)"
        },
        "IncomingEmailSummaryCountType": {
            "FROM_NAME": "Sender name",
            "FROM_ADDRESS": "Sender address"
        },
        "DealerStatisticSearchOrder": {
            "PARTITION_ASC": "Partition (ascending)",
            "PARTITION_DESC": "Partition (descending)",
            "DEALER_ASC": "Dealer (ascending)",
            "DEALER_DESC": "Dealer (descending)",
            "SOFWARE_LICENSE_DAYS_COVERED_ASC": "Number of days with booked software (ascending)",
            "SOFWARE_LICENSE_DAYS_COVERED_DESC": "Number of days with booked software (descending)",
            "CLICK_TO_CALL_MINUTES_ASC": "Number of generated click-to-call minutes (ascending)",
            "CLICK_TO_CALL_MINUTES_DESC": "Number of generated click-to-call minutes (descending)",
            "CLICK_TO_CALL_MINUTES_COVERED_ASC": "Number of covered click-to-call minutes (ascending)",
            "CLICK_TO_CALL_MINUTES_COVERED_DESC": "Number of covered click-to-call minutes (descending)",
            "CALL_TRACKING_MINUTES_ASC": "Number of generated call-tracking minutes (ascending)",
            "CALL_TRACKING_MINUTES_DESC": "Number of generated call-tracking minutes (descending)",
            "CALL_TRACKING_MINUTES_COVERED_ASC": "Number of covered call-tracking minutes (ascending)",
            "CALL_TRACKING_MINUTES_COVERED_DESC": "Number of covered call-tracking minutes (descending)",
            "OUTGOING_SMS_ASC": "Number of sent text messages (ascending)",
            "OUTGOING_SMS_DESC": "Number of sent text messages (descending)",
            "OUTGOING_SMS_COVERED_ASC": "Number of covered text messages (ascending)",
            "OUTGOING_SMS_COVERED_DESC": "Number of covered text messages (descending)",
            "EXTERNAL_BDC_MINUTES_ASC": "Number of generated external BDC minutes (ascending)",
            "EXTERNAL_BDC_MINUTES_DESC": "Number of generated external BDC minutes (descending)",
            "EXTERNAL_BDC_MINUTES_COVERED_ASC": "Number of covered external BDC minutes (ascending)",
            "EXTERNAL_BDC_MINUTES_COVERED_DESC": "Number of covered external BDC minutes (descending)",
            "INCOMING_WHATSAPP_MESSAGES_ASC": "Number of received WhatsApp messages (ascending)",
            "INCOMING_WHATSAPP_MESSAGES_DESC": "Number of received WhatsApp messages (descending)",
            "OUTGOING_WHATSAPP_MESSAGES_ASC": "Number of sent WhatsApp messages (ascending)",
            "OUTGOING_WHATSAPP_MESSAGES_DESC": "Number of sent WhatsApp messages (descending)",
            "WHATSAPP_NUMBERS_ASC": "Number of WhatsApp numbers (ascending)",
            "WHATSAPP_NUMBERS_DESC": "Number of WhatsApp  numbers (descending)",
            "VIDEOCHATS_ASC": "Number of videochats (ascending)",
            "VIDEOCHATS_DESC": "Number of videochats (descending)",
            "TOTAL_USERS_ASC": "Number of created users (ascending)",
            "TOTAL_USERS_DESC": "Number of created users (descending)",
            "ONLINE_USERS_ASC": "Number of online users (ascending)",
            "ONLINE_USERS_DESC": "Number of online users (descending)",
            "UNDELETED_INVENTORY_VEHICLES_ASC": "Number of active inventory vehicles (ascending)",
            "UNDELETED_INVENTORY_VEHICLES_DESC": "Number of active inventory vehicles (descending)",
            "ACTIVE_INVENTORY_VEHICLE_INQUIRIES_ASC": "Number of active inventory vehicle search inquiries (ascending)",
            "ACTIVE_INVENTORY_VEHICLE_INQUIRIES_DESC": "Number of active inventory vehicle search inquiries (descending)",
            "UNDELETED_CONTACTS_ASC": "Number of active contacts (ascending)",
            "UNDELETED_CONTACTS_DESC": "Number of active contacts (descending)",
            "CASES_OPEN_ASC": "Number of open cases (ascending)",
            "CASES_OPEN_DESC": "Number of open cases (descending)",
            "CASES_ASSIGNED_ASC": "Number of assigned cases (ascending)",
            "CASES_ASSIGNED_DESC": "Number of assigned cases (descending)",
            "OPPORTUNITIES_OPEN_ASC": "Number of open opportunities (ascending)",
            "OPPORTUNITIES_OPEN_DESC": "Number of open opportunities (descending)",
            "OPPORTUNITIES_ASSIGNED_ASC": "Number of assigned opportunities (ascending)",
            "OPPORTUNITIES_ASSIGNED_DESC": "Number of assigned opportunities (descending)",
            "EMERGENCY_INCIDENTS_OPEN_ASC": "Number of open emergency incidents (ascending)",
            "EMERGENCY_INCIDENTS_OPEN_DESC": "Number of open emergency incidents (descending)",
            "CASES_CREATED_BY_PARTITION_USER_ASC": "Number of cases created by users (ascending)",
            "CASES_CREATED_BY_PARTITION_USER_DESC": "Number of cases created by users (descending)",
            "CASES_CREATED_BY_CT_USER_ASC": "Number of cases created by external agents (ascending)",
            "CASES_CREATED_BY_CT_USER_DESC": "Number of cases created by external agents (descending)",
            "CASES_CREATED_BY_SYSTEM_ASC": "Number of cases created by the system (ascending)",
            "CASES_CREATED_BY_SYSTEM_DESC": "Number of cases created by the system (descending)",
            "CASES_CREATED_SENTIMENT_HAPPY_PER_CREATED_CASES_ASC": "Ratio of created cases with happy sentiment to all created cases (ascending)",
            "CASES_CREATED_SENTIMENT_HAPPY_PER_CREATED_CASES_DESC": "Ratio of created cases with happy sentiment to all created cases (descending)",
            "CASES_CREATED_SENTIMENT_UNHAPPY_PER_CREATED_CASES_ASC": "Ratio of created cases with unhappy sentiment to all created cases (ascending)",
            "CASES_CREATED_SENTIMENT_UNHAPPY_PER_CREATED_CASES_DESC": "Ratio of created cases with unhappy sentiment to all created cases (descending)",
            "OPPORTUNITIES_CREATED_BY_PARTITION_USER_ASC": "Number of opportunities created by users (ascending)",
            "OPPORTUNITIES_CREATED_BY_PARTITION_USER_DESC": "Number of opportunities created by users (descending)",
            "OPPORTUNITIES_CREATED_BY_CT_USER_ASC": "Number of opportunities created by external agents (ascending)",
            "OPPORTUNITIES_CREATED_BY_CT_USER_DESC": "Number of opportunities created by external agents (descending)",
            "OPPORTUNITIES_CREATED_BY_SYSTEM_ASC": "Number of opportunities created by the system (ascending)",
            "OPPORTUNITIES_CREATED_BY_SYSTEM_DESC": "Number of opportunities created by the system (descending)",
            "OPPORTUNITIES_CREATED_SENTIMENT_HAPPY_PER_CREATED_OPPORTUNITIES_ASC": "Ratio of created opportunities with happy sentiment to all created opportunities (ascending)",
            "OPPORTUNITIES_CREATED_SENTIMENT_HAPPY_PER_CREATED_OPPORTUNITIES_DESC": "Ratio of created opportunities with happy sentiment to all created opportunities (descending)",
            "OPPORTUNITIES_CREATED_SENTIMENT_UNHAPPY_PER_CREATED_OPPORTUNITIES_ASC": "Ratio of created opportunities with unhappy sentiment to all created opportunities (ascending)",
            "OPPORTUNITIES_CREATED_SENTIMENT_UNHAPPY_PER_CREATED_OPPORTUNITIES_DESC": "Ratio of created opportunities with unhappy sentiment to all created opportunities (descending)",
            "EMERGENCY_INCIDENTS_CREATED_BY_PARTITION_USER_ASC": "Number of emergency incidents created by users (ascending)",
            "EMERGENCY_INCIDENTS_CREATED_BY_PARTITION_USER_DESC": "Number of emergency incidents created by users (descending)",
            "EMERGENCY_INCIDENTS_CREATED_BY_CT_USER_ASC": "Number of emergency incidents created by external agents (ascending)",
            "EMERGENCY_INCIDENTS_CREATED_BY_CT_USER_DESC": "Number of emergency incidents created by external agents (descending)",
            "EMERGENCY_INCIDENTS_CREATED_BY_SYSTEM_ASC": "Number of emergency incidents created by the system (ascending)",
            "EMERGENCY_INCIDENTS_CREATED_BY_SYSTEM_DESC": "Number of emergency incidents created by the system (descending)"
        },
        "ActionType": {
            "ADD_ACTIVITY_CREATING_CASE": "Case created",
            "ADD_ACTIVITY_CREATING_OPPORTUNITY": "Opportunity created",
            "ADD_ACTIVITY_CREATING_EMERGENCY_INCIDENT": "Emergency incident created",
            "ADD_ACTIVITY_EXISTING_CASE": "Note created (case)",
            "ADD_ACTIVITY_EXISTING_OPPORTUNITY": "Note created (opportunity)",
            "ADD_ACTIVITY_EXISTING_EMERGENCY_INCIDENT": "Note created (emergency incident)",
            "ADD_CONTACT": "Contact created"
        },
        "ExternalBdcCdrSearchOrder": {
            "BEGIN_ASC": "Begin (ascending)",
            "BEGIN_DESC": "Begin (descending)",
            "ACCEPT_ASC": "Acceptance (ascending)",
            "ACCEPT_DESC": "Acceptance (descending)",
            "END_ASC": "End (ascending)",
            "END_DESC": "End (descending)"
        },
        "ContractSearchOrder": {
            "CREATED_DESC": "Created (descending)",
            "CREATED_ASC": "Created (ascending)",
            "BEGIN_DESC": "Contract begin (descending)",
            "BEGIN_ASC": "Contract begin (ascending)",
            "END_DESC": "Contract end (descending)",
            "END_ASC": "Contract end (ascending)"
        },
        "BillingAccountSearchOrder": {
            "CREATED_DESC": "Created (descending)",
            "CREATED_ASC": "Created (ascending)"
        },
        "DutyRosterEntryType": {
            "ANSWER_INCOMING_ORDINARY_CALLS": "Answer incoming calls (normal calls)",
            "ANSWER_INCOMING_PRIORITY_CALLS": "Answer incoming calls (priority calls)",
            "TRAINING": "Training",
            "OTHER_WORK": "Other work",
            "HOLIDAY": "Holiday",
            "OVERTIME_COMPENSATION": "Overtime compensation",
            "OTHER_TIME_COMPENSATION": "Other time compensation",
            "SICKNESS": "Sickness",
            "SICK_CHILD": "Sick child",
            "OTHER_UNPAID_ABSENCE": "Other absence (unpaid)"
        },
        "DisconnectStatus": {
            "UNKNOWN": "unknown",
            "BUSY": "busy",
            "REJECT": "rejected",
            "NO_ANSWER": "no answer",
            "UNKNOWN_NUMBER": "unknown number",
            "UNREACHABLE": "unreachable",
            "NETWORK_CONGESTION": "network congested",
            "CALL_DISCONNECTED": "agent disconnected",
            "NO_CHANNEL_AVAILABLE": "no channel available",
            "ORIGINATOR_DISCONNECTED": "caller disconnected",
            "OTHER": "other"
        },
        "AgentActionStatisticsDataTableSearchOrder": {
            "ELAPSED_SECONDS_ANSWER_INCOMING_ORDINARY_CALLS_DESC": "Working hours in which incoming normal calls were answered (descending)",
            "ELAPSED_SECONDS_ANSWER_INCOMING_ORDINARY_CALLS_ASC": "Working hours in which incoming normal calls were answered (ascending)",
            "ELAPSED_SECONDS_ANSWER_INCOMING_PRIORITY_CALLS_DESC": "Working hours in which incoming priority calls were answered (descending)",
            "ELAPSED_SECONDS_ANSWER_INCOMING_PRIORITY_CALLS_ASC": "Working hours in which incoming priority calls were answered (ascending)",
            "ELAPSED_SECONDS_TRAINING_DESC": "Working hours spent with training (descending)",
            "ELAPSED_SECONDS_TRAINING_ASC": "Working hours spent with training (ascending)",
            "ELAPSED_SECONDS_OTHER_WORK_DESC": "Working hours spent with other work (descending)",
            "ELAPSED_SECONDS_OTHER_WORK_ASC": "Working hours spent with other work (ascending)",
            "ELAPSED_SECONDS_TOTAL_DESC": "Total working hours (descending)",
            "ELAPSED_SECONDS_TOTAL_ASC": "Total working hours (ascending)",
            "CALLS_ACCEPTED_DESC": "Calls (descending)",
            "CALLS_ACCEPTED_ASC": "Calls (ascending)",
            "CALL_PERFORMANCE_DESC": "Performance (calls) (descending)",
            "CALL_PERFORMANCE_ASC": "Performance (calls) (ascending)",
            "BILLED_MINUTES_DESC": "Minutes (descending)",
            "BILLED_MINUTES_ASC": "Minutes (ascending)",
            "BILLED_MINUTES_PERFORMANCE_DESC": "Performance (minutes) (descending)",
            "BILLED_MINUTES_PERFORMANCE_ASC": "Performance (minutes) (ascending)",
            "ERRORS_REPORTED_DESC": "Errors (descending)",
            "ERRORS_REPORTED_ASC": "Errors (ascending)",
            "ERROR_RATIO_DESC": "Error rate (descending)",
            "ERROR_RATIO_ASC": "Error rate (ascending)",
            "ISSUES_CREATED_TO_CALLS_ACCEPTED_RATIO_DESC": "Issue/Call (descending)",
            "ISSUES_CREATED_TO_CALLS_ACCEPTED_RATIO_ASC": "Issue/Call (ascending)",
            "NOTES_CREATED_TO_CALLS_ACCEPTED_RATIO_DESC": "Note/Call (descending)",
            "NOTES_CREATED_TO_CALLS_ACCEPTED_RATIO_ASC": "Note/Call (ascending)",
            "CONTACTS_CREATED_TO_ISSUES_CREATED_RATIO_DESC": "Contact/Issue (descending)",
            "CONTACTS_CREATED_TO_ISSUES_CREATED_RATIO_ASC": "Contact/Issue (ascending)",
            "SECONDS_ISSUES_CREATED_AFTER_CALL_DESC": "Data maintenance (descending)",
            "SECONDS_ISSUES_CREATED_AFTER_CALL_ASC": "Data maintenance (ascending)",
            "SECONDS_ISSUES_CREATED_AFTER_CALL_MEDIAN_DESC": "Data maintenance (median) (descending)",
            "SECONDS_ISSUES_CREATED_AFTER_CALL_MEDIAN_ASC": "Data maintenance (Median) (ascending)",
            "SCHEDULED_DAYS_WITH_ANSWER_INCOMING_ORDINARY_CALLS_DESC": "Days on which incoming normal calls were answered (descending)",
            "SCHEDULED_DAYS_WITH_ANSWER_INCOMING_ORDINARY_CALLS_ASC": "Days on which incoming normal calls were answered (ascending)",
            "SCHEDULED_DAYS_WITH_ANSWER_INCOMING_PRIORITY_CALLS_DESC": "Days on which incoming priority calls were answered (descending)",
            "SCHEDULED_DAYS_WITH_ANSWER_INCOMING_PRIORITY_CALLS_ASC": "Days on which incoming priority calls were answered (ascending)",
            "SCHEDULED_DAYS_WITH_TRAINING_DESC": "Days with training (descending)",
            "SCHEDULED_DAYS_WITH_TRAINING_ASC": "Days with training (ascending)",
            "SCHEDULED_DAYS_WITH_OTHER_WORK_DESC": "Days with other work (descending)",
            "SCHEDULED_DAYS_WITH_OTHER_WORK_ASC": "Days with other work (ascending)",
            "SCHEDULED_DAYS_WITH_HOLIDAY_DESC": "Days with holiday (descending)",
            "SCHEDULED_DAYS_WITH_HOLIDAY_ASC": "Days with holiday (ascending)",
            "SCHEDULED_DAYS_WITH_OVERTIME_COMPENSATION_DESC": "Days with overtime compensation (descending)",
            "SCHEDULED_DAYS_WITH_OVERTIME_COMPENSATION_ASC": "Days with overtime compensation (ascending)",
            "SCHEDULED_DAYS_WITH_OTHER_TIME_COMPENSATION_DESC": "Days with other time compensation (descending)",
            "SCHEDULED_DAYS_WITH_OTHER_TIME_COMPENSATION_ASC": "Days with other time compensation (ascending)",
            "SCHEDULED_DAYS_WITH_SICKNESS_DESC": "Days with sickness (descending)",
            "SCHEDULED_DAYS_WITH_SICKNESS_ASC": "Days with sickness (ascending)",
            "SCHEDULED_DAYS_WITH_SICK_CHILD_DESC": "Days with sick child (descending)",
            "SCHEDULED_DAYS_WITH_SICK_CHILD_ASC": "Days with sick child (ascending)",
            "SCHEDULED_DAYS_WITH_OTHER_UNPAID_ABSENCE_DESC": "Days with other unpaid absence (descending)",
            "SCHEDULED_DAYS_WITH_OTHER_UNPAID_ABSENCE_ASC": "Days with other unpaid absence (ascending)"
        },
        "ActionSearchOrder": {
            "CREATED_DESC": "Created (descending)",
            "CREATED_ASC": "Created (ascending)",
            "FEEDBACK_TYPE_DESC": "Feedback type (descending)",
            "FEEDBACK_TYPE_ASC": "Feedback type (ascending)"
        }
    },
    "marketingEmailPlaceholders": {
        "SALUTATION": "Salutation",
        "UNSUBSCRIBE_LINK": "Unsubscribe link"
    },
    "marketingEmailPlaceholdersDescription": {
        "SALUTATION": "Formal salutation of the associated contact, e.g. Dear Mr. Smith",
        "UNSUBSCRIBE_LINK": "Link which unsubscribes the receiver if clicked"
    },
    "email": {
        "invoice": {
            "reminder": {
                "subject": "Invoice reminder",
                "content": "<p>Dear sir or madam,</p>\n<p>in the hectic everyday life some things get lost. Therefore, today we would like to remind you of our outstanding invoice regarding the service period from %INVOICE_PERIOD_BEGIN% to %INVOICE_PERIOD_END%.</p>\n<p>The invoice is attached.</p>\n<p>Please wire the amount due to our bank account.</p>\n<p>If you have paid the amount in the meantime, please consider this invoice reminder as irrelevant.</p>\n<p>%ORGANISATION_COMPANY%</p>\n<p>%ORGANISATION_ADDRESS1%<br />\n%ORGANISATION_ADDRESS2%<br />\n%ORGANISATION_ZIP% %ORGANISATION_CITY%</p>\n<p>Phone: %ORGANISATION_BILLING_PHONE%<br />\nEmail: %ORGANISATION_BILLING_EMAIL%<br />\nWeb: %ORGANISATION_WEBSITE%<br />\nVAT number: %ORGANISATION_VAT_NUMBER%</p>\n<p>Credit institute: %ORGANISATION_BANK_NAME%<br />\nIBAN: %ORGANISATION_BANK_ACCOUNT_NUMBER%<br />\nBIC: %ORGANISATION_BANK_CODE%<br />\nCreditor ID: %ORGANISATION_SEPA_CREDITOR_IDENTIFIER%</p>"
            }
        }
    }
};

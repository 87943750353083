
import AgentDayBottomSheet from "./AgentDayBottomSheet.vue";
import { ExtendedAgentStatisticsData, subtractDutyRosterEntryTypeStatisticsData } from "./agentStatisticsUtils";
import { DutyRosterEntryTypeStatisticsData } from "@/api/agentStatistics";
import { Permission } from "@/api/userSession";
import { ExtendedCallCenterStatisticsData } from "@/app/pages/callcenterstatistics/callCenterStatisticsUtils";
import DutyRosterPanelBottomSheet from "@/app/pages/dutyrosters/DutyRosterPanelBottomSheet.vue";
import { userSession } from "@/store/userSession";
import { formatDifference } from "@/util/dateTimeUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        agentStatisticsData: {
            type: Object as () => ExtendedAgentStatisticsData,
            required: true,
        },
        callCenterStatisticsData: {
            type: Object as () => ExtendedCallCenterStatisticsData | null,
            default: null,
        },
        date: {
            type: String as () => string | null,
            default: null,
        },
        includeCallCenterStatistics: {
            type: Boolean,
            default: false,
        },
        isAgent: {
            type: Boolean,
            required: false,
        },
        name: {
            type: String,
            required: true,
        },
        userFullName: {
            type: String as () => string | null,
            default: null,
        },
        userId: {
            type: String as () => string | null,
            default: null,
        },
    },

    data() {
        return {
            agentDayBottomSheetVisible: false,
            dutyRosterPanelBottomSheetVisible: false,
        };
    },

    computed: {
        canViewAgentDayOverview() {
            return (
                userSession.hasPermission(Permission.CT_VIEW_AGENT_DAY_OVERVIEW) ||
                userSession.hasPermission(Permission.CT_VIEW_OWN_AGENT_DAY_OVERVIEW)
            );
        },

        canViewDutyRoster() {
            return userSession.hasPermission(Permission.CT_MANAGE_DUTY_ROSTER);
        },

        elapsedSeconds(): DutyRosterEntryTypeStatisticsData {
            return this.agentStatisticsData.elapsedSeconds;
        },

        elapsedTimeCompensationSeconds(): number {
            return this.elapsedSeconds.overtimeCompensation + this.elapsedSeconds.otherTimeCompensation;
        },

        elapsedSicknessAndOtherAbsenceSeconds(): number {
            return (
                this.elapsedSeconds.sickness + this.elapsedSeconds.sickChild + this.elapsedSeconds.otherUnpaidAbsence
            );
        },

        elapsedWorkTimeSeconds(): number {
            return (
                this.elapsedSeconds.answerIncomingOrdinaryCalls +
                this.elapsedSeconds.answerIncomingPriorityCalls +
                this.elapsedSeconds.training +
                this.elapsedSeconds.otherWork
            );
        },

        pendingTimeCompensationSeconds(): number {
            return this.scheduledTimeCompensationSeconds - this.elapsedTimeCompensationSeconds;
        },

        pendingSeconds(): DutyRosterEntryTypeStatisticsData {
            return subtractDutyRosterEntryTypeStatisticsData(this.scheduledSeconds, this.elapsedSeconds);
        },

        pendingSicknessAndOtherAbsenceSeconds(): number {
            return this.scheduledSicknessAndOtherAbsenceSeconds - this.elapsedSicknessAndOtherAbsenceSeconds;
        },

        scheduledTimeCompensationSeconds(): number {
            return this.scheduledSeconds.overtimeCompensation + this.scheduledSeconds.otherTimeCompensation;
        },

        scheduledSeconds(): DutyRosterEntryTypeStatisticsData {
            return this.agentStatisticsData.scheduledSeconds;
        },

        scheduledSicknessAndOtherAbsenceSeconds(): number {
            return (
                this.scheduledSeconds.sickness +
                this.scheduledSeconds.sickChild +
                this.scheduledSeconds.otherUnpaidAbsence
            );
        },

        scheduledWorkTimeSeconds(): number {
            return (
                this.scheduledSeconds.answerIncomingOrdinaryCalls +
                this.scheduledSeconds.answerIncomingPriorityCalls +
                this.scheduledSeconds.training +
                this.scheduledSeconds.otherWork
            );
        },

        totalElapsedSeconds(): number {
            return (
                this.elapsedWorkTimeSeconds +
                this.elapsedSeconds.holiday +
                this.elapsedTimeCompensationSeconds +
                this.elapsedSicknessAndOtherAbsenceSeconds
            );
        },

        totalPendingSeconds(): number {
            return this.totalScheduledSeconds - this.totalElapsedSeconds;
        },

        totalScheduledSeconds(): number {
            return (
                this.scheduledWorkTimeSeconds +
                this.scheduledSeconds.holiday +
                this.scheduledTimeCompensationSeconds +
                this.scheduledSicknessAndOtherAbsenceSeconds
            );
        },
    },

    methods: {
        formatWorkingHours(seconds: number): string {
            const beginDate = new Date();
            const endDate = new Date(beginDate.getTime() + seconds * 1000);

            return formatDifference(beginDate, endDate, false);
        },

        renderPercentage(value: number): string {
            return this.$n(value, { style: "percent", maximumFractionDigits: "1" });
        },
    },

    components: {
        AgentDayBottomSheet,
        DutyRosterPanelBottomSheet,
    },
});


import ExternalBdcReportPage from "./ExternalBdcReportPage.vue";
import { ExtendedExternalBdcCdrRow, ExternalBdcCdrReportPageResult } from "./externalBdcReportPage";
import { mapExternalBdcCdrRowKeyToRowLabel } from "./externalBdcRowUtils";
import ReportTableCard from "@/app/pages/reporting/ReportTableCard.vue";
import ReportingBarChartCard from "@/app/pages/reporting/ReportingBarChartCard.vue";
import { BarChartSeriesOptions, getReportingBarChartForHeaderGroup } from "@/app/pages/reporting/chartUtils";
import { ReportingBarChartData } from "@/app/pages/reporting/charts/reportingBarChart";
import { ReportingChartColorPalette } from "@/app/pages/reporting/charts/reportingChart";
import { groupRowsBy } from "@/app/pages/reporting/pivotUtils";
import { avg, median, rate, renderDuration, sum } from "@/app/pages/reporting/reportingUtils";
import { RowKey, TitledRowGroup } from "@/app/pages/reporting/rowUtils";
import {
    getSortByOptions,
    ReportingTableData,
    ReportingTableHeaderGroup,
    ReportingTableItemColumnEntries,
    ReportingTableItemColumnGroup,
    ReportingTableSortByOptions,
    ReportingTableSortByOptionValue,
    sort,
} from "@/app/pages/reporting/table/reportingTable";
import { userSession } from "@/store/userSession";
import { SelectOption } from "@/util/types";
import Vue from "vue";

type ComputedExternalBdcCdrRow = ExtendedExternalBdcCdrRow;

type ComputedExternalBdcCdrRowGroupBy = keyof Pick<ComputedExternalBdcCdrRow, "dealerId" | "partitionId" | "userId">;

enum EvaluationType {
    ABSOLUTE,
    PERCENTAGE,
}

interface GroupByOption extends SelectOption {
    readonly value: ComputedExternalBdcCdrRowGroupBy;
}

type ReportingTableHeaderGroupWithColumnGroupComputer<Options> = ReportingTableHeaderGroup<Options> & {
    readonly toColumnGroup: (
        rowGroupRows: readonly ComputedExternalBdcCdrRow[],
        totalRows: readonly ComputedExternalBdcCdrRow[],
        isColumnTotal?: boolean
    ) => ReportingTableItemColumnGroup;
};

export default Vue.extend({
    data() {
        return {
            evaluationType: EvaluationType.ABSOLUTE as EvaluationType,
            EvaluationType,
            groupBy: "partitionId" as ComputedExternalBdcCdrRowGroupBy,
            result: null as ExternalBdcCdrReportPageResult | null,
            sortBy: {
                groupIndex: 0,
                columnIndex: 0,
                direction: "DESC",
            } as ReportingTableSortByOptionValue,
        };
    },

    computed: {
        billedFactorChart(): ReportingBarChartData | null {
            return getReportingBarChartForHeaderGroup(this.table, 4);
        },

        callVolumeChart(): ReportingBarChartData | null {
            return getReportingBarChartForHeaderGroup(this.table, 1);
        },

        callDurationChart(): ReportingBarChartData | null {
            return getReportingBarChartForHeaderGroup(this.table, 3);
        },

        groupByOptions(): GroupByOption[] {
            return [
                {
                    value: "userId",
                    text: this.$t("Benutzer"),
                },
                {
                    value: "partitionId",
                    text: this.$t("Partition"),
                },
                {
                    value: "dealerId",
                    text: this.$t("Standort"),
                },
            ];
        },

        groupByText(): string | null {
            return (this.groupByOptions.find((o) => o.value === this.groupBy)?.text ?? null) as string | null;
        },

        rowGroups(): readonly TitledRowGroup<RowKey, ComputedExternalBdcCdrRow>[] {
            const rowGroups = groupRowsBy(this.result?.rows ?? [], (r) => r[this.groupBy]);

            return rowGroups.map((rowGroup) => ({
                ...rowGroup,
                title: mapExternalBdcCdrRowKeyToRowLabel(rowGroup.key, this.groupBy, this.result?.mapKeyToLabelContext)
                    .label,
            }));
        },

        sortByOptions(): ReportingTableSortByOptions {
            return getSortByOptions(this.tableHeaderGroups);
        },

        table(): ReportingTableData<BarChartSeriesOptions> | null {
            if (!this.tableData) {
                return null;
            }

            return {
                ...this.tableData,
                items: sort(this.tableData.items, this.sortBy),
            };
        },

        tableData(): ReportingTableData<BarChartSeriesOptions> | null {
            const allRows = this.result?.rows ?? [];

            if (!allRows.length) {
                return null;
            }

            return {
                headerGroups: this.tableHeaderGroups.map((headerGroup) => ({
                    ...headerGroup,
                    toColumnGroup: undefined,
                })),
                items: this.rowGroups.map((rowGroup) => ({
                    title: rowGroup.title,
                    groups: this.tableHeaderGroups.map((headerGroup) =>
                        headerGroup.toColumnGroup(rowGroup.rows, allRows)
                    ),
                })),
                totals: {
                    title: this.$t("Gesamt") as string,
                    groups: this.tableHeaderGroups.map((headerGroup) =>
                        headerGroup.toColumnGroup(allRows, allRows, true)
                    ),
                },
                groupByHeaderText: this.groupByText ?? undefined,
            };
        },

        tableHeaderGroups(): ReportingTableHeaderGroupWithColumnGroupComputer<BarChartSeriesOptions>[] {
            return [
                {
                    text: this.$t("Gesamt") as string,
                    headers: [{ text: this.$t("Gesamt") as string }],
                    toColumnGroup: (rowGroupRows, allRows) => ({
                        columns: [{ entries: this.toCountShareValueEntries(rowGroupRows, allRows) }],
                    }),
                },
                {
                    text: this.$t("Gesamt") as string,
                    headers: [
                        {
                            text: this.$t("Angenommen") as string,
                            options: { colorPalette: ReportingChartColorPalette.POSITIVE },
                        },
                        {
                            text: this.$t("Verpasst") as string,
                            options: { colorPalette: ReportingChartColorPalette.NEGATIVE },
                        },
                    ],
                    toColumnGroup: (rowGroupRows) => ({
                        columns: [
                            {
                                entries: this.toCountShareValueEntries(
                                    rowGroupRows.filter((r) => r.duration !== null),
                                    rowGroupRows
                                ),
                            },
                            {
                                entries: this.toCountShareValueEntries(
                                    rowGroupRows.filter((r) => r.duration === null),
                                    rowGroupRows
                                ),
                            },
                        ],
                    }),
                },
                {
                    text: this.$t("Wartezeit in Sekunden") as string,
                    headers: [
                        { text: this.$t("Gesamt (Median)") as string },
                        {
                            text: this.$t("Angenommen (Median)") as string,
                            options: { colorPalette: ReportingChartColorPalette.POSITIVE },
                        },
                        {
                            text: this.$t("Verpasst (Median)") as string,
                            options: { colorPalette: ReportingChartColorPalette.NEGATIVE },
                        },
                    ],
                    toColumnGroup: (rowGroupRows) => ({
                        columns: [
                            {
                                entries: this.toMedianAvgDurationEntries(rowGroupRows, (r) => r.waited),
                            },
                            {
                                entries: this.toMedianAvgDurationEntries(
                                    rowGroupRows.filter((r) => r.duration !== null),
                                    (r) => r.waited
                                ),
                            },
                            {
                                entries: this.toMedianAvgDurationEntries(
                                    rowGroupRows.filter((r) => r.duration === null),
                                    (r) => r.waited
                                ),
                            },
                        ],
                    }),
                },
                {
                    text: this.$t("Gesprächsdauer in Minuten") as string,
                    headers: [
                        { text: this.$t("Gesamt (Summe)") as string },
                        { text: this.$t("Abgerechnet (Summe)") as string },
                    ],
                    toColumnGroup: (rowGroupRows) => ({
                        columns: [
                            {
                                entries: this.toSumDurationEntries(
                                    rowGroupRows.filter((r) => r.duration !== null),
                                    (r) => r.duration
                                ),
                            },
                            {
                                entries: this.toSumDurationEntries(
                                    rowGroupRows.filter((r) => r.billedMinutes !== null),
                                    (r) => r.billedMinutes! * 60 // to seconds
                                ),
                            },
                        ],
                    }),
                },
                {
                    text: this.$t("Abrechnungsquote") as string,
                    headers: [{ text: this.$t("Abrechnungsquote") as string }],
                    toColumnGroup: (rowGroupRows) => {
                        const duration = sum(rowGroupRows.map((r) => r.duration)) ?? 0;
                        const billedMinutes = sum(rowGroupRows.map((r) => r.billedMinutes)) ?? 0;

                        return {
                            columns: [
                                {
                                    entries: [{ value: duration > 0 ? (billedMinutes * 60) / duration : undefined }],
                                },
                            ],
                        };
                    },
                },
                {
                    text: this.$t("Einzelgespräch in Sekunden") as string,
                    headers: [{ text: this.$t("Median") as string }, { text: this.$t("Durchschnitt") as string }],
                    toColumnGroup: (rowGroupRows, allRows, isColumnTotal) => ({
                        columns: [
                            {
                                entries: this.toDiffDurationEntriesFn(
                                    median(rowGroupRows.map((r) => r.duration)),
                                    !isColumnTotal ? median(allRows.map((r) => r.duration)) : undefined
                                ),
                            },
                            {
                                entries: this.toDiffDurationEntriesFn(
                                    avg(rowGroupRows.map((r) => r.duration)),
                                    !isColumnTotal ? avg(allRows.map((r) => r.duration)) : undefined
                                ),
                            },
                        ],
                    }),
                },
            ];
        },

        timeZone(): string {
            return userSession.timeZone;
        },

        totalDurationChart(): ReportingBarChartData | null {
            return getReportingBarChartForHeaderGroup(this.table, 5);
        },

        waitedChart(): ReportingBarChartData | null {
            return getReportingBarChartForHeaderGroup(this.table, 2);
        },
    },

    methods: {
        toCountShareValueEntries(
            countRows: readonly ComputedExternalBdcCdrRow[],
            totalRows: readonly ComputedExternalBdcCdrRow[]
        ): ReportingTableItemColumnEntries {
            const absoluteValue = { value: countRows.length };
            const percentageValue = {
                value: rate(countRows.length, totalRows.length),
                isPercentage: true,
            };

            return this.evaluationType === EvaluationType.PERCENTAGE
                ? [percentageValue, absoluteValue]
                : [absoluteValue, percentageValue];
        },

        toDiffDurationEntriesFn(value: number | undefined, total: number | undefined): ReportingTableItemColumnEntries {
            const entries: ReportingTableItemColumnEntries = [
                {
                    value,
                    formatter: (value) => renderDuration(value, "L"),
                },
            ];

            if (value !== undefined && total !== undefined) {
                entries.push({
                    value: value - total,
                    formatter: (value) => renderDuration(value, "S"),
                });
            }

            return entries;
        },

        toMedianAvgDurationEntries(
            rows: readonly ComputedExternalBdcCdrRow[],
            getValue: (r: ComputedExternalBdcCdrRow) => number | null
        ): ReportingTableItemColumnEntries {
            const valueRows = rows.filter((r) => getValue(r) !== null);

            return [
                { value: median(valueRows.map(getValue)), formatter: (value) => renderDuration(value, "L") },
                { value: avg(valueRows.map(getValue)), formatter: (value) => renderDuration(value, "L") },
            ];
        },

        toSumDurationEntries(
            rows: readonly ComputedExternalBdcCdrRow[],
            getValue: (r: ComputedExternalBdcCdrRow) => number | null
        ): ReportingTableItemColumnEntries {
            const valueRows = rows.filter((r) => getValue(r) !== null);

            return [{ value: sum(valueRows.map(getValue)), formatter: (value) => renderDuration(value, "L") }];
        },
    },

    components: {
        ExternalBdcReportPage,
        ReportingBarChartCard,
        ReportTableCard,
    },
});


import MonthlyBwaRevenueDataTableRow from "./MonthlyBwaRevenueDataTableRow.vue";
import { BwaMonthRevenue, bwaRevenuesApi } from "@/api/bwarevenues";
import CsvDownloadIcon from "@/app/components/CsvDownloadIcon.vue";
import { addInvoiceRevenueData, getOtherRevenue } from "@/app/pages/invoicerevenue/invoiceRevenueUtils";
import { getFileBasename } from "@/app/pages/reporting/downloadUtils";
import { configStore } from "@/store/config";
import { now } from "@/store/now";
import { addDuration, getCurrentMonth, startOf, UnitOfTime } from "@/util/dateTimeUtils";
import { SelectOption } from "@/util/types";
import Vue from "vue";

interface Item {
    readonly bwaMonthRevenue: BwaMonthRevenue;
    readonly isProjection: boolean;
}

type Total = Omit<BwaMonthRevenue, "bwaMonth">;

export default Vue.extend({
    data() {
        return {
            items: [] as Item[],
            loading: true,
            now: now(),
            projectionMonths: 0,
            searchId: 1,
        };
    },

    computed: {
        csvRevenueData(): string[][] {
            return [
                [
                    this.$t("Monat") as string,
                    this.$t("Gesamtumsatz") as string,
                    this.$t("Software") as string,
                    this.$t("BDC (wiederkehrend)") as string,
                    this.$t("BDC (variabel)") as string,
                    this.$t("Click-to-Call") as string,
                    this.$t("Call-Tracking") as string,
                    this.$t("SMS") as string,
                    this.$t("WhatsApp") as string,
                    this.$t("Einrichtung") as string,
                    this.$t("Sonstiges") as string,
                ],
                ...this.items.map((i) => [
                    i.bwaMonthRevenue.bwaMonth.month,
                    this.formatPriceForCsv(i.bwaMonthRevenue.invoiceRevenueData.totalRevenue),
                    this.formatPriceForCsv(i.bwaMonthRevenue.invoiceRevenueData.softwareRevenue),
                    this.formatPriceForCsv(i.bwaMonthRevenue.invoiceRevenueData.fixedBdcRevenue),
                    this.formatPriceForCsv(i.bwaMonthRevenue.invoiceRevenueData.variableBdcRevenue),
                    this.formatPriceForCsv(i.bwaMonthRevenue.invoiceRevenueData.clickToCallRevenue),
                    this.formatPriceForCsv(i.bwaMonthRevenue.invoiceRevenueData.callTrackingRevenue),
                    this.formatPriceForCsv(i.bwaMonthRevenue.invoiceRevenueData.outgoingSmsRevenue),
                    this.formatPriceForCsv(i.bwaMonthRevenue.invoiceRevenueData.whatsAppNumberRevenue),
                    this.formatPriceForCsv(i.bwaMonthRevenue.invoiceRevenueData.setupRevenue),
                    this.formatPriceForCsv(getOtherRevenue(i.bwaMonthRevenue.invoiceRevenueData)),
                ]),
            ];
        },

        csvRevenueFilename(): string | null {
            if (!this.items.length) {
                return null;
            }

            const prefix = getFileBasename(
                this.$t("BWA") as string,
                this.items[this.items.length - 1].bwaMonthRevenue.bwaMonth.month,
                this.items[0].bwaMonthRevenue.bwaMonth.month,
                this.$t("Umsatz") as string
            );

            return `${prefix}.csv`;
        },

        locale(): string {
            return configStore.configuration.defaultLocale;
        },

        projectionMonthsOptions(): SelectOption[] {
            return [...Array(13).keys()].map((monthOffset: number) => ({
                text: this.$tc("Keine | 1 Monat | {count} Monate", monthOffset),
                value: monthOffset,
            }));
        },

        timezone(): string {
            return configStore.configuration.organisationTimeZone;
        },

        total(): Total | null {
            if (!this.items.length) {
                return null;
            }

            return this.items
                .map((item) => item.bwaMonthRevenue as Total)
                .reduceRight((a, b) => ({
                    billingAccountCount: a.billingAccountCount + b.billingAccountCount,
                    partitionCount: a.partitionCount + b.partitionCount,
                    dealerCount: a.dealerCount + b.dealerCount,
                    invoiceTotal: a.invoiceTotal + b.invoiceTotal,
                    unpaidInvoiceTotal: a.unpaidInvoiceTotal + b.unpaidInvoiceTotal,
                    unpaidInvoiceCount: a.unpaidInvoiceCount + b.unpaidInvoiceCount,
                    invoiceRevenueData: addInvoiceRevenueData(a.invoiceRevenueData, b.invoiceRevenueData),
                }));
        },
    },

    methods: {
        formatPriceForCsv(value: number): string {
            return this.$n(value, {
                minimumFractionDigits: "2",
                maximumFractionDigits: "4",
                useGrouping: false as any, // interface requires string, but the implementation a number
            });
        },

        async loadItems() {
            this.items = [];
            this.loading = true;
            const searchId = ++this.searchId;

            try {
                const startOfCurrentMonth = startOf(this.now, this.timezone, this.locale, UnitOfTime.MONTH);

                const prevMonth = getCurrentMonth(
                    addDuration(startOfCurrentMonth, this.timezone, -1, UnitOfTime.MONTH),
                    this.timezone
                );

                const [projectedMonthlyRevenues, bwaMonthlyRevenues] = await Promise.all([
                    Promise.all(
                        [...Array(this.projectionMonths).keys()]
                            .reverse()
                            .map((offset) => addDuration(startOfCurrentMonth, this.timezone, offset, UnitOfTime.MONTH))
                            .map((startOfProjectionMonth) => getCurrentMonth(startOfProjectionMonth, this.timezone))
                            .map((projectionMonth) => bwaRevenuesApi.getProjectionOfMonthlyRevenue(projectionMonth))
                    ),
                    bwaRevenuesApi.getAll(),
                ]);

                if (!!bwaMonthlyRevenues.length && !bwaMonthlyRevenues.find((r) => r.bwaMonth.month === prevMonth)) {
                    projectedMonthlyRevenues.push(await bwaRevenuesApi.getProjectionOfMonthlyRevenue(prevMonth));
                }

                if (searchId === this.searchId) {
                    this.items = [
                        ...projectedMonthlyRevenues.map((projectedMonthRevenue) => ({
                            bwaMonthRevenue: projectedMonthRevenue,
                            isProjection: true,
                        })),
                        ...bwaMonthlyRevenues.map((bwaMonthRevenue) => ({
                            bwaMonthRevenue,
                            isProjection: false,
                        })),
                    ];
                }
            } finally {
                if (searchId === this.searchId) {
                    this.loading = false;
                }
            }
        },

        async refresh() {
            await this.loadItems();
        },
    },

    watch: {
        async projectionMonths() {
            try {
                await this.refresh();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },
    },

    async mounted() {
        await this.loadItems();
    },

    components: {
        CsvDownloadIcon,
        MonthlyBwaRevenueDataTableRow,
    },
});

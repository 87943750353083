
import BillingAccountFormDialog from "./BillingAccountFormDialog.vue";
import { BillingAccount } from "@/api/billingAccounts";
import { getStateCaption } from "@/app/countryStateUtils";
import CustomInvoiceFormDialog from "@/app/pages/invoices/CustomInvoiceFormDialog.vue";
import Vue from "vue";

export default Vue.extend({
    props: {
        billingAccount: {
            type: Object as () => BillingAccount,
            required: true,
        },
    },

    data() {
        return {
            billingAccountFormDialogVisible: false,
            customInvoiceFormVisible: false,
            getStateCaption,
        };
    },

    methods: {
        hideBillingAccountFormDialogAndRefresh() {
            this.billingAccountFormDialogVisible = false;
            this.$emit("refresh");
        },
    },

    components: {
        BillingAccountFormDialog,
        CustomInvoiceFormDialog,
    },
});


import { TimeRange } from "./reportPage";
import {
    getEndOfRelativeTimeSlot,
    getStartOfRelativeTimeSlot,
    OngoingTimeInterval,
    RecurringTimeInterval,
    RelativeTimeInterval,
} from "./timeInterval";
import { now } from "@/store/now";
import { getDate } from "@/util/dateTimeUtils";
import { SelectOption, SelectOptions } from "@/util/types";
import Vue from "vue";
import { TranslateResult } from "vue-i18n";

interface QuickFilter {
    readonly label: TranslateResult;
    readonly from: string;
    readonly to: string;
}

interface TimeIntervalOption extends SelectOption {
    readonly value: OngoingTimeInterval | RecurringTimeInterval;
}

export default Vue.extend({
    props: {
        canQuickFilter: {
            type: Boolean,
            default: false,
        },
        canRefresh: {
            type: Boolean,
            default: false,
        },
        canSelectEvaluationType: {
            type: Boolean,
            default: false,
        },
        canSelectGroupBy: {
            type: Boolean,
            default: false,
        },
        canSelectSortBy: {
            type: Boolean,
            default: false,
        },
        canSelectTimeInterval: {
            type: Boolean,
            default: false,
        },
        evaluationType: {
            type: Number,
            required: false,
        },
        evaluationTypeOptions: {
            type: Array as () => SelectOption[],
            required: false,
            default: () => [],
        },
        groupBy: {
            type: String,
            required: false,
        },
        groupByOptions: {
            type: Array as () => SelectOption[],
            required: false,
            default: () => [],
        },
        loading: {
            type: Boolean,
            required: true,
        },
        noData: {
            type: Boolean,
            default: false,
        },
        sortBy: {
            type: Object,
            required: false,
        },
        sortByOptions: {
            type: Array as () => SelectOptions,
            required: false,
            default: () => [],
        },
        timeInterval: {
            type: String as () => OngoingTimeInterval | RecurringTimeInterval,
            required: false,
        },
        timeIntervals: {
            type: Array as () => OngoingTimeInterval[] | RecurringTimeInterval[],
            required: false,
            default: () => [],
        },
        timeRange: {
            type: Object as () => TimeRange,
            required: false,
        },
        timeZone: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            now: now(),
        };
    },

    computed: {
        canSelectTimeRange(): boolean {
            return this.canQuickFilter && !!this.$listeners["update:time-range"];
        },

        pageTitle(): string | undefined {
            return this.$route.matched
                .map((r) => r.meta.title as string | undefined)
                .reduce((p, c) => c || p, undefined);
        },

        quickFilters(): QuickFilter[] {
            const ts = this.now;

            return [
                { label: this.$t("Heute"), interval: RelativeTimeInterval.TODAY },
                { label: this.$t("Letzten 7 Tage"), interval: RelativeTimeInterval.LAST_SEVEN_DAYS },
                { label: this.$t("Aktuelle Woche"), interval: RelativeTimeInterval.CURRENT_WEEK },
                { label: this.$t("Letzte Woche"), interval: RelativeTimeInterval.PREVIOUS_WEEK },
                { label: this.$t("Aktueller Monat"), interval: RelativeTimeInterval.CURRENT_MONTH },
                { label: this.$t("Letzter Monat"), interval: RelativeTimeInterval.PREVIOUS_MONTH },
                { label: this.$t("Aktuelles Quartal"), interval: RelativeTimeInterval.CURRENT_QUARTER },
                { label: this.$t("Letztes Quartal"), interval: RelativeTimeInterval.PREVIOUS_QUARTER },
            ].map((i) => ({
                label: i.label,
                from: getDate(getStartOfRelativeTimeSlot(ts, i.interval)!, this.timeZone),
                to: getDate(getEndOfRelativeTimeSlot(ts, i.interval)!, this.timeZone),
            }));
        },

        timeIntervalOptions(): TimeIntervalOption[] {
            if (!this.timeIntervals) {
                return [];
            }

            return [
                {
                    text: this.$tc("0 Sekunden | 1 Sekunde | {count} Sekunden", 1),
                    value: OngoingTimeInterval.ONE_SECOND,
                },
                {
                    text: this.$tc("0 Sekunden | 1 Sekunde | {count} Sekunden", 5),
                    value: OngoingTimeInterval.FIVE_SECONDS,
                },
                {
                    text: this.$tc("0 Sekunden | 1 Sekunde | {count} Sekunden", 10),
                    value: OngoingTimeInterval.TEN_SECONDS,
                },
                {
                    text: this.$tc("0 Sekunden | 1 Sekunde | {count} Sekunden", 15),
                    value: OngoingTimeInterval.FIFTEEN_SECONDS,
                },
                {
                    text: this.$tc("0 Sekunden | 1 Sekunde | {count} Sekunden", 30),
                    value: OngoingTimeInterval.THIRTY_SECONDS,
                },
                {
                    text: this.$tc("0 Minuten | 1 Minute | {count} Minuten", 1),
                    value: OngoingTimeInterval.ONE_MINUTE,
                },
                {
                    text: this.$tc("0 Minuten | 1 Minute | {count} Minuten", 5),
                    value: OngoingTimeInterval.FIVE_MINUTES,
                },
                {
                    text: this.$tc("0 Minuten | 1 Minute | {count} Minuten", 10),
                    value: OngoingTimeInterval.TEN_MINUTES,
                },
                {
                    text: this.$tc("0 Minuten | 1 Minute | {count} Minuten", 15),
                    value: OngoingTimeInterval.FIFTEEN_MINUTES,
                },
                {
                    text: this.$tc("0 Minuten | 1 Minute | {count} Minuten", 30),
                    value: OngoingTimeInterval.THIRTY_MINUTES,
                },
                {
                    text: this.$tc("0 Minuten | 1 Minute | {count} Minuten", 60),
                    value: OngoingTimeInterval.SIXTY_MINUTES,
                },
                {
                    text: this.$t("Tag"),
                    value: OngoingTimeInterval.DATE,
                },
                {
                    text: this.$t("Woche"),
                    value: OngoingTimeInterval.WEEK,
                },
                {
                    text: this.$t("Monat"),
                    value: OngoingTimeInterval.MONTH,
                },
                {
                    text: this.$t("Quartal"),
                    value: OngoingTimeInterval.QUARTER,
                },
                {
                    text: this.$t("Jahr"),
                    value: OngoingTimeInterval.YEAR,
                },
                {
                    text: this.$t("Stunde"),
                    value: RecurringTimeInterval.HOUR_OF_DAY,
                },
                {
                    text: this.$t("Wochentag"),
                    value: RecurringTimeInterval.DAY_OF_WEEK,
                },
                {
                    text: this.$t("Tag des Monats"),
                    value: RecurringTimeInterval.DAY_OF_MONTH,
                },
                {
                    text: this.$t("Tag des Jahres"),
                    value: RecurringTimeInterval.DAY_OF_YEAR,
                },
                {
                    text: this.$t("Woche des Jahres"),
                    value: RecurringTimeInterval.WEEK_OF_YEAR,
                },
                {
                    text: this.$t("Monat des Jahres"),
                    value: RecurringTimeInterval.MONTH_OF_YEAR,
                },
                {
                    text: this.$t("Quartal des Jahres"),
                    value: RecurringTimeInterval.QUARTER_OF_YEAR,
                },
            ].filter((i) => (this.timeIntervals as unknown[]).includes(i.value));
        },
    },

    methods: {
        isTimeRangeSelected(quickFilter: QuickFilter) {
            return this.timeRange?.from === quickFilter.from && this.timeRange?.to === quickFilter.to;
        },
    },
});

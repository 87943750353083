
import { DataTablePaging } from "./dataTable";
import { cloneObject } from "@/util/cloneUtils";
import { SelectOption } from "@/util/types";
import Vue from "vue";
import { DataPagination } from "vuetify";

export default Vue.extend({
    props: {
        canSelectPageSize: {
            type: Boolean,
            default: false,
        },
        paging: {
            type: Object as () => DataTablePaging,
            required: true,
        },
        pagination: {
            type: Object as () => DataPagination,
            required: true,
        },
    },

    computed: {
        isLastPage(): boolean {
            return this.pagination.page >= Math.min(this.pagination.pageCount, this.paging.maxPage);
        },

        pageEnd(): number {
            return this.pagination.pageStop;
        },

        pageStart(): number {
            return this.pagination.pageStart + 1;
        },

        pageSizeOptions(): SelectOption[] {
            if (!this.paging.pageSizes) {
                return [];
            }

            const pageSizes: number[] = [this.paging.pageSize, ...(this.paging.pageSizes ?? [])]
                .filter((pageSize) => pageSize > 0)
                .sort((a, b) => a - b)
                .filter((pageSize, index, array) => !index || array[index - 1] !== pageSize);

            if (pageSizes.length < 2) {
                return [];
            }

            return pageSizes.map((pageSize) => ({
                text: pageSize.toString(),
                value: pageSize,
            }));
        },
    },

    methods: {
        changePage(page: number) {
            if (this.paging.page === page) {
                return;
            }

            const paging: DataTablePaging = {
                ...this.paging,
                page: Math.max(1, Math.min(page, this.pagination.pageCount, this.paging.maxPage)),
            };

            this.$emit("page", cloneObject(paging));
        },

        changePageSize(pageSize: number) {
            if (this.paging.pageSize === pageSize) {
                return;
            }

            const paging: DataTablePaging = {
                ...this.paging,
                page: Math.floor(this.pageStart / pageSize) + 1,
                pageSize,
            };

            this.$emit("page", cloneObject(paging));
        },
    },
});

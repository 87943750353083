
import Vue from "vue";

export default Vue.extend({
    props: {
        icon: {
            type: String,
            required: true,
        },
        iconClass: {
            type: String,
            required: false,
        },
        tooltipText: {
            type: String,
            required: true,
        },
    },
});

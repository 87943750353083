import { cloneObject } from "@/util/cloneUtils";
import { ReconnectingEventSource } from "@/util/eventSource";

export interface Notification {
    readonly timestamp: Date;
    readonly type: string;
}

export function isTimestampNotification(n: Notification) {
    return n.type === "TIMESTAMP";
}

export const notificationEventSource = new ReconnectingEventSource("/api/notifications/_stream", (data) =>
    cloneObject(data)
);

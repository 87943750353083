
import CallCenterDashboardStatisticsCard from "./CallCenterDashboardStatisticsCard.vue";
import { CallCenterStatistics, callCenterStatisticsApi } from "@/api/callCenterStatistics";
import { UserOnlinePeriod, userTrackingApi } from "@/api/userTracking";
import { configStore } from "@/store/config";
import { now } from "@/store/now";
import { addDuration, formatTime, startOf, UnitOfTime } from "@/util/dateTimeUtils";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            acceptanceRatioThreshold: 0.9,
            callCenterStatisticsLast60Minutes: null as CallCenterStatistics | null,
            currentOnlinePeriods: [] as UserOnlinePeriod[],
            errorRatioThreshold: 0.01,
            historyHoursSize: 5,
            hourlyCallCenterStatistics: [] as CallCenterStatistics[],
            key: "",
            loading: true,
            maxPausingAgents: 9,
            performanceThreshold: 55,
            refreshTimer: null as number | null,
        };
    },

    computed: {
        absentAgentIds(): string[] {
            return this.currentOnlinePeriods.filter((p) => !p.begin).map((p) => p.userId);
        },

        locale(): string {
            return configStore.configuration.defaultLocale;
        },

        pausingAgentIds(): string[] {
            return this.currentOnlinePeriods.filter((p) => !!p.begin && !!p.end).map((p) => p.userId);
        },

        presentAgentIds(): string[] {
            return this.currentOnlinePeriods.filter((p) => !!p.begin).map((p) => p.userId);
        },

        timeZone(): string {
            return configStore.configuration.organisationTimeZone;
        },

        waitingTimeThresholdSecondsForConsideringUnansweredCalls(): number {
            return configStore.configuration.waitingTimeThresholdSecondsForConsideringUnansweredCalls;
        },
    },

    methods: {
        formatTime(date: Date) {
            return formatTime(date, this.timeZone, this.locale, false);
        },

        async refresh() {
            const ts = now();
            const startOfCurrentHour = startOf(ts, this.timeZone, this.locale, UnitOfTime.HOUR);

            const [
                callCenterStatisticsLast60Minutes,
                currentOnlinePeriods,
                hourlyCallCenterStatistics,
            ] = await Promise.all([
                callCenterStatisticsApi.getCallCenterStatistics(
                    {
                        from: addDuration(ts, this.timeZone, -60, UnitOfTime.MINUTE),
                        to: ts,
                        waitingTimeThresholdSecondsForConsideringUnansweredCalls: this
                            .waitingTimeThresholdSecondsForConsideringUnansweredCalls,
                    },
                    this.key
                ),
                userTrackingApi.getCurrentOnlinePeriodsForScheduledAgents(this.key),
                Promise.all(
                    [...Array(this.historyHoursSize).keys()]
                        .map((value) => -1 * value - 1)
                        .map((offset) =>
                            callCenterStatisticsApi.getCallCenterStatistics(
                                {
                                    from: addDuration(startOfCurrentHour, this.timeZone, offset, UnitOfTime.HOUR),
                                    to: addDuration(startOfCurrentHour, this.timeZone, offset + 1, UnitOfTime.HOUR),
                                    waitingTimeThresholdSecondsForConsideringUnansweredCalls: this
                                        .waitingTimeThresholdSecondsForConsideringUnansweredCalls,
                                },
                                this.key
                            )
                        )
                ),
            ]);

            this.callCenterStatisticsLast60Minutes = callCenterStatisticsLast60Minutes;
            this.hourlyCallCenterStatistics = hourlyCallCenterStatistics;
            this.currentOnlinePeriods = currentOnlinePeriods;
        },

        renderPercentage(value: number): string {
            return this.$n(value, { style: "percent", minimumFractionDigits: "1", maximumFractionDigits: "1" });
        },
    },

    async mounted() {
        document.documentElement.style.overflowY = "hidden";
        this.key = (this.$route.query.key as string) || "";

        await this.refresh();
        this.loading = false;

        this.refreshTimer = setInterval(() => this.refresh(), 10000);
    },

    destroyed() {
        if (this.refreshTimer) {
            clearInterval(this.refreshTimer);
        }
    },

    components: {
        CallCenterDashboardStatisticsCard,
    },
});

import axios from "axios";

export enum InvoiceLineItemCompensationSubject {
    SETUP = "SETUP",
    SOFTWARE_LICENSE = "SOFTWARE_LICENSE",
    EXTERNAL_BDC_CALL = "EXTERNAL_BDC_CALL",
    EXTERNAL_BDC_MINUTE = "EXTERNAL_BDC_MINUTE",
    CLICK_TO_CALL_MINUTE = "CLICK_TO_CALL_MINUTE",
    CALL_TRACKING_MINUTE = "CALL_TRACKING_MINUTE",
    OUTGOING_SMS = "OUTGOING_SMS",
    WHATSAPP_NUMBER = "WHATSAPP_NUMBER",
}

export enum InvoiceLineItemCompensationType {
    ONE_TIME_FEE = "ONE_TIME_FEE",
    MONTHLY_FEE = "MONTHLY_FEE",
    INCLUDED_UNIT = "INCLUDED_UNIT",
    UNIT = "UNIT",
}

export interface CustomInvoiceForm {
    readonly billingAccountId: number;
    readonly periodBegin: string;
    readonly periodEnd: string;
    readonly customInvoiceLineItemForms: CustomInvoiceLineItemForm[];
    readonly sendEmail: boolean;
}

export interface CustomInvoiceLineItemForm {
    readonly dealerId: string | null;
    readonly compensationSubject: InvoiceLineItemCompensationSubject | null;
    readonly compensationType: InvoiceLineItemCompensationType | null;
    readonly description: string;
    readonly quantity: number;
    readonly pricePerUnit: number;
}

export interface Invoice {
    readonly id: number;
    readonly billingAccountId: number;
    readonly created: string;
    readonly periodBegin: string;
    readonly periodEnd: string;
    readonly netAmount: number;
    readonly grossAmount: number;
    readonly paid: boolean;
    readonly reverseInvoiceId: number | null;
}

export interface InvoiceReminderEmailForm {
    readonly bccReceivers: string[];
    readonly subject: string;
    readonly htmlBody: string;
    readonly locale: string;
}

export interface InvoiceReminderEmailPreview {
    readonly subject: string;
    readonly htmlBody: string;
}

interface InvoicesApi {
    getAll(fromInvoiceId: number | null, toInvoiceId: number | null, isPaid: boolean | null): Promise<Invoice[]>;
    getByCreatedMonth(month: string): Promise<Invoice[]>;
    updatePaid(id: number, paid: boolean): Promise<void>;
    markAsPaid(ids: number[]): Promise<void>;
    createCustomInvoice(customInvoiceForm: CustomInvoiceForm, sendEmail: boolean): Promise<number>;
    cancel(id: number, sendEmail: boolean): Promise<number>;
    generateDownloadLink(id: number, reminder: boolean): string;
    previewReminderEmail(
        id: number,
        invoiceReminderEmailForm: InvoiceReminderEmailForm
    ): Promise<InvoiceReminderEmailPreview | null>;
    sendReminderEmail(id: number, invoiceReminderEmailForm: InvoiceReminderEmailForm): Promise<void>;
}

export const invoicesApi: InvoicesApi = {
    async getAll(fromInvoiceId, toInvoiceId, isPaid) {
        return (
            await axios.get("/api/invoices", {
                params: {
                    fromInvoiceId,
                    toInvoiceId,
                    isPaid,
                },
            })
        ).data;
    },

    async getByCreatedMonth(month) {
        return (await axios.get(`/api/invoices/${encodeURIComponent(month)}`)).data;
    },

    async updatePaid(id, paid) {
        await axios.patch(`/api/invoices/${id}`, { paid });
    },

    async markAsPaid(ids) {
        await axios.post("/api/invoices/_mark-as-paid", ids);
    },

    async createCustomInvoice(customInvoiceForm, sendEmail) {
        return (await axios.post("/api/invoices", customInvoiceForm, { params: { sendEmail } })).data;
    },

    async cancel(id, sendEmail) {
        return (await axios.post(`/api/invoices/${id}/_cancel`, undefined, { params: { sendEmail } })).data;
    },

    generateDownloadLink(id, reminder) {
        if (reminder) {
            return `/api/invoices/${id}.pdf?reminder=true`;
        } else {
            return `/api/invoices/${id}.pdf`;
        }
    },

    async previewReminderEmail(id, invoiceReminderEmailForm) {
        return (await axios.post(`/api/invoices/${id}/_preview-reminder-email`, invoiceReminderEmailForm)).data;
    },

    async sendReminderEmail(id, invoiceReminderEmailForm) {
        await axios.post(`/api/invoices/${id}/_send-reminder-email`, invoiceReminderEmailForm);
    },
};


import AgentActionStatisticsDataTable from "./AgentActionStatisticsDataTable.vue";
import { AgentActionStatisticsDataTableSearchOrder } from "./agentActionStatisticsDataTable";
import DateRangePicker from "@/app/components/DateRangePicker.vue";
import EnumField from "@/app/components/EnumField.vue";
import { DateRange } from "@/app/components/dateRangePicker";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { addDuration, endOf, getDate, startOf, UnitOfTime } from "@/util/dateTimeUtils";
import Vue from "vue";
import { TranslateResult } from "vue-i18n";

interface QuickFilter {
    readonly label: TranslateResult;
    readonly from: string;
    readonly to: string;
}

export default Vue.extend({
    data() {
        const ts = now();

        return {
            AgentActionStatisticsDataTableSearchOrder,
            loadFilter: {
                createdRange: {
                    from: getDate(ts, userSession.timeZone),
                    to: getDate(ts, userSession.timeZone),
                } as DateRange,
            },
            loading: true,
            now: ts,
            sortBy: AgentActionStatisticsDataTableSearchOrder.CALLS_ACCEPTED_DESC,
        };
    },

    computed: {
        quickFilters(): QuickFilter[] {
            const timeZone = this.timeZone;
            const locale = userSession.locale;

            const relativeStartOf = (offset: number, unit: UnitOfTime) =>
                getDate(startOf(addDuration(this.now, timeZone, offset, unit), timeZone, locale, unit), timeZone);

            const relativeEndOf = (offset: number, unit: UnitOfTime) =>
                getDate(endOf(addDuration(this.now, timeZone, offset, unit), timeZone, locale, unit), timeZone);

            return [
                {
                    label: this.$t("Heute"),
                    from: relativeStartOf(0, UnitOfTime.DAY),
                    to: relativeEndOf(0, UnitOfTime.DAY),
                },
                {
                    label: this.$t("Aktuelle Woche"),
                    from: relativeStartOf(0, UnitOfTime.WEEK),
                    to: relativeEndOf(0, UnitOfTime.WEEK),
                },
                {
                    label: this.$t("Letzte Woche"),
                    from: relativeStartOf(-1, UnitOfTime.WEEK),
                    to: relativeEndOf(-1, UnitOfTime.WEEK),
                },
                {
                    label: this.$t("Aktueller Monat"),
                    from: relativeStartOf(0, UnitOfTime.MONTH),
                    to: relativeEndOf(0, UnitOfTime.MONTH),
                },
                {
                    label: this.$t("Letzter Monat"),
                    from: relativeStartOf(-1, UnitOfTime.MONTH),
                    to: relativeEndOf(-1, UnitOfTime.MONTH),
                },
                {
                    label: this.$t("Vorletzter Monat"),
                    from: relativeStartOf(-2, UnitOfTime.MONTH),
                    to: relativeEndOf(-2, UnitOfTime.MONTH),
                },
            ];
        },

        timeZone(): string {
            return userSession.timeZone;
        },
    },

    methods: {
        isQuickFilterSelected(quickFilter: QuickFilter) {
            return (
                this.loadFilter.createdRange.from === quickFilter.from &&
                this.loadFilter.createdRange.to === quickFilter.to
            );
        },

        async refresh() {
            await (this.$refs.agentActionStatisticsDataTable as any).refresh();
        },

        setTimeRangeByQuickFilter(quickFilter: QuickFilter) {
            this.loadFilter.createdRange = {
                from: quickFilter.from,
                to: quickFilter.to,
            };
        },
    },

    components: {
        AgentActionStatisticsDataTable,
        DateRangePicker,
        EnumField,
    },
});

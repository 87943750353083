
import { partitionHouseKeepInventoryApi } from "./partitionHouseKeepInventoryApi";
import { partitionsApi } from "@/api/partitions";
import { Permission } from "@/api/userSession";
import { showConfirm } from "@/app/messageUtil";
import { userSession } from "@/store/userSession";
import Vue from "vue";
import { TranslateResult } from "vue-i18n";

enum TaskType {
    MARK_INVENTORY_VEHICLES_WITHOUT_SOURCE_AS_DELETED = "MARK_INVENTORY_VEHICLES_WITHOUT_SOURCE_AS_DELETED",
    REMOVE_DUPLICATES_OF_ALL_INVENTORY_VEHICLES = "REMOVE_DUPLICATES_OF_ALL_INVENTORY_VEHICLES",
    REMOVE_DUPLICATES_OF_INVENTORY_VEHICLES_WITHOUT_SOURCE = "REMOVE_DUPLICATES_OF_INVENTORY_VEHICLES_WITHOUT_SOURCE",
}

interface Task {
    readonly type: TaskType;
    readonly label: TranslateResult;
    readonly enabled: boolean;
    readonly execute: (host: string) => Promise<void>;
    readonly size: (host: string) => Promise<null | number>;
}

interface TaskState {
    readonly type: TaskType;
    readonly enabled: boolean;
    size: number | null;
    errorWhileExecuting: boolean;
    querying: boolean;
    executing: boolean;
    enqueued: { [operation: string]: boolean };
}

enum Operation {
    EXECUTE = "EXECUTE",
    QUERY_SIZE = "QUERY_SIZE",
}

interface Job {
    readonly partitionState: PartitionState;
    readonly taskType: TaskType;
    readonly operation: Operation;
    working: boolean;
    completed: boolean;
}

interface PartitionState {
    readonly host: string;
    readonly tasks: { [taskType: string]: TaskState };
}

export default Vue.extend({
    data() {
        return {
            aborting: false,
            loading: false,
            maxParallelOtherRequests: 5,
            maxParallelQuerySizeRequests: 20,
            newJobQueue: [] as Job[],
            Operation,
            partitionStates: [] as PartitionState[],
            processingQueue: [] as Job[],
            processingTimeout: null as number | null,
        };
    },

    computed: {
        canManageInventoryImport(): boolean {
            return userSession.hasPermission(Permission.MANAGE_INVENTORY_IMPORT);
        },

        tasks(): Task[] {
            return [
                {
                    type: TaskType.MARK_INVENTORY_VEHICLES_WITHOUT_SOURCE_AS_DELETED,
                    label: this.$t("Entferne Fahrzeuge ohne Importquelle aus dem Fahrzeugbestand"),
                    enabled: this.canManageInventoryImport,
                    execute: partitionHouseKeepInventoryApi.markInventoryVehiclesWithoutSourceAsDeleted,
                    size: partitionHouseKeepInventoryApi.getNonDeletedInventoryVehiclesWithoutSourceCount,
                },
                {
                    type: TaskType.REMOVE_DUPLICATES_OF_INVENTORY_VEHICLES_WITHOUT_SOURCE,
                    label: this.$t("Entferne Dubletten von Fahrzeugen ohne Importquelle"),
                    enabled: true,
                    execute: partitionHouseKeepInventoryApi.removeDuplicatesOfInventoryVehiclesWithoutSource,
                    size: () => Promise.resolve(null),
                },
                {
                    type: TaskType.REMOVE_DUPLICATES_OF_ALL_INVENTORY_VEHICLES,
                    label: this.$t("Entferne Dubletten von allen Fahrzeugen"),
                    enabled: true,
                    execute: partitionHouseKeepInventoryApi.removeDuplicatesOfAllInventoryVehicles,
                    size: () => Promise.resolve(null),
                },
            ];
        },
    },

    methods: {
        async addExecuteTaskJobByPartitionToQueue(taskType: TaskType, partitionState: PartitionState) {
            if (
                !(await showConfirm(
                    this.$t("Aufgabe ausführen") as string,
                    this.$t("Sind Sie sicher, dass Sie die Aufgabe ausführen möchten?") as string
                ))
            ) {
                return;
            }

            this.addJobToQueue(taskType, partitionState, Operation.EXECUTE);
        },

        async addExecuteTaskJobToQueue(taskType: TaskType, onlyWithError: boolean) {
            if (
                !(await showConfirm(
                    this.$t(
                        onlyWithError
                            ? "Aufgabe in fehlgeschlagenen Partitionen ausführen"
                            : "Aufgabe in allen Partitionen ausführen"
                    ) as string,
                    this.$t("Sind Sie sicher, dass Sie die Aufgabe ausführen möchten?") as string
                ))
            ) {
                return;
            }

            for (const partitionState of this.partitionStates) {
                const taskState = partitionState.tasks[taskType];

                if (taskState.executing || (onlyWithError && !taskState.errorWhileExecuting)) {
                    continue;
                }

                this.addJobToQueue(taskType, partitionState, Operation.EXECUTE);
            }
        },

        addJobToQueue(taskType: TaskType, partitionState: PartitionState, operation: Operation) {
            if (partitionState.tasks[taskType].enqueued[operation]) {
                return;
            }

            partitionState.tasks[taskType].enqueued[operation] = true;

            this.newJobQueue.push({
                partitionState,
                taskType,
                operation,
                working: false,
                completed: false,
            });
        },

        addQueryTaskSizesToQueue(taskType: TaskType) {
            for (const partitionState of this.partitionStates) {
                this.addJobToQueue(taskType, partitionState, Operation.QUERY_SIZE);
            }
        },

        continueToProcess() {
            if (this.processingTimeout !== null) {
                clearTimeout(this.processingTimeout);
            }

            // add new jobs to processing queue
            const newJobQueue = this.newJobQueue;
            this.newJobQueue = [];
            this.processingQueue.push(...newJobQueue);

            // remove completed jobs from processing queue
            const uncompleted: Job[] = [];

            for (const job of this.processingQueue) {
                if (job.completed || (this.aborting && !job.working)) {
                    job.partitionState.tasks[job.taskType].enqueued[job.operation] = false;
                } else {
                    uncompleted.push(job);
                }
            }

            this.processingQueue = uncompleted;

            // determine next jobs
            const otherRequestJobs = this.processingQueue.filter((j) => j.operation !== Operation.QUERY_SIZE);
            const querySizeRequestJobs = this.processingQueue.filter((j) => j.operation === Operation.QUERY_SIZE);

            const otherRequestRunningCount = otherRequestJobs.filter((j) => j.working).length;
            const querySizeRequestRunningCount = querySizeRequestJobs.filter((j) => j.working).length;

            const nextJobs = [
                ...otherRequestJobs
                    .filter((j) => !j.working)
                    .slice(0, Math.max(0, this.maxParallelOtherRequests - otherRequestRunningCount)),
                ...querySizeRequestJobs
                    .filter((j) => !j.working)
                    .slice(0, Math.max(0, this.maxParallelQuerySizeRequests - querySizeRequestRunningCount)),
            ];

            // start processing of next job (non-blocking)
            for (const job of nextJobs) {
                this.processJob(job);
            }

            // continue
            this.aborting = false;
            this.processingTimeout = setTimeout(this.continueToProcess, 50);
        },

        async executeTaskByPartition(taskType: TaskType, partitionState: PartitionState) {
            const task = this.tasks.find((task) => task.type === taskType);
            const taskState = partitionState.tasks[taskType];

            if (!task || taskState.executing) {
                return;
            }

            taskState.size = null;
            taskState.errorWhileExecuting = false;
            taskState.executing = true;
            try {
                await task.execute(partitionState.host);

                this.addJobToQueue(taskType, partitionState, Operation.QUERY_SIZE);
            } catch (e) {
                taskState.errorWhileExecuting = false;
                throw e;
            } finally {
                taskState.executing = false;
            }
        },

        getTaskStates(partitionState: PartitionState, tasks: Task[]): TaskState[] {
            return tasks.map((task) => partitionState.tasks[task.type]);
        },

        hasExecuteErrorsByTaskType(taskType: TaskType) {
            return this.partitionStates.some((p) => p.tasks[taskType]?.errorWhileExecuting);
        },

        async loadPartitionTasksStates() {
            this.partitionStates = [];
            this.newJobQueue = [];
            this.processingQueue = [];

            this.loading = true;
            try {
                const partitionStates = (await partitionsApi.list())
                    .filter((p) => p.active)
                    .map((p) => ({
                        host: p.host,
                        tasks: this.tasks.reduce((map, task) => {
                            map[task.type] = {
                                type: task.type,
                                enabled: task.enabled,
                                size: null,
                                errorWhileExecuting: false,
                                querying: false,
                                executing: false,
                                enqueued: Object.keys(Operation).reduce((opMap, operation) => {
                                    opMap[operation] = false;

                                    return opMap;
                                }, {} as TaskState["enqueued"]),
                            };

                            return map;
                        }, {} as PartitionState["tasks"]),
                    }));

                this.partitionStates = partitionStates.sort((a, b) => a.host.localeCompare(b.host, userSession.locale));
            } finally {
                this.loading = false;
            }
        },

        async queryTaskSizeByPartition(taskType: TaskType, partitionState: PartitionState) {
            const task = this.tasks.find((task) => task.type === taskType);
            const taskState = partitionState.tasks[taskType];

            if (!task || taskState.querying) {
                return;
            }

            taskState.size = null;
            taskState.querying = true;
            try {
                taskState.size = await task.size(partitionState.host);
            } finally {
                taskState.querying = false;
            }
        },

        async processJob(job: Job) {
            job.working = true;
            job.completed = false;

            try {
                if (job.operation === Operation.EXECUTE) {
                    await this.executeTaskByPartition(job.taskType, job.partitionState);
                } else if (job.operation === Operation.QUERY_SIZE) {
                    await this.queryTaskSizeByPartition(job.taskType, job.partitionState);
                }
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            } finally {
                job.working = false;
                job.completed = true;
            }
        },
    },

    async mounted() {
        await this.loadPartitionTasksStates();
        await this.continueToProcess();
    },

    destroyed() {
        if (this.processingTimeout) {
            clearTimeout(this.processingTimeout);
        }
    },
});

import { Configuration, configurationApi } from "@/api/config";
import { notificationEventSource } from "@/api/notifications";
import { reactive } from "@/util/reactive";

@reactive
class ConfigStore {
    private config: Configuration | null = null;
    private connected = true;
    private outdated_ = false;

    get configuration() {
        return this.config!;
    }

    get outdated() {
        return this.outdated_;
    }

    async initialize() {
        this.config = await configurationApi.config();
    }

    async connectionChanged(connected: boolean) {
        if (!this.connected && connected && this.config && !this.outdated_) {
            try {
                const config = await configurationApi.config();
                this.outdated_ = this.config.shortCommitId !== config.shortCommitId;
            } catch (e) {
                this.outdated_ = true;
                throw e;
            }
        }
        this.connected = connected;
    }
}

export const configStore = new ConfigStore();

notificationEventSource.addConnectionHandler((connected) => configStore.connectionChanged(connected));

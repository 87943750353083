
import { ExtendedCallCenterStatisticsData, toExtendedCallCenterStatisticsData } from "./callCenterStatisticsUtils";
import { CallCenterStatistics } from "@/api/callCenterStatistics";
import Vue from "vue";

export default Vue.extend({
    props: {
        acceptanceRatioThreshold: {
            type: Number,
            required: true,
        },
        callCenterStatistics: {
            type: Object as () => CallCenterStatistics,
            required: true,
        },
        errorRatioThreshold: {
            type: Number,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        performanceThreshold: {
            type: Number,
            required: true,
        },
        small: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        callCenterStatisticsData(): ExtendedCallCenterStatisticsData {
            return toExtendedCallCenterStatisticsData(this.callCenterStatistics);
        },
    },

    methods: {
        renderPercentage(value: number): string {
            return this.$n(value, { style: "percent", minimumFractionDigits: "1", maximumFractionDigits: "1" });
        },
    },
});

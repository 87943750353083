
import { ExternalBdcCdr } from "@/api/externalBdcCdrs";
import { Permission } from "@/api/userSession";
import { UserOnlinePeriod } from "@/api/userTracking";
import { User } from "@/api/users";
import DutyRosterPanelBottomSheet from "@/app/pages/dutyrosters/DutyRosterPanelBottomSheet.vue";
import ExternalBdcCdrsBottomSheet from "@/app/pages/externalbdccdr/ExternalBdcCdrsBottomSheet.vue";
import { getReportingTableByReportingTimeSeriesChart } from "@/app/pages/reporting/chartUtils";
import { ReportingChartColorPalette } from "@/app/pages/reporting/charts/reportingChart";
import { hasNonZeroSeries, ReportingTimeSeriesChartData } from "@/app/pages/reporting/charts/reportingTimeSeriesChart";
import ReportingDashboardTimeSeriesTile from "@/app/pages/reporting/dashboard/ReportingDashboardTimeSeriesTile.vue";
import {
    AnswerIncomingCallsShift,
    DutyRosterRow,
    WithDuration as WithDutyRosterDuration,
    WithElapsedDuration as WthDutyRosterElapsedDuration,
} from "@/app/pages/reporting/dutyroster/dutyRosterRowUtils";
import { addMissingRowGroups, groupRowsBy, RowGroup } from "@/app/pages/reporting/pivotUtils";
import { getMaxDate, getMinDate, renderDuration } from "@/app/pages/reporting/reportingUtils";
import { ReportingTableData } from "@/app/pages/reporting/table/reportingTable";
import {
    getNextOngoingTimeSlot,
    getOngoingTimeSlotKeys,
    getOngoingTimeSlotLabel,
    OngoingTimeInterval,
} from "@/app/pages/reporting/timeInterval";
import { getNumberOfSubIntervalsInOngoingTimeInterval } from "@/app/pages/reporting/timeSeriesUtils";
import { getFullName } from "@/app/userUtils";
import { userSession } from "@/store/userSession";
import { groupByAsMap } from "@/util/arrayUtils";
import { formatTime, getDate } from "@/util/dateTimeUtils";
import Vue from "vue";

type ComputedDutyRosterRow = PrecomputedDutyRosterRow;
type PrecomputedDutyRosterRow = DutyRosterRow & WithDutyRosterDuration & WthDutyRosterElapsedDuration;

interface DutyRosterRowUserSlot {
    readonly userId: string;
    readonly user: User | null;
    readonly ongoingTimeSlot: string;
    readonly ongoingTimeSlotDate: Date;
    readonly wasOnline: boolean;
    readonly wasInCall: boolean;
    readonly unacceptedExternalBdcCdrRows: ExternalBdcCdr[];
    readonly previousCallEnd: Date | null;
    readonly nextCallAccept: Date | null;
    readonly answerIncomingCallsDutyRosterRows: ComputedDutyRosterRow[];
    readonly minAnswerIncomingCallsDutyBegin: Date;
    readonly maxAnswerIncomingCallsDutyEnd: Date;
}

export default Vue.extend({
    props: {
        answerIncomingCallsShift: {
            type: Array as () => readonly AnswerIncomingCallsShift<PrecomputedDutyRosterRow>[],
            required: true,
        },
        chartHeight: {
            type: Number,
            required: false,
        },
        chartTimeInterval: {
            type: String as () => OngoingTimeInterval,
            default: OngoingTimeInterval.DATE,
        },
        chartWindowInterval: {
            type: String as () => OngoingTimeInterval,
            default: OngoingTimeInterval.WEEK,
        },
        externalBdcCdrRows: {
            type: Array as () => readonly ExternalBdcCdr[],
            required: true,
        },
        loading: {
            type: Boolean,
            required: true,
        },
        minHeight: {
            type: Number,
            required: false,
        },
        small: {
            type: Boolean,
            default: false,
        },
        subtitle: {
            type: String,
            required: false,
        },
        timeSeriesFrom: {
            type: (Date as unknown) as () => Date,
            required: true,
        },
        timeSeriesTo: {
            type: (Date as unknown) as () => Date,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        userOnlinePeriods: {
            type: Array as () => readonly UserOnlinePeriod[],
            required: true,
        },
        users: {
            type: Array as () => readonly User[],
            required: true,
        },
    },

    data() {
        return {
            getFullName,
            getMinDate,
            renderDuration,
            maxPostCallProcessingSeconds: 20,
            dialogUserSlots: [] as readonly DutyRosterRowUserSlot[],
            dialogTitle: null as string | null,
            dialogVisible: false,
            dutyRosterPanelBottomSheetDate: null as string | null,
            dutyRosterPanelBottomSheetUserId: null as string | null,
            dutyRosterPanelBottomSheetVisible: false,
            externalBdcCdrBottomSheetExternalBdcCdrIds: [] as number[],
            externalBdcCdrBottomSheetTitle: null as string | null,
            externalBdcCdrBottomSheetVisible: false,
        };
    },

    computed: {
        canViewDutyRoster() {
            return userSession.hasPermission(Permission.CT_MANAGE_DUTY_ROSTER);
        },

        chart(): ReportingTimeSeriesChartData | undefined {
            const rowGroups = this.rowGroups.map((rowGroup) => ({
                ...rowGroup,
                onClick: this.showDialogOnClick(rowGroup.rows, this.dateFormatter(new Date(rowGroup.key), false)),
            }));

            const chart: ReportingTimeSeriesChartData = {
                title: "",
                series: [
                    {
                        id: "planned",
                        name: this.$t("Eingeplant") as string,
                        data: {
                            values: rowGroups.map((rowGroup) => ({
                                date: new Date(rowGroup.key),
                                value: rowGroup.rows.length,
                                onClick: rowGroup.onClick,
                            })),
                        },
                    },
                    {
                        id: "online",
                        name: this.$t("Online") as string,
                        data: {
                            values: rowGroups.map((rowGroup) => ({
                                date: new Date(rowGroup.key),
                                value: rowGroup.rows.filter((r) => r.wasOnline).length,
                                onClick: rowGroup.onClick,
                            })),
                        },
                        colorPalette: ReportingChartColorPalette.POSITIVE,
                    },
                    {
                        id: "not-in-call-count",
                        name: this.$t("Online und untätig seit mind. {0}", [
                            renderDuration(this.maxPostCallProcessingSeconds, "S", true),
                        ]) as string,
                        data: {
                            values: rowGroups.map((rowGroup) => ({
                                date: new Date(rowGroup.key),
                                value: rowGroup.rows.filter((r) => r.wasOnline && !r.wasInCall).length,
                                onClick: rowGroup.onClick,
                            })),
                        },
                        colorPalette: ReportingChartColorPalette.NEGATIVE,
                    },
                ],
                dateFormatter: this.dateFormatter,
            };

            if (!hasNonZeroSeries(chart)) {
                return undefined;
            }

            return chart;
        },

        chartWindowSize(): number | undefined {
            return (
                getNumberOfSubIntervalsInOngoingTimeInterval(this.chartWindowInterval, this.chartTimeInterval) ??
                undefined
            );
        },

        defaultKeys(): string[] {
            return getOngoingTimeSlotKeys(
                this.timeSeriesFrom,
                this.timeSeriesTo,
                this.chartTimeInterval,
                this.timeZone,
                true
            );
        },

        timeZone(): string {
            return userSession.timeZone;
        },

        rowGroups(): readonly RowGroup<string, DutyRosterRowUserSlot>[] {
            const maxPostCallProcessingSeconds = this.maxPostCallProcessingSeconds;
            const externalBdcCdrRowsByUser = groupByAsMap(this.externalBdcCdrRows, (r) => r.userId);
            const onlinePeriodsByUser = groupByAsMap(this.userOnlinePeriods, (o) => o.userId);
            const unacceptedCallRows = this.externalBdcCdrRows
                .filter((r) => r.begin.getTime() < this.timeSeriesTo.getTime())
                .filter((r) => this.timeSeriesFrom.getTime() < r.end.getTime())
                .filter((r) => !r.accept);

            const entries: DutyRosterRowUserSlot[] = this.answerIncomingCallsShift
                .filter((r) => r.begin.getTime() < this.timeSeriesTo.getTime())
                .filter((r) => this.timeSeriesFrom.getTime() < r.end.getTime())
                .map((shift) => ({
                    ...shift,
                    user: this.users.find((u) => u.id === shift.userId) ?? null,
                    userExternalBdcCdrRowsInShift: (externalBdcCdrRowsByUser.get(shift.userId) ?? []).filter((r) =>
                        shift.rows.some(
                            (d) => d.begin.getTime() <= r.accept!.getTime() && r.accept!.getTime() < d.end.getTime()
                        )
                    ),
                }))
                .map((shift) => ({
                    ...shift,
                    ongoingTimeSlots: shift.rows
                        .map((r) =>
                            getOngoingTimeSlotKeys(
                                getMaxDate(r.begin, this.timeSeriesFrom)!,
                                getMinDate(r.end, this.timeSeriesTo)!,
                                this.chartTimeInterval,
                                this.timeZone,
                                false
                            )
                        )
                        .reduce((prev, cur) => prev.concat(cur), [])
                        .sort((a, b) => a.localeCompare(b))
                        .filter((slot, index, array) => !index || slot !== array[index - 1])
                        .map((ongoingTimeSlot) => ({
                            userId: shift.userId,
                            user: shift.user,
                            ongoingTimeSlot,
                            ongoingTimeSlotDate: new Date(ongoingTimeSlot),
                        }))
                        .map((data, index, array) => ({
                            ...data,
                            nextOngoingTimeSlotDate:
                                index + 1 < array.length
                                    ? array[index + 1].ongoingTimeSlotDate
                                    : getNextOngoingTimeSlot(
                                          data.ongoingTimeSlotDate,
                                          this.chartTimeInterval,
                                          this.timeZone
                                      )!,
                            answerIncomingCallsDutyRosterRows: shift.rows.filter(
                                (r) =>
                                    r.begin.getTime() <= data.ongoingTimeSlotDate.getTime() &&
                                    data.ongoingTimeSlotDate.getTime() < r.end.getTime()
                            ),
                        }))
                        .filter((data) => data.answerIncomingCallsDutyRosterRows.length)
                        .map((data) => ({
                            ...data,
                            wasInCall: shift.userExternalBdcCdrRowsInShift.some(
                                (r) =>
                                    r.accept!.getTime() < data.nextOngoingTimeSlotDate.getTime() &&
                                    data.ongoingTimeSlotDate.getTime() <
                                        r.end.getTime() + maxPostCallProcessingSeconds * 1000
                            ),
                            wasOnline: (onlinePeriodsByUser.get(shift.userId) ?? []).some(
                                (o) =>
                                    o.begin.getTime() < data.nextOngoingTimeSlotDate.getTime() &&
                                    (!o.end || data.ongoingTimeSlotDate.getTime() < o.end.getTime())
                            ),
                            minAnswerIncomingCallsDutyBegin: getMinDate(
                                ...data.answerIncomingCallsDutyRosterRows.map((d) => d.begin)
                            )!,
                            maxAnswerIncomingCallsDutyEnd: getMaxDate(
                                ...data.answerIncomingCallsDutyRosterRows.map((d) => d.end)
                            )!,
                        }))
                        .map((data) => ({
                            ...data,
                            previousCallEnd: data.wasInCall
                                ? null
                                : getMaxDate(
                                      ...shift.userExternalBdcCdrRowsInShift
                                          .filter((r) => r.end.getTime() < data.ongoingTimeSlotDate.getTime())
                                          .filter((r) =>
                                              data.answerIncomingCallsDutyRosterRows.some(
                                                  (d) =>
                                                      d.begin.getTime() <= r.begin.getTime() &&
                                                      r.begin.getTime() < d.end.getTime()
                                              )
                                          )
                                          .map((r) => r.end)
                                  ),
                            nextCallAccept: data.wasInCall
                                ? null
                                : getMinDate(
                                      ...shift.userExternalBdcCdrRowsInShift
                                          .filter((r) => data.nextOngoingTimeSlotDate.getTime() <= r.accept!.getTime())
                                          .filter((r) =>
                                              data.answerIncomingCallsDutyRosterRows.some(
                                                  (d) =>
                                                      d.begin.getTime() <= r.begin.getTime() &&
                                                      r.begin.getTime() < d.end.getTime()
                                              )
                                          )
                                          .map((r) => r.accept)
                                  ),
                        }))
                        .map((data) => ({
                            ...data,
                            unacceptedExternalBdcCdrRows: data.wasInCall
                                ? []
                                : unacceptedCallRows
                                      .filter(
                                          (r) =>
                                              (data.previousCallEnd
                                                  ? data.previousCallEnd.getTime() + maxPostCallProcessingSeconds * 1000
                                                  : data.minAnswerIncomingCallsDutyBegin.getTime()) <= r.begin.getTime()
                                      )
                                      .filter(
                                          (r) =>
                                              // only count calls that began at least x seconds before the next accepted call
                                              r.begin.getTime() + 30 * 1000 <
                                              (data.nextCallAccept
                                                  ? data.nextCallAccept.getTime()
                                                  : data.maxAnswerIncomingCallsDutyEnd.getTime())
                                      ),
                        })),
                }))
                .map((shift) => shift.ongoingTimeSlots)
                .reduce((prev, cur) => prev.concat(cur), []);

            const rowGroups = groupRowsBy(entries, (r) => r.ongoingTimeSlot);

            return addMissingRowGroups(rowGroups, this.defaultKeys);
        },

        table(): ReportingTableData | null {
            if (!this.chart) {
                return null;
            }

            return {
                ...getReportingTableByReportingTimeSeriesChart(this.chart),
                groupByHeaderText: this.$t("Zeitraum") as string,
            };
        },
    },

    methods: {
        dateFormatter(date: Date, short: boolean): string {
            return getOngoingTimeSlotLabel(date, this.chartTimeInterval, this.timeZone, short ? "S" : "L") || "";
        },

        formatDifference(from: Date, to: Date): string {
            return renderDuration(Math.floor(to.getTime() / 1000) - Math.floor(from.getTime() / 1000), "S", false)!;
        },

        formatTime(date: Date, includeSeconds: boolean): string {
            return formatTime(date, this.timeZone, userSession.locale, includeSeconds);
        },

        hideDialog() {
            this.dialogUserSlots = [];
            this.dialogVisible = false;
            this.dialogTitle = null;
        },

        hideDutyRosterPanelBottomSheet() {
            this.dutyRosterPanelBottomSheetVisible = false;
            this.dutyRosterPanelBottomSheetDate = null;
            this.dutyRosterPanelBottomSheetUserId = null;
        },

        hideExternalBdcCdrBottomSheet() {
            this.externalBdcCdrBottomSheetVisible = false;
            this.externalBdcCdrBottomSheetExternalBdcCdrIds = [];
            this.externalBdcCdrBottomSheetTitle = null;
        },

        showDialogOnClick(userSlots: readonly DutyRosterRowUserSlot[], title: string): () => void {
            return () => {
                this.dialogUserSlots = [...userSlots].sort(
                    (a, b) =>
                        (a.wasInCall ? 1 : 0) - (b.wasInCall ? 1 : 0) ||
                        (a.wasOnline ? 1 : 0) - (b.wasOnline ? 1 : 0) ||
                        b.unacceptedExternalBdcCdrRows.length - a.unacceptedExternalBdcCdrRows.length
                );
                this.dialogTitle = title;
                this.dialogVisible = true;
            };
        },

        showDutyRosterPanelBottomSheet(date: Date, userId: string) {
            this.dutyRosterPanelBottomSheetDate = getDate(date, this.timeZone);
            this.dutyRosterPanelBottomSheetUserId = userId;
            this.dutyRosterPanelBottomSheetVisible = true;
        },

        showExternalBdcCdrBottomSheet(externalBdcCdrIds: number[], user: User | null, idleFrom: Date, idleTo: Date) {
            this.externalBdcCdrBottomSheetExternalBdcCdrIds = [...new Set(externalBdcCdrIds)];
            this.externalBdcCdrBottomSheetTitle = `${
                user ? getFullName(user) : this.$t("Unbekannter Benutzer")
            } (${this.$t("untätig von {0} bis {1}", [
                this.formatTime(idleFrom, true),
                this.formatTime(idleTo, true),
            ])})`;
            this.externalBdcCdrBottomSheetVisible = true;
        },
    },

    components: {
        DutyRosterPanelBottomSheet,
        ExternalBdcCdrsBottomSheet,
        ReportingDashboardTimeSeriesTile,
    },
});

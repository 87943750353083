
import { Dealer, dealersApi } from "@/api/dealers";
import { partitionsApi, PartitionSummary } from "@/api/partitions";
import NumberField from "@/app/components/NumberField.vue";
import CrudTablePage from "@/app/pages/CrudTablePage.vue";
import { TableHeader } from "@/app/pages/crudPage";
import { notEmpty } from "@/app/validation";
import { userSession } from "@/store/userSession";
import Vue from "vue";

interface DealerExternalBdcTargetNumberWithDealer {
    readonly partitionId: string;
    readonly dealerId: string;
    readonly number: string;
    readonly distributionBeginOffset: number;
}

export default Vue.extend({
    data() {
        return {
            dealers: [] as Dealer[],
            notEmpty: notEmpty,
            partitions: [] as PartitionSummary[],
        };
    },

    computed: {
        headers(): TableHeader[] {
            return [
                {
                    id: "externalBdcTargetNumber",
                    text: this.$t("Zielrufnummer"),
                    width: "25%",
                },
                {
                    id: "partition",
                    text: this.$t("Partition"),
                    width: "25%",
                },
                {
                    id: "dealer",
                    text: this.$t("Standort"),
                    width: "25%",
                },
                {
                    id: "distribution",
                    text: this.$t("Sekunden bis zur Anrufverteilung"),
                    width: "25%",
                },
            ];
        },
    },

    methods: {
        async editDealerExternalBdcTargetNumber(form: DealerExternalBdcTargetNumberWithDealer) {
            await dealersApi.editDealerExternalBdcTargetNumber({
                number: form.number,
                distributionBeginOffset: form.distributionBeginOffset,
            });
        },

        async getDealerExternalBdcTargetNumbers(): Promise<DealerExternalBdcTargetNumberWithDealer[]> {
            this.dealers = await dealersApi.list();

            return [...this.dealers]
                .sort((a, b) => a.name.localeCompare(b.name, userSession.locale))
                .map((d) =>
                    d.externalBdcTargetNumbers.map((n) => ({
                        ...n,
                        partitionId: d.partitionId,
                        dealerId: d.id,
                    }))
                )
                .reduce((prev, cur) => prev.concat(cur), []);
        },

        getDealerNameById(dealerId: string) {
            return this.dealers.find((d) => d.id === dealerId)?.name || this.$t("Unbekannter Standort");
        },

        getPartitionNameById(partitionId: string) {
            return this.partitions.find((p) => p.id === partitionId)?.name || this.$t("Unbekannte Partition");
        },

        toForm(item: DealerExternalBdcTargetNumberWithDealer): DealerExternalBdcTargetNumberWithDealer {
            return { ...item };
        },
    },

    async mounted() {
        this.partitions = await partitionsApi.list();
    },

    components: {
        CrudTablePage,
        NumberField,
    },
});

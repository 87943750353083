
import UserOverviewDataTableRow from "./UserOverviewDataTableRow.vue";
import { User } from "@/api/users";
import DataTable from "@/app/components/DataTable.vue";
import { DataTableHeader } from "@/app/components/dataTable";
import { getFullName } from "@/app/userUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        users: {
            type: Array as () => User[],
            default: () => [],
        },
    },

    data() {
        return {
            getFullName,
        };
    },

    computed: {
        headers(): DataTableHeader[] {
            return [
                {
                    text: this.$t("Benutzername"),
                    width: "20%",
                },
                {
                    text: this.$t("Name"),
                    width: "35%",
                },
                {
                    text: this.$t("Rollen"),
                    width: "35%",
                },
                {
                    text: this.$t("Aktionen"),
                    align: "right",
                    width: "10%",
                },
            ];
        },
    },

    components: {
        DataTable,
        UserOverviewDataTableRow,
    },
});

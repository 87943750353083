
import ContractFormDialog from "./ContractFormDialog.vue";
import ContractOverviewContractCard from "./ContractOverviewContractCard.vue";
import { BillingAccount } from "@/api/billingAccounts";
import { Contract } from "@/api/contracts";
import { Dealer } from "@/api/dealers";
import { PartitionSummary } from "@/api/partitions";
import { Permission } from "@/api/userSession";
import { userSession } from "@/store/userSession";
import Vue from "vue";

export default Vue.extend({
    props: {
        billingAccounts: {
            type: Array as () => BillingAccount[],
            required: true,
        },
        contracts: {
            type: Array as () => Contract[],
            required: true,
        },
        dealer: {
            type: Object as () => Dealer,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: true,
        },
        partition: {
            type: Object as () => PartitionSummary,
            required: true,
        },
    },

    data() {
        return {
            contractFormDialogVisible: false,
        };
    },

    computed: {
        canManageContracts(): boolean {
            return userSession.hasPermission(Permission.CT_MANAGE_CONTRACTS);
        },
    },

    methods: {
        savedContract() {
            this.contractFormDialogVisible = false;
            this.$emit("refresh");
        },
    },

    components: {
        ContractFormDialog,
        ContractOverviewContractCard,
    },
});


import AgentActionStatisticsDataTableRow from "./AgentActionStatisticsDataTableRow.vue";
import {
    AgentActionStatisticsDataTableSearchOrder,
    getAgentStatisticsSortField,
    isAgentStatisticsAscendingSort,
} from "./agentActionStatisticsDataTable";
import {
    addExtendedAgentStatisticsData,
    ExtendedAgentStatisticsData,
    toExtendedAgentStatisticsData,
} from "./agentStatisticsUtils";
import { AgentStatistics, agentStatisticsApi } from "@/api/agentStatistics";
import { DutyRosterEntryType } from "@/api/dutyRosters";
import { User, usersApi } from "@/api/users";
import { getFullName } from "@/app/userUtils";
import { ActionLimiter } from "@/util/debounce";
import Vue from "vue";

interface Item extends AgentStatistics {
    readonly extendedAgentStatisticsData: ExtendedAgentStatisticsData;
}

export default Vue.extend({
    props: {
        from: {
            type: String,
            required: true,
        },
        sortBy: {
            type: String as () => AgentActionStatisticsDataTableSearchOrder,
            default: AgentActionStatisticsDataTableSearchOrder.CALLS_ACCEPTED_DESC,
        },
        to: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            DutyRosterEntryType,
            agentStatistics: [] as AgentStatistics[],
            loadLimiter: new ActionLimiter(true),
            loading: true,
            searchId: 0,
            users: [] as User[],
        };
    },

    computed: {
        items(): Item[] {
            const directionFactor = !this.sortBy || isAgentStatisticsAscendingSort(this.sortBy) ? 1 : -1;

            return this.agentStatistics
                .map((item) => ({
                    ...item,
                    extendedAgentStatisticsData: toExtendedAgentStatisticsData(item.agentStatisticsData),
                }))
                .sort((a, b) => {
                    const aValue = getAgentStatisticsSortField(a.extendedAgentStatisticsData, this.sortBy);
                    const bValue = getAgentStatisticsSortField(b.extendedAgentStatisticsData, this.sortBy);

                    const callsAcceptedCmp = b.agentStatisticsData.callsAccepted - a.agentStatisticsData.callsAccepted;

                    if (aValue === bValue) {
                        return callsAcceptedCmp;
                    } else if (aValue === null || aValue === 0) {
                        return 1;
                    } else if (bValue === null || bValue === 0) {
                        return -1;
                    }

                    return (aValue - bValue) * directionFactor;
                });
        },

        total(): ExtendedAgentStatisticsData | null {
            if (!this.items.length) {
                return null;
            }

            return this.items
                .map((i) => i.extendedAgentStatisticsData)
                .reduce((a, b) => addExtendedAgentStatisticsData(a, b));
        },
    },

    methods: {
        getUserById(userId: string | null): User | null {
            return this.users.find((u) => u.id === userId) ?? null;
        },

        getUserFullNameById(userId: string | null) {
            const user = this.getUserById(userId);

            if (!user) {
                return this.$t("Gelöschter Benutzer");
            }

            return getFullName(user);
        },

        async loadItems() {
            this.agentStatistics = [];
            this.loading = true;
            const searchId = ++this.searchId;

            await this.loadLimiter.execute(async () => {
                try {
                    const agentStatistics = await agentStatisticsApi.getAgentStatistics(this.from, this.to);

                    if (searchId === this.searchId) {
                        this.agentStatistics = agentStatistics;
                    }
                } finally {
                    if (searchId === this.searchId) {
                        this.loading = false;
                    }
                }
            });
        },

        async refresh() {
            await this.loadItems();
        },
    },

    watch: {
        async from() {
            try {
                await this.loadItems();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },

        loading: {
            immediate: true,
            handler() {
                this.$emit("change:loading", this.loading);
            },
        },

        async to() {
            try {
                await this.loadItems();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },
    },

    async mounted() {
        this.users = await usersApi.list();
        await this.loadItems();
    },

    components: {
        AgentActionStatisticsDataTableRow,
    },
});

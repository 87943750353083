
import { ActionType } from "@/api/actions";
import { ExternalBdcCdr } from "@/api/externalBdcCdrs";
import { ActionRow } from "@/api/reporting";
import { User } from "@/api/users";
import { mapActionRowKeyToRowLabel } from "@/app/pages/reporting/action/actionRowUtils";
import { getReportingTableByReportingScatterChart } from "@/app/pages/reporting/chartUtils";
import { hasNonZeroSeries, ReportingScatterChartData } from "@/app/pages/reporting/charts/reportingScatterChart";
import ReportingDashboardScatterTile from "@/app/pages/reporting/dashboard/ReportingDashboardScatterTile.vue";
import { mapExternalBdcCdrRowKeyToRowLabel } from "@/app/pages/reporting/externalbdc/externalBdcRowUtils";
import { groupRowsBy } from "@/app/pages/reporting/pivotUtils";
import { rate } from "@/app/pages/reporting/reportingUtils";
import { TitledRowGroup } from "@/app/pages/reporting/rowUtils";
import { ReportingTableData } from "@/app/pages/reporting/table/reportingTable";
import Vue from "vue";

export default Vue.extend({
    props: {
        actionRows: {
            type: Array as () => readonly ActionRow[],
            required: true,
        },
        chartHeight: {
            type: Number,
            required: false,
        },
        externalBdcCdrRows: {
            type: Array as () => readonly ExternalBdcCdr[],
            required: true,
        },
        loading: {
            type: Boolean,
            required: true,
        },
        subtitle: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            required: false,
        },
        users: {
            type: Array as () => readonly User[],
            required: true,
        },
    },

    computed: {
        chart(): ReportingScatterChartData | null {
            const topCategories = this.actionRowGroups
                .map((rowGroup) => ({
                    categoryId: rowGroup.key,
                    name: rowGroup.title,
                    description: rowGroup.subtitle,
                    rows: rowGroup.rows,
                    notesCreated: rowGroup.rows.filter(
                        (r) =>
                            r.type === ActionType.ADD_ACTIVITY_EXISTING_CASE ||
                            r.type === ActionType.ADD_ACTIVITY_EXISTING_EMERGENCY_INCIDENT ||
                            r.type === ActionType.ADD_ACTIVITY_EXISTING_OPPORTUNITY
                    ).length,
                    acceptedCallCount:
                        this.externalBdcCdrRowGroups.find((g) => g.key === rowGroup.key)?.rows.length ?? 0,
                }))
                .map((c) => ({
                    ...c,
                    notesPerAcceptedCallRate: rate(c.notesCreated, c.acceptedCallCount),
                }))
                .sort((a, b) => (b.notesCreated ?? 0) - (a.notesCreated ?? 0))
                .filter((c) => !!c.notesPerAcceptedCallRate || !!c.acceptedCallCount);

            const chart: ReportingScatterChartData = {
                title: "",
                xAxis: [{ id: "call-count", name: this.$t("Anrufe") as string }],
                yAxis: [
                    { id: "notes-per-accepted-call-rate", name: this.$t("Notiz/Anruf") as string, isPercentage: true },
                ],
                categories: topCategories.map((c) => ({ name: c.name, description: c.description })),
                series: [
                    {
                        id: "rate",
                        name: this.$t("Quote") as string,
                        data: {
                            values: topCategories.map((c) => ({
                                xValue: c.acceptedCallCount,
                                yValue: c.notesPerAcceptedCallRate ?? 0,
                            })),
                            xAxisId: "call-count",
                            yAxisId: "notes-per-accepted-call-rate",
                        },
                    },
                ],
            };

            if (!hasNonZeroSeries(chart)) {
                return null;
            }

            return chart;
        },

        actionRowGroups(): readonly TitledRowGroup<string, ActionRow>[] {
            return groupRowsBy(
                this.actionRows.filter((r) => r.userId),
                (r) => r.userId
            ).map((rowGroup) => {
                const rowGroupLabel = mapActionRowKeyToRowLabel(rowGroup.key, "userId", {
                    users: this.users,
                });

                return {
                    ...rowGroup,
                    title: rowGroupLabel.label,
                    subtitle: rowGroupLabel.sublabel,
                };
            });
        },

        externalBdcCdrRowGroups(): readonly TitledRowGroup<string | null, ExternalBdcCdr>[] {
            return groupRowsBy(
                this.externalBdcCdrRows.filter((r) => r.userId),
                (r) => r.userId
            ).map((rowGroup) => {
                const rowGroupLabel = mapExternalBdcCdrRowKeyToRowLabel(rowGroup.key, "userId", {
                    users: this.users,
                });

                return {
                    ...rowGroup,
                    title: rowGroupLabel.label,
                    subtitle: rowGroupLabel.sublabel,
                };
            });
        },

        table(): ReportingTableData | null {
            if (!this.chart) {
                return null;
            }

            return {
                ...getReportingTableByReportingScatterChart(this.chart),
                groupByHeaderText: this.$t("Benutzer") as string,
            };
        },
    },

    components: {
        ReportingDashboardScatterTile,
    },
});

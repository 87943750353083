import axios from "axios";

export interface CaseSearchIndexSize {
    readonly expectedSize: number;
    readonly actualSize: number | null;
}

export interface ContactSearchIndexSize {
    readonly expectedSize: number;
    readonly actualSize: number | null;
}

export interface EmergencyIncidentSearchIndexSize {
    readonly expectedSize: number;
    readonly actualSize: number | null;
}

export interface InventorySearchIndexSize {
    readonly expectedSize: number;
    readonly actualSize: number | null;
}

export interface OpportunitySearchIndexSize {
    readonly expectedSize: number;
    readonly actualSize: number | null;
}

export interface FinanceContractSearchIndexSize {
    readonly expectedSize: number;
    readonly actualSize: number | null;
}

interface PartitionIndexApi {
    caseSearchIndexSize(host: string): Promise<CaseSearchIndexSize>;
    contactSearchIndexSize(host: string): Promise<ContactSearchIndexSize>;
    emergencyIncidentSearchIndexSize(host: string): Promise<EmergencyIncidentSearchIndexSize>;
    financeContractSearchIndexSize(host: string): Promise<FinanceContractSearchIndexSize>;
    inventorySearchIndexSize(host: string): Promise<InventorySearchIndexSize>;
    opportunitySearchIndexSize(host: string): Promise<OpportunitySearchIndexSize>;
    rebuildCaseSearchIndex(host: string): Promise<void>;
    rebuildContactSearchIndex(host: string): Promise<void>;
    rebuildEmergencyIncidentSearchIndex(host: string): Promise<void>;
    rebuildFinanceContractSearchIndex(host: string): Promise<void>;
    rebuildInventorySearchIndex(host: string): Promise<void>;
    rebuildOpportunitySearchIndex(host: string): Promise<void>;
}

export const partitionIndexApi: PartitionIndexApi = {
    async caseSearchIndexSize(host: string) {
        return (
            await axios.get("/api/case-search/_size", {
                baseURL: `https://${host}`,
                withCredentials: true,
            })
        ).data;
    },

    async contactSearchIndexSize(host: string) {
        return (
            await axios.get("/api/contact-search/_size", {
                baseURL: `https://${host}`,
                withCredentials: true,
            })
        ).data;
    },

    async emergencyIncidentSearchIndexSize(host: string) {
        return (
            await axios.get("/api/emergency-incident-search/_size", {
                baseURL: `https://${host}`,
                withCredentials: true,
            })
        ).data;
    },

    async financeContractSearchIndexSize(host: string) {
        return (
            await axios.get("/api/finance-contract-search/_size", {
                baseURL: `https://${host}`,
                withCredentials: true,
            })
        ).data;
    },

    async inventorySearchIndexSize(host: string) {
        return (
            await axios.get("/api/inventory-search/_size", {
                baseURL: `https://${host}`,
                withCredentials: true,
            })
        ).data;
    },

    async opportunitySearchIndexSize(host: string) {
        return (
            await axios.get("/api/opportunity-search/_size", {
                baseURL: `https://${host}`,
                withCredentials: true,
            })
        ).data;
    },

    async rebuildCaseSearchIndex(host: string) {
        await axios.post("/api/case-search/_rebuild", undefined, {
            baseURL: `https://${host}`,
            withCredentials: true,
        });
    },

    async rebuildContactSearchIndex(host: string) {
        await axios.post("/api/contact-search/_rebuild", undefined, {
            baseURL: `https://${host}`,
            withCredentials: true,
        });
    },

    async rebuildEmergencyIncidentSearchIndex(host: string) {
        await axios.post("/api/emergency-incident-search/_rebuild", undefined, {
            baseURL: `https://${host}`,
            withCredentials: true,
        });
    },

    async rebuildFinanceContractSearchIndex(host: string) {
        await axios.post("/api/finance-contract-search/_rebuild", undefined, {
            baseURL: `https://${host}`,
            withCredentials: true,
        });
    },

    async rebuildInventorySearchIndex(host: string) {
        await axios.post("/api/inventory-search/_rebuild", undefined, {
            baseURL: `https://${host}`,
            withCredentials: true,
        });
    },

    async rebuildOpportunitySearchIndex(host: string) {
        await axios.post("/api/opportunity-search/_rebuild", undefined, {
            baseURL: `https://${host}`,
            withCredentials: true,
        });
    },
};

import { userSession } from "@/store/userSession";

export interface ReportingChartComponent {
    getChartAsPngDataUrl(): string | null;
    getChartAsSvgDataUrl(): string | null;
}

export enum ReportingChartColorPalette {
    GENERIC = "GENERIC",
    NEGATIVE = "NEGATIVE",
    POSITIVE = "POSITIVE",
}

const GENERIC_COLOR_PALETTE = [
    "#2E93fA",
    "#C0CA33",
    "#546E7A",
    "#CE93D8",
    "#4DB6AC",
    "#8D6E63",
    "#FDD835",
    "#64DD17",
    "#00B8D4",
    "#7C4DFF",
    "#424242",
    "#FF9100",
    "#00E676",
    "#00695C",
];
const NEGATIVE_COLOR_PALETTE = [
    "#ff5252",
    "#ff6b6b",
    "#ff8585",
    "#ff9e9e",
    "#ffb8b8",
    "#ffd1d1",
    "#ff5252",
    "#ff3838",
    "#ff1f1f",
    "#ff0505",
    "#eb0000",
    "#d10000",
    "#b80000",
    "#9e0000",
    "#850000",
    "#6b0000",
    "#ff5252",
    "#ff5252",
    "#fb5656",
    "#f65b5b",
    "#f25f5f",
    "#ee6363",
    "#e96868",
    "#e56c6c",
    "#e17070",
    "#dc7575",
    "#d87979",
    "#d47d7d",
    "#cf8282",
    "#cb8686",
    "#c78a8a",
    "#c28f8f",
    "#be9393",
    "#ba9797",
    "#b59c9c",
    "#b1a0a0",
];

const POSITIVE_COLOR_PALETTE = [
    "#4caf50",
    "#5cb860",
    "#6ec071",
    "#80c883",
    "#92cf94",
    "#a3d7a5",
    "#b5dfb7",
    "#c7e7c8",
    "#d9eeda",
    "#4caf50",
    "#449d48",
    "#3d8b40",
    "#357a38",
    "#2d682f",
    "#255627",
    "#1e441f",
    "#163317",
    "#0e210f",
    "#070f07",
    "#4caf50",
    "#46b54a",
    "#3fbc44",
    "#39c23f",
    "#33c839",
    "#2dce33",
    "#26d52d",
    "#20db28",
    "#1ae122",
    "#14e71c",
    "#0dee16",
    "#07f411",
    "#01fa0b",
    "#4caf50",
    "#52a956",
    "#59a25c",
    "#5f9c61",
    "#659667",
    "#6b906d",
    "#728973",
    "#788378",
];

export interface ColorableReportingChartSeries {
    readonly name: string;
    readonly color?: string;
    readonly colorPalette?: ReportingChartColorPalette;
}

export function getColoredChartSeries<T extends ColorableReportingChartSeries>(series: T[]): T[] {
    let positiveColorCounter = 0;
    let negativeColorCounter = 0;

    return series
        .map((i, index) => ({
            ...i,
            coloredChartOldIndex: index,
        }))
        .sort((a, b) => (a.name as string).localeCompare(b.name as string, userSession.locale))
        .map((i, index) => ({
            ...i,
            coloredChartStableGenericColor: GENERIC_COLOR_PALETTE[index % GENERIC_COLOR_PALETTE.length],
        }))
        .sort((a, b) => a.coloredChartOldIndex - b.coloredChartOldIndex)
        .map((i) => ({
            ...i,
            color: i.color
                ? i.color
                : i.colorPalette === ReportingChartColorPalette.POSITIVE
                ? POSITIVE_COLOR_PALETTE[positiveColorCounter++ % POSITIVE_COLOR_PALETTE.length]
                : i.colorPalette === ReportingChartColorPalette.NEGATIVE
                ? NEGATIVE_COLOR_PALETTE[negativeColorCounter++ % NEGATIVE_COLOR_PALETTE.length]
                : i.coloredChartStableGenericColor,
            coloredChartStableGenericColor: undefined,
            coloredChartOldIndex: undefined,
        }));
}

export function getSeriesValuesMatrixMaxValue(seriesValuesMatrix: (number | undefined)[][], stacked: boolean) {
    if (!seriesValuesMatrix.length) {
        return undefined;
    }

    const columnReduceFn = !stacked
        ? (values: number[]) => Math.max(...values)
        : (values: number[]) => values.reduce((a, b) => a + b, 0);

    const reduced: number[] = seriesValuesMatrix
        .map((column) => column.filter((v) => v !== undefined) as number[])
        .filter((column) => column.length)
        .map(columnReduceFn);

    if (!reduced.length) {
        return undefined;
    }

    return Math.max(...reduced);
}


import { ExternalBdcCdr } from "@/api/externalBdcCdrs";
import { User } from "@/api/users";
import ExternalBdcCdrsBottomSheet from "@/app/pages/externalbdccdr/ExternalBdcCdrsBottomSheet.vue";
import { getReportingTableByReportingBarChart } from "@/app/pages/reporting/chartUtils";
import { hasNonZeroSeries, ReportingBarChartData } from "@/app/pages/reporting/charts/reportingBarChart";
import ReportingDashboardBarTile from "@/app/pages/reporting/dashboard/ReportingDashboardBarTile.vue";
import { mapExternalBdcCdrRowKeyToRowLabel, WithDuration } from "@/app/pages/reporting/externalbdc/externalBdcRowUtils";
import { groupRowsBy } from "@/app/pages/reporting/pivotUtils";
import { rate } from "@/app/pages/reporting/reportingUtils";
import { TitledRowGroup } from "@/app/pages/reporting/rowUtils";
import { ReportingTableData } from "@/app/pages/reporting/table/reportingTable";
import Vue from "vue";

type ComputedExternalBdcCdrRow = PrecomputedExternalBdcCdrRow;

type PrecomputedExternalBdcCdrRow = ExternalBdcCdr & WithDuration;

export default Vue.extend({
    props: {
        chartHeight: {
            type: Number,
            required: false,
        },
        durationThreshold: {
            type: Number,
            required: true,
        },
        externalBdcCdrRows: {
            type: Array as () => readonly PrecomputedExternalBdcCdrRow[],
            required: true,
        },
        loading: {
            type: Boolean,
            required: true,
        },
        subtitle: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            required: false,
        },
        users: {
            type: Array as () => readonly User[],
            required: true,
        },
    },

    data() {
        return {
            bottomSheetExternalBdcCdrIds: [] as number[],
            bottomSheetTitle: null as string | null,
            bottomSheetVisible: false,
        };
    },

    computed: {
        chart(): ReportingBarChartData | null {
            const topCategories = this.rowGroups
                .map((rowGroup) => ({
                    categoryId: rowGroup.key,
                    name: rowGroup.title,
                    description: rowGroup.subtitle,
                    rows: rowGroup.rows,
                    shortDurationRows: rowGroup.rows.filter(
                        (r) => r.duration !== null && r.duration <= this.durationThreshold
                    ),
                    longDurationRows: rowGroup.rows.filter(
                        (r) => r.duration !== null && this.durationThreshold < r.duration
                    ),
                }))
                .map((c) => ({
                    ...c,
                    shortDurationRate: rate(c.shortDurationRows.length, c.rows.length),
                    sortValue: c.shortDurationRows.length,
                }))
                .sort((a, b) => b.sortValue - a.sortValue)
                .filter((c) => c.shortDurationRate && c.shortDurationRate < 1);

            const chart: ReportingBarChartData = {
                title: "",
                categories: topCategories.map((c) => ({ name: c.name, description: c.description })),
                series: [
                    {
                        id: "short-duration-call-count",
                        name: this.$t("Kurze Anrufe") as string,
                        data: {
                            values: topCategories.map((c) => ({
                                value: c.shortDurationRows.length,
                                onClick: this.showBottomSheetOnClick(
                                    c.shortDurationRows.map((r) => r.id),
                                    c.name
                                ),
                            })),
                        },
                        group: this.$t("Anzahl") as string,
                    },
                    {
                        id: "call-count",
                        name: this.$t("Lange Anrufe") as string,
                        data: {
                            values: topCategories.map((c) => ({
                                value: c.longDurationRows.length - c.shortDurationRows.length,
                                onClick: this.showBottomSheetOnClick(
                                    c.longDurationRows.map((r) => r.id),
                                    c.name
                                ),
                            })),
                        },
                        selected: false,
                        group: this.$t("Anzahl") as string,
                    },
                    {
                        id: "short-duration-rate",
                        name: this.$t("Anteil der kurzen Anrufe") as string,
                        data: {
                            values: topCategories.map((c) => ({
                                value: c.shortDurationRate,
                                onClick: this.showBottomSheetOnClick(
                                    c.shortDurationRows.map((r) => r.id),
                                    c.name
                                ),
                            })),
                            isPercentage: true,
                        },
                        group: this.$t("Anteil") as string,
                    },
                ],
            };

            if (!hasNonZeroSeries(chart)) {
                return null;
            }

            return chart;
        },

        rowGroups(): readonly TitledRowGroup<string | null, ComputedExternalBdcCdrRow>[] {
            const filteredExternalBdcCdrRows = this.externalBdcCdrRows.filter((r) => !!r.accept);

            return groupRowsBy(filteredExternalBdcCdrRows, (r) => r.userId).map((rowGroup) => {
                const rowGroupLabel = mapExternalBdcCdrRowKeyToRowLabel(rowGroup.key, "userId", {
                    users: this.users,
                });

                return {
                    ...rowGroup,
                    title: rowGroupLabel.label,
                    subtitle: rowGroupLabel.sublabel,
                };
            });
        },

        table(): ReportingTableData | null {
            if (!this.chart) {
                return null;
            }

            return {
                ...getReportingTableByReportingBarChart(this.chart),
                groupByHeaderText: this.$t("Benutzer") as string,
            };
        },
    },

    methods: {
        hideBottomSheet() {
            this.bottomSheetVisible = false;
            this.bottomSheetTitle = null;
            this.bottomSheetExternalBdcCdrIds = [];
        },

        showBottomSheetOnClick(externalBdcCdrIds: number[], title: string): () => void {
            return () => {
                this.bottomSheetExternalBdcCdrIds = [...new Set(externalBdcCdrIds)];
                this.bottomSheetTitle = title;
                this.bottomSheetVisible = true;
            };
        },
    },

    components: {
        ExternalBdcCdrsBottomSheet,
        ReportingDashboardBarTile,
    },
});


import AgentMonthDataTable from "./AgentMonthDataTable.vue";
import { User, usersApi } from "@/api/users";
import MonthPicker from "@/app/components/MonthPicker.vue";
import { getFullName } from "@/app/userUtils";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { getCurrentMonth } from "@/util/dateTimeUtils";
import { SelectOption } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            loading: true,
            loadFilter: {
                yearMonth: getCurrentMonth(now(), userSession.timeZone),
                userId: userSession.id!,
            },
            users: [] as User[],
        };
    },

    computed: {
        userOptions(): SelectOption[] {
            return this.users
                .map((u) => ({ value: u.id, text: getFullName(u) }))
                .sort((a, b) => a.text.localeCompare(b.text, userSession.locale));
        },
    },

    methods: {
        getUserById(userId: string | null): User | null {
            return this.users.find((u) => u.id === userId) ?? null;
        },

        getUserFullNameById(userId: string | null) {
            const user = this.getUserById(userId);

            if (!user) {
                return this.$t("Unbekannter Benutzer");
            }

            return getFullName(user);
        },

        async refresh() {
            await (this.$refs.agentMonthDataTable as any).refresh();
        },
    },

    async mounted() {
        this.users = await usersApi.list();
    },

    components: {
        AgentMonthDataTable,
        MonthPicker,
    },
});

import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export enum Role {
    AGENT = "AGENT",
    QUALITY_MANAGER = "QUALITY_MANAGER",
    BDC_MANAGER = "BDC_MANAGER",
    BDC_ADMIN = "BDC_ADMIN",
    FEATURE_A_TESTER = "FEATURE_A_TESTER",
    FEATURE_B_TESTER = "FEATURE_B_TESTER",
    ACCOUNTANT = "ACCOUNTANT",
    CONTRACT_MANAGER = "CONTRACT_MANAGER",
    PARTITION_MANAGER = "PARTITION_MANAGER",
    INFRASTRUCTURE_MANAGER = "INFRASTRUCTURE_MANAGER",
    CT_ADMIN = "CT_ADMIN",
    CT_SUPER_ADMIN = "CT_SUPER_ADMIN",
}

export enum Gender {
    MALE = "MALE",
    FEMALE = "FEMALE",
    DIVERSE = "DIVERSE",
}

export interface PhoneNumber {
    readonly number: string;
    readonly label: string | null;
}

export interface User {
    readonly id: string;
    readonly created: Date;
    readonly updated: Date;
    readonly username: string;
    readonly roles: Role[];
    readonly timeZone: string;
    readonly locale: string;
    readonly gender: Gender | null;
    readonly namePrefix: string | null;
    readonly givenName: string;
    readonly familyName: string;
    readonly birthday: string;
    readonly darkmode: boolean;
    readonly profileImageHash: string | null;
    readonly weeklyWorkingHours: number | null;
    readonly forcePasswordChange: boolean;
    readonly employeeNumber: string | null;
    readonly emailAddress: string | null;
    readonly phoneNumbers: PhoneNumber[];
    readonly address1: string | null;
    readonly address2: string | null;
    readonly zip: string | null;
    readonly city: string | null;
    readonly state: string | null;
    readonly country: string | null;
    readonly citnowConversationsUserIds: string[];
}

export interface UserForm {
    readonly username: string;
    readonly roles: Role[];
    readonly timeZone: string;
    readonly locale: string;
    readonly gender: Gender | null;
    readonly namePrefix: string | null;
    readonly givenName: string;
    readonly familyName: string;
    readonly birthday: string | null;
    readonly weeklyWorkingHours: number | null;
    readonly employeeNumber: string | null;
    readonly emailAddress: string | null;
    readonly phoneNumbers: PhoneNumber[];
    readonly address1: string | null;
    readonly address2: string | null;
    readonly zip: string | null;
    readonly city: string | null;
    readonly state: string | null;
    readonly country: string | null;
    readonly citnowConversationsUserIds: string[];
}

interface UsersApi {
    add(
        userForm: UserForm,
        profileImage: File | null,
        progressCallback: (progressEvent: ProgressEvent) => void
    ): Promise<string>;
    delete(id: string): Promise<void>;
    edit(
        id: string,
        userForm: UserForm,
        profileImage: File | null,
        progressCallback: (progressEvent: ProgressEvent) => void
    ): Promise<void>;
    editForcePasswordChange(id: string): Promise<void>;
    editWeeklyWorkingHours(id: string, weeklyWorkingHours: number): Promise<void>;
    getProfileImageByHash(id: string, hash: string): Promise<string>;
    getById(id: string): Promise<User>;
    list(): Promise<User[]>;
    resetPassword(id: string): Promise<string>;
}

export const usersApi: UsersApi = {
    async add(userForm, profileImageFile, onUploadProgress) {
        const formData = new FormData();

        formData.append("userForm", new Blob([JSON.stringify(userForm)], { type: "application/json" }));

        if (profileImageFile) {
            formData.append("profileImageFile", profileImageFile);
        }

        return (await axios.post("/api/users", formData, { onUploadProgress })).data;
    },

    async delete(id) {
        await axios.delete(`/api/users/${id}`);
    },

    async edit(id, userForm, profileImageFile, onUploadProgress) {
        const formData = new FormData();

        formData.append("userForm", new Blob([JSON.stringify(userForm)], { type: "application/json" }));

        if (profileImageFile) {
            formData.append("profileImageFile", profileImageFile);
        }

        await axios.put(`/api/users/${id}`, formData, { onUploadProgress });
    },

    async editForcePasswordChange(id) {
        await axios.post(`/api/users/${id}/_force-password-change`);
    },

    async editWeeklyWorkingHours(id, weeklyWorkingHours) {
        await axios.post(`/api/users/${id}/_edit-weekly-working-hours`, undefined, { params: { weeklyWorkingHours } });
    },

    async getById(id) {
        return cloneObject((await axios.get(`/api/users/${id}`)).data);
    },

    async getProfileImageByHash(id, hash) {
        return (await axios.get(`/api/users/${id}/profile-image/${hash}.b64`, { responseType: "text" })).data;
    },

    async list() {
        return cloneObject((await axios.get("/api/users")).data);
    },

    async resetPassword(id) {
        return (await axios.post(`/api/users/${id}/_reset-password`)).data;
    },
};

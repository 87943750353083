import i18n, { $t } from "./i18n";
import { showError } from "./messageUtil";
import { BadRequest } from "@/api/errors";
import { isValidNumber } from "@/util/phoneNumberUtils";
import { toASCII } from "punycode";
import { TranslateResult } from "vue-i18n";

function asI18nValues(args: any) {
    return typeof args === "object" ? args : [args];
}

export class ValidationHelper {
    private errors = new Map<string, string>();
    private rejectedValues = new Map<string, any>();
    private values = new Map<string, string | number | null | undefined>();

    update(badRequest: BadRequest, form: any) {
        let messageKey: string | undefined;
        let rejectedValue: any;
        for (const details of badRequest.details) {
            if (details.path) {
                this.errors.set(details.path, details.messageKey);
                this.rejectedValues.set(details.path, details.rejectedValue);
            } else if (!messageKey) {
                messageKey = details.messageKey;
                rejectedValue = details.rejectedValue;
            }
        }
        if (messageKey) {
            showError(i18n.t(messageKey, asI18nValues(rejectedValue)) as string);
        }
        form.validate();
    }

    validate(key: string, value: string | number | null | undefined) {
        const error = this.errors.get(key);
        if (error) {
            const currentValue = this.values.get(key);
            if (currentValue === undefined) {
                this.values.set(key, value);
            } else if (value !== currentValue) {
                this.errors.delete(key);
                this.rejectedValues.delete(key);
                this.values.delete(key);
                return true;
            }
            return i18n.t(error, asI18nValues(this.rejectedValues.get(key)));
        }
        return true;
    }
}

type TranslateResultSupplier = () => TranslateResult;
type ValidationRule = (v: string | number | null | undefined) => true | TranslateResult;
export type CustomValidationRule = (v: string | number | null | undefined) => boolean | TranslateResult;

interface MaxLength {
    maxLength(n: number): Custom & Msg & And;
}

interface E164 {
    e164(): Custom & Msg & And;
}

interface Email {
    email(): Custom & Msg & And;
}

export interface DecimalArgs {
    min?: number;
    max?: number;
    totalDigits: number;
    precision: number;
}

interface Decimal {
    decimal(args: DecimalArgs): Custom & Msg & And;
}

interface Integer {
    integer(min?: number, max?: number): Custom & Msg & And;
}

interface Custom {
    custom(rule: CustomValidationRule): Custom & Msg & And;
}

interface Msg {
    msg(m: () => TranslateResult): And;
}

interface And {
    and(validationHelper: ValidationHelper, key: string): {};
}

const EMPTY_VALUE = () => $t("Dieses Feld ist erforderlich.");
const INVALID_VALUE = () => $t("Bitte geben Sie einen gültigen Wert ein.");
const INVALID_EMAIL = () => $t("Gültige E-Mail-Adresse ist erforderlich");
const EMAIL_REGEX = /^(?=.{5,254}$)(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])$/i;

function isDecimalOrEmpty(
    v: string | number | null | undefined,
    { min, max, totalDigits, precision }: DecimalArgs
): boolean {
    v = (v ?? "").toString();

    if (!v) {
        return true;
    }

    if (!v.match(/^-?\d+(\.\d+)?$/)) {
        return false;
    }

    const n = Number(v);

    if ((min !== undefined && n < min) || (max !== undefined && max < n)) {
        return false;
    }

    if (v[0] === "-") {
        v = v.slice(1);
    }

    const [a, b] = (v + ".").split(".", 3);

    return a.length <= totalDigits - precision && b.length <= precision;
}

export function isIntegerOrEmpty(
    v: string | number | null | undefined,
    min: number | undefined,
    max: number | undefined
): boolean {
    return isDecimalOrEmpty(v, {
        min,
        max,
        totalDigits: 10,
        precision: 0,
    });
}

class ValidationRuleBuilder extends Array<ValidationRule> {
    private message?: TranslateResultSupplier;

    constructor() {
        super();
        // needed for ES5 (https://github.com/Microsoft/TypeScript/wiki/FAQ#why-doesnt-extending-built-ins-like-error-array-and-map-work)
        Object.setPrototypeOf(this, ValidationRuleBuilder.prototype);
    }

    notEmpty(): MaxLength & E164 & Email & Decimal & Integer & Custom & Msg & And {
        this.push((v) => !!(v ?? "").toString().trim() || (this.message || EMPTY_VALUE)());
        return this;
    }

    maxLength(n: number): Custom & Msg & And {
        this.push((v) => (v ?? "").toString().trim().length <= n || (this.message || INVALID_VALUE)());
        return this;
    }

    regEx(re: RegExp): Custom & Msg & And {
        this.push((v) => !!(v ?? "").toString().match(re) || (this.message || INVALID_VALUE)());
        return this;
    }

    e164(): Custom & Msg & And {
        this.push((v) => !v || isValidNumber(v.toString()) || (this.message || INVALID_VALUE)());
        return this;
    }

    email(): Custom & Msg & And {
        this.push(
            (v) =>
                !(v ?? "").toString() ||
                !!toASCII(v!.toString()).match(EMAIL_REGEX) ||
                (this.message || INVALID_EMAIL)()
        );
        return this;
    }

    decimal(decimalArgs: DecimalArgs): Custom & Msg & And {
        this.push((v) => isDecimalOrEmpty(v, decimalArgs) || (this.message || INVALID_VALUE)());
        return this;
    }

    integer(min = 0, max = Infinity): Custom & Msg & And {
        this.push(
            (v) =>
                isIntegerOrEmpty(v, Math.max(min, -(2 ** 31)), Math.min(max, 2 ** 31 - 1)) ||
                (this.message || INVALID_VALUE)()
        );
        return this;
    }

    custom(rule: CustomValidationRule): Custom & Msg & And {
        this.push((v) => rule(v) || (this.message || INVALID_VALUE)());
        return this;
    }

    msg(m: TranslateResultSupplier): And {
        this.message = m;
        return this;
    }

    and(validationHelper: ValidationHelper, key: string): {} {
        this.push((v) => validationHelper.validate(key, v));
        return this;
    }
}

export const notEmpty = () => new ValidationRuleBuilder().notEmpty();
export const maxLength = (n: number) => new ValidationRuleBuilder().maxLength(n);
export const regEx = (re: RegExp) => new ValidationRuleBuilder().regEx(re);
export const e164 = () => new ValidationRuleBuilder().e164();
export const email = () => new ValidationRuleBuilder().email();
export const decimal = (args: DecimalArgs) => new ValidationRuleBuilder().decimal(args);
export const integer = (min = 0, max = Infinity) => new ValidationRuleBuilder().integer(min, max);
export const custom = (rule: CustomValidationRule) => new ValidationRuleBuilder().custom(rule);

function currencyPrecision(c: string) {
    const s = new Intl.NumberFormat("en-US", { style: "currency", currency: c }).format(0);
    const i = s.indexOf(".");
    return Math.min(4, i === -1 ? 0 : s.length - i - 1);
}

const TOTAL_DIGITS = [15, 16, 15, 15, 16];

export function currency(c: string): DecimalArgs {
    const precision = currencyPrecision(c);
    return { totalDigits: TOTAL_DIGITS[precision], precision };
}

export function formatAmount(n: string, c: string) {
    return Number(n).toFixed(currencyPrecision(c));
}

export function validate(rules: unknown, input: string | number | null): boolean {
    for (const rule of rules as ((v: string | number | null) => true | string)[]) {
        const r = rule(input);
        if (r !== true) {
            return false;
        }
    }
    return true;
}

export const EMAIL_LOCAL_PART_REGEX = /^(?=.{1,64}$)(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")$/i;

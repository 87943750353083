
import { NavigationFolder, NavigationItem, NavigationNode } from "@/app/navigation";
import Vue from "vue";

export default Vue.extend({
    name: "navigation",

    props: {
        items: Array as () => NavigationNode[],
        subgroup: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        isFolderOpen(folder: NavigationFolder): boolean {
            return folder.items.some(
                (i) =>
                    (i instanceof NavigationItem && this.$route.path === `/${i.path}`) ||
                    (i instanceof NavigationFolder && this.isFolderOpen(i))
            );
        },
    },
});


import DealerRevenueDataTableRow from "./DealerRevenueDataTableRow.vue";
import { benefitRevenuesApi, DealerBenefitMonthRevenue } from "@/api/benefitRevenues";
import { Dealer, dealersApi } from "@/api/dealers";
import { InvoiceRevenueData } from "@/api/invoicerevenues";
import { partitionsApi, PartitionSummary } from "@/api/partitions";
import CsvDownloadIcon from "@/app/components/CsvDownloadIcon.vue";
import MonthPicker from "@/app/components/MonthPicker.vue";
import { addInvoiceRevenueData, getOtherRevenue } from "@/app/pages/invoicerevenue/invoiceRevenueUtils";
import { getFileBasename } from "@/app/pages/reporting/downloadUtils";
import { configStore } from "@/store/config";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { groupBy } from "@/util/arrayUtils";
import { addDuration, getCurrentMonth, UnitOfTime } from "@/util/dateTimeUtils";
import { SelectOption } from "@/util/types";
import Vue from "vue";

enum GroupRevenueMode {
    AVG,
    SUM,
}

interface Item {
    readonly dealerId: string | null;
    readonly dealerName: string | null;
    readonly dealerCount: number;
    readonly benefitMonth: string | null;
    readonly benefitMonthCount: number;
    readonly partitionId: string | null;
    readonly partitionName: string | null;
    readonly revenueDataCount: number;
    readonly revenueData: InvoiceRevenueData;
}

interface DealerBenefitMonthRevenueWithNames extends DealerBenefitMonthRevenue {
    readonly dealerName: string | null;
    readonly partitionName: string | null;
    readonly revenueData: InvoiceRevenueData;
}

interface Total {
    readonly dealerCount: number;
    readonly revenueData: InvoiceRevenueData;
}

export default Vue.extend({
    data() {
        const ts = now();
        const timeZone = configStore.configuration.organisationTimeZone;

        return {
            dealers: [] as Dealer[],
            groupByBenefitMonth: true as boolean,
            groupByDealer: true as boolean,
            groupRevenueMode: GroupRevenueMode.SUM as GroupRevenueMode,
            loadFilter: {
                from: getCurrentMonth(addDuration(ts, timeZone, -1, UnitOfTime.MONTH), timeZone),
                to: getCurrentMonth(addDuration(ts, timeZone, -1, UnitOfTime.MONTH), timeZone),
            },
            loading: true,
            localFilter: {
                dealerIds: [] as string[],
                partitionIds: [] as string[],
            },
            now: ts,
            partitions: [] as PartitionSummary[],
            revenues: [] as DealerBenefitMonthRevenueWithNames[],
        };
    },

    computed: {
        csvData(): string[][] {
            return [
                [
                    this.$t("Partition") as string,
                    this.groupByDealer ? (this.$t("Standort") as string) : (this.$t("Standorte") as string),
                    this.groupByBenefitMonth ? (this.$t("Monat") as string) : (this.$t("Monate") as string),
                    this.$t("Gesamtumsatz") as string,
                    this.$t("Software") as string,
                    this.$t("BDC (wiederkehrend)") as string,
                    this.$t("BDC (variabel)") as string,
                    this.$t("Click-to-Call") as string,
                    this.$t("Call-Tracking") as string,
                    this.$t("SMS") as string,
                    this.$t("WhatsApp") as string,
                    this.$t("Einrichtung") as string,
                    this.$t("Sonstiges") as string,
                ],
                ...this.items.map((i) => [
                    i.partitionId === null
                        ? (this.$t("Unbestimmte Partition") as string)
                        : i.partitionName ?? (this.$t("Unbekannte Partition") as string),
                    !this.groupByDealer
                        ? i.dealerCount.toString()
                        : i.dealerId === null
                        ? (this.$t("Unbestimmter Standort") as string)
                        : i.dealerName ?? (this.$t("Unbekannter Standort") as string),
                    this.groupByBenefitMonth ? i.benefitMonth! : i.benefitMonthCount.toString(),
                    this.formatPriceForCsv(i.revenueData.totalRevenue),
                    this.formatPriceForCsv(i.revenueData.softwareRevenue),
                    this.formatPriceForCsv(i.revenueData.fixedBdcRevenue),
                    this.formatPriceForCsv(i.revenueData.variableBdcRevenue),
                    this.formatPriceForCsv(i.revenueData.clickToCallRevenue),
                    this.formatPriceForCsv(i.revenueData.callTrackingRevenue),
                    this.formatPriceForCsv(i.revenueData.outgoingSmsRevenue),
                    this.formatPriceForCsv(i.revenueData.whatsAppNumberRevenue),
                    this.formatPriceForCsv(i.revenueData.setupRevenue),
                    this.formatPriceForCsv(getOtherRevenue(i.revenueData)),
                ]),
            ];
        },

        csvFilename(): string {
            const prefix = getFileBasename(
                this.$t("Umsatz") as string,
                this.loadFilter.from !== this.loadFilter.to
                    ? (this.$t("Leistungsmonate") as string)
                    : (this.$t("Leistungsmonat") as string),
                this.loadFilter.from,
                this.loadFilter.from !== this.loadFilter.to ? this.loadFilter.to : null,
                this.$t("Nach Partition") as string,
                this.groupByDealer ? (this.$t("Nach Händler") as string) : null,
                this.groupByBenefitMonth ? (this.$t("Nach Monat") as string) : null,
                this.groupRevenueMode === GroupRevenueMode.AVG ? (this.$t("Durchschnitt") as string) : null
            );

            return `${prefix}.csv`;
        },

        currency(): string {
            return configStore.configuration.organisationCurrency;
        },

        dealerOptions(): SelectOption[] {
            return this.dealers
                .map((d) => ({ value: d.id, text: d.name }))
                .sort((a, b) => a.text.localeCompare(b.text, userSession.locale));
        },

        groupRevenueModeOptions(): SelectOption[] {
            return [
                {
                    text: this.$t("Summe"),
                    value: GroupRevenueMode.SUM,
                },
                {
                    text: this.$t("Durchschnitt"),
                    value: GroupRevenueMode.AVG,
                },
            ];
        },

        items(): Item[] {
            let items: Item[] = this.revenues
                .filter(
                    (r) =>
                        !this.localFilter.dealerIds.length ||
                        (!!r.dealerId && this.localFilter.dealerIds.includes(r.dealerId))
                )
                .filter(
                    (r) =>
                        !this.localFilter.partitionIds.length ||
                        (!!r.partitionId && this.localFilter.partitionIds.includes(r.partitionId))
                )
                .map((r) => ({
                    ...r,
                    dealerCount: 1,
                    benefitMonthCount: 1,
                    revenueDataCount: 1,
                }));

            if (!this.groupByDealer) {
                items = groupBy(items, (i) => `${i.benefitMonth}-${i.partitionId}`).map((group) =>
                    group.items
                        .map((i) => ({
                            ...i,
                            dealerId: null,
                            dealerName: null,
                        }))
                        .reduce((a, b) => ({
                            ...a,
                            dealerCount: a.dealerCount + b.dealerCount,
                            revenueDataCount: a.revenueDataCount + b.revenueDataCount,
                            revenueData: addInvoiceRevenueData(a.revenueData, b.revenueData),
                        }))
                );
            }

            if (!this.groupByBenefitMonth) {
                items = groupBy(items, (i) => `${i.partitionId}-${i.dealerId}`).map((group) =>
                    group.items
                        .map((i) => ({
                            ...i,
                            benefitMonth: null,
                        }))
                        .reduce((a, b) => ({
                            ...a,
                            dealerCount: Math.max(a.dealerCount, b.dealerCount),
                            benefitMonthCount: a.benefitMonthCount + b.benefitMonthCount,
                            revenueDataCount: a.revenueDataCount + b.revenueDataCount,
                            revenueData: addInvoiceRevenueData(a.revenueData, b.revenueData),
                        }))
                );
            }

            if (this.groupRevenueMode === GroupRevenueMode.AVG) {
                items = items.map((i) => ({
                    ...i,
                    revenueData: {
                        totalRevenue: i.revenueData.totalRevenue / i.revenueDataCount,
                        softwareRevenue: i.revenueData.softwareRevenue / i.revenueDataCount,
                        fixedBdcRevenue: i.revenueData.fixedBdcRevenue / i.revenueDataCount,
                        variableBdcRevenue: i.revenueData.variableBdcRevenue / i.revenueDataCount,
                        clickToCallRevenue: i.revenueData.clickToCallRevenue / i.revenueDataCount,
                        callTrackingRevenue: i.revenueData.callTrackingRevenue / i.revenueDataCount,
                        outgoingSmsRevenue: i.revenueData.outgoingSmsRevenue / i.revenueDataCount,
                        whatsAppNumberRevenue: i.revenueData.whatsAppNumberRevenue / i.revenueDataCount,
                        setupRevenue: i.revenueData.setupRevenue / i.revenueDataCount,
                    },
                }));
            }

            return items;
        },

        partitionOptions(): SelectOption[] {
            return this.partitions
                .map((p) => ({ value: p.id, text: p.name }))
                .sort((a, b) => a.text.localeCompare(b.text, userSession.locale));
        },

        total(): Total | null {
            if (!this.items.length) {
                return null;
            }

            return this.items
                .map((item) => item as Total)
                .reduce((a, b) => ({
                    dealerCount: a.dealerCount + b.dealerCount,
                    revenueData: addInvoiceRevenueData(a.revenueData, b.revenueData),
                }));
        },
    },

    methods: {
        formatPriceForCsv(value: number): string {
            return this.$n(value, {
                minimumFractionDigits: "2",
                maximumFractionDigits: "4",
                useGrouping: false as any, // interface requires string, but the implementation a number
            });
        },

        async loadItems() {
            this.revenues = [];
            this.loading = true;
            try {
                this.revenues = (
                    await benefitRevenuesApi.getDealerBenefitMonthRevenues(this.loadFilter.from, this.loadFilter.to)
                )
                    .map((r) => ({
                        ...r,
                        dealerName: this.dealers.find((d) => d.id === r.dealerId)?.name ?? null,
                        partitionName: this.partitions.find((p) => p.id === r.partitionId)?.name ?? null,
                        revenueData: addInvoiceRevenueData(r.invoiceRevenueData, r.legacyRevenueData),
                    }))
                    .sort(
                        (a, b) =>
                            (a.partitionName ?? "").localeCompare(b.partitionName ?? "", userSession.locale) ||
                            (a.dealerName ?? "").localeCompare(b.dealerName ?? "", userSession.locale) ||
                            -1 * a.benefitMonth.localeCompare(b.benefitMonth, userSession.locale)
                    );
            } finally {
                this.loading = false;
            }
        },

        async refresh() {
            await this.loadItems();
        },
    },

    watch: {
        loadFilter: {
            deep: true,
            async handler() {
                try {
                    await this.loadItems();
                } catch (e) {
                    this.$nextTick(() => {
                        throw e;
                    });
                }
            },
        },
    },

    async mounted() {
        this.dealers = await dealersApi.list();
        this.partitions = await partitionsApi.list();

        await this.loadItems();
    },

    components: {
        CsvDownloadIcon,
        DealerRevenueDataTableRow,
        MonthPicker,
    },
});


import { storageApi, StorageStats } from "@/api/storage";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            working: false,
            storageStats: null as StorageStats | null,
        };
    },

    methods: {
        async cleanup() {
            this.working = true;
            try {
                await storageApi.removeUnusedData();
                this.storageStats = await storageApi.getStorageStats();
            } finally {
                this.working = false;
            }
        },
    },

    async beforeMount() {
        this.storageStats = await storageApi.getStorageStats();
    },
});


import { BadRequest, Forbidden } from "@/api/errors";
import { LoginForm, PasswordForm } from "@/api/userSession";
import { notEmpty, regEx, ValidationHelper } from "@/app/validation";
import { configStore } from "@/store/config";
import { userSession } from "@/store/userSession";
import { Mutable } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    data() {
        const validationHelper = new ValidationHelper();

        return {
            error: false,
            errorMessage: "",
            forcePasswordChange: false,
            loading: false,
            loginForm: {
                username: "",
                password: "",
            } as Mutable<LoginForm>,
            newPassword: "",
            newPasswordConfirm: "",
            oldPasswordRules: notEmpty()
                .msg(() => this.$t("Passwort ist erforderlich"))
                .and(validationHelper, "oldPassword"),
            newPasswordRules: regEx(/^(?=.{8,})(?!.*\s)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$/)
                .msg(() =>
                    this.$t(
                        "Das Passwort muss mindestens 8 Zeichen lang sein sowie kein Leerzeichen, einen Kleinbuchstaben, einen Großbuchstaben und eine Ziffer enthalten."
                    )
                )
                .and(validationHelper, "newPassword"),
            usernameRules: notEmpty().msg(() => this.$t("Benutzername ist erforderlich")),
            validationHelper,
        };
    },

    computed: {
        config() {
            return configStore.configuration;
        },

        newPasswordConfirmRules() {
            return [
                (v: string) => v === this.newPassword || this.$t("Die eingegebenen Passwörter stimmen nicht überein."),
            ];
        },

        passwordForm(): PasswordForm | null {
            if (!this.forcePasswordChange) {
                return null;
            }

            return {
                oldPassword: this.loginForm.password,
                newPassword: this.newPassword,
            };
        },
    },

    methods: {
        async login() {
            this.error = false;

            if (!(this.$refs.form as any).validate()) {
                return;
            }

            this.loading = true;
            try {
                const loggedIn = await userSession.login(this.loginForm, this.passwordForm ?? undefined);

                if (!loggedIn) {
                    this.errorMessage = this.$t("E-Mail-Adresse oder Passwort sind nicht korrekt.") as string;
                    this.error = true;
                    return;
                }

                await this.$router.replace((this.$route.query.d as string) || "/");
            } catch (e) {
                if (e instanceof Forbidden) {
                    this.errorMessage = this.$t("Sie müssen ihr Passwort ändern.") as string;
                    this.error = true;
                    this.forcePasswordChange = true;
                } else if (e instanceof BadRequest) {
                    this.errorMessage = this.$t(e.details[0].messageKey) as string;
                    this.error = true;
                } else {
                    throw e;
                }
            } finally {
                this.loading = false;
            }
        },
    },

    beforeRouteEnter(to, _, next) {
        if (userSession.permissions) {
            next((to.query.d as string) || "/");
        } else {
            next();
        }
    },
});

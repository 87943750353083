import { InvoiceRevenueData } from "@/api/invoicerevenues";

export const ZERO_INVOICE_REVENUE_DATA: InvoiceRevenueData = Object.freeze({
    totalRevenue: 0,
    softwareRevenue: 0,
    fixedBdcRevenue: 0,
    variableBdcRevenue: 0,
    clickToCallRevenue: 0,
    callTrackingRevenue: 0,
    outgoingSmsRevenue: 0,
    whatsAppNumberRevenue: 0,
    setupRevenue: 0,
});

export function addInvoiceRevenueData(a: InvoiceRevenueData, b: InvoiceRevenueData): InvoiceRevenueData {
    return {
        totalRevenue: a.totalRevenue + b.totalRevenue,
        softwareRevenue: a.softwareRevenue + b.softwareRevenue,
        fixedBdcRevenue: a.fixedBdcRevenue + b.fixedBdcRevenue,
        variableBdcRevenue: a.variableBdcRevenue + b.variableBdcRevenue,
        clickToCallRevenue: a.clickToCallRevenue + b.clickToCallRevenue,
        callTrackingRevenue: a.callTrackingRevenue + b.callTrackingRevenue,
        outgoingSmsRevenue: a.outgoingSmsRevenue + b.outgoingSmsRevenue,
        whatsAppNumberRevenue: a.whatsAppNumberRevenue + b.whatsAppNumberRevenue,
        setupRevenue: a.setupRevenue + b.setupRevenue,
    };
}

export function getOtherRevenue(invoiceRevenueData: InvoiceRevenueData): number {
    const otherRevenue =
        invoiceRevenueData.totalRevenue -
        (invoiceRevenueData.softwareRevenue +
            invoiceRevenueData.fixedBdcRevenue +
            invoiceRevenueData.variableBdcRevenue +
            invoiceRevenueData.clickToCallRevenue +
            invoiceRevenueData.callTrackingRevenue +
            invoiceRevenueData.outgoingSmsRevenue +
            invoiceRevenueData.whatsAppNumberRevenue +
            invoiceRevenueData.setupRevenue);

    return Math.round(10000 * otherRevenue) / 10000;
}

import axios from "axios";

interface CitnowSalesforceApi {
    sendBillingItemSummaries(from: Date, to: Date): Promise<void>;
}

export const citnowSalesforceApi: CitnowSalesforceApi = {
    async sendBillingItemSummaries(from, to) {
        await axios.post("/api/citnow-salesforce/_send-billing-item-summaries", undefined, {
            params: {
                from,
                to,
            },
        });
    },
};

import { $t } from "./i18n";
import { configStore } from "@/store/config";

export function getStates(countryCode: string | null) {
    return configStore.configuration.countries.find((e) => e.code === countryCode)?.states || [];
}

export function getStateLabel(country: string | null) {
    const countryStates = getStates(country);
    if (countryStates.length) {
        return $t(`enum.StateType.${country}`);
    }

    return $t("Staat") as string;
}

export function getCountryCaption(country: string) {
    return $t(`country.${country}`) as string;
}

export function getStateCaption(country: string, state: string) {
    return $t(`state.${country}-${state}`) as string;
}

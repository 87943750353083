import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export enum IncomingEmailSummarySearchOrder {
    CREATED_DESC = "CREATED_DESC",
    CREATED_ASC = "CREATED_ASC",
    FROM_NAME_DESC = "FROM_NAME_DESC",
    FROM_NAME_ASC = "FROM_NAME_ASC",
    FROM_ADDRESS_DESC = "FROM_ADDRESS_DESC",
    FROM_ADDRESS_ASC = "FROM_ADDRESS_ASC",
    SUBJECT_DESC = "SUBJECT_DESC",
    SUBJECT_ASC = "SUBJECT_ASC",
}
export enum IncomingEmailSummaryCountType {
    FROM_NAME = "FROM_NAME",
    FROM_ADDRESS = "FROM_ADDRESS",
}

export interface IncomingEmailSummary {
    readonly partitionId: string;
    readonly created: Date;
    readonly matchedBlacklistEntryId: string | null;
    readonly spamReportCreated: Date | null;
    readonly spamReportHandled: Date | null;
    readonly data: IncomingEmailSummaryData;
}

export interface IncomingEmailSummaryCount {
    readonly value: string | null;
    readonly count: number;
}

export interface IncomingEmailSummaryData {
    readonly id: string;
    readonly contentDetected: boolean;
    readonly user: boolean;
    readonly fromName: string | null;
    readonly fromAddress: string;
    readonly subject: string | null;
}

export interface IncomingEmailSummarySearchRequest {
    readonly blacklisted: boolean | null;
    readonly contentDetected: boolean | null;
    readonly user: boolean | null;
    readonly fromName: string | null;
    readonly fromAddress: string | null;
    readonly createdFrom: Date | null;
    readonly createdTo: Date | null;
    readonly countType: IncomingEmailSummaryCountType;
    readonly sortBy: IncomingEmailSummarySearchOrder;
}

export interface IncomingEmailSummarySearchResults {
    readonly counts: IncomingEmailSummaryCount[];
    readonly results: IncomingEmailSummary[];
    readonly searchId: number;
    readonly totalSize: number;
}

interface IncomingEmailsApi {
    getAllEmailSummariesWithUnhandledSpamReport(): Promise<IncomingEmailSummary[]>;
    handleSpam(id: string): Promise<void>;
    search(
        start: number,
        size: number,
        searchRequest: IncomingEmailSummarySearchRequest,
        searchId: number
    ): Promise<IncomingEmailSummarySearchResults>;
}

export const incomingEmailsApi: IncomingEmailsApi = {
    async getAllEmailSummariesWithUnhandledSpamReport() {
        return cloneObject((await axios.get("/api/incoming-emails")).data);
    },

    async handleSpam(id) {
        await axios.post(`/api/incoming-emails/${id}/_handle-spam`);
    },

    async search(start, size, searchRequest, searchId) {
        return cloneObject(
            (
                await axios.post("/api/incoming-emails/_search", searchRequest, {
                    params: { start, size, searchId },
                })
            ).data
        );
    },
};

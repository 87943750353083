
import { citnowSalesforceApi } from "@/api/citnowSalesforce";
import DateRangePicker from "@/app/components/DateRangePicker.vue";
import { DateRange } from "@/app/components/dateRangePicker";
import { notEmpty } from "@/app/validation";
import { now } from "@/store/now";
import { endOf, getDate, startOf, toDateObject, UnitOfTime } from "@/util/dateTimeUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        locale: {
            type: String,
            required: true,
        },
        timeZone: {
            type: String,
            required: true,
        },
    },

    data() {
        const ts = now();

        return {
            dateRange: {
                from: getDate(ts, this.timeZone),
                to: getDate(ts, this.timeZone),
            } as DateRange,
            isWorking: false,
            notEmpty,
        };
    },

    computed: {
        endOfTo(): Date {
            const date = toDateObject(this.timeZone, this.dateRange.to);

            return endOf(date, this.timeZone, this.locale, UnitOfTime.DAY);
        },

        startOfFrom(): Date {
            const date = toDateObject(this.timeZone, this.dateRange.from);

            return startOf(date, this.timeZone, this.locale, UnitOfTime.DAY);
        },
    },

    methods: {
        close() {
            this.$emit("close");
        },

        async submitForm() {
            if (!(this.$refs.form as any).validate()) {
                return;
            }

            this.isWorking = true;

            try {
                await citnowSalesforceApi.sendBillingItemSummaries(this.startOfFrom, this.endOfTo);
                this.$emit("success");
            } finally {
                this.isWorking = false;
            }
        },
    },

    components: {
        DateRangePicker,
    },
});

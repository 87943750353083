var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-dialog',{attrs:{"value":true,"max-width":"1200","persistent":_vm.isWorking},on:{"input":_vm.close}},[_c('v-form',{ref:"form",attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',{attrs:{"disabled":_vm.isWorking}},[_c('v-toolbar',{attrs:{"color":_vm.$vuetify.theme.dark ? undefined : 'primary',"dark":"","flat":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t("Rechnungserstellung"))+" ")])],1),_c('v-card-text',[_c('date-range-picker',{attrs:{"label":_vm.$t('Leistungszeitraum'),"max-date":_vm.periodEndMaxDate,"rules":_vm.notEmpty(),"time-zone":_vm.timeZone,"value":_vm.period},on:{"input":_vm.setPeriod}}),_c('v-autocomplete',{attrs:{"autocomplete":"no","items":[
                        { value: false, text: _vm.$t('Nein') },
                        { value: true, text: _vm.$t('Ja') },
                    ],"label":_vm.$t('E-Mail an Rechnungsempfänger schicken'),"id":_vm.$id()},model:{value:(_vm.sendEmail),callback:function ($$v) {_vm.sendEmail=$$v},expression:"sendEmail"}}),_c('v-card',{attrs:{"outlined":""}},[_c('v-toolbar',{attrs:{"color":_vm.$vuetify.theme.dark ? undefined : 'primary',"dark":"","flat":"","dense":""}},[_c('v-toolbar-title',{staticClass:"subtitle-1"},[_vm._v(" "+_vm._s(_vm.$t("Rechnungspositionen"))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"x-small":"","color":"secondary"},on:{"click":function($event){return _vm.addLineItem()}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"x-small":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t("Rechnungsposition hinzufügen"))+" ")],1)],1),_c('v-card-text',{staticClass:"pb-0"},[(!_vm.customInvoiceForm.customInvoiceLineItemForms.length)?_c('v-alert',{staticClass:"body-2 mb-4",attrs:{"border":"left","colored-border":"","dense":"","elevation":"0","text":"","type":"error"}},[_vm._v(" "+_vm._s(_vm.$t("Es sind keine Rechnungspositionen vorhanden."))+" ")]):_vm._e(),(_vm.customInvoiceForm.customInvoiceLineItemForms.length)?_c('div',_vm._l((_vm.customInvoiceForm.customInvoiceLineItemForms),function(lineItemForm,index){return _c('v-card',{key:index,staticClass:"mb-4",attrs:{"outlined":""}},[_c('v-card-text',{staticClass:"pt-0 pb-6"},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"mt-3 mb-n2 mr-n2 float-right",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.removeLineItem(index)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","sm":"6","md":"7"}},[_c('v-autocomplete',{attrs:{"autocomplete":"no","hide-details":"auto","hint":_vm.isDealerOfOtherBillingAccount(lineItemForm.dealerId)
                                                        ? _vm.$t(
                                                              'Der Händler gehört zu einem anderen Abrechnungskonto.'
                                                          )
                                                        : null,"id":_vm.$id(),"items":_vm.dealerOptions,"label":_vm.$t('Standort'),"persistent-hint":"","rules":_vm.notEmpty(),"value":lineItemForm.dealerId},on:{"input":(dealerId) => _vm.setDealerId(lineItemForm, dealerId)}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","sm":"6","md":"5"}},[_c('v-autocomplete',{attrs:{"autocomplete":"no","clearable":"","hide-details":"auto","id":_vm.$id(),"items":_vm.serviceOptions,"label":_vm.$t('Leistungsart'),"value":_vm.getService(lineItemForm)},on:{"input":(v) => _vm.setService(lineItemForm, v)}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","sm":"12","md":"7"}},[_c('v-text-field',{attrs:{"autocomplete":"no","disabled":(!_vm.customInvoiceForm.periodBegin || !lineItemForm.dealerId) &&
                                                        !lineItemForm.description,"hide-details":"auto","persistent-placeholder":"","placeholder":_vm.generateDescription(lineItemForm),"id":_vm.$id(),"label":_vm.$t('Beschreibung'),"rules":_vm.notEmpty(),"append-icon":!lineItemForm.description && !!_vm.generateDescription(lineItemForm)
                                                        ? 'mdi-check'
                                                        : ''},on:{"click:append":function($event){lineItemForm.description = _vm.generateDescription(lineItemForm)}},model:{value:(lineItemForm.description),callback:function ($$v) {_vm.$set(lineItemForm, "description", $$v)},expression:"lineItemForm.description"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","sm":"4","md":"1"}},[_c('number-field',{attrs:{"hide-details":"auto","label":_vm.$t('Einheiten'),"rules":_vm.notEmpty().integer()},model:{value:(lineItemForm.quantity),callback:function ($$v) {_vm.$set(lineItemForm, "quantity", $$v)},expression:"lineItemForm.quantity"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","sm":"4","md":"2"}},[_c('number-field',{attrs:{"hide-details":"auto","label":_vm.$t('Preis pro Einheit'),"rules":_vm.notEmpty()
                                                        .decimal(_vm.currency)
                                                        .custom(
                                                            (v) =>
                                                                lineItemForm.compensationType !==
                                                                    _vm.InvoiceLineItemCompensationType.INCLUDED_UNIT ||
                                                                _vm.isIntegerOrEmpty(v, 0, 0)
                                                        ),"suffix":_vm.organisationCurrency},model:{value:(lineItemForm.pricePerUnit),callback:function ($$v) {_vm.$set(lineItemForm, "pricePerUnit", $$v)},expression:"lineItemForm.pricePerUnit"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","sm":"4","md":"2"}},[_c('number-field',{attrs:{"disabled":"","hide-details":"auto","label":_vm.$t('Gesamtpreis'),"readonly":"","suffix":_vm.organisationCurrency,"value":_vm.getCustomInvoiceLineItemFormPrice(lineItemForm)}})],1)],1)],1)],1)}),1):_vm._e(),(_vm.customInvoiceForm.customInvoiceLineItemForms.length)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","offset-sm":"8","sm":"4","offset-md":"10","md":"2"}},[_c('div',{staticClass:"ml-sm-n3 pr-sm-3"},[_c('v-divider',{staticClass:"mb-2"}),_c('number-field',{attrs:{"disabled":"","label":_vm.$t('Summe (netto)'),"readonly":"","suffix":_vm.organisationCurrency,"value":_vm.netAmount}})],1)])],1):_vm._e()],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$t("Abbrechen")))]),_c('v-btn',{attrs:{"type":"submit","loading":_vm.isWorking,"text":""}},[_vm._v(_vm._s(_vm.$t("Erstellen")))])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }

import { mysqlDatabasesApi, MysqlDatabaseResult, MysqlDatabaseSummary } from "@/api/mysqlDatabases";
import { showConfirm, showInfo } from "@/app/messageUtil";
import { MAX_PARTITIONS_PER_DATABASE } from "@/app/resourceLimits";
import { userSession } from "@/store/userSession";
import Vue from "vue";

export default Vue.extend({
    props: {
        mysqlDatabaseResult: {
            type: Object as () => MysqlDatabaseResult,
            required: true,
        },
    },

    data() {
        return {
            MAX_PARTITIONS_PER_DATABASE,
            isFinalizing: false,
            isRenewingRootPassword: false,
            mysqlDatabaseResultProp: this.mysqlDatabaseResult,
        };
    },

    computed: {
        canRenewPassword(): boolean {
            return userSession.username === "root";
        },

        mysqlDatabaseSummary(): MysqlDatabaseSummary {
            return this.mysqlDatabaseResultProp.mysqlDatabaseSummary;
        },

        usageCountColor(): string {
            if (MAX_PARTITIONS_PER_DATABASE <= this.mysqlDatabaseResultProp.usageCount) {
                return "error";
            } else {
                return "success";
            }
        },
    },

    methods: {
        async finalizeDatabase() {
            this.isFinalizing = true;
            try {
                const host = await mysqlDatabasesApi.finalize(this.mysqlDatabaseSummary.id);

                if (!host) {
                    showInfo(
                        this.$t("Die Datenbank ist noch nicht bereit. Bitte versuchen Sie es später nochmal.") as string
                    );
                    return;
                }

                this.mysqlDatabaseResultProp = {
                    ...this.mysqlDatabaseResultProp,
                    mysqlDatabaseSummary: {
                        ...this.mysqlDatabaseResultProp.mysqlDatabaseSummary,
                        host,
                    },
                };
            } finally {
                this.isFinalizing = false;
            }
        },

        async renewRootPassword() {
            if (
                !(await showConfirm(
                    this.$t("Passwort erneuern") as string,
                    this.$t("Sind Sie sicher, dass Sie das Passwort erneuern möchten?") as string
                ))
            ) {
                return;
            }

            this.isRenewingRootPassword = true;
            try {
                await mysqlDatabasesApi.renewRootPassword(this.mysqlDatabaseSummary.id);
            } finally {
                this.isRenewingRootPassword = false;
            }
        },
    },
});

import navigation, { NavigationFolder, NavigationItem, NavigationNode } from "./navigation";
import Login from "./pages/Login.vue";
import MainFrame from "./pages/MainFrame.vue";
import NotFound from "./pages/NotFound.vue";
import ActionDetailPage from "./pages/action/ActionDetailPage.vue";
import CallCenterDashboard from "./pages/callcenterstatistics/CallCenterDashboard.vue";
import DutyRosterResourcePrintView from "./pages/dutyrosters/DutyRosterResourcePrintView.vue";
import DutyRosterView from "./pages/dutyrosters/DutyRosterView.vue";
import PasswordForm from "./pages/users/PasswordForm.vue";
import UserFormPage from "./pages/users/UserFormPage.vue";
import UserProfileForm from "./pages/users/UserProfileForm.vue";
import { Permission } from "@/api/userSession";
import { userSession } from "@/store/userSession";
import Vue from "vue";
import VueRouter, { NavigationGuard, RouteConfig } from "vue-router";

Vue.use(VueRouter);

function checkPermissionsNavigationGuard(permissions: Permission[]) {
    return ((_, __, next) => {
        if ((userSession.permissions || []).some((p) => permissions.includes(p))) {
            next();
        } else {
            next("/404");
        }
    }) as NavigationGuard;
}

function toRouterConfig(nodes: NavigationNode[]): RouteConfig[] {
    return [
        ...nodes
            .filter((n): n is NavigationItem => n instanceof NavigationItem)
            .map((i) => {
                return {
                    path: i.path,
                    component: i.component,
                    meta: { title: i.title },
                    beforeEnter: ((_, __, next) => {
                        if (i.isVisible) {
                            next();
                        } else {
                            next("/404");
                        }
                    }) as NavigationGuard,
                };
            }),
        ...nodes
            .filter((n): n is NavigationFolder => n instanceof NavigationFolder)
            .map((f) => toRouterConfig(f.items))
            .reduce((p, c) => p.concat(c), []),
    ];
}

const $t = (s: string) => s;

export default new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    routes: [
        { path: "/login", component: Login, meta: { title: $t("Login") } },
        {
            path: "/call-center-dashboard",
            component: CallCenterDashboard,
            meta: { title: $t("Call-Center Dashboard") },
        },
        {
            path: "/duty-roster-print",
            component: DutyRosterResourcePrintView,
            meta: { title: $t("Dienstplanübersicht") },
        },
        {
            path: "/",
            component: MainFrame,
            meta: { title: $t("Control Tower") },
            children: [
                ...toRouterConfig(navigation),
                {
                    path: "user/:userid?",
                    component: UserFormPage,
                    beforeEnter: checkPermissionsNavigationGuard([Permission.CT_MANAGE_USERS]),
                    meta: { title: $t("Benutzer") },
                },
                { path: "password-form", component: PasswordForm, meta: { title: $t("Passwort ändern") } },
                { path: "user-profile-form", component: UserProfileForm, meta: { title: $t("Profil bearbeiten") } },
                { path: "duty-roster", component: DutyRosterView, meta: { title: $t("Dienstplan") } },
                { path: "action/:actionid", component: ActionDetailPage },
                { path: "404", component: NotFound },
                { path: "*", component: NotFound },
            ],
            beforeEnter(to, _, next) {
                if (userSession.permissions) {
                    next();
                } else {
                    next(`/login?d=${to.fullPath}`);
                }
            },
        },
    ],
});

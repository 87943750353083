import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export interface MysqlDatabaseResult {
    readonly mysqlDatabaseSummary: MysqlDatabaseSummary;
    readonly usageCount: number;
}

export interface MysqlDatabaseSummary {
    readonly id: string;
    readonly created: Date;
    readonly updated: Date;
    readonly host: string;
}

interface MysqlDatabasesApi {
    add(id: string): Promise<void>;
    finalize(id: string): Promise<string | null>;
    getAll(): Promise<MysqlDatabaseResult[]>;
    renewRootPassword(id: string): Promise<void>;
}

export const mysqlDatabasesApi: MysqlDatabasesApi = {
    async add(id: string) {
        await axios.post(`/api/mysql-databases/${id}`);
    },

    async finalize(id: string) {
        return (await axios.post(`/api/mysql-databases/${id}/_finalize`)).data;
    },

    async getAll() {
        return cloneObject((await axios.get("/api/mysql-databases")).data);
    },

    async renewRootPassword(id: string) {
        await axios.post(`/api/mysql-databases/${id}/_renew-root-password`);
    },
};

import { Dealer } from "@/api/dealers";
import { PartitionSummary } from "@/api/partitions";
import { User } from "@/api/users";
import { $t } from "@/app/i18n";
import { RowKey, RowLabel } from "@/app/pages/reporting/rowUtils";
import {
    getNextOngoingTimeSlot,
    getOngoingTimeSlot,
    getOngoingTimeSlotKey,
    getOngoingTimeSlotLabelByKey,
    OngoingTimeInterval,
} from "@/app/pages/reporting/timeInterval";
import { getFullName } from "@/app/userUtils";
import { userSession } from "@/store/userSession";

/*
 * helper
 */

function toFullSeconds(time: number | null): number | null {
    if (time === null) {
        return null;
    }

    const seconds = time / 1000;

    return seconds < 0 ? Math.floor(seconds) : Math.ceil(seconds);
}

/*
 * with accept second
 */

export interface WithAcceptSecond {
    readonly acceptSecond: number | null;
}

export function withAcceptSecond<T extends { readonly accept: Date | null }>(row: T): T & WithAcceptSecond {
    return {
        ...row,
        acceptSecond: toFullSeconds(row.accept?.getTime() ?? null),
    };
}

/*
 * with begin ongoing time slot
 */

export interface WithBeginOngoingTimeSlot {
    readonly beginOngoingTimeSlot: string;
    readonly startedInTimeSlot: boolean;
}

export function withBeginOngoingTimeSlot<T extends { begin: Date; waited: number; duration: number | null }>(
    rows: readonly T[],
    ongoingTimeInterval: OngoingTimeInterval
): (T & WithBeginOngoingTimeSlot)[] {
    if (!rows.length) {
        return [];
    }

    type RowWithStartedInTimeSlot = T & { startedInTimeSlot: boolean };

    const earliestTime = rows.reduce((prev, cur) => Math.min(prev, cur.begin.getTime()), Infinity);
    const latestTime = rows.reduce((prev, cur) => Math.max(prev, cur.begin.getTime()), -Infinity);

    const done: (T & WithBeginOngoingTimeSlot)[] = [];
    let worklist: RowWithStartedInTimeSlot[] = rows
        .map((row) => ({
            ...row,
            startedInTimeSlot: true,
        }))
        .sort((a, b) => a.begin.getTime() - b.begin.getTime());

    const finalSlotStartDate = getOngoingTimeSlot(new Date(latestTime), ongoingTimeInterval, userSession.timeZone)!;

    for (
        let slotStart = getOngoingTimeSlot(new Date(earliestTime), ongoingTimeInterval, userSession.timeZone)!,
            slotEnd = getNextOngoingTimeSlot(slotStart, ongoingTimeInterval, userSession.timeZone)!;
        slotStart.getTime() <= finalSlotStartDate.getTime() && worklist.length;
        slotStart = slotEnd, slotEnd = getNextOngoingTimeSlot(slotEnd, ongoingTimeInterval, userSession.timeZone)!
    ) {
        const beginOngoingTimeSlot = getOngoingTimeSlotKey(slotStart, ongoingTimeInterval, userSession.timeZone)!;

        const prevWorklist = worklist;
        worklist = [];

        for (let index = 0; index < prevWorklist.length; ++index) {
            const row = prevWorklist[index];

            const startTime = Math.ceil(row.begin.getTime() / 1000) * 1000;
            const waitedUntilTime = startTime + row.waited * 1000;
            const endTime = waitedUntilTime + (row.duration ?? 0) * 1000;

            if (slotEnd.getTime() <= startTime) {
                worklist = index === 0 ? prevWorklist : worklist.concat(prevWorklist.slice(index));
                break;
            } else if (slotEnd.getTime() < waitedUntilTime) {
                const waitedInSlot = (slotEnd.getTime() - startTime) / 1000;

                done.push({
                    ...row,
                    waited: waitedInSlot,
                    duration: row.duration !== null ? 0 : null,
                    beginOngoingTimeSlot,
                });

                worklist.push({
                    ...row,
                    begin: new Date(slotEnd),
                    waited: row.waited - waitedInSlot,
                    startedInTimeSlot: false,
                });
            } else if (slotEnd.getTime() < endTime) {
                const durationInSlot = (slotEnd.getTime() - waitedUntilTime) / 1000;

                done.push({
                    ...row,
                    duration: durationInSlot,
                    beginOngoingTimeSlot,
                });

                worklist.push({
                    ...row,
                    begin: new Date(slotEnd),
                    waited: 0,
                    duration: row.duration! - durationInSlot,
                    startedInTimeSlot: false,
                });
            } else {
                done.push({ ...row, beginOngoingTimeSlot });
            }
        }
    }

    return done;
}

/*
 * with begin second
 */

export interface WithBeginSecond {
    readonly beginSecond: number;
}

export function withBeginSecond<T extends { readonly begin: Date }>(row: T): T & WithBeginSecond {
    return {
        ...row,
        beginSecond: toFullSeconds(row.begin.getTime())!,
    };
}

/*
 * with billed minutes
 */

export interface WithBilledMinutes {
    readonly billedMinutes: number | null;
}

export function withBilledMinutes<T extends WithDuration>(row: T): T & WithBilledMinutes {
    return {
        ...row,
        billedMinutes: row.duration !== null && row.duration <= 3600 ? Math.max(Math.ceil(row.duration / 60), 1) : null,
    };
}

/*
 * with duration
 */

export interface WithDuration {
    readonly duration: number | null;
}

export function withDuration<T extends WithAcceptSecond & WithEndSecond>(row: T): T & WithDuration {
    return {
        ...row,
        duration: row.acceptSecond ? row.endSecond - row.acceptSecond : null,
    };
}

/*
 * with end second
 */

export interface WithEndSecond {
    readonly endSecond: number;
}

export function withEndSecond<T extends { readonly end: Date }>(row: T): T & WithEndSecond {
    return {
        ...row,
        endSecond: toFullSeconds(row.end.getTime())!,
    };
}

/*
 * with partition id
 */

export interface WithPartitionId {
    readonly partitionId: string | null;
}

export function withPartitionId<T extends { dealerId: string | null }>(
    rows: readonly T[],
    dealers: readonly Dealer[]
): (T & WithPartitionId)[] {
    const dealerById = dealers.reduce((map, dealer) => {
        map.set(dealer.id, dealer);

        return map;
    }, new Map<string, Dealer>());

    return rows.map((r) => ({
        ...r,
        partitionId: r.dealerId !== null ? dealerById.get(r.dealerId)?.partitionId || null : null,
    }));
}

/*
 * with waited
 */

export interface WithWaited {
    readonly waited: number;
}

export function withWaited<
    T extends {
        readonly begin: Date;
        readonly distributionBeginOffset: number;
        readonly accept: Date | null;
        readonly end: Date;
    }
>(row: T): T & WithWaited {
    return {
        ...row,
        waited: Math.max(
            0,
            toFullSeconds((row.accept ?? row.end).getTime())! -
                toFullSeconds(row.begin.getTime())! -
                row.distributionBeginOffset
        ),
    };
}

/*
 * misc
 */

export interface MapExternalBdcCdrRowKeyToLabelContext {
    dealers?: readonly Dealer[];
    ongoingTimeInterval?: OngoingTimeInterval;
    partitions?: readonly PartitionSummary[];
    users?: readonly User[];
}

export function mapExternalBdcCdrRowKeyToRowLabel(
    key: RowKey,
    groupBy: string,
    context?: MapExternalBdcCdrRowKeyToLabelContext
): RowLabel {
    const { dealers, ongoingTimeInterval, partitions, users } = context ?? {};

    if (groupBy === "beginOngoingTimeSlot" && typeof key === "string" && ongoingTimeInterval) {
        return {
            label:
                getOngoingTimeSlotLabelByKey(key, ongoingTimeInterval, userSession.timeZone, "L") ??
                ($t("Unbekannt") as string),
        };
    } else if (groupBy === "dealerId") {
        if (key === null) {
            return { label: $t("Ohne Standort") as string };
        } else if (typeof key === "string") {
            return { label: dealers?.find((d) => d.id === key)?.name || ($t("Unbekannter Standort") as string) };
        }
    } else if (groupBy === "partitionId") {
        if (key === null) {
            return { label: $t("Ohne Partition") as string };
        } else if (typeof key === "string") {
            return { label: partitions?.find((d) => d.id === key)?.name || ($t("Unbekannte Partition") as string) };
        }
    } else if (groupBy === "userId") {
        if (key === null) {
            return { label: $t("Ohne Benutzer") as string };
        } else if (typeof key === "string") {
            const user = users?.find((d) => d.id === key);

            if (!user) {
                return { label: $t("Gelöschter Benutzer") as string };
            }

            return { label: getFullName(user) };
        }
    }

    return { label: $t("Unbekannt") as string };
}

import ActionOverview from "./pages/action/ActionOverview.vue";
import AgentActionStatisticsOverview from "./pages/agentstatistics/AgentActionStatisticsOverview.vue";
import AgentDayOverview from "./pages/agentstatistics/AgentDayOverview.vue";
import AgentMonthOverview from "./pages/agentstatistics/AgentMonthOverview.vue";
import MyAgentDayOverview from "./pages/agentstatistics/MyAgentDayOverview.vue";
import MyAgentMonthOverview from "./pages/agentstatistics/MyAgentMonthOverview.vue";
import BdcTeamOverview from "./pages/bdcteam/BdcTeamOverview.vue";
import DealerRevenueOverview from "./pages/benefitrevenue/DealerRevenueOverview.vue";
import MonthlyRevenueOverview from "./pages/benefitrevenue/MonthlyRevenueOverview.vue";
import BillingAccountsOverview from "./pages/billingaccounts/BillingAccountsOverview.vue";
import BwaMonthDealerRevenueOverview from "./pages/bwarevenue/BwaMonthDealerRevenueOverview.vue";
import MonthlyBwaRevenueOverview from "./pages/bwarevenue/MonthlyBwaRevenueOverview.vue";
import CitNowDataExchangeOverview from "./pages/citnowdataexchange/CitNowDataExchangeOverview.vue";
import CitnowSalesforceOverview from "./pages/citnowsalesforce/CitnowSalesforceOverview.vue";
import ContractOverview from "./pages/contract/ContractOverview.vue";
import DealerDirectory from "./pages/dealers/DealerDirectory.vue";
import ExternalBdcTargetNumberOverview from "./pages/dealers/ExternalBdcTargetNumberOverview.vue";
import DealerStatisticsOverview from "./pages/dealerstatistics/DealerStatisticsOverview.vue";
import DutyRosterResourceView from "./pages/dutyrosters/DutyRosterResourceView.vue";
import DutyRosters from "./pages/dutyrosters/DutyRosters.vue";
import ElasticClusters from "./pages/elasticclusters/ElasticClusters.vue";
import EmergencyIncidentSummariesOverview from "./pages/emergencyincidentsummaries/EmergencyIncidentSummariesOverview.vue";
import ExternalBdcCdrOverview from "./pages/externalbdccdr/ExternalBdcCdrOverview.vue";
import AgentAssessmentOverview from "./pages/externalbdccdrassessment/AgentAssessmentOverview.vue";
import ExternalBdcCdrAssessment from "./pages/externalbdccdrassessment/ExternalBdcCdrAssessment.vue";
import FtpAccounts from "./pages/ftpaccounts/FtpAccounts.vue";
import HouseKeepInventory from "./pages/housekeepinventory/HouseKeepInventory.vue";
import HouseKeepStorage from "./pages/housekeepstorage/HouseKeepStorage.vue";
import IncomingEmailBlacklist from "./pages/incomingemails/IncomingEmailBlacklist.vue";
import IncomingEmailSpam from "./pages/incomingemails/IncomingEmailSpam.vue";
import IncomingEmailSummariesOverview from "./pages/incomingemails/IncomingEmailSummariesOverview.vue";
import InvoicesOverview from "./pages/invoices/InvoicesOverview.vue";
import Loadbalancers from "./pages/loadbalancers/Loadbalancers.vue";
import MarketingEmail from "./pages/marketingemails/MarketingEmail.vue";
import MysqlDatabases from "./pages/mysqldatabases/MysqlDatabases.vue";
import Partitions from "./pages/partitions/Partitions.vue";
import RebuildIndexes from "./pages/rebuildindexes/RebuildIndexes.vue";
import {
    ExternalBdcCallVolumeTimeSeries,
    ExternalBdcCdrDashboard,
    ExternalBdcExpectedAgentsRequiredReport,
    ExternalBdcIncomingCallReport,
    ExternalBdcIncomingCallTimeSeries,
} from "./pages/reporting";
import ShiftScheduleTemplates from "./pages/shiftscheduletemplates/ShiftScheduleTemplates.vue";
import PartitionSqlConsole from "./pages/sqlconsole/PartitionSqlConsole.vue";
import UserOverview from "./pages/users/UserOverview.vue";
import WhatsAppAccounts from "./pages/whatsappaccounts/WhatsAppAccounts.vue";
import { Permission } from "@/api/userSession";
import { userSession } from "@/store/userSession";
import Vue, { AsyncComponent, ComponentOptions } from "vue";

type Component = ComponentOptions<Vue> | typeof Vue | AsyncComponent;

interface NavigationBadge {
    readonly value: number;
    readonly color: string;
    readonly tooltip?: string;
}

export abstract class NavigationNode {
    abstract readonly isVisible: boolean;
    abstract readonly badges: NavigationBadge[];

    protected constructor(public title: string, public icon?: string) {}
}

export class NavigationItem extends NavigationNode {
    readonly path: string;
    readonly component: Component;
    readonly permissions?: Permission[];
    private readonly badges_?: () => NavigationBadge[];
    private readonly guard_?: () => boolean;

    constructor(item: {
        title: string;
        icon?: string;
        path: string;
        component: Component;
        permission?: Permission;
        permissions?: Permission[];
        badges?: () => NavigationBadge[];
        guard?: () => boolean;
    }) {
        super(item.title, item.icon);
        this.path = item.path;
        this.component = item.component;
        this.permissions = item.permission ? [item.permission] : item.permissions;
        this.badges_ = item.badges;
        this.guard_ = item.guard;
    }

    get isVisible() {
        const hasPermission =
            !this.permissions || (userSession.permissions || []).some((p) => this.permissions!.includes(p));

        return hasPermission && (!this.guard_ || this.guard_());
    }

    get badges() {
        return this.badges_ ? this.badges_().filter((b) => b.value > 0) : [];
    }
}

export class NavigationFolder extends NavigationNode {
    items: NavigationNode[];

    constructor(folder: { title: string; icon?: string; items: NavigationNode[] }) {
        super(folder.title, folder.icon);
        this.items = folder.items;
    }

    get isVisible() {
        return this.items.some((i) => i.isVisible);
    }

    get badges() {
        const badges = new Map<string, NavigationBadge>();
        for (const item of this.items) {
            for (const badge of item.badges) {
                const b = badges.get(badge.color);
                badges.set(badge.color, { value: (b?.value || 0) + badge.value, color: badge.color });
            }
        }
        return [...badges.values()];
    }
}

const $t = (s: string) => s;

export default [
    new NavigationItem({
        title: $t("Händlerverzeichnis"),
        icon: "mdi-folder-home",
        path: "",
        component: DealerDirectory,
        permission: Permission.CT_VIEW_DEALER_DIRECTORY,
    }),
    new NavigationItem({
        title: $t("Meine Tagesübersicht"),
        icon: "mdi-chart-line",
        path: "my-day-overview",
        component: MyAgentDayOverview,
        permission: Permission.CT_VIEW_OWN_AGENT_DAY_OVERVIEW,
        guard: () => userSession.hasPermission(Permission.CT_IS_AGENT),
    }),
    new NavigationItem({
        title: $t("Meine Monatsübersicht"),
        icon: "mdi-chart-areaspline",
        path: "my-month-overview",
        component: MyAgentMonthOverview,
        permission: Permission.CT_VIEW_OWN_AGENT_MONTH_OVERVIEW,
        guard: () => userSession.hasPermission(Permission.CT_IS_AGENT),
    }),
    new NavigationItem({
        title: $t("Notdienstvorgänge"),
        icon: "mdi-car-emergency",
        path: "emergency-incidents",
        component: EmergencyIncidentSummariesOverview,
        permission: Permission.CT_VIEW_EMERGENCY_INCIDENTS,
    }),
    new NavigationItem({
        title: $t("Benutzerverwaltung"),
        icon: "mdi-account-multiple",
        path: "users",
        component: UserOverview,
        permission: Permission.CT_MANAGE_USERS,
    }),
    new NavigationItem({
        title: $t("Abrechnungskonten"),
        icon: "mdi-bank-transfer",
        path: "billing-accounts",
        component: BillingAccountsOverview,
        permission: Permission.CT_MANAGE_BILLING_ACCOUNTS,
    }),
    new NavigationFolder({
        title: $t("Partitionsverwaltung"),
        icon: "mdi-server",
        items: [
            new NavigationItem({
                title: $t("Datenbanken"),
                path: "mysql-databases",
                component: MysqlDatabases,
                permission: Permission.CT_MANAGE_DATABASES,
            }),
            new NavigationItem({
                title: $t("Elastic-Clusters"),
                path: "elastic-clusters",
                component: ElasticClusters,
                permission: Permission.CT_MANAGE_ELASTIC_SEARCH,
            }),
            new NavigationItem({
                title: $t("Loadbalancer"),
                path: "loadbalancers",
                component: Loadbalancers,
                permission: Permission.CT_MANAGE_LOAD_BALANCERS,
            }),
            new NavigationItem({
                title: $t("Partitionen"),
                path: "partitions",
                component: Partitions,
                permission: Permission.CT_MANAGE_PARTITIONS,
            }),
        ],
    }),
    new NavigationItem({
        title: $t("Verträge"),
        icon: "mdi-file-document-edit-outline",
        path: "contracts",
        component: ContractOverview,
        permissions: [Permission.CT_VIEW_CONTRACTS, Permission.CT_MANAGE_CONTRACTS],
    }),
    new NavigationItem({
        title: $t("Rechnungen"),
        icon: "mdi-file-document-outline",
        path: "invoices",
        component: InvoicesOverview,
        permissions: [Permission.CT_MANAGE_INVOICES, Permission.CT_VIEW_INVOICES],
    }),
    new NavigationFolder({
        title: $t("Umsätze"),
        icon: "mdi-finance",
        items: [
            new NavigationItem({
                title: $t("Monatsumsatz (BWA)"),
                path: "monthly-bwa-revenue-overview",
                component: MonthlyBwaRevenueOverview,
                permission: Permission.CT_VIEW_BWA_REVENUES,
            }),
            new NavigationItem({
                title: $t("Händlerumsatz (BWA)"),
                path: "bwa-month-dealer-revenue-overview",
                component: BwaMonthDealerRevenueOverview,
                permission: Permission.CT_VIEW_BWA_REVENUES,
            }),
            new NavigationItem({
                title: $t("Monatsumsatz (Leistung)"),
                path: "monthly-revenue-overview",
                component: MonthlyRevenueOverview,
                permission: Permission.CT_VIEW_BENEFIT_REVENUES,
            }),
            new NavigationItem({
                title: $t("Händlerumsatz (Leistung)"),
                path: "dealer-revenue-overview",
                component: DealerRevenueOverview,
                permission: Permission.CT_VIEW_BENEFIT_REVENUES,
            }),
        ],
    }),
    new NavigationFolder({
        title: $t("Eingehende E-Mails"),
        icon: "mdi-email-receive",
        items: [
            new NavigationItem({
                title: $t("Blacklist"),
                path: "incoming-email-blacklist",
                component: IncomingEmailBlacklist,
                permission: Permission.CT_MANAGE_INCOMING_EMAIL_BLACKLIST,
            }),
            new NavigationItem({
                title: $t("Spam-Meldungen"),
                path: "incoming-email-spam",
                component: IncomingEmailSpam,
                permission: Permission.CT_MANAGE_INCOMING_EMAILS,
            }),
            new NavigationItem({
                title: $t("Verlauf"),
                path: "incoming-emails",
                component: IncomingEmailSummariesOverview,
                permission: Permission.CT_MANAGE_INCOMING_EMAILS,
            }),
        ],
    }),
    new NavigationItem({
        title: $t("FTP-Zugänge"),
        icon: "mdi-folder-account-outline",
        path: "ftp-accounts",
        component: FtpAccounts,
        permission: Permission.CT_MANAGE_FTP_ACCOUNTS,
    }),
    new NavigationItem({
        title: $t("Aktivitäten"),
        icon: "mdi-account-details",
        path: "actions",
        component: ActionOverview,
        permission: Permission.CT_VIEW_ACTIONS,
    }),
    new NavigationFolder({
        title: $t("Statistiken"),
        icon: "mdi-chart-line",
        items: [
            new NavigationItem({
                title: $t("Agenten-Tagesübersicht"),
                path: "agent-day-overview",
                component: AgentDayOverview,
                permission: Permission.CT_VIEW_AGENT_DAY_OVERVIEW,
            }),
            new NavigationItem({
                title: $t("Agenten-Monatsübersicht"),
                path: "agent-month-overview",
                component: AgentMonthOverview,
                permission: Permission.CT_VIEW_AGENT_MONTH_OVERVIEW,
            }),
            new NavigationItem({
                title: $t("Agentenstatistik"),
                path: "agent-action-statistics",
                component: AgentActionStatisticsOverview,
                permission: Permission.CT_VIEW_AGENT_STATISTICS,
            }),
            new NavigationItem({
                title: $t("Händlerstatistik"),
                path: "dealer-statistics",
                component: DealerStatisticsOverview,
                permission: Permission.CT_VIEW_DEALER_STATISTICS,
            }),
        ],
    }),
    new NavigationFolder({
        title: $t("Berichte"),
        icon: "mdi-chart-bar",
        items: [
            new NavigationItem({
                title: $t("Dashboard"),
                path: "external-bdc-dashboard",
                component: ExternalBdcCdrDashboard,
                permission: Permission.CT_VIEW_BDC_REPORTS,
            }),
            new NavigationItem({
                title: $t("Eingehende Anrufe"),
                path: "external-bdc-incoming-call-report",
                component: ExternalBdcIncomingCallReport,
                permission: Permission.CT_VIEW_BDC_REPORTS,
            }),
            new NavigationItem({
                title: $t("Eingehende Anrufentwicklung"),
                path: "external-bdc-incoming-call-time-series",
                component: ExternalBdcIncomingCallTimeSeries,
                permission: Permission.CT_VIEW_BDC_REPORTS,
            }),
            new NavigationItem({
                title: $t("Benötigte Agentenanzahl"),
                path: "external-bdc-expected-agents-required-report",
                component: ExternalBdcExpectedAgentsRequiredReport,
                permission: Permission.CT_VIEW_BDC_REPORTS,
            }),
            new NavigationItem({
                title: $t("Anrufaufkommen"),
                path: "external-bdc-call-volume-time-series",
                component: ExternalBdcCallVolumeTimeSeries,
                permission: Permission.CT_VIEW_BDC_REPORTS,
            }),
        ],
    }),
    new NavigationFolder({
        title: $t("Einzelverbindungen"),
        icon: "mdi-phone-log",
        items: [
            new NavigationItem({
                title: $t("Einzelverbindungsübersicht"),
                path: "external-bdc-cdrs",
                component: ExternalBdcCdrOverview,
                permission: Permission.CT_VIEW_CDRS,
            }),
            new NavigationItem({
                title: $t("Beurteilung"),
                path: "external-bdc-cdr-assessment",
                component: ExternalBdcCdrAssessment,
                permission: Permission.CT_ASSESS_CDRS,
            }),
            new NavigationItem({
                title: $t("Agentenbeurteilungen"),
                path: "agent-assessment",
                component: AgentAssessmentOverview,
                permission: Permission.CT_ASSESS_CDRS,
            }),
        ],
    }),
    new NavigationFolder({
        title: $t("Dienstplanung"),
        icon: "mdi-account-clock",
        items: [
            new NavigationItem({
                title: $t("Dienstpläne"),
                path: "duty-rosters",
                component: DutyRosters,
                permission: Permission.CT_MANAGE_DUTY_ROSTER,
            }),
            new NavigationItem({
                title: $t("Dienstplanübersicht"),
                path: "duty-roster-overview",
                component: DutyRosterResourceView,
                permission: Permission.CT_MANAGE_DUTY_ROSTER,
            }),
            new NavigationItem({
                title: $t("Schichtplanvorlagen"),
                path: "shift-schedule-templates",
                component: ShiftScheduleTemplates,
                permission: Permission.CT_MANAGE_DUTY_ROSTER,
            }),
        ],
    }),
    new NavigationItem({
        title: $t("BDC-Teams"),
        icon: "mdi-account-multiple",
        path: "bdc-teams",
        component: BdcTeamOverview,
        permission: Permission.CT_MANAGE_BDC_TEAMS,
    }),
    new NavigationFolder({
        title: $t("Administration"),
        icon: "mdi-tune",
        items: [
            new NavigationItem({
                title: $t("Zielrufnummern"),
                path: "external-bdc-target-numbers",
                component: ExternalBdcTargetNumberOverview,
                permission: Permission.CT_MANAGE_DEALERS,
            }),
            new NavigationItem({
                title: $t("Rundschreiben"),
                path: "marketing-emails",
                component: MarketingEmail,
                permission: Permission.CT_SEND_MARKETING_EMAILS,
            }),
            new NavigationItem({
                title: $t("Suchindexe"),
                path: "rebuild-indexes",
                component: RebuildIndexes,
                permission: Permission.CT_REBUILD_INDEXES,
                guard: () => userSession.hasPermission(Permission.REBUILD_INDEXES),
            }),
            new NavigationItem({
                title: $t("Speicher"),
                path: "storage-housekeeping",
                component: HouseKeepStorage,
                permission: Permission.CT_HOUSEKEEP_STORAGE,
            }),
            new NavigationItem({
                title: $t("Fahrzeugbestand"),
                path: "inventory-housekeeping",
                component: HouseKeepInventory,
                permission: Permission.CT_HOUSEKEEP_INVENTORY,
                guard: () => userSession.hasPermission(Permission.REMOVE_INVENTORY_VEHICLE_DUPLICATES),
            }),
            new NavigationItem({
                title: $t("SQL-Konsole"),
                path: "partition-sql-console",
                component: PartitionSqlConsole,
                permission: Permission.CT_EXECUTE_PARTITION_SELECT_QUERY,
            }),
            new NavigationItem({
                title: $t("WhatsApp-Zugänge"),
                path: "whatsapp-accounts",
                component: WhatsAppAccounts,
                permission: Permission.CT_VIEW_WHATSAPP_ACCOUNTS,
            }),
            new NavigationItem({
                title: $t("CitNOW-Datenaustausch"),
                path: "citnow-data-exchange",
                component: CitNowDataExchangeOverview,
                permission: Permission.CT_MANAGE_CITNOW_DATA_EXCHANGE,
            }),
            new NavigationItem({
                title: $t("CitNOW-Salesforce"),
                path: "citnow-salesforce",
                component: CitnowSalesforceOverview,
                permission: Permission.CT_MANAGE_CITNOW_SALESFORCE,
            }),
        ],
    }),
];

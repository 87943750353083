
import Vue from "vue";

type ProcessCallback = (item: any) => Promise<void>;

export default Vue.extend({
    props: {
        canAutoSkipOnError: {
            type: Boolean,
            required: false,
            default: false,
        },
        canRetryOnError: {
            type: Boolean,
            required: false,
            default: false,
        },
        canSkipOnError: {
            type: Boolean,
            required: false,
            default: false,
        },
        canViewQueue: {
            type: Boolean,
            required: false,
            default: false,
        },
        itemProcessingTimeout: {
            type: Number,
            required: false,
            default: 250,
        },
        items: {
            type: Array,
            required: true,
        },
        process: {
            type: Function,
            required: true,
        },
        title: {
            type: String as () => string | null,
            required: false,
            default: null,
        },
    },

    data() {
        return {
            aborting: false,
            autoSkipOnError: false,
            hasError: false,
            processing: false,
            processingTimeout: null as number | null,
            queue: [] as unknown[],
        };
    },

    computed: {
        processed(): number {
            return this.items.length - this.queue.length;
        },

        progress(): number | null {
            if (!this.items.length) {
                return null;
            }

            return this.processed / this.items.length;
        },
    },

    methods: {
        async continueToProcess() {
            if (this.processingTimeout !== null) {
                clearTimeout(this.processingTimeout);
            }

            if (this.aborting || !this.queue.length) {
                this.aborting = false;
                this.processing = false;

                if (!this.queue.length) {
                    this.$emit("close");
                }

                return;
            }

            this.processing = true;

            try {
                // keep current item in queue for potential retry on error
                await this.processItem(this.queue[0]);

                // processed item successfully, drop from queue
                this.queue.shift();

                this.processingTimeout = setTimeout(
                    this.continueToProcess,
                    this.aborting ? 0 : this.itemProcessingTimeout
                );
            } catch (e) {
                this.processing = false;

                if (this.autoSkipOnError) {
                    this.skip();
                }

                this.$nextTick(() => {
                    throw e;
                });
            }
        },

        async processItem(item: any) {
            this.hasError = false;

            try {
                await (this.process as ProcessCallback)(item);
            } catch (e) {
                this.hasError = true;
                throw e;
            }
        },

        skip() {
            this.queue.shift();
            this.continueToProcess();
        },
    },

    mounted() {
        this.queue = [...this.items];
        this.continueToProcess();
    },

    beforeDestroy() {
        if (this.processingTimeout !== null) {
            clearTimeout(this.processingTimeout);
        }
    },
});


import { SelectOptions } from "@/util/types/SelectOptions";
import Vue from "vue";

export default Vue.extend({
    props: {
        value: {
            type: String,
        },
    },
    computed: {
        locales(): SelectOptions {
            return this.$i18n.availableLocales.map((k) => ({ value: k, text: this.$t(`locale.${k}`) }));
        },
    },
});


import { DutyRosterCalendarEvent, getDutyRosterEventColor, isWorkingTimeType } from "./dutyRosterUtils";
import { DutyRosterEntry, DutyRosterEntryType } from "@/api/dutyRosters";
import { configStore } from "@/store/config";
import { getFirstDayOfWeek } from "@/util/dateTimeUtils";
import { Mutable } from "@/util/types";
import moment from "moment-timezone";
import Vue from "vue";

interface DateTime {
    readonly year: number;
    readonly month: number;
    readonly day: number;
    readonly hour: number;
    readonly minute: number;
}

function getDate(ts: number) {
    return moment(ts)
        .format()
        .substring(0, 10);
}

function getTime(ts: number) {
    return moment(ts)
        .format()
        .substring(11, 16);
}

export default Vue.extend({
    props: {
        value: Array as () => DutyRosterEntry[],
        startDate: String,
        disabled: Boolean,
    },

    data() {
        return {
            events: [] as Mutable<DutyRosterCalendarEvent>[],
            dragTime: null as number | null,
            dragEvent: null as Mutable<DutyRosterCalendarEvent> | null,
            dragStart: null as number | null,
            createEvent: null as Mutable<DutyRosterCalendarEvent> | null,
            createStart: null as number | null,
            extendOriginal: null as number | null,
            DutyRosterEntryType,
            isWorkingTimeType,
        };
    },

    computed: {
        weekdays(): number[] {
            return getFirstDayOfWeek(configStore.configuration.defaultLocale) === 1
                ? [1, 2, 3, 4, 5, 6, 0]
                : [0, 1, 2, 3, 4, 5, 6];
        },
    },

    watch: {
        value() {
            this.events = this.fromEntries(this.value);
        },
    },

    methods: {
        startDrag({ event }: { event: DutyRosterCalendarEvent }) {
            this.dragEvent = event;
            this.dragTime = null;
            this.extendOriginal = null;
        },

        startTime(tms: DateTime) {
            const mouse = this.toTime(tms);
            if (this.dragEvent && this.dragTime === null) {
                const start = this.dragEvent.start;
                this.dragTime = mouse - start;
            } else {
                this.createStart = this.roundTime(mouse);
                this.createEvent = {
                    start: this.createStart!,
                    end: this.createStart! + 60 * 60 * 1000,
                    type: DutyRosterEntryType.ANSWER_INCOMING_ORDINARY_CALLS,
                    break: 0,
                    timed: true,
                };
                this.events.push(this.createEvent);
            }
        },

        extendBottom(event: DutyRosterCalendarEvent) {
            this.createEvent = event;
            this.createStart = event.start;
            this.extendOriginal = event.end;
        },

        mouseMove(tms: DateTime) {
            const mouse = this.toTime(tms);
            if (this.dragEvent && this.dragTime !== null) {
                const start = this.dragEvent.start;
                const end = this.dragEvent.end;
                const duration = end - start;
                const newStartTime = mouse - this.dragTime;
                const newStart = this.roundTime(newStartTime);
                const newEnd = newStart + duration;
                this.dragEvent.start = newStart;
                this.dragEvent.end = newEnd;
            } else if (this.createEvent && this.createStart !== null) {
                const mouseRounded = this.roundTime(mouse, false);
                const min = Math.min(mouseRounded, this.createStart);
                const max = Math.max(mouseRounded, this.createStart);
                this.createEvent.start = min;
                this.createEvent.end = max;
                this.createEvent.break = Math.min(this.createEvent.break, (max - min) / (60 * 1000));
            }
        },

        endDrag() {
            this.dragTime = null;
            this.dragEvent = null;
            this.createEvent = null;
            this.createStart = null;
            this.extendOriginal = null;
            setTimeout(() => this.$emit("input", this.toEntries(this.events)), 0);
        },

        cancelDrag() {
            if (this.createEvent) {
                if (this.extendOriginal) {
                    this.createEvent.end = this.extendOriginal;
                } else {
                    const i = this.events.indexOf(this.createEvent);
                    if (i !== -1) {
                        this.events.splice(i, 1);
                    }
                }
            }
            this.createEvent = null;
            this.createStart = null;
            this.dragTime = null;
            this.dragEvent = null;
        },

        roundTime(time: number, down = true) {
            const roundTo = 15;
            const roundDownTime = roundTo * 60 * 1000;
            return down ? time - (time % roundDownTime) : time + (roundDownTime - (time % roundDownTime));
        },

        toTime(tms: DateTime) {
            return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime();
        },

        removeEvent(event: DutyRosterCalendarEvent) {
            const i = this.events.indexOf(event);
            if (i !== -1) {
                this.events.splice(i, 1);
            }
        },

        fromEntries(entries: DutyRosterEntry[]): DutyRosterCalendarEvent[] {
            return entries.map((e) => ({
                start: Date.parse(`${e.day}T${e.beginTime}`),
                end: Date.parse(`${e.day}T${e.endTime}`) + 60 * 1000,
                break: e.includedBreakMinutes,
                type: e.type,
                timed: true,
            }));
        },

        toEntries(events: DutyRosterCalendarEvent[]): DutyRosterEntry[] {
            return events.map((e) => {
                const day = getTime(e.end) === "00:00" ? getDate(e.end - 60 * 1000) : getDate(e.end);
                return {
                    day,
                    beginTime: day === getDate(e.start) ? getTime(e.start) : "00:00",
                    endTime: getTime(e.end - 60 * 1000),
                    type: e.type,
                    includedBreakMinutes: e.break,
                };
            });
        },

        changeType(event: Mutable<DutyRosterCalendarEvent>, type: DutyRosterEntryType) {
            event.type = type;

            if (!this.isWorkingTimeType(type)) {
                event.break = 0;
            }

            this.$emit("input", this.toEntries(this.events));
        },

        eventColor(event: DutyRosterCalendarEvent): string {
            return getDutyRosterEventColor(event.type, this.$vuetify.theme.dark);
        },
    },

    created() {
        this.events = this.fromEntries(this.value);
    },
});

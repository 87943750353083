import axios from "axios";

export enum PartitionMachineType {
    M = "M",
    L = "L",
    XL = "XL",
}

export interface PartitionSummary {
    readonly id: string;
    readonly created: Date;
    readonly active: boolean;
    readonly customImage: string | null;
    readonly name: string;
    readonly host: string;
    readonly transactionalEmailHost: string;
    readonly loadbalancerId: number;
    readonly mysqlDatabaseId: string;
    readonly mysqlUser: string;
    readonly mysqlDatabaseName: string;
    readonly elasticClusterId: number;
    readonly machineType: PartitionMachineType;
    readonly numberOfInstances: number;
    readonly prefix: string;
    readonly sequenceOffset: number;
    readonly country: string | null;
    readonly currency: string | null;
    readonly marketingEmailsEnabled: boolean;

    readonly lastHeartbeatTimestamp: Date | null;
    readonly lastHeartbeatProcessManually: number | null;
    readonly deletedContacts: number;
    readonly totalContacts: number;

    readonly mysqlUserWithDatabaseCreated: boolean;
    readonly dealerdeskTwilioAccountSid: string;
    readonly dealerdeskTwilioUseMainAccount: boolean;
    readonly defaultClickToCallCallerId: string | null;
    readonly citnowConversationsTwilioAccountSid: string | null;
    readonly citnowConversationsTwilioApiKeySid: string | null;
    readonly postmarkDomainId: string;
    readonly dkimVerified: boolean;
    readonly returnPathVerified: boolean;
    readonly dnsRecordsCreated: boolean;
    readonly postmarkServerId: string;
    readonly bucketName: string;
    readonly gcloudServiceAccountCreated: boolean;
    readonly gcloudInstancegroupName: string;
    readonly gcloudBackendName: string;
    readonly gcloudPathMatcherName: string;
    readonly gcloudInstanceNamePrefix: string;
}

export interface PartitionCreationForm {
    readonly id: string;
    readonly name: string;
    readonly loadbalancerId: number;
    readonly mysqlDatabaseId: string;
    readonly elasticClusterId: number;
    readonly machineType: PartitionMachineType;
    readonly dealerdeskTwilioUseMainAccount: boolean;
    readonly prefix: string;
    readonly sequenceOffset: number;
    readonly country: string | null;
    readonly currency: string | null;
    readonly marketingEmailsEnabled: boolean;
}

export interface PartitionModificationForm {
    readonly name: string;
    readonly machineType: PartitionMachineType;
    readonly numberOfInstances: number;
    readonly marketingEmailsEnabled: boolean;
}

export interface TwilioApiKey {
    readonly twilioApiKeySid: string;
    readonly twilioApiKeySecret: string;
}

interface PartitionsApi {
    list(): Promise<PartitionSummary[]>;
    getById(id: string): Promise<PartitionSummary>;
    add(partitionCreationForm: PartitionCreationForm): Promise<void>;
    edit(id: string, partitionModificationForm: PartitionModificationForm): Promise<void>;
    createMysqlUserWithDatabase(id: string): Promise<void>;
    createCitnowConversationsTwilioSubAccount(id: string): Promise<void>;
    createCitnowConversationsTwilioSubAccountApiKey(id: string): Promise<void>;
    createDealerdeskTwilioSubAccount(id: string): Promise<void>;
    verifyDefaultClickToCallCallerId(id: string): Promise<void>;
    createPostmarkDomain(id: string): Promise<void>;
    createDnsRecords(id: string): Promise<void>;
    createPostmarkServer(id: string): Promise<void>;
    createBucket(id: string): Promise<void>;
    createGcloudCredentials(id: string): Promise<void>;
    createInstancegroup(id: string): Promise<void>;
    createBackend(id: string): Promise<void>;
    createPathMatcher(id: string): Promise<void>;
    createInstancesById(id: string): Promise<void>;
    recreateInstances(renewMysqlPassword: boolean): Promise<void>;
    recreateInstancesById(id: string, renewMysqlPassword: boolean): Promise<void>;
    deactivateInstancesById(id: string): Promise<void>;
    deployCustomImageById(id: string, image: string): Promise<void>;
    getCitnowConversationsTwilioSubAccountApiKey(id: string): Promise<TwilioApiKey | null>;
    resetAndRecreateInstancesById(id: string, renewMysqlPassword: boolean): Promise<void>;
}

export const partitionsApi: PartitionsApi = {
    async list() {
        const result: PartitionSummary[] = (await axios.get("/api/partitions")).data;

        return result.map((v) => ({
            ...v,
            created: new Date(v.created),
            lastHeartbeatTimestamp: v.lastHeartbeatTimestamp ? new Date(v.lastHeartbeatTimestamp) : null,
        }));
    },

    async getById(id) {
        const result: PartitionSummary = (await axios.get(`/api/partitions/${id}`)).data;

        return {
            ...result,
            created: new Date(result.created),
            lastHeartbeatTimestamp: result.lastHeartbeatTimestamp ? new Date(result.lastHeartbeatTimestamp) : null,
        };
    },

    async add(partitionCreationForm) {
        await axios.post("/api/partitions", partitionCreationForm);
    },

    async edit(id, partitionModificationForm) {
        await axios.put(`/api/partitions/${id}`, partitionModificationForm);
    },

    async createMysqlUserWithDatabase(id) {
        await axios.post(`/api/partitions/${id}/_create-mysql-user-with-database`);
    },

    async createCitnowConversationsTwilioSubAccount(id) {
        await axios.post(`/api/partitions/${id}/_create-citnow-conversations-twilio-sub-account`);
    },

    async createCitnowConversationsTwilioSubAccountApiKey(id) {
        await axios.post(`/api/partitions/${id}/_create-citnow-conversations-twilio-sub-account-api-key`);
    },

    async createDealerdeskTwilioSubAccount(id) {
        await axios.post(`/api/partitions/${id}/_create-dealerdesk-twilio-sub-account`);
    },

    async verifyDefaultClickToCallCallerId(id) {
        await axios.post(`/api/partitions/${id}/_verify-default-click-to-call-caller-id`);
    },

    async createPostmarkDomain(id) {
        await axios.post(`/api/partitions/${id}/_create-postmark-domain`);
    },

    async createDnsRecords(id) {
        await axios.post(`/api/partitions/${id}/_create-dns-records`);
    },

    async createPostmarkServer(id) {
        await axios.post(`/api/partitions/${id}/_create-postmark-server`);
    },

    async createBucket(id) {
        await axios.post(`/api/partitions/${id}/_create-bucket`);
    },

    async createGcloudCredentials(id) {
        await axios.post(`/api/partitions/${id}/_create-gcloud-credentials`);
    },

    async createInstancegroup(id) {
        await axios.post(`/api/partitions/${id}/_create-instancegroup`);
    },

    async createBackend(id) {
        await axios.post(`/api/partitions/${id}/_create-backend`);
    },

    async createPathMatcher(id) {
        await axios.post(`/api/partitions/${id}/_create-path-matcher`);
    },

    async createInstancesById(id) {
        await axios.post(`/api/partitions/${id}/_create-instances`);
    },

    async recreateInstances(renewMysqlPassword) {
        await axios.post("/api/partitions/_/_recreate-instances", undefined, { params: { renewMysqlPassword } });
    },

    async recreateInstancesById(id, renewMysqlPassword) {
        await axios.post(`/api/partitions/${id}/_recreate-instances`, undefined, { params: { renewMysqlPassword } });
    },

    async deactivateInstancesById(id) {
        await axios.post(`/api/partitions/${id}/_deactivate-instances`);
    },

    async deployCustomImageById(id, image) {
        await axios.post(`/api/partitions/${id}/_deploy-image`, undefined, { params: { image } });
    },

    async getCitnowConversationsTwilioSubAccountApiKey(id) {
        return (await axios.get(`/api/partitions/${id}/citnow-conversations-twilio-sub-account-api-key`)).data;
    },

    async resetAndRecreateInstancesById(id, renewMysqlPassword) {
        await axios.post(`/api/partitions/${id}/_reset-recreate-instances`, undefined, {
            params: { renewMysqlPassword },
        });
    },
};

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('crud-table-page',{attrs:{"headers":_vm.headers,"add-dialog-title":_vm.$t('Blacklist-Eintrag hinzufügen'),"page-title":_vm.$t('Blacklist'),"default-sort-by":_vm.defaultSortBy,"sort-options":_vm.sortOptions,"callbacks":{
        load: (sortBy) => _vm.getIncomingEmailBlacklistEntries(sortBy),
        add: (form) => _vm.addIncomingEmailBlacklistEntry(form),
        delete: (item) => _vm.deleteIncomingEmailBlacklistEntry(item),
        key: (item) => item.id,
        emptyForm: () => _vm.emptyForm(),
    }},scopedSlots:_vm._u([{key:"item.type",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$t(`enum.IncomingEmailBlacklistEntryType.${item.data.type}`))+" ")]}},{key:"item.value",fn:function({ item }){return [_vm._v(" "+_vm._s(item.data.value)+" ")]}},{key:"item.matchCount",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$tc("Keine Matches | 1 Match | {count} Matches", item.matchCount, { count: _vm.$n(item.matchCount) }))+" ")]}},{key:"item.lastMatch",fn:function({ item }){return [(item.lastMatch)?_c('span',[_vm._v(_vm._s(_vm._f("instant")(item.lastMatch,"L")))]):_c('span',[_vm._v("-")])]}},{key:"dialog",fn:function({ form }){return [_c('enum-field',{attrs:{"label":_vm.$t('Typ'),"rules":_vm.notEmpty(),"text":(k) => _vm.$t(`enum.IncomingEmailBlacklistEntryType.${k}`),"values":_vm.IncomingEmailBlacklistEntryType},model:{value:(form.type),callback:function ($$v) {_vm.$set(form, "type", $$v)},expression:"form.type"}}),_c('v-text-field',{attrs:{"autocomplete":"no","autofocus":"","id":_vm.$id(),"label":_vm.$t('Text'),"rules":_vm.notEmpty().maxLength(300)},model:{value:(form.value),callback:function ($$v) {_vm.$set(form, "value", $$v)},expression:"form.value"}})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }
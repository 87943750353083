import {
    IncomingEmailSummaryCountType,
    IncomingEmailSummarySearchOrder,
    IncomingEmailSummarySearchRequest,
} from "@/api/incomingEmails";

export const EMPTY_SEARCH_REQUEST: IncomingEmailSummarySearchRequest = {
    blacklisted: null,
    contentDetected: null,
    user: null,
    fromName: null,
    fromAddress: null,
    createdFrom: null,
    createdTo: null,
    countType: IncomingEmailSummaryCountType.FROM_ADDRESS,
    sortBy: IncomingEmailSummarySearchOrder.CREATED_DESC,
};

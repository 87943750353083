import "./app/errorHandling";
import i18n from "./app/i18n";
import App from "./app/pages/App.vue";
import Loader from "./app/pages/Loader.vue";
import "./app/ids";
import "./app/csrf";
import "./app/filters";
import router from "./app/router";
import vuetify from "./app/vuetify";
import { configStore } from "./store/config";
import { loaderStore } from "./store/loader";
import "./store/now";
import { userSession } from "./store/userSession";
import Vue from "vue";

import "@fontsource/roboto/100.css";
import "@fontsource/roboto/100-italic.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/300-italic.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/400-italic.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/500-italic.css";
import "@fontsource/roboto/700.css";
import "@fontsource/roboto/700-italic.css";
import "@fontsource/roboto/900.css";
import "@fontsource/roboto/900-italic.css";
import "@mdi/font/css/materialdesignicons.css";
import "flag-icon-css/css/flag-icon.css";

new Vue({
    vuetify,
    render: (h) => h(Loader),
}).$mount("#app");

Promise.all([configStore.initialize(), userSession.initialize()])
    .then(() => {
        Object.entries(configStore.configuration.messages).forEach(([locale, message]) =>
            i18n.mergeLocaleMessage(locale, message)
        );
        new Vue({
            i18n,
            router,
            vuetify,
            render: (h) => h(App),
        }).$mount("#app");
    })
    .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
        loaderStore.failed = true;
    });

import {
    getNextOngoingTimeSlot,
    getOngoingTimeSlot,
    getOngoingTimeSlotKeys,
    OngoingTimeInterval,
} from "./timeInterval";
import { userSession } from "@/store/userSession";
import { toDateObject } from "@/util/dateTimeUtils";

export function getTimeSeriesDefaultKeys<T>(
    rows: readonly T[],
    getDate: (row: T) => Date | null,
    from: string | null,
    to: string | null,
    ongoingTimeInterval: OngoingTimeInterval,
    now: Date | null,
    timeZone?: string
) {
    const earliestTime = rows.reduce((prev, cur) => Math.min(prev, getDate(cur)?.getTime() ?? Infinity), Infinity);
    const latestTime = rows.reduce((prev, cur) => Math.max(prev, getDate(cur)?.getTime() ?? -Infinity), -Infinity);

    const start = from
        ? toDateObject(timeZone || userSession.timeZone, from)
        : earliestTime !== Infinity
        ? new Date(earliestTime)
        : now;

    const end = to
        ? toDateObject(timeZone || userSession.timeZone, to, 1)
        : latestTime !== -Infinity
        ? new Date(latestTime)
        : now;

    if (!start || !end || start.getTime() > end.getTime()) {
        return [];
    }

    return getOngoingTimeSlotKeys(start, end, ongoingTimeInterval, timeZone || userSession.timeZone, !to);
}

export function getNumberOfSubIntervalsInOngoingTimeInterval(
    interval: OngoingTimeInterval,
    subInterval: OngoingTimeInterval
): number | null {
    const ts = new Date();
    const timeZone = userSession.timeZone;
    const getFullSecondsFn = (date: Date) => Math.floor(date.getTime() / 1000);

    const subIntervalFrom = getFullSecondsFn(getOngoingTimeSlot(ts, subInterval, timeZone)!);
    const subIntervalTo = getFullSecondsFn(getNextOngoingTimeSlot(ts, subInterval, timeZone)!);
    const subIntervalDuration = subIntervalTo - subIntervalFrom;

    if (subIntervalDuration <= 0) {
        return null;
    }

    const intervalFrom = getFullSecondsFn(getOngoingTimeSlot(ts, interval, timeZone)!);
    const intervalTo = getFullSecondsFn(getNextOngoingTimeSlot(ts, interval, timeZone)!);
    const intervalDuration = intervalTo - intervalFrom;

    if (intervalDuration <= 0) {
        return null;
    }

    return intervalDuration / subIntervalDuration;
}

import { $t } from "./i18n";
import { showConfirm } from "./messageUtil";

export function writeHtmlToIframe(elem: HTMLIFrameElement, html: string) {
    const iframe = elem?.contentWindow?.document;
    if (!iframe) {
        return;
    }

    iframe.write(html);
    const style = iframe.createElement("style");
    style.innerHTML =
        "body { font-family: sans-serif; font-size: 10.5pt; background: white; } body > pre { white-space: pre-wrap; }";
    const head = iframe.head;
    head.insertBefore(style, head.firstChild);
    const base = iframe.createElement("base");
    base.target = "_blank";
    head.insertBefore(base, head.firstChild);

    const body = iframe.body;
    if (body.clientHeight < body.scrollHeight) {
        elem!.height = `${Math.min(1000, body.scrollHeight + 30)}px`;
    }

    body.querySelectorAll("a").forEach((link) => {
        link.addEventListener("click", (event) => {
            event.preventDefault();
            event.stopPropagation();
            showConfirm(
                $t("Externer Link") as string,
                $t("Der angeklickte Link führt auf eine externe Seite. Möchten Sie fortfahren?") as string
            ).then((confirmed) => confirmed && (link.cloneNode(false) as HTMLAnchorElement).click());
        });
    });
}


import GlobalDialog from "./GlobalDialog.vue";
import Snackbar from "./Snackbar.vue";
import { userSession } from "@/store/userSession";
import Vue from "vue";

export default Vue.extend({
    computed: {
        userSession() {
            return userSession;
        },

        title() {
            const title = this.$route.matched
                .map((r) => r.meta.title as string | undefined)
                .reduceRight((p, c) => p || c);
            return title ? (this.$t(title) as string) : "";
        },
    },

    watch: {
        "userSession.locale"(locale: string) {
            this.$i18n.locale = this.$vuetify.lang.current = locale;
        },

        "userSession.darkmode"(value: boolean) {
            this.$vuetify.theme.dark = value;
        },

        title() {
            window.document.title = this.title;
        },
    },

    beforeMount() {
        this.$i18n.locale = this.$vuetify.lang.current = userSession.locale!;
        this.$vuetify.theme.dark = userSession.darkmode;
        window.document.title = this.title;
    },

    components: {
        GlobalDialog,
        Snackbar,
    },
});


import Vue from "vue";

export default Vue.extend({
    props: {
        dialogTitle: {
            type: String,
            required: true,
        },
        password: {
            type: String,
            required: true,
        },
    },
});

import axios, { AxiosError } from "axios";

type JsonValue = string | number | boolean | { [key: string]: JsonValue } | [JsonValue] | null;

interface BadRequestDetails {
    readonly messageKey: string;
    readonly path: string | null;
    readonly rejectedValue: JsonValue;
}

export class BadRequest {
    constructor(public readonly details: BadRequestDetails[]) {}
}

export class ServerError {
    constructor(public readonly uuid: string, public readonly path: string) {}
}

export class Unauthorized {}

export class Forbidden {
    constructor(public readonly error: AxiosError) {}
}

export interface FrontendError {
    readonly name: string;
    readonly message: string | null;
    readonly stack: string | null;
    readonly userAgent: string;
    readonly url: string | null;
    readonly method: string | null;
}

interface ErrorsApi {
    reportError(error: FrontendError): Promise<void>;
}

export const errorsApi: ErrorsApi = {
    async reportError(error) {
        await axios.post("/api/frontend-errors", error);
    },
};

import { CallCenterStatistics, CallCenterStatisticsData } from "@/api/callCenterStatistics";

function getErrorRatio(issuesCreated: number, activitiesCreated: number, errorsReported: number) {
    if (issuesCreated + activitiesCreated <= 0) {
        return 0;
    }

    return errorsReported / (issuesCreated + activitiesCreated);
}

function getRatio(dividend: number, divisor: number) {
    if (divisor <= 0) {
        return 0;
    }

    return dividend / divisor;
}

export interface ExtendedCallCenterStatisticsData extends CallCenterStatisticsData {
    readonly hours: number;
    readonly agentHours: number;
    readonly billedMinutesPerformance: number;
    readonly callsAcceptedRatio: number;
    readonly callPerformance: number;
    readonly contactsCreatedToIssuesCreatedRatio: number;
    readonly errorRatio: number;
    readonly issuesCreatedToCallsAcceptedRatio: number;
    readonly notesCreatedToCallsAcceptedRatio: number;
    readonly performance: number;
}

function toExtendedCallCenterStatisticsDataInternal(
    d: CallCenterStatisticsData,
    hours: number
): ExtendedCallCenterStatisticsData {
    const agentHours = d.workedSeconds / (hours * 3600);

    return {
        ...d,
        hours,
        agentHours,
        billedMinutesPerformance: 100 * getRatio(d.billedMinutes, d.workedSeconds / 36),
        callsAcceptedRatio:
            d.callsAccepted + d.unacceptedCallsAfterThreshold <= 0
                ? 1
                : d.callsAccepted / (d.callsAccepted + d.unacceptedCallsAfterThreshold),
        callPerformance: 100 * getRatio(d.callsAccepted, d.workedSeconds / 36),
        contactsCreatedToIssuesCreatedRatio: getRatio(d.contactsCreated, d.issuesCreated),
        errorRatio: getErrorRatio(d.issuesCreated, d.activitiesCreated, d.errorsReported),
        issuesCreatedToCallsAcceptedRatio: getRatio(d.issuesCreated, d.callsAccepted),
        notesCreatedToCallsAcceptedRatio: getRatio(d.activitiesCreated, d.callsAccepted),
        performance: agentHours <= 0 ? 0 : d.billedMinutes / agentHours,
    };
}

export function toExtendedCallCenterStatisticsData(s: CallCenterStatistics): ExtendedCallCenterStatisticsData {
    const hours = Math.ceil((s.to.getTime() - s.from.getTime()) / 1000) / 3600;

    return toExtendedCallCenterStatisticsDataInternal(s.callCenterStatisticsData, hours);
}

export function addExtendedCallCenterStatisticsData(
    a: ExtendedCallCenterStatisticsData,
    b: ExtendedCallCenterStatisticsData
): ExtendedCallCenterStatisticsData {
    return toExtendedCallCenterStatisticsDataInternal(
        {
            calls: a.calls + b.calls,
            callsAccepted: a.callsAccepted + b.callsAccepted,
            billedMinutes: a.billedMinutes + b.billedMinutes,
            unacceptedCallsAfterThreshold: a.unacceptedCallsAfterThreshold + b.unacceptedCallsAfterThreshold,
            issuesCreated: a.issuesCreated + b.issuesCreated,
            activitiesCreated: a.activitiesCreated + b.activitiesCreated,
            contactsCreated: a.contactsCreated + b.contactsCreated,
            errorsReported: a.errorsReported + b.errorsReported,
            scheduledSeconds: a.scheduledSeconds + b.scheduledSeconds,
            workedSeconds: a.workedSeconds + b.workedSeconds,
        },
        a.hours + b.hours
    );
}

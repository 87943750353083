var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{staticClass:"background-neutral",attrs:{"cols":"2"}},[_c('div',{class:{
                        'font-weight-bold': true,
                        'pt-3': !_vm.small,
                        'text-h4': !_vm.small,
                        'text-h6': _vm.small,
                    }},[_vm._v(" "+_vm._s(_vm.name)+" ")])]),_c('v-col',{class:_vm.acceptanceRatioThreshold <= _vm.callCenterStatisticsData.callsAcceptedRatio
                        ? 'background-positive'
                        : 'background-negative',attrs:{"cols":"3"}},[(!_vm.small)?_c('div',{staticClass:"text-h4"},[_vm._v(" "+_vm._s(_vm.$t("Annahmequote"))+" ")]):_vm._e(),_c('div',{class:{ 'font-weight-bold': true, 'text-h2': !_vm.small, 'text-h5': _vm.small }},[_vm._v(" "+_vm._s(_vm.renderPercentage(_vm.callCenterStatisticsData.callsAcceptedRatio))+" ")])]),_c('v-col',{class:_vm.performanceThreshold <= _vm.callCenterStatisticsData.performance
                        ? 'background-positive'
                        : 'background-negative',attrs:{"cols":"3"}},[(!_vm.small)?_c('div',{staticClass:"text-h4"},[_vm._v(_vm._s(_vm.$t("Leistung")))]):_vm._e(),_c('div',{class:{ 'font-weight-bold': true, 'text-h2': !_vm.small, 'text-h5': _vm.small }},[_vm._v(" "+_vm._s(_vm.$n(_vm.callCenterStatisticsData.performance, { minimumFractionDigits: "1", maximumFractionDigits: "1", }))+" ")])]),_c('v-col',{class:_vm.callCenterStatisticsData.errorRatio <= _vm.errorRatioThreshold
                        ? 'background-positive'
                        : 'background-negative',attrs:{"cols":"2"}},[(!_vm.small)?_c('div',{staticClass:"text-h4"},[_vm._v(" "+_vm._s(_vm.$t("Fehler"))+" ")]):_vm._e(),_c('div',{class:{ 'font-weight-bold': true, 'text-h2': !_vm.small, 'text-h5': _vm.small }},[_vm._v(" "+_vm._s(_vm.renderPercentage(_vm.callCenterStatisticsData.errorRatio))+" ")])]),_c('v-col',{staticClass:"background-neutral",attrs:{"cols":"2"}},[(!_vm.small)?_c('div',{staticClass:"text-h4"},[_vm._v(" "+_vm._s(_vm.$t("Vorg./Anruf"))+" ")]):_vm._e(),_c('div',{class:{ 'font-weight-bold': true, 'text-h2': !_vm.small, 'text-h5': _vm.small }},[_vm._v(" "+_vm._s(_vm.renderPercentage(_vm.callCenterStatisticsData.issuesCreatedToCallsAcceptedRatio))+" ")])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
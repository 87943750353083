import i18n from "./i18n";
import { userSession } from "@/store/userSession";
import { DateTimeOutputFormat, formatInstant, formatLocalDate, formatLocalTime } from "@/util/dateTimeUtils";
import { parseAndFormatNumber } from "@/util/phoneNumberUtils";
import Vue from "vue";

export function renderUnit(n: number, unit: string): string {
    try {
        // cast to any needed because of experimental API
        return new Intl.NumberFormat(i18n.locale, { style: "unit", unit } as any).format(n);
    } catch (e) {
        // fallback for browsers not supporting units
        return `${i18n.n(n)} ${i18n.t(unit)}`;
    }
}

export function renderCurrency(n: number, currency: string): string {
    return new Intl.NumberFormat(i18n.locale, { style: "currency", currency }).format(n);
}

Vue.mixin({
    filters: {
        filesize(n: number, maxFractionDigits: number) {
            if (n < 1000) {
                return renderUnit(n, "byte");
            }
            if (n < 1000000) {
                return renderUnit(Number.parseFloat((n / 1000).toFixed(maxFractionDigits)), "kilobyte");
            }

            return renderUnit(Number.parseFloat((n / 1000000).toFixed(maxFractionDigits)), "megabyte");
        },

        phoneNumber(v: string) {
            return v ? parseAndFormatNumber(v, "INTERNATIONAL") || v : "";
        },

        currency(n: number, currency: string) {
            return renderCurrency(n, currency);
        },

        unit(n: number, unit: string) {
            return renderUnit(n, unit);
        },

        localDate(localDate: string | null, format: DateTimeOutputFormat = "S") {
            return localDate ? formatLocalDate(localDate, userSession.locale, format) : "";
        },

        localTime(localTime: string | null, format: DateTimeOutputFormat = "S") {
            return localTime ? formatLocalTime(localTime, userSession.locale, format) : "";
        },

        instant(d: Date | string | null, format: DateTimeOutputFormat = "S", tz?: string) {
            return d ? formatInstant(d, tz || userSession.timeZone, userSession.locale, format) : "";
        },

        internalLink(link: string) {
            return link;
        },

        externalLink(link: string) {
            if (link.match(/^[a-zA-Z]+:\/\//)) {
                return link;
            }

            return "http://" + link;
        },
    },
});

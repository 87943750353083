import axios from "axios";

export interface Dealer {
    readonly id: string;
    readonly created: Date;
    readonly updated: Date;
    readonly active: boolean;
    readonly sequence: number;
    readonly searchKey: string;
    readonly billingAccountId: number;
    readonly partitionId: string;
    readonly name: string;
    readonly externalBdcTargetNumbers: DealerExternalBdcTargetNumber[];
    readonly address1: string | null;
    readonly address2: string | null;
    readonly zip: string | null;
    readonly city: string | null;
    readonly state: string | null;
    readonly country: string | null;
    readonly numbers: string[];
    readonly emailAddresses: string[];
    readonly onlineUsers: number;
    readonly totalUsers: number;
    readonly casesOpen: number;
    readonly casesAssigned: number;
    readonly opportunitiesOpen: number;
    readonly opportunitiesAssigned: number;
    readonly emergencyIncidentsOpen: number;
    readonly deletedInventoryVehicles: number;
    readonly totalInventoryVehicles: number;
    readonly activeInventoryVehicleInquiries: number;
    readonly whatsAppNumbers: string[];
}

export interface DealerExternalBdcTargetNumber {
    readonly number: string;
    readonly distributionBeginOffset: number;
}

interface DealersApi {
    editDealerExternalBdcTargetNumber(dealerExternalBdcTargetNumber: DealerExternalBdcTargetNumber): Promise<void>;
    list(): Promise<Dealer[]>;
}

export const dealersApi: DealersApi = {
    async editDealerExternalBdcTargetNumber(dealerExternalBdcTargetNumber) {
        await axios.put("/api/dealers/_/external-bdc-target-numbers", dealerExternalBdcTargetNumber);
    },

    async list() {
        const result: Dealer[] = (await axios.get("/api/dealers")).data;

        return result.map((v) => ({
            ...v,
            created: new Date(v.created),
            updated: new Date(v.updated),
        }));
    },
};


import { BdcTeam, BdcTeamForm, bdcTeamsApi } from "@/api/bdcTeams";
import { BadRequest } from "@/api/errors";
import { Gender, User } from "@/api/users";
import { getFullName } from "@/app/userUtils";
import { notEmpty, ValidationHelper } from "@/app/validation";
import { userSession } from "@/store/userSession";
import { cloneObject } from "@/util/cloneUtils";
import { Mutable, SelectOption } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    props: {
        bdcTeam: {
            type: Object as () => BdcTeam | null,
            default: null,
        },
        users: {
            type: Array as () => User[],
            required: true,
        },
    },

    data() {
        const validationHelper = new ValidationHelper();

        return {
            Gender,
            notEmpty,
            bdcTeamForm: (this.bdcTeam
                ? cloneObject(this.bdcTeam)
                : {
                      name: "",
                      teamLeadUserId: null,
                      memberUserIds: [],
                      sendActionFeedbackToTeamLead: true,
                  }) as Mutable<BdcTeamForm>,
            saving: false,
            validationHelper,
        };
    },

    computed: {
        trueFalseOptions(): SelectOption[] {
            return [
                { value: true, text: this.$t("Ja") },
                { value: false, text: this.$t("Nein") },
            ];
        },

        userOptions(): SelectOption[] {
            return this.users
                .map((u) => ({ value: u.id, text: getFullName(u) }))
                .sort((a, b) => a.text.localeCompare(b.text, userSession.locale));
        },
    },

    methods: {
        async submitForm() {
            if (!(this.$refs.form as any).validate()) {
                return;
            }

            this.saving = true;
            try {
                if (this.bdcTeam) {
                    await bdcTeamsApi.edit(this.bdcTeam.id, this.bdcTeamForm);
                } else {
                    await bdcTeamsApi.add(this.bdcTeamForm);
                }

                this.$emit("success");
            } catch (e) {
                if (!(e instanceof BadRequest)) {
                    throw e;
                }

                this.validationHelper.update(e, this.$refs.form);
            } finally {
                this.saving = false;
            }
        },
    },
});

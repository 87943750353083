import { Action } from "@/api/actions";
import { ExternalBdcCdr } from "@/api/externalBdcCdrs";
import { PartitionSummary } from "@/api/partitions";
import { Recording } from "@/api/recordings";
import { Permission } from "@/api/userSession";
import { userSession } from "@/store/userSession";

export interface ExternalBdcCdrAndRecordings {
    readonly externalBdcCr: ExternalBdcCdr;
    readonly recordings: Recording[];
}

export function canViewActions(): boolean {
    return userSession.hasPermission(Permission.CT_VIEW_ACTIONS);
}

export function getPartitionResourceUrl(action: Action, partition: PartitionSummary): string | null {
    if (!action.resourceUrl) {
        return null;
    }

    return `https://${partition.host}${action.resourceUrl}`;
}


import { ActionRow } from "@/api/reporting";
import ActionsBottomSheet from "@/app/pages/action/ActionsBottomSheet.vue";
import ReportingDashboardTile from "@/app/pages/reporting/dashboard/ReportingDashboardTile.vue";
import Vue from "vue";

export default Vue.extend({
    props: {
        actionRows: {
            type: Array as () => readonly ActionRow[],
            required: true,
        },
        minHeight: {
            type: Number,
            required: false,
        },
        loading: {
            type: Boolean,
            required: true,
        },
        small: {
            type: Boolean,
            default: false,
        },
        subtitle: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            bottomSheetActionIds: [] as number[],
            bottomSheetVisible: false,
        };
    },

    computed: {
        actionRowsWithFeedback(): readonly ActionRow[] {
            return this.actionRows.filter((r) => r.feedbackCreated);
        },
    },

    methods: {
        hideBottomSheet() {
            this.bottomSheetVisible = false;
            this.bottomSheetActionIds = [];
        },

        showBottomSheetOnClick(ids: number[]): void {
            this.bottomSheetActionIds = ids;
            this.bottomSheetVisible = true;
        },
    },

    components: {
        ActionsBottomSheet,
        ReportingDashboardTile,
    },
});

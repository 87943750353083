
import { loadbalancersApi } from "@/api/loadbalancers";
import { MAX_PARTITIONS_PER_LOADBALANCER } from "@/app/resourceLimits";
import { notEmpty } from "@/app/validation";

import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            isFinalizing: false,
            valid: true,
            gcloudId: "dealerdesk-loadbalancer-" + this.lb.id,
            gcloudStaticExternalIpId: "dealerdesk-static-ip-" + this.lb.id,
            host: "",
            idRules: notEmpty()
                .maxLength(63)
                .msg(() => this.$t("Gültige ID ist erforderlich")),
            loadbalancer: this.lb,
            MAX_PARTITIONS_PER_LOADBALANCER,
        };
    },

    props: {
        lb: {
            type: Object,
            required: true,
        },
    },

    computed: {
        usageCountColor(): string {
            if (this.loadbalancer.usageCount >= MAX_PARTITIONS_PER_LOADBALANCER) {
                return "error";
            }
            return "success";
        },
    },

    methods: {
        async finalizeLoadbalancer() {
            if (!this.valid) {
                (this.$refs.form as any).validate();
                return;
            }

            this.isFinalizing = true;

            try {
                await loadbalancersApi.finalize(this.loadbalancer.id, {
                    gcloudId: this.gcloudId,
                    gcloudStaticExternalIpId: this.gcloudStaticExternalIpId,
                    host: this.host,
                });

                this.loadbalancer.gcloudId = this.gcloudId;
                this.loadbalancer.gcloudStaticExternalIpId = this.gcloudStaticExternalIpId;
                this.loadbalancer.host = this.host;
            } finally {
                this.isFinalizing = false;
            }
        },
    },
});

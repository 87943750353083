
import { ExternalBdcCdr } from "@/api/externalBdcCdrs";
import ExternalBdcCdrsBottomSheet from "@/app/pages/externalbdccdr/ExternalBdcCdrsBottomSheet.vue";
import { getReportingTableItemsByReportingDonutChartSeries } from "@/app/pages/reporting/chartUtils";
import { ReportingChartColorPalette } from "@/app/pages/reporting/charts/reportingChart";
import { hasNonZeroSeries, ReportingDonutChartData } from "@/app/pages/reporting/charts/reportingDonutChart";
import ReportingDashboardDonutTile from "@/app/pages/reporting/dashboard/ReportingDashboardDonutTile.vue";
import { ReportingTableData } from "@/app/pages/reporting/table/reportingTable";
import Vue from "vue";

export default Vue.extend({
    props: {
        externalBdcCdrRows: {
            type: Array as () => readonly ExternalBdcCdr[],
            required: true,
        },
        chartHeight: {
            type: Number,
            required: false,
        },
        minHeight: {
            type: Number,
            required: false,
        },
        loading: {
            type: Boolean,
            required: true,
        },
        small: {
            type: Boolean,
            default: false,
        },
        subtitle: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            required: false,
        },
    },

    data() {
        return {
            bottomSheetExternalBdcCdrIds: [] as number[],
            bottomSheetVisible: false,
        };
    },

    computed: {
        chart(): ReportingDonutChartData | null {
            const chart: ReportingDonutChartData = {
                title: "",
                series: [
                    {
                        id: "accepted",
                        name: this.$t("Angenommen") as string,
                        data: {
                            value: {
                                value: this.externalBdcCdrRows.filter((r) => r.accept).length,
                                onClick: this.showBottomSheetOnClick(
                                    this.externalBdcCdrRows.filter((r) => r.accept).map((r) => r.id)
                                ),
                            },
                        },
                        colorPalette: ReportingChartColorPalette.POSITIVE,
                    },
                    {
                        id: "not-accepted",
                        name: this.$t("Nicht angenommen") as string,
                        data: {
                            value: {
                                value: this.externalBdcCdrRows.filter((r) => !r.accept).length,
                                onClick: this.showBottomSheetOnClick(
                                    this.externalBdcCdrRows.filter((r) => !r.accept).map((r) => r.id)
                                ),
                            },
                        },
                        colorPalette: ReportingChartColorPalette.NEGATIVE,
                    },
                ],
            };

            if (!hasNonZeroSeries(chart)) {
                return null;
            }

            return chart;
        },

        table(): ReportingTableData | null {
            if (!this.chart) {
                return null;
            }

            return {
                headerGroups: [
                    {
                        text: this.$t("Anrufe") as string,
                        headers: [{ text: this.$t("Anrufe") as string }],
                    },
                ],
                items: getReportingTableItemsByReportingDonutChartSeries(this.chart.series),
                groupByHeaderText: this.$t("Status") as string,
            };
        },
    },

    methods: {
        hideBottomSheet() {
            this.bottomSheetVisible = false;
            this.bottomSheetExternalBdcCdrIds = [];
        },

        showBottomSheetOnClick(externalBdcCdrIds: number[]): () => void {
            return () => {
                this.bottomSheetExternalBdcCdrIds = [...new Set(externalBdcCdrIds)];
                this.bottomSheetVisible = true;
            };
        },
    },

    components: {
        ExternalBdcCdrsBottomSheet,
        ReportingDashboardDonutTile,
    },
});


import ExternalBdcCdrDataTableRow from "./ExternalBdcCdrDataTableRow.vue";
import { canViewExternalBdcCdr } from "./externalBdcCdrUtils";
import { Dealer, dealersApi } from "@/api/dealers";
import { ExternalBdcCdr, externalBdcCdrsApi } from "@/api/externalBdcCdrs";
import { User, usersApi } from "@/api/users";
import DataTable from "@/app/components/DataTable.vue";
import { DataTableHeader, DataTablePaging } from "@/app/components/dataTable";
import { PickMutable } from "@/util/types";
import Vue from "vue";

interface Item {
    readonly externalBdcCdrId: number;
    readonly externalBdcCdr: ExternalBdcCdr | null;
    readonly dealer: Dealer | null;
    readonly user: User | null;
}

export default Vue.extend({
    props: {
        externalBdcCdrIds: {
            type: Array as () => number[],
            required: true,
        },
        itemsPerPage: {
            type: Number,
            default: 25,
        },
        title: {
            type: String as () => string | null,
            default: null,
        },
    },

    data() {
        return {
            items: [] as Item[],
            loading: true,
            paging: {
                page: 1,
                pageSize: this.itemsPerPage,
                totalSize: this.externalBdcCdrIds.length,
                maxTotalSize: 1_000_000,
                maxPage: 10_000 / this.itemsPerPage,
            } as PickMutable<DataTablePaging, "page" | "totalSize">,
            searchId: 0,
        };
    },

    computed: {
        canViewExternalBdcCdr(): boolean {
            return canViewExternalBdcCdr();
        },

        headers(): DataTableHeader[] {
            return [
                {
                    text: this.$t("Beginn"),
                    width: "5%",
                },
                {
                    text: this.$t("Annahme"),
                    width: "5%",
                },
                {
                    text: this.$t("Ende"),
                    width: "5%",
                },
                {
                    text: this.$t("Ansage"),
                    width: "5%",
                },
                {
                    text: this.$t("Wartezeit"),
                    width: "5%",
                },
                {
                    text: this.$t("Gesprächsdauer"),
                    width: "5%",
                },
                {
                    text: this.$t("Grund Gesprächsende"),
                    width: "5%",
                },
                {
                    text: this.$t("Anruferkennung"),
                    width: "10%",
                },
                {
                    text: this.$t("Zielrufnummer"),
                    width: "10%",
                },
                {
                    text: this.$t("Standort"),
                    width: "20%",
                },
                {
                    text: this.$t("Agent"),
                    width: "16%",
                },
                {
                    text: this.$t("Aufzeichnung"),
                    width: "14%",
                },
            ];
        },

        pageExternalBdcCdrIds(): number[] {
            const start = (this.paging.page - 1) * this.itemsPerPage;

            return this.externalBdcCdrIds.slice(start, start + this.itemsPerPage);
        },
    },

    methods: {
        async loadItems() {
            this.paging.totalSize = 0;
            this.items = [];

            if (!this.canViewExternalBdcCdr) {
                this.loading = false;
                return;
            }

            this.loading = true;
            const pageExternalBdcCdrIds = this.pageExternalBdcCdrIds;
            const searchId = ++this.searchId;

            try {
                const [externalBdcCdrs, dealers, users] = await Promise.all([
                    externalBdcCdrsApi.getByIds(pageExternalBdcCdrIds),
                    dealersApi.list(),
                    usersApi.list(),
                ]);

                const items: Item[] = pageExternalBdcCdrIds
                    .map((externalBdcCdrId) => ({
                        externalBdcCdrId,
                        externalBdcCdr: externalBdcCdrs.find((c) => c.id === externalBdcCdrId) ?? null,
                    }))
                    .map((item) => ({
                        ...item,
                        dealer: dealers.find((d) => d.id === item.externalBdcCdr?.dealerId) ?? null,
                        user: users.find((u) => u.id === item.externalBdcCdr?.userId) ?? null,
                    }));

                if (searchId === this.searchId) {
                    this.items = items;
                    this.paging.totalSize = this.externalBdcCdrIds.length;
                    this.loading = false;
                }
            } finally {
                if (searchId === this.searchId) {
                    this.loading = false;
                }
            }
        },

        async page(paging: DataTablePaging) {
            this.paging = { ...paging };

            await this.loadItems();
        },
    },

    watch: {
        async externalBdcCdrIds() {
            this.paging.page = 1;
            this.paging.totalSize = this.externalBdcCdrIds.length;
            try {
                await this.loadItems();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },
    },

    async mounted() {
        await this.loadItems();
    },

    components: {
        DataTable,
        ExternalBdcCdrDataTableRow,
    },
});

import axios from "axios";

interface CitNowDataExchangeApi {
    uploadBillingAccountsContractsInvoicesAndRevenues(): Promise<void>;
    uploadBillingItemsByCreatedDate(createdDate: string): Promise<void>;
}

export const citNowDataExchangeApi: CitNowDataExchangeApi = {
    async uploadBillingAccountsContractsInvoicesAndRevenues() {
        await axios.post("/api/citnow-data-exchange/_upload-billing-accounts-contracts-invoices-and-revenues");
    },

    async uploadBillingItemsByCreatedDate(createdDate) {
        await axios.post("/api/citnow-data-exchange/_upload-billing-items-by-created-date", undefined, {
            params: {
                createdDate,
            },
        });
    },
};


import { BadRequest } from "@/api/errors";
import { partitionsApi, PartitionSummary } from "@/api/partitions";
import { PartitionsQuery, SelectStatementResult, SelectStatementResultValue, sqlConsoleApi } from "@/api/sqlConsole";
import CsvDownloadIcon from "@/app/components/CsvDownloadIcon.vue";
import DataTable from "@/app/components/DataTable.vue";
import { DataTablePaging } from "@/app/components/dataTable";
import { notEmpty, ValidationHelper } from "@/app/validation";
import { userSession } from "@/store/userSession";
import { PickMutable, SelectOption, SelectOptions } from "@/util/types";
import Vue from "vue";
import { DataTableHeader } from "vuetify";

export default Vue.extend({
    data() {
        const itemsPerPage = 50;
        const validationHelper = new ValidationHelper();

        return {
            itemsPerPage,
            lastSelectedIndex: -1,
            notEmpty,
            paging: {
                page: 1,
                pageSize: itemsPerPage,
                totalSize: 0,
                maxTotalSize: 1_000_000,
                maxPage: 10_000 / itemsPerPage,
            } as PickMutable<DataTablePaging, "page" | "totalSize">,
            partitionsQuery: {
                partitionIds: [] as string[],
                sql: null as string | null,
            },
            partitions: [] as PartitionSummary[],
            querying: false,
            result: null as SelectStatementResult | null,
            shiftKeyDown: false,
            sqlRules: notEmpty().and(validationHelper, "partitionQuery.sql"),
            validationHelper,
        };
    },

    computed: {
        csvData(): string[][] {
            return [
                this.resultColumns,
                ...this.resultItems.map((line) => line.map((v) => (v ?? "").toString())),
            ].filter((line) => line.length);
        },

        csvFilename(): string | undefined {
            const partitionNames = this.partitions
                .filter((p) => this.partitionsQuery.partitionIds.includes(p.id))
                .map((p) => p.name);

            if (!partitionNames.length) {
                return undefined;
            }

            return `${partitionNames.join(" - ")}.csv`;
        },

        partitionOptions(): SelectOptions {
            const activePartitions = this.partitions
                .filter((p) => p.mysqlDatabaseId && p.mysqlUser && p.mysqlDatabaseName && p.active)
                .map((p) => ({
                    text: `${p.id} (${p.name})`,
                    value: p.id,
                }))
                .sort((a, b) => a.text.localeCompare(b.text, userSession.locale));

            const inactivePartitions = this.partitions
                .filter((p) => p.mysqlDatabaseId && p.mysqlUser && p.mysqlDatabaseName && !p.active)
                .map((p) => ({
                    text: `${p.id} (${p.name})`,
                    value: p.id,
                }))
                .sort((a, b) => a.text.localeCompare(b.text, userSession.locale));

            return inactivePartitions.length
                ? [...activePartitions, { header: this.$t("inaktiv") }, ...inactivePartitions]
                : activePartitions;
        },

        resultColumns(): string[] {
            if (!this.result?.length) {
                return [];
            }

            return Object.keys(this.result[0]);
        },

        resultItems(): SelectStatementResultValue[][] {
            if (!this.result?.length) {
                return [];
            }

            return this.result.map((line) => this.resultColumns.map((c) => line[c]));
        },

        resultTableHeaders(): DataTableHeader[] {
            return this.resultColumns.map((c) => ({
                text: c,
                value: "",
            }));
        },

        resultTableItems(): SelectStatementResultValue[][] {
            const start = (this.paging.page - 1) * this.itemsPerPage;

            return this.resultItems.slice(start, start + this.itemsPerPage);
        },
    },

    watch: {
        "partitionsQuery.partitionIds"(newIds: string[], oldIds: string[]) {
            // ignore in-place editing
            if (newIds === oldIds) {
                return;
            }

            // deselected item?
            if (oldIds.some((i) => !newIds.includes(i))) {
                this.lastSelectedIndex = -1;
                return;
            }

            // selected more than one item?
            const addedIds = newIds.filter((i) => !oldIds.includes(i));
            if (addedIds.length !== 1) {
                return;
            }

            const selectedIndex = this.partitionOptions.findIndex((o) => (o as SelectOption).value === addedIds[0]);
            if (this.shiftKeyDown && this.lastSelectedIndex >= 0) {
                for (
                    let i = Math.min(selectedIndex, this.lastSelectedIndex);
                    i <= Math.max(selectedIndex, this.lastSelectedIndex);
                    i++
                ) {
                    const id = (this.partitionOptions[i] as SelectOption).value;
                    if (id && !this.partitionsQuery.partitionIds.includes(id)) {
                        this.partitionsQuery.partitionIds.push(id);
                    }
                }
            } else {
                this.lastSelectedIndex = selectedIndex;
            }
        },
    },

    methods: {
        async page(paging: DataTablePaging) {
            this.paging = { ...paging };
        },

        async select() {
            if (!(this.$refs.form as any).validate()) {
                return;
            }

            this.paging.totalSize = 0;
            this.paging.page = 1;
            this.result = null;
            this.querying = true;

            try {
                this.result = await sqlConsoleApi.executePartitionsSelect(this.partitionsQuery as PartitionsQuery);

                this.paging.totalSize = this.result?.length ?? 0;
            } catch (e) {
                if (!(e instanceof BadRequest)) {
                    throw e;
                }

                this.validationHelper.update(e, this.$refs.form);
            } finally {
                this.querying = false;
            }
        },

        onKeyDown(e: KeyboardEvent): void {
            if (e.keyCode === 16 /* DOM_VK_SHIFT */) {
                this.shiftKeyDown = true;
            }
        },

        onKeyUp(e: KeyboardEvent): void {
            if (e.keyCode === 16 /* DOM_VK_SHIFT */) {
                this.shiftKeyDown = false;
            }
        },
    },

    async mounted() {
        addEventListener("keydown", this.onKeyDown);
        addEventListener("keyup", this.onKeyUp);
        this.partitions = await partitionsApi.list();
    },

    beforeDestroy() {
        removeEventListener("keydown", this.onKeyDown);
        removeEventListener("keyup", this.onKeyUp);
    },

    components: {
        CsvDownloadIcon,
        DataTable,
    },
});

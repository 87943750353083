var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('external-bdc-report-page',{attrs:{"no-data":!_vm.table,"time-interval":_vm.ongoingTimeInterval,"time-intervals":[
        _vm.OngoingTimeInterval.FIVE_MINUTES,
        _vm.OngoingTimeInterval.TEN_MINUTES,
        _vm.OngoingTimeInterval.FIFTEEN_MINUTES,
        _vm.OngoingTimeInterval.THIRTY_MINUTES,
        _vm.OngoingTimeInterval.SIXTY_MINUTES,
        _vm.OngoingTimeInterval.DATE,
        _vm.OngoingTimeInterval.WEEK,
        _vm.OngoingTimeInterval.MONTH,
        _vm.OngoingTimeInterval.QUARTER,
    ],"time-range":_vm.timeRange,"time-zone":_vm.timeZone,"with-begin-ongoing-time-slot":_vm.ongoingTimeInterval},on:{"update:timeInterval":function($event){_vm.ongoingTimeInterval=$event},"update:time-interval":function($event){_vm.ongoingTimeInterval=$event},"loaded":(v) => (_vm.result = v)},scopedSlots:_vm._u([{key:"default",fn:function({ pageTitle }){return [(_vm.table)?_c('report-table-card',{staticClass:"mb-2",attrs:{"csv-download":"","page-title":pageTitle,"table":_vm.table}}):_vm._e(),(_vm.incomingChart)?_c('reporting-time-series-chart-card',{staticClass:"mb-2",attrs:{"chart":_vm.incomingChart,"chart-percentage-max":1,"filename-prefix":pageTitle,"group-by-header-text":_vm.$t('Zeitraum')}}):_vm._e(),(_vm.agentChart)?_c('reporting-time-series-chart-card',{staticClass:"mb-2",attrs:{"chart":_vm.agentChart,"chart-percentage-max":1,"filename-prefix":pageTitle,"group-by-header-text":_vm.$t('Zeitraum')}}):_vm._e(),(_vm.durationChart)?_c('reporting-time-series-chart-card',{staticClass:"mb-2",attrs:{"chart":_vm.durationChart,"chart-percentage-max":1,"filename-prefix":pageTitle,"group-by-header-text":_vm.$t('Zeitraum')}}):_vm._e(),(_vm.waitedChart)?_c('reporting-time-series-chart-card',{attrs:{"chart":_vm.waitedChart,"chart-percentage-max":1,"filename-prefix":pageTitle,"group-by-header-text":_vm.$t('Zeitraum')}}):_vm._e()]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }
import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export enum PaymentMethod {
    BANK_TRANSFER = "BANK_TRANSFER",
    SEPA_B2B_DIRECT_DEBIT = "SEPA_B2B_DIRECT_DEBIT",
}

export interface SepaB2bDirectDebitPaymentInformation {
    readonly iban: string;
    readonly bic: string;
    readonly mandateReference: string;
    readonly signatureDate: string;
}

export interface BillingAccountForm {
    readonly companyName: string;
    readonly address1: string | null;
    readonly address2: string | null;
    readonly zip: string;
    readonly city: string;
    readonly state: string | null;
    readonly country: string;
    readonly vatNumber: string | null;
    readonly companyRegistrationNumber: string | null;
    readonly receivers: string[];
    readonly paymentMethod: PaymentMethod;
    readonly sepaB2bDirectDebitPaymentInformation: SepaB2bDirectDebitPaymentInformation | null;
}

export interface BillingAccount {
    readonly id: number;
    readonly created: Date;
    readonly updated: Date;
    readonly companyName: string;
    readonly address1: string | null;
    readonly address2: string;
    readonly zip: string;
    readonly city: string;
    readonly state: string | null;
    readonly country: string;
    readonly vatNumber: string | null;
    readonly companyRegistrationNumber: string | null;
    readonly receivers: string[];
    readonly paymentMethod: PaymentMethod;
    readonly sepaB2bDirectDebitPaymentInformation: SepaB2bDirectDebitPaymentInformation | null;
}

interface BillingAccountsApi {
    add(billingAccountForm: BillingAccountForm): Promise<void>;
    checkIban(iban: string): Promise<boolean>;
    delete(id: number): Promise<void>;
    edit(id: number, billingAccountForm: BillingAccountForm): Promise<void>;
    getById(id: number): Promise<BillingAccount | null>;
    list(): Promise<BillingAccount[]>;
}

export const billingAccountsApi: BillingAccountsApi = {
    async add(billingAccountForm: BillingAccountForm) {
        await axios.post("/api/billing-accounts", billingAccountForm);
    },

    async checkIban(iban: string) {
        return (
            await axios.get("/api/billing-accounts/_check-iban", {
                params: {
                    iban,
                },
            })
        ).data;
    },

    async delete(id: number) {
        await axios.delete(`/api/billing-accounts/${id}`);
    },

    async edit(id: number, billingAccountForm: BillingAccountForm) {
        await axios.put(`/api/billing-accounts/${id}`, billingAccountForm);
    },

    async getById(id: number) {
        return cloneObject((await axios.get(`/api/billing-accounts/${id}`)).data);
    },

    async list() {
        return cloneObject((await axios.get("/api/billing-accounts")).data);
    },
};


import { DateRange } from "./dateRangePicker";
import {
    addDuration,
    endOf,
    formatLocalDate,
    getDate,
    getFirstDayOfWeek,
    startOf,
    toDateObject,
    UnitOfTime,
} from "@/util/dateTimeUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        maxDate: {
            type: String as () => string | null,
            required: false,
            default: null,
        },
        maxDuration: {
            type: Number as () => number | null,
            required: false,
            default: null,
        },
        maxDurationUnitOfTime: {
            type: Number as () => UnitOfTime,
            required: false,
            default: () => UnitOfTime.DAY,
        },
        minDate: {
            type: String as () => string | null,
            required: false,
            default: null,
        },
        rules: {
            type: Array as () => Function[] | null,
            required: false,
            default: null,
        },
        timeZone: {
            type: String,
            required: true,
        },
        value: {
            type: Object as () => DateRange | null,
            required: false,
            default: null,
        },
    },

    data() {
        return {
            menu: false,
            range: null as [string, string] | null,
            internalRange: null as [string, string] | null,
            selecting: false,
        };
    },

    computed: {
        firstDayOfWeek(): number {
            return getFirstDayOfWeek(this.$i18n.locale);
        },

        effectiveMaxDate(): string | null {
            return this.getEarliest(
                this.maxDate,
                this.selecting ? this.getToMaxByMaxDuration(this.internalRange![0]) : null
            );
        },

        effectiveMinDate(): string | null {
            return this.getLatest(
                this.minDate,
                this.selecting ? this.getFromMinByMaxDuration(this.internalRange![0]) : null
            );
        },

        rulez(): Function[] {
            const maxDurationRule = () => {
                if (!this.value) {
                    return true;
                }

                if (this.value.to === this.getEarliest(this.value.to, this.getToMaxByMaxDuration(this.value.from))) {
                    return true;
                }

                return this.$t("Der ausgewählte Zeitraum ist zu lang.");
            };

            return [maxDurationRule, ...(this.rules ?? []).map((r) => () => r(this.value))];
        },

        valueText(): string | null {
            if (!this.value) {
                return null;
            }

            const from = formatLocalDate(this.value.from, this.$i18n.locale, "S");
            const to = formatLocalDate(this.value.to, this.$i18n.locale, "S");

            return this.$t("{from} bis {to}", { from, to }) as string;
        },
    },

    methods: {
        clear() {
            this.range = this.internalRange = null;
            this.$emit("change", null);
            this.handleInput();
        },

        getEarliest(...localDates: (string | null)[]): string | null {
            const earliest = localDates
                .filter((localDate) => localDate)
                .map((localDate) => toDateObject(this.timeZone, localDate!))
                .reduce(
                    (min, date) => (date.getTime() <= (min?.getTime() ?? Infinity) ? date : min),
                    null as Date | null
                );

            if (!earliest) {
                return null;
            }

            return getDate(earliest, this.timeZone);
        },

        getFromMinByMaxDuration(to: string): string | null {
            if (this.maxDuration === null) {
                return null;
            }

            return [to]
                .map((v) => toDateObject(this.timeZone, v))
                .map((v) => startOf(v, this.timeZone, this.$i18n.locale, this.maxDurationUnitOfTime)) // start of current period
                .map((v) => addDuration(v, this.timeZone, -1 * this.maxDuration! + 1, this.maxDurationUnitOfTime)) // in the middle of min period
                .map((v) => startOf(v, this.timeZone, this.$i18n.locale, this.maxDurationUnitOfTime)) // start of min period
                .map((v) => getDate(v, this.timeZone))
                .pop()!;
        },

        getLatest(...localDates: (string | null)[]): string | null {
            const latest = localDates
                .filter((localDate) => localDate)
                .map((localDate) => toDateObject(this.timeZone, localDate!))
                .reduce(
                    (max, date) => ((max?.getTime() ?? -Infinity) <= date.getTime() ? date : max),
                    null as Date | null
                );

            if (!latest) {
                return null;
            }

            return getDate(latest, this.timeZone);
        },

        getToMaxByMaxDuration(from: string): string | null {
            if (this.maxDuration === null) {
                return null;
            }

            return [from]
                .map((v) => toDateObject(this.timeZone, v))
                .map((v) => endOf(v, this.timeZone, this.$i18n.locale, this.maxDurationUnitOfTime)) // end of current period
                .map((v) => addDuration(v, this.timeZone, this.maxDuration! - 1, this.maxDurationUnitOfTime)) // in the middle of max period
                .map((v) => endOf(v, this.timeZone, this.$i18n.locale, this.maxDurationUnitOfTime)) // end of max period
                .map((v) => getDate(v, this.timeZone))
                .pop()!;
        },

        handleInput() {
            if (!this.range) {
                this.$emit("input", null);
                return;
            }

            this.$emit("input", {
                from: this.getEarliest(...this.range)!,
                to: this.getLatest(...this.range)!,
            } as DateRange);
        },

        onClickDate(date: string) {
            if (this.selecting) {
                this.selecting = false;
                this.range = this.internalRange = [this.internalRange![0], date];
                this.handleInput();
            } else {
                this.selecting = true;
                this.internalRange = [date, date];
            }
        },

        onHoverDate(date: string) {
            if (this.selecting) {
                this.internalRange = [this.internalRange![0], date];
            }
        },
    },

    watch: {
        range() {
            (this.$refs.menu as any).save(this.range);
        },

        menu() {
            if (!this.menu && this.selecting) {
                this.selecting = false;
                this.internalRange = this.range;
            }
        },

        value: {
            immediate: true,
            handler() {
                this.range = this.internalRange = this.value ? [this.value.from, this.value.to] : null;
            },
        },
    },
});

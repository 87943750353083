import { ReportingChartColorPalette } from "./reportingChart";

export interface ReportingScatterChartAxis {
    readonly id: string;
    readonly name?: string;
    readonly show?: boolean;
    readonly isPercentage?: boolean;
    readonly formatter?: ReportingScatterChartSeriesValueFormatter;
    readonly min?: number;
    readonly max?: number;
    readonly interval?: number;
}

export interface ReportingScatterChartAxisMarker {
    readonly value: number;
    readonly fillArea?: "ABOVE" | "BELOW" | "LEFT" | "RIGHT";
    readonly opacity?: number;
    readonly color?: string;
}

export interface ReportingScatterChartCategory {
    readonly name: string;
    readonly description?: string;
}

export interface ReportingScatterChartData {
    readonly title: string;
    readonly subtitle?: string;
    readonly xAxis: ReportingScatterChartAxis[];
    readonly yAxis: ReportingScatterChartAxis[];
    readonly categories: ReportingScatterChartCategory[];
    readonly series: ReportingScatterChartSeries[];
    readonly seriesDataTooltipHeader?: string;
    readonly seriesAdditionalTooltipDataTooltipHeaders?: string[];
}

export interface ReportingScatterChartSeries {
    readonly id: string;
    readonly name: string;
    readonly tooltip?: string;
    readonly selected?: boolean;
    readonly data: ReportingScatterChartSeriesData;
    readonly color?: string;
    readonly colorPalette?: ReportingChartColorPalette;
    readonly additionalTooltipData?: ReportingScatterChartSeriesData[];
}

export interface ReportingScatterChartSeriesData {
    readonly values: ReportingScatterChartSeriesValue[];
    readonly xAxisId: string;
    readonly yAxisId: string;
    readonly xAxisMarkers?: ReportingScatterChartAxisMarker[];
    readonly yAxisMarkers?: ReportingScatterChartAxisMarker[];
}

export interface ReportingScatterChartSeriesValue {
    readonly xValue: number;
    readonly yValue: number;
    readonly onClick?: () => Promise<void> | void;
    readonly color?: string;
}

export type ReportingScatterChartSeriesValueFormatter = (
    value: number | undefined,
    isPercentage: boolean,
    context: "LABEL" | "TOOLTIP"
) => string | null;

export function isNonZeroSeries(series: ReportingScatterChartSeries): boolean {
    return series.data.values.some((v) => v.xValue !== 0 || v.yValue !== 0);
}

export function hasNonZeroSeries(chart: ReportingScatterChartData): boolean {
    return chart.series.some(isNonZeroSeries);
}

import axios from "axios";

export interface DealerStatistic {
    readonly partitionId: string;
    readonly dealerId: string;
    readonly name: string;
    readonly activeContracts: number;
    readonly softwareLicenseDaysCovered: number;
    readonly onlineUsers: number;
    readonly totalUsers: number;
    readonly deletedInventoryVehicles: number;
    readonly totalInventoryVehicles: number;
    readonly deletedContacts: number;
    readonly totalContacts: number;
    readonly casesOpen: number;
    readonly casesAssigned: number;
    readonly opportunitiesOpen: number;
    readonly opportunitiesAssigned: number;
    readonly emergencyIncidentsOpen: number;
    readonly clickToCallMinutes: number;
    readonly clickToCallMinutesCovered: number;
    readonly callTrackingMinutes: number;
    readonly callTrackingMinutesCovered: number;
    readonly outgoingSms: number;
    readonly outgoingSmsCovered: number;
    readonly externalBdcMinutes: number;
    readonly externalBdcMinutesCovered: number;
    readonly incomingWhatsAppMessages: number;
    readonly outgoingWhatsAppMessages: number;
    readonly videochats: number;
    readonly whatsAppNumbers: number;
    readonly activeInventoryVehicleInquiries: number;
    readonly caseCreationStatistic: IssueCreationStatistic;
    readonly opportunityCreationStatistic: IssueCreationStatistic;
    readonly emergencyIncidentCreationStatistic: IssueCreationStatistic;
    readonly caseSentimentStatistic: IssueSentimentStatistic;
    readonly opportunitySentimentStatistic: IssueSentimentStatistic;
}

export interface IssueCreationStatistic {
    readonly createdBySystem: number;
    readonly createdByCtUser: number;
    readonly createdByPartitionUser: number;
}

export interface IssueSentimentStatistic {
    readonly happy: number;
    readonly neutral: number;
    readonly unhappy: number;
    readonly unknown: number;
}

interface DealerStatisticsApi {
    getDealerStatistics(from: string, to: string): Promise<DealerStatistic[]>;
}

export const dealerStatisticsApi: DealerStatisticsApi = {
    async getDealerStatistics(from, to) {
        return (await axios.get("/api/dealer-statistics", { params: { from, to } })).data;
    },
};


import { getFileBasename } from "./downloadUtils";
import ReportingTable from "./table/ReportingTable.vue";
import { ReportingTableData } from "./table/reportingTable";
import Vue from "vue";

export default Vue.extend({
    props: {
        csvDownload: {
            type: Boolean,
            default: false,
        },
        pageTitle: {
            type: String as () => string | null,
            required: false,
            default: null,
        },
        table: {
            type: Object as () => ReportingTableData,
            required: true,
        },
    },

    computed: {
        csvFilename(): string | undefined {
            const basename = getFileBasename(this.pageTitle, this.table.groupByHeaderText);

            return basename ? `${basename}.csv` : undefined;
        },
    },

    components: {
        ReportingTable,
    },
});

import { $t } from "@/app/i18n";
import { configStore } from "@/store/config";
import { userSession } from "@/store/userSession";
import { getDayOfWeekName, getDaysOfWeek, getMonthName } from "@/util/dateTimeUtils";
import moment from "moment-timezone";

export type IntervalOutputFormat = "L" | "S";

export interface IndividualDurationInterval {
    readonly key: string;
    readonly label: string;
    readonly from: number;
    readonly to?: number;
}

export enum OngoingTimeInterval {
    ONE_SECOND = "1_SECOND",
    FIVE_SECONDS = "5_SECONDS",
    TEN_SECONDS = "10_SECONDS",
    FIFTEEN_SECONDS = "15_SECONDS",
    THIRTY_SECONDS = "30_SECONDS",
    ONE_MINUTE = "1_MINUTE",
    FIVE_MINUTES = "5_MINUTES",
    TEN_MINUTES = "10_MINUTES",
    FIFTEEN_MINUTES = "15_MINUTES",
    THIRTY_MINUTES = "30_MINUTES",
    SIXTY_MINUTES = "SIXTY_MINUTES",
    DATE = "DATE",
    WEEK = "WEEK",
    MONTH = "MONTH",
    QUARTER = "QUARTER",
    YEAR = "YEAR",
}

export enum RecurringTimeInterval {
    HOUR_OF_DAY = "HOUR_OF_DAY",
    DAY_OF_WEEK = "DAY_OF_WEEK",
    DAY_OF_MONTH = "DAY_OF_MONTH",
    DAY_OF_YEAR = "DAY_OF_YEAR",
    WEEK_OF_YEAR = "WEEK_OF_YEAR",
    MONTH_OF_YEAR = "MONTH_OF_YEAR",
    QUARTER_OF_YEAR = "QUARTER_OF_YEAR",
}

export enum RelativeTimeInterval {
    TODAY = "TODAY",
    YESTERDAY = "YESTERDAY",
    SEVEN_DAYS_AGO = "SEVEN_DAYS_AGO",
    CURRENT_WEEK = "CURRENT_WEEK",
    CURRENT_MONTH = "CURRENT_MONTH",
    CURRENT_QUARTER = "CURRENT_QUARTER",
    CURRENT_YEAR = "CURRENT_YEAR",
    LAST_SEVEN_DAYS = "LAST_SEVEN_DAYS",
    LAST_FOURTEEN_DAYS = "LAST_FOURTEEN_DAYS",
    LAST_TEN_WEEKS = "LAST_TEN_WEEKS",
    PREVIOUS_DAY = "PREVIOUS_DAY",
    PREVIOUS_WEEK = "PREVIOUS_WEEK",
    PREVIOUS_MONTH = "PREVIOUS_MONTH",
    PREVIOUS_QUARTER = "PREVIOUS_QUARTER",
    PREVIOUS_YEAR = "PREVIOUS_YEAR",
}

export function toIndividualDurationIntervalKey(interval: Pick<IndividualDurationInterval, "from" | "to">): string {
    return JSON.stringify({
        from: interval.from,
        to: interval.to,
    });
}

export function isInIndividualDurationInterval(duration: number | null, interval: IndividualDurationInterval): boolean {
    if (duration !== null) {
        return interval.from <= duration && (interval.to === undefined || duration < interval.to);
    } else {
        return interval.to === undefined;
    }
}

export function getIndividualDurationIntervalKeyByDuration(
    duration: number,
    intervals: readonly IndividualDurationInterval[]
): string | null {
    const interval = intervals.find((i) => isInIndividualDurationInterval(duration, i));

    if (!interval) {
        return null;
    }

    return toIndividualDurationIntervalKey(interval);
}

export function getIndividualDurationIntervalKey(
    from: Date,
    to: Date,
    intervals: readonly IndividualDurationInterval[]
): string | null {
    return getIndividualDurationIntervalKeyByDuration((to.getTime() - from.getTime()) / 1000, intervals);
}

export function getIndividualDurationIntervalLabel(interval: IndividualDurationInterval): string {
    return $t(interval.label) as string;
}

export function getIndividualDurationIntervalLabelByKey(
    key: string | null,
    intervals: readonly IndividualDurationInterval[]
): string | null {
    const interval = intervals.find((i) => i.key === key);

    if (!interval) {
        return null;
    }

    return getIndividualDurationIntervalLabel(interval);
}

function setMomentToStartOfOngoingTimeSlot(m: moment.Moment, ongoingTimeInterval: OngoingTimeInterval): boolean {
    if (ongoingTimeInterval === OngoingTimeInterval.ONE_SECOND) {
        m.startOf("second");
    } else if (ongoingTimeInterval === OngoingTimeInterval.FIVE_SECONDS) {
        m.startOf("second").subtract(m.seconds() % 5, "seconds");
    } else if (ongoingTimeInterval === OngoingTimeInterval.TEN_SECONDS) {
        m.startOf("second").subtract(m.seconds() % 10, "seconds");
    } else if (ongoingTimeInterval === OngoingTimeInterval.FIFTEEN_SECONDS) {
        m.startOf("second").subtract(m.seconds() % 15, "seconds");
    } else if (ongoingTimeInterval === OngoingTimeInterval.THIRTY_SECONDS) {
        m.startOf("second").subtract(m.seconds() % 30, "seconds");
    } else if (ongoingTimeInterval === OngoingTimeInterval.ONE_MINUTE) {
        m.startOf("minute");
    } else if (ongoingTimeInterval === OngoingTimeInterval.FIVE_MINUTES) {
        m.startOf("minute").subtract(m.minutes() % 5, "minutes");
    } else if (ongoingTimeInterval === OngoingTimeInterval.TEN_MINUTES) {
        m.startOf("minute").subtract(m.minutes() % 10, "minutes");
    } else if (ongoingTimeInterval === OngoingTimeInterval.FIFTEEN_MINUTES) {
        m.startOf("minute").subtract(m.minutes() % 15, "minutes");
    } else if (ongoingTimeInterval === OngoingTimeInterval.THIRTY_MINUTES) {
        m.startOf("minute").subtract(m.minutes() % 30, "minutes");
    } else if (ongoingTimeInterval === OngoingTimeInterval.SIXTY_MINUTES) {
        m.startOf("hour");
    } else if (ongoingTimeInterval === OngoingTimeInterval.DATE) {
        m.startOf("day");
    } else if (ongoingTimeInterval === OngoingTimeInterval.WEEK) {
        m.startOf("week");
    } else if (ongoingTimeInterval === OngoingTimeInterval.MONTH) {
        m.startOf("month");
    } else if (ongoingTimeInterval === OngoingTimeInterval.QUARTER) {
        m.startOf("quarter");
    } else if (ongoingTimeInterval === OngoingTimeInterval.YEAR) {
        m.startOf("year");
    } else {
        return false;
    }

    return true;
}

function setMomentToNextOngoingTimeSlot(m: moment.Moment, ongoingTimeInterval: OngoingTimeInterval): boolean {
    if (ongoingTimeInterval === OngoingTimeInterval.ONE_SECOND) {
        m.add(1, "second");
    } else if (ongoingTimeInterval === OngoingTimeInterval.FIVE_SECONDS) {
        m.add(5, "seconds");
    } else if (ongoingTimeInterval === OngoingTimeInterval.TEN_SECONDS) {
        m.add(10, "seconds");
    } else if (ongoingTimeInterval === OngoingTimeInterval.FIFTEEN_SECONDS) {
        m.add(15, "seconds");
    } else if (ongoingTimeInterval === OngoingTimeInterval.THIRTY_SECONDS) {
        m.add(30, "seconds");
    } else if (ongoingTimeInterval === OngoingTimeInterval.ONE_MINUTE) {
        m.add(1, "minute");
    } else if (ongoingTimeInterval === OngoingTimeInterval.FIVE_MINUTES) {
        m.add(5, "minutes");
    } else if (ongoingTimeInterval === OngoingTimeInterval.TEN_MINUTES) {
        m.add(10, "minutes");
    } else if (ongoingTimeInterval === OngoingTimeInterval.FIFTEEN_MINUTES) {
        m.add(15, "minutes");
    } else if (ongoingTimeInterval === OngoingTimeInterval.THIRTY_MINUTES) {
        m.add(30, "minutes");
    } else if (ongoingTimeInterval === OngoingTimeInterval.SIXTY_MINUTES) {
        m.add(1, "hour");
    } else if (ongoingTimeInterval === OngoingTimeInterval.DATE) {
        m.add(1, "day");
    } else if (ongoingTimeInterval === OngoingTimeInterval.WEEK) {
        m.add(1, "week");
    } else if (ongoingTimeInterval === OngoingTimeInterval.MONTH) {
        m.add(1, "month");
    } else if (ongoingTimeInterval === OngoingTimeInterval.QUARTER) {
        m.add(1, "quarter");
    } else if (ongoingTimeInterval === OngoingTimeInterval.YEAR) {
        m.add(1, "year");
    } else {
        return false;
    }

    return true;
}

export function getOngoingTimeSlot(
    date: Date,
    ongoingTimeInterval: OngoingTimeInterval,
    timeZone: string
): Date | null {
    const m = moment(date)
        .tz(timeZone)
        .locale(userSession.locale);

    if (!setMomentToStartOfOngoingTimeSlot(m, ongoingTimeInterval)) {
        return null;
    }

    return m.toDate();
}

export function getNextOngoingTimeSlot(
    date: Date,
    ongoingTimeInterval: OngoingTimeInterval,
    timeZone: string
): Date | null {
    const m = moment(date)
        .tz(timeZone)
        .locale(userSession.locale);

    if (!setMomentToStartOfOngoingTimeSlot(m, ongoingTimeInterval)) {
        return null;
    }

    if (!setMomentToNextOngoingTimeSlot(m, ongoingTimeInterval)) {
        return null;
    }

    return m.toDate();
}

export function getEndOfOngoingTimeSlot(
    date: Date,
    ongoingTimeInterval: OngoingTimeInterval,
    timeZone: string
): Date | null {
    const m = moment(date)
        .tz(timeZone)
        .locale(userSession.locale);

    if (!setMomentToStartOfOngoingTimeSlot(m, ongoingTimeInterval)) {
        return null;
    }

    if (!setMomentToNextOngoingTimeSlot(m, ongoingTimeInterval)) {
        return null;
    }

    m.subtract(1, "millisecond");

    return m.toDate();
}

function getOngoingTimeSlotKeyByMoment(m: moment.Moment): string {
    return m.toDate().toISOString();
}

export function getOngoingTimeSlotKey(
    date: Date,
    ongoingTimeInterval: OngoingTimeInterval,
    timeZone: string
): string | null {
    const m = moment(date)
        .tz(timeZone)
        .locale(userSession.locale);

    if (!setMomentToStartOfOngoingTimeSlot(m, ongoingTimeInterval)) {
        return null;
    }

    return getOngoingTimeSlotKeyByMoment(m);
}

export function getOngoingTimeSlotKeys(
    from: Date,
    to: Date,
    ongoingTimeInterval: OngoingTimeInterval,
    timeZone: string,
    includeTo?: boolean
): string[] {
    const toTimeSlotKey = getOngoingTimeSlotKey(to, ongoingTimeInterval, timeZone);

    if (!toTimeSlotKey || from.getTime() > to.getTime()) {
        return [];
    }

    const endTime = getNextOngoingTimeSlot(to, ongoingTimeInterval, timeZone)!.getTime();

    const keys: string[] = [];

    const m = moment(from)
        .tz(timeZone)
        .locale(userSession.locale);

    setMomentToStartOfOngoingTimeSlot(m, ongoingTimeInterval);

    for (
        let slotDate = m.toDate(), key = getOngoingTimeSlotKeyByMoment(m);
        key && key !== toTimeSlotKey && slotDate.getTime() <= endTime;
        setMomentToNextOngoingTimeSlot(m, ongoingTimeInterval),
            slotDate = m.toDate(),
            key = getOngoingTimeSlotKeyByMoment(m)
    ) {
        keys.push(key);
    }

    if (includeTo && (!keys.length || keys[keys.length - 1] !== toTimeSlotKey)) {
        keys.push(toTimeSlotKey);
    }

    return keys;
}

export function getOngoingTimeSlotLabel(
    date: Date,
    ongoingTimeInterval: OngoingTimeInterval,
    timeZone: string,
    format: IntervalOutputFormat
) {
    let momentFormat: string | null = null;

    if (ongoingTimeInterval === OngoingTimeInterval.ONE_SECOND) {
        momentFormat = format === "L" ? "LLL" : "LT";
    } else if (ongoingTimeInterval === OngoingTimeInterval.FIVE_SECONDS) {
        momentFormat = format === "L" ? "LLL" : "LT";
    } else if (ongoingTimeInterval === OngoingTimeInterval.TEN_SECONDS) {
        momentFormat = format === "L" ? "LLL" : "LT";
    } else if (ongoingTimeInterval === OngoingTimeInterval.FIFTEEN_SECONDS) {
        momentFormat = format === "L" ? "LLL" : "LT";
    } else if (ongoingTimeInterval === OngoingTimeInterval.THIRTY_SECONDS) {
        momentFormat = format === "L" ? "LLL" : "LT";
    } else if (ongoingTimeInterval === OngoingTimeInterval.ONE_MINUTE) {
        momentFormat = format === "L" ? "LLL" : "LT";
    } else if (ongoingTimeInterval === OngoingTimeInterval.FIVE_MINUTES) {
        momentFormat = format === "L" ? "LLL" : "LT";
    } else if (ongoingTimeInterval === OngoingTimeInterval.TEN_MINUTES) {
        momentFormat = format === "L" ? "LLL" : "LT";
    } else if (ongoingTimeInterval === OngoingTimeInterval.FIFTEEN_MINUTES) {
        momentFormat = format === "L" ? "LLL" : "LT";
    } else if (ongoingTimeInterval === OngoingTimeInterval.THIRTY_MINUTES) {
        momentFormat = format === "L" ? "LLL" : "LT";
    } else if (ongoingTimeInterval === OngoingTimeInterval.SIXTY_MINUTES) {
        momentFormat = format === "L" ? "LLL" : "LT";
    } else if (ongoingTimeInterval === OngoingTimeInterval.DATE) {
        momentFormat = format === "L" ? "L" : ($t("DD.MM") as string);
    } else if (ongoingTimeInterval === OngoingTimeInterval.WEEK) {
        momentFormat = format === "L" ? ($t("Wo [Woche] GGGG") as string) : "Wo";
    } else if (ongoingTimeInterval === OngoingTimeInterval.MONTH) {
        momentFormat = format === "L" ? ($t("MMM YYYY") as string) : "MMM";
    } else if (ongoingTimeInterval === OngoingTimeInterval.QUARTER) {
        momentFormat = format === "L" ? ($t("Qo [Quartal] YYYY") as string) : "Qo";
    } else if (ongoingTimeInterval === OngoingTimeInterval.YEAR) {
        momentFormat = format === "L" ? "YYYY" : "YY";
    } else {
        return null;
    }

    return moment(date)
        .tz(timeZone)
        .locale(userSession.locale)
        .format(momentFormat);
}

export function getOngoingTimeSlotLabelByKey(
    key: string | null,
    ongoingTimeInterval: OngoingTimeInterval,
    timeZone: string,
    format: IntervalOutputFormat
): string | null {
    if (key === null) {
        return null;
    }

    return getOngoingTimeSlotLabel(new Date(key), ongoingTimeInterval, timeZone, format);
}

export function getRecurringTimeSlot(
    date: Date,
    recurringTimeInterval: RecurringTimeInterval,
    timeZone: string
): number {
    const dateInTimezone = moment(date)
        .tz(timeZone)
        .locale(userSession.locale)
        .startOf("minute");

    if (recurringTimeInterval === RecurringTimeInterval.HOUR_OF_DAY) {
        return dateInTimezone.hour();
    } else if (recurringTimeInterval === RecurringTimeInterval.DAY_OF_WEEK) {
        return dateInTimezone.day();
    } else if (recurringTimeInterval === RecurringTimeInterval.DAY_OF_MONTH) {
        return dateInTimezone.date();
    } else if (recurringTimeInterval === RecurringTimeInterval.DAY_OF_YEAR) {
        return dateInTimezone.dayOfYear();
    } else if (recurringTimeInterval === RecurringTimeInterval.WEEK_OF_YEAR) {
        return dateInTimezone.week();
    } else if (recurringTimeInterval === RecurringTimeInterval.MONTH_OF_YEAR) {
        return dateInTimezone.month();
    } else if (recurringTimeInterval === RecurringTimeInterval.QUARTER_OF_YEAR) {
        return dateInTimezone.quarter();
    } else {
        return 0;
    }
}

export function getRecurringTimeSlotKey(
    date: Date,
    recurringTimeInterval: RecurringTimeInterval,
    timeZone: string
): number {
    return getRecurringTimeSlot(date, recurringTimeInterval, timeZone)!;
}

export function getRecurringTimeSlotKeys(recurringTimeInterval: RecurringTimeInterval): number[] {
    if (recurringTimeInterval === RecurringTimeInterval.HOUR_OF_DAY) {
        return [...Array(24).keys()];
    } else if (recurringTimeInterval === RecurringTimeInterval.DAY_OF_WEEK) {
        const daysOfWeek = getDaysOfWeek(configStore.configuration.defaultLocale, userSession.locale);

        return daysOfWeek.map((dayOfWeek) => Number.parseInt(dayOfWeek.value));
    } else if (recurringTimeInterval === RecurringTimeInterval.DAY_OF_MONTH) {
        return [...Array(32).keys()].slice(1);
    } else if (recurringTimeInterval === RecurringTimeInterval.DAY_OF_YEAR) {
        return [...Array(366).keys()];
    } else if (recurringTimeInterval === RecurringTimeInterval.WEEK_OF_YEAR) {
        return [...Array(53).keys()];
    } else if (recurringTimeInterval === RecurringTimeInterval.MONTH_OF_YEAR) {
        return [...Array(12).keys()];
    } else if (recurringTimeInterval === RecurringTimeInterval.QUARTER_OF_YEAR) {
        return [1, 2, 3, 4];
    } else {
        return [];
    }
}

export function getRecurringTimeSlotLabel(key: number, recurringTimeInterval: RecurringTimeInterval): string | null {
    if (recurringTimeInterval === RecurringTimeInterval.HOUR_OF_DAY) {
        return key.toString();
    } else if (recurringTimeInterval === RecurringTimeInterval.DAY_OF_WEEK) {
        return getDayOfWeekName(key, userSession.locale);
    } else if (recurringTimeInterval === RecurringTimeInterval.DAY_OF_MONTH) {
        return key.toString();
    } else if (recurringTimeInterval === RecurringTimeInterval.DAY_OF_YEAR) {
        return key.toString();
    } else if (recurringTimeInterval === RecurringTimeInterval.WEEK_OF_YEAR) {
        return key.toString();
    } else if (recurringTimeInterval === RecurringTimeInterval.MONTH_OF_YEAR) {
        return getMonthName(key, userSession.locale, "S");
    } else if (recurringTimeInterval === RecurringTimeInterval.QUARTER_OF_YEAR) {
        const firstMonthOfQuarter = 3 * (key - 1);

        const start = getMonthName(firstMonthOfQuarter, userSession.locale, "S");
        const end = getMonthName(firstMonthOfQuarter + 2, userSession.locale, "S");

        return `${start} - ${end}`;
    } else {
        return null;
    }
}

export function getStartOfRelativeTimeSlot(
    now: Date,
    relativeTimeInterval: RelativeTimeInterval,
    timeZone?: string
): Date | null {
    const nowInTimezone = moment(now)
        .tz(timeZone || userSession.timeZone)
        .locale(userSession.locale)
        .startOf("minute");

    if (relativeTimeInterval === RelativeTimeInterval.TODAY) {
        nowInTimezone.startOf("day");
    } else if (relativeTimeInterval === RelativeTimeInterval.YESTERDAY) {
        nowInTimezone.startOf("day").subtract(1, "day");
    } else if (relativeTimeInterval === RelativeTimeInterval.SEVEN_DAYS_AGO) {
        nowInTimezone.startOf("day").subtract(7, "day");
    } else if (relativeTimeInterval === RelativeTimeInterval.CURRENT_WEEK) {
        nowInTimezone.startOf("week");
    } else if (relativeTimeInterval === RelativeTimeInterval.CURRENT_MONTH) {
        nowInTimezone.startOf("month");
    } else if (relativeTimeInterval === RelativeTimeInterval.CURRENT_QUARTER) {
        nowInTimezone.startOf("quarter");
    } else if (relativeTimeInterval === RelativeTimeInterval.CURRENT_YEAR) {
        nowInTimezone.startOf("year");
    } else if (relativeTimeInterval === RelativeTimeInterval.LAST_SEVEN_DAYS) {
        nowInTimezone.startOf("day").subtract(6, "day");
    } else if (relativeTimeInterval === RelativeTimeInterval.LAST_FOURTEEN_DAYS) {
        nowInTimezone.startOf("day").subtract(13, "day");
    } else if (relativeTimeInterval === RelativeTimeInterval.LAST_TEN_WEEKS) {
        nowInTimezone.startOf("week").subtract(9, "week");
    } else if (relativeTimeInterval === RelativeTimeInterval.PREVIOUS_DAY) {
        nowInTimezone.startOf("day").subtract(1, "day");
    } else if (relativeTimeInterval === RelativeTimeInterval.PREVIOUS_WEEK) {
        nowInTimezone.startOf("week").subtract(1, "week");
    } else if (relativeTimeInterval === RelativeTimeInterval.PREVIOUS_MONTH) {
        nowInTimezone.startOf("month").subtract(1, "month");
    } else if (relativeTimeInterval === RelativeTimeInterval.PREVIOUS_QUARTER) {
        nowInTimezone.startOf("quarter").subtract(1, "quarter");
    } else if (relativeTimeInterval === RelativeTimeInterval.PREVIOUS_YEAR) {
        nowInTimezone.startOf("year").subtract(1, "year");
    } else {
        return null;
    }

    return nowInTimezone.toDate();
}

export function getEndOfRelativeTimeSlot(
    now: Date,
    relativeTimeInterval: RelativeTimeInterval,
    timeZone?: string
): Date | null {
    const endOfTimeSlot = moment(getStartOfRelativeTimeSlot(now, relativeTimeInterval, timeZone))
        .tz(timeZone || userSession.timeZone)
        .locale(userSession.locale);

    if (relativeTimeInterval === RelativeTimeInterval.TODAY) {
        endOfTimeSlot.endOf("day");
    } else if (relativeTimeInterval === RelativeTimeInterval.YESTERDAY) {
        endOfTimeSlot.endOf("day");
    } else if (relativeTimeInterval === RelativeTimeInterval.SEVEN_DAYS_AGO) {
        endOfTimeSlot.endOf("day");
    } else if (relativeTimeInterval === RelativeTimeInterval.CURRENT_WEEK) {
        endOfTimeSlot.endOf("week");
    } else if (relativeTimeInterval === RelativeTimeInterval.CURRENT_MONTH) {
        endOfTimeSlot.endOf("month");
    } else if (relativeTimeInterval === RelativeTimeInterval.CURRENT_QUARTER) {
        endOfTimeSlot.endOf("quarter");
    } else if (relativeTimeInterval === RelativeTimeInterval.CURRENT_YEAR) {
        endOfTimeSlot.endOf("year");
    } else if (relativeTimeInterval === RelativeTimeInterval.LAST_SEVEN_DAYS) {
        endOfTimeSlot.add(6, "day").endOf("day");
    } else if (relativeTimeInterval === RelativeTimeInterval.LAST_FOURTEEN_DAYS) {
        endOfTimeSlot.add(13, "day").endOf("day");
    } else if (relativeTimeInterval === RelativeTimeInterval.LAST_TEN_WEEKS) {
        endOfTimeSlot.add(9, "week").endOf("week");
    } else if (relativeTimeInterval === RelativeTimeInterval.PREVIOUS_DAY) {
        endOfTimeSlot.endOf("day");
    } else if (relativeTimeInterval === RelativeTimeInterval.PREVIOUS_WEEK) {
        endOfTimeSlot.endOf("week");
    } else if (relativeTimeInterval === RelativeTimeInterval.PREVIOUS_MONTH) {
        endOfTimeSlot.endOf("month");
    } else if (relativeTimeInterval === RelativeTimeInterval.PREVIOUS_QUARTER) {
        endOfTimeSlot.endOf("quarter");
    } else if (relativeTimeInterval === RelativeTimeInterval.PREVIOUS_YEAR) {
        endOfTimeSlot.endOf("year");
    } else {
        return null;
    }

    return endOfTimeSlot.toDate();
}

export function getRelativeTimeSlotLabel(relativeTimeInterval: RelativeTimeInterval): string | null {
    if (relativeTimeInterval === RelativeTimeInterval.TODAY) {
        return $t("Heute") as string;
    } else if (relativeTimeInterval === RelativeTimeInterval.YESTERDAY) {
        return $t("Gestern") as string;
    } else if (relativeTimeInterval === RelativeTimeInterval.SEVEN_DAYS_AGO) {
        return $t("Vor 7 Tagen") as string;
    } else if (relativeTimeInterval === RelativeTimeInterval.CURRENT_WEEK) {
        return $t("Aktuelle Woche") as string;
    } else if (relativeTimeInterval === RelativeTimeInterval.CURRENT_MONTH) {
        return $t("Aktueller Monat") as string;
    } else if (relativeTimeInterval === RelativeTimeInterval.CURRENT_QUARTER) {
        return $t("Aktuelles Quartal") as string;
    } else if (relativeTimeInterval === RelativeTimeInterval.CURRENT_YEAR) {
        return $t("Aktuelles Jahr") as string;
    } else if (relativeTimeInterval === RelativeTimeInterval.LAST_SEVEN_DAYS) {
        return $t("Letzten 7 Tage") as string;
    } else if (relativeTimeInterval === RelativeTimeInterval.LAST_FOURTEEN_DAYS) {
        return $t("Letzten 14 Tage") as string;
    } else if (relativeTimeInterval === RelativeTimeInterval.LAST_TEN_WEEKS) {
        return $t("Letzten 10 Wochen") as string;
    } else if (relativeTimeInterval === RelativeTimeInterval.PREVIOUS_DAY) {
        return $t("Vortag") as string;
    } else if (relativeTimeInterval === RelativeTimeInterval.PREVIOUS_WEEK) {
        return $t("Vorherige Woche") as string;
    } else if (relativeTimeInterval === RelativeTimeInterval.PREVIOUS_MONTH) {
        return $t("Vorheriger Monat") as string;
    } else if (relativeTimeInterval === RelativeTimeInterval.PREVIOUS_QUARTER) {
        return $t("Vorheriges Quartal") as string;
    } else if (relativeTimeInterval === RelativeTimeInterval.PREVIOUS_YEAR) {
        return $t("Vorheriges Jahr") as string;
    } else {
        return null;
    }
}

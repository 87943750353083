
import { BadRequest } from "@/api/errors";
import { PasswordForm } from "@/api/userSession";
import { showInfo } from "@/app/messageUtil";
import { notEmpty, regEx, ValidationHelper } from "@/app/validation";
import { userSession } from "@/store/userSession";
import Vue from "vue";

export default Vue.extend({
    data() {
        const validationHelper = new ValidationHelper();
        return {
            validationHelper,
            isWorking: false,
            valid: true,
            oldPassword: "",
            newPassword: "",
            confirmedPassword: "",
            oldPasswordRules: notEmpty().and(validationHelper, "oldPassword"),
            newPasswordRules: regEx(/^(?=.{8,})(?!.*\s)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$/)
                .msg(() =>
                    this.$t(
                        "Das Passwort muss mindestens 8 Zeichen lang sein sowie kein Leerzeichen, einen Kleinbuchstaben, einen Großbuchstaben und eine Ziffer enthalten."
                    )
                )
                .and(validationHelper, "newPassword"),
        };
    },

    computed: {
        confirmedPasswordRules() {
            return [
                (v: string) => v === this.newPassword || this.$t("Die eingegebenen Passwörter stimmen nicht überein."),
            ];
        },
    },

    methods: {
        async changePassword() {
            if (!this.valid) {
                (this.$refs.form as any).validate();
                return;
            }

            this.isWorking = true;

            const passwordForm: PasswordForm = {
                newPassword: this.newPassword,
                oldPassword: this.oldPassword,
            };

            try {
                await userSession.changePassword(passwordForm);
            } catch (e) {
                if (!(e instanceof BadRequest)) {
                    throw e;
                }
                this.validationHelper.update(e, this.$refs.form);
                return;
            } finally {
                this.isWorking = false;
            }

            this.oldPassword = "";
            this.newPassword = "";
            this.confirmedPassword = "";
            (this.$refs.form as any).resetValidation();
            showInfo(this.$t("Passwort erfolgreich geändert") as string);
        },
    },
});

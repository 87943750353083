import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export enum FeedbackType {
    INFO = "INFO",
    ERROR_WRONG_LICENSE_PLATE = "ERROR_WRONG_LICENSE_PLATE",
    ERROR_WRONG_PHONE_NUMBER = "ERROR_WRONG_PHONE_NUMBER",
    ERROR_WRONG_RECEIVER = "ERROR_WRONG_RECEIVER",
    ERROR_OTHER = "ERROR_OTHER",
}

export enum ActionSearchOrder {
    CREATED_DESC = "CREATED_DESC",
    CREATED_ASC = "CREATED_ASC",
    FEEDBACK_TYPE_DESC = "FEEDBACK_TYPE_DESC",
    FEEDBACK_TYPE_ASC = "FEEDBACK_TYPE_ASC",
}

export enum ActionType {
    ADD_ACTIVITY_CREATING_CASE = "ADD_ACTIVITY_CREATING_CASE",
    ADD_ACTIVITY_CREATING_OPPORTUNITY = "ADD_ACTIVITY_CREATING_OPPORTUNITY",
    ADD_ACTIVITY_CREATING_EMERGENCY_INCIDENT = "ADD_ACTIVITY_CREATING_EMERGENCY_INCIDENT",
    ADD_ACTIVITY_EXISTING_CASE = "ADD_ACTIVITY_EXISTING_CASE",
    ADD_ACTIVITY_EXISTING_OPPORTUNITY = "ADD_ACTIVITY_EXISTING_OPPORTUNITY",
    ADD_ACTIVITY_EXISTING_EMERGENCY_INCIDENT = "ADD_ACTIVITY_EXISTING_EMERGENCY_INCIDENT",
    ADD_CONTACT = "ADD_CONTACT",
}

export interface Action {
    readonly id: number;
    readonly reference: string;
    readonly created: Date;
    readonly partitionId: string;
    readonly dealerId: string | null;
    readonly userId: string;
    readonly type: ActionType;
    readonly resourceId: string;
    readonly resourceUrl: string | null;
    readonly actionFeedback: ActionFeedback | null;
}

export interface ActionFeedback {
    readonly feedbackCreated: Date;
    readonly feedbackType: FeedbackType;
    readonly comment: string | null;
    readonly reporterUserId: string;
    readonly reporterFullName: string;
    readonly reporterEmail: string;
    readonly reporterPhone: string | null;
}

export interface ActionSearchRequest {
    readonly types: ActionType[];
    readonly bdcTeamIds: string[];
    readonly userIds: string[];
    readonly partitionIds: string[];
    readonly dealerIds: string[];
    readonly feedbackTypes: FeedbackType[];
    readonly hasFeedback: boolean | null;
    readonly createdFrom: Date;
    readonly createdTo: Date;
    readonly sortBy: ActionSearchOrder;
}

export interface ActionSearchResults {
    readonly totalSize: number;
    readonly results: Action[];
    readonly searchId: number;
}

interface ActionsApi {
    getById(id: number): Promise<Action>;
    getByIds(id: number[]): Promise<Action[]>;
    search(
        start: number,
        size: number,
        searchRequest: ActionSearchRequest,
        searchId: number
    ): Promise<ActionSearchResults>;
}

export const actionsApi: ActionsApi = {
    async getById(id) {
        return cloneObject((await axios.get(`/api/actions/${id}`)).data);
    },

    async getByIds(ids) {
        return cloneObject((await axios.get("/api/actions", { params: { id: ids.join(",") } })).data);
    },

    async search(start, size, searchRequest, searchId) {
        return cloneObject(
            (await axios.post("/api/actions/_search", searchRequest, { params: { start, size, searchId } })).data
        );
    },
};


import AgentDayDataTableRow from "./AgentDayDataTableRow.vue";
import {
    addExtendedAgentStatisticsData,
    ExtendedAgentStatisticsData,
    toExtendedAgentStatisticsData,
} from "./agentStatisticsUtils";
import { AgentStatistics, agentStatisticsApi } from "@/api/agentStatistics";
import { CallCenterStatistics, callCenterStatisticsApi } from "@/api/callCenterStatistics";
import { DutyRosterEntryType } from "@/api/dutyRosters";
import { Permission } from "@/api/userSession";
import {
    addExtendedCallCenterStatisticsData,
    ExtendedCallCenterStatisticsData,
    toExtendedCallCenterStatisticsData,
} from "@/app/pages/callcenterstatistics/callCenterStatisticsUtils";
import { configStore } from "@/store/config";
import { userSession } from "@/store/userSession";
import { formatTime } from "@/util/dateTimeUtils";
import { ActionLimiter } from "@/util/debounce";
import Vue from "vue";

interface Item {
    readonly from: Date;
    readonly to: Date;
    readonly agentStatisticsData: ExtendedAgentStatisticsData;
    readonly callCenterStatisticsData: ExtendedCallCenterStatisticsData | null;
}

export default Vue.extend({
    props: {
        date: {
            type: String,
            required: true,
        },
        includeCallCenterStatistics: {
            type: Boolean,
            default: false,
        },
        userId: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            DutyRosterEntryType,
            hourlyAgentStatistics: [] as AgentStatistics[],
            hourlyCallCenterStatistics: [] as CallCenterStatistics[],
            loading: true,
            loadLimiter: new ActionLimiter(true),
            searchId: 0,
        };
    },

    computed: {
        canViewAllUsers() {
            return userSession.hasPermission(Permission.CT_VIEW_AGENT_DAY_OVERVIEW);
        },

        items(): Item[] {
            return this.hourlyAgentStatistics.map((a) => {
                const callCenterStatistics = this.hourlyCallCenterStatistics.find(
                    (c) => a.from.getTime() === c.from.getTime() && a.to.getTime() === c.to.getTime()
                );

                return {
                    from: a.from,
                    to: a.to,
                    agentStatisticsData: toExtendedAgentStatisticsData(a.agentStatisticsData),
                    callCenterStatisticsData: callCenterStatistics
                        ? toExtendedCallCenterStatisticsData(callCenterStatistics)
                        : null,
                };
            });
        },

        total(): Item | null {
            if (!this.items.length) {
                return null;
            }

            return {
                from: this.items.map((i) => i.from).reduce((a, b) => (a.getTime() < b.getTime() ? a : b)),
                to: this.items.map((i) => i.to).reduce((a, b) => (a.getTime() < b.getTime() ? b : a)),
                agentStatisticsData: this.items
                    .map((i) => i.agentStatisticsData)
                    .reduce((a, b) => addExtendedAgentStatisticsData(a, b)),
                callCenterStatisticsData: this.includeCallCenterStatistics
                    ? this.items
                          .map((i) => i.callCenterStatisticsData)
                          .reduce((a, b) => (!!a && !!b ? addExtendedCallCenterStatisticsData(a, b) : a ?? b))
                    : null,
            };
        },

        waitingTimeThresholdSecondsForConsideringUnansweredCalls(): number {
            return configStore.configuration.waitingTimeThresholdSecondsForConsideringUnansweredCalls;
        },
    },

    methods: {
        formatHourRange(from: Date, to: Date) {
            return `${formatTime(from, userSession.timeZone, userSession.locale, false)} - ${formatTime(
                to,
                userSession.timeZone,
                userSession.locale,
                false
            )}`;
        },

        async loadItems() {
            this.loading = true;
            this.hourlyAgentStatistics = [];
            this.hourlyCallCenterStatistics = [];
            const searchId = ++this.searchId;

            if (!this.canViewAllUsers && this.userId !== userSession.id) {
                this.loading = false;
                return;
            }

            await this.loadLimiter.execute(async () => {
                try {
                    const [hourlyAgentStatistics, hourlyCallCenterStatistics] = await Promise.all([
                        agentStatisticsApi.getHourlyAgentStatisticsByDate(this.userId, this.date),
                        this.includeCallCenterStatistics
                            ? callCenterStatisticsApi.getHourlyCallCenterStatisticsByDate(
                                  this.date,
                                  this.waitingTimeThresholdSecondsForConsideringUnansweredCalls
                              )
                            : Promise.resolve([]),
                    ]);

                    if (searchId === this.searchId) {
                        this.hourlyAgentStatistics = hourlyAgentStatistics;
                        this.hourlyCallCenterStatistics = hourlyCallCenterStatistics;
                    }
                } finally {
                    if (searchId === this.searchId) {
                        this.loading = false;
                    }
                }
            });
        },

        async refresh() {
            await this.loadItems();
        },
    },

    watch: {
        async date() {
            try {
                await this.loadItems();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },

        async includeCallCenterStatistics() {
            try {
                if (this.includeCallCenterStatistics) {
                    await this.loadItems();
                } else {
                    this.hourlyCallCenterStatistics = [];
                }
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },

        loading: {
            immediate: true,
            handler() {
                this.$emit("change:loading", this.loading);
            },
        },

        async userId() {
            try {
                await this.loadItems();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },
    },

    async mounted() {
        await this.loadItems();
    },

    components: {
        AgentDayDataTableRow,
    },
});


import BdcTeamFormDialog from "./BdcTeamFormDialog.vue";
import { BdcTeam, bdcTeamsApi } from "@/api/bdcTeams";
import { User } from "@/api/users";
import { showConfirm } from "@/app/messageUtil";
import { getFullName } from "@/app/userUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        bdcTeam: {
            type: Object as () => BdcTeam,
            required: true,
        },
        users: {
            type: Array as () => User[],
            required: true,
        },
    },

    data() {
        return {
            getFullName,
            bdcTeamFormDialogVisible: false,
            deleting: false,
        };
    },

    computed: {
        teamLeadUser(): User | null {
            if (this.bdcTeam.teamLeadUserId === null) {
                return null;
            }

            return this.getUserById(this.bdcTeam.teamLeadUserId);
        },
    },

    methods: {
        async deleteBdcTeam() {
            if (
                !(await showConfirm(
                    this.$t("BDC-Team löschen") as string,
                    this.$t("Sind Sie sicher, dass Sie das BDC-Team {0} löschen möchten?", [
                        this.bdcTeam.name,
                    ]) as string
                ))
            ) {
                return;
            }

            this.deleting = true;
            try {
                await bdcTeamsApi.delete(this.bdcTeam.id);

                this.$emit("changed");
            } finally {
                this.deleting = false;
            }
        },

        getUserById(userId: string): User | null {
            return this.users.find((u) => u.id === userId) ?? null;
        },
    },

    components: {
        BdcTeamFormDialog,
    },
});

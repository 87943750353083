
import { DutyRosterCalendarEvent, getDutyRosterEventColor, isWorkingTimeType } from "./dutyRosterUtils";
import { DutyRosterEntry, DutyRosterEntryType } from "@/api/dutyRosters";
import { configStore } from "@/store/config";
import { userSession } from "@/store/userSession";
import { getFirstDayOfWeek } from "@/util/dateTimeUtils";
import moment from "moment-timezone";
import Vue from "vue";

export default Vue.extend({
    props: {
        start: String,
        entries: Array as () => DutyRosterEntry[],
    },

    data() {
        return {
            DutyRosterEntryType,
            isWorkingTimeType,
        };
    },

    computed: {
        events(): DutyRosterCalendarEvent[] {
            return this.entries.map((e) => ({
                start: Date.parse(`${e.day}T${e.beginTime}`),
                end: Date.parse(`${e.day}T${e.endTime}`) + 60 * 1000,
                break: e.includedBreakMinutes,
                type: e.type,
                timed: true,
            }));
        },

        month(): string {
            return moment(this.start)
                .locale(userSession.locale)
                .format("MMM YYYY");
        },

        weekdays(): number[] {
            return getFirstDayOfWeek(configStore.configuration.defaultLocale) === 1
                ? [1, 2, 3, 4, 5, 6, 0]
                : [0, 1, 2, 3, 4, 5, 6];
        },
    },

    methods: {
        eventColor(event: DutyRosterCalendarEvent): string {
            return getDutyRosterEventColor(event.type, this.$vuetify.theme.dark);
        },
    },
});


import {
    calculateAxisMaxValue,
    EChartsDatazoomEvent,
    EChartsLegendSelectChangedEvent,
    EChartsMouseEvent,
    formatAxisCategoryLabel,
    formatAxisValueLabel,
    formatDataLabel,
    getDatazoomStartIndex,
    getSliderWindow,
} from "./eChartsUtils";
import {
    getSeriesMaxValue,
    ReportingBarChartCategory,
    ReportingBarChartSeries,
    ReportingBarChartSeriesData,
    ReportingBarChartSeriesValue,
    ReportingBarChartSeriesValueFormatter,
} from "./reportingBarChart";
import { getColoredChartSeries } from "./reportingChart";
import { renderTooltip, ReportingChartTooltipCache } from "./reportingChartTooltip";
import { trimAndReturnNullIfEmpty } from "@/util/stringUtils";
import {
    BarSeriesOption,
    DefaultLabelFormatterCallbackParams,
    EChartsOption,
    XAXisComponentOption as XAXisOption,
    YAXisComponentOption as YAXisOption,
} from "echarts";
import Vue from "vue";

interface BarSeriesOptionDataValue {
    readonly value: number | undefined;
    readonly isPercentage: boolean;
    readonly onClick?: () => Promise<void> | void;
    readonly formatter?: ReportingBarChartSeriesValueFormatter;
}

interface ExtendedBarSeriesOptionDataValue extends BarSeriesOptionDataValue {
    readonly additionalTooltipValues: BarSeriesOptionDataValue[];
    readonly stack?: string;
    readonly category: ReportingBarChartCategory;
}

interface CategoryAxisSlider {
    startIndex?: number;
}

const STACK_MARKER = "MAIN_STACK";

export default Vue.extend({
    props: {
        absoluteMax: {
            type: Number,
            required: false,
        },
        categories: {
            type: Array as () => ReportingBarChartCategory[],
            required: true,
        },
        categoryAxisLabelTrimLength: {
            type: Number,
            default: 0,
        },
        categoryAxisLabelWrap: {
            type: Boolean,
            default: true,
        },
        categoryAxisScroll: {
            type: Boolean,
            default: false,
        },
        categoryAxisScrollWindowPosition: {
            type: String as () => "START" | "CENTER" | "END",
            default: "START",
        },
        height: {
            type: Number,
            required: false,
        },
        hideLegend: {
            type: Boolean,
            default: false,
        },
        hideAbsoluteValueAxis: {
            type: Boolean,
            default: false,
        },
        hidePercentageValueAxis: {
            type: Boolean,
            default: false,
        },
        hideValueAxis: {
            type: Boolean,
            default: false,
        },
        overlap: {
            type: Boolean,
            default: false,
        },
        percentageMax: {
            type: Number,
            required: false,
        },
        series: {
            type: Array as () => ReportingBarChartSeries[],
            required: true,
        },
        seriesAdditionalTooltipDataTooltipHeaders: {
            type: Array as () => string[],
            required: false,
        },
        seriesDataTooltipHeader: {
            type: String,
            required: false,
        },
        stacked: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            categoryAxisSlider: {} as CategoryAxisSlider, // enforce non-reactive attributes
            eChartGridLeftMargin: 0,
            eChartGridRightMargin: 20,
            eChartGridTopMargin: 20,
            selected: this.series.filter((s) => s.selected !== false).map((s) => s.name),
            tooltipCache: {} as ReportingChartTooltipCache,
            valueAxisMax: {} as { [key: string]: number },
            width: 0,
        };
    },

    computed: {
        barOverlapPercentage(): number {
            return this.overlap ? 0.75 : 0;
        },

        categoryAxisLabelLinesMax(): number {
            const categoryAxisOption = this.eChartXAXisOptions;

            const formatter = categoryAxisOption.axisLabel?.formatter as
                | ((category: string) => string | null)
                | undefined;

            return ((categoryAxisOption.data as string[]) ?? [])
                .map((category) => (formatter ? formatter(category) : null))
                .map((label) => label ?? "")
                .map((label) => label.split("\n").length)
                .reduce((max, occurrences) => Math.max(max, occurrences), 0);
        },

        categoryAxisScrollFullChartWidthFactor(): number {
            if (!this.showCategoryAxisSlider || !this.eChartsOption.dataZoom) {
                return 1;
            }

            return this.categories.length / this.categoryAxisScrollWindowSize;
        },

        categoryAxisScrollStart(): number {
            if (this.categoryAxisScrollWindowPosition === "START") {
                return 0;
            } else if (this.categoryAxisScrollWindowPosition === "CENTER") {
                return Math.max(0, Math.floor((this.categories.length - this.categoryAxisScrollWindowSize) / 2));
            } else if (this.categoryAxisScrollWindowPosition === "END") {
                return Math.max(0, this.categories.length - this.categoryAxisScrollWindowSize);
            } else {
                return 0;
            }
        },

        categoryAxisScrollWindowSize(): number {
            const stackCount = this.series
                .filter((series) => this.isSeriesSelected(series.name))
                .map((series) => (this.stacked ? series.group || STACK_MARKER : series.id))
                .filter((group, index, array) => array.indexOf(group) === index).length;

            const barNonOverlapPercentage = 1 - this.barOverlapPercentage;

            const barMargin = 25;
            const barWidth = 50;

            const firstBarWidth = barWidth;
            const otherBarWidth = Math.max(0, (stackCount - 1) * Math.ceil(barNonOverlapPercentage * barWidth));

            const barWidthAndMargin = barMargin + firstBarWidth + otherBarWidth;

            return Math.max(2, Math.ceil(this.eChartGridWidth / barWidthAndMargin));
        },

        eChartGridBottomMargin(): number {
            return this.showCategoryAxisSlider ? (this.hideLegend ? 30 : 50) : this.hideLegend ? 0 : 30;
        },

        eChartGridHeight(): number {
            const categoryAxisMargin = 6;
            const categoryAxisHeight = categoryAxisMargin + 10 * this.categoryAxisLabelLinesMax;

            return Math.max(
                0,
                this.eChartHeight - this.eChartGridTopMargin - this.eChartGridBottomMargin - categoryAxisHeight
            );
        },

        eChartGridWidth(): number {
            return Math.max(0, this.width - this.eChartGridLeftMargin - this.eChartGridRightMargin);
        },

        eChartHeight(): number {
            if (this.height) {
                return this.height;
            } else {
                return 500;
            }
        },

        eChartsOption(): EChartsOption {
            const options: EChartsOption = {
                animation: false,
                grid: {
                    top: `${this.eChartGridTopMargin}px`,
                    left: `${this.eChartGridLeftMargin}px`,
                    right: `${this.eChartGridRightMargin}px`,
                    bottom: `${this.eChartGridBottomMargin}px`,
                    containLabel: true,
                },
                xAxis: this.eChartXAXisOptions,
                yAxis: this.eChartYAXisOptions,
                tooltip: {
                    trigger: "axis",
                    formatter: (params: any) => {
                        return this.renderTooltip(params as DefaultLabelFormatterCallbackParams[]) ?? "";
                    },
                },
                axisPointer: {
                    type: "none",
                },
                legend: {
                    show: !this.hideLegend,
                    type: "scroll",
                    bottom: "0px",
                    height: "30px",
                    itemWidth: 12,
                    itemHeight: 12,
                    textStyle: {
                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                        fontSize: "11px",
                    },
                    selected: this.series.reduce((selected, s) => {
                        selected[s.name] = this.isSeriesSelected(s.name);
                        return selected;
                    }, {} as { [key: string]: boolean }),
                    tooltip: {
                        show: true,
                        align: "center",
                        formatter: (params: any) => this.series.find((s) => s.name === params.name)?.tooltip ?? "",
                    },
                },
                series: this.eChartSeries,
            };

            if (this.showCategoryAxisSlider) {
                const sliderWindow = getSliderWindow(
                    this.categoryAxisSlider.startIndex ?? this.categoryAxisScrollStart,
                    this.categories.length,
                    this.categoryAxisScrollWindowSize
                );

                options.dataZoom = {
                    name: "categoryAxisSlider",
                    type: "slider",
                    dataBackground: {
                        lineStyle: { opacity: 0, width: 0 },
                        areaStyle: { opacity: 0 },
                    },
                    selectedDataBackground: {
                        lineStyle: { opacity: 0, width: 0 },
                        areaStyle: { opacity: 0 },
                    },
                    handleStyle: { opacity: 0 },
                    labelFormatter: "",
                    filterMode: "none",
                    startValue: sliderWindow.startIndex,
                    endValue: sliderWindow.endIndex,
                    zoomLock: true,
                    rangeMode: ["value", "value"],
                    brushSelect: false,
                    height: 15,
                    bottom: this.hideLegend ? 5 : 30,
                };
            }

            return options;
        },

        eChartSeries(): BarSeriesOption[] {
            const labelLineHeight = 14;
            const labelVerticalMargin = 2;

            const groups = this.series
                .map((s) => s.group || STACK_MARKER)
                .filter((group, index, array) => array.indexOf(group) === index);

            return getColoredChartSeries(this.series)
                .map((series: ReportingBarChartSeries) => {
                    const valueAxisId = this.getValueAxisId(!!series.data.isPercentage);

                    const stack = this.stacked ? series.group || STACK_MARKER : undefined;

                    const seriesMaxValue = series.data.isPercentage
                        ? this.selectedSeriesMaxPercentageValue
                        : this.selectedSeriesMaxAbsoluteValue;

                    const barSeries: BarSeriesOption = {
                        name: series.name,
                        type: "bar",
                        barMaxWidth: 50,
                        barGap: `-${100 * this.barOverlapPercentage}%`,
                        yAxisId: valueAxisId,
                        silent: !series.data.values.some((v) => v.onClick),
                        data: series.data.values.map((v, index) => {
                            const dataValue = this.getBarSeriesOptionDataValue(v, series.data);

                            const barHeight =
                                dataValue.value !== undefined && (seriesMaxValue ?? 0) !== 0
                                    ? Math.ceil((dataValue.value / seriesMaxValue!) * this.eChartGridHeight)
                                    : undefined;

                            const label = this.formatDataLabel(series, dataValue.value) ?? "";
                            const labelLinesCount = label.split("\n").length;
                            const requiredBarHeight = labelLineHeight * labelLinesCount;

                            return {
                                ...dataValue,
                                additionalTooltipValues: (series.additionalTooltipData ?? []).map((adtData) =>
                                    this.getBarSeriesOptionDataValue(adtData.values[index], adtData)
                                ),
                                stack,
                                label: {
                                    formatter: requiredBarHeight <= (barHeight ?? 0) ? label : "",
                                },
                                itemStyle: {
                                    borderWidth: dataValue.value ? 0.5 : 0,
                                    borderColor: "#FFFFFFAA",
                                },
                                category: this.categories[index],
                            };
                        }),
                        label: {
                            show: true,
                            color: "#ffffff",
                            fontFamily: "Helvetica, Arial, sans-serif",
                            fontSize: "12px",
                            fontWeight: "bold",
                            textBorderColor: "#999999",
                            textBorderWidth: 2,
                        },
                        color: series.color,
                        stack,
                    };

                    return barSeries;
                })
                .map((series, index, array) => ({
                    ...series,
                    data: series.data!.map((dataValue: any, dataValueIndex: number) => {
                        const dataValueWithoutDataLabel = {
                            ...dataValue,
                            label: {
                                ...dataValue.label,
                                formatter: "",
                            },
                        };

                        const isNoOverlap = !this.overlap || index + 1 === array.length;
                        const label = dataValue.label.formatter as string;

                        if (isNoOverlap || !label || !this.isSeriesSelected(series.name!.toString())) {
                            return dataValue;
                        }

                        let seriesStacks: BarSeriesOption[][];

                        if (this.stacked) {
                            seriesStacks = groups
                                .slice(groups.findIndex((group) => group === series.stack))
                                .map((group) => array.filter((s) => group === s.stack));
                        } else {
                            seriesStacks = array.slice(index).map((s) => [s]);
                        }

                        if (seriesStacks.length === 1) {
                            return dataValue;
                        }

                        const seriesStacksDimensions = seriesStacks.map((s, index) =>
                            this.getSelectedBarSeriesOptionStackDimensions(
                                s,
                                dataValueIndex,
                                index === 0 ? series : undefined
                            )
                        );

                        const dimension = seriesStacksDimensions[0];
                        const overlapDimensions = seriesStacksDimensions.slice(1);

                        if (
                            !dimension.stackHeight ||
                            dimension.seriesStartHeight === undefined ||
                            !dimension.seriesEndHeight ||
                            !dimension.seriesHeight
                        ) {
                            return dataValueWithoutDataLabel;
                        }

                        const labelLinesCount = label.split("\n").length;
                        const requiredHeight = labelLineHeight * labelLinesCount + 2 * labelVerticalMargin;

                        const overlapHeight = Math.max(
                            0,
                            ...overlapDimensions.map((overlapDimension) =>
                                dimension.seriesStartHeight! < overlapDimension.stackHeight
                                    ? overlapDimension.stackHeight - dimension.seriesStartHeight!
                                    : 0
                            )
                        );
                        const availableHeight = Math.max(0, Math.floor(dimension.seriesHeight - overlapHeight));

                        if (availableHeight < requiredHeight) {
                            return dataValueWithoutDataLabel;
                        }

                        return {
                            ...dataValue,
                            label: {
                                ...dataValue.label,
                                offset: [0, Math.ceil(overlapHeight / -2)],
                            },
                        };
                    }),
                }));
        },

        eChartXAXisOptions(): Extract<XAXisOption, { type?: "category" }> {
            return {
                type: "category",
                data: this.categories.map((c) => c.name),
                axisPointer: {
                    type: "shadow",
                },
                axisTick: {
                    interval: () => true,
                },
                axisLabel: {
                    formatter: (value: string | number) =>
                        formatAxisCategoryLabel(
                            value as string,
                            this.categoryAxisLabelTrimLength,
                            this.categoryAxisLabelWrap
                        ),
                    interval: () => true,
                    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                    fontSize: "11px",
                },
            };
        },

        eChartYAXisOptions(): YAXisOption[] {
            const axis: YAXisOption[] = [];

            // has absolute series
            if (this.series.some((series) => !series.data.isPercentage)) {
                axis.push(this.getValueAxisOption(false));
            }

            // has percentage series
            if (this.series.some((series) => !!series.data.isPercentage)) {
                axis.push(this.getValueAxisOption(true));
            }

            return axis.map((a, index) => ({
                ...a,
                alignTicks: index === 0 || undefined,
            }));
        },

        selectedSeriesMaxAbsoluteValue(): number | undefined {
            return getSeriesMaxValue(
                this.series.filter((s) => !s.data.isPercentage && this.isSeriesSelected(s.name)),
                this.stacked
            );
        },

        selectedSeriesMaxPercentageValue(): number | undefined {
            return getSeriesMaxValue(
                this.series.filter((s) => !!s.data.isPercentage && this.isSeriesSelected(s.name)),
                this.stacked
            );
        },

        showCategoryAxisSlider(): boolean {
            return this.categoryAxisScroll && this.categoryAxisScrollWindowSize < this.categories.length;
        },
    },

    methods: {
        formatDataLabel(series: ReportingBarChartSeries, value: number | undefined): string | null {
            const label = series.data.formatter
                ? trimAndReturnNullIfEmpty(series.data.formatter(value, !!series.data.isPercentage, "LABEL"))
                : null;

            if (label !== null) {
                return label;
            }

            return value !== undefined
                ? trimAndReturnNullIfEmpty(formatDataLabel(value, !!series.data.isPercentage))
                : null;
        },

        getBarSeriesOptionDataValue(
            v: ReportingBarChartSeriesValue,
            data: ReportingBarChartSeriesData
        ): BarSeriesOptionDataValue {
            const roundFactor = data.isPercentage ? 10000 : 100;

            return {
                value: v.value === undefined ? null! : Math.round(roundFactor * v.value) / roundFactor,
                isPercentage: !!data.isPercentage,
                onClick: v.onClick,
                formatter: data.formatter,
            };
        },

        getBarSeriesOptionValueSum(series: BarSeriesOption[], valueIndex: number): number | undefined {
            return series.reduce((prev, cur) => {
                const value = (cur.data![valueIndex] as BarSeriesOptionDataValue).value;

                return prev === undefined ? value : prev + (value ?? 0);
            }, undefined as number | undefined);
        },

        getChartAsPngDataUrl(): string | null {
            return (this.$refs.chart as any).getChartAsPngDataUrl();
        },

        getChartAsSvgDataUrl(): string | null {
            return (this.$refs.chart as any).getChartAsSvgDataUrl();
        },

        getSelectedBarSeriesOptionStackDimensions(
            stack: BarSeriesOption[],
            valueIndex: number,
            series?: BarSeriesOption
        ) {
            const hasPercentageSeries = this.hasBarSeriesOptionStackPercentageSeries(stack);
            const valueAxisId = this.getValueAxisId(hasPercentageSeries);
            const valueAxisMaxValue = this.valueAxisMax[valueAxisId];

            const selectedSeries = stack.filter((s) => !!s.name && this.selected.includes(s.name.toString()));

            const selectedSeriesMaxValue = hasPercentageSeries
                ? this.selectedSeriesMaxPercentageValue
                : this.selectedSeriesMaxAbsoluteValue;

            const selectedSeriesMaxHeight = Math.ceil(
                (selectedSeriesMaxValue !== undefined && valueAxisMaxValue
                    ? selectedSeriesMaxValue / valueAxisMaxValue
                    : 0) * this.eChartGridHeight
            );

            const sum = this.getBarSeriesOptionValueSum(selectedSeries, valueIndex);

            const stackHeight = Math.floor(
                (sum !== undefined && selectedSeriesMaxValue ? sum / selectedSeriesMaxValue : 0) *
                    selectedSeriesMaxHeight
            );

            let seriesStartHeight: number | undefined = undefined;
            let seriesEndHeight: number | undefined = undefined;
            let seriesHeight: number | undefined = undefined;

            const seriesIndex = selectedSeries.findIndex((s) => s.name && s.name === series?.name);

            if (seriesIndex !== -1 && selectedSeriesMaxValue && selectedSeriesMaxHeight) {
                const precedingSeriesSum =
                    this.getBarSeriesOptionValueSum(selectedSeries.slice(0, seriesIndex), valueIndex) ?? 0;

                const includingSeriesSum =
                    this.getBarSeriesOptionValueSum(selectedSeries.slice(0, seriesIndex + 1), valueIndex) ?? 0;

                const startPos = precedingSeriesSum ? precedingSeriesSum / selectedSeriesMaxValue : 0;
                const endPos = includingSeriesSum ? includingSeriesSum / selectedSeriesMaxValue : 0;

                seriesStartHeight = Math.floor(startPos * selectedSeriesMaxHeight);
                seriesEndHeight = Math.floor(endPos * selectedSeriesMaxHeight);
                seriesHeight = seriesEndHeight - seriesStartHeight;
            }

            return {
                seriesStartHeight,
                seriesEndHeight,
                seriesHeight,
                stackHeight,
            };
        },

        getValueAxisId(isPercentage: boolean): string {
            return isPercentage ? "percentage-axis" : "absolute-axis";
        },

        getValueAxisOption(isPercentage: boolean): YAXisOption {
            const id = this.getValueAxisId(isPercentage);
            const show =
                !this.hideValueAxis && (isPercentage ? !this.hidePercentageValueAxis : !this.hideAbsoluteValueAxis);

            return {
                id,
                position: isPercentage && this.hideAbsoluteValueAxis ? "left" : undefined,
                type: "value",
                axisLabel: {
                    formatter: (value: number) => {
                        Vue.set(this.valueAxisMax, id, Math.max(value, this.valueAxisMax[id] ?? -Infinity));

                        if (!show) {
                            return "";
                        }

                        return formatAxisValueLabel(value, isPercentage);
                    },
                    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                    fontSize: "11px",
                },
                splitLine: {
                    show,
                },
                max: (extent: { min: number; max: number }) =>
                    calculateAxisMaxValue(
                        extent,
                        isPercentage ? this.percentageMax : this.absoluteMax,
                        isPercentage
                    ) as number, // type is incorrect, prop accepts null
            };
        },

        hasBarSeriesOptionStackPercentageSeries(stack: BarSeriesOption[]): boolean {
            return stack.some((s) => !!s.data!.length && (s.data![0] as BarSeriesOptionDataValue).isPercentage);
        },

        isSeriesSelected(seriesName: string): boolean {
            return this.selected.includes(seriesName);
        },

        onDatazoom(e: EChartsDatazoomEvent) {
            this.categoryAxisSlider.startIndex = getDatazoomStartIndex(e, this.categories.length);
        },

        onLegendSelectChanged(e: EChartsLegendSelectChangedEvent) {
            this.valueAxisMax = {};
            this.selected = Object.keys(e.selected).filter((name) => e.selected[name]);
        },

        async onValueClick(e: EChartsMouseEvent<BarSeriesOptionDataValue>) {
            if (e.componentType !== "series" || e.seriesType !== "bar" || !e.data.onClick) {
                return;
            }

            await e.data.onClick();
        },

        renderTooltip(series: DefaultLabelFormatterCallbackParams[]): string | null {
            if (!series.length) {
                return null;
            }

            const tooltipSeries = series.map((s) => {
                const dataValue = s.data as ExtendedBarSeriesOptionDataValue;

                return {
                    name: s.seriesName!,
                    marker: s.marker! as string,
                    value: {
                        value: dataValue.value,
                        isPercentage: dataValue.isPercentage,
                        formatter: (value: number | undefined, isPercentage: boolean) =>
                            dataValue.formatter ? dataValue.formatter(value, isPercentage, "TOOLTIP") : null,
                    },
                    additionalTooltipValues: dataValue.additionalTooltipValues.map((adtValue) => ({
                        value: adtValue.value,
                        isPercentage: adtValue.isPercentage,
                        formatter: (value: number | undefined, isPercentage: boolean) =>
                            adtValue.formatter ? adtValue.formatter(value, isPercentage, "TOOLTIP") : null,
                    })),
                    group: dataValue.stack !== STACK_MARKER ? dataValue.stack ?? null : null,
                };
            });

            const category = (series[0].data as ExtendedBarSeriesOptionDataValue).category;

            return renderTooltip(
                {
                    additionalValueHeaders: this.seriesAdditionalTooltipDataTooltipHeaders,
                    series: tooltipSeries,
                    title: category.name,
                    subtitle: category.description,
                    valueHeader: this.seriesDataTooltipHeader,
                },
                this.tooltipCache,
                this.$i18n
            );
        },

        resize(width: number) {
            this.width = width;
        },
    },

    watch: {
        categories() {
            this.categoryAxisSlider.startIndex = this.categoryAxisScrollStart;
        },

        series(__, oldSeries: ReportingBarChartSeries[]) {
            const oldSeriesNames = oldSeries.map((s) => s.name);

            this.valueAxisMax = {};
            this.selected = this.series
                .filter(
                    (s) => this.selected.includes(s.name) || (s.selected !== false && !oldSeriesNames.includes(s.name))
                )
                .map((s) => s.name);
        },
    },

    components: {
        LazyEChart: () => import("./LazyEChart.vue"),
    },
});


import CitNowDataExchangeBillingItemUploadDialog from "./CitNowDataExchangeBillingItemUploadDialog.vue";
import { citNowDataExchangeApi } from "@/api/citNowDataExchange";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            billingItemUploadDialogVisible: false,
            working: false,
        };
    },

    methods: {
        async uploadBillingAccountsContractsInvoicesAndRevenues() {
            this.working = true;
            try {
                await citNowDataExchangeApi.uploadBillingAccountsContractsInvoicesAndRevenues();
            } finally {
                this.working = false;
            }
        },
    },

    components: {
        CitNowDataExchangeBillingItemUploadDialog,
    },
});


import FtpAccountDialog from "./FtpAccountDialog.vue";
import { FtpAccount, ftpAccountApi } from "@/api/ftpAccounts";
import CopyToClipboardIcon from "@/app/components/CopyToClipboardIcon.vue";
import DataTable from "@/app/components/DataTable.vue";
import { DataTableHeader } from "@/app/components/dataTable";
import { showConfirm } from "@/app/messageUtil";
import { configStore } from "@/store/config";
import Vue from "vue";

interface FtpAccountItem extends FtpAccount {
    showPassword: boolean;
}

export default Vue.extend({
    data() {
        return {
            items: [] as FtpAccountItem[],
            ftpAccountDialogVisible: false,
            ftpAccount: null as FtpAccount | null,
            loading: false,
        };
    },

    computed: {
        ftpDomain(): string {
            return configStore.configuration.ftpDomain;
        },

        headers(): DataTableHeader[] {
            return [
                {
                    text: this.$t("FTP-Zugang"),
                    width: "20%",
                },
                {
                    text: this.$t("Typ"),
                    width: "20%",
                },
                {
                    text: this.$t("Host"),
                    width: "15%",
                },
                {
                    text: this.$t("Benutzername"),
                    width: "15%",
                },
                {
                    text: this.$t("Passwort"),
                    width: "20%",
                },
                {
                    text: this.$t("Aktionen"),
                    align: "right",
                    width: "10%",
                },
            ];
        },
    },

    async mounted() {
        await this.loadItems();
    },

    methods: {
        async loadItems() {
            this.loading = true;
            try {
                this.items = (await ftpAccountApi.list()).map((i) => ({ ...i, showPassword: false }));
            } finally {
                this.loading = false;
            }
        },

        async savedFtpAccount() {
            this.ftpAccountDialogVisible = false;
            await this.loadItems();
        },

        async deleteFtpAccount(id: string) {
            if (
                await showConfirm(
                    this.$t("FTP-Zugang löschen") as string,
                    this.$t("Sind Sie sicher, dass Sie den FTP-Zugang löschen möchten?") as string
                )
            ) {
                await ftpAccountApi.delete(id);
                await this.loadItems();
            }
        },

        showAddDialog() {
            this.ftpAccount = null;
            this.ftpAccountDialogVisible = true;
        },

        showEditDialog(item: FtpAccount) {
            this.ftpAccount = item;
            this.ftpAccountDialogVisible = true;
        },

        renderFtpLink(item: FtpAccount) {
            return `ftp://${encodeURIComponent(item.username)}:${encodeURIComponent(item.password)}@${this.ftpDomain}/`;
        },
    },

    components: {
        FtpAccountDialog,
        CopyToClipboardIcon,
        DataTable,
    },
});

import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export enum DisconnectStatus {
    UNKNOWN = "UNKNOWN",
    BUSY = "BUSY",
    REJECT = "REJECT",
    NO_ANSWER = "NO_ANSWER",
    UNKNOWN_NUMBER = "UNKNOWN_NUMBER",
    UNREACHABLE = "UNREACHABLE",
    NETWORK_CONGESTION = "NETWORK_CONGESTION",
    CALL_DISCONNECTED = "CALL_DISCONNECTED",
    NO_CHANNEL_AVAILABLE = "NO_CHANNEL_AVAILABLE",
    ORIGINATOR_DISCONNECTED = "ORIGINATOR_DISCONNECTED",
    OTHER = "OTHER",
}

export enum ExternalBdcCdrSearchOrder {
    BEGIN_DESC = "BEGIN_DESC",
    BEGIN_ASC = "BEGIN_ASC",
    ACCEPT_DESC = "ACCEPT_DESC",
    ACCEPT_ASC = "ACCEPT_ASC",
    END_DESC = "END_DESC",
    END_ASC = "END_ASC",
}

export interface CallsPerUserAndTime {
    readonly userId: string;
    readonly timestamp: Date;
    readonly numberOfCalls: number;
}

export interface ExternalBdcCdr {
    readonly id: number;
    readonly hash: string;
    readonly caller: string | null;
    readonly callee: string;
    readonly begin: Date;
    readonly distributionBeginOffset: number;
    readonly accept: Date | null;
    readonly end: Date;
    readonly userId: string | null;
    readonly dealerId: string | null;
    readonly disconnectStatus: DisconnectStatus;
    readonly disconnectReason: string | null;
}

export interface ExternalBdcCdrSearchRequest {
    readonly bdcTeamIds: string[];
    readonly userIds: string[];
    readonly dealerIds: string[];
    readonly accepted: boolean | null;
    readonly beginFrom: Date;
    readonly beginTo: Date;
    readonly sortBy: ExternalBdcCdrSearchOrder;
}

export interface ExternalBdcCdrSearchResults {
    readonly searchId: number;
    readonly totalSize: number;
    readonly results: ExternalBdcCdr[];
}

interface ExternalBdcCdrsApi {
    getByIds(ids: number[]): Promise<ExternalBdcCdr[]>;
    getByUserWithinEndRange(userId: string, endFrom: Date, endTo: Date): Promise<ExternalBdcCdr[]>;
    getCallsPerUserAndTime(from: Date, to: Date, intervalInMinutes: number): Promise<CallsPerUserAndTime[]>;
    search(
        start: number,
        size: number,
        searchRequest: ExternalBdcCdrSearchRequest,
        searchId: number
    ): Promise<ExternalBdcCdrSearchResults>;
}

export const externalBdcCdrsApi: ExternalBdcCdrsApi = {
    async getByIds(ids) {
        return cloneObject((await axios.get(`/api/external-bdc-cdrs`, { params: { id: ids.join(",") } })).data);
    },

    async getByUserWithinEndRange(userId, endFrom, endTo) {
        return cloneObject(
            (await axios.get(`/api/external-bdc-cdrs/users/${userId}`, { params: { endFrom, endTo } })).data
        );
    },

    async getCallsPerUserAndTime(from, to, intervalInMinutes) {
        return cloneObject(
            (
                await axios.get("/api/external-bdc-cdrs/_calls-per-user-and-time", {
                    params: {
                        from,
                        to,
                        intervalInMinutes,
                    },
                })
            ).data
        );
    },

    async search(start, size, searchRequest, searchId) {
        return cloneObject(
            (await axios.post("/api/external-bdc-cdrs/_search", searchRequest, { params: { start, size, searchId } }))
                .data
        );
    },
};

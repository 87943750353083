import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export interface BdcTeam {
    readonly id: string;
    readonly created: Date;
    readonly updated: Date;
    readonly name: string;
    readonly teamLeadUserId: string | null;
    readonly memberUserIds: string[];
    readonly sendActionFeedbackToTeamLead: boolean;
}

export interface BdcTeamForm {
    readonly name: string;
    readonly teamLeadUserId: string | null;
    readonly memberUserIds: string[];
    readonly sendActionFeedbackToTeamLead: boolean;
}

interface BdcTeamsApi {
    add(bdcTeamForm: BdcTeamForm): Promise<string>;
    delete(id: string): Promise<void>;
    edit(id: string, bdcTeamForm: BdcTeamForm): Promise<void>;
    getAll(): Promise<BdcTeam[]>;
}

export const bdcTeamsApi: BdcTeamsApi = {
    async add(bdcTeamForm) {
        return (await axios.post("/api/bdc-teams", bdcTeamForm)).data;
    },

    async delete(id) {
        await axios.delete(`/api/bdc-teams/${id}`);
    },

    async edit(id, bdcTeamForm) {
        await axios.put(`/api/bdc-teams/${id}`, bdcTeamForm);
    },

    async getAll() {
        return cloneObject((await axios.get("/api/bdc-teams")).data);
    },
};

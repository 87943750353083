
import DutyRosterPanel from "./DutyRosterPanel.vue";
import { dutyRostersApi } from "@/api/dutyRosters";
import { User, usersApi } from "@/api/users";
import { configStore } from "@/store/config";
import { addDuration, endOf, getWeek, startOf, toDateObject, UnitOfTime, Week } from "@/util/dateTimeUtils";
import Vue from "vue";

interface Agent {
    readonly user: User;
    readonly name: string;
    readonly usedCapacity: number;
    readonly weeklyWorkingHours: number;
}

export default Vue.extend({
    props: {
        editMode: {
            type: Boolean,
            default: false,
        },
        from: {
            type: String,
            required: true,
        },
        to: {
            type: String,
            required: true,
        },
        openWeekWithDate: {
            type: String as () => string | null,
            default: null,
        },
        userId: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            agent: null as Agent | null,
            loading: true,
            visibleWeekIndex: null as number | null,
            scheduledTime: [] as number[],
        };
    },

    computed: {
        openWeekWithDateWeekIndex(): number | null {
            if (!this.openWeekWithDate) {
                return null;
            }

            const date = toDateObject(this.timeZone, this.openWeekWithDate);

            return (
                this.weeks
                    .map((w) => ({
                        start: toDateObject(this.timeZone, w.begin),
                        end: endOf(toDateObject(this.timeZone, w.end), this.timeZone, this.locale, UnitOfTime.DAY),
                    }))
                    .findIndex((w) => w.start.getTime() <= date.getTime() && date.getTime() <= w.end.getTime()) ?? null
            );
        },

        locale(): string {
            return configStore.configuration.defaultLocale;
        },

        timeZone(): string {
            return configStore.configuration.organisationTimeZone;
        },

        weeks(): Week[] {
            let date = startOf(toDateObject(this.timeZone, this.from), this.timeZone, this.locale, UnitOfTime.WEEK);
            const end = endOf(toDateObject(this.timeZone, this.to), this.timeZone, this.locale, UnitOfTime.WEEK);

            const weeks: Week[] = [];

            while (date.getTime() <= end.getTime()) {
                weeks.push(getWeek(date, 0, this.timeZone, this.locale));

                date = addDuration(date, this.timeZone, 1, UnitOfTime.WEEK);
            }

            return weeks;
        },
    },

    methods: {
        getUsedCapacity(weekIndex: number): number {
            if (this.scheduledTime.length <= weekIndex) {
                return 0;
            }

            return (100 * this.scheduledTime[weekIndex]) / (this.agent!.weeklyWorkingHours * 60);
        },

        async loadAgent() {
            this.agent = null;
            this.loading = true;

            try {
                const [u, scheduledTime] = await Promise.all([
                    usersApi.getById(this.userId),
                    dutyRostersApi.getScheduledTimeByUser(this.userId, this.weeks[0].begin, this.weeks.length),
                ]);

                if (u.weeklyWorkingHours !== null) {
                    this.agent = {
                        user: u,
                        name: `${u.givenName} ${u.familyName} (${u.username})`,
                        usedCapacity:
                            (100 * scheduledTime.reduce((p, c) => p + c, 0)) /
                            (this.weeks.length * u.weeklyWorkingHours! * 60),
                        weeklyWorkingHours: u.weeklyWorkingHours!,
                    };
                    this.scheduledTime = scheduledTime;
                }
            } finally {
                this.loading = false;
            }
        },

        async updateScheduledTime() {
            if (!this.agent) {
                this.scheduledTime = [];
                return;
            }

            this.scheduledTime = await dutyRostersApi.getScheduledTimeByUser(
                this.agent.user.id,
                this.weeks[0].begin,
                this.weeks.length
            );

            this.$emit("update:scheduled-time");
        },
    },

    watch: {
        weeks: {
            immediate: true,
            handler() {
                this.visibleWeekIndex = this.openWeekWithDateWeekIndex;
            },
        },
    },

    async mounted() {
        await this.loadAgent();
    },

    components: {
        DutyRosterPanel,
    },
});

import { IndividualDurationInterval, toIndividualDurationIntervalKey } from "./timeInterval";
import i18n from "@/app/i18n";
import { DateTimeOutputFormat, formatDuration } from "@/util/dateTimeUtils";
import { trimAndReturnNullIfEmpty } from "@/util/stringUtils";

const $t = (s: string) => s;

export const DEFAULT_INDIVIDUAL_DURATION_INTERVALS: IndividualDurationInterval[] = [
    {
        label: $t("< 5 Minuten"),
        from: 0,
        to: 5 * 60,
    },
    {
        label: $t("5 - 15 Minuten"),
        from: 5 * 60,
        to: 15 * 60,
    },
    {
        label: $t("15 - 30 Minuten"),
        from: 15 * 60,
        to: 30 * 60,
    },
    {
        label: $t("30 - 60 Minuten"),
        from: 30 * 60,
        to: 60 * 60,
    },
    {
        label: $t("1 - 2 Stunden"),
        from: 60 * 60,
        to: 2 * 60 * 60,
    },
    {
        label: $t("2 - 4 Stunden"),
        from: 2 * 60 * 60,
        to: 4 * 60 * 60,
    },
    {
        label: $t("4 - 24 Stunden"),
        from: 4 * 60 * 60,
        to: 24 * 60 * 60,
    },
    {
        label: $t("24 - 72 Stunden"),
        from: 24 * 60 * 60,
        to: 72 * 60 * 60,
    },
    {
        label: $t("3 - 7 Tage"),
        from: 3 * 24 * 60 * 60,
        to: 7 * 24 * 60 * 60,
    },
    {
        label: $t("1 - 2 Wochen"),
        from: 7 * 24 * 60 * 60,
        to: 14 * 24 * 60 * 60,
    },
    {
        label: $t("2 - 4 Wochen"),
        from: 14 * 24 * 60 * 60,
        to: 28 * 24 * 60 * 60,
    },
    {
        label: $t(">= 4 Wochen"),
        from: 28 * 24 * 60 * 60,
    },
].map((i) => ({
    key: toIndividualDurationIntervalKey(i),
    ...i,
}));

const SECONDS_IN_MINUTE = 60;
const SECONDS_IN_HOUR = 60 * SECONDS_IN_MINUTE;
const SECONDS_IN_DAY = 24 * SECONDS_IN_HOUR;
const SECONDS_IN_WEEK = 7 * SECONDS_IN_DAY;

export function renderHumanizedDuration(seconds: number | undefined, format?: DateTimeOutputFormat): string | null {
    if (seconds === undefined) {
        return null;
    }

    let value = seconds;
    let unit = "second";

    if (seconds >= SECONDS_IN_WEEK) {
        value = seconds / SECONDS_IN_WEEK;
        unit = "week";
    } else if (seconds >= SECONDS_IN_DAY) {
        value = seconds / SECONDS_IN_DAY;
        unit = "day";
    } else if (seconds >= SECONDS_IN_HOUR) {
        value = seconds / SECONDS_IN_HOUR;
        unit = "hour";
    } else if (seconds >= SECONDS_IN_MINUTE) {
        value = seconds / SECONDS_IN_MINUTE;
        unit = "minute";
    }

    return trimAndReturnNullIfEmpty(
        i18n.n(value, {
            style: "unit",
            unit,
            unitDisplay: format === "S" ? "short" : "long",
            minimumFractionDigits: "1",
            maximumFractionDigits: "1",
        })
    );
}

export function renderDuration(
    seconds: number | null | undefined,
    format: DateTimeOutputFormat,
    humanized?: boolean
): string | null {
    if (seconds === undefined || seconds === null) {
        return null;
    }

    return trimAndReturnNullIfEmpty(
        humanized ? renderHumanizedDuration(seconds, format) : formatDuration(seconds, true, format)
    );
}

export function renderPercentage(value: number | null | undefined, fractionDigits?: number | null): string | null {
    if (value === undefined || value === null) {
        return null;
    }

    const minMaxFractionDigits = fractionDigits?.toString() ?? "2";

    return trimAndReturnNullIfEmpty(
        i18n.n(value, {
            style: "percent",
            minimumFractionDigits: minMaxFractionDigits,
            maximumFractionDigits: minMaxFractionDigits,
        })
    );
}

export function max(values: (number | undefined | null)[]): number | undefined {
    return values
        .filter((v) => v !== undefined && v !== null)
        .map((v) => v as number)
        .reduce((max, value) => (max === undefined ? value : Math.max(max, value)), undefined as number | undefined);
}

export function sum(values: (number | undefined | null)[]): number | undefined {
    return values
        .filter((v) => v !== undefined && v !== null)
        .map((v) => v as number)
        .reduce((sum, value) => (sum ?? 0) + value, undefined as number | undefined);
}

export function avg(values: (number | undefined | null)[]): number | undefined {
    const count = values.filter((v) => v !== undefined && v !== null).length;

    return count ? sum(values)! / count : undefined;
}

export function median(values: (number | undefined | null)[]): number | undefined {
    const sorted = values
        .filter((v) => v !== undefined && v !== null)

        .map((v) => v as number)
        .sort((a, b) => a - b);

    if (!sorted.length) {
        return undefined;
    }

    const maxIndex = Math.max(0, sorted.length - 1);

    return (sorted[Math.floor(maxIndex / 2)] + sorted[Math.ceil(maxIndex / 2)]) / 2;
}

export function rate(value: number | undefined | null, total: number | undefined | null): number | undefined {
    if (!total || (!value && value !== 0)) {
        return undefined;
    }

    return value / total;
}

export function numberCompare(
    a: number | undefined | null,
    b: number | undefined | null,
    direction: "ASC" | "DESC"
): number {
    if ((a ?? null) === (b ?? null)) {
        return 0;
    } else if ((a ?? null) === null) {
        return 1;
    } else if ((b ?? null) === null) {
        return -1;
    }

    return direction === "ASC" ? a! - b! : b! - a!;
}

export function getMaxDate(...dates: (Date | undefined | null)[]): Date | null {
    return dates
        .filter((d): d is Date => !!d)
        .reduce((prev, cur) => (!prev || prev.getTime() < cur.getTime() ? cur : prev), null as Date | null);
}

export function getMinDate(...dates: (Date | undefined | null)[]): Date | null {
    return dates
        .filter((d): d is Date => !!d)
        .reduce((prev, cur) => (!prev || cur.getTime() < prev.getTime() ? cur : prev), null as Date | null);
}

import axios from "axios";

export interface ElasticCluster {
    readonly id: number;
    readonly url: string;
    readonly username: string;
    readonly password: string;
    readonly usageCount: number;
}

export interface ElasticClusterForm {
    readonly url: string;
    readonly username: string;
    readonly password: string;
}

interface ElasticClustersApi {
    list(): Promise<ElasticCluster[]>;
    add(form: ElasticClusterForm): Promise<void>;
}

export const elasticClustersApi: ElasticClustersApi = {
    async list() {
        return (await axios.get("/api/elastic-clusters")).data;
    },

    async add(form) {
        await axios.post("/api/elastic-clusters", form);
    },
};

import { globalDialog } from "@/store/globalDialog";
import { snackbar } from "@/store/snackbar";

export function showInfo(message: string) {
    snackbar.display(message, 3000);
}

export function showError(message: string) {
    snackbar.display(message, 0, true);
}

export function showAlert(title: string, text: string, okLabel?: string) {
    return globalDialog.showAlert(title, text, okLabel);
}

export function showConfirm(title: string, text: string, okLabel?: string, cancelLabel?: string) {
    return globalDialog.showConfirm(title, text, okLabel, cancelLabel);
}

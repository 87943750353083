
import { notEmpty } from "@/app/validation";
import Vue from "vue";

export default Vue.extend({
    props: {
        value: {
            type: String,
            required: false,
        },
        label: {
            type: String,
            required: true,
        },
        clearable: {
            type: Boolean,
            required: false,
        },
        required: {
            type: Boolean,
            required: false,
        },
        mindate: {
            type: String,
            required: false,
        },
        maxdate: {
            type: String,
            required: false,
        },
    },

    data() {
        return {
            menu: false,
            date: this.value as string | null,
            rules: this.required ? notEmpty() : [],
        };
    },

    methods: {
        handleInput() {
            this.$emit("input", this.date);
        },
        clear() {
            this.date = null;
            this.handleInput();
        },
    },

    watch: {
        date(value: string | null) {
            (this.$refs.menu as any).save(value);
        },
        value(value: string | null) {
            this.date = value;
        },
    },
});

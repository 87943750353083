
import { Dealer, dealersApi } from "@/api/dealers";
import { externalBdcCdrAssessmentsApi } from "@/api/externalBdcCdrAssessments";
import { ExternalBdcCdr } from "@/api/externalBdcCdrs";
import { Recording, recordingsApi } from "@/api/recordings";
import { User, usersApi } from "@/api/users";
import { getFullName } from "@/app/userUtils";
import { now } from "@/store/now";
import { userSession } from "@/store/userSession";
import { formatDifference, formatTime } from "@/util/dateTimeUtils";
import { parseAndFormatNumber } from "@/util/phoneNumberUtils";
import { SelectOption } from "@/util/types";
import Vue from "vue";
import { TranslateResult } from "vue-i18n";

interface TableHeader {
    readonly text: TranslateResult;
    readonly tooltip: TranslateResult;
    readonly width: string;
}

export default Vue.extend({
    data() {
        return {
            dealers: [] as Dealer[],
            externalBdcCdr: null as ExternalBdcCdr | null,
            loading: true,
            users: [] as User[],
            loadFilter: {
                userId: null as string | null,
                dealerId: null as string | null,
            },
            parseAndFormatNumber,
            recordings: [] as Recording[],
            saving: false,
        };
    },

    computed: {
        dealerOptions(): SelectOption[] {
            return this.dealers
                .map((d) => ({ value: d.id, text: d.name }))
                .sort((a, b) => a.text.localeCompare(b.text, userSession.locale));
        },

        userOptions(): SelectOption[] {
            return this.users
                .map((u) => ({ value: u.id, text: getFullName(u) }))
                .sort((a, b) => a.text.localeCompare(b.text, userSession.locale));
        },

        working(): boolean {
            return this.loading || this.saving;
        },
    },

    methods: {
        formatDifference(lengthInSeconds: number) {
            return formatDifference(new Date(0), new Date(lengthInSeconds * 1000), true);
        },

        formatTime(date: Date) {
            return formatTime(date, userSession.timeZone, userSession.locale);
        },

        generateRecordingLink(id: number): string {
            return recordingsApi.generateRecordingLink(id);
        },

        getDealerNameById(id: string | null) {
            return this.dealers.find((d) => d.id === id)?.name || "-";
        },
        getUserFullNameById(userId: string | null) {
            const user = this.users.find((u) => u.id === userId);

            return user ? getFullName(user) : "-";
        },

        async loadRandomUnassessed() {
            this.loading = true;
            this.externalBdcCdr = null;
            this.recordings = [];

            try {
                const ts = now();

                const externalBdcCdr = await externalBdcCdrAssessmentsApi.getRandomUnassessed(
                    new Date(ts.getTime() - 7 * 24 * 60 * 60 * 1000),
                    ts,
                    this.loadFilter.userId,
                    this.loadFilter.dealerId
                );

                const recordings = externalBdcCdr
                    ? await recordingsApi.getByBeginRangeAndCaller(
                          new Date(externalBdcCdr.begin.getTime() - 1000),
                          new Date(externalBdcCdr.begin.getTime() + 1000),
                          externalBdcCdr.caller
                      )
                    : [];

                this.externalBdcCdr = externalBdcCdr;
                this.recordings = recordings;
            } finally {
                this.loading = false;
            }
        },

        async refresh() {
            await this.loadRandomUnassessed();
        },

        async saveAssessmentAndLoadNextUnassessed(assessment: number | null) {
            if (!this.externalBdcCdr) {
                return;
            }

            this.saving = true;

            try {
                await externalBdcCdrAssessmentsApi.editAssessment(this.externalBdcCdr!.id, assessment);
                await this.loadRandomUnassessed();
            } finally {
                this.saving = false;
            }
        },
    },

    watch: {
        loadFilter: {
            deep: true,
            async handler() {
                try {
                    await this.loadRandomUnassessed();
                } catch (e) {
                    this.$nextTick(() => {
                        throw e;
                    });
                }
            },
        },
    },

    async mounted() {
        this.dealers = await dealersApi.list();
        this.users = await usersApi.list();

        await this.loadRandomUnassessed();
    },
});

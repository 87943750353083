import { getDataUrlMimeType } from "@/util/mimeUtils";
import axios from "axios";
import Vue from "vue";

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export function fileDialog(
    multiple: boolean,
    cb: (files: File[]) => void | Promise<void>,
    acceptedFileTypes: string[] = [],
    filenameFilter = /^[^.]+$|.\.(?=[^.]+$)(?!(exe|com|dll|vbs|bat|cmd)$)/i
) {
    const input = document.createElement("input");
    input.type = "file";
    input.multiple = multiple;
    if (acceptedFileTypes.length) {
        input.accept = acceptedFileTypes.join(",");
    }
    input.onchange = async () => {
        if (input.files && input.files.length > 0) {
            const files = Array.from(input.files).filter((file) => !!file.name.match(filenameFilter));
            if (files.length) {
                try {
                    await cb(files);
                } catch (e) {
                    Vue.nextTick(() => {
                        throw e;
                    });
                }
            }
        }
    };
    if (isSafari) {
        input.style.display = "none";
        document.body.appendChild(input);

        const focusHandler = () => {
            document.body.removeChild(input);
            window.removeEventListener("focus", focusHandler);
        };
        window.addEventListener("focus", focusHandler, { once: true });
    }
    input.click();
}

function getFilename(url: string): string | undefined {
    const from = url.lastIndexOf("/");

    if (from < 0) {
        return;
    }

    return url.substr(from + 1);
}

export async function getFile(url: string, filename?: string): Promise<File | undefined> {
    try {
        const request = await axios.get(url, {
            responseType: "blob",
        });
        const content = request.data;

        const options = {
            type: request.headers["content-type"] || "application/octet-stream",
        };

        const blob = new Blob([content], options);
        const name = filename || getFilename(url) || "file";

        return new File([blob], name, options);
    } catch (e) {
        return;
    }
}

export function readFileAsText(file: File): Promise<string> {
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = () => reject(reader.error);
        reader.readAsText(file);
    });
}

export function readTextAsFile(text: string, filename: string): Promise<File> {
    return fetch(text)
        .then((r) => r.blob())
        .then((blob) => new File([blob], filename, { type: blob.type }));
}

export function downloadOrOpenFile(file: File, download: boolean) {
    const url = URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = url;
    if (download) {
        link.download = file.name;
    } else {
        link.target = "_blank";
    }
    link.type = file.type;
    link.click();
    setTimeout(() => URL.revokeObjectURL(url), 0);
}

export function downloadDataUrl(dataUrl: string, filename: string) {
    const link = document.createElement("a");

    link.href = dataUrl;
    link.download = filename;

    const mimeType = getDataUrlMimeType(dataUrl);

    if (mimeType) {
        link.type = mimeType.raw;
    }

    link.click();
}

export function downloadUrl(url: string) {
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.click();
}

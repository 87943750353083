
import InvoiceSendReminderEmailDialog from "./InvoiceSendReminderEmailDialog.vue";
import InvoicesDataTableRow from "./InvoicesDataTableRow.vue";
import { BillingAccount, PaymentMethod } from "@/api/billingAccounts";
import { bwaApi, BwaMonth } from "@/api/bwa";
import { invoiceDirectDebitsApi } from "@/api/invoicedirectdebits";
import { Invoice, InvoiceReminderEmailForm, invoicesApi } from "@/api/invoices";
import { Permission } from "@/api/userSession";
import DataTable from "@/app/components/DataTable.vue";
import { DataTableHeader } from "@/app/components/dataTable";
import { showConfirm, showInfo } from "@/app/messageUtil";
import ProcessingProgressDialog from "@/app/pages/ProcessingProgressDialog.vue";
import { ProcessingProgressDialogProps } from "@/app/pages/processingProgressDialog";
import { searchFilter } from "@/app/searchFilter";
import { configStore } from "@/store/config";
import { userSession } from "@/store/userSession";
import { getCurrentMonth, toDateObject } from "@/util/dateTimeUtils";
import Vue from "vue";

interface Item {
    selected: boolean;
    readonly periodBeginMonth: string;
    readonly billingAccount: BillingAccount;
    readonly invoice: Invoice;
    readonly bwaMonth: BwaMonth | null;
}

export default Vue.extend({
    props: {
        canSelect: {
            type: Boolean,
            default: false,
        },
        billingAccounts: {
            type: Array as () => BillingAccount[],
            required: true,
        },
        invoices: {
            type: Array as () => Invoice[],
            required: true,
        },
        loading: {
            type: Boolean,
            required: true,
        },
        month: {
            type: String,
            required: false,
        },
        search: {
            type: String,
            required: false,
        },
        timeZone: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            bwaMonths: [] as BwaMonth[],
            invoiceSendReminderEmailDialogVisible: false,
            items: [] as Item[],
            processingProgressDialogProps: null as ProcessingProgressDialogProps<Item> | null,
            reminderEmailInvoiceIds: [] as number[],
            working: false,
        };
    },

    computed: {
        bwaMonth(): BwaMonth | null {
            if (!this.month) {
                return null;
            }

            return this.bwaMonths.find((b) => b.month === this.month) ?? null;
        },

        canReverseInvoices(): boolean {
            return userSession.hasPermission(Permission.CT_MANAGE_INVOICES);
        },

        canRemindInvoices(): boolean {
            return userSession.hasPermission(Permission.CT_MANAGE_INVOICES);
        },

        canTogglePaid(): boolean {
            return (
                userSession.hasPermission(Permission.CT_MANAGE_INVOICES) ||
                userSession.hasPermission(Permission.CT_MARK_INVOICES_AS_PAID)
            );
        },

        headers(): DataTableHeader[] {
            return [
                {
                    text: this.$t("Rechnung"),
                    width: "25%",
                },
                {
                    text: this.$t("Abrechnungskonto"),
                    width: "25%",
                },
                {
                    text: this.$t("Rechnungsdetails"),
                    width: "30%",
                },
                {
                    text: this.$t("Status"),
                    width: "10%",
                },
                {
                    text: this.$t("Aktionen"),
                    align: "right",
                    width: "10%",
                },
            ];
        },

        organisationCurrency(): string {
            return configStore.configuration.organisationCurrency;
        },

        selectedItems(): Item[] {
            return this.items.filter((item) => item.selected);
        },

        totalGross(): number {
            return this.invoices.map((x) => x.grossAmount).reduce((a, b) => a + b, 0.0);
        },

        totalNet(): number {
            return this.invoices.map((x) => x.netAmount).reduce((a, b) => a + b, 0.0);
        },
    },

    watch: {
        invoices() {
            this.generateItems();
        },

        search() {
            this.unselectAll();
        },
    },

    methods: {
        async cancelInvoice(invoiceId: number) {
            if (
                !(await showConfirm(
                    this.$t("Rechnung stornieren") as string,
                    this.$t("Sind Sie sicher, dass Sie die Rechnung mit der Nummer {0} stornieren möchten ?", [
                        invoiceId,
                    ]) as string
                ))
            ) {
                return;
            }

            const sendEmail = await showConfirm(
                this.$t("E-Mail-Versand") as string,
                this.$t("Soll eine E-Mail an die Rechnungsempfänger geschickt werden?") as string,
                this.$t("Ja") as string,
                this.$t("Nein") as string
            );

            this.working = true;
            try {
                await invoicesApi.cancel(invoiceId, sendEmail);
                this.$emit("change");
            } finally {
                this.working = false;
            }
        },

        downloadDirectDebitSepaXml() {
            window.location.assign(invoiceDirectDebitsApi.generateDirectDebitsAsSepaXml(this.month));
        },

        downloadInvoiceExcel() {
            window.location.assign(bwaApi.generateBwaMonthInvoicesExcelLink(this.month));
        },

        generateItems() {
            this.items = [...this.invoices]
                .sort((a, b) => {
                    const aBegin = toDateObject(this.timeZone, a.periodBegin);
                    const bBegin = toDateObject(this.timeZone, b.periodBegin);

                    return aBegin.getTime() - bBegin.getTime();
                })
                .map((invoice) => ({
                    selected: false,
                    periodBeginMonth: getCurrentMonth(toDateObject(this.timeZone, invoice.periodBegin), this.timeZone),
                    billingAccount: this.billingAccounts.find((b) => b.id === invoice.billingAccountId)!,
                    invoice,
                    bwaMonth:
                        this.bwaMonths
                            .filter((b) => b.fromInvoiceId !== null)
                            .find((b) => b.fromInvoiceId! <= invoice.id && invoice.id <= b.toInvoiceId!) ?? null,
                }));
        },

        getItemsCountInPeriodBeginMonth(periodBeginMonth: string, items: Item[]) {
            return items.filter((i) => i.periodBeginMonth === periodBeginMonth).length;
        },

        hideInvoiceSendReminderEmailDialog() {
            this.reminderEmailInvoiceIds = [];
            this.invoiceSendReminderEmailDialogVisible = false;
        },

        async loadBwaMonth() {
            this.bwaMonths = [];
            this.bwaMonths = await bwaApi.getAll();
        },

        searchFilter(search: string | null | undefined, item: Item) {
            return searchFilter(
                search ?? null,
                item.invoice.id.toString(),
                item.billingAccount.companyName,
                item.billingAccount.sepaB2bDirectDebitPaymentInformation?.iban,
                item.billingAccount.sepaB2bDirectDebitPaymentInformation?.bic,
                item.invoice.grossAmount.toString()
            );
        },

        selectAll() {
            for (const item of this.items) {
                item.selected = true;
            }
        },

        sendInvoiceReminderEmails(invoiceIds: number[], form: InvoiceReminderEmailForm) {
            this.invoiceSendReminderEmailDialogVisible = false;

            const items = invoiceIds
                .map((invoiceId) => this.items.find((item) => item.invoice.id === invoiceId)!)
                .filter((item) => item);

            if (!items.length) {
                return;
            }

            this.processingProgressDialogProps = {
                items,
                process: (item: Item) => invoicesApi.sendReminderEmail(item.invoice.id, form),
                title: this.$t("Sende Zahlungserinnerung") as string,
            };
        },

        showInvoiceSendReminderEmailDialog(invoiceIds: number[]) {
            this.reminderEmailInvoiceIds = invoiceIds;

            if (!this.reminderEmailInvoiceIds.length) {
                return;
            }

            this.invoiceSendReminderEmailDialogVisible = true;
        },

        showInvoiceSendReminderEmailDialogForSelection() {
            const unpaidInvoices = this.selectedItems
                .filter(
                    (item) =>
                        item.billingAccount.receivers.length &&
                        item.billingAccount.paymentMethod === PaymentMethod.BANK_TRANSFER
                )
                .map((item) => item.invoice)
                .filter((invoice) => !invoice.paid && !invoice.reverseInvoiceId && invoice.grossAmount > 0);

            if (this.selectedItems.length && unpaidInvoices.length < this.selectedItems.length) {
                showInfo(
                    this.$t(
                        "Es wurden {0} von {1} Rechnungen ignoriert, da sie bereits bezahlt oder storniert wurden bzw. keine Rechnungsempfänger hinterlegt sind.",
                        [this.selectedItems.length - unpaidInvoices.length, this.selectedItems.length]
                    ) as string
                );
            }

            this.showInvoiceSendReminderEmailDialog(unpaidInvoices.map((invoice) => invoice.id));

            this.unselectAll();
        },

        async togglePaid(invoiceId: number, paid: boolean) {
            this.working = true;
            try {
                await invoicesApi.updatePaid(invoiceId, paid);
                this.$emit("change");
            } finally {
                this.working = false;
            }
        },

        unselectAll() {
            for (const item of this.items) {
                item.selected = false;
            }
        },
    },

    async mounted() {
        await this.loadBwaMonth();
        this.generateItems();
    },

    components: {
        DataTable,
        InvoicesDataTableRow,
        InvoiceSendReminderEmailDialog,
        ProcessingProgressDialog,
    },
});


import { EChartsMouseEvent, formatDataLabel } from "./eChartsUtils";
import { getColoredChartSeries } from "./reportingChart";
import { renderTooltip, ReportingChartTooltipCache } from "./reportingChartTooltip";
import {
    ReportingDonutChartSeries,
    ReportingDonutChartSeriesData,
    ReportingDonutChartSeriesValue,
    ReportingDonutChartSeriesValueFormatter,
} from "./reportingDonutChart";
import { DefaultLabelFormatterCallbackParams, EChartsOption, PieSeriesOption } from "echarts";
import Vue from "vue";

interface ChartPosition {
    readonly centerHorizontal: string;
    readonly centerVertical: string;
    readonly radiusStart: string;
    readonly radiusEnd: string;
    readonly title: string;
}

interface PieSeriesOptionDataValue {
    readonly value: number;
    readonly onClick?: () => Promise<void> | void;
    readonly formatter?: ReportingDonutChartSeriesValueFormatter;
}

export default Vue.extend({
    props: {
        height: {
            type: Number,
            required: false,
        },
        hideLegend: {
            type: Boolean,
            default: false,
        },
        pie: {
            type: Boolean,
            default: false,
        },
        semi: {
            type: Boolean,
            default: false,
        },
        series: {
            type: Array as () => ReportingDonutChartSeries[],
            required: true,
        },
        seriesAdditionalTooltipDataTooltipHeaders: {
            type: Array as () => string[],
            required: false,
        },
        seriesDataTooltipHeader: {
            type: String,
            required: false,
        },
        showTotal: {
            type: Boolean,
            default: false,
        },
        totalLabel: {
            type: String,
            required: false,
        },
    },

    data() {
        return {
            tooltipCache: {} as ReportingChartTooltipCache,
            width: 0,
        };
    },

    computed: {
        chartPosition(): ChartPosition {
            const centerHorizontal = 50;
            let centerVertical = 50;
            let radiusStart = 61;
            let radiusEnd = 95;
            let title = 45;

            if (this.pie) {
                radiusStart = 0;
            }

            if (this.semi) {
                centerVertical *= 2;
                radiusStart *= 2;
                radiusEnd *= 2;
                title *= 1.5;
            }

            if (this.totalLabel && this.showTotal) {
                title *= 0.87;
            }

            if (!this.hideLegend) {
                centerVertical *= this.semi ? 0.8 : 0.9;
                radiusStart *= this.semi ? 0.78 : 0.9;
                radiusEnd *= this.semi ? 0.78 : 0.9;
                title *= this.semi ? 0.78 : 0.9;
            }

            return {
                centerHorizontal: `${centerHorizontal}%`,
                centerVertical: `${centerVertical}%`,
                radiusStart: `${radiusStart}%`,
                radiusEnd: `${radiusEnd}%`,
                title: `${title}%`,
            };
        },

        eChartHeight(): number {
            const availableWidth = this.width - (this.semi ? 20 : 0);

            if (this.height) {
                return Math.min(this.height, availableWidth) / (this.semi ? 2 : 1);
            } else {
                return Math.min(500, availableWidth);
            }
        },

        eChartsOption(): EChartsOption {
            const showTitle = !!this.totalLabel;
            const subtitle = this.showTotal ? this.total.toString() : undefined;

            return {
                animation: false,
                tooltip: {
                    trigger: "item",
                    formatter: (params: any) => {
                        return this.renderTooltip([params] as DefaultLabelFormatterCallbackParams[]) ?? "";
                    },
                },
                legend: {
                    show: !this.hideLegend,
                    type: "scroll",
                    top: "bottom",
                    height: "30px",
                    itemWidth: 12,
                    itemHeight: 12,
                    textStyle: {
                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                        fontSize: "12px",
                    },
                },
                series: this.eChartSeries,
                title: {
                    show: showTitle || this.showTotal,
                    text: this.totalLabel ?? subtitle,
                    subtext: showTitle ? subtitle : undefined,
                    left: "center",
                    top: this.chartPosition.title,
                    textStyle: {
                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                        fontSize: "14px",
                        fontWeight: "normal",
                    },
                    subtextStyle: {
                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                        fontSize: "14px",
                        fontWeight: "normal",
                    },
                },
            };
        },

        eChartSeries(): PieSeriesOption {
            const pieSeriesOption: PieSeriesOption = {
                type: "pie",
                radius: [this.chartPosition.radiusStart, this.chartPosition.radiusEnd],
                center: [this.chartPosition.centerHorizontal, this.chartPosition.centerVertical],
                avoidLabelOverlap: false,
                startAngle: 180,
                cursor: this.series.some((s) => s.data.value.onClick) ? "pointer" : "default",
                data: getColoredChartSeries(this.series).map((series: ReportingDonutChartSeries) => {
                    return {
                        name: series.name,
                        ...this.getPieSeriesOptionDataValue(series.data.value, series.data),
                        additionalTooltipValues: (series.additionalTooltipData ?? []).map((adtData) =>
                            this.getPieSeriesOptionDataValue(adtData.value, adtData)
                        ),
                        label: {
                            show: !!series.data.value.value,
                            silent: true,
                            color: "#ffffff",
                            fontFamily: "Helvetica, Arial, sans-serif",
                            fontSize: "12px",
                            fontWeight: "bold",
                            textBorderColor: "#999999",
                            textBorderWidth: 2,
                            position: "inside",
                            formatter: (params: any) => {
                                const value = params?.value;

                                if (typeof value !== "number") {
                                    return "";
                                }

                                // only show labels if there is approximately enough space
                                const showValueAboveFactor = this.semi ? 0.04 : 0.02;

                                if (value < showValueAboveFactor * this.total) {
                                    return "";
                                }

                                const customLabel = series.data.formatter
                                    ? series.data.formatter(value, "LABEL")
                                    : null;

                                return customLabel ?? formatDataLabel(params?.value ?? null, false);
                            },
                        } as any,
                        itemStyle: {
                            color: series.color,
                            borderWidth: 0.5,
                            borderColor: "#FFFFFFAA",
                        },
                    };
                }),
            };

            if (this.semi) {
                pieSeriesOption.data!.push({
                    value: this.total,
                    name: "",
                    cursor: "auto",
                    itemStyle: {
                        opacity: 0,
                    },
                    label: {
                        show: false,
                    },
                    tooltip: {
                        show: false,
                    },
                } as any);
            }

            return pieSeriesOption;
        },

        total(): number {
            return this.series.reduce((total, cur) => total + cur.data.value.value, 0);
        },
    },

    methods: {
        getChartAsPngDataUrl(): string | null {
            return (this.$refs.chart as any).getChartAsPngDataUrl();
        },

        getChartAsSvgDataUrl(): string | null {
            return (this.$refs.chart as any).getChartAsSvgDataUrl();
        },

        getPieSeriesOptionDataValue(
            v: ReportingDonutChartSeriesValue,
            data: ReportingDonutChartSeriesData
        ): PieSeriesOptionDataValue {
            const roundFactor = 100;

            return {
                value: Math.round(roundFactor * v.value) / roundFactor,
                onClick: data.value.onClick,
                formatter: data.formatter,
            };
        },

        async onValueClick(e: EChartsMouseEvent<PieSeriesOptionDataValue>) {
            if (e.componentType !== "series" || e.seriesType !== "pie" || !e.data.onClick) {
                return;
            }

            await e.data.onClick();
        },

        renderTooltip(series: DefaultLabelFormatterCallbackParams[]): string | null {
            if (!series.length) {
                return null;
            }

            const tooltipSeries = series.map((s) => {
                const dataValue = s.data as PieSeriesOptionDataValue;
                const additionalTooltipValues = (s.data as any).additionalTooltipValues as PieSeriesOptionDataValue[];

                return {
                    name: s.name!,
                    marker: s.marker! as string,
                    value: {
                        value: dataValue.value,
                        isPercentage: false,
                        formatter: (value: number | undefined) =>
                            dataValue.formatter ? dataValue.formatter(value, "TOOLTIP") : null,
                    },
                    additionalTooltipValues: additionalTooltipValues.map((adtValue) => ({
                        value: adtValue.value,
                        isPercentage: false,
                        formatter: (value: number | undefined) =>
                            adtValue.formatter ? adtValue.formatter(value, "TOOLTIP") : null,
                    })),
                    group: null,
                };
            });

            return renderTooltip(
                {
                    additionalValueHeaders: this.seriesAdditionalTooltipDataTooltipHeaders,
                    series: tooltipSeries,
                    valueHeader: this.seriesDataTooltipHeader,
                },
                this.tooltipCache,
                this.$i18n
            );
        },

        resize(width: number) {
            this.width = width;
        },
    },

    components: {
        LazyEChart: () => import("./LazyEChart.vue"),
    },
});

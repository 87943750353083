
import UserOverviewDataTable from "./UserOverviewDataTable.vue";
import { BdcTeam, bdcTeamsApi } from "@/api/bdcTeams";
import { Role, User, usersApi } from "@/api/users";
import EnumField from "@/app/components/EnumField.vue";
import { searchFilter, searchFilterPhoneNumbers } from "@/app/searchFilter";
import { SelectOption } from "@/util/types";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            Role,
            bdcTeams: [] as BdcTeam[],
            loading: true,
            searchRequest: {
                bdcTeamIds: [] as string[],
                forcePasswordChange: null as boolean | null,
                roles: [] as Role[],
                search: null as string | null,
            },
            users: [] as User[],
        };
    },

    computed: {
        bdcTeamOptions(): SelectOption[] {
            return this.bdcTeams.map((bdcTeam) => ({
                text: bdcTeam.name,
                value: bdcTeam.id,
            }));
        },

        filteredUsers(): User[] {
            return this.users
                .filter(
                    (u) =>
                        this.searchRequest.forcePasswordChange === null ||
                        u.forcePasswordChange === this.searchRequest.forcePasswordChange
                )
                .filter(
                    (u) =>
                        this.searchRequest.roles.length === 0 ||
                        this.searchRequest.roles.some((r) => u.roles.includes(r))
                )
                .filter(
                    (u) => !this.searchRequest.bdcTeamIds.length || this.memberUserIdsOfSelectedBdcTeams.includes(u.id)
                )
                .filter(
                    (u) =>
                        searchFilter(
                            this.searchRequest.search,
                            u.username,
                            u.namePrefix,
                            u.givenName,
                            u.familyName,
                            u.employeeNumber,
                            u.emailAddress
                        ) || searchFilterPhoneNumbers(this.searchRequest.search, ...u.phoneNumbers.map((p) => p.number))
                );
        },

        memberUserIdsOfSelectedBdcTeams(): string[] {
            return this.bdcTeams
                .filter((bdcTeam) => this.searchRequest.bdcTeamIds.includes(bdcTeam.id))
                .map((bdcTeam) => bdcTeam.memberUserIds)
                .reduce((prev, cur) => prev.concat(cur), []);
        },

        trueFalseOptions(): SelectOption[] {
            return [
                { value: true, text: this.$t("Ja") },
                { value: false, text: this.$t("Nein") },
            ];
        },
    },

    methods: {
        async loadItems() {
            this.loading = true;
            this.bdcTeams = [];
            this.users = [];
            try {
                this.bdcTeams = await bdcTeamsApi.getAll();
                this.users = await usersApi.list();
            } finally {
                this.loading = false;
            }
        },
    },

    async mounted() {
        await this.loadItems();
    },

    components: {
        EnumField,
        UserOverviewDataTable,
    },
});
